import { Component, OnInit, Input } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { MastersServiceService } from '../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { FormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import 'fullcalendar';

@Component({
  selector: 'app-dashboard-athlete',
  templateUrl: './dashboard-athlete.component.html',
  styleUrls: ['./dashboard-athlete.component.css']
})
export class DashboardAthleteComponent implements OnInit {

  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    //select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire: eventAdd: eventChange: eventRemove: */
  };
  currentEvents: EventApi[] = [];

  public isShowLoader: boolean;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isSuccessAlert: boolean = false; public successAlert: string;

  public isSchedule: boolean = false;

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    // this.isSchedule=true;
    const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  public ListEventsTemp: any = [];
  public EventName!: string; public EventDay!: string;
  public EventMonth!: string; public EventStart!: string;
  public EventEnd!: string; public EventVenue!: string; 
  public EventComments!: string; public EventGroup!:string; public EventType!:string;

  handleEventClick(clickInfo: EventClickArg) {
    this.isSchedule = true;
    this.ListEventsTemp =[];
    this.ListEventsTemp = this.ListEvents.filter(s => String(s.EventID) === String(clickInfo.event.extendedProps.EventID) );
    if(this.ListEventsTemp.length > 0){
        this.EventName  =  this.ListEventsTemp[0].title;
        this.EventDay   =  this.ListEventsTemp[0].EventDay;
        this.EventMonth =  this.ListEventsTemp[0].EventMonth;
        this.EventStart =  this.ListEventsTemp[0].StartTime;
        this.EventEnd   =  this.ListEventsTemp[0].EndTime;
        this.EventVenue =  this.ListEventsTemp[0].Venue;
        this.EventComments =  this.ListEventsTemp[0].Comments;
        this.EventGroup = this.ListEventsTemp[0].MemberTypeID;
        this.EventType =  this.ListEventsTemp[0].EventTypeName;

        
    }
  }

  hideSchedule(){
    this.isSchedule=false;
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  @Input() eventData: any;
  defaultConfigurations: any;
  @Input()
  set configurations(config: any) {
    if (config) {
      this.defaultConfigurations = config;
    }
  }

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router) {
    this.defaultConfigurations = {
      editable: true,
      eventLimit: true,
      titleFormat: 'MMM D YYYY',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      views: {
        agenda: {
          eventLimit: 2
        }
      },
      allDaySlot: false,
      slotDuration: moment.duration('00:15:00'),
      slotLabelInterval: moment.duration('01:00:00'),
      firstDay: 1,
      selectable: true,
      selectHelper: true,
      events: this.eventData,
    };
    this.eventData = [
      {
        title: 'event1',
        start: moment()
      },
      {
        title: 'event2',
        start: moment(),
        end: moment().add(2, 'days')
      },
    ];
  }

  public UserCode: string; public UserName: string;
  public AthleteDashboardDetails: any[];
  public AthleteMyUploadsList: any[];
  public AthleteMyDrillsList: any[];
  public AthleteVideoCountList: any[];

  public AthleteName: string; public DateOFBirth: string; public ProfileImage: string;
  public AssociationName: string; public PlayerRole: string;
  public BattingStyle: string; public BowlingStyle: string;

  public IsEmptyDrills: boolean = false;
  public IsVideoShow: boolean = true;

  public IsMyVideoShow: boolean = false;

  public TotalVideos: string; public TotalUploads: string; public TotalReviewed: string;
  ngOnInit(): void {
    this.utility.pageLoadScript(); 
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.AthleteDashBoardLoad();
    this.LoadCalenderValues('FullView', '');
  };

  public ListEvents: any = [];
  LoadCalenderValues(type, eventId) {
    this.service.PlannerCalendarLoad({ ActionFlag: type, UserID: this.UserCode, EventID: eventId }).subscribe((ret: any = []) => {
      this.ListEvents = ret.EventsList;
      this.calendarOptions.events = this.ListEvents;
    });
  }

  public PlayerRoleImage: string; public PlayerBattStyelImg: string; public BowSpecImage: String;

  AthleteDashBoardLoad() {
    this.isShowLoader = true;
    var AthleteDashBoard = { ActionFlag: "Dashboard", UserID: String(this.UserCode) };
    this.service.AthleteDashboard(AthleteDashBoard).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.AthleteDashboardDetails = ret.AthleteDashboardDetailsList;
        this.AthleteMyUploadsList = ret.AthleteMyUploadsList;
        this.AthleteMyDrillsList = ret.AthleteMyDrillsList;
        this.AthleteVideoCountList = ret.AthleteVideoCountList;

        if (this.AthleteDashboardDetails.length > 0 )
        {
          // -- Athlete Basic Details -- //
          this.AthleteName = this.AthleteDashboardDetails[0].AthleteName;
          this.DateOFBirth = this.AthleteDashboardDetails[0].Age;
          this.ProfileImage = this.AthleteDashboardDetails[0].ProfileImage;
          this.AssociationName = this.AthleteDashboardDetails[0].AssociationName;
          this.PlayerRole = this.AthleteDashboardDetails[0].PlayerRole;
          this.BattingStyle = this.AthleteDashboardDetails[0].BattingTypeName;
          this.BowlingStyle = this.AthleteDashboardDetails[0].BowlingSpecialization;
          // public PlayerRoleImage:string; public PlayerBattStyelImg:string; public BowSpecImage:String;
  
          if (this.AthleteDashboardDetails[0].PlayerRoleID == 1) {
            this.PlayerRoleImage = "ico-bat02"
          }
          else if (this.AthleteDashboardDetails[0].PlayerRoleID == 2) {
            this.PlayerRoleImage = "ico-bowler02"
          }
          else if (this.AthleteDashboardDetails[0].PlayerRoleID == 3) {
            this.PlayerRoleImage = "ico-bat-wk"
          }
          else if (this.AthleteDashboardDetails[0].PlayerRoleID == 4) {
            this.PlayerRoleImage = "ico-allrounder02"
          }
  
          if (this.AthleteDashboardDetails[0].BattingTypeID == 1) {
            this.PlayerBattStyelImg = "ico-righthandbat"
          }
          else if (this.AthleteDashboardDetails[0].BattingTypeID == 2) {
            this.PlayerBattStyelImg = "ico-lefthandbat"
          }
  
  
          if (this.AthleteDashboardDetails[0].BowlingStyleID == 1) { // right
            if (this.AthleteDashboardDetails[0].BowlingTypeID == 1) { // fast
              this.BowSpecImage = "ico-rightarmfast";
            }
            else {// spin
              if (this.AthleteDashboardDetails[0].BowlingSPTypeID == 5) { // off
                this.BowSpecImage = "ico-rightoffspin";
              }
              else if (this.AthleteDashboardDetails[0].BowlingSPTypeID == 6) { // leg
                this.BowSpecImage = "ico-rightlegspin";
              }
            }
          }
          else { // left
            if (this.AthleteDashboardDetails[0].BowlingTypeID == 1) { // fast
              this.BowSpecImage = "ico-leftarmfast";
            }
            else { // spin
              if (this.AthleteDashboardDetails[0].BowlingSPTypeID == 7) { // off
                this.BowSpecImage = "ico-orthodox";
              }
              else if (this.AthleteDashboardDetails[0].BowlingSPTypeID == 8) { // china
                this.BowSpecImage = "ico-chinnaman";
              }
            }
          }

          if(this.AthleteVideoCountList.length > 0 ){
            this.TotalVideos = this.AthleteVideoCountList[0].TotalVideos;
            this.TotalUploads = this.AthleteVideoCountList[0].UploadCount;
            this.TotalReviewed = this.AthleteVideoCountList[0].ReviewCount;
          }

          // -- Athlte MyUploads Details -- //
          this.isShowLoader = false;
          this.IsEmptyDrills = false;
        }
        this.isShowLoader = false;
        this.IsEmptyDrills = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  AtheleteMyDrillsLoad() {
    this.isShowLoader = true;
    var AthleteDashBoard = { ActionFlag: "Dashboard", UserID: String(this.UserCode) };
    this.service.AthleteDashboard(AthleteDashBoard).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.AthleteMyDrillsList = ret.AthleteMyDrillsList;

        if (this.AthleteMyDrillsList.length > 0) {
          this.AthleteMyDrillsList = this.AthleteMyDrillsList;
          this.IsEmptyDrills = false;
        }
        else { this.IsEmptyDrills = true; }
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  MyUploadClick() {
    if (this.AthleteMyUploadsList.length > 0) {
      this.AthleteMyUploadsList = this.AthleteMyUploadsList;
      this.IsVideoShow = false;
    }
    else { this.IsVideoShow = true }
  }

  // ---- Drills View ---- //
  public PreviewDrillName: string; public DrillType: string;
  public ThumbNail: string; public VideoURL: string;
  public CreatedTime: string; public CreatedDate: string; public CreatedBy: string;
  public MarialsBind: any = []; public lstMyDrills: any = [];
  public lstDrillEdit: any[]; public lstDrillEditStep: any[]; public lstMembers: any[];
  ShowMyVideo(video) {
    this.isShowLoader = true;
    this.MarialsBind = [];
    var DrillsEdit = { DrillID: String(video.DrillID), ActionFlag: "Edit", RefUserID: this.UserCode };
    this.service.DrillsCRUD(DrillsEdit).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstDrillEdit = ret.DrillEditList;
        this.lstDrillEditStep = ret.DrillEditStepList;
        this.lstMembers = ret.RowDetails;

        this.IsMyVideoShow = true;
        this.PreviewDrillName = this.lstDrillEdit[0].DrillName;
        this.DrillType = this.lstDrillEdit[0].DrillType;
        this.ThumbNail = this.lstDrillEdit[0].ThumbnailURL;
        this.VideoURL = this.lstDrillEdit[0].VideoURL;
        this.CreatedTime = this.lstDrillEdit[0].CreatedTime;
        this.CreatedDate = this.lstDrillEdit[0].CreatedDate;
        this.CreatedBy = this.lstDrillEdit[0].CreatedBy;
        var arrayMat = String(this.lstDrillEdit[0].Materials).split(",").map(String);
        for (var i = 0; i < arrayMat.length; i++) {
          this.MarialsBind.push(arrayMat[i]);
        }
        this.isShowLoader = false;
      }
      else {
        this.IsMyVideoShow = false;
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  // ---- MyUploads or Session View ---- //
  public SessionName: string; public SessionType: string; public SessionView: string;
  public SessionVideoURL: string; public SessionThumbnailURL: string; public SessionDate: string;
  public SessionTime: string; public SessionUserName: string;
  public ListAthleteSession: any[];
  GetSessionDetails(session) {
    this.isShowLoader = true;
    var AthleteDashBoard = { ActionFlag: "GetSessionDetails", UserID: String(this.UserCode), SessionID: String(session.SessionID) };
    this.service.AthleteDashboard(AthleteDashBoard).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.ListAthleteSession = ret.AthleteSessionDetails;
        this.SessionName = this.ListAthleteSession[0].SessionName;
        this.SessionType = this.ListAthleteSession[0].SessionTypeName;
        this.SessionView = this.ListAthleteSession[0].VideoViewName;
        this.SessionVideoURL = this.ListAthleteSession[0].VideoURL;
        this.SessionThumbnailURL = this.ListAthleteSession[0].ThumbnailURL;
        this.SessionDate = this.ListAthleteSession[0].SessionDate;
        this.SessionTime = this.ListAthleteSession[0].SessionTime;
        this.SessionUserName = this.ListAthleteSession[0].UserName;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }



  GetAnalysisDetails(data) {
    sessionStorage.setItem("sessionId", String(data.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(data.CloudURL));
    sessionStorage.setItem("sessionVideoDate", String(data.SessionDate));
    sessionStorage.setItem("sessionsThumbnail", String(data.ThumbnailURL));
    sessionStorage.setItem("SessionTypeName", String(data.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(data.VideoViewName));
    sessionStorage.setItem("SessionName", String(data.SessionName));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));
    sessionStorage.setItem("sessionPlayerName", String(this.AthleteName));
    sessionStorage.setItem("sessionPlayerRole", String(this.PlayerRole));
    sessionStorage.setItem("sessionProfileImage", String(this.ProfileImage));
    sessionStorage.setItem("sessionFlag", String("2"));
    sessionStorage.setItem("sessionPage", String("AthleteDashBoard"));
    this.router.navigate(['/video-analyse']);
  }

  GetCompareVideos(data){
    sessionStorage.setItem("sessionId", String(data.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(data.CloudURL));
    sessionStorage.setItem("sessionPlayerName", String(this.AthleteName));
    sessionStorage.setItem("sessionPlayerRole", String(this.PlayerRole));
    sessionStorage.setItem("sessionProfileImage", String(this.ProfileImage));
    sessionStorage.setItem("sessionVideoDate", String(data.SessionDate));
    sessionStorage.setItem("sessionsThumbnail", String(data.ThumbnailURL));
    sessionStorage.setItem("SessionTypeName", String(data.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(data.VideoViewName));
    sessionStorage.setItem("SessionName", String(data.SessionName));
    sessionStorage.setItem("sessionUserCode", String(data.UserCode));
    sessionStorage.setItem("athleteID",String(this.UserCode));
    sessionStorage.setItem("sFlag",String("2"));
  this.router.navigate(['/video-compare']);
  }



}


