import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../../masters-service.service';
import { DatePipe } from '@angular/common';
import { ThemePalette } from '@angular/material/core';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-drills-athletes',
  templateUrl: './drills-athletes.component.html',
  styleUrls: ['./drills-athletes.component.css']
})
export class DrillsAthletesComponent implements OnInit {

  constructor(private utility: UtilitiesService, private http: HttpClient,
    private blob: BlobService, private service: MastersServiceService,
    public datepipe: DatePipe, private formBuilder: FormBuilder,) { }

  public UserCode:string = "1";
  public isShowLoader:boolean = false;
  public isSuccessAlert:boolean = false; public successAlert:string;
  public isErrorAlert:boolean = false; public errorAlert:string;
  public ButtonType:string = "Save";

  public lstGetAthleteDrill:any[];
  public lstDrillLevel:any = [];
  public SearchLevel:String; public SearchFilter:string;

  public lstActive:any = []; public lstCompleted:any = []; public lstLibrary:any = [];

  public IsMyVideoShow:boolean = true;
  public PreviewDrillName:string; public DrillType:string;
  public ThumbNail:string;   public VideoURL:string;
  public CreatedTime:string; public CreatedDate:string; 
  public CreatedBy:string;   public VideoLength:string;
  public lstDrillEdit:any[]; public lstDrillEditStep:any[];
  public MarialsBind:any = [];

  public lstLibraryTypes:any = [];
  public IsShowLibraryType:boolean = true;
  public IsShowLibrayVideo:boolean = false;
  public IsShowLibrayBack:boolean = false;

  public IsDrillProgress:boolean = false;

  public allComplete:boolean = false;
  public DrillProgress:string;
  public ChkDrillName:string;
  public ChkDrillID:string;
  public ChkDrillStatus:string;
  public IsEmptyDrill:boolean = false;

  public IsShowSearch:boolean = true; //  *ngIf = "IsShowSearch"

  ngOnInit(): void { 
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.DrillsAthletesLoad();
  }

  DrillsAthletesLoad() {
    this.isShowLoader = true;
    var DrillsLoad = { ActionFlag: "MyDrills", RefUserID: this.UserCode };
    this.service.DrillsAthletes(DrillsLoad).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstGetAthleteDrill = ret.GetAthleteDrillList;
        this.lstDrillLevel = ret.DrillLevelList; 
        this.lstActive = this.lstGetAthleteDrill.filter(s => s.DrillStatus != "Completed");
        // this.lstCompleted = this.lstGetAthleteDrill.filter(s => s.DrillStatus === "Completed");
        this.lstLibrary = this.lstGetAthleteDrill;
        this.isShowLoader = false;
        if (this.lstActive.length > 0) {
          this.lstActive = this.lstActive;
          this.IsEmptyDrill = false;
        }
        else { this.IsEmptyDrill = true; }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  GetCompletedDrill() {
    this.isShowLoader = true;
    var DrillsLoad = { ActionFlag: "MyDrills", RefUserID: this.UserCode };
    this.service.DrillsAthletes(DrillsLoad).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstGetAthleteDrill = ret.GetAthleteDrillList;
        this.lstDrillLevel = ret.DrillLevelList; 
        // this.lstActive = this.lstGetAthleteDrill.filter(s => s.DrillStatus === "Shared");
        this.lstCompleted = this.lstGetAthleteDrill.filter(s => s.DrillStatus === "Completed");
        this.lstLibrary = this.lstGetAthleteDrill;
        this.isShowLoader = false;
        if (this.lstCompleted.length > 0) {
          this.lstCompleted = this.lstCompleted;
          this.IsEmptyDrill = false;
        }
        else { this.IsEmptyDrill = true; }

      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  GetCheckedDrillDetails(data) {
    this.IsDrillProgress = true;
    this.ChkDrillName = data.DrillName;
    this.ChkDrillID   = data.DrillID;
    this.ChkDrillStatus = data.Status;
    if (this.ChkDrillStatus == 'Active') 
    { 
      this.allComplete = false; 
      this.DrillProgress = "Start Drill";
    }
    else if(this.ChkDrillStatus == 'InProgress') 
    { 
      this.allComplete = false; 
      this.DrillProgress = "End Drill";
    }
    else if(this.ChkDrillStatus == 'Completed') 
    { 
      this.allComplete = true; 
      this.DrillProgress = "Drill Completed";
    }
  }

  DrillsAthletesStartEndDrill() {
    //this.isShowLoader = true;
    let submitType: string;
    if (this.ChkDrillStatus == 'Active') { submitType = "StartDrill"; }
    else { submitType = "EndDrill";  }
    var Drills = { ActionFlag: submitType, RefUserID: this.UserCode, DrillID: String(this.ChkDrillID) };
    this.service.DrillsAthletes(Drills).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        // this.isShowLoader = false;
        this.IsDrillProgress = false;
        $(".modal-backdrop").hide();
        $('.modal-backdrop').remove();
        this.isSuccessAlert = true;
        if (submitType == "StartDrill") { this.successAlert = "Drill Started successfully.."; }
        else { this.successAlert = "Drill Ended successfully.."; }
        this.ngOnInit();
        this.ChkDrillName = "";
        this.ChkDrillID   = "";
        this.ChkDrillStatus = "";
        this.IsEmptyDrill = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  ShowMyVideo(video) {
    this.isShowLoader = true;
    this.IsEmptyDrill = false;
    this.MarialsBind = [];
    var Drills = { DrillID: String(video.DrillID), ActionFlag: "GetDrillDetails", RefUserID: this.UserCode };
    this.service.DrillsAthletes(Drills).subscribe((ret: any = []) => {
      if (ret.Status == true) {
          this.lstDrillEdit = ret.DrillEditList;
          this.lstDrillEditStep = ret.DrillEditStepList;
          this.IsMyVideoShow = true;
          this.PreviewDrillName = this.lstDrillEdit[0].DrillName;
          this.DrillType = this.lstDrillEdit[0].DrillType;
          this.ThumbNail = this.lstDrillEdit[0].ThumbnailURL;
          this.VideoURL  = this.lstDrillEdit[0].VideoURL;
          this.CreatedTime = this.lstDrillEdit[0].CreatedTime;
          this.CreatedDate = this.lstDrillEdit[0].CreatedDate;
          this.CreatedBy   = video.SharedByUserName;
          this.VideoLength = video.VideoLength;
          var arrayMat = String(this.lstDrillEdit[0].Materials).split(",").map(String);
            for (var i = 0; i < arrayMat.length; i++) {
              this.MarialsBind.push(arrayMat[i]);
            }
          this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  GetLibraryDrill() {
    this.isShowLoader = true;
    this.IsShowSearch = false;
    this.IsEmptyDrill = false; 
    var DrillsLoad = { ActionFlag: "GetLibraryDetails", RefUserID: this.UserCode };
    this.service.DrillsAthletes(DrillsLoad).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstLibrary = ret.GetLibraryDrillList;

        // if (this.lstLibrary.length > 0) {
        //   this.lstLibrary = this.lstLibrary;
        //   this.IsEmptyDrill = false;
        // }
        // else { this.IsEmptyDrill = true; }

        this.lstLibrary = this.lstLibrary;
        this.IsEmptyDrill = false;

        this.IsShowLibrayVideo = false; this.IsShowLibrayBack  = false;
        this.IsShowLibraryType = true;  this.isShowLoader = false;     
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }  

  GetLibraryByType(type) {
    this.IsShowLibrayVideo = true;
    this.IsShowLibrayBack  = true;
    this.IsShowLibraryType = false;
    this.lstLibraryTypes = this.lstLibrary.filter(s => s.DrillTypeID === type);
    if (this.lstLibraryTypes.length > 0) {
        this.lstLibraryTypes = this.lstLibraryTypes;
        this.IsEmptyDrill = false;
        this.IsShowSearch = true;
    }
    else { 
      this.IsEmptyDrill = true; 
      this.IsShowSearch = true;
    }
  }



  WindowLoad(){
    window.location.reload();
  }
  



}
