<div class="page-container playertocoach-page" *ngIf="Pagetitle==''" >
    <div class="container">
        <div class="input-container">
            <div class="white">
                <div class="main-tab" rel="coachtoplayer">
                    <a href="javascript:void(0);" (click)="loadMyActive()" rel="active" [ngClass]="selectedTab=='ActiveSessions'?'select':''">Active Sessions</a>
                    <a href="javascript:void(0);" (click)="loadMyRequests()" rel="sent-request" [ngClass]="selectedTab=='ActiveRequests'?'select':''">Active Requests</a>
                    <a href="javascript:void(0);" (click)="loadCompletedSessions()"rel="completed" [ngClass]="selectedTab=='CompletedSessions'?'select':''">Completed</a>
                    <a href="javascript:void(0);" (click)="loadCoaches()" rel="new-request" [ngClass]="selectedTab=='LoadCoaches'?'select':''">New Request</a>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper no-input">
        <div class="container">
            <div class="output-container">
                <div class="coachtoplayer">
                    <div class="main-tab-container active" *ngIf="selectedTab=='ActiveSessions'" >
                        <ul>
                            <li *ngFor="let actses of ActiveSessions" (click)="loadPlayerwall(actses)">
                                <div class="playerlist-item">
                                    <div class="playerimg">
                                        <a href="javascript:void(0);" >
                                            <img src="{{actses.CoachImage}}">
                                        </a>
                                    </div>
                                    <div class="playerinfo">
                                        <h4>{{actses.CoachName}}</h4>
                                        <p class="coachoprofile-proficiency">Batsman</p>
                                    </div>
                                    <div class="playerstatus txt-center">
                                        <div class="remainingdays">
                                            <span>{{actses.DaysLeft}}</span> Day to Left
                                        </div>
                                    </div>
                                </div>
                            </li>
                           
                        </ul>
                    </div>
                    <div class="main-tab-container sent-request" *ngIf="selectedTab=='ActiveRequests'" >
                        <ul>
                            <li *ngFor="let actreq of ActiveRequests">
                                <div class="playerlist-item">
                                    <div class="playerimg">
                                        <a href="javascript:void(0);">
                                            <img src="{{actreq.CoachImage}}">
                                        </a>
                                    </div>
                                    <div class="playerinfo">
                                        <h4>{{actreq.CoachName}}</h4>
                                        <p class="player-requestdate">Request Date - {{actreq.RequestOn}}</p>
                                    </div>
                                    <div class="playerstatus request-action txt-center">
                                        <a (click)="PayNow(actreq)" href="javascript:void(0);" class="txt-accept">{{actreq.RequestStatus}}</a>
                                    </div> 
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    <div class="main-tab-container completed"  *ngIf="selectedTab=='CompletedSessions'">
                        <ul>
                            <li *ngFor="let comp of CompletedSessions">
                                <div class="playerlist-item">
                                    <div class="playerimg">
                                        <a href="javascript:void(0);" routerLink="/coachviewplayer">
                                            <img src="{{comp.CoachImage}}">
                                        </a>
                                    </div>
                                    <div class="playerinfo">
                                        <h4>{{comp.CoachName}}</h4>
                                        <p class="player-requestdate">Session Completed Date - 19-Sep-2021</p>
                                    </div>
                                    <div class="playerstatus request-action txt-center">
                                        <a href="javascript:void(0);" class="txt-ignore">Session Completed</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="main-tab-container new-request" *ngIf="selectedTab=='LoadCoaches'">
                        <div class="coachpadplayer-container">
                            <div class="search-container">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="coachpadplayercount">{{CoachCount}} Coaches</div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block search-txt" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon">
                                                <a href="javascript:void(0);">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.12 373.12"
                                                        width="24" height="24">
                                                        <path
                                                            d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                    </svg>
                                                </a>
                                            </mat-icon>
                                            <input matInput placeholder="Search Table">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2">
                                        <button mat-raised-button color="primary" class="mt5">Search</button>
                                    </div>
                                </div>
                            </div>
                            <ul class="row">
                                <li class="col-md-3" (click)="LoadCoachDetails(coaches)"
                                    *ngFor="let coaches of AvailableCoachesList|filter:SearchFilter;let ind=index;">
                                    <div class="profile-container">
                                        <a href="javascript:void(0);">
                                            <div class="profileimg">
                                                <img src="{{coaches.CoachImage}}">
                                            </div>
                                            <div class="profiledesc">
                                                <div class="pname">{{coaches.DisplayName}}</div>
                                                <div class="pproficiency">{{coaches.Specialization}}</div>
                                                <div class="prating">
                                                    <!-- <span class="select">
                                                        <i class="fa fa-star"></i>
                                                    </span>
                                                    <span class="unselect">
                                                        <i class="fa fa-star-o"></i>
                                                    </span> -->
                                                    <!-- <ng-template #t[{{ind}}] let-fill="fill">
                                                        <span class="gift" [class.full]="fill === 100">
                                                          <span class="half" [style.width.%]="fill">&#9734;</span>
                                                        </span>
                                                      </ng-template> -->
                                                      <ng-template #t[{{ind}}] let-fill="fill">
                                                        <span class="star" [class.full]="fill === 100">
                                                        <span class="half" [style.width.%]="fill">&#9734;</span>
                                                        </span>
                                                    </ng-template>

                                                      <ngb-rating 
                                                      [(rate)]="coaches.AVGRating" 
                                                      [starTemplate]="t" 
                                                      [readonly]="true" 
                                                      max="5">
                                                      </ngb-rating>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div *ngIf="Pagetitle=='CoachProfile'"><app-coach-profile (buttonClicks)="Back($event)"></app-coach-profile></div>
<div *ngIf="Pagetitle=='PlayerWall'"><app-coachpad-playerwall  (buttonClick)="Back($event)"></app-coachpad-playerwall>