export class videoTag
{
    ActionFlag?:string;
    RefUserID?:string;
    SessionID?:string;
}
export class engineTag
{
    defensive? :string;
    aggressive?:string;
    aggressiveShotId?:string;
    defensiveShotId? :string;
    fastBowlTypeID?:string;
    spinBowlTypeID?:string;
    footmoveId?:string;
    footmoveIdPopUp?:string;
    uncomfortID?:string;
    beatenID?:string
} 