import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../../masters-service.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
@Component({
  selector: 'app-academic-player-sesssions',
  templateUrl: './academic-player-sesssions.component.html',
  styleUrls: ['./academic-player-sesssions.component.css']
})
export class AcademicPlayerSesssionsComponent implements OnInit {
  SessionForm: FormGroup;
  config: UploadConfig;
  /** The current percent to be displayed */
  percent!: number;
  uploadedsize!: string;
  isUploading: boolean = false;
  public UserCode: string = "1";
  public SessionTypes: any[];
  public VideoViewTypes: any[];
  public SessionsList: any[];
  public CoachList: any[];
  public SelectedCoachList: any[];
  public MessageList: any[];

  public skill: string;
  public viewType: string;
  public sessionName: string;
  public sessionDate: Date;
  public videoURL: string;
  public thumbnailURL: string;
  public selectedCoach: string = "";
  public openedShareid: string;
  public filterStartDate: string;
  public filterEndDate: string;
  public FilterSessionTypeID: string;
  public FilterViewID: string;
  public videowidth: number;

  myControl = new FormControl();
  options: User[] = [
    { name: '2020-2021' },
    { name: '2019-2020' },
    { name: '2018-2019' }
  ];
  filteredOptions!: Observable<User[]>;

  public commentsFeed: boolean = false;

  public showMonthRange: boolean = false;
  public showDateRange: boolean = true;
  public advFilters: boolean = false;
  public selectedfilesize: string;
  public durations: number;
  public Videoduration: string;
  public currentFile: File;
  public selectedFile: string;
  public videoLoaded: boolean = false;
  public videoSubscription: boolean = true;

  public IsShowComments: boolean = false;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  public userType: string;
  constructor(private utility: UtilitiesService, private http: HttpClient, private blob: BlobService,
    private service: MastersServiceService, public datepipe: DatePipe, private formBuilder: FormBuilder, private _host: ElementRef,
    private router: Router) {
    this.currentFile = null;
    this.config = null;
    this.percent = 0;
  }



  public SearchFilter: string;
  public IsShowUpload: boolean = true;

  ngOnInit(): void {
    this.IsShowUpload = true;
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.userType = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberID);
    this.utility.pageLoadScript();
    this.SessionLoad();
    this.videoSubscription = true;
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
    this.SessionForm = this.formBuilder.group({
      n_sessionName: ['', Validators.required],
      n_sessionDate: ['', Validators.required],
      n_skill: ['', Validators.required],
      n_viewType: ['', [Validators.required]]
    });
    $('#elemId').width();
  }

  SessionLoad() {
    this.service.AthleteSessionPageLoad({ ActionFlag: "Load", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      this.percent = 0;
      this.videoLoaded = false;
      this.SessionTypes = ret.SessionTypes;
      this.VideoViewTypes = ret.VideoViewTypes;
      this.SessionListLoad();
    })
  }

  public uploadCount: number = 0;
  public IsEmptyVideos: boolean = false;
  SessionListLoad() {
    this.service.CoachSessionList({ ActionFlag: "MySessionsCoach", RefUserID: this.UserCode,AtheleteRefUserID: this.UserCode}).subscribe((ret: any = []) => {
      this.SessionsList = ret.SessionsList;

      if (this.SessionsList.length > 0) {
        this.SessionsList = this.SessionsList;
        this.IsEmptyVideos = false;
        for (var i = 0; i < this.SessionsList.length; i++) {
          this.uploadCount = i + 1;
        }
      }
      else { this.IsEmptyVideos = true; }
    })
  }

  ApplySessionFilters() {
    this.service.CoachSessionList({ ActionFlag: "MySessionsCoach", RefUserID: this.UserCode, FilterSessionTypeID: this.selsesTypes.join(','), FilterViewID: this.selsesViews.join(','), FromDate: this.filterStartDate, ToDate: this.filterEndDate }).subscribe((ret: any = []) => {
      this.SessionsList = ret.SessionsList;
      if (this.SessionsList.length > 0) {
        this.SessionsList = this.SessionsList;
        this.IsEmptyVideos = false;
      }
      else { this.IsEmptyVideos = true; }
    })
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  changeMonthDate() {
    this.showMonthRange = !this.showMonthRange;
    this.showDateRange = !this.showDateRange;
  }

  showCommentbox(id, SessionID) {
    this.IsShowComments = !this.IsShowComments;
    this.openedShareid = id;
    this.service.AthleteSessionChatting({ ActionFlag: "ReceiveAthelete", RefUserID: this.UserCode, SessionID: String(SessionID) }).subscribe((ret: any = []) => {
      this.MessageList = ret.MessageList;
    })
  }

  hideCommentbox() {
    this.IsShowComments = false;
  }

  showFilters() {
    if (this.advFilters == true)
      this.advFilters = false;
    else
      this.advFilters = true;
  }

  showSharebox(id, session) {
    //this.showhide(id);
    this.openedShareid = id;
    this.service.AthleteSessionSharing({ ActionFlag: "GetCoach", RefUserID: this.UserCode, SessionID: String(session) }).subscribe((ret: any = []) => {
      this.CoachList = ret.CoachList;
      this.SelectedCoachList = this.CoachList.filter(s => s.IsChecked === 1);
    })
  }

  shareVideotoCoach(ses, Coach) {
    this.service.AthleteSessionSharing({
      ActionFlag: "Share", RefUserID: this.UserCode, SessionID: String(ses)
      , CoachRefUserID: String(Coach.CoachRefUserID), RequestID: String(Coach.RequestID)
    }).subscribe((ret: any = []) => {
      // this.showhide(this.openedShareid);
      this.selectedCoach = "";
      this.showSharebox('share' + ses, ses);
    })
  }

  sendMessage(msgid) {
    var x = document.getElementById('message' + msgid).innerText;
    if (x != "" && x != null) {
      this.service.AthleteSessionChatting({ ActionFlag: "SendAthelete", RefUserID: this.UserCode, SessionID: String(msgid), MsgContent: x }).subscribe((ret: any = []) => {
        this.MessageList = ret.MessageList;
        document.getElementById('message' + msgid).innerText = "";
      })
    }
    else {
      this.isErrorAlert = true;
      this.errorAlert = "Commants should not be empty..!!";
    }
  }

  classFlag: boolean = false;
  public selsesTypes: any = [];
  public selsesViews: any = [];
  menuToggle(event: any, val, type) {
    event.target.classList.toggle('active');
    if (type == 'type') {
      if (event.target.classList.value == 'active') {
        this.selsesTypes.push(val);
      }
      else {
        var x = this.selsesTypes.indexOf(val);
        this.selsesTypes.splice(x, 1);
      }
    }
    else if (type == 'view') {
      if (event.target.classList.value == 'active' || event.target.classList.value == 'select active') {
        this.selsesViews.push(val);
      }
      else {
        var x = this.selsesViews.indexOf(val);
        this.selsesViews.splice(x, 1);
      }
    }
    // this.listAddAthleteGroup.splice(x,1); 
  }

  onVideoChange(event) {
    if (this.uploadCount < 50) {
      var attachedFile = <File>event.target.files[0];
      this.currentFile = attachedFile;
      var splitted = this.currentFile.type;
      // var fileType = splitted.split("/", 3);  && "video/mov" && "video/mpeg" 
      if (this.currentFile.type == "video/mp4") {
        let duration: any;
        this.showVideouploadstatus();
        this.selectedFile = URL.createObjectURL(attachedFile);
        document.getElementById("myVideo").setAttribute("src", URL.createObjectURL(attachedFile));
        this.selectedfilesize = (attachedFile.size / (1024 * 1024)).toFixed(2) + 'MB';

      }
      else {
        this.currentFile = null;
        this.isErrorAlert = true;
        this.errorAlert = "File format not support..!!";
      }
    }
    else {
      this.isWarningAlert = true;
      this.warningAlert = "You have exceeded your upload limit..!!";
    }

  }

  onMetadata(e, video) {
    // this.videowidth = 0;
    this.durations = video.duration;
    this.Videoduration = Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2) + ' Mins';
    this.videowidth = video.width;

    var dura = (Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2));
    if (this.durations < 480) {
      this.videoLoaded = true;
    }
    else {
      this.Videoduration = "";
      this.selectedFile = "";
      this.videoLoaded = false;
      this.isWarningAlert = true;
      this.warningAlert = "Video duration should be 08:00 Min (or) Below..!!";
    }
  }

  pad(n, width) {
    var z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  generateBlobUrl(
    params: UploadParams,
    filename: string,
    useAzureStorageEmulator = false,
    azureStorageEmulatorBaseUrl = '') {
    const url = useAzureStorageEmulator ? azureStorageEmulatorBaseUrl : `https://${params.storageAccount}.blob.core.windows.net`
    return `${url}/${params.containerName}/${filename}`
  }

  private prependZeros(number, length) {
    let str = '' + number
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }

  private uploadFileInBlocks(reader, state) {
    if (!state.cancelled) {
      if (state.totalBytesRemaining > 0) {
        const fileContent = state.file.slice(state.currentFilePointer, state.currentFilePointer + state.maxBlockSize)
        const blockId = state.blockIdPrefix + this.prependZeros(state.blockIds.length, 6)
        state.blockIds.push(btoa(blockId))
        reader.readAsArrayBuffer(fileContent)
        state.currentFilePointer += state.maxBlockSize
        state.totalBytesRemaining -= state.maxBlockSize
        if (state.totalBytesRemaining < state.maxBlockSize) {
          state.maxBlockSize = state.totalBytesRemaining;
        }
      } else {
        this.commitBlockList(state);

      }
    }
  }

  private commitBlockList(state) {
    const uri = state.fileUrl + '&comp=blocklist'
    const headers = new HttpHeaders({ 'x-ms-blob-content-type': state.file.type, 'Accept-Ranges': 'bytes', 'x-ms-version': '2013-08-15' })
    let requestBody = '<?xml version=\'1.0\' encoding=\'utf-8\'?><BlockList>'
    for (let i = 0; i < state.blockIds.length; i++) {
      requestBody += '<Latest>' + state.blockIds[i] + '</Latest>'
    }
    requestBody += '</BlockList>'

    this.http.put(uri, requestBody, { headers: headers, responseType: 'text' })
      .subscribe(_elem => {
        if (state.complete) {
          state.complete();
          this.service.AthleteSessionCreation({
            ActionFlag: "Create", SessionName: this.sessionName, SessionDate: this.datepipe.transform(this.sessionDate, 'yyyy-MM-dd')
            , SessionTypeID: String(this.skill), VideoViewID: String(this.viewType), VideoURL: state.baseUrl, IsCloudUpload: "1",
            //VideoLength: String(this.durations), 
            VideoLength: String(this.Videoduration),
            UploadDevice: "Web"
            , CloudURL: state.baseUrl, RefUserID: this.UserCode, ThumbnailURL: this.thumbnailURL
          }).subscribe((ret: any = []) => {
            // alert("Completed");
            this.isSuccessAlert = true;
            this.successAlert = "Session uploaded successfully.."
            // this.SessionLoad();
            this.ngOnInit();
            this.showVideoGoupload();
            this.clearSession();
          })
        }
      }, err => {
        console.log({ error: err })
        if (state.error) {
          state.error(err)
        }
      })
  }

  private initializeState(config: UploadConfig) {
    let blockSize = BlobService.DefaultBlockSize
    if (config.blockSize) {
      blockSize = config.blockSize
    }
    let maxBlockSize = blockSize
    let numberOfBlocks = 1
    const file = config.file
    const fileSize = file.size
    if (fileSize < blockSize) {
      maxBlockSize = fileSize
    }
    if (fileSize % maxBlockSize === 0) {
      numberOfBlocks = fileSize / maxBlockSize
    } else {
      numberOfBlocks = fileSize / maxBlockSize + 1
    }

    return {
      maxBlockSize: maxBlockSize, // Each file will be split in 256 KB.
      numberOfBlocks: numberOfBlocks,
      totalBytesRemaining: fileSize,
      currentFilePointer: 0,
      blockIds: new Array(),
      blockIdPrefix: 'block-',
      bytesUploaded: 0,
      submitUri: null,
      file: file,
      baseUrl: config.baseUrl,
      sasToken: config.sasToken,
      fileUrl: config.baseUrl + '?' + config.sasToken,
      progress: config.progress,
      complete: config.complete,
      error: config.error,
      cancelled: false
    }
  }

  upload(config: UploadConfig) {
    this.isUploading = true;

    const state = this.initializeState(config)
    const reader = new FileReader()
    reader.onloadend = (evt: any) => {
      if (evt.target.readyState === 2 && !state.cancelled) {
        const uri = state.fileUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1]
        const requestData = evt.target.result
        const requestData2 = new Uint8Array(evt.target.result)
        const headers = new HttpHeaders({ 'x-ms-blob-type': 'BlockBlob', 'Content-Type': 'application/octet-stream' })
        this.http.put(uri, requestData, { headers: headers, responseType: 'text' })
          .subscribe(_elem => {
            state.bytesUploaded += requestData2.length
            const percentComplete = Math.round((state.bytesUploaded / state.file.size) * 1000) / 10
            if (state.progress) {
              state.progress(percentComplete)
            }
            if (this.isuploadcancelled)
              state.cancelled = true;
            this.uploadFileInBlocks(reader, state)
          }, err => {
            console.log({ error: err })
            if (state.error) {
              state.error(err)
            }
          })
      }
    }
    this.uploadFileInBlocks(reader, state)
    return {
      cancel: () => {
        state.cancelled = true
      }
    }
  }


  today = new Date();

  uploadbutton() {
    this.isuploadcancelled = false;
    var canvas = <HTMLCanvasElement>document.getElementById('canvas');
    var video = <HTMLCanvasElement>document.getElementById('myVideo');
    // var img =  <HTMLCanvasElement> document.getElementById('imgId');
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // var dataURL = canvas.toDataURL();
    // img.setAttribute('src', dataURL);     
    // alert(canvas.toDataURL());
    if (this.SessionForm.invalid) {
      return;
    }
    else {
      if (this.currentFile !== null) {
        this.IsShowUpload = false;
        this.service.UploadFile({ data: canvas.toDataURL(), name: "image/png" }).subscribe((ret: any = []) => {

          var splitted = this.currentFile.type;
          var fileType = splitted.split("/", 3);  //&& "video/mov" && "video/mpeg" 
          var now = new Date().getTime();
          var filename = this.datepipe.transform(this.today, 'yyyy-MM-dd') + now + '-' + this.randomid(10);
          var VideoFileName = this.currentFile.name.replace(this.currentFile.name, filename) + '.' + fileType[1];

          this.thumbnailURL = ret.name;
          const baseUrl = this.generateBlobUrl(Config, VideoFileName)
          this.config = {
            baseUrl: baseUrl,
            blockSize: 1024 * 1024 * 2,
            sasToken: Config.sas,
            file: this.currentFile,
            complete: () => {
              console.log('Transfer completed !')
            },
            error: (err) => {
              console.log('Error:', err)
            },
            progress: (percent) => {
              this.percent = percent, this.uploadedsize = (percent * (this.currentFile.size / (1024 * 1024 * 100))).toFixed(2)
            }
          }
          this.upload(this.config);
        })

      }
    }
  }

  randomid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  IsUploadShow() { this.videoLoaded = false; }

  GetAnalysisDetails(data) {
    sessionStorage.setItem("sessionId", String(data.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(data.CloudURL));
    sessionStorage.setItem("sessionPlayerName", String(data.PlayerName));
    sessionStorage.setItem("sessionPlayerRole", String(data.PlayerRoleName));
    sessionStorage.setItem("sessionVideoDate", String(data.SessionDate));
    sessionStorage.setItem("sessionProfileImage", String(data.ProfileImage));
    sessionStorage.setItem("sessionsThumbnail", String(data.ThumbnailURL));
    sessionStorage.setItem("SessionTypeName", String(data.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(data.VideoViewName));
    sessionStorage.setItem("SessionName", String(data.SessionName));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));
    sessionStorage.setItem("sessionFlag", String("2"));
    this.service.VideoSessionDetails = data;
    this.service.lastPage = "myvideos";
    this.router.navigate(['/video-analyse']);
  }

  public videosubscription: boolean = false;
  public videoformfields: boolean = false;
  public videouploadstatus: boolean = false;
  public videoupload: boolean = false;
  public videouploadsession: boolean = true;
  showVideouploadsession() {
    this.videouploadsession = true;
    this.videoupload = false;
    this.videouploadstatus = false;
    this.videoformfields = false;
    this.videosubscription = false;
  }
  showVideoupload() {
    if (this.SessionForm.invalid) {
      return;
    }
    else {
      this.videouploadsession = false;
      this.videoupload = true;
      this.videouploadstatus = false;
      this.videoformfields = false;
      this.videosubscription = false;
    }
  }
  showVideouploadstatus() {
    this.videouploadsession = false;
    this.videoupload = false;
    this.videouploadstatus = true;
    this.videoformfields = false;
    this.videosubscription = false;
  }
  showVideoFormfields() {
    this.videouploadsession = false;
    this.videoupload = false;
    this.videouploadstatus = false;
    this.videoformfields = true;
    this.videosubscription = false;
  }
  showVideosubscription() {
    this.videouploadsession = false;
    this.videoupload = false;
    this.videouploadstatus = false;
    this.videoformfields = false;
    this.videosubscription = true;
  }

  showVideoGoupload() {
    this.videouploadsession = true;
    this.videoupload = false;
    this.videouploadstatus = false;
    this.videoformfields = false;
    this.videosubscription = false;
  }
  isuploadcancelled: boolean = false;
  cancel(event) {
    this.isuploadcancelled = true;
    this.ngOnInit();
    this.showVideoGoupload();
    this.clearSession();
  }
  clearSession() {
    this.sessionName = "";
    this.sessionDate = new Date();
    this.viewType = "";
    this.skill = "";
  }

}

export interface User { name: string; }
const Config: UploadParams = {
  // sas: 'sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-05-31T14:50:55Z&st=2021-04-01T06:50:55Z&spr=https,http&sig=Pp4Dg4IeL2gfPLbj%2BdedKfpscdxOXgRfWfkCQcPF%2FMk%3D',
  // storageAccount: 'prodlivedata2020',
  // containerName: 'livecontent' 

  // -- Updated 05-05-2021 sas token -- //
  sas: 'sp=racwdl&st=2022-05-06T11:14:35Z&se=2024-05-06T19:14:35Z&sv=2020-08-04&sr=c&sig=7LqV5ZH%2B5QfucxrojJJ12tjqiKBSw5K59eeOiPlbR%2F8%3D',
  storageAccount: 'splcms',
  containerName: 'cricpass'
};
