import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UtilitiesService } from '../utilities.service';

@Injectable({ providedIn: "root" })
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    // this.loginList = JSON.parse(sessionStorage.getItem('loginresponse'));
    // if (this.loginList != null) {
    let loginValid = JSON.parse(sessionStorage.getItem('loginresponse'));
    if (loginValid == true) {
      this.loggedIn.next(true);
    }
    // }
    return this.loggedIn.asObservable();
  }

  constructor(private router: Router, public utility: UtilitiesService) { }

  public InputData: Inputs[] = [
    { LoginName: "", Password: "", ActionFlag: "Validate" }
  ]
  public loginList: any = [];
  //public ValidationRes:boolean;

  login(InputData: boolean) {


    //  let loginValid = true;
    if (InputData == true) {
      this.loggedIn.next(true);
      if (String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID) == '6') {
        this.router.navigate(['/academy-dashboard']);
      }
      else if (String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID) == '5') {
        this.router.navigate(['/dashboard']);
      }
      else if (String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID) == '1') {
        this.router.navigate(['/athlete-dashboard']);
      }
      else if (String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID) == '2') {
        this.router.navigate(['/coach-dashboard']);
      }
      else if (String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID) == '7') {
        this.router.navigate(['/finance-dashboard']);
      }
      else {
        this.router.navigate(['/dashboard']);
      }
    }
    else {
      this.loggedIn.next(false);
      this.utility.openSnackBar('Invalid Login...');
    }
  }

  logout() {
    this.loggedIn.next(false);

    this.router.navigate(['/landing']);
  }

}


export interface User {
  userName: string;
  password: string;
}

export interface Inputs {
  LoginName: string;
  Password: string;
  ActionFlag: string;
}