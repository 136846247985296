<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container admin-dashboard-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center"></div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card-profile-container box-shadow br-5">
                                    <div class="card-profile-header tr-5">
                                        <div class="profile-picture">
                                            <img src="{{ProfileImage}}" alt="Profile Picture"
                                                onerror="this.src='assets/images/no_user.png';" >
                                        </div>
                                    </div>
                                    <div class="card-profile-body">
                                        <div class="txt-center">
                                            <div class="profile-name" style="text-transform:capitalize;">{{AthleteName}}
                                            </div>
                                            <div class="profile-age" style="text-transform:capitalize;">{{DateOFBirth}}
                                            </div>
                                            <div class="profile-association" style="text-transform:capitalize;">
                                                {{AssociationName}}</div>
                                        </div>
                                        <div class="proficiency-tbl">
                                            <table>
                                                <tr>
                                                    <td colspan="2" style="width:100%;" class="txt-center">
                                                        <div class="ico-proficiency">
                                                            <img src="assets/images/{{PlayerRoleImage}}.png" alt="All Rounder" 
                                                             onerror="this.src='assets/images/no-image40.png';" >
                                                        </div>
                                                        <div class="profile-proficiency">{{PlayerRole}}</div>
                                                    </td>
                                                </tr>
                                                <tr> 
                                                    <td class="txt-center" style="width: 50%;">
                                                        <div class="ico-proficiency">
                                                            <img src="assets/images/{{PlayerBattStyelImg}}.png" alt="Batsman"
                                                             onerror="this.src='assets/images/no-image40.png';" >
                                                        </div>
                                                        <div class="profile-proficiency">{{BattingStyle}}</div>
                                                    </td>
                                                    <td class="txt-center" style="width: 50%;" *ngIf ="BowlingStyle != 'NULL'" >
                                                        <div class="ico-proficiency">
                                                            <img src="assets/images/{{BowSpecImage}}.png" alt="Bowler"
                                                             onerror="this.src='assets/images/no-image40.png';" >
                                                        </div>
                                                        <div class="profile-proficiency">{{BowlingStyle}}</div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="profile-data">
                                            <ul>
                                                <li>
                                                    <div class="data-count">{{TotalVideos}}</div>
                                                    <div class="data-name">Videos</div>
                                                </li>
                                                <li>
                                                    <div class="data-count">{{TotalUploads}}</div>
                                                    <div class="data-name">Uploads</div>
                                                </li>
                                                <li>
                                                    <div class="data-count">{{TotalReviewed}}</div> 
                                                    <div class="data-name">Reviews</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <div class="card-profile-progress">
                                            <div class="monthly-updates">Monthly Uploads</div>
                                            <div class="prg-container">
                                                <div class="prg-rating">0/8</div>
                                                <div class="prg-ratio">
                                                    <mat-progress-bar mode="determinate" value="0"></mat-progress-bar>
                                                </div>
                                                <div class="prg-percentage">0%</div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="main-tab" rel="athlete-db"> 
                                        <a href="javascript:void(0);" rel="schedules" class="select">Schedules</a>
                                        <a href="javascript:void(0);" rel="myuploads" (click) = "MyUploadClick()"> My Uploads </a>
                                        <a href="javascript:void(0);" rel="mydrills"  (click) = "AtheleteMyDrillsLoad()"> My Drills </a>
                                    </div>
                                    <div class="athlete-db">

                                        <div class="main-tab-container schedules" style="display: block;">
                                            <full-calendar *ngIf='calendarVisible' [options]='calendarOptions'>
                                            </full-calendar>
                                        </div>

                                        <div class="main-tab-container myuploads" style="display: none;">
                                            <div class="row"  *ngIf = "IsVideoShow"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Session-640.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="video-list-container">
                                                <div class="video-list" *ngFor="let myUpload of AthleteMyUploadsList;">
                                                    <div class="video-thumbnail br-5">
                                                        <a href="javascript:void(0);" data-toggle="modal" data-target="#uploadvideopreview" 
                                                        (click) = "GetSessionDetails(myUpload)"  >
                                                                <img src="{{myUpload.ThumbnailURL}}" class="img-fluid">
                                                        </a>
                                                        <!-- <div>
                                                            <video id="my-video" class="video-js" controls poster="{{myUpload.ThumbnailURL}}"
                                                                preload="none" data-setup="{}" style="width: 100%;"  
                                                                disablePictureInPicture controlsList="nodownload" >
                                                                <source src="{{myUpload.CloudURL}}" type="video/mp4">
                                                            </video>
                                                        </div> -->
                                                        <div class="video-time">{{myUpload.VideoLength}}</div>
                                                    </div>
                                                    <div class="video-list-details">
                                                        <div class="video-type">
                                                            <h2>{{myUpload.SessionName}}</h2>
                                                            <!-- <a href="javascript:void(0);" class="ico-menu"></a> -->
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">  
                                                                <div class="video-left txt-center">
                                                                    <div class="video-type-view">
                                                                        <span>{{myUpload.SessionTypeName}}</span>
                                                                        <span>{{myUpload.VideoViewName}}</span>
                                                                        <p>{{myUpload.SessionDate}} || {{myUpload.SessionTime}}</p>
                                                                    </div>
                                                                    <div class="video-tag toggle-class">
                                                                        <a *ngIf = "myUpload.IsTagged!='0'" class="video-tagged select" href="javascript:void(0);">Tagged</a>
                                                                        <a *ngIf = "myUpload.IsReviewed!='0'"  class="video-reviewed" href="javascript:void(0);">Reviewed</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="video-right txt-center">
                                                                    <div class="video-views">
                                                                        <a href="javascript:void(0);" class="compare"
                                                                          (click) = "GetCompareVideos(myUpload)">Compare</a>
                                                                        <a href="javascript:void(0);" class="analyse" 
                                                                           (click)="GetAnalysisDetails(myUpload)" >Analyse</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="main-tab-container mydrills" style="display: none;">
                                            <div class="row" *ngIf = "IsEmptyDrills"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="video-list-container">
                                                <div class="video-list"  *ngFor="let myDrill of AthleteMyDrillsList;" >
                                                    <div class="video-thumbnail br-5">
                                                        <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                           (click) = "ShowMyVideo(myDrill)" >
                                                            <img src="{{myDrill.ThumbnailURL}}" class="img-fluid">
                                                        </a>
                                                        <!-- <div> <video id="my-video" class="video-js" controls poster="{{myDrill.ThumbnailURL}}"
                                                                preload="none" data-setup="{}" style="width: 100%;"  
                                                                disablePictureInPicture controlsList="nodownload" >
                                                                <source src="{{myDrill.VideoURL}}" type="video/mp4">
                                                               </video> </div>    -->
                                                        <div class="video-time">{{myDrill.VideoLength}}</div>
                                                    </div>
                                                    <div class="video-list-details">
                                                        <div class="video-type">
                                                            <h2 style="text-transform:capitalize;" >{{myDrill.DrillName}}</h2>
                                                            <a class="ico-menu"></a>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="video-left txt-left">
                                                                    <div class="video-type-view">
                                                                        <span style="text-transform:capitalize;" >{{myDrill.SessionTypeName}}</span>
                                                                        <p>{{myDrill.CreatedDate}} || {{myDrill.CreatedTime}} </p>
                                                                    </div>
                                                                    <div class="video-tag toggle-class">
                                                                        <a class="video-user" style="text-transform:capitalize;"
                                                                            href="javascript:void(0);">{{myDrill.CreatedBy}}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="video-right txt-right">
                                                                    <div  class="mydrill-status" 
                                                                        [ngClass] = "{'in-progress':myDrill.DrillStatus=='InProgress',
                                                                                      'completed':myDrill.DrillStatus=='Completed',
                                                                                      'drill-active':myDrill.DrillStatus=='Active'}"  >
                                                                            {{myDrill.DrillStatus}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Dashboard</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf = "isErrorAlert" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
                <div  class="alcenter" > 
                 <h4>Dashboard</h4>
                <p>{{errorAlert}}</p> 
               </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"   (click)="isErrorAlert=false" >Ok</button>
        </div>
   </div>
</div> 


<!-- Video Preview -- Drills -->
<div class="modal fade" id="videopreview"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle" style="text-transform:capitalize;"  >{{PreviewDrillName}} 
                    <span class="skill-type" style="text-transform:capitalize;"  >{{DrillType}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                    <div class="drills-popup-video">
                        <div class="vprofile-video">
                            <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                src="{{VideoURL}}" data-setup="{}"  disablePictureInPicture controlsList="nodownload" type="video/mp4" >
                            </video>
                            <!-- <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                    data-setup="{}"  disablePictureInPicture controlsList="nodownload" >
                                <source src="{{VideoURL}}" type="video/mp4" /> </video> -->
                        </div>   
                        <div class="drill-user-date">
                            <div class="drill-user">
                                <a href="javascript:void(0);" class="video-user"
                                style="text-transform:capitalize;"  >{{CreatedBy}}</a>
                            </div>
                            <div class="time">
                                <p>{{CreatedDate}} || {{CreatedTime}}</p>
                            </div>   
                        </div>                            
                    </div>
                    <div class="drills-description">    
                        <div class="title">Instruction</div>
                        <ul class="instruction mb15"  >
                            <li style="text-transform:capitalize;" 
                              *ngFor="let stp of lstDrillEditStep;let ind=index;">Step {{ind + 1}} : {{stp.StepText}}
                            </li>
                        </ul>
                        <div class="title">Required Items</div>
                        <ul class="required"   >
                            <li style="text-transform:capitalize;" *ngFor="let val of MarialsBind"  >{{val}}</li>
                        </ul>     
                    </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Preview -- Session -->
<div class="modal fade" id="uploadvideopreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px;"> 
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{SessionName}} 
                    <span class="skill-type">{{SessionView}}</span>
                    <span class="skill-type">{{SessionType}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body txt-center">
                    <div class="drills-popup-video">
                        <video id="my-video" class="video-js" controls poster="{{SessionThumbnailURL}}" preload="none" width="100%"
                            src="{{SessionVideoURL}}" data-setup="{}"  disablePictureInPicture controlsList="nodownload" type="video/mp4" >
                        </video>
                        <div class="drill-user-date txt-left">
                            <div class="drill-user">
                                <a href="javascript:void(0);" class="video-user">{{SessionUserName}}</a>
                            </div>
                            <div class="time">
                                <p>{{SessionDate}} || {{SessionTime}}</p>
                            </div>
                        </div>                            
                    </div>
            </div>
        </div>
    </div>
</div>



<div class="overlay" *ngIf="isSchedule">
    <div class="schedule-preview">
        <div class="schedule-title"  style="text-transform:capitalize;" >{{EventName}} 
            <a href="javascript:void(0);" class="remove-list" (click)="hideSchedule();"></a>
        </div>
        <div class="schedule-body">
            <div class="schedule-date txt-center">
                <h4>{{EventDay}}</h4>
                <span  style="text-transform:capitalize;" >{{EventMonth}}</span>
            </div>
            <div class="schedule-txt txt-center">
                <p>{{EventType}}</p>
            </div>
            <div class="schedule-txt">
                <img src="assets/images/ico-schedule-time.png">
                <p>{{EventStart}} - {{EventEnd}}</p>
            </div>
            <div class="schedule-txt">
                <img src="assets/images/ico-schedule-venue.png">
                <p  style="text-transform:capitalize;" >{{EventVenue}}</p>
            </div>
            <div class="schedule-txt comment">
                <img src="assets/images/ico-schedule-comment.png">
                <p  style="text-transform:capitalize;" >{{EventComments}}</p>
            </div>
            <div class="schedule-status txt-center">
                <span style="background-color: #027f08;"></span> Active
            </div>
        </div>
    </div>
</div>