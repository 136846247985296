<div class="page-container my-videos-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="upload-videos-container box-shadow br-5">
                            <!-- <div class="title">Video Upload <span class="remove-list"></span></div>
                            <div class="upload-container">
                                <div class="video-upload mb10">
                                    <div class="dropzone">
                                        <div class="text-wrapper">
                                            <div class="centered">
                                                <input type="file" name="file" id="file" multiple>
                                                <label for="file"><span class="textLink">Select your file</span> or Drop it here!</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="title">Player Details</div>
                            <div class="program-player">
                                <div class="pgm-picture"><img src="assets/images/no_user.png"></div>
                                <figcaption>
                                    <div class="name">Kane Williamson</div>
                                    <div class="proficiency">
                                        <span>All Rounder</span>
                                        <span>Right Hand Bat</span>
                                        <span>Right Arm Fast Medium</span>
                                        <span>15 Years and 15 days</span>
                                    </div>
                                </figcaption>
                            </div>
                            <div class="upload-container upload-subs mb10">
                                <ul class="pgm-card">
                                    <li class="select">
                                        <div class="pgm-count">08</div>
                                        <div class="pgm-name txt-right">
                                            <img src="assets/images/session.png">
                                            <p>Seesion Review</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="pgm-count">04</div>
                                        <div class="pgm-name txt-right">
                                            <img src="assets/images/drills.png">
                                            <p>Assigned Drills</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="pgm-count">04</div>
                                        <div class="pgm-name txt-right">
                                            <img src="assets/images/assigntoplaylist.png">
                                            <p>Assigned Playlist</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="pgm-count">02</div>
                                        <div class="pgm-name txt-right">
                                            <a href="javascript:void(0)" (click)="isSchedule();">
                                                <img src="assets/images/assigntoplaylist.png">
                                                <p>Meeting schedule</p>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="video-container">
                            <div class="player-search">
                                <div class="row">
                                    <div class="col-md-6">
                                        <a href="javascript:void(0);" (click)="Back()" class="theme-button">Back</a>
                                        <!-- <a href="javascript:void(0);" routerLink="/play-groups" class="back"><img src="assets/images/back02.png">Back to Groups</a> -->
                                    </div>
                                    <div class="col-md-6 txt-right">
                                        <a href="javascript:void(0);" class="sorting">Sort by : <strong>Latest</strong><img src="assets/images/sorting.png"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="video-item br-5">
                                <div class="vprofile-header">
                                    <a href="javascript:void(0);" class="vprofile-left">
                                        <div class="vprofile">
                                            <img src="assets/images/no_user.png"> 
                                        </div>
                                        <div class="vprofile-info">
                                            <div class="vprofile-name">Yuzvendra Chahal</div>
                                            <div class="vprofile-type">All Rounder</div>
                                            <div class="vprofile-time">15<sup>th</sup> Feb 2020 <span>5:30PM</span></div>
                                        </div>
                                    </a>
                                    <div class="vprofile-more txt-right">
                                          <div class="dropdown">
                                            <a href="javascript:void(0);" class="ico-menu dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Copy Link</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                                              </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="vprofile-video">
                                    <video id="my-video" class="video-js" controls preload="none" width="640" height="264" poster="https://i.ytimg.com/vi/MFSjLx-LkrU/maxresdefault.jpg" data-setup="{}">
                                        <source src="assets/videos/GANGA-SRIDHAR-BATTING-FRONT-VIEW.mp4" type="video/mp4">
                                    </video>
                                </div>
                                <div class="vprofile-session">
                                    <div class="session">
                                        Session Name : <span>Bowling</span> <span>Side View</span>
                                    </div>  
                                    <div class="feed-info txt-right">
                                        <a href="javascript:void(0);">4 Fist Bump</a>
                                        <a href="javascript:void(0);">16 Comments</a>
                                    </div>
                                </div>
                                <div class="vprofile-feed">
                                    <div class="row">
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="fist-bump" matRipple>Fist Bump</a> </div>
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="comments" (click)="showCommentbox()" matRipple>Comments</a> </div>
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="fdanalyse" matRipple>Analyse</a> </div>
                                    </div>
                                </div>
                                <div class="comments-feed" *ngIf="commentsFeed">
                                    <div class="main-comments">
                                        <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                        <div class="comments-box">
                                            <div class="comments-txt">
                                                <div class="textarea" role="textbox" contenteditable="true" tabindex="1"></div>
                                            </div>
                                            <div class="ico-sent">
                                                <a href="javascript:void(0);" (click)="hideCommentbox()"><img src="assets/images/ico-sent.png" ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="post-comments">
                                        <div class="comment-sender1">
                                            <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                            <div class="comments-profile">
                                                <div class="c-name">Elavarasan</div>
                                                <div class="c-proficiency">Front-End Developer</div>
                                            </div>
                                            <div class="comments-msg">
                                                Welcome to Third Eye...
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                            </div>
                                        </div>
                                        <div class="comment-sender2">
                                            <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                            <div class="comments-profile">
                                                <div class="c-name">Elavarasan</div>
                                                <div class="c-proficiency">Front-End Developer</div>
                                            </div>
                                            <div class="comments-msg">
                                                Welcome to Third Eye...
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="video-item br-5">
                                <div class="vprofile-header">
                                    <a href="javascript:void(0);" class="vprofile-left">
                                        <div class="vprofile">
                                            <img src="assets/images/no_user.png"> 
                                        </div>
                                        <div class="vprofile-info">
                                            <div class="vprofile-name">Yuzvendra Chahal</div>
                                            <div class="vprofile-type">All Rounder</div>
                                            <div class="vprofile-time">15<sup>th</sup> Feb 2020 <span>5:30PM</span></div>
                                        </div>
                                    </a>
                                    <div class="vprofile-more txt-right">
                                          <div class="dropdown">
                                            <a href="javascript:void(0);" class="ico-menu dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Copy Link</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                                              </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="vprofile-video">
                                    <video id="my-video" class="video-js" controls preload="none" width="640" height="264" poster="https://i.ytimg.com/vi/MFSjLx-LkrU/maxresdefault.jpg" data-setup="{}">
                                        <source src="assets/videos/GANGA-SRIDHAR-BATTING-FRONT-VIEW.mp4" type="video/mp4">
                                    </video>
                                </div>
                                <div class="vprofile-session">
                                    <div class="session">
                                        Session Name : <span>Bowling</span> <span>Side View</span>
                                    </div>  
                                    <div class="feed-info txt-right">
                                        <a href="javascript:void(0);">4 Fist Bump</a>
                                        <a href="javascript:void(0);">16 Comments</a>
                                    </div>
                                </div>
                                <div class="vprofile-feed">
                                    <div class="row">
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="fist-bump" matRipple>Fist Bump</a> </div>
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="comments" matRipple>Comments</a> </div>
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="fdanalyse" matRipple>Analyse</a> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="video-item br-5">
                                <div class="vprofile-header">
                                    <a href="javascript:void(0);" class="vprofile-left">
                                        <div class="vprofile">
                                            <img src="assets/images/no_user.png"> 
                                        </div>
                                        <div class="vprofile-info">
                                            <div class="vprofile-name">Yuzvendra Chahal</div>
                                            <div class="vprofile-type">All Rounder</div>
                                            <div class="vprofile-time">15<sup>th</sup> Feb 2020 <span>5:30PM</span></div>
                                        </div>
                                    </a>
                                    <div class="vprofile-more txt-right">
                                          <div class="dropdown">
                                            <a href="javascript:void(0);" class="ico-menu dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Copy Link</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                                              </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="vprofile-video">
                                    <video id="my-video" class="video-js" controls preload="none" width="640" height="264" poster="https://i.ytimg.com/vi/MFSjLx-LkrU/maxresdefault.jpg" data-setup="{}">
                                        <source src="assets/videos/GANGA-SRIDHAR-BATTING-FRONT-VIEW.mp4" type="video/mp4">
                                    </video>
                                </div>
                                <div class="vprofile-session">
                                    <div class="session">
                                        Session Name : <span>Bowling</span> <span>Side View</span>
                                    </div>  
                                    <div class="feed-info txt-right">
                                        <a href="javascript:void(0);">4 Fist Bump</a>
                                        <a href="javascript:void(0);">16 Comments</a>
                                    </div>
                                </div>
                                <div class="vprofile-feed">
                                    <div class="row">
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="fist-bump" matRipple>Fist Bump</a> </div>
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="comments" matRipple>Comments</a> </div>
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="fdanalyse" matRipple>Analyse</a> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="support">
                            <a href="javascript:void(0);">Support</a>
                            <span>
                                <i class="fa fa-question"></i>
                            </span>
                        </div>
                        <div class="video-filters box-shadow br-5">
                            <div class="title">Chat</div>
                            <div class="openchat">
                                <div class="chatcontainer">
                                    <div class="chatpanel">
                                        <div class="chat01">
                                            <p>Heith Ingram</p>
                                            <span>Hello Suraj Karavadra</span>
                                        </div>
                                        <div class="chat02">
                                            <p>Suraj Karavadra</p>
                                            <span>Hi, Welcome</span>
                                            
                                        </div>
                                    </div>
                                    <div class="main-comments">
                                        <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                        <div class="comments-box">
                                            <div class="comments-txt">
                                                <div class="textarea" role="textbox" contenteditable="true" tabindex="1"></div>
                                            </div>
                                            <div class="ico-sent">
                                                <a href="javascript:void(0);" (click)="hideCommentbox()"><img src="assets/images/ico-sent.png" ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="title">Filters</div>
                            <div class="video-filters-container">
                                <div class="search-container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon">
                                                    <a href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.12 373.12" width="24" height="24"><path d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z"/></svg>
                                                    </a>
                                                </mat-icon>
                                                <input matInput placeholder="Search Table">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="title adv-filter">
                                Advance Filters
                                <a href="javascript:void(0);" (click)="advFilters=!advFilters"></a>
                            </div>
                            <div class="adv-filter-container" *ngIf="advFilters">
                                <div class="filter-type mb15">
                                    <mat-radio-group aria-label="Select an option" (change)="changeMonthDate()">
                                        <mat-radio-button value="1" [checked]="true">Month Range</mat-radio-button>
                                        <mat-radio-button value="2">Date Range</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="range-filter">
                                    <div class="row">
                                        <div class="col" *ngIf="showMonthRange">
                                            <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block" floatLabel="always">
                                                <mat-label>Month Range</mat-label>
                                                <mat-date-range-input [rangePicker]="range">
                                                  <input matStartDate placeholder="Start date">
                                                  <input matEndDate placeholder="End date">
                                                </mat-date-range-input>
                                                <mat-datepicker-toggle matSuffix [for]="range"></mat-datepicker-toggle>
                                                <mat-date-range-picker #range></mat-date-range-picker>
                                              </mat-form-field>
                                        </div>
                                        <div class="col" *ngIf="showDateRange">
                                            <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block" floatLabel="always">
                                                <mat-label>Date Range</mat-label>
                                                <mat-date-range-input [rangePicker]="range">
                                                  <input matStartDate placeholder="Start date">
                                                  <input matEndDate placeholder="End date">
                                                </mat-date-range-input>
                                                <mat-datepicker-toggle matSuffix [for]="range"></mat-datepicker-toggle>
                                                <mat-date-range-picker #range></mat-date-range-picker>
                                              </mat-form-field>
                                        </div>
                                        <!-- <div class="col">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Select Season</mat-label>
                                                <mat-select placeholder="Season">
                                                    <mat-option [value]="01">2020-2021</mat-option>
                                                    <mat-option [value]="02">2019-2020</mat-option>
                                                    <mat-option [value]="03">2018-2019</mat-option>
                                                    <mat-option [value]="04">2017-2018</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                        <div class="col">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Select Season</mat-label>
                                                <input type="text" placeholder="Season" matInput [formControl]="myControl" [matAutocomplete]="auto">
                                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                    {{option.name}}
                                                  </mat-option>
                                                </mat-autocomplete>
                                              </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="select-skill mb20">
                                        <div class="skill-label">Select Skill</div>
                                        <div class="multi-select">
                                            <span><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Batting</a></span>
                                            <span><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Bowling</a></span>
                                            <span><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Fielding</a></span>
                                            <span><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Fitness</a></span>
                                            <span><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Others</a></span>
                                            <span><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Wicket Keeper</a></span>
                                        </div>
                                    </div>
                                    <div class="select-view ">
                                        <div class="view-label">Select View</div>
                                        <div class="single-select">
                                            <ul>
                                                <li><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Front</a></li>
                                                <li><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Side</a></li>
                                                <li><a href="javascript:void(0);" [ngClass]="classFlag === true ? 'active' : '' " (click)="menuToggle($event)">Back</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="txt-center">
                                    <button mat-raised-button color="primary" class="mb5 mt15">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overlay" *ngIf="scheduledate">
    <div class="playerview popup">
        <div class="playerview-header">Schedule  <span class="clr-popup" (click)="hidePopup();">x</span></div>
        <div class="playerview-body" style="background-color:#fff;">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-3">
                    <div class="title" style="padding-left:0;">Schedule Date</div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                <mat-label>Select Date</mat-label>
                                <input matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                <mat-label>Start Time</mat-label>
                                <input matInput required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                <mat-label>End Time</mat-label>
                                <input matInput required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 txt-center">
                            <button mat-raised-button color="primary">Submit</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <full-calendar *ngIf='calendarVisible'[options]='calendarOptions'></full-calendar>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</div>