<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container">
    <div class="container">
        <div class="input-container">
            <div class="white">
                <div class="row">
                    <div class="col-md-10">
                        <div class="page-tab">
                            <a routerLink="/player-list">Player</a>
                            <a routerLink="/coach-list" class="select">Coach</a> 
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="txt-right add-new back-previous">
                            <a routerLink="/coach-list">
                                Back
                                <!-- <div class="ico-action-svg more" title="Previous Page">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.151 219.151" width="32" height="32">	<path d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575		C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"/><path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008 c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628 		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/></svg>
                                    <img src="assets/images/Back.png"> 
                                </div> -->
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="page-wrapper">
    <div class="form-container">
        <div class="container">
            <div class="output-container">
                <div class="white">
                    <mat-vertical-stepper #stepper [linear]="isLinear" >

                        <mat-step [completed]="false" [stepControl]="CoachBasicForm" (click)="BaseToContact(stepper)" >
                            <form [formGroup]="CoachBasicForm" #co="ngForm"  > 
                            <ng-template matStepLabel>Basic Information</ng-template>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Academic Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <mat-select placeholder="Association Name"  formControlName="n_AssociationName"  style="text-transform:capitalize;" [(ngModel)]="AssociationName"  >
                                            <mat-option [value]="association.AssociationID"  *ngFor="let association of AssociationList"  style="text-transform:capitalize;"  >{{association.AssociationName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                     <div *ngIf="co.submitted && CoachBasicForm.controls.n_AssociationName.invalid"
                                                            class="alert alert-danger">
                                        <div *ngIf="CoachBasicForm.controls.n_AssociationName.errors.required">
                                            Association Name is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>First Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput formControlName="n_FirstName" style="text-transform:capitalize;" [(ngModel)]="FirstName" maxlength="25"
                                         required >
                                    </mat-form-field>
                                    <div *ngIf="co.submitted && CoachBasicForm.controls.n_FirstName.invalid"
                                                            class="alert alert-danger">
                                        <div *ngIf="CoachBasicForm.controls.n_FirstName.errors.required">
                                            First Name is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Last Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput formControlName="n_LastName" style="text-transform:capitalize;"  [(ngModel)]="LastName" maxlength="25" required>
                                    </mat-form-field>
                                    <div *ngIf="co.submitted && CoachBasicForm.controls.n_LastName.invalid"
                                                            class="alert alert-danger">
                                        <div *ngIf="CoachBasicForm.controls.n_LastName.errors.required">
                                            Last Name is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Father/Guardian Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput formControlName="n_FatherName" style="text-transform:capitalize;" [(ngModel)]="FatherName" maxlength="25" required>
                                    </mat-form-field>
                                    <div *ngIf="co.submitted && CoachBasicForm.controls.n_FatherName.invalid"
                                                            class="alert alert-danger">
                                        <div *ngIf="CoachBasicForm.controls.n_FatherName.errors.required">
                                            Father/Guardian is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Display Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput formControlName="n_DisplayName" style="text-transform:capitalize;" [(ngModel)]="DisplayName" maxlength="50" required >
                                    </mat-form-field>
                                    <div *ngIf="co.submitted && CoachBasicForm.controls.n_DisplayName.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachBasicForm.controls.n_DisplayName.errors.required">
                                            Display Name is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="md-block">
                                        <div class="radio-grp">Gender*</div>
                                    </div>
                                    <mat-radio-group aria-label="Select an option"  [(ngModel)]="Gender" formControlName="n_Gender">
                                        <mat-radio-button value="1">Male</mat-radio-button>
                                        <mat-radio-button value="2">Female</mat-radio-button>
                                    </mat-radio-group>
                                    <div *ngIf="co.submitted && CoachBasicForm.controls.n_Gender.invalid"
                                                        class="alert alert-danger" style="margin-top: 6px;" >
                                        <div *ngIf="CoachBasicForm.controls.n_Gender.errors.required">
                                            Gender is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Date of Birth(DOB)</mat-label>
                                        <input matInput required [matDatepicker]="dob"  required
                                        formControlName="n_DOB" [(ngModel)]="DOB" readonly   [max]="maxDate" >                                        
                                        <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                                        <mat-datepicker #dob></mat-datepicker>       
                                    </mat-form-field>
                                    <div *ngIf="co.submitted && CoachBasicForm.controls.n_DOB.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachBasicForm.controls.n_DOB.errors.required">
                                            DOB is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button (click)="BaseToContact(stepper)" color="primary">Next</button>
                            </div>
                            </form>
                        </mat-step>

                        <mat-step [completed]="false" [stepControl]="CoachContactForm" >
                            <form [formGroup]="CoachContactForm" #b="ngForm" >
                            <ng-template matStepLabel>Contact Infomation</ng-template>
                            <div class="row">
                                <div class="col-md-4" style="display: none;" >
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Registration Number</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput [(ngModel)]="RegistrationNumber" formControlName="n_RegistrationNumber" style="text-transform:capitalize;"  >
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_RegistrationNumber.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachContactForm.controls.n_RegistrationNumber.errors.required">
                                            Registration Number is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Registration Date</mat-label>

                                        <input required matInput [matDatepicker]="picker" [(ngModel)]="RegistrationDate" 
                                        [max]="maxDate" formControlName="n_RegistrationDate" readonly >
                                        
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_RegistrationDate.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachContactForm.controls.n_RegistrationDate.errors.required">
                                            Registration Date is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="fileupload">
                                        <div class="placeholder">Profile Image</div>                                            
                                        <input type="file" name="" class="fileupload-container" size="60" [(ngModel)]="CoachImage" 
                                           formControlName="n_CoachImage"  (change)="onFileChange($event,'profile')"  >
                                        <div class="browse-upload">
                                            <input name="y" id="y" type="text" value="{{CoachImagepath}}" class="upload-txt" placeholder="Select file">
                                            <input type="button" value="Browse" class="upload-btn">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Mobile Number</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput [(ngModel)]="MobileNumber" formControlName="n_MobileNumber"  maxlength="10" required
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"    >
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_MobileNumber.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachContactForm.controls.n_MobileNumber.errors.required">
                                            Mobile Number is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Email Id</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                        <input matInput [(ngModel)]="EmailId" formControlName="n_EmailID" required >
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_EmailID.invalid" class="alert alert-danger">
                                            <div *ngIf="CoachContactForm.controls.n_EmailID.errors.required">
                                                Email ID required</div>
                                            <div *ngIf="this.CoachContactForm.controls.n_EmailID.errors.email">
                                                Email Id is Incorrect</div>
                                    </div>  
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Emergency Contact Person</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput [(ngModel)]="EmergencyContactPerson" formControlName="n_EmergencyContactPerson" style="text-transform:capitalize;"  >
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_EmergencyContactPerson.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachContactForm.controls.n_EmergencyContactPerson.errors.required">
                                            Emergency Contact Person is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Emergency Contact No</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput [(ngModel)]="EmergencyContactNumber" formControlName="n_EmergencyContactNumber"  maxlength="10"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"  >
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_EmergencyContactNumber.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachContactForm.controls.n_EmergencyContactNumber.errors.required">
                                            Emergency Contact Number is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon mat-icon-custom"><svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg></mat-icon>
                                        <mat-label>Blood Group</mat-label>
                                        <mat-select placeholder="Blood Group"  [(ngModel)]="BloodGroup" formControlName="n_BloodGroup"  style="text-transform:capitalize;">
                                            <mat-option [value]="association.BloodGroupID"  *ngFor="let association of BloodGroupList"  style="text-transform:capitalize;"  >{{association.BloodGroupName}}</mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_BloodGroup.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachContactForm.controls.n_BloodGroup.errors.required">
                                            Blood Group is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Nationality</mat-label>
                                        <mat-select placeholder="Nationality" required [(ngModel)]="Nationality" formControlName="n_Nationality"  style="text-transform:capitalize;">
                                            <mat-option [value]="con.CountryID"  *ngFor="let con of CountryList" required style="text-transform:capitalize;">{{con.CountryName}}</mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                    <div *ngIf="b.submitted && CoachContactForm.controls.n_Nationality.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachContactForm.controls.n_Nationality.errors.required">
                                            Nationality is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-12 txt-right">
                                    <div class="img-preview">
                                        <div class="img-preview">
                                            <img draggable="false"   src="{{CoachImage}}"   class="img-fluid" 
                                            onerror="this.src='assets/images/no_user.png';" />
                                            <!-- <img src="assets/images/no_user.png" class="img-fluid" > -->
                                        </div>
                                    </div>
                               </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" (click)="ContactToInfo(stepper)"  >Next</button>
                            </div>
                            </form>
                        </mat-step>

                        <mat-step [completed]="false" [stepControl]="CoachInformationForm" > 
                            <form [formGroup]="CoachInformationForm" #c="ngForm" >
                            <ng-template matStepLabel>Coach Information</ng-template>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Level</mat-label>
                                        <mat-select placeholder="Coach Level"  [(ngModel)]="CoachLevel" formControlName="n_CoachLevel"  style="text-transform:capitalize;">
                                            <mat-option [value]="con.MemberLevelID"  *ngFor="let con of MemberLevelList" required  style="text-transform:capitalize;">
                                                {{con.MemberLevelName}}</mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                    <div *ngIf="c.submitted && CoachInformationForm.controls.n_CoachLevel.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachInformationForm.controls.n_CoachLevel.errors.required">
                                            Coach Level is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Specification</mat-label>
                                        <mat-select placeholder="Coach Specification"  [(ngModel)]="CoachSpecification" formControlName="n_CoachSpecification"  style="text-transform:capitalize;">
                                            <mat-option [value]="con.SpecID"  *ngFor="let con of SpecsList"  style="text-transform:capitalize;" required >
                                                {{con.SpecName}}</mat-option>
                                        </mat-select>  
                                    </mat-form-field>
                                    <div *ngIf="c.submitted && CoachInformationForm.controls.n_CoachSpecification.invalid"
                                                        class="alert alert-danger">
                                        <div *ngIf="CoachInformationForm.controls.n_CoachSpecification.errors.required">
                                            Coach Level is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="fileupload">
                                        <div class="placeholder">Certification Upload (Image/PDF)</div>                                            
                                        <input type="file" name="" class="fileupload-container" size="60"  [(ngModel)]="CoachCertificateFile" 
                                          formControlName="n_CoachCertificateFile" (change)="onCertChange($event)">
                                        <div class="browse-upload">
                                            <input name="x" id="x" type="text" value="{{CoachCertficatepath}}" class="upload-txt" placeholder="Select file">
                                            <input type="button" value="Browse" class="upload-btn">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Coaching Description</mat-label>
                                        <textarea matInput placeholder="Coaching Description" style="text-transform:capitalize;" 
                                        [(ngModel)]="Description"  formControlName="n_Description" ></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Certification Agency</mat-label>
                                        <textarea matInput placeholder="Certification Agency" style="text-transform:capitalize;" 
                                        [(ngModel)]="Agency"  formControlName="n_Agency" ></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 txt-right">
                                    <div class="img-preview" style="margin-top:20px;margin-bottom:0;">
                                        <img src="{{CoachCertificateFile}}" class="img-fluid"
                                        onerror="this.src='assets/images/no_user.png';" />
                                    </div>
                               </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" (click)="InfoToAddress(stepper)" >Next</button>
                            </div>
                        </form>
                        </mat-step>

                        <mat-step [completed]="false" [stepControl]="CoachAddressForm" >
                         <form [formGroup]="CoachAddressForm" #d="ngForm" >
                            <ng-template matStepLabel>Address</ng-template>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Address 1</mat-label>
                                        <input matInput [(ngModel)]="Address01" formControlName="n_Address01" required  maxlength="150" style="text-transform:capitalize;"  >
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_Address01.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_Address01.errors.required">
                                                Address 1 is required.</div>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Address 2</mat-label>
                                        <input matInput [(ngModel)]="Address02" formControlName="n_Address02" maxlength="150" style="text-transform:capitalize;"  >
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_Address02.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_Address02.errors.required">
                                                Address 2 is required.</div>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Region</mat-label>
                                        <mat-select placeholder="Region" [(ngModel)]="Continent"  formControlName="n_Continent" required (selectionChange)="onChangeContinent(Continent)"  style="text-transform:capitalize;"  >
                                            <mat-option [value]="Continent.ContinentID"  *ngFor="let Continent of ContinentsList"  style="text-transform:capitalize;" 
                                             >{{Continent.ContinentName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_Continent.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_Continent.errors.required">
                                                Region is required.</div>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Country</mat-label>
                                        <mat-select placeholder="Country"  [(ngModel)]="Country"  formControlName="n_Country" required (selectionChange)="onChangeCountry(Country)"  style="text-transform:capitalize;"  >
                                            <mat-option [value]="country.CountryID"  *ngFor="let country of CountryByRegion"  style="text-transform:capitalize;"  >{{country.CountryName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_Country.invalid"
                                            class="alert alert-danger">        
                                            <div *ngIf="CoachAddressForm.controls.n_Country.errors.required">
                                                Country is required.</div>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="432" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5 16h3.04l-1.52-2.03z"></path><path d="M9.78 11.63l1.25 1.67L14 9.33 19 16h-8.46l-4.01-5.37L1 18h22L14 6l-4.22 5.63zM5 16l1.52-2.03L8.04 16H5z"></path></svg></mat-icon>
                                        <mat-label>State</mat-label>
                                        <mat-select placeholder="State"  [(ngModel)]="States"  formControlName="n_States" required  (selectionChange)="onChangeState(States)"  style="text-transform:capitalize;"  >
                                            <mat-option [value]="state.StateID" *ngFor="let state of StateByCountry"  style="text-transform:capitalize;"  >{{state.StateName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_States.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_States.errors.required">
                                                State is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="432" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5 16h3.04l-1.52-2.03z"></path><path d="M9.78 11.63l1.25 1.67L14 9.33 19 16h-8.46l-4.01-5.37L1 18h22L14 6l-4.22 5.63zM5 16l1.52-2.03L8.04 16H5z"></path></svg></mat-icon>
                                        <mat-label>City</mat-label>
                                        <mat-select placeholder="City"  [(ngModel)]="City"  formControlName="n_City" required (selectionChange)="onChangeCity(City)"  style="text-transform:capitalize;"   >
                                            <mat-option [value]="city.CityID" *ngFor="let city of CityByState"  style="text-transform:capitalize;"  >{{city.CityName}}</mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_City.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_City.errors.required">
                                                City is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                        <mat-label>Pin Code</mat-label>
                                        <input matInput [(ngModel)]="Pincode"  formControlName="n_Pincode"   maxlength="6"  required
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"  >  
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_Pincode.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_Pincode.errors.required">
                                             Pincocde is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="432" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5 16h3.04l-1.52-2.03z"></path><path d="M9.78 11.63l1.25 1.67L14 9.33 19 16h-8.46l-4.01-5.37L1 18h22L14 6l-4.22 5.63zM5 16l1.52-2.03L8.04 16H5z"></path></svg></mat-icon>
                                        <mat-label>ID Proof</mat-label>
                                        <mat-select placeholder="ID Proof"  [(ngModel)]="IDProof"  formControlName="n_IDProof"   >
                                            <mat-option [value]="idProof.DocumentID" *ngFor="let idProof of DocumentTypeList"  style="text-transform:capitalize;"  >{{idProof.DocumentType}}</mat-option>                                           
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_IDProof.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_IDProof.errors.required">
                                             ID-Proof Type is required.</div>
                                    </div>
                                </div>
                               <div class="col-md-4">
                                    <div class="fileupload">
                                        <div class="placeholder">ID Proof Upload</div>                                            
                                        <input type="file" name="" class="fileupload-container" size="60" [(ngModel)]="IDProofFile" 
                                        formControlName="n_IDProofFile" (change)="onDocChange($event)" >
                                        <div class="browse-upload">
                                            <input name="" type="text" class="upload-txt" value="{{CoachIDProofpath}}" placeholder="Select file"
                                              [(ngModel)]="IDProofFile"  formControlName="n_IDProofFile"  >
                                            <input type="submit" value="Browse" class="upload-btn" (change)="onDocChange($event)" >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                        <mat-label>Referal Code</mat-label>
                                        <input matInput  [(ngModel)]="ReferralCode"  formControlName="n_ReferralCode" maxlength="7" style="text-transform:capitalize;"  >
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_ReferralCode.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="CoachAddressForm.controls.n_ReferralCode.errors.required">
                                                Referal Code is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Package Valid Till</mat-label>
                                        <input matInput [matDatepicker]="validtill" [(ngModel)]="ValidDate"  formControlName="n_ValidDate"
                                         required readonly [min]="today">
                                        <mat-datepicker-toggle matSuffix [for]="validtill"></mat-datepicker-toggle>
                                        <mat-datepicker #validtill></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="d.submitted && CoachAddressForm.controls.n_ValidDate.invalid"
                                           class="alert alert-danger">
                                           <div *ngIf="CoachAddressForm.controls.n_ValidDate.errors.required">
                                               Package Valid Till is required.</div>
                                   </div>
                               </div>
                                <div class="col-md-4 txt-right">
                                    <div class="img-preview">
                                        <img draggable="false"   src="{{IDProofFile}}"   class="img-fluid" 
                                        onerror="this.src='assets/images/no_user.png';" />
                                     </div>
                                </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" style="margin-right: 15px;" (click)="onSubmit()" >{{ButtonType}}</button>
                                <button mat-raised-button color="primary" (click)="stepper.reset()">Reset</button>
                                <!-- <button type="submit" mat-raised-button color="primary" (click)="onSubmit()">{{ButtonType}}</button> -->
                            </div>
                          </form>
                        </mat-step>

                        </mat-vertical-stepper>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div class="overlay success"  *ngIf = "isSuccessAlert" >
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div  class="alcenter" > 
                <h4>Coach Registration</h4>
                <p >{{successAlert}}</p> 
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" routerLink="/coach-list" (click)="isSuccessAlert=false" >Ok</button>
        </div>
    </div>
</div> 
 <div class="overlay error" *ngIf = "isErrorAlert" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
             <div  class="alcenter" > 
                 <h4>Coach Registration</h4>
               <p >{{errorAlert}}</p> 
            </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="isErrorAlert=false" >Ok</button>
        </div>
   </div>
</div> 
 <div class="overlay warning" *ngIf = "isWarningAlert" >
    <div class="warning-widget">
       <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="warning-container">
            <div class="alcenter"> 
               <h4>Coach Registration</h4>
               <p  >{{warningAlert}}</p> 
             </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="isWarningAlert=false" >Ok</button>
        </div>
   </div>
</div>




<div class="modal fade" id="modalcrop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" >
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Resize Image</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <!-- <span  data-toggle="modal" data-target="#modalcrop">click</span>   -->
                    <div class="col-md-8" style="float:left">
                        <image-cropper [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                            [cropperMinWidth]="200" [hideResizeSquares]="false" format="png"
                            (imageCropped)="imageCropped($event)"
                            (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()" style="float:left;">
                        </image-cropper>

                    </div>
                    <!-- <button type="button" (click)=zoonIn()>+</button> <button type="button" (click)=zoonOut()>-</button> -->
                    <div class="col-md-4" style="float:left">
                        
                        <img draggable="false" src="{{croppedImage}}"
                            class="upload-preview" style="width: 100%"/> 
                    </div>

                </div>
            </div>
            <div class="footer txt-center">
                <button type="button" class="btn btn-primary" (click)="Applycropper()">Apply</button>
            </div>
        </div>
    </div>
</div>