import { Component, OnInit, Input, ViewChild, ElementRef, Directive, HostListener } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { MastersServiceService } from '../masters-service.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, windowToggle } from 'rxjs/operators';
import * as $ from 'jquery';

import { Hosted } from 'protractor/built/driverProviders';
import { FLOAT, float } from 'html2canvas/dist/types/css/property-descriptors/float';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
declare var jQuery: any;
declare var jwplayer: any;
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';

import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { AnnotationsService } from '../annotations.service'
//import { I } from 'node_modules1/@angular/cdk/keycodes';

export interface User {
  name: string;
}


@Component({
  selector: 'app-video-analyse',
  templateUrl: './video-analyse.component.html',
  styleUrls: ['./video-analyse.component.css'],
})

export class VideoAnalyseComponent implements OnInit {

  @ViewChild('myCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('canvasDraw', { static: false }) canvas: ElementRef;
  @ViewChild('canvasDraw2', { static: false }) canvas2: ElementRef;
  @ViewChild('canvasDraw3', { static: false }) canvas3: ElementRef;
  @ViewChild('canvasDraw4', { static: false }) canvas4: ElementRef;
  @ViewChild('videoplayer', { static: false }) analysevideoplayer: ElementRef;

  public context: CanvasRenderingContext2D;
  myControl = new FormControl();
  options: any = [
  ];
  filteredOptions!: Observable<User[]>;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, public datepipe: DatePipe,
    private router: Router, private domSanitizer: DomSanitizer, private Aservice: AnnotationsService) { }

  public commentsFeed: boolean = false;

  public showMonthRange: boolean = true;
  public showDateRange: boolean = false;
  public advFilters: boolean = false;
  public annotationType: string = '';
  public clickCount: number = 0;
  public canvasData: canvasDetails[] = [];
  public canvasTempData: canvasDetails[] = [];
  public drawingId: number;
  public SessionID: string;
  public SessionVideo: string;
  public showcanvas: boolean;
  public videocurrentTime: string;

  public AnnotationTime: number;
  public lstAnnotations: any;
  public lstAnnotationDetails: any;
  public lstAnnotationReviews: any;

  public annotationNumber: number;
  public NextAnnotationTime: number;
  public lstCanvasAnnotationDetails: any;

  public lstCanvasAnn: any;
  public isTagVideo: boolean = false;
  playballonly: boolean;

  canvasElement: any;
  canvasElement2: any;
  canvasElement3: any;
  lines: any[];
  isDown: boolean = false;
  startX: number;
  startY: number;
  nearest: any;
  offsetX: any;
  offsetY: any;
  startDraw: boolean = false;
  angstartX: number;
  angstartY: number;
  isTextEditor: boolean = false;
  isScaleRular: boolean = false;
  seekbarmove: boolean = false;
  textArray: any = [];
  selectedText: number = -1;
  scaleArray: any = [];
  scaleassigned: boolean = false;
  unitValue: number;
  unitType: string;
  unitPixels!: number;
  drawcolor: string = "yellow";
  fontcolor: string = 'yellow';
  fontbackcolor: string = '#ffffff00';

  public UserCode: string; public AthleteID: string;
  public PlayerName: string; public PlayerRole: string;
  public VideoDate: string; public ProfileImage: string;
  public sessionsThumbnail: string; public SessionTypeName: string;
  public VideoViewName: string; public SessionName: string;
  public sFlag: string; public sPage: string;

  public isShowLoader: boolean = false;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  isDeleteConfirm: boolean = false;

  videoanalyse: boolean = true;
  videocompare: boolean = false;
  videoStatus: string = 'pause';
  compareduration!: number;
  videocompare1Starttime!: number;
  videocompare2Starttime!: number;
  refVideotoCompare!: number;
  isfeedbackenable: boolean = false;
  RequestId: string;
  isPlayer: boolean = false;
  public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];


  @HostListener('videoplayer') myVideo: ElementRef;

  public IsShowCompare: boolean = false;
  strokewidth: number = 1;
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.utility.fullscreenlayout();

    $("#custom-seekbar").css("width", "0%");
    var sessionddetails = this.service.VideoSessionDetails;

    this.sFlag = sessionStorage.getItem('sessionFlag');
    if (JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).MemberTypeID == 1) {
      this.isPlayer = true;
      this.AthleteID = sessionStorage.getItem('sessionUserCode');
    }
    else {
      this.isPlayer = false;
      this.AthleteID = sessionStorage.getItem('athleteID');
    }

    this.SessionID = String(sessionddetails.SessionID);
    this.SessionVideo = String(sessionddetails.CloudURL);
    this.PlayerName = String(sessionddetails.PlayerName);
    this.PlayerRole = String(sessionddetails.PlayerRoleName);
    this.VideoDate = String(sessionddetails.SessionDate);
    this.ProfileImage = String(sessionddetails.ProfileImage);
    this.sessionsThumbnail = String(sessionddetails.ThumbnailURL);
    this.SessionTypeName = String(sessionddetails.SessionTypeName);
    this.VideoViewName = String(sessionddetails.VideoViewName);
    this.SessionName = String(sessionddetails.SessionName);
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.sFlag = sessionStorage.getItem('sessionFlag');
    this.sPage = sessionStorage.getItem('sessionPage');
    this.showcanvas = false;
    if (this.utility.previousPage == '/coachpad-player') {
      this.isfeedbackenable = true;
      this.RequestId = String(sessionddetails.RequestID);
    }
    if (this.sFlag == "1") {
      this.IsShowCompare = true;
    }
    else {
      this.IsShowCompare = false;
    }
    this.AnnotationInitLoad();
  }

  AnnotationInitLoad() {
    this.service.VideoAnalyseCRUD({
      ActionFlag: 'FetchAnnotationsBySession', RefUserID: String(this.UserCode),
      SessionID: this.SessionID, RequestID: this.RequestId
    }).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (ret.Status) {
        this.lstAnnotations = ret.lstAnnotations;
        this.lstAnnotationDetails = ret.lstAnnotationDetails;
        this.lstAnnotationReviews = ret.lstAnnotationReviews;
        if (this.isfeedbackenable)
          this.sessionRate = ret.SessionRate;
        if (ret.BattingStyle == 'LHB') {
          this.isRHB = false;
        }
        else {
          this.isRHB = true;
        }
        //alert(this.sessionRate);
      }


    });


  }
  sessionRate: number;
  SessionRatingComment: string = "";
  changeRating(value) {
    this.sessionRate = value;

  }
  SaveSessionRating() {
    this.service.CoachProfileSettings({
      method: "VideoAnalyse/VideoAnalyseRating",
      request: {
        ActionFlag: "Rating", RefUserID: this.UserCode, SessionID: String(this.SessionID)
        , Rating: String(this.sessionRate), RatingComment: String(this.SessionRatingComment)
        , RequestID: this.service.VideoSessionDetails.RequestID, CoachRefUserID: this.service.VideoSessionDetails.CoachRefUserID
      }
    }).subscribe((ret: any = []) => {
    });
  }

  ngAfterViewInit() {
    this.canvasElement = this.canvas.nativeElement;
    this.canvasElement2 = this.canvas2.nativeElement;
    this.canvasElement3 = this.canvas3.nativeElement;

    this.lines = [];
    requestAnimationFrame(() => {
      this.reOffset()
    })
    // this.FetchAnnotations();
  }

  reOffset() {
    let BB = this.canvasElement.getBoundingClientRect();
    this.offsetX = BB.left;
    this.offsetY = BB.top;
  }

  changeMonthDate() {
    this.showMonthRange = !this.showMonthRange;
    this.showDateRange = !this.showDateRange;
  }
  showCommentbox() {
    this.commentsFeed = true;
  }
  hideCommentbox() {
    this.commentsFeed = false;
  }
  classFlag: boolean = false;
  menuToggle(event: any) {
    event.target.classList.toggle('active')
  }

  draw(data, color) {
    let ctx = this.canvasElement2.getContext('2d');
    let cw = this.canvasElement.width;
    let ch = this.canvasElement.height;
    // ctx.clearRect(0, 0, cw, ch);
    // draw all lines at their current positions
    let ctx2 = this.canvasElement2.getContext('2d');
    for (let i = 0; i < data.length; i++) {

      ctx2.beginPath();
      ctx2.moveTo(data[i].x0, data[i].y0);
      ctx2.lineTo(data[i].x1, data[i].y1);
      ctx2.strokeStyle = color;
      ctx2.lineWidth = data[i].Linewidth == 0 ? 1 : data[i].Linewidth;
      ctx.shadowColor = '';
      ctx.shadowBlur = 0;
      ctx2.stroke();
    }
    // draw markers if a line is being dragged
    if (this.nearest) {
      // point on line this.nearest to mouse
      ctx.beginPath();
      ctx.arc(this.nearest.pt.x, this.nearest.pt.y, 5, 0, Math.PI * 2);
      ctx.strokeStyle = color;
      ctx.lineWidth = this.strokewidth;
      ctx.shadowColor = '';
      ctx.shadowBlur = 0;
      ctx.stroke();
      // marker for original line before dragging
      this.drawLine(this.nearest.originalLine, color);
      // hightlight the line as its dragged
      this.drawLine(this.nearest.line, color);
    }
  }


  drawLine(line, color) {
    let ctx = this.canvasElement.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(line.x0, line.y0);
    ctx.lineTo(line.x1, line.y1);
    ctx.strokeStyle = color;
    ctx.lineWidth = line.Linewidth == 0 ? 1 : line.Linewidth;
    ctx.shadowColor = '';
    ctx.shadowBlur = 0;
    ctx.stroke();
  }

  // drawCircle1(ctx,x, y, w, h) {
  //   var cnst = .5522848;
  //   var ox = (w / 2) * cnst,
  //     oy = (h / 2) * cnst,
  //     xe = x + w,
  //     ye = y + h,
  //     xm = x + w / 2,
  //     ym = y + h / 2;
  //   ctx.beginPath();
  //   ctx.moveTo(x, ym);
  //   ctx.lineWidth = 1; 
  //   ctx.strokeStyle = "yellow";
  //   ctx.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
  //   ctx.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
  //   ctx.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
  //   ctx.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
  //   ctx.closePath();
  //   ctx.stroke();
  // }

  drawCircle(ctx, x0, y0, x1, y1, color, lwidth) {
    var xDiff = x0 - x1;
    var yDiff = y0 - y1;

    var r = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    ctx.lineWidth = lwidth == 0 ? 1 : lwidth;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.arc(x0, y0, r, 0, 2 * Math.PI);
    ctx.stroke();

  }

  drawRectangle(ctx, x, y, w, h, color, lwidth) {
    ctx.beginPath();
    ctx.lineWidth = lwidth;
    ctx.strokeStyle = color;
    ctx.strokeRect(x, y, w, h);
  }

  drawArrow(ctx, startX, startY, endX, endY, color, lwidth) {
    if (startX != endX && startY != endY) {
      var headlen = 15;
      var dx = endX - startX;
      var dy = endY - startY;
      var angle = Math.atan2(dy, dx);
      ctx.lineWidth = lwidth;
      headlen = headlen * ctx.lineWidth * 0.5;
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      //ctx.lineWidth = lineWidth;
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = color;
      ctx.stroke();
      ctx.closePath();
      //
      ctx.beginPath();

      ctx.moveTo(endX - headlen * Math.cos(angle - Math.PI / 6), endY - headlen * Math.sin(angle - Math.PI / 6));

      ctx.lineTo(endX, endY);

      ctx.lineTo(endX - headlen * Math.cos(angle + Math.PI / 6), endY - headlen * Math.sin(angle + Math.PI / 6));

      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    }
  }

  // test if x,y is inside the bounding box of texts[textIndex]
  textHittest(x, y, textIndex) {
    var text = this.textArray[textIndex];
    return (x >= text.x && x <= text.x + text.width && y >= text.y - text.height && y <= text.y);
  }

  drawAngleSymbol(x1, x2, x3, y1, y2, y3, color) {
    let ctx = this.canvasElement2.getContext('2d');
    var dx1 = x1 - x2;
    var dy1 = y1 - y2;
    var dx2 = x3 - x2;
    var dy2 = y3 - y2;
    var a1 = Math.atan2(dy1, dx1);
    var a2 = Math.atan2(dy2, dx2);
    var a = Math.round(((a2 - a1) * 180 / Math.PI + 360) % 360);
    // draw angleSymbol
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x2, y2);
    ctx.arc(x2, y2, 20, a1, a2);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.globalAlpha = 0.5;
    ctx.fill();
    ctx.restore();
    ctx.fillStyle = color;
    ctx.fillText(String(a) + '°', x2 + 25, y2);
  }
  textLeft: string;
  textTop: string;
  isText: boolean;
  annotationtoSelected: any;
  videoplayer1: any;
  RemoveAnnotation() {
    this.service.VideoAnalyseCRUD({
      ActionFlag: 'DeleteVideoAnnotation', RefUserID: this.UserCode, SessionID: String(this.annotationtoSelected.SessionID),
      VideoAnalyseID: String(this.annotationtoSelected.VideoAnalyseID), Thumbnail: this.ThumbURL, AnnotationID: String(this.annotationtoSelected.AnnotationID)
    }).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      this.clearCanvas();
      this.canvasData = [];
      this.clearTextCanvas();
      this.showcanvas = false;
      this.service.VideoAnalyseCRUD({ ActionFlag: 'FetchAnnotationsBySession', RefUserID: this.UserCode, SessionID: this.SessionID }).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        this.isShowLoader = false;
        if (ret.Status) {
          this.lstAnnotations = ret.lstAnnotations;
          this.lstAnnotationDetails = ret.lstAnnotationDetails;
          this.lstAnnotationReviews = ret.lstAnnotationReviews;

          this.videoplayer1.currentTime = this.AnnotationTime;
          var nxttime = this.lstAnnotations.filter(option => Number(option.FrameTime) >= this.AnnotationTime);
          if (nxttime.length > 0)
            this.NextAnnotationTime = nxttime[0].FrameTime;
          else
            this.NextAnnotationTime = this.videoplayer1.duration;
        }
      });
    });
  }
  selAnnotationtype: string;
  handleclick(event, videoplayer, canvas) {
    if (this.isshapeedit) {
      this.videoplayer1 = videoplayer;
      var r = canvas.getBoundingClientRect(), // adjust to proper mouse position
        x = event.layerX,
        y = event.layerY

      var lstline = this.lstCanvasAnnotationDetails.filter(option => String(option.AnnotationType) === 'line');
      var lstrect = this.lstCanvasAnnotationDetails.filter(option => String(option.AnnotationType) === 'rectangle');
      var lstcircle = this.lstCanvasAnnotationDetails.filter(option => String(option.AnnotationType) === 'circle');
      var lstarrow = this.lstCanvasAnnotationDetails.filter(option => String(option.AnnotationType) === 'arrow');

      // for each line segment, build segment to path and check
      if (lstline.length > 0) {
        let ctx = this.canvasElement2.getContext('2d');
        for (var i = 0; i < lstline.length; i++) {
          ctx.beginPath();                        // new segment
          ctx.moveTo(lstline[i].X0, lstline[i].Y0);     // start is current point
          ctx.lineTo(lstline[i].X1, lstline[i].Y1); // end point is next
          if (ctx.isPointInStroke(x, y)) {        // x,y is on line?
            ctx.strokeStyle = "red";
            ctx.shadowColor = 'black';
            ctx.shadowBlur = 2;     // stroke red for demo
            ctx.stroke();
            this.annotationtoSelected = lstline[i];
            this.selAnnotationtype = 'line';
            break;
          }
        }
      }
      if (lstrect.length > 0) {
        // for each line segment, build segment to path and check
        let ctx = this.canvasElement2.getContext('2d');
        for (var i = 0; i < lstrect.length; i++) {
          ctx.beginPath();
          ctx.rect(lstrect[i].X0, lstrect[i].Y0, Math.abs(lstrect[i].X0 - lstrect[i].X1), Math.abs(lstrect[i].Y0 - lstrect[i].Y1));                     // new segment 
          if (ctx.isPointInPath(x, y)) {        // x,y is on rectangle?
            ctx.strokeStyle = "red";
            ctx.shadowColor = 'black';
            ctx.shadowBlur = 2;     // stroke red for demo
            //ctx.rect(lst[i].X0, lst[i].Y0, Math.abs(lst[i].X0 - lst[i].X1), Math.abs(lst[i].X0 - lst[i].X1));
            // this.drawRectangle(ctx, lst[i].X0, lst[i].Y0, Math.abs(lst[i].X0 - lst[i].X1), Math.abs(lst[i].X0 - lst[i].X1), this.drawcolor, this.strokewidth);
            this.annotationtoSelected = lstrect[i];
            this.Aservice.drawBoxOn({ x1: lstrect[i].X0, y1: lstrect[i].Y0, x2: lstrect[i].X1, y2: lstrect[i].Y1 }, ctx);
            this.selAnnotationtype = 'rectangle';
            break;
          }
        }
      }
      if (lstcircle.length > 0) {
        // for each line segment, build segment to path and check
        let ctx = this.canvasElement2.getContext('2d');
        for (var i = 0; i < lstcircle.length; i++) {
          var xDiff = lstcircle[i].X0 - lstcircle[i].X1;
          var yDiff = lstcircle[i].Y0 - lstcircle[i].Y1;

          var rad = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
          var dx = x - lstcircle[i].X0,
            dy = y - lstcircle[i].Y0,
            dist = Math.sqrt(dx * dx + dy * dy);

          if (dist < rad) {
            ctx.strokeStyle = 'red';
            ctx.shadowColor = 'black';
            ctx.shadowBlur = 2;     // stroke red for demo 
            ctx.lineWidth = lstcircle[i].Linewidth;     //
            this.annotationtoSelected = lstcircle[i];
            // this.drawCircle(ctx,lstcircle[i].X0,lstcircle[i].Y0,lstcircle[i].X1,lstcircle[i].Y1,'red',2);
            ctx.arc(lstcircle[i].X0, lstcircle[i].Y0, rad, 0, 2 * Math.PI);
            ctx.fillRect(lstcircle[i].X0 - rad, lstcircle[i].Y0, 2, 2);
            ctx.fillRect(lstcircle[i].X0 + rad, lstcircle[i].Y0, 2, 2);
            ctx.fillRect(lstcircle[i].X0, lstcircle[i].Y0 - rad, 2, 2);
            ctx.fillRect(lstcircle[i].X0, lstcircle[i].Y0 + rad, 2, 2);
            ctx.fillRect(lstcircle[i].X0, lstcircle[i].Y0, 2, 2);
            this.selAnnotationtype = 'circle';
            break;
          }

        }
      }

    } else {
      if (this.annotationType == 'angle') {
        this.clickCount++;
        if (this.clickCount == 1) {
          this.angstartX = event.layerX;
          this.angstartY = event.layerY;
          this.lines = [];
        }
        else if (this.clickCount == 2) {
          this.lines.push({ x0: this.angstartX, y0: this.angstartY, x1: event.layerX, y1: event.layerY });
          this.angstartX = event.layerX;
          this.angstartY = event.layerY;
        }
        else if (this.clickCount == 3) {
          this.lines.push({ x0: this.angstartX, y0: this.angstartY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth });
          this.draw(this.lines, this.drawcolor);
          this.addtoCanvasData('angle', this.lines[0].x0, this.lines[0].y0, this.lines[1].x0, this.lines[1].y0, this.lines[1].x1, this.lines[1].y1, "", this.drawcolor, "", "", "", "", "", "", "", "", "", "", "");
          this.drawAngleSymbol(this.lines[0].x0, this.lines[1].x0, this.lines[1].x1, this.lines[0].y0, this.lines[1].y0, this.lines[1].y1, this.drawcolor)
          //this.lines=[];
          this.clickCount = 0;
        }
      }
      else if (this.annotationType == "text") {
        if (!this.ismove) {
          this.isText = true;
          this.textLeft = event.layerX;
          this.textTop = event.layerY;
        }
      }

      else if (!this.ismove) {
        if (event.shiftKey) {
          alert("shift");
          this.isshapeedit = true;
        }
        else {
          this.audiocommentUrl = null;
          this.resume(videoplayer);
        }

      }
      this.ismove = false;
    }
    this.isScaleRular = false;

  }
  isshapeedit: boolean = false;
  clickedArea: any;
  mousedown: boolean;
  handleMouseDown(event) {
    this.startX = event.layerX;
    this.startY = event.layerY;
    if (!this.isshapeedit) {
      if (this.annotationType != '' && this.annotationType != 'text') //Common for all
      {
        this.startDraw = true;
        if (this.annotationType == 'scale') {
          if (this.unitPixels > 0) {
            this.isScaleRular = false;
          } else { this.isScaleRular = true; }
        }
      }
      else if (this.annotationType == 'text') {  // for selecting the text
        event.preventDefault();

        this.startX = event.layerX;
        this.startY = event.layerY;
        // Put your mousedown stuff here
        for (var i = 0; i < this.textArray.length; i++) {
          if (this.textHittest(this.startX, this.startY, i)) {
            this.selectedText = i;
          }
        }
      }
      else {
      }
      //this.lines = [];
    }
    else {
      this.mousedown = true;
      this.clickedArea = this.Aservice.findCurrentArea(this.annotationtoSelected, event, event.offsetX, event.offsetY, this.selAnnotationtype);
      var x1 = event.offsetX;
      var y1 = event.offsetY;
      var x2 = event.offsetX;
      var y2 = event.offsetY;

    }
  }
  ismove: boolean = false;
  x1: number = -1;
  y1: number = -1;
  x2: number = -1;
  y2: number = -1;
  handleMouseMove(event) {
    if (!this.isshapeedit) {
      if (this.startDraw) {
        this.ismove = true;
        if (this.annotationType == 'line') {
          this.clearDrawingCanvas();
          this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth }, this.drawcolor);
        }
        else if (this.annotationType == 'scale') {

          this.clearDrawingCanvas();
          //  this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, 'yellow');
          if (this.isScaleRular == true) {
            this.clearDrawingCanvas();
            this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth }, this.drawcolor);
          } else {
            let ctx = this.canvasElement.getContext('2d');
            this.x1 = this.startX;
            this.x2 = event.layerX;
            this.y1 = this.startY;
            this.y2 = event.layerY;
            if (this.unitValue == undefined || this.unitType == undefined) {
              this.clearDrawingCanvas();
              this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth }, this.drawcolor);
            }
            else {
              this.drawLineText(ctx, this.x1, this.x2, this.y1, this.y2, this.drawcolor);
            }
          }

        }
        else if (this.annotationType == 'freehand') {
          this.lines.push({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth });
          this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth }, this.drawcolor);
          this.addtoCanvasData('freehand', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", ""
            , "", "", "", "", "");
          this.startX = event.layerX;
          this.startY = event.layerY;

        }
        else if (this.annotationType == 'circle') {
          this.clearDrawingCanvas();
          let ctx = this.canvasElement.getContext('2d');
          //this.drawCircle1(ctx,Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY));
          this.drawCircle(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor, this.strokewidth);
        }
        else if (this.annotationType == 'rectangle') {
          this.clearDrawingCanvas();
          let ctx = this.canvasElement.getContext('2d');
          this.drawRectangle(ctx, Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY), this.drawcolor, this.strokewidth);

        }
        else if (this.annotationType == 'arrow') {
          this.clearDrawingCanvas();
          let ctx = this.canvasElement.getContext('2d');

          ctx.strokeStyle = this.drawcolor;
          this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor, this.strokewidth);
        }
        // else if (this.annotationType == 'angle') {
        //   this.clearDrawingCanvas();
        //   let ctx = this.canvasElement.getContext('2d');

        //   ctx.strokeStyle = 'yellow';
        //   this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY);
        //   this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY);

        // }
      }
      else {
        if (this.selectedText < 0) {
          return;
        }
        this.ismove = true;
        event.preventDefault();
        this.clearTextCanvas();

        var mouseX = event.layerX;
        var mouseY = event.layerY;

        // Put your mousemove stuff here
        var dx = mouseX - this.startX;
        var dy = mouseY - this.startY;
        this.startX = mouseX;
        this.startY = mouseY;

        var text = this.textArray[this.selectedText];
        var selIndex = this.canvasData.findIndex(option => option.TextContent == text.text && option.X0 == String(text.x) && option.Y0 == String(text.y));
        text.x += dx;
        text.y += dy;
        if (selIndex >= 0) {
          this.canvasData[selIndex].X0 = String(text.x);
          this.canvasData[selIndex].Y0 = String(text.y);
        }
        let ctx = this.canvasElement3.getContext('2d');
        this.drawText(ctx, this.textArray);
      }
    } else if (this.mousedown) {
      var x2 = event.offsetX;
      var y2 = event.offsetY;
      var xOffset = x2 - this.annotationtoSelected.X0;
      var yOffset = y2 - this.annotationtoSelected.Y0;
      // this.x1 = x2;
      // this.y1 = y2;
      var selIndex = Number(this.lstCanvasAnnotationDetails.findIndex(option => option.sessionId == this.annotationtoSelected.sessionId
        && option.VideoAnalyseID == this.annotationtoSelected.VideoAnalyseID
        && option.AnnotationID == this.annotationtoSelected.AnnotationID));
      if (this.selAnnotationtype == 'rectangle') {
        if (this.clickedArea.pos == 'i' ||
          this.clickedArea.pos == 'tl' ||
          this.clickedArea.pos == 'l' ||
          this.clickedArea.pos == 'bl') {
          this.lstCanvasAnnotationDetails[selIndex].X0 += xOffset;
        }
        if (this.clickedArea.pos == 'i' ||
          this.clickedArea.pos == 'tl' ||
          this.clickedArea.pos == 't' ||
          this.clickedArea.pos == 'tr') {
          this.lstCanvasAnnotationDetails[selIndex].Y0 += yOffset;
        }
        if (this.clickedArea.pos == 'i' ||
          this.clickedArea.pos == 'tr' ||
          this.clickedArea.pos == 'r' ||
          this.clickedArea.pos == 'br') {
          this.lstCanvasAnnotationDetails[selIndex].X1 += xOffset;
        }
        if (this.clickedArea.pos == 'i' ||
          this.clickedArea.pos == 'bl' ||
          this.clickedArea.pos == 'b' ||
          this.clickedArea.pos == 'br') {
          this.lstCanvasAnnotationDetails[selIndex].Y1 += yOffset;
        }
      } else if (this.selAnnotationtype == 'circle') {

      }
      this.clearCanvas();
      this.drawAnnotations(this.lstCanvasAnnotationDetails);
    }
  }
  handleMouseUpOut(event) {
    if (!this.isshapeedit) {
      this.startDraw = false;
      this.selectedText = -1;
      if (this.annotationType == 'line') {
        this.lines = [];
        this.lines.push({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth });
        this.clearDrawingCanvas();
        this.draw(this.lines, this.drawcolor);
        this.addtoCanvasData('line', this.startX, this.startY, event.layerX, event.layerY, "", "", this.strokewidth, this.drawcolor, "", "", "", "", "", "", "", "", "", "", "");
      }
      else if (this.annotationType == 'scale') {
        if (this.isScaleRular == true) {
          var xCorData = event.layerX - this.startX;
          var yCorData = event.layerY - this.startY;
          var dis = Math.sqrt(xCorData * xCorData + yCorData * yCorData);
          this.unitPixels = Math.round(dis);
          this.scaleassigned = true;
        } else {
          this.clearDrawingCanvas();
          let ctx = this.canvasElement2.getContext('2d');
          var x1 = this.startX;
          var x2 = event.layerX;
          var y1 = this.startY;
          var y2 = event.layerY;
          if (this.unitValue == undefined || this.unitType == undefined) {
            var xCorData = event.layerX - this.startX;
            var yCorData = event.layerY - this.startY;
            var dis = Math.sqrt(xCorData * xCorData + yCorData * yCorData);
            this.unitPixels = Math.round(dis);
            this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY, Linewidth: this.strokewidth }, this.drawcolor);
          }
          else {
            this.addtoCanvasData('scale', x1, y1, x2, y2, "", "", this.strokewidth, this.drawcolor, "", "", "", this.unitPixels, this.unitValue, this.unitType, "", "", "", "", "");

            this.drawLineText(ctx, x1, x2, y1, y2, this.drawcolor);
          }
          // this.drawLineText(ctx, x1, x2, y1, y2, this.drawcolor);

        }
      }
      else if (this.annotationType == 'freehand') {
        this.clearDrawingCanvas();
        this.draw(this.lines, this.drawcolor);
      }
      else if (this.annotationType == 'circle') {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement2.getContext('2d');
        //this.drawCircle1(ctx,Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY));
        this.drawCircle(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor, this.strokewidth);
        this.addtoCanvasData('circle', this.startX, this.startY, event.layerX, event.layerY, "", "", this.strokewidth, this.drawcolor, "", "", "", "", "", "", "", "", "", "", "");
      }
      else if (this.annotationType == 'rectangle') {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement2.getContext('2d');
        this.drawRectangle(ctx, Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY), this.drawcolor, this.strokewidth);
        this.addtoCanvasData('rectangle', this.startX, this.startY, event.layerX, event.layerY, "", "", this.strokewidth, this.drawcolor, "", "", "", "", "", "", "", "", "", "", "");
      }
      else if (this.annotationType == 'arrow') {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement2.getContext('2d');
        ctx.strokeStyle = this.drawcolor;
        this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor, this.strokewidth);
        this.addtoCanvasData('arrow', this.startX, this.startY, event.layerX, event.layerY, "", "", this.strokewidth, this.drawcolor, "", "", "", "", "", "", "", "", "", "", "");
      }
      else {
        // alert('select asd shape');
      }
    } else if (this.mousedown) {
      if (this.annotationtoSelected) {
        // this.isshapeedit = false;
        this.annotationtoSelected = null;
        this.mousedown = false;
        this.clickedArea = { box: -1, pos: 'o' };
      }
    }
  }

  addtoCanvasData(type, x0, y0, x1, y1, x2, y2, lwidth, color, canwidth, canheight, TextContent, unitPixels, unitValue, unitType, audio, fColor, fbColor, fSize, tWidth) {
    var x = this.canvasData.length;
    var id;
    if (x > 0) {
      id = String(Number(this.canvasData[x - 1].AnnotationID) + 1);
    }
    else id = "1";
    this.canvasData.push({
      AnnotationID: String(id), AnnotationType: type, X0: String(x0), Y0: String(y0),
      X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: String(lwidth),
      Color: color, Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
      , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: "",
      Audiourl: audio, FontColor: String(fColor), FontBackColor: String(fbColor), FontSize: String(fSize), TextWidth: String(tWidth), AnalysePlatform: "Web"
    });

    this.canvasTempData.push({
      AnnotationID: String(id), AnnotationType: type, X0: String(x0), Y0: String(y0),
      X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: String(lwidth),
      Color: color, Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
      , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: "",
      Audiourl: audio, FontColor: String(fColor), FontBackColor: String(fbColor), FontSize: String(fSize), TextWidth: String(tWidth), AnalysePlatform: "Web"
    });
    // }
    // else {
    //   this.canvasData.push({
    //     AnnotationID: "1", AnnotationType: type, X0: String(x0), Y0: String(y0),
    //     X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: lwidth, Color: color,
    //     Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
    //     , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: "",
    //     Audiourl: audio, FontColor: String(fColor), FontBackColor: String(fbColor), FontSize: String(fSize), TextWidth: String(tWidth)
    //   });

    //   this.canvasTempData.push({
    //     AnnotationID: "1", AnnotationType: type, X0: String(x0), Y0: String(y0),
    //     X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: lwidth, Color: color,
    //     Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
    //     , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: "",
    //     Audiourl: audio, FontColor: String(fColor), FontBackColor: String(fbColor), FontSize: String(fSize), TextWidth: String(tWidth)
    //   });
    // }
  }
  drawText(ctx, textArray) {
    ctx.clearRect(0, 0, ctx.width, ctx.height);
    //  ctx.drawImage(imageObj, 0, 0, 1920, 1080);
    for (var i = 0; i < textArray.length; i++) {

      var text = textArray[i];
      var w = text.text.length;
      w = w * text.fsize * 0.5;
      if (w > ctx.width) {
        w = ctx.width;
      }
      ctx.font = text.fsize + "px muktaregular";
      ctx.fillStyle = text.fbcolor;
      ctx.fillRect(text.x - 2, text.y - text.fsize, w, (text.fsize * 1.5));
      //ctx.fill();
      ctx.fillStyle = text.fcolor;
      ctx.fillText(text.text, text.x, text.y);
    }
  }
  drawLineText(ctx, x1, x2, y1, y2, color) {
    var dx = (x2 - x1);
    var dy = (y2 - y1);
    var pad;
    var alignment = "center";
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.strokeStyle = color;
    ctx.stroke();
    pad = 1 / 2;
    ctx.save();
    ctx.textAlign = alignment;
    ctx.textBaseline = "bottom";

    ctx.translate(x1 + dx * pad, y1 + dy * pad);
    if (dx < 0) {
      ctx.rotate(Math.atan2(dy, dx) - Math.PI);  //to avoid label upside down
    }
    else {
      ctx.rotate(Math.atan2(dy, dx));
    }
    var xCorData = x2 - x1;
    var yCorData = y2 - y1;
    var dis = Math.sqrt(xCorData * xCorData + yCorData * yCorData);
    if (dis > 0) {
      var pos = (dis / this.unitPixels) * this.unitValue;
      var txt = parseFloat(String(pos)).toFixed(2) + ' ' + this.unitType;
      ctx.fillStyle = color;
      ctx.font = "10px consolas";
      ctx.fillText(txt, 0, 0);
    }
    ctx.restore();

  }
  textFontSize: number = 14;
  changeFontSize(change) {
    this.textFontSize += Number(change);
  }
  addText(left, top, fsize, fcolor, backcolor, textval) {
    // calc the y coordinate for this text on the canvas
    var y = this.textArray.length * 20 + 14;
    let ctx = this.canvasElement3.getContext('2d');
    // get the text from the input element
    var text = {
      //text: $('#text_tool')[0].innerHTML,
      text: textval,
      x: left,
      y: top + fsize,
      width: 0,
      height: 0,
      fcolor: fcolor,
      fbcolor: backcolor,
      fsize: fsize
    };



    text.width = ctx.measureText(text.text).width;
    text.height = this.textFontSize;

    // put this new text in the texts array
    this.textArray.push(text);
    this.addtoCanvasData('text', text.x, text.y, "", "", "", "", "2", this.drawcolor, "", "", text.text, "", "", "", "", fcolor, backcolor, fsize, "");
    this.drawText(ctx, this.textArray);
    this.isTextEditor = false;
  }

  setScaleRular() {
    if (this.unitValue > 0 && this.unitType != "") {
      if (this.unitPixels > 0) {
        this.scaleArray.push({ unitValue: this.unitValue, unitType: this.unitType, unitPixels: this.unitPixels });
        this.isScaleRular = false;
      } else {
        this.isWarningAlert = true;
        this.warningAlert = "Draw a line to measure";
        // alert("Draw a line to measure");
      }
    }
    else {
      this.isWarningAlert = true;
      this.warningAlert = "Please select unit values";
      // alert("Please select unit values");
    }
  }

  clearDrawingCanvas() {
    let ctx = this.canvasElement.getContext('2d');
    let cw = this.canvasElement.width;
    let ch = this.canvasElement.height;
    ctx.clearRect(0, 0, cw, ch);

  }
  clearCanvas() {
    let ctx = this.canvasElement2.getContext('2d');
    let cw = this.canvasElement2.width;
    let ch = this.canvasElement2.height;
    ctx.clearRect(0, 0, cw, ch);

  }
  clearTextCanvas() {
    
    let ctx = this.canvasElement3.getContext('2d');
    let cw = this.canvasElement3.width;
    let ch = this.canvasElement3.height;
    ctx.clearRect(0, 0, cw, ch);

  }
  title = 'micRecorder';
  //Lets declare Record OBJ
  record;
  //Will use this flag for toggeling recording
  recording = false;
  //URL of Blob
  url;
  error;
  isaudio: boolean = false;
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  changeAnnotation(type) {
    this.annotationType = type;
    this.isshapeedit = false;
    if (type == "angle") {
      this.clickCount = 0;
    }
    if (type == "text") {
      this.isTextEditor = true;
    }
    if (type == "scale") {
      this.isScaleRular = true;
      this.scaleArray = [];
    }
    if (type == 'audio') {
      this.isaudio = true;
    }
    if (type == 'shapeedit') {
      this.isshapeedit = true;
    }
  }
  textInput!: string;
  inputEnter() {

    this.isText = false;
    this.addText(this.textLeft, this.textTop, this.textFontSize, this.fontcolor, this.fontbackcolor, $('#text_tool')[0].innerHTML);
    $('#text_tool')[0].innerHTML = "";
  }
  initiateRecording() {
    this.url = null;
    this.recording = true;

    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  successCallback(stream) {
    var options = {
      mimeType: 'audio/webm', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
      bitsPerSecond: 128000 // if this line is provided, skip above two
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }
  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }

  audiourl: string;
  onAudiodata(event, data) {
    debugger;
    this.ImageDetails = [];
    this.ImageDetails.push({ data: this.audiosample, name: 'audio/wav' });

  }
  saveAudioComment() {
    if (this.ImageDetails[0].name != '') {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        this.audiourl = ret.name;
        this.addtoCanvasData('audio', "", "", "", "", "", "", "", "", "", "", "", "", "", "", this.audiourl, "", "", "", "");
      });
    }
    else {
      this.isErrorAlert = true;
      this.errorAlert = "Please record your comments to continue!!"
    }
  }
  cancelAudio() {
    this.isaudio = false;
  }
  /**
  * processRecording Do what ever you want with blob
  * @param  {any} blob Blog
  */
  public audiosample: any;
  processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    var reader = new FileReader();
    var x;
    reader.readAsDataURL(blob);
    reader.onloadend = (evt: any) => {
      this.audiosample = reader.result;
    }



    // this.formData.append('key', blob);
    console.log("blob", blob);
    console.log("url", this.url);

  }
  /**
  * Process Error.
  */
  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }

  public ThumbURL: string;
  saveAnnotation(videoplayer) {
    let ctx = this.canvasElement2.getContext('2d');
    this.drawText(ctx, this.textArray);
    if (this.canvasData.length > 0) {
      this.isShowLoader = true;
      var canvasSnap = <HTMLCanvasElement>document.getElementById('canvasDraw4');
      var videoSnap = <HTMLCanvasElement>document.getElementById('videoplayer');
      var annoSnap = <HTMLCanvasElement>document.getElementById('canvasDraw2');
      var context = canvasSnap.getContext('2d');
      if (videoSnap.width > videoplayer.videoWidth) {
        var hRatio = videoSnap.width / videoplayer.videoWidth;
        var vRatio = videoSnap.height / videoplayer.videoHeight;
        var ratio = Math.min(hRatio, vRatio);
        var centerShift_x = (videoSnap.width - videoplayer.videoWidth * ratio) / 2;
        var centerShift_y = (videoSnap.height - videoplayer.videoHeight * ratio) / 2;

        context.drawImage(videoSnap, 0, 0, videoplayer.videoWidth, videoplayer.videoHeight, centerShift_x, centerShift_y
          , videoplayer.videoWidth * ratio, videoplayer.videoHeight * ratio);
      } else {
        context.drawImage(videoSnap, 0, 0, videoSnap.width, videoSnap.height);
      }
      context.drawImage(annoSnap, 0, 0, canvasSnap.width, canvasSnap.height);

      this.service.UploadFile({ data: canvasSnap.toDataURL(), name: "image/png" }).subscribe((ret: any = []) => {
        this.ThumbURL = ret.name;
        this.service.VideoAnalyseCRUD({
          ActionFlag: 'CreateVideoAnnotations', RefUserID: this.UserCode, SessionID: this.SessionID,
          VideoAnalyseID: '', FrameTime: String(this.AnnotationTime),
          lstAnnotations: this.canvasData, Thumbnail: this.ThumbURL
        }).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          this.clearCanvas();
          this.canvasData = [];
          this.textArray=[];
          this.clearTextCanvas();
          this.showcanvas = false;
          this.service.VideoAnalyseCRUD({ ActionFlag: 'FetchAnnotationsBySession', RefUserID: this.UserCode, SessionID: this.SessionID }).subscribe((ret: any = []) => {
            let BTStatus = String(ret.Status);
            this.isShowLoader = false;
            if (ret.Status) {
              this.lstAnnotations = ret.lstAnnotations;
              this.lstAnnotationDetails = ret.lstAnnotationDetails;
              this.lstAnnotationReviews = ret.lstAnnotationReviews;

              videoplayer.currentTime = this.AnnotationTime;
              var nxttime = this.lstAnnotations.filter(option => Number(option.FrameTime) >= this.AnnotationTime);
              if (nxttime.length > 0)
                this.NextAnnotationTime = nxttime[0].FrameTime;
              else
                this.NextAnnotationTime = videoplayer.duration;
            }
          });
        });
      });
    }
    else {
      this.isErrorAlert = true;
      this.errorAlert = "Please draw annotation to save";

    }
  }

  FetchAnnotations() {
    this.service.VideoAnalyseCRUD({ ActionFlag: 'FetchAnnotationsBySession', RefUserID: String(this.UserCode), SessionID: this.SessionID }).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (ret.Status) {
        this.lstAnnotations = ret.lstAnnotations;
        this.lstAnnotationDetails = ret.lstAnnotationDetails;
        this.clearCanvas();
        this.canvasData = [];
        this.showcanvas = false;
        if (this.lstAnnotations.length > 0)
          this.NextAnnotationTime = this.lstAnnotations[0].FrameTime;
        this.annotationNumber = 0;

      }
    });
  }
  play: boolean = true;
  tagplay: boolean = true;
  startAnnotation(videoplayer) {
    this.canvasData = [];
    this.clearCanvas();
    this.clearDrawingCanvas();
    this.clearTextCanvas();
    videoplayer.pause();
    this.play = true;
    this.AnnotationTime = videoplayer.currentTime;
    this.showcanvas = true;
    this.lines = [];
  }
  playTags(videoplayer) {
    if (this.tagplay == true) {
      this.tagplay = false;
      videoplayer.play();
      this.play = false;
    }
    else {
      this.tagplay = true;
      videoplayer.pause();
      this.play = true;
    }
  }
  resume(videoplayer) {
    this.isshowannotations = false;
    this.isshapeedit = false;
    if (this.annotationType == 'shapeedit')
      this.annotationType = '';
    if (this.play == true) {
      videoplayer.play();
      this.play = false;
      this.canvasData = [];
      this.textArray=[];
      this.clearCanvas();
      this.clearDrawingCanvas();
      this.clearTextCanvas();
      // this.audiocommentUrl = null;

      // this.showcanvas = false;
    } else {
      this.startAnnotation(videoplayer);
    }
  }
  skip(val, videoplayer) {
    videoplayer.currentTime += val;
  }

  videoEnded(videoplayer) {
    this.NextAnnotationTime = this.lstAnnotations[0].FrameTime;
    videoplayer.pause();
    this.play = true;
  }
  // ondata(event, vp) {
  //   if (this.service.lastPage == "myvideos") {
  //     this.service.lastPage = this.router.url;
  //     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //     this.router.onSameUrlNavigation = 'reload';
  //     this.router.navigate([this.service.lastPage]);
  //   }
  // }
  seekpercent: any;
  setCurrentTime(videoplayer) {

    var percentage = (videoplayer.currentTime / videoplayer.duration) * 100;
    this.videocurrtime = Math.floor(videoplayer.currentTime / 60) % 60 + ':' + this.pad((Math.floor(videoplayer.currentTime % 60)), 2);
    this.videoduration = Math.floor(videoplayer.duration / 60) % 60 + ':' + this.pad((Math.floor(videoplayer.duration % 60)), 2);
    this.seekpercent = (percentage - 5) + "%";
    $("#custom-seekbar").css("width", percentage + "%");
    // if (parseFloat(videoplayer.currentTime).toFixed(1) == (parseFloat(String(this.NextAnnotationTime)).toFixed(1))) {
    if (this.selectedTab == 'T') {
      if (videoplayer.currentTime >= this.tagEndTime) {
        videoplayer.pause();
        this.play = true;
        if (this.lstSessionTagdetails.length > this.tagIndex + 1)
          this.PlayTagVideo(this.lstSessionTagdetails[this.tagIndex + 1], videoplayer, this.tagIndex + 1)
      }
    }
    else if (this.selectedTab == 'R') {
      if (videoplayer.currentTime >= this.NextAnnotationTime) {
        videoplayer.pause();
        this.play = true;
        videoplayer.currentTime = this.NextAnnotationTime;
        if (videoplayer.currentTime == 0)
          videoplayer.currentTime = String(this.NextAnnotationTime);
        this.showcanvas = true;
        this.AnnotationTime = videoplayer.currentTime;
        //var x= this.lstAnnotations[this.annotationNumber].FrameTime;
        this.lstCanvasAnnotationDetails = this.lstAnnotationDetails.filter(option => Number(option.FrameTime) === this.NextAnnotationTime);
        this.clearCanvas();
        this.audiocommentUrl = null;
        this.drawCanvasImage(this.lstCanvasAnnotationDetails[0].Thumbnail);
        //this.drawAnnotations(this.lstCanvasAnnotationDetails);
        this.isshowannotations = true;
        var x = this.lstAnnotations.filter(option => option.FrameTime > this.NextAnnotationTime)
        if (x.length > 0)
          this.NextAnnotationTime = x[0].FrameTime;
        else
          this.NextAnnotationTime = videoplayer.duration;
      }
    }
  }
  pagemouseup(ev) {
    this.seekbarmove = false;
  }
  ClearAll(){
    this.canvasData = [];
    this.clearCanvas();
    this.clearDrawingCanvas();
    this.clearTextCanvas();
  }
  seektotime: any;
  seekbarclick(event, videoplayer) {

    if (event.type == 'mousedown') {
      this.seekbarmove = true;
    } else if (event.type == 'mouseup') {
      this.seekbarmove = false;
    }
    else if ((event.type == 'mousemove' && this.seekbarmove === true) || event.type == 'click') {

      this.audiocommentUrl = null;
      var offset = event.offsetX;
      var percentage = (offset / event.currentTarget.clientWidth);
      var vidTime = videoplayer.duration * percentage;
      videoplayer.currentTime = vidTime;
      var nxttime = this.lstAnnotations.filter(option => Number(option.FrameTime) >= vidTime);
      if (nxttime.length > 0)
        this.NextAnnotationTime = nxttime[0].FrameTime;
      else
        this.NextAnnotationTime = videoplayer.duration;
      this.clearCanvas();
      this.canvasData = [];
      this.AnnotationTime = videoplayer.currentTime;

    }
    else if (event.type == 'mousemove' && this.seekbarmove === false) {
      var offset = event.offsetX;
      var percentage = (offset / event.currentTarget.clientWidth);
      var vidTime = videoplayer.duration * percentage;
      this.seektotime = Math.floor(vidTime / 60) % 60 + ':' + this.pad((Math.floor(vidTime % 60)), 2);

    }
  }

  tagpointClick(event, review, videoplayer, ind) {
    this.audiocommentUrl = null;
    var percentage = (Number(review.FrameTime) / videoplayer.duration) * 100;
    var vidTime = Number(review.FrameTime);
    videoplayer.currentTime = vidTime;
    var nxttime = this.lstAnnotations.filter(option => Number(option.FrameTime) >= vidTime);
    if (nxttime.length > 0)
      this.NextAnnotationTime = nxttime[0].FrameTime;
    else
      this.NextAnnotationTime = videoplayer.duration;
    this.clearCanvas();
    this.canvasData = [];
    this.AnnotationTime = videoplayer.currentTime;

    this.playVideoByFrame(review, videoplayer, ind);
  }

  playVideo(vPlayer) {
    vPlayer.play();
    this.clearCanvas();
    this.canvasData = [];
  }

  unitTypeChange() {
    this.unitPixels = 0;
  }

  drawAnnotations(list) {
    //this.clearCanvas();
    //this.audiocommentUrl = null;
    this.textArray = [];
    for (var i = 0; i < list.length; i++) {
      // setTimeout(function () {
      // }, 500);
      if (list[i].AnnotationType == 'line') {
        this.lines = [];
        this.lines.push({ x0: list[i].X0, y0: list[i].Y0, x1: list[i].X1, y1: list[i].Y1, Linewidth: list[i].Linewidth });
        this.draw(this.lines, list[i].Color);
      }
      else if (list[i].AnnotationType == 'freehand') {
        this.lines = [];
        this.lines.push({ x0: list[i].X0, y0: list[i].Y0, x1: list[i].X1, y1: list[i].Y1, Linewidth: list[i].Linewidth });
        this.draw(this.lines, list[i].Color);
      }
      else if (list[i].AnnotationType == 'circle') {
        let ctx = this.canvasElement2.getContext('2d');
        this.drawCircle(ctx, list[i].X0, list[i].Y0, list[i].X1, list[i].Y1, list[i].Color, list[i].Linewidth);
      }
      else if (list[i].AnnotationType == 'rectangle') {
        let ctx = this.canvasElement2.getContext('2d');
        //this.drawCircle(ctx, list[i].X0, list[i].Y0, list[i].X1, list[i].Y1);
        this.drawRectangle(ctx, Math.min(list[i].X0, list[i].X1), Math.min(list[i].Y0, list[i].Y1), Math.abs(list[i].X0 - list[i].X1), Math.abs(list[i].Y0 - list[i].Y1), list[i].Color, list[i].Linewidth);
      }
      else if (list[i].AnnotationType == 'arrow') {
        let ctx = this.canvasElement2.getContext('2d');
        ctx.strokeStyle = list[i].Color;
        this.drawArrow(ctx, list[i].X0, list[i].Y0, list[i].X1, list[i].Y1, list[i].Color, list[i].Linewidth);
      }
      else if (list[i].AnnotationType == 'angle') {
        let ctx = this.canvasElement2.getContext('2d');
        ctx.strokeStyle = list[i].Color;
        this.lines = [];
        this.lines.push({ x0: list[i].X0, y0: list[i].Y0, x1: list[i].X1, y1: list[i].Y1, Linewidth: list[i].Linewidth });
        this.lines.push({ x0: list[i].X1, y0: list[i].Y1, x1: list[i].X2, y1: list[i].Y2, Linewidth: list[i].Linewidth });
        this.draw(this.lines, list[i].Color);
        this.drawAngleSymbol(list[i].X0, list[i].X1, list[i].X2, list[i].Y0, list[i].Y1, list[i].Y2, list[i].Color);
      }
      else if (list[i].AnnotationType == 'scale') {
        let ctx = this.canvasElement2.getContext('2d');
        ctx.strokeStyle = list[i].Color;
        this.unitPixels = list[i].UnitPixels;
        this.unitValue = list[i].UnitValue;
        this.unitType = list[i].UnitType;
        this.drawLineText(ctx, list[i].X0, list[i].X1, list[i].Y0, list[i].Y1, list[i].Color);
      }
      else if (list[i].AnnotationType == 'text') {
        let ctx = this.canvasElement2.getContext('2d');
        var text = {
          text: list[i].TextContent,
          x: list[i].X0,
          y: list[i].Y0,
          width: list[i].X1,
          height: list[i].Y1,
          fcolor: list[i].FontColor,
          fbcolor: list[i].FontBackColor,
          fsize: list[i].FontSize,
        };



        // calc the size of this text for hit-testing purposes
        ctx.font = "14px consolas";
        ctx.fillStyle = list[i].Color;
        text.width = ctx.measureText(text.text).width;
        text.height = 14;

        // put this new text in the texts array
        this.textArray.push(text);
        this.drawText(ctx, this.textArray);

      }
      else if (list[i].AnnotationType == 'audio') {
        this.audiocommentUrl = list[i].Audiourl;
        this.audiocommentUrl = this.sanitize(this.audiocommentUrl);
      }
    }
    this.textArray = [];
  }
  audiocommentUrl: any;
  undoAnnotation(videoplayer) {
    this.clearCanvas();
    this.clearDrawingCanvas();
    if (this.canvasData.length > 0) {
      this.canvasData.splice(this.canvasData.length - 1, 1);
      this.drawAnnotations(this.canvasData);
    }
    if (this.lstAnnotationDetails > 0) {
      this.drawAnnotations(this.lstAnnotationDetails);
    }
  }

  compareVideos(status) {
    this.videoanalyse = !status;
    this.videocompare = status;
  }

  // changeVideoStatus(videoCompare1, videoCompare2) {
  //   if (this.videoStatus == 'play') {
  //     this.videoStatus = 'pause';
  //     videoCompare1.pause();
  //     videoCompare2.pause();
  //   }
  //   else {
  //     this.videoStatus = 'play';
  //     videoCompare1.play();
  //     videoCompare2.play();
  //   }
  // }
  changeVideoStatus() {
    if (this.videoStatus == 'play') {
      this.videoStatus = 'pause';
    }
    else {
      this.videoStatus = 'play';
    }
  }

  Linktimeline(videoCompare1, videoCompare2) {
    videoCompare1.pause();
    videoCompare2.pause();
    var vid1duration = videoCompare1.duration - videoCompare1.currentTime;
    var vid2duration = videoCompare2.duration - videoCompare2.currentTime;
    this.compareduration = Math.min(vid1duration, vid2duration);
    this.videocompare1Starttime = videoCompare1.currentTime;
    this.videocompare2Starttime = videoCompare2.currentTime;
    if (this.compareduration == vid1duration)
      this.refVideotoCompare = 1;
    else if (this.compareduration == vid2duration)
      this.refVideotoCompare = 2;

    this.currentDurationTime = Math.floor(this.compareduration / 60) % 60 + ':' + this.pad((Math.floor(this.compareduration % 60)), 2) + ' Mins';
  }

  currentCompareTime: string = '0:00 Mins';
  currentDurationTime: string = '0:00 Mins';
  pad(n, width) {
    var z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  setCompareTime(videoCompare1, videoCompare2) {
    var percentage = 0;
    if (this.refVideotoCompare == 1) {
      percentage = ((videoCompare1.currentTime - this.videocompare1Starttime) / this.compareduration) * 100;
      this.currentCompareTime = Math.floor((videoCompare1.currentTime - this.videocompare1Starttime) / 60) % 60 + ':' + this.pad((Math.floor((videoCompare1.currentTime - this.videocompare1Starttime) % 60)), 2) + ' Mins';
    }
    else {
      percentage = ((videoCompare2.currentTime - this.videocompare2Starttime) / this.compareduration) * 100;
      this.currentCompareTime = Math.floor((videoCompare2.currentTime - this.videocompare2Starttime) / 60) % 60 + ':' + this.pad((Math.floor((videoCompare2.currentTime - this.videocompare2Starttime) % 60)), 2) + ' Mins';
    }

    $("#compare-seekbar div").css("width", percentage + "%");
    if (percentage == 100) {
      videoCompare1.pause();
      videoCompare2.pause();
    }

    // if (parseFloat(videoplayer.currentTime).toFixed(1) == (parseFloat(String(this.NextAnnotationTime)).toFixed(1))) {
    // if (videoplayer.currentTime >= this.NextAnnotationTime) {
    //   videoplayer.pause();
    //   videoplayer.currentTime = this.NextAnnotationTime;
    //   this.showcanvas = true;
    //   this.AnnotationTime = videoplayer.currentTime;
    //   //var x= this.lstAnnotations[this.annotationNumber].FrameTime;
    //   this.lstCanvasAnnotationDetails = this.lstAnnotationDetails.filter(option => Number(option.FrameTime) === this.NextAnnotationTime);
    //   this.clearCanvas();
    //   this.drawAnnotations(this.lstCanvasAnnotationDetails);
    //   var x = this.lstAnnotations.filter(option => option.FrameTime > this.NextAnnotationTime)
    //   if (x.length > 0)
    //     this.NextAnnotationTime = x[0].FrameTime;
    //   else
    //     this.NextAnnotationTime = videoplayer.duration;
    // }  
  }

  compareseekbarmove: boolean;
  compareSeekbarclick(event, videoCompare1, videoCompare2) {
    if (event.type == 'mousedown') {
      this.compareseekbarmove = true;
    } else if (event.type == 'mouseup') {
      this.compareseekbarmove = false;
    }
    else if ((event.type == 'mousemove' && this.compareseekbarmove === true) || event.type == 'click') {
      var offset = event.offsetX;
      var percentage = (offset / event.currentTarget.clientWidth);
      var vidTime = this.compareduration * percentage;
      videoCompare1.currentTime = (this.compareduration * percentage) + this.videocompare1Starttime;
      videoCompare2.currentTime = (this.compareduration * percentage) + this.videocompare2Starttime;
    }
  }

  routeBack() {

    this.router.navigate([this.utility.previousPage]);
  }
  isshowannotations: boolean = false;
  drawCanvasImage(data) {

    var canvas = <HTMLCanvasElement>document.getElementById('canvasDraw4');
    var context = canvas.getContext('2d');
    // load image from data url
    var imageObj = new Image();
    imageObj.setAttribute('crossOrigin', 'anonymous');
    //imageObj.crossOrigin="anonymous";
    imageObj.onload = function () {
      context.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height);

      var annoSnap = <HTMLCanvasElement>document.getElementById('canvasDraw2');
      var context1 = annoSnap.getContext('2d');
      if (imageObj.height > imageObj.width) {
        var hRatio = annoSnap.width / imageObj.width;
        var vRatio = annoSnap.height / imageObj.height;
        var ratio = Math.min(hRatio, vRatio);
        var centerShift_x = (annoSnap.width - imageObj.width * ratio) / 2;
        var centerShift_y = (annoSnap.height - imageObj.height * ratio) / 2;

        context1.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height, centerShift_x - 3, -6
          , (imageObj.width * ratio) + 6, (imageObj.height * ratio) + 11);
      } else {
        context1.drawImage(imageObj, 0, 0, 796, 450);
      }
      // context1.drawImage(annoSnap, 0, 0, canvasSnap.width, canvasSnap.height);
      context.clearRect(0, 0, imageObj.width, imageObj.height);
    };
    imageObj.src = data;

  }
  playVideoByFrame(data, videoplayer, ind) {
    this.audiocommentUrl = null;
    this.isshowannotations = true;
    var percentage = (data.CurrentTime / videoplayer.duration) * 100;
    this.videocurrtime = Math.floor(videoplayer.currentTime / 60) % 60 + ':' + this.pad((Math.floor(videoplayer.currentTime % 60)), 2);
    this.videoduration = Math.floor(videoplayer.duration / 60) % 60 + ':' + this.pad((Math.floor(videoplayer.duration % 60)), 2);

    $("#custom-seekbar span").css("width", percentage + "%");
    videoplayer.pause();
    this.play = true;
    videoplayer.currentTime = Number(data.CurrentTime);
    this.showcanvas = true;
    this.AnnotationTime = Number(data.CurrentTime);
    this.lstCanvasAnn = this.lstAnnotationDetails.filter(option => (option.FrameTime) === data.CurrentTime);
    this.clearCanvas();
    // This code is to show the canvas image 
    // this.drawCanvasImage(data.Thumbnail);
    // This code is to show the canvas drawing annotations by points 
    this.drawAnnotations(this.lstCanvasAnn);//drawing annotations by points
    if (this.lstAnnotationReviews.length - 1 > ind) {
      this.NextAnnotationTime = Number(this.lstAnnotationReviews[ind + 1].CurrentTime);
    }
    else {
      this.NextAnnotationTime = videoplayer.duration;
    }
  }
  ReviewsLoad(videoplayer) {
    this.selectedTab = "R";
    videoplayer.pause();
    this.play = true;
    this.isTagVideo = false;
  }
  lstSides: any;
  lstFootMovements: any;
  lstPMLengths: any;
  lstShotQuality: any;
  lstShots: any;
  lstBallTypes: any;
  lstSessionTagdetails: any;
  selectedTab: string = "R";
  TagsLoad(videoplayer) {
    this.selectedTab = "T";
    videoplayer.pause();
    this.play = true;
    this.service.VideoAnalyseTags({ ActionFlag: 'Load', RefUserID: String(this.UserCode), SessionID: this.SessionID }).subscribe((ret: any = []) => {
      if (ret.Status) {
        this.lstSides = ret.lstSides;
        this.lstFootMovements = ret.lstFootMovements;
        this.lstPMLengths = ret.lstPMLengths;
        this.lstShotQuality = ret.lstShotQuality;
        this.lstShots = ret.lstShots;
        this.lstBallTypes = ret.lstBallTypes;
        this.showcanvas = false;
        this.GetTagsDetails('Side', 'All');
      }
    });
  }
  tagContainer!: boolean;
  GetTagsDetails(filterType, filterID) {
    this.service.VideoAnalyseTags({ ActionFlag: 'GetTagDetails', RefUserID: String(this.UserCode), SessionID: this.SessionID, FilterType: filterType, FilterID: String(filterID) }).subscribe((ret: any = []) => {
      if (ret.Status) {
        this.lstSessionTagdetails = ret.lstSessionTagdetails;
        this.showcanvas = false;
        this.tagContainer = false;
      }
    });
  }
  tagVideo!: string;
  tagStartTime!: number;
  tagEndTime!: number;
  tagIndex!: number;
  PlayTagVideo(tags, videoplayer, index) {
    // this.isTagVideo = true;
    this.tagIndex = index;
    this.tagVideo = tags.VideoURL;
    this.tagStartTime = tags.StartTime;
    this.tagEndTime = tags.EndTime;

    videoplayer.currentTime = this.tagStartTime;
    videoplayer.play();
    this.play = false;
    this.tagplay = false;
  }
  videocurrtime: string = "";
  videoduration: string = "";
  setCurrentTimeTag(videoplayer) {
    this.videocurrtime = Math.floor(videoplayer.currentTime / 60) % 60 + ':' + this.pad((Math.floor(videoplayer.currentTime % 60)), 2);
    this.videoduration = Math.floor(videoplayer.duration / 60) % 60 + ':' + this.pad((Math.floor(videoplayer.duration % 60)), 2);

    var percentage = (videoplayer.currentTime / videoplayer.duration) * 100;
    $("#custom-seekbar span").css("width", percentage + "%");
    // if (parseFloat(videoplayer.currentTime).toFixed(1) == (parseFloat(String(this.NextAnnotationTime)).toFixed(1))) {
    if (videoplayer.currentTime >= this.tagEndTime) {
      videoplayer.pause();
      this.play = true;
      if (this.lstSessionTagdetails.length > this.tagIndex + 1)
        this.PlayTagVideo(this.lstSessionTagdetails[this.tagIndex + 1], videoplayer, this.tagIndex + 1)
    }
  }
  TagvideoEnded(videoplayer) {
    videoplayer.pause();
    this.play = true;
  }
  lstWagonWheel: any = [];
  lstPitchMap: any = [];
  lstImpact: any = [];
  svgns: string = "http://www.w3.org/2000/svg";
  WWLine: boolean = false;
  isRHB: boolean = true;
  GraphsLoad() {
    this.service.VideoAnalyseGraphs({ ActionFlag: 'WagonWheel', RefUserID: String(this.UserCode), SessionID: this.SessionID }).subscribe((ret: any = []) => {
      if (ret.Status) {
        this.lstWagonWheel = ret.lstValues;
        var svgBattingEleWagonWheel = <HTMLElement>document.getElementById('wagonwheeldata');
        this.lstWagonWheel.forEach(element => {
          var line = <HTMLElement>document.createElementNS(this.svgns, 'line');
          line.setAttributeNS(null, 'x1', element.WWX1);
          line.setAttributeNS(null, 'x2', element.WWX2);
          line.setAttributeNS(null, 'y1', element.WWY1);
          line.setAttributeNS(null, 'y2', element.WWY2);
          line.setAttributeNS(null, 'style', 'stroke: #d30808; stroke-width : 2');
          svgBattingEleWagonWheel.append(line);
        });

      }
    });
  }
  lstPMValues: any = [];
  lstImpactValues: any = [];
  videoSpeed: any = 1;
  videoSpeedchange(videoplayer) {
    videoplayer.playbackRate = this.videoSpeed;

  }
  GraphslistLoad(val) {
    this.service.VideoAnalyseGraphs({ ActionFlag: val, RefUserID: String(this.UserCode), SessionID: this.SessionID }).subscribe((ret: any = []) => {
      if (ret.Status) {
        if (val == 'PitchMap') {
          this.lstPMValues = ret.lstValues;
          var svgPM = <HTMLElement>document.getElementById('picthmapdata');
          this.lstPMValues.forEach(element => {
            var BallImage = <HTMLElement>document.createElementNS(this.svgns, 'image');
            BallImage.setAttributeNS(null, 'x', element.PitchX);
            BallImage.setAttributeNS(null, 'y', element.PitchY);
            BallImage.setAttributeNS(null, 'visibility', "visible");
            BallImage.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', "assets/images/wkts.png");
            svgPM.append(BallImage);
          });
        } else if (val == 'Impact') {
          this.lstImpactValues = ret.lstValues;
          var svgPM = <HTMLElement>document.getElementById('impactdata');
          this.lstImpactValues.forEach(element => {
            var BallImage = <HTMLElement>document.createElementNS(this.svgns, 'image');
            BallImage.setAttributeNS(null, 'x', element.ImpactX);
            BallImage.setAttributeNS(null, 'y', element.ImpactY);
            BallImage.setAttributeNS(null, 'visibility', "visible");
            BallImage.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', "assets/images/wkts.png");
            svgPM.append(BallImage);
          });
        }
      }
    });
  }

  GetCompareVideos() {
    sessionStorage.setItem("sessionId", String(this.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(this.SessionVideo));
    sessionStorage.setItem("sessionPlayerName", String(this.PlayerName));
    sessionStorage.setItem("sessionPlayerRole", String(this.PlayerRole));
    sessionStorage.setItem("sessionVideoDate", String(this.VideoDate));
    sessionStorage.setItem("sessionProfileImage", String(this.ProfileImage));
    sessionStorage.setItem("sessionsThumbnail", String(this.sessionsThumbnail));
    sessionStorage.setItem("SessionTypeName", String(this.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(this.VideoViewName));
    sessionStorage.setItem("SessionName", String(this.SessionName));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));
    if (JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).MemberTypeID == 1) {
      sessionStorage.setItem("athleteID", String(sessionStorage.getItem('sessionUserCode')))
    }
    else
      sessionStorage.setItem("athleteID", String(sessionStorage.getItem('athleteID')))
    this.router.navigate(['/video-compare']);
  }

  // redoAnnotation(){
  //   if(this.canvasTempData.length>this.canvasData.length){
  //     this.canvasData.push(this.canvasTempData[this.canvasData.length]); 
  //     this.drawAnnotations(this.canvasData);
  //   }
  // }

  playballonlyChange() {
    this.GetTagsDetails('Side', 'All');
  }
  ReviewtoDelete: any;
  deletedata: string;
  DeleteReview(review) {
    this.ReviewtoDelete = review;
    this.isDeleteConfirm = true;
    this.deletedata = 'review';
  }
  CofirmDelete() {
    this.isDeleteConfirm = false;
    if (this.deletedata == 'review') {
      this.service.VideoAnalyseCRUD({
        ActionFlag: 'DeleteVideoAnnotations',
        RefUserID: String(this.UserCode),
        SessionID: String(this.ReviewtoDelete.SessionID),
        FrameTime: String(this.ReviewtoDelete.CurrentTime),
        Thumbnail: String(this.ReviewtoDelete.Thumbnail)
      }).subscribe((ret: any = []) => {
        this.AnnotationInitLoad();
      });
    }
  }

  @HostListener('document:keydown.delete', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    this.RemoveAnnotation();
  }
}

export interface canvasDetails {
  AnnotationID: string, AnnotationType: string, X0: string, Y0: string, X1: string,
  Y1: string, X2: string, Y2: string, Linewidth: string, Color: string, Canvaswidth: string,
  Canvasheight: string, TextContent: string, UnitPixels: string, UnitValue: string,
  UnitType: string, Thumbnail: string, Audiourl: string, FontColor: string, FontBackColor: string, FontSize: string, TextWidth: string, AnalysePlatform: string
}

export interface ImageDetails { data: any, name: string }