import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { BlobStorageService } from '../services/blob-storage.service';
import { stringify } from '@angular/compiler/src/util';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-coach-pad',
  templateUrl: './coach-pad.component.html',
  styleUrls: ['./coach-pad.component.css']
})
export class CoachPadComponent implements OnInit {
  public coachpad: string = 'formQualified';
  public UserCode: string;
  // public isCoachform=false;
  CoachInfoForm: FormGroup;
  CoachAboutMeForm: FormGroup; CoachBankInfo: FormGroup; CoachFeesInfo: FormGroup;
  config: UploadConfig;
  percent!: number;
  uploadedsize!: string;
  coachName!: string;
  coachLevel!: string;
  coachFees!: string;
  CoachVideoperSession!: string;
  CoachAvailability!: string;
  isAutoUpdate: boolean;
  AutoUpdate!: string;
  CoachImage!: string;
  CoachProSettingsID!: number;
  CertificateDocumentValue: string;
  CertificateLevel: string;
  Specialization: string;
  CertificateDocument: string;
  CoachExperience: string;
  CertificateAgency: string;

  BankName: string;
  BranchName: string;
  AccountHolderName: string;
  AccountNumber: string;
  IFSCcode: string;
  SwiftCode: string;

  FeesperSession: string;
  SessionsperDay: string;
  DaysperSession: string;
  VideosperSession: string;
  ChatEnabled: string;
  VideoUpload: string;
  AudioNotes: string;

  CoachPhotosvalue!: string;
  CoachPhotos!: any;
  CoachVideos!: any;
  n_BankName: string;
  n_BranchName: string;
  n_AccountHolderName: string;
  ConfirmAccountNumber: string;
  n_IFSCcode: string;
  n_SwiftCode: string;
  isSuccessAlert: boolean = false; isErrorAlert: boolean = false; isWarningAlert: boolean = false; isDeleteConfirm: boolean = false;
  successAlert: string = ""; errorAlert: string = ""; warningAlert: string = ""; deleteAlert: string = "";

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder
    , public datepipe: DatePipe, private blobup: BlobStorageService) { }
  @ViewChild("autoupdatetoggle") autoupdatetoggle: MatSlideToggle;
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).UserID);
    this.checkAvailability();
    this.CoachInfoForm = this.formBuilder.group({
      n_CertificateLevel: ['', Validators.required],
      n_CertificateAgency: ['', Validators.required],
      n_Specialization: ['', Validators.required],
      n_CertificateDoc: ['', Validators.required],
      n_Experience: ['', Validators.required]
    });

    this.CoachAboutMeForm = this.formBuilder.group({
      n_CoachAboutMe: ['', Validators.required],
      n_CoachTagLine: ['', Validators.required],
      n_CoachPhotos: '',
      n_CoachVideos: ''
    });
    this.CoachBankInfo = this.formBuilder.group({
      n_BankName: ['', Validators.required],
      n_BranchName: ['', Validators.required],
      n_AccountHolderName: ['', Validators.required],
      n_AccountNumber: ['', Validators.required],
      n_ConfirmAccountNumber: ['', Validators.required],
      n_IFSCcode: ['', Validators.required],
      n_SwiftCode: ['', Validators.required]
    },
      {
        validator: ConfirmValidator(this.AccountNumber, this.ConfirmAccountNumber)
      }
    );
    this.CoachFeesInfo = this.formBuilder.group({
      n_FeesperSession: ['', Validators.required],
      n_VideosperSession: ['', Validators.required]
    });
  }
  isEditStatus: boolean = false;
  showEditStatus() {
    this.isEditStatus = true;
  }
  checkAvailability() {
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachProfileAvailability",
      request: { ActionFlag: "ConfigExist", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      if (ret.ProSettingsID == "") {
        this.coachpad = 'formQualified';
      } else {
        this.coachpad = 'coachProfile';
        this.CoachProSettingsID = ret.ProSettingsID;
        this.coachName = ret.CoachName;
        this.coachLevel = ret.CoachLevel;
        this.coachFees = ret.FeePerSession;
        this.CoachVideos = ret.Noofvideos;
        this.CoachImage = ret.CoachImage;
        this.CoachAvailability = ret.AvailableStatus;
        this.AutoUpdate = ret.AutoUpdate;
        if (this.AutoUpdate == "1")
          this.isAutoUpdate = true;
        else
          this.isAutoUpdate = false;
        // this.getCoachInfoonLoad();
      }
    });
  }
  isautoupdate: string;
  ChangeAutoUpdate() {
    //this.coachpad = 'regForm';   
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachProfileCoachAvailability",
      request: { ActionFlag: "AutoUpdate", RefUserID: String(this.UserCode), ProSettingsID: this.CoachProSettingsID, AutoUpdate: this.isautoupdate }
    }).subscribe((ret: any = []) => {
      if (ret.Message = 'Success') {
        this.checkAvailability();
      }
    });
  }

  CertificateLevels: any;
  Experience: any;
  showCoachform() {
    this.coachpad = 'regForm';
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachProfileLoad",
      request: { ActionFlag: "Load", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      this.CertificateLevels = ret.CertificateLevels;
      this.Experience = ret.Experience;
    });
    this.getCoachInfoonLoad();
    //this.isCoachform=true;
  }
  getCoachInfoonLoad() {
    //this.coachpad = 'regForm';
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachProfileDetailsLoad",
      request: { ActionFlag: "GetConfig", RefUserID: this.UserCode, ProSettingsID: String(this.CoachProSettingsID) }
    }).subscribe((ret: any = []) => {
      var coachinfo = ret.coachInfo[0];
      this.CertificateLevel = coachinfo.CertificateLevel;
      this.Specialization = coachinfo.Specialization;
      this.CertificateDocument = coachinfo.CertificateUpload;
      this.CoachExperience = coachinfo.Experience;
      this.CertificateAgency = coachinfo.CertificateAgency;
      this.CertificateDocumentValue = coachinfo.CertificateUpload;
      this.CoachAboutMe = coachinfo.AboutMe;
      this.CoachTagLine = coachinfo.TagLine;

      var coachfeesinfo = ret.coachFeesInfo[0];
      this.FeesperSession = coachfeesinfo.FeePerSession;
      this.VideosperSession = coachfeesinfo.NoOfSessions;

      this.selectedvideosList = ret.coachVideos;
      this.selectedphotosList = ret.coachPhotos;

      var coachbankinfo = ret.coachBankInfo[0];
      this.BankName = coachbankinfo.BankName;
      this.BranchName = coachbankinfo.BranchName;
      this.AccountHolderName = coachbankinfo.AccountName;
      this.AccountNumber = coachbankinfo.AccountNumber;
      this.IFSCcode = coachbankinfo.IFSCCode;
      this.SwiftCode = coachbankinfo.SwiftCode;
    });
    //this.isCoachform=true;
  }
  today = new Date();
  randomid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  generateBlobUrl(
    params: UploadParams,
    filename: string,
    useAzureStorageEmulator = false,
    azureStorageEmulatorBaseUrl = '') {
    const url = useAzureStorageEmulator ? azureStorageEmulatorBaseUrl : `https://${params.storageAccount}.blob.core.windows.net`
    return `${url}/${params.containerName}/${filename}`
  }
  onCertificateDocChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.CertificateDocumentValue = event.target.value;
      reader.onload = () => {
        this.service.CoachProfileSettings({
          method: "CoachProfileSettings/getSASToken",
          request: { ActionFlag: "Load", RefUserID: this.UserCode }
        }).subscribe((ret: any = []) => {
          this.CertificateDocument = reader.result as string;
          Config.sas = ret;
          var splitted = file.type;
          var fileType = splitted.split("/", 3);  //&& "video/mov" && "video/mpeg" 
          var ext = file.name.split('.');
          var now = new Date().getTime();
          var filename = this.datepipe.transform(this.today, 'yyyy-MM-dd') + now + '-' + this.randomid(10);
          var VideoFileName = file.name.replace(file.name, filename) + '.' + ext[1];

          const baseUrl = this.generateBlobUrl(Config, VideoFileName)
          this.config = {
            baseUrl: baseUrl,
            blockSize: 1024 * 1024 * 2,
            sasToken: Config.sas,
            file: file,
            complete: () => {
              console.log('Transfer completed !')

            },
            error: (err) => {
              console.log('Error:', err)
            },
            progress: (percent) => {
              this.percent = percent,
                this.uploadedsize = (percent * (file.size / (1024 * 1024 * 100))).toFixed(2);
              if (this.percent == 100) {
                this.CertificateDocument = baseUrl;
              }


            }
          }
          this.blobup.upload(this.config);
        });
      };
    }
  }

  SaveCoachInfo(stepper) {
    if (this.CoachInfoForm.status == "VALID") {
      this.service.CoachProfileSettings({
        method: "CoachProfileSettings/CoachProfileCoachInfo",
        request: {
          ActionFlag: "Create", RefUserID: this.UserCode, Specialization: this.CoachInfoForm.controls.n_Specialization.value
          , Experience: String(this.CoachInfoForm.controls.n_Experience.value)
          , CertificateLevel: String(this.CoachInfoForm.controls.n_CertificateLevel.value)
          , CertificateUpload: this.CoachInfoForm.controls.n_CertificateDoc.value
          , CertificateAgency: this.CoachInfoForm.controls.n_CertificateAgency.value
        }
      }).subscribe((ret: any = []) => {

      });
      stepper.next();
    }
    else
      return;

  }
  CoachAboutMe: string;
  CoachTagLine: string;
  coachstatus: string;
  SaveCoachAboutInfo(stepper) {
    var photos: any = [];
    var videos: any = [];
    for (var i = 0; i < this.selectedphotosList.length; i++) {
      photos.push(this.selectedphotosList[i].PhotoURL);
    }
    for (var i = 0; i < this.selectedvideosList.length; i++) {
      videos.push(this.selectedvideosList[i].VideoURL);
    }
    if (this.CoachAboutMeForm.status == "VALID") {
      this.service.CoachProfileSettings({
        method: "CoachProfileSettings/CoachProfileAboutCoach",
        request: {
          ActionFlag: "UpdateAboutMyself", RefUserID: this.UserCode, ProSettingsID: this.CoachProSettingsID, AboutMe: this.CoachAboutMe
          , TagLine: this.CoachTagLine
          , PhotoURL: photos.toString()
          , VideoURL: videos.toString()
        }
      }).subscribe((ret: any = []) => {

      });
      stepper.next();
    }
    else
      return;

  }
  SaveCoachBankInfo(stepper) {
    if (this.CoachBankInfo.status == "VALID") {
      this.service.CoachProfileSettings({
        method: "CoachProfileSettings/CoachProfileBankInfo",
        request: {
          ActionFlag: "UpdateBankInfo", RefUserID: this.UserCode, ProSettingsID: this.CoachProSettingsID
          , AccountName: this.CoachBankInfo.controls.n_AccountHolderName.value
          , BankName: String(this.CoachBankInfo.controls.n_BankName.value)
          , BranchName: this.CoachBankInfo.controls.n_BranchName.value
          , AccountNumber: this.CoachBankInfo.controls.n_AccountNumber.value
          , IFSCCode: this.CoachBankInfo.controls.n_IFSCcode.value
          , SwiftCode: this.CoachBankInfo.controls.n_SwiftCode.value
        }
      }).subscribe((ret: any = []) => {

      });
      stepper.next();
    }
    else
      return;
  }
  isEditFees: boolean = false;
  SaveCoachFeesInfo(stepper) {
    if (this.CoachFeesInfo.status == "VALID") {
      this.service.CoachProfileSettings({
        method: "CoachProfileSettings/CoachProfileCoachFees",
        request: {
          ActionFlag: "UpdateCoachFee", RefUserID: this.UserCode, ProSettingsID: this.CoachProSettingsID
          , FeePerSession: this.CoachFeesInfo.controls.n_FeesperSession.value
          , NoOfVideoSessions: String(this.CoachFeesInfo.controls.n_VideosperSession.value)
        }
      }).subscribe((ret: any = []) => {

      });
      stepper.next();
    }
    else
      return;
  }
  coachFeesedit: string;
  CoachVideosedit: number;
  openEditFees() {
    this.coachFeesedit = this.coachFees;
    this.CoachVideosedit =Number(this.CoachVideos);
    this.isEditFees = true;
  }
  updateCoachFeesInfo() {
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachProfileCoachFees",
      request: {
        ActionFlag: "UpdateCoachFee", RefUserID: this.UserCode, ProSettingsID: this.CoachProSettingsID
        , FeePerSession: String(this.coachFeesedit)
        , NoOfVideoSessions: String(this.CoachVideosedit)
      }
    }).subscribe((ret: any = []) => {
      this.isEditFees = false;
      this.checkAvailability();
    });
  }
  AvailableStatus: number;
  ChangeStatus(staus) {
    this.AvailableStatus = staus;
  }
  updatetype: string = "";
  confirmUpdate(value, event) {
    this.isDeleteConfirm = true;
    this.updatetype = value;
    if (value == "status") {
      this.deleteAlert = "Are you sure you want to change the availability status";
    }
    else if (value == "autoupdate") {
      if (event.checked) {
        this.isautoupdate = "1";
        this.deleteAlert = "Are you sure you want to change enable auto update every month";
      }
      else {
        this.isautoupdate = "0";
        this.deleteAlert = "Are you sure you want to change disable auto update every month";
      }
    }
  }
  confirmedupdate() {
    if (this.updatetype == 'status') {
      this.UpdateStatus();
      this.isDeleteConfirm = false;
    } else if (this.updatetype == 'autoupdate') {
      this.ChangeAutoUpdate();
      this.isDeleteConfirm = false;
    }
  }
  cancelDelete(e) {
    this.isDeleteConfirm = false;
    if (this.updatetype == 'status') {
      this.isEditStatus = false;
    } else if (this.updatetype == 'autoupdate') {
      if (this.AutoUpdate == "1")
        this.autoupdatetoggle.checked = true;
      else
        this.autoupdatetoggle.checked = false;
    }


  }
  UpdateStatus() {
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachProfileCoachAvailability",
      request: {
        ActionFlag: "UpdateAvailablity", RefUserID: this.UserCode, ProSettingsID: this.CoachProSettingsID
        , AvailableStatus: String(this.AvailableStatus)
      }
    }).subscribe((ret: any = []) => {
      if (ret.Message = 'Success') {
        this.isEditStatus = false;
        this.checkAvailability();
      }
    });
  }
  sasTokenforUpload: string = "";
  selectedphotos: any = [];
  selectedphotosList: any = [];
  currentFile: number = 0;
  onPhotosChange(event) {
    this.selectedphotos = event.target.files;
    for (var i = 0; i < this.selectedphotos.length; i++) {
      this.selectedphotosList.push({ PhotoURL: this.selectedphotos[i].name, status: false });
    }
    this.uploadfiles(this.currentFile, 'photo')
  }
  selectedvideos: any = [];
  selectedvideosList: any = [];
  onVideosChange(event) {
    this.selectedvideos = event.target.files;
    for (var i = 0; i < this.selectedvideos.length; i++) {
      this.selectedvideosList.push({ VideoURL: this.selectedvideos[i].name, status: false });
    }
    this.uploadfiles(this.currentFile, 'video')
  }

  uploadfiles(filecount, type) {
    if (this.sasTokenforUpload == "") {
      this.service.CoachProfileSettings({
        method: "CoachProfileSettings/getSASToken",
        request: { ActionFlag: "Load", RefUserID: this.UserCode }
      }).subscribe((ret: any = []) => {
        this.sasTokenforUpload = ret;
        if (type == 'photo') {
          if (filecount < this.selectedphotos.length) {
            if (this.selectedphotosList[filecount].status == false) {
              this.currentFile = filecount;
              this.uploadtoazure(this.selectedphotos[filecount], 'photo');
            }
          }
        }
        else if (type == 'video') {
          if (filecount < this.selectedvideos.length) {
            if (this.selectedvideosList[filecount].status == false) {
              this.currentFile = filecount;
              this.uploadtoazure(this.selectedvideos[filecount], 'video');
            }
          }
        }
      });
    }
    else {
      if (type == 'photo') {
        if (filecount < this.selectedphotos.length) {
          if (this.selectedphotosList[filecount].status == false) {
            this.currentFile = filecount;
            this.uploadtoazure(this.selectedphotos[filecount], 'photo');
          }
        }
        else {
          this.currentFile = 0;
        }
      }
      else if (type == 'video') {
        if (filecount < this.selectedvideos.length) {
          if (this.selectedvideosList[filecount].status == false) {
            this.currentFile = filecount;
            this.uploadtoazure(this.selectedvideos[filecount], 'video');
          }
        }
        else {
          this.currentFile = 0;
        }
      }
    }
  }

  uploadtoazure(files: any, type: string) {

    const reader = new FileReader();
    const file: File = files;
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.CertificateDocument = reader.result as string;
      Config.sas = this.sasTokenforUpload;
      var splitted = file.type;
      var fileType = splitted.split("/", 3);  //&& "video/mov" && "video/mpeg" 
      var ext = file.name.split('.');
      var now = new Date().getTime();
      var filename = this.datepipe.transform(this.today, 'yyyy-MM-dd') + now + '-' + this.randomid(10);
      var VideoFileName = file.name.replace(file.name, filename) + '.' + ext[1];

      const baseUrl = this.generateBlobUrl(Config, VideoFileName)
      this.config = {
        baseUrl: baseUrl,
        blockSize: 1024 * 1024 * 2,
        sasToken: Config.sas,
        file: file,
        complete: () => {
          console.log('Transfer completed !')

        },
        error: (err) => {
          console.log('Error:', err)
        },
        progress: (percent) => {
          this.percent = percent,
            this.uploadedsize = (percent * (file.size / (1024 * 1024 * 100))).toFixed(2);
          if (this.percent == 100) {
            if (type == 'photo') {
              this.selectedphotosList[this.currentFile].PhotoURL = baseUrl;
              this.selectedphotosList[this.currentFile].status = true;

            } else {
              this.selectedvideosList[this.currentFile].VideoURL = baseUrl;
              this.selectedvideosList[this.currentFile].status = true;
            }
            this.currentFile++;
            this.uploadfiles(this.currentFile, type);
          }


        }
      }
      this.blobup.upload(this.config);
    };
  }

  async getSASToken() {
    await this.service.CoachProfileSettings({
      method: "CoachProfileSettings/getSASToken",
      request: { ActionFlag: "Load", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      return ret;
    });

  }


}
const Config: UploadParams = {
  sas: '',
  storageAccount: 'splcms',
  containerName: 'cricpass'
};
export function ConfirmValidator(password: string, confirmedPassword: string) {

  return (control: FormControl): { [s: string]: boolean } => {
    //getting undefined values for both variables
    console.log(password, confirmedPassword);
    //if I change this condition to === it throws the error if the 
    //  two fields are the same, so this part works
    if (password !== confirmedPassword) {
      return { 'passwordMismatch': true }
    } else {
      //it always gets here no matter what
      return null;
    }
  }
}
