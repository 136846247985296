<div class="page-container coachprofile-page">
    <div class="container">
        <div class="input-container">

        </div>
    </div>
    <div class="page-wrapper no-input">
        <div class="container">
            <div class="output-container">
                <div class="athlete-dashboard-container playerview">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card-profile-container box-shadow br-5">
                                <div class="card-profile-header tr-5">
                                    <div class="profile-picture">
                                        <img src="{{CoachesDetails.CoachImage}}" alt="Profile Picture">
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="addtofav">
                                                <div class="example-section coachpad-checklist">
                                                    <mat-checkbox class="example-margin">Add to Favourite</mat-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 txt-right">
                                            <div class="prating">
                                                <ng-template #t let-fill="fill">
                                                    <span class="fa star" [class.full]="fill === 100">
                                                        <span class="half" [style.width.%]="fill">&#9734;</span>
                                                    </span>
                                                </ng-template>

                                                <ngb-rating [(rate)]="CoachesDetails.AVGRating" 
                                                    [readonly]="true" max="5">
                                                </ngb-rating>
                                            </div>
                                            <div class="sessioncount">54 sessions</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-profile-body">
                                    <div class="txt-center">
                                        <div class="profile-name">{{CoachesDetails.DisplayName}}</div>
                                        <div class="profile-age">14 Years 152 Days</div>
                                        <div class="profile-association">{{CoachesDetails.Specialization}}</div>
                                        <div class="fees-hld">Fees per session - {{CoachesDetails.FeePerSession}}</div>
                                        <div class="lblsessionlist">
                                            <button mat-raised-button color="primary"
                                                (click)="addsession(-1)">-</button>
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>No of Sessions</mat-label>
                                                <input matInput [value]="totalsession" disabled>
                                            </mat-form-field>
                                            <button mat-raised-button color="primary" (click)="addsession(1)">+</button>
                                        </div>
                                    </div>
                                    <div class="txt-center">
                                        <button mat-raised-button color="primary" (click)="sendRequest()">Send
                                            Request</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="profile-with-tab br-5 box-shadow">
                                <!-- <div class="main-tab" rel="athlete-db">
                                    <a href="javascript:void(0);" rel="aboutus" class="select">About</a>
                                    <a href="javascript:void(0);" rel="photos">Photos</a>
                                    <a href="javascript:void(0);" rel="videos">Videos</a>
                                    <a href="javascript:void(0);" rel="reviews">Reviews</a> -->
                                <div class="txt-right add-new back-previous" (click)="buttonClicks.emit($event)">
                                    <a href="javascript:void(0);"> Back </a>
                                </div>
                                <!-- </div> -->
                                <div class="athlete-db">
                                    <div class="main-tab-container aboutus" style="display: block;">
                                        <div class="profile-name">{{CoachesDetails.DisplayName}}</div>
                                        <span
                                            class="coachtagline">{{CoachesDetails.Specialization}}/{{CoachesDetails.CoachLevel}}</span>
                                        <p>
                                            {{CoachesDetails.AboutMe}}
                                        </p>
                                        <!-- <p>Since starting my coaching career I have developed other coaches within the cricket industry, with the purpose to see their teams and players grow. In 2020 I was the guest speaker on an OMTEX zoom webinar teaching on Fast Bowling Technique, Video Analysis and Injury Prevention to over 150 coaches worldwide. As a coach I believe it is my duty to pass on the knowledge I have gained along the way to see the sport of cricket flourish and in the process change players and coaches lives. The experience I have gained allows me to coach all aspects of cricket, which I believe will contribute immense value.</p>
                                        <p>Recently I have teamed up with Jonty Rhodes, Ryan Maron (The Jonty Rhodes Way) and Cricket School Of Excellence as their lead bowling specialist working with bowlers of all ages and at all coaching clinics. I have also been appointed as the Bowling Specialist by the Director of Coaching at Western Province Cricket on the Level 1 and level 2 Coaching Courses. My purpose in this role is to develop young talented coaches to understand bowling and bowling technique, especially fast bowling.</p> -->
                                    </div>
                                    <div class="title">Photos</div>
                                    <!-- <div class="main-tab-container photos" style="display: none;"> -->
                                    <ul class="photos-container row" *ngFor="let photos of CoachPhotos">
                                        <li class="col-md-4">
                                            <div class="image-placeholder">
                                                <img src="{{photos.PhotoURL}}" class="img-fluid">
                                            </div>
                                        </li>

                                    </ul>
                                    <!-- </div> -->
                                    <div class="title">Videos</div>
                                    <!-- <div class="main-tab-container videos" style="display: none;"> -->
                                    <div class="row">
                                        <div class="col-md-4" *ngFor="let videos of CoachVideos">
                                            <div class="video-list" (click)="openVideo(videos.VideoURL)">
                                                <div class="video-thumbnail br-5">
                                                    <a href="javascript:void(0);" data-toggle="modal"
                                                        data-target="#videos">
                                                        <img src="assets/images/thumbnail/drills3.jpg"
                                                            class="img-fluid">
                                                    </a>
                                                    <div class="ico-video"></div>
                                                    <div class="video-time">12.5 Mins</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- </div> -->
                                    <div class="title">Reviews</div>
                                    <!-- <div class="main-tab-container reviews" style="display: none;"> -->
                                    <div class="reviews-container">
                                        <div class="review-container" *ngFor="let reviews of CoachReviews">
                                            <div class="reviewedby">
                                                <div class="reviewedimg"><img src="{{reviews.ProfileImage}}"></div>
                                                <div class="nameContainer">
                                                    {{reviews.Name}}
                                                    <div class="prating">
                                                        <ngb-rating [(rate)]="reviews.Rating"  
                                                            [readonly]="true" max="5">
                                                        </ngb-rating>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>{{reviews.RatingComment}}</p>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Preview Modal -->
<div class="modal fade" id="videos" *ngIf="videopreview" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"></h5>
                <button type="button" class="close" (click)="videopreview=false" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="help-video">
                    <video width="100%" controls disablePictureInPicture controlsList="nodownload" src="{{videoFile}}">
                        <!-- <source src="{{videoFile}}" type="video/mp4">  -->
                    </video>
                </div>
            </div>
        </div>
    </div>
</div>