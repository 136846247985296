import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';

@Component({
  selector: 'app-age-category',
  templateUrl: './age-category.component.html',
  styleUrls: ['./age-category.component.css']
})
export class AgeCategoryComponent implements OnInit {

  AgeCategoryFrom: FormGroup;
  submitted = false;

  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { }

    @Input()
    public AgeCategoryName: string; public AgeCategoryMaxAge: string;
    public AgeCategoryMinAge: string; public AgeCategoryCutOffDate: string;
  
    today = new Date();
    public SearchFilter:string;
    public staffList:boolean = true;
    public staffGroup:boolean = false;
    public playerList:boolean = true;
    public playerGroup:boolean = false;
  
    public UserCode:string = "";
    public ButtonType:string;
  
    public isShowLoader: boolean;
    public isSuccessAlert: boolean = false; public successAlert: string;  
    public isErrorAlert: boolean = false; public errorAlert: string;
    public isWarningAlert: boolean = false; public warningAlert: string;
    public isConfirmAlert: boolean = false; public confirmAlert: string;
  
    public minDate: Date;
    public maxDate: Date;
  
    public ColumnMode: ColumnMode;
    public rowLimits: number = 10;
    public lstAssociationsFiltered: any = [];
    public LIMITARRAY = [
      { value: 10 },
      { value: 20 },
      { value: 50 },
      { value: 100 },
      { value: 500 }
    ];
    public TabName: string;
  
    public CategoryID: string;
    public listAgeCategoryGridLoad: any[]; public listAgeCategoryEdit: any[];
  
    ngOnInit(): void {
      this.utility.pageLoadScript();
      this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
      this.InitLoadAgeCategory();
      this.TabName = "AgeCategory";
    }
    
    showStaffgroup() {
      this.staffGroup = true;
      this.staffList = false;
    }
    hideStaffgroup() {
      this.staffList = true;
      this.staffGroup = false;
    }
    showPlayergroup() {
      this.playerGroup = true;
      this.playerList = false;
    }
    hidePlayergroup() {
      this.playerList = true;
      this.playerGroup = false;
    }
  
    InitLoadAgeCategory() {
      this.isShowLoader = true;
      this.AgeCategoryFrom = this.formBuilder.group({  
            n_AgeCategoryName: ['', Validators.required] ,
            n_AgeCategoryMaxAge: ['', Validators.required],
            n_AgeCategoryMinAge: ['', Validators.required],
            n_AgeCategoryCutOffDate: ['', Validators.required],
         }, {
            validator: AgeValidity('n_AgeCategoryMaxAge', 'n_AgeCategoryMinAge')
      });
      var minCurrentDate = new Date();
      var maxNewDate = new Date();
      this.minDate = minCurrentDate;
      
      this.maxDate = new Date(maxNewDate.getFullYear(), maxNewDate.getMonth(), maxNewDate.getDate());
     
      this.ClearAgeCategory();
      this.ButtonType = "Save";
      this.GridLoadAgeCategory();
    }

    GridLoadAgeCategory(){
      var AgeCategoryLoad = { CategoryID: "", ActionFlag: "GridLoad", ModuleName: "AssoAgeCategory", UserID: this.UserCode };
      this.service.AgeCategoryMaster(AgeCategoryLoad).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.listAgeCategoryGridLoad = ret.AgeCategoryGridLoad;
          this.isShowLoader = false;
          this.submitted = false;
          this.ClearAgeCategory();
          this.AgeCategoryFrom.reset();
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          this.errorAlert = ret.Message;
          return;
        }
      })
    }
  
  
    onSubmit() {
      //  this.submitted = true;
      if (this.AgeCategoryFrom.invalid) {
        return;
      }
      else {
        this.SaveAgeCategory();
      }
    }
  
  
    SaveAgeCategory() {
      this.isShowLoader = true;
      let submitType: string;
      let cutoffdate = this.datepipe.transform(this.AgeCategoryCutOffDate, 'yyyy-MM-dd');
      if (this.ButtonType == "Save") { submitType = "Register", this.CategoryID = "" }
      else { submitType = "Update", this.CategoryID = this.CategoryID }
      var AgeCategoryLoad = {
          CategoryID: String(this.CategoryID), CategoryName: this.AgeCategoryName, MinAgeLimit: String(this.AgeCategoryMinAge),
          MaxAgeLimit: String(this.AgeCategoryMaxAge), CutOffDate: cutoffdate,
          ActionFlag: String(submitType), ModuleName: "AssoAgeCategory", UserID: this.UserCode
      };
      this.service.AgeCategoryMaster(AgeCategoryLoad).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          if (this.ButtonType == "Save") this.successAlert = '\n"' + this.AgeCategoryName + '"\n Saved successfully..!!';
          else if (this.ButtonType == "Update") this.successAlert = '\n"' + this.AgeCategoryName + '"\n Updated successfully..!!';
          // this.ngOnInit();
          this.GridLoadAgeCategory();
          this.isShowLoader = false;
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          this.errorAlert = this.AgeCategoryName + '-' + ret.Message;
          // if (this.ButtonType == "Save") this.errorAlert = '\n"' + this.AgeCategoryName + '"\n Save failed..!!';
          // else if (this.ButtonType == "Update") this.errorAlert = '\n"' + this.AgeCategoryName + '"\n Update failed..!!';
          return;
        }
      })
    }
  
    EditAgeCategory(edit) {
      this.isShowLoader = true;
      var AgeCategory = { CategoryID: String(edit.CategoryID), ActionFlag: "Edit", ModuleName: "AssoAgeCategory", UserID: this.UserCode };
      this.service.AgeCategoryMaster(AgeCategory).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.listAgeCategoryEdit = ret.AgeCategoryEditLoad;
          this.CategoryID = this.listAgeCategoryEdit[0].CategoryID;
          this.AgeCategoryName = this.listAgeCategoryEdit[0].CategoryName;
          this.AgeCategoryMaxAge = this.listAgeCategoryEdit[0].MaxAgeLimit;
          this.AgeCategoryMinAge = this.listAgeCategoryEdit[0].MinAgeLimit;
          this.AgeCategoryCutOffDate = this.listAgeCategoryEdit[0].CutOffDate;
          this.ButtonType = "Update";
          this.isShowLoader = false;
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          this.errorAlert = edit.CategoryName + '-' + ret.Message;
          return;
        }
      })
    }
  
    ActivateAgeCategory(activate) {
      try{
          this.isConfirmAlert = false;
          this.isShowLoader = true;
          var AgeCategory = {
            CategoryID: String(activate.CategoryID), ActionFlag: "Activate",
            ModuleName: "AssoAgeCategory", UserID: this.UserCode
          };
          this.service.AgeCategoryMaster(AgeCategory).subscribe((ret: any = []) => {
            let BTStatus = String(ret.Status);
            if (BTStatus == "true") {
              this.isSuccessAlert = true;
              this.successAlert = '\n"' + activate.CategoryName + '"\n Activated successfully..!!';
              this.isShowLoader = false;
              this.ngOnInit();
            }
            else {
              this.isShowLoader = false;
              this.isErrorAlert = true;
              this.errorAlert = activate.CategoryName + '-' + ret.Message;
              return;
            }
          })
      }
      catch (Error)   
      {  
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = Error.message;
      }  
    }
  
    DeActivateAgeCategory(deactivate) {
      this.isWarningAlert = false;
      this.isShowLoader = true;
      var AgeCategory = {
        CategoryID: String(deactivate.CategoryID), ActionFlag: "DeActivate",
        ModuleName: "AssoAgeCategory", UserID: this.UserCode
      };
      this.service.AgeCategoryMaster(AgeCategory).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          this.successAlert = '\n"' + deactivate.CategoryName + '"\n DeActivated successfully..!!';
          this.isShowLoader = false;
          this.ngOnInit();
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          this.errorAlert = deactivate.CategoryName + '-' + ret.Message;
          return;
        }
      })
    }
  
    ClearAgeCategory() {
      this.AgeCategoryName = ""; this.AgeCategoryMaxAge = "";
      this.AgeCategoryMinAge = ""; this.AgeCategoryCutOffDate = ""; this.ButtonType = "Save";
      this.submitted = false;
    }
  
  
    public rowValue: any[]; public rowValueActive: any[];
    showWarningDialogue(row) {
      if (String(row.RecordStatus) == "Active") {
        this.rowValue = row;
        this.isWarningAlert = true;
        this.warningAlert = "Are you sure want to DeActivate..?"; //+'\n' + row.CategoryName;
      }
      else if (String(row.RecordStatus) == "InActive") {
        this.rowValueActive = row;
        this.isConfirmAlert = true;
        this.confirmAlert = "Are you sure want to Activate..?"; //+'\n' + row.CategoryName;
      }
      else {
        this.isWarningAlert = false;
        return;
      }
    }
  
  
  
  }
  
  export var pageStatus: string = "";
  
  export function AgeValidity(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
  
      if (matchingControl.errors && !matchingControl.errors.AgeValidity) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
  
      // set error on matchingControl if validation fails
      if (Number(control.value) <= Number(matchingControl.value)) {
        control.setErrors({ AgeValidity: true });
      } else {
        control.setErrors(null);
      }
    }
  }
