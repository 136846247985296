import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { selectedAthleteList, pageStatus } from '../../registration/player-list/player-list.component';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

declare var $: any

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {
  AthleteBasicForm: FormGroup; AthleteContactForm: FormGroup;
  AthleteProfileForm: FormGroup; AthleteAddressForm: FormGroup;
  isLinear = true; submitted = false;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }

  @Input()
  public AssociationID: String; public MemberTypeID: String;
  public AssociationName: string; public FirstName: string; public LastName: string;
  public FatherName: string; public DisplayName: string; public Gender: string; public DOB: string; public RegistrationNumber: string;
  public RegistrationDate: string; public AthleteImage: string; public AthleteImagePath: string; public AthleteImageBrowse: string;
  public MobileNumber: string; public EmailID: string;
  public EmergencyContactPerson: string; public EmergencyContactNumber: string; public BloodGroup: string; public Nationality: string;
  public Level: string; public BattingStyle: string; public BattingOrder: string; public BattingRole: string; public BowlingArm: string;
  public BowlingStyles: string; public IsWicketKeeper: boolean; public BowlingSpec: string; public Height: string; public Weight: string;
  public Address01: string; public Address02: string; public Pincode: string; public IDProof: string;
  public IDProofFile: string; public IDProofFilePath: string;
  public ReferralCode: string; public ValidDate: string; today = new Date();

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;

  public minDate: Date;
  public maxDate: Date;

  public UserCode: string = ""; public ButtonType: string;
  public RefUserID: string;
  public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];
  public ImageDocuments: ImageDocumentDetails[] = [{ data: "", name: "" }];

  public ContinentsList: any = []; public CountryByRegion: any = [];
  public StateByCountry: any = []; public CityByState: any = [];
  public Continent: string; public Country: string;
  public States: string; public City: string;

  public InputData: Inputs[] = [{ ID: "", ActionFlag: "Load" }];
  public ContinentID: string; public StateID: string; public CountryID: string; public CityID: string;

  public lstAthleteEdit: any = []; public lstAthleteProfileEdit: any[];

  public AssociationList: any[]; public SportsList: any[];
  public MemberList: any[]; public GenderList: any[];
  public BloodGroupList: any[]; public NationalityList: any[];
  public MemberLevelList: any[]; public BattingTypeList: any = [];
  public BattingOrderList: any = []; public PlayerRoleList: any = [];
  public BowlingStyleList: any = []; public BowllingTypeList: any = [];
  public BowlingSpecializationList: any = []; public DocumentTypeList: any[];

  public BowlingSpecList: any = [];

  public BatStyle: String; public BatOrder: String; public PlayerRole: String;
  public BolwStyle: String = ""; public BowlType: String = ""; public BowlSpec: String = "";

  public filOfcRoleUpdate: any = []; public croppedImage: string;
  public imageChangedEvent: any = '';
  public imageChangedType: string = '';
  public displayImageCrop: string = 'none';

  ngOnInit(): void {
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    this.InitPageLoad();
  }

  InitPageLoad() {
    var minCurrentDate = new Date();
    var maxNewDate = new Date();
    this.minDate = minCurrentDate;
    this.maxDate = new Date(maxNewDate.getFullYear(), maxNewDate.getMonth(), maxNewDate.getDate());

    var dateToday = new Date();
    this.RegistrationDate = this.datepipe.transform(dateToday, 'yyyy-MM-dd');

    this.ClearAssociation();
    this.ButtonType = "Save"
    const URL = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const Email = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.AthleteBasicForm = this.formBuilder.group({
      n_AssociationName: ['', Validators.required],
      n_FirstName: ['', Validators.required],
      n_LastName: ['', Validators.required],
      n_FatherName: ['', Validators.required],
      n_DisplayName: ['', Validators.required],
      n_Gender: ['', Validators.required],
      n_DOB: ['', Validators.required],
    });
    this.AthleteContactForm = this.formBuilder.group({
      n_RegistrationNumber: [],
      n_RegistrationDate: ['', Validators.required],
      n_MobileNumber: ['', Validators.required],
      n_EmailID: ['', [Validators.required, Validators.email]],
      n_EmergencyContactPerson: [],
      n_EmergencyContactNumber: [],
      n_BloodGroup: [],
      n_Nationality: ['', Validators.required],
      n_AthleteImage: [],
    });
    this.AthleteProfileForm = this.formBuilder.group({
      n_Level: ['', Validators.required],
      n_BattingStyles: ['', Validators.required],
      n_BattingOrder: ['', Validators.required],
      n_BattingRole: ['', Validators.required],
      n_BowlingArm: [],
      n_BowlingStyles: [],
      n_BowlingSpec: [],
      n_IsWicketKeeper: [],
      n_Height: [],
      n_Weight: [],
    });
    this.AthleteAddressForm = this.formBuilder.group({
      n_Address01: [],
      n_Address02: [],
      n_Continent: ['', Validators.required],
      n_Country: ['', Validators.required],
      n_States: ['', Validators.required],
      n_City: ['', Validators.required],
      n_Pincode: ['', Validators.required],
      n_IDProof: [],
      n_IDProofFile: [],
      n_ReferralCode: [],
      n_ValidDate: ['', Validators.required],
    });

    var InitLoad = { ActionFlag: "Load", UserID: this.UserCode, ModuleName: "AssocAtheleteReg" }
    this.service.AthleteMasterCRUD(InitLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.AssociationList = ret.AssociationList;
        this.SportsList = ret.SportsList;
        this.MemberList = ret.MemberList;
        this.GenderList = ret.GenderList;
        this.BloodGroupList = ret.BloodGroupList;
        this.NationalityList = ret.CountryList;
        this.MemberLevelList = ret.MemberLevelList;
        this.BattingTypeList = ret.BattingTypeList;
        this.BattingOrderList = ret.BattingOrderList;
        this.PlayerRoleList = ret.PlayerRoleList;
        this.BowlingStyleList = ret.BowlingStyleList;
        this.BowllingTypeList = ret.BowllingTypeList;
        this.BowlingSpecializationList = ret.BowlingSpecializationList;
        this.DocumentTypeList = ret.DocumentTypeList;

        this.AssociationName = this.AssociationList[0].AssociationID;
        var dateToday = new Date();
        this.RegistrationDate = this.datepipe.transform(dateToday, 'yyyy-MM-dd');

        var RegionLoad = { ID: "", ActionFlag: "Load" };
        this.service.loadAssociationMaster(RegionLoad).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
            this.ContinentsList = ret.ContinentsList;

            if (sessionStorage.getItem("AthletePageStatus") == "Edit") {
              var EditAthlete = {
                AssociationID: String(selectedAthleteList.AssociationID), ActionFlag: "Edit",
                ModuleName: "AssocAtheleteReg", RefUserID: String(selectedAthleteList.UserID)
              };
              this.service.AthleteMasterCRUD(EditAthlete).subscribe((ret: any = []) => {
                let BTStatus = String(ret.Status);
                if (BTStatus == "true") {
                  this.lstAthleteEdit = ret.AthleteEditList;
                  this.lstAthleteProfileEdit = ret.AthleteProfileEditList;

                  this.RegistrationDate = "";

                  this.AssociationName = (this.lstAthleteEdit[0].AssociationID);
                  this.AssociationID = (this.lstAthleteEdit[0].AssociationID);

                  this.FirstName = this.lstAthleteEdit[0].FirstName;
                  this.LastName = this.lstAthleteEdit[0].LastName;
                  this.FatherName = this.lstAthleteEdit[0].FatherName;
                  this.DisplayName = this.lstAthleteProfileEdit[0].DisplayName;

                  this.Gender = this.lstAthleteEdit[0].Gender;
                  if (this.lstAthleteEdit[0].Gender == "1") { this.Gender = "1" }
                  else { this.Gender = "2" }

                  this.DOB = this.lstAthleteEdit[0].DOB;
                  this.RegistrationDate = this.lstAthleteEdit[0].RegistrationDate;
                  this.RegistrationNumber = this.lstAthleteEdit[0].RegisterNumber;
                  this.AthleteImage = this.lstAthleteEdit[0].ProfileImage;
                  // this.AthleteImagePath = this.lstAthleteEdit[0].ProfileImage;
                  this.MobileNumber = this.lstAthleteEdit[0].LoginMobile;
                  this.EmergencyContactPerson = this.lstAthleteEdit[0].EmergencyContact;
                  this.EmergencyContactNumber = this.lstAthleteEdit[0].EmergencyNo;
                  this.BloodGroup = (this.lstAthleteEdit[0].BloodGroupID);
                  this.Nationality = (this.lstAthleteEdit[0].Nationality);
                  this.EmailID = this.lstAthleteEdit[0].LoginEmail;

                  // for (var i = 0; i < this.BattingTypeList.length; i++) {
                  //   var ismatch = false;
                  //     if (this.BattingTypeList[i].BattingTypeID == this.lstAthleteProfileEdit[0].BattingTypeID) {
                  //       ismatch = true;
                  //       this.BattingTypeList[i].checked = true;
                  //     }
                  //   if (!ismatch) {
                  //       this.BattingTypeList[i].checked = false;
                  //   }  
                  // }

                  this.Level = (this.lstAthleteProfileEdit[0].LevelID);
                  this.BattingStyle = (this.lstAthleteProfileEdit[0].BattingTypeID);
                  this.BattingOrder = (this.lstAthleteProfileEdit[0].BattingOrderID);
                  this.BattingRole = (this.lstAthleteProfileEdit[0].PlayerRoleID);

                  this.BowlingArm = (this.lstAthleteProfileEdit[0].BowlingStyleID);
                  this.BowlingStyles = (this.lstAthleteProfileEdit[0].BowlingTypeID);
                  this.BowlingSpec = (this.lstAthleteProfileEdit[0].BowlingSpecID);

                  this.BatStyle = (this.lstAthleteProfileEdit[0].BattingTypeID);
                  this.BatOrder = (this.lstAthleteProfileEdit[0].BattingOrderID);
                  this.PlayerRole = (this.lstAthleteProfileEdit[0].PlayerRoleID);

                  this.BowlType = (this.lstAthleteProfileEdit[0].BowlingStyleID);
                  this.BolwStyle = (this.lstAthleteProfileEdit[0].BowlingTypeID);
                  this.BowlSpec = (this.lstAthleteProfileEdit[0].BowlingSpecID);

                  //BattingTypeID:String(this.BatStyle), BattingOrderID:String(this.BatOrder), 
                  //PlayerRoleID:String(this.PlayerRole), BowlingStyleID:String(this.BowlType), 
                  //BowlingTypeID:String(this.BolwStyle), BowlingSpecID:String(this.BowlSpec), 

                  // this.BowlingStyleChange(this.lstAthleteProfileEdit[0].BowlingStyleID);
                  this.BowlingStyleChange(this.lstAthleteProfileEdit[0].BowlingTypeID);

                  let wkt = (this.lstAthleteProfileEdit[0].IsWicketKeeper);
                  if (wkt == "1") { this.IsWicketKeeper = true }
                  else { this.IsWicketKeeper = false }
                  //this.IsWicketKeeper = String(this.lstAthleteProfileEdit[0].IsWicketKeeper);

                  this.Height = String(this.lstAthleteProfileEdit[0].Height);
                  this.Weight = String(this.lstAthleteProfileEdit[0].Weight);

                  this.Address01 = this.lstAthleteEdit[0].Address1;
                  this.Address02 = this.lstAthleteEdit[0].Address2;

                  // this.ContinentID = this.lstAthleteEdit[0].RegionID;
                  this.Continent = this.lstAthleteEdit[0].RegionID;
                  this.onChangeContinent(this.Continent);
                  this.Country = this.lstAthleteEdit[0].CountryID;
                  this.onChangeCountry(this.Country);
                  this.States = this.lstAthleteEdit[0].StateID;
                  this.onChangeState(this.States);
                  this.City = this.lstAthleteEdit[0].CityID;

                  this.Pincode = this.lstAthleteEdit[0].Pincode;
                  this.ValidDate = this.lstAthleteEdit[0].PackageValid;
                  this.IDProof = this.lstAthleteEdit[0].IDProofType;
                  this.IDProofFile = this.lstAthleteEdit[0].IDProodUpload;
                  //this.IDProofFilePath = this.lstAthleteEdit[0].IDProodUpload;
                  this.ReferralCode = this.lstAthleteEdit[0].ReferalCode;

                  this.MemberTypeID = String(this.lstAthleteProfileEdit[0].MemberTypeID);
                  this.RefUserID = String(selectedAthleteList.UserID);
                  //this.UserCode = String(this.lstAthleteProfileEdit[0].RefUserID);

                  sessionStorage.setItem("AthletePageStatus", "");
                  this.ButtonType = "Update";
                }
              })
            }
          }
          else {
            this.isShowLoader = false;
            this.isErrorAlert = true;
            this.errorAlert = '\n Edit load failed..!! \n';
            return;
          }
        })


      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Load failed..!! \n';
        return;
      }
    })
  }

  BowlingStyleChange(value) {
    this.BowlType = value;
    this.BolwStyle = this.BowlingArm;
    this.BowlingSpecList = this.BowlingSpecializationList.filter(s => s.BowlingTypeID === Number(value));
    if (value == "2") {
      this.BowlingSpecList = this.BowlingSpecList.filter(b => b.BowlingStyleName === Number(this.BowlingArm));
    }
  }

  onChangeContinent(cont) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(cont), ActionFlag: "GetCountryByRegion" });
    this.ContinentID = cont;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.CountryByRegion = ret.CountryByRegion;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Country details load failed..!! \n';
        return;
      }
    })
  }

  onChangeCountry(country) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(country), ActionFlag: "GetStateByCountry" });
    this.CountryID = country;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.StateByCountry = ret.StateByCountry;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n State details load failed..!! \n';
        return;
      }
    })
  }

  onChangeState(state) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(state), ActionFlag: "GetCityByState" });
    this.StateID = state;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.CityByState = ret.CityByState;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n City details load failed..!! \n';
        return;
      }
    })
  }

  onChangeCity(city) {
    this.CityID = city;
  }


  onSubmit() {
    this.submitted = true;
    if (this.AthleteAddressForm.invalid) {
      this.isWarningAlert = true;
      this.warningAlert = 'Please fill out all mandatory fields..!!';
      return;
    }
    else {
      this.SaveAthleteDetails();
    }
  }

  SaveAthleteDetails() {
    this.isShowLoader = true;
    let submitType: string;
    var profileImage = ""; var documentImage = "";

    if (this.ButtonType == "Save") { submitType = "Register", this.RefUserID = "" }
    else { submitType = "Update", this.RefUserID = String(selectedAthleteList.UserID) }

    let DOB = this.datepipe.transform(this.DOB, 'yyyy-MM-dd');
    let regDate = this.datepipe.transform(this.RegistrationDate, 'yyyy-MM-dd');
    let valDate = this.datepipe.transform(this.ValidDate, 'yyyy-MM-dd');

    // BowlingArm:[],    BowlingStyles:[],    BowlingSpec:[],
    if (this.BowlType == undefined) { this.BowlType = "" }
    else { this.BowlType = this.BowlType }

    if (this.BolwStyle == undefined) { this.BolwStyle = "" }
    else { this.BolwStyle = this.BolwStyle }

    if (this.BowlSpec == undefined) { this.BowlSpec = "" }
    else { this.BowlSpec = this.BowlSpec }

    if (this.Height == undefined || this.Height == "" || this.Height == null) { this.Height = "0" }
    else { this.Height = this.Height }

    if (this.Weight == undefined || this.Weight == "" || this.Weight == null) { this.Weight = "0" }
    else { this.Weight = this.Weight }

    let isWkt: string;
    if (this.IsWicketKeeper == true) { isWkt = "1" }
    else if (this.IsWicketKeeper == false) { isWkt = "0" }

    var saveAthlete = {
      ModuleName: "AssocAtheleteReg",
      AssociationID: String(this.AssociationName),
      FirstName: this.FirstName, LastName: this.LastName,
      FatherName: String(this.FatherName), DisplayName: this.DisplayName,
      Gender: String(this.Gender), DOB: DOB, RegistrationDate: regDate,
      ProfileImage: this.AthleteImage, LoginMobile: this.MobileNumber,
      LoginEmail: this.EmailID,
      EmergencyContact: this.EmergencyContactPerson,
      EmergencyNo: this.EmergencyContactNumber, BloodGroupID: String(this.BloodGroup),
      Nationality: String(this.Nationality), LevelID: String(this.Level),

      BattingTypeID: String(this.BatStyle), BattingOrderID: String(this.BatOrder),
      PlayerRoleID: String(this.PlayerRole),
      BowlingStyleID: String(this.BolwStyle),
      BowlingTypeID: String(this.BowlType),
      BowlingSpecID: String(this.BowlSpec),

      IsWicketKeeper: String(isWkt), Height: this.Height, Weight: this.Weight,
      Address1: this.Address01, Address2: this.Address02,
      RegionID: String(this.Continent), CountryID: String(this.CountryID),
      StateID: String(this.StateID), CityID: String(this.City), Pincode: this.Pincode,
      IDProofType: String(this.IDProof), IDProodUpload: this.IDProofFile,
      ReferalCode: this.ReferralCode, PackageValid: String(valDate),
      MemberTypeID: "1",
      UserID: this.UserCode,
      RefUserID: this.RefUserID,
      ActionFlag: submitType,
    };

    if (this.ImageDetails[0].data != "") {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          profileImage = ret.name;
          saveAthlete.ProfileImage = profileImage;

          if (this.ImageDocuments[0].data != "") {
            this.service.UploadFile(this.ImageDocuments[0]).subscribe((ret: any = []) => {
              if (ret.Status == true) {
                documentImage = ret.name;
                saveAthlete.IDProodUpload = documentImage;

                this.service.AthleteMasterCRUD(saveAthlete).subscribe((ret: any = []) => {
                  let BTStatus = String(ret.Status);
                  if (BTStatus == "true") {
                    this.isSuccessAlert = true;
                    if (submitType == "Register") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Saved successfully..!!';
                    else if (submitType == "Update") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updated successfully..!!';
                    this.isShowLoader = false;
                    submitType = "";
                  }
                  else {
                    this.isShowLoader = false;
                    this.isErrorAlert = true;
                    if (submitType == "Register") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Save failed..!!';
                    else if (submitType == "Update") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updation failed..!!';
                  }
                })
              }
            })
          }
          else {
            this.service.AthleteMasterCRUD(saveAthlete).subscribe((ret: any = []) => {
              let BTStatus = String(ret.Status);
              if (BTStatus == "true") {
                this.isSuccessAlert = true;
                if (submitType == "Register") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Saved successfully..!!';
                else if (submitType == "Update") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updated successfully..!!';
                this.isShowLoader = false;
                submitType = "";
              }
              else {
                this.isShowLoader = false;
                this.isErrorAlert = true;
                if (submitType == "Register") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Save failed..!!';
                else if (submitType == "Update") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updation failed..!!';
              }
            })
          }
        }
      })
    }
    else if (this.ImageDocuments[0].data != "") {
      this.service.UploadFile(this.ImageDocuments[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          documentImage = ret.name;
          saveAthlete.IDProodUpload = documentImage;

          this.service.AthleteMasterCRUD(saveAthlete).subscribe((ret: any = []) => {
            let BTStatus = String(ret.Status);
            if (BTStatus == "true") {
              this.isSuccessAlert = true;
              if (submitType == "Register") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Saved successfully..!!';
              else if (submitType == "Update") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updated successfully..!!';
              this.isShowLoader = false;
              submitType = "";
            }
            else {
              this.isShowLoader = false;
              this.isErrorAlert = true;
              if (submitType == "Register") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Save failed..!!';
              else if (submitType == "Update") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updation failed..!!';
            }
          })
        }
      })
    }
    else {
      this.service.AthleteMasterCRUD(saveAthlete).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          if (submitType == "Register") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Saved successfully..!!';
          else if (submitType == "Update") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updated successfully..!!';
          this.isShowLoader = false;
          submitType = "";
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          if (submitType == "Register") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Save failed..!!';
          else if (submitType == "Update") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updation failed..!!';

          // if(this.ButtonType=="Save"){ this.utility.openSnackBar( 'Athlete -\n"'+ this.FirstName + '"\n Save Failed..!!' ); }  
          // else { this.utility.openSnackBar( 'Athlete -\n"'+ this.FirstName + '"\n Updation Failed..!!' ); }
        }
      })

    }


  }



  // showbowltype() {
  //   for (var i = 0; i < this.BowlingSPTypeList.length; i++) {
  //     this.BowlingSPTypeList[i].checked = false;
  //   }
  //   this.BowlingSPTypeListFiltered = this.BowlingSPTypeList.filter(sptype => sptype.BowlingTypeID === this.BowlingType);
  //   if (this.BowlingType == "2") {
  //     this.BowlingSPTypeListFiltered = this.BowlingSPTypeListFiltered.filter(sptype => sptype.StyleID === this.BowlingStyle);
  //   }
  // }

  ClearAssociation() {
    this.AssociationName = ""; this.FirstName = ""; this.LastName = ""; this.FatherName = "";
    this.DisplayName = ""; this.Gender = ""; this.DOB = ""; this.RegistrationDate = ""; this.RegistrationNumber = "";
    this.AthleteImage = ""; this.AthleteImagePath = "";
    this.MobileNumber = ""; this.EmergencyContactPerson = ""; this.EmergencyContactNumber = ""; this.BloodGroup = ""; this.Nationality = "";
    this.EmailID = ""; this.Level = ""; this.BattingStyle = ""; this.BattingOrder = ""; this.BattingRole = ""; this.BowlingArm = ""; this.BowlingStyles = "";
    this.IsWicketKeeper = false; this.BowlingSpec = ""; this.Height = ""; this.Weight = ""; this.Address01 = ""; this.Address02 = ""; this.Pincode = "";
    this.ValidDate = ""; this.ReferralCode = ""; this.Pincode = ""; this.IDProof = ""; this.IDProofFile = ""; this.IDProofFilePath = "";
    this.ReferralCode = ""; this.ValidDate = ""; this.ButtonType = "Save";
  }



  BaseToContact(stepper: MatStepper) {
    if (this.AssociationName != "" && this.AssociationName != null && this.FirstName != "" && this.FirstName != null &&
      this.LastName != "" && this.LastName != null &&
      this.FatherName != "" && this.FatherName != null &&
      this.DisplayName != "" && this.DisplayName != null &&
      this.Gender != "" && this.Gender != null &&
      this.DOB != "" && this.DOB != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!';
    return;
  }

  ContactToInfo(stepper: MatStepper) {
    if (this.RegistrationDate != "" && this.RegistrationDate != null &&
      this.MobileNumber != "" && this.MobileNumber != null && this.EmailID != "" && this.EmailID != null &&
      this.Nationality != "" && this.Nationality != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!';
    return;
  }

  InfoToAddress(stepper: MatStepper) {
    //  if ( this.Level != "" && this.Level != null && this.Level != undefined && 
    //       this.BatStyle != "" && this.BatStyle != null &&  this.BatStyle != undefined &&
    //       this.PlayerRole != "" && this.PlayerRole != null && this.PlayerRole != undefined  &&
    //        this.IsWicketKeeper == false &&  this.IsWicketKeeper == undefined )
    //    stepper.next();
    //  else
    //    this.utility.openSnackBar("Please fill mandatory details..!! ");
    //     return;

    if (this.BolwStyle == null && this.BolwStyle == undefined && this.BolwStyle == "") {
      stepper.next();
    }
    else if (this.BolwStyle != "" && ((this.BowlType == "") || (this.BowlSpec == ""))) {
      this.isWarningAlert = true;
      this.warningAlert = "Please choose Bowling Type & Bowling Specification...";
    }
    else {
      stepper.next();
    }
  }

  AddressVal(stepper: MatStepper) {
    if (this.Address01 != "" && this.Address01 != null && this.Continent != "" && this.Continent != null &&
      this.Country != "" && this.Country != null && this.States != "" && this.States != null &&
      this.City != "" && this.City != null && this.Pincode != "" && this.Pincode != null &&
      this.IDProof != "" && this.IDProof != null && this.ReferralCode != "" && this.ReferralCode != null &&
      this.ValidDate != "" && this.ValidDate != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!';
    return;
  }


  BatStyleClick(style) {
    this.BatStyle = style;
  }

  BatOrderClick(order) {
    this.BatOrder = order;
  }

  PlayerRoleClick(role) {
    this.PlayerRole = role;
  }

  BowlStyleClick(bowlStyle) {
    this.BolwStyle = bowlStyle;
  }

  BowlTypeClick(bowlType) {
    this.BowlType = bowlType;
    // alert(bowlType);
  }

  BowlSpecClick(bowlSpc) {
    this.BowlSpec = bowlSpc;
  }

  onFileChange(event, type) {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   this.AthleteImagePath = event.target.value;
    //   reader.onload = () => {
    //     this.AthleteImage = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.AthleteImage, name: file.type });
    //   };
    // }
    const reader = new FileReader();
    this.imageChangedEvent = event;
    this.imageChangedType = type;
    $('#modalcrop').modal('show');
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  Applycropper() {

    //   reader.readAsDataURL(file);
    //   this.AthleteImagePath = event.target.value;
    //   reader.onload = () => {
    //     this.AthleteImage = reader.result as string;

    if (this.imageChangedType == 'profile') {
      this.AthleteImage = this.croppedImage;
      this.ImageDetails = [];
      this.ImageDetails.push({ data: this.AthleteImage, name: 'image/png' });
      $('#modalcrop').modal('hide');
      this.AthleteImageBrowse = "";
    }
  }


  saveFile(file, type) {
    this.ImageDetails = [];
    this.ImageDetails.push({
      data: file, name: type
    });
  }

  onDocChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.IDProofFilePath = event.target.value;
      reader.onload = () => {
        this.IDProofFile = reader.result as string;
        this.ImageDocuments = [];
        this.ImageDocuments.push({ data: this.IDProofFile, name: file.type });
      };
    }
  }

  saveDoc(file, type) {
    this.ImageDocuments = [];
    this.ImageDocuments.push({
      data: file, name: type
    });
  }

  PlayerRoleChange(roles, selRole, $event) {
    if ($event.checked == true) {
      roles.push(selRole);
    }
    else {
      const index = roles.indexOf(selRole, 0);
      if (index > -1) {
        roles.splice(index, 1);
      }
    }
    //alert(roles.join(','));
  }


}

export interface ImageDetails { data: string, name: string }
export interface ImageDocumentDetails { data: string, name: string }
export interface Inputs { ID: string; ActionFlag: string; }

// [stepControl]="AthleteBasicForm"
// [stepControl]="AthleteContactForm"
// [stepControl]="AthleteProfileForm" 
// [stepControl]="AthleteAddressForm"