import { Component, OnInit, Input } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventDropArg } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { MastersServiceService } from '../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { FormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import 'fullcalendar';
import { EventResizeDoneArg } from '@fullcalendar/interaction';


export interface User {
  name: string;
}

@Component({
  selector: 'app-dashboard-coach',
  templateUrl: './dashboard-coach.component.html',
  styleUrls: ['./dashboard-coach.component.css']
})
export class DashboardCoachComponent implements OnInit {
  public UserCode: string = "";
  public RefUserID: string;
  public templates: boolean = false;
  public isPlanner: boolean = false;


  myControl = new FormControl();
  options: User[] = [
    { name: 'Net Practice' },
    { name: 'Front Foot' },
    { name: 'Back Foot' }
  ];
  filteredOptions!: Observable<User[]>;

  calendarVisible = true;
  public calendarOptions: CalendarOptions;

  currentEvents: EventApi[] = [];

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }


  @Input() eventData: any;
  defaultConfigurations: any;
  @Input()
  set configurations(config: any) {
    if (config) {
      this.defaultConfigurations = config;
    }
  }


  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router) {
    this.defaultConfigurations = {
      editable: true,
      eventLimit: true,
      titleFormat: 'MMM D YYYY',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      views: {
        agenda: { eventLimit: 2 }
      },
      allDaySlot: false,
      slotDuration: moment.duration('00:15:00'),
      slotLabelInterval: moment.duration('01:00:00'),
      firstDay: 1,
      selectable: true,
      selectHelper: true,
      events: this.eventData,
    };
    this.eventData = [
      {
        title: 'event1',
        start: moment()
      },
      {
        title: 'event2',
        start: moment(),
        end: moment().add(2, 'days')
      },
    ];
  }

  public ListEvents: any = [];
  public loadedEvents: any = [];

  public isShowLoader: boolean;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isSuccessAlert: boolean = false; public successAlert: string;

  public UserName: string;

  public CoachDashboardDetail: any[];
  public ProfileImage: string; public ProfileDisplayName: string; public Age: string;
  public AssociationName: string; public Proficiency: string; public Level: string;
  public EventTypeList: any = [];
  public EventStatusList: any = [];
  public MembetTypeList: any = [];
  public PlayerGroupList: any = [];
  public MembersList: any = [];
  //public PlayerGroup:string;
  //public MemberType:string;
  public StartTime: string;
  public EndTime: string;
  public lstParticipants: any = [];
  public EventID: string = "";
  public EventName: string;
  public EventTypeID: string;
  public StartDate: Date;
  public StartMeridian: string = "";
  public EndDate: Date;
  public EndMeridian: string = "";
  public Venue: string;
  public EventStatusID: string;
  public Comments: string;
  public PlayGroupID: string;
  public MemberTypeID: string;
  public ParticipantID: string;
  public ActionFlag: string = 'Create';
  public SearchParticipant: string;
  public SearchSelParticipant: string;

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.CoachDashboardLoad();
    this.calendarOptions = {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      initialView: 'dayGridMonth',
      initialEvents: [
      ], // alternatively, use the `events` setting to fetch from a feed      
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      displayEventTime: true,
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventsSet: this.handleEvents.bind(this),
      droppable: false,
      eventDrop: this.handleDropEvent.bind(this),
      eventResize: this.handleExtendEvent.bind(this),
      dropAccept: '.cool-event'

    };
    this.LoadCalenderValues('FullView', '');
    this.service.PlannerPageLoad({ ActionFlag: 'Load', UserID: this.UserCode }).subscribe((ret: any = []) => {
      this.EventTypeList = ret.EventTypeList;
      this.EventStatusList = ret.EventStatusList;
      this.MembetTypeList = ret.MembetTypeList;
      this.PlayerGroupList = ret.PlayerGroupList;
    });
  }

  LoadCalenderValues(type, eventId) {
    this.service.PlannerCalendarLoad({ ActionFlag: type, UserID: this.UserCode, EventID: eventId }).subscribe((ret: any = []) => {
      this.ListEvents = ret.EventsList;
      this.calendarOptions.events = this.ListEvents;
    });
  }
  MemberTypeChange() {
    this.MembersList = [];
    this.service.PlannerPageLoad({ ActionFlag: 'GetMembers', UserID: this.UserCode, PlayGroupID: String(this.PlayGroupID), MemberTypeID: String(this.MemberTypeID) }).subscribe((ret: any = []) => {
      this.MembersList = ret.MembersList;
      var temp = this.lstParticipants.filter(s => String(s.MemberTypeID) === String(this.MemberTypeID));
      for (var i = 0; i < temp.length; i++) {
        var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(temp[i].ParticipantID));

        if (x >= 0)
          this.MembersList[x].Status = 1;
      }
    });
  }

  onAddclick(value, event) {
    if (event.currentTarget.checked == true) {
      this.lstParticipants.push({ EventID: String(this.EventID), ParticipantID: String(value.MemberID), MemberTypeID: String(this.MemberTypeID), DisplayName: value.DisplayName, MemberName: value.MemberName, PlayGroupID: String(this.PlayGroupID) });
      // this.listAthleteRefUserID.push(value.RefUserID);
      var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(value.MemberID));
      if (x >= 0)
        this.MembersList[x].Status = 1;
    }
    else {
      var x = this.lstParticipants.findIndex(mem => String(mem.ParticipantID) === String(value.MemberID));
      this.lstParticipants.splice(x, 1);
      var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(value.MemberID));
      if (x >= 0)
        this.MembersList[x].Status = 0;

    }
  }
  removeParticipant(value, event) {
    var x = this.lstParticipants.findIndex(mem => String(mem.ParticipantID) === String(value.ParticipantID));
    this.lstParticipants.splice(x, 1);

    var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(value.ParticipantID));
    if (x >= 0)
      this.MembersList[x].Status = 0;
  }
  ClearEventDetails() {
    this.EventID = "";
    this.lstParticipants = [];
    this.EventName = "";
    this.StartDate = new Date();
    this.EndDate = new Date();
    this.StartTime = "";
    this.EndTime = "";
    this.Venue = "";
    this.Comments = "";
    this.EventTypeID = "";
    this.EventStatusID = "";
    this.PlayGroupID = "";
    this.MemberTypeID = "";
    this.MembersList = [];
  }
  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }


  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  hidePlanner() {
    this.isPlanner = false;
  }

  SaveEvent() {
    this.MembersList = [];
    this.service.PlannerEventsCRUD(
      {
        ActionFlag: this.ActionFlag,
        UserID: this.UserCode,
        EventID: String(this.EventID),
        EventName: String(this.EventName),
        EventTypeID: String(this.EventTypeID),
        StartDate: this.StartDate,
        StartTime: String(this.StartTime),
        StartMeridian: String(this.StartMeridian),
        EndDate: this.EndDate,
        EndTime: String(this.EndTime),
        EndMeridian: String(this.EndMeridian),
        Venue: String(this.Venue),
        EventStatusID: String(this.EventStatusID),
        Comments: String(this.Comments),
        PlayGroupID: String(this.PlayGroupID),
        MemberTypeID: String(this.MemberTypeID),
        ParticipantID: String(this.ParticipantID),
        lstParticipants: this.lstParticipants
      }).subscribe((ret: any = []) => {
        this.MembersList = ret.MembersList;
        this.LoadCalenderValues('FullView', '');
        this.hidePlanner();
      });
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    if (new Date(selectInfo.endStr) >= new Date()) {
      this.ClearEventDetails();
      this.isPlanner = true;
      this.StartDate = new Date(selectInfo.startStr);
      var enddatetemp = new Date(selectInfo.endStr);
      enddatetemp.setDate(enddatetemp.getDate() - 1);
      this.EndDate = enddatetemp;
      this.ActionFlag = 'Create';
    }

  }
  handleDropEvent(selectInfo: EventDropArg) {
    if (new Date(selectInfo.event._instance.range.start) >= new Date()) {
      this.ClearEventDetails();

      this.StartDate = new Date(selectInfo.event._instance.range.start);
      // var enddatetemp = new Date(selectInfo.event._instance.range.end);
      // enddatetemp.setDate(enddatetemp.getDate());
      // this.EndDate = enddatetemp;
      this.EventID = selectInfo.event.extendedProps.EventID;
      this.service.PlannerEventsCRUD(
        {
          ActionFlag: 'Edit',
          UserID: this.UserCode,
          EventID: String(this.EventID)
        }).subscribe((ret: any = []) => {
          this.ActionFlag = 'Update';
          this.lstParticipants = ret.EventParticipants;

          this.EventName = ret.EventDetails[0].EventName;
          // this.StartDate = ret.EventDetails[0].StartDate;
          // this.EndDate = ret.EventDetails[0].EndDate; 
          var diffDays = Math.ceil(Number(Number(new Date(ret.EventDetails[0].EndDate)) - Number(new Date(ret.EventDetails[0].StartDate))) / (1000 * 3600 * 24));
          var enddatetemp = new Date(this.StartDate);
          enddatetemp.setDate(enddatetemp.getDate() + diffDays);
          this.EndDate = enddatetemp;
          this.StartTime = ret.EventDetails[0].StartTime;
          this.EndTime = ret.EventDetails[0].EndTime;
          this.Venue = ret.EventDetails[0].Venue;
          this.Comments = ret.EventDetails[0].Comments;
          this.EventTypeID = ret.EventDetails[0].EventTypeID;
          this.EventStatusID = ret.EventDetails[0].EventStatusID;
          this.PlayGroupID = ret.EventDetails[0].PlayGroupID;
        });
      this.isPlanner = true;
    }
    else {
      this.hidePlanner();
    }
    // this.hidePlanner();

  }
  handleExtendEvent(selectInfo: EventResizeDoneArg) {
    this.isPlanner = true;

  }

  handleEventClick(clickInfo: EventClickArg) {
    this.ClearEventDetails();
    this.EventID = clickInfo.event._def.extendedProps.EventID;

    this.service.PlannerEventsCRUD(
      {
        ActionFlag: 'Edit',
        UserID: this.UserCode,
        EventID: String(this.EventID)
      }).subscribe((ret: any = []) => {
        this.ActionFlag = 'Update';
        this.lstParticipants = ret.EventParticipants;

        this.EventName = ret.EventDetails[0].EventName;
        this.StartDate = ret.EventDetails[0].StartDate;
        this.EndDate = ret.EventDetails[0].EndDate;
        this.StartTime = ret.EventDetails[0].StartTime;
        this.EndTime = ret.EventDetails[0].EndTime;
        this.Venue = ret.EventDetails[0].Venue;
        this.Comments = ret.EventDetails[0].Comments;
        this.EventTypeID = ret.EventDetails[0].EventTypeID;
        this.EventStatusID = ret.EventDetails[0].EventStatusID;
        this.PlayGroupID = ret.EventDetails[0].PlayGroupID;
      });

    this.isPlanner = true;
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  clickEvent(event) {
    //alert(event.path[0].classList.value);
    if (event.path[1].classList.value == 'fc-next-button fc-button fc-button-primary') {
      //alert('next month clicked');
    }
    if (event.path[1].classList.value == 'fc-prev-button fc-button fc-button-primary') {
      //alert('previous month clicked');
    }
    else if (event.path[0].classList.value == 'fc-timeGridWeek-button fc-button fc-button-primary fc-button-active') {
      // alert('week button');
    }
    else if (event.path[0].classList.value == 'fc-timeGridDay-button fc-button fc-button-primary fc-button-active') {
      // alert('Day button');
    }
    else if (event.path[0].classList.value == 'fc-listWeek-button fc-button fc-button-primary fc-button-active') {
      // alert('list button');
    }
    else if (event.path[0].classList.value == 'fc-dayGridMonth-button fc-button fc-button-primary fc-button-active') {
      //alert('month button');
    }
  }

  CoachDashboardLoad() {
    this.isShowLoader = true;
    var CoachLoad = { ActionFlag: "Dashboard", UserID: String(this.UserCode) };
    this.service.CoachDashboard(CoachLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.CoachDashboardDetail = ret.CoachDashboardDetailList;
        if (this.CoachDashboardDetail.length > 0) {
          this.ProfileImage = this.CoachDashboardDetail[0].ProfileImage;
          this.ProfileDisplayName = this.CoachDashboardDetail[0].DisplayName;
          this.Age = this.CoachDashboardDetail[0].Age;
          this.AssociationName = this.CoachDashboardDetail[0].AssociationName;
          this.Proficiency = this.CoachDashboardDetail[0].SpecName;
          this.Level = this.CoachDashboardDetail[0].MemberLevelName;
          this.isShowLoader = false;
        }
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }
}

export interface CalenderValues {
  title: string, start: string, end: string
}