import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';
import { CurrencyPipe } from '@angular/common'
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-finance-master',
  templateUrl: './finance-master.component.html',
  styleUrls: ['./finance-master.component.css'],
  providers:[CurrencyPipe]
})
export class FinanceMasterComponent implements OnInit {
  packageForm: FormGroup; topupForm: FormGroup;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  tabmaster: string = "package";
  constructor(private utility: UtilitiesService, public service: MastersServiceService, private formBuilder: FormBuilder
    ,public currency:CurrencyPipe,private chRef: ChangeDetectorRef) { }
  public ColumnMode: ColumnMode;
  public LIMITS = [
    { value: 1 },
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 20 }
  ];
  seasons: any = [];
  AcademyTypes: any = [];
  PackageTypes: any = [];
  PackageDurations: any = [];
  PackageMasterGrid: any = [];
  TopupMasterGrid: any = [];
  public UserCode: string;
  public SeasonID!: string;
  public academyType!: string;
  public playerPackage!: string;
  public packageDuration!: string;
  public MonthlyVideos!: string;
  public Amount!: string;
  public GSTPercent!: string;
  public PackageManagerID!: string;
  public packageFlag = "Create";
  TopupTotalAmount: string = "";
  rowLimit = 10;

  AlertType: string = '';
  AlertMessage: string = '';
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.packageForm = this.formBuilder.group({
      _academyType: ['', Validators.required],
      _playerPackage: ['', Validators.required],
      _MonthlyVideos: ['', Validators.required],
      _packageDuration: ['', Validators.required],
      _Amount: ['', Validators.required],
      _GSTPercent: ['', Validators.required],
    });
    this.topupForm = this.formBuilder.group({
      _TopupVideos: ['', Validators.required],
      _TopupAmount: ['', Validators.required],
      _TopupGST: ['', Validators.required],
      _TopupTotalAmount: []
    });
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.LoadDropdowns();
    this.LoadPackageGridData();
    this.LoadTopupGridData();


  }

  LoadDropdowns() {
    this.service.Finance({
      method: "Finance/PackageMasterLoad",
      request: { ActionFlag: "DropDown", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {

      this.seasons = ret.seasons;
      this.AcademyTypes = ret.AcademyTypes;
      this.PackageTypes = ret.PackageTypes;
      this.PackageDurations = ret.PackageDurations;
    });
  }
  LoadPackageGridData() {
    this.service.Finance({
      method: "Finance/PackageMasterLoad",
      request: { ActionFlag: "GridLoad", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {

      this.PackageMasterGrid = ret.PackageMasterGrid;
    });
  }
  SavePackage() {
    if (this.packageForm.invalid) {
      return;
    }
    else {
      this.service.Finance({
        method: "Finance/PackageMaster",
        request: {
          ActionFlag: this.packageFlag,
          RefUserID: String(this.UserCode),
          PackageManagerID: String(this.PackageManagerID),
          AcademyTypeID: String(this.academyType),
          PackageID: String(this.playerPackage),
          DurationID: String(this.packageDuration),
          NoOfVideos: String(this.MonthlyVideos),
          Amount: String(this.Amount),
          GST: String(this.GSTPercent)
        }
      }).subscribe((ret: any = []) => {
        if (ret.Status) {
          this.AlertType = 'Success';
          if (this.TopupFlag == "Create")
            this.AlertMessage = "Package Registred successfully";
          else
            this.AlertMessage = "Package updated successfully";
          this.packageFlag = "Create";
          this.clearPackage();
          this.LoadPackageGridData();
        } else {
          this.AlertType = 'Error';
          this.AlertMessage = ret.Message;
        }
      });
    }
  }
  EditPackage(data: any) {
    this.packageFlag = "Update";
    this.PackageManagerID = data.PackageManagerID;
    this.academyType = data.AcademyTypeID;
    this.playerPackage = data.PackageID;
    this.packageDuration = data.DurationID;
    this.MonthlyVideos = data.NoOfVideos;
    this.Amount = data.Amount;
    this.GSTPercent = data.GST;
  }
  clearPackage() {
    this.packageFlag = "Create";
    this.PackageManagerID = "",
      this.academyType = "",
      this.playerPackage = "",
      this.packageDuration = "",
      this.MonthlyVideos = "",
      this.Amount = "",
      this.GSTPercent = ""
  }
  TopupID!: string;
  TopupVideos!: string;
  TopupAmount!: string;
  TopupGST!: string;
  TopupFlag: string = "Create";
  LoadTopupGridData() {
    this.service.Finance({
      method: "Finance/TopupMaster",
      request: { ActionFlag: "GridLoad", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {

      this.TopupMasterGrid = ret.TopupMasterGrid;
    });
  }
  CalculateTotal() {
    if (this.topupForm.invalid) {
      this.TopupTotalAmount = "";
      return;
    }
    else {
      this.TopupTotalAmount =String(Number(this.TopupAmount) + ((Number(this.TopupGST) * Number(this.TopupAmount)) / 100));
    }
  }
  SaveTopup() {
    if (this.topupForm.invalid) {
      return;
    }
    else {
      this.service.Finance({
        method: "Finance/TopupMaster",
        request: {
          ActionFlag: this.TopupFlag,
          RefUserID: String(this.UserCode),
          TopupID: String(this.TopupID),
          NoOfVideos: String(this.TopupVideos),
          Amount: String(this.TopupAmount),
          GST: String(this.TopupGST)
        }
      }).subscribe((ret: any = []) => {
        if (ret.Status) {
          this.AlertType = 'Success';
          if (this.TopupFlag == "Create")
            this.AlertMessage = "Topup Registred successfully";
          else
            this.AlertMessage = "Topup updated successfully";
          this.TopupFlag = "Create";
          this.clearTopup();
          this.LoadTopupGridData();
        } else {
          this.AlertType = 'Error';
          this.AlertMessage = ret.Message;
        }
      });
    }
  }
  EditTopup(data: any) {
    this.TopupFlag = "Update";
    this.TopupID = data.TopupID;
    this.TopupVideos = data.NoOfVideos;
    this.TopupAmount = data.Amount;
    this.TopupGST = data.GST;
    this.chRef.detectChanges();
    this.CalculateTotal();
  }
  clearTopup() {
    this.TopupFlag = "Create";
    this.TopupID = "";
    this.TopupVideos = "";
    this.TopupAmount = "";
    this.TopupGST = "";
  }
}
