import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { selectedCoachList } from '../coach-list/coach-list.component';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
//import { Certificate } from 'crypto';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

declare var $: any

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.css']
})
export class CoachComponent implements OnInit {

  CoachBasicForm: FormGroup; CoachContactForm: FormGroup;
  CoachInformationForm: FormGroup; CoachAddressForm: FormGroup;
  isLinear = true; submitted = false;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }

  public UserCode: string = ""; public ButtonType: string; public RefUserID: string;

  public minDate: Date;
  public maxDate: Date;
  public todayDate: Date;

  public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];
  public ImageDocuments: ImageDocumentDetails[] = [{ data: "", name: "" }];
  public ImageCertificates: ImageDocumentDetails[] = [{ data: "", name: "" }];

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;

  @Input()
  public AssociationName: string; public FirstName: string; public LastName: string;
  public FatherName: string; public DisplayName: string; public Gender: string; public DOB: string;
  public RegistrationNumber: string; public RegistrationDate: string; public CoachImage: string;
  public CoachImagepath: string;
  public MobileNumber: string; public EmailId: string;
  public EmergencyContactPerson: string; public EmergencyContactNumber: string; public BloodGroup: string; public Nationality: string;
  public CoachLevel: string; public CoachSpecification: string; public CoachCertificateFile: string; public CoachCertficatepath: string;
  public Description: string; public Agency: string;
  public Address01: string; public Address02: string;
  public Pincode: string; public IDProof: string = ""; public IDProofFile: string = ""; public CoachIDProofpath: string = ""; public ReferralCode: string; public ValidDate: string;
  today = new Date();

  public ContinentsList: any = []; public CountryByRegion: any = [];
  public StateByCountry: any = []; public CityByState: any = [];
  public Continent: string; public Country: string;
  public States: string; public City: string;

  public InputData: Inputs[] = [{ ID: "", ActionFlag: "Load" }];
  public ContinentID: string; public StateID: string; public CountryID: string; public CityID: string;

  public AssociationList: any[]; public SportsList: any[];
  public MemberList: any[]; public GenderList: any[];
  public BloodGroupList: any[]; public CountryList: any[];
  public MemberLevelList: any[]; public MemberTypeList: any[];
  public SpecsList: any[]; public DocumentTypeList: any[];
  public CoachDetails: any[];

  public filOfcRoleUpdate: any = []; public croppedImage: string;
  public imageChangedEvent: any = '';
  public imageChangedType: string = '';
  public displayImageCrop: string = 'none';

  ngOnInit(): void {
    this.UserCode = JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID;
    this.utility.pageLoadScript();
    this.InitPageLoad();
  }

  InitPageLoad() {
    var minCurrentDate = new Date();
    var maxNewDate = new Date();
    this.minDate = minCurrentDate;
    this.maxDate = new Date(maxNewDate.getFullYear(), maxNewDate.getMonth(), maxNewDate.getDate());

    var dateToday = new Date();
    this.RegistrationDate = this.datepipe.transform(dateToday, 'yyyy-MM-dd');

    this.isShowLoader = true;
    this.ButtonType = "Save"
    const URL = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const Email = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.CoachBasicForm = this.formBuilder.group({
      n_AssociationName: ['', Validators.required],
      n_FirstName: ['', Validators.required],
      n_LastName: ['', Validators.required],
      n_FatherName: ['', Validators.required],
      n_DisplayName: ['', Validators.required],
      n_Gender: ['', Validators.required],
      n_DOB: ['', Validators.required],
    });
    this.CoachContactForm = this.formBuilder.group({
      n_RegistrationNumber: [],
      n_RegistrationDate: ['', Validators.required],
      n_MobileNumber: ['', Validators.required],
      n_EmailID: ['', [Validators.required, Validators.email]],
      n_CoachImage: [],
      n_EmergencyContactPerson: [],
      n_EmergencyContactNumber: [],
      n_BloodGroup: [],
      n_Nationality: ['', Validators.required],
    });
    this.CoachInformationForm = this.formBuilder.group({
      n_CoachLevel: ['', Validators.required],
      n_CoachSpecification: ['', Validators.required],
      n_CoachCertificateFile: [],
      n_Description: [],
      n_Agency: []

    });
    this.CoachAddressForm = this.formBuilder.group({
      n_Address01: ['', Validators.required],
      n_Address02: [],
      n_Continent: ['', Validators.required],
      n_Country: ['', Validators.required],
      n_States: ['', Validators.required],
      n_City: ['', Validators.required],
      n_Pincode: ['', Validators.required],
      n_IDProof: [],
      n_IDProofFile: [],
      n_ReferralCode: [],
      n_ValidDate: ['', Validators.required],
    });

    var InitLoad = { ActionFlag: "Load", UserID: String(this.UserCode), ModuleName: "" }
    this.service.AssoCoachRegLoad(InitLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.AssociationList = ret.AssoList;
        this.SportsList = ret.SportsList;
        this.MemberTypeList = ret.MemberTypeList;
        this.GenderList = ret.GenderList;
        this.CountryList = ret.CountryList;
        this.MemberLevelList = ret.MemLevelList;
        this.BloodGroupList = ret.BloodGroupList;
        this.SpecsList = ret.SpecsList;
        this.DocumentTypeList = ret.DocumentList;

        this.AssociationName = this.AssociationList[0].AssociationID;

        var RegionLoad = { ID: "", ActionFlag: "Load" };
        this.service.loadAssociationMaster(RegionLoad).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
            this.ContinentsList = ret.ContinentsList;
            if (sessionStorage.getItem("CoachPageStatus") == "Edit") {
              //alert(sessionStorage.getItem("CoachID"));
              this.service.AssoCoachRegLoad({ RefUserID: String(selectedCoachList.UserID), ActionFlag: "Edit" }).subscribe((ret: any = []) => {
                let BTStatus = String(ret.Status);
                if (BTStatus == "true") {
                  this.AssociationName = ret.AssoCoachList[0].AssociationID;
                  this.FirstName = ret.AssoCoachList[0].FirstName;
                  this.LastName = ret.AssoCoachList[0].LastName;
                  this.FatherName = ret.AssoCoachList[0].FatherName;
                  this.DisplayName = ret.MemberTypeList[0].DisplayName;

                  this.RegistrationDate = "";

                  this.Gender = ret.AssoCoachList[0].Gender;
                  if (ret.AssoCoachList[0].Gender == "1") { this.Gender = "1" }
                  else { this.Gender = "2" }

                  // this.Gender= ret.AssoCoachList[0].Gender; 
                  this.DOB = ret.AssoCoachList[0].DOB;
                  this.RegistrationNumber = ret.AssoCoachList[0].RegisterNumber;
                  this.RegistrationDate = ret.AssoCoachList[0].RegistrationDate;
                  this.CoachImage = ret.AssoCoachList[0].ProfileImage;
                  //this.CoachImagepath = ret.AssoCoachList[0].ProfileImage;
                  this.MobileNumber = ret.AssoCoachList[0].LoginMobile;
                  this.EmailId = ret.AssoCoachList[0].LoginEmail;
                  this.EmergencyContactPerson = ret.AssoCoachList[0].EmergencyContact;
                  this.EmergencyContactNumber = ret.AssoCoachList[0].EmergencyNo;
                  this.BloodGroup = ret.AssoCoachList[0].BloodGroupID;
                  this.Nationality = ret.AssoCoachList[0].Nationality;
                  this.CoachLevel = ret.MemberTypeList[0].LevelID;
                  this.CoachSpecification = ret.MemberTypeList[0].SpecID;
                  this.CoachCertificateFile = ret.MemberTypeList[0].CertifyUpload;
                  //this.CoachCertficatepath = ret.MemberTypeList[0].CertifyUpload;
                  this.Description = ret.MemberTypeList[0].Description;
                  this.Agency = ret.MemberTypeList[0].CertifyAgency;
                  this.Address01 = ret.AssoCoachList[0].Address1;
                  this.Address02 = ret.AssoCoachList[0].Address2;
                  this.Pincode = ret.AssoCoachList[0].Pincode;
                  this.IDProof = ret.AssoCoachList[0].IDProofType;
                 // this.CoachIDProofpath = ret.AssoCoachList[0].IDProodUpload;
                  this.IDProofFile = ret.AssoCoachList[0].IDProodUpload;
                  this.ReferralCode = ret.AssoCoachList[0].ReferalCode;
                  this.ValidDate = ret.AssoCoachList[0].PackageValid;

                  this.Continent = ret.AssoCoachList[0].RegionID;
                  this.onChangeContinent(this.Continent);
                  this.Country = ret.AssoCoachList[0].CountryID;
                  this.onChangeCountry(this.Country);
                  this.States = ret.AssoCoachList[0].StateID;
                  this.onChangeState(this.States);
                  this.City = ret.AssoCoachList[0].CityID;

                  this.RefUserID = String(this.UserCode);

                  sessionStorage.setItem("CoachPageStatus", "");
                  this.ButtonType = "Update";
                }
                else {
                  this.isShowLoader = false;
                  this.isErrorAlert = true;
                  this.errorAlert = '\n Edit details load failed..!! \n';
                  return;
                }
              })
            }
          }
          else {
            this.isShowLoader = false;
            this.isErrorAlert = true;
            this.errorAlert = '\n Initial details load failed..!! \n';
            return;
            this.utility.openSnackBar('Load Failed..!!');
          }
        })
        this.isShowLoader = false;
        //sessionStorage.setItem("CoachID",String(coach.UserID));)
      }
      else {
        this.utility.openSnackBar('Load failed..!!');
      }
    })


  }

  onChangeContinent(cont) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(cont), ActionFlag: "GetCountryByRegion" });
    this.ContinentID = cont;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.CountryByRegion = ret.CountryByRegion;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Country details load failed..!! \n';
        return;
      }
    })
  }

  onChangeCountry(country) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(country), ActionFlag: "GetStateByCountry" });
    this.CountryID = country;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.StateByCountry = ret.StateByCountry;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n State details load failed..!! \n';
        return;
      }
    })
  }

  onChangeState(state) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(state), ActionFlag: "GetCityByState" });
    this.StateID = state;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.CityByState = ret.CityByState;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n City details load failed..!! \n';
        return;
      }
    })
  }

  onChangeCity(city) {
    this.CityID = city;
    this.City = city;
  }

  onSubmit() {
    this.submitted = true;
    if (this.CoachAddressForm.invalid) {
      this.isWarningAlert = true;
      this.warningAlert = 'Please fill out all mandatory fields..!!'
      return;
    }
    else {
      this.SaveCoachDetails();
    }
  }

  SaveCoachDetails() {
    this.isShowLoader = true;
    let submitType: string;

    var profileImage = ""; var documentImage = ""; var coachCertificate = "";

    if (this.ButtonType == "Save") { submitType = "Register", this.RefUserID = ""; }
    else { submitType = "Update", this.RefUserID = String(selectedCoachList.UserID) }

    let DOB = this.datepipe.transform(this.DOB, 'yyyy-MM-dd');
    let regDate = this.datepipe.transform(this.RegistrationDate, 'yyyy-MM-dd');
    let valDate = this.datepipe.transform(this.ValidDate, 'yyyy-MM-dd');

    if (this.BloodGroup == undefined || this.BloodGroup == "" || this.BloodGroup == null) { this.BloodGroup = "" }
    else { this.BloodGroup = this.BloodGroup }

    if (this.Agency == undefined || this.Agency == "" || this.Agency == null) { this.Agency = "" }
    else { this.Agency = this.Agency }

    if (this.EmergencyContactPerson == undefined || this.EmergencyContactPerson == "" || this.EmergencyContactPerson == null) { this.EmergencyContactPerson = "" }
    else { this.EmergencyContactPerson = this.EmergencyContactPerson }

    if (this.EmergencyContactNumber == undefined || this.EmergencyContactNumber == "" || this.EmergencyContactNumber == null) { this.EmergencyContactNumber = "" }
    else { this.EmergencyContactNumber = this.EmergencyContactNumber }

    // if(valDate==null)valDate=""; 
    this.CoachDetails = [{
      AssociationID: String(this.AssociationName),
      FirstName: this.FirstName,
      LastName: this.LastName,
      FatherName: this.FatherName,
      DisplayName: this.DisplayName,
      Gender: String(this.Gender),
      DOB: DOB,
      RegistrationDate: regDate,
      ProfileImage: this.CoachImage,
      LoginMobile: this.MobileNumber,
      LoginEmail: this.EmailId,
      EmergencyContact: this.EmergencyContactPerson,
      EmergencyNo: this.EmergencyContactNumber,
      BloodGroupID: String(this.BloodGroup),
      Nationality: String(this.Nationality),

      LevelID: String(this.CoachLevel),
      SpecID: String(this.CoachSpecification),
      Description: this.Description,
      CertifyAgency: String(this.Agency),
      CertifyUpload: String(this.CoachCertificateFile),
      Address1: this.Address01,
      Address2: this.Address02,
      RegionID: String(this.ContinentID),
      CountryID: String(this.CountryID),
      StateID: String(this.StateID),
      CityID: String(this.City),
      Pincode: this.Pincode,
      IDProofType: String(this.IDProof),
      IDProodUpload: this.IDProofFile,
      ReferalCode: this.ReferralCode,
      PackageValid: String(valDate),
      MemberTypeID: "2",
      UserID: String(this.UserCode),
      RefUserID: String(this.RefUserID),
      ActionFlag: submitType
    }]
    if (this.ImageDetails[0].data != "") {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          profileImage = ret.name;
          this.CoachDetails[0].ProfileImage = profileImage;

          if (this.ImageDocuments[0].data != "") {
            this.service.UploadFile(this.ImageDocuments[0]).subscribe((ret: any = []) => {
              if (ret.Status == true) {
                documentImage = ret.name;
                this.CoachDetails[0].IDProodUpload = documentImage;
                if (this.ImageCertificates[0].data != "") {
                  this.service.UploadFile(this.ImageCertificates[0]).subscribe((ret: any = []) => {
                    if (ret.Status == true) {
                      coachCertificate = ret.name;
                      this.CoachDetails[0].CertifyUpload = coachCertificate;
                      this.SaveCoachCRUD();
                    }
                  })
                }
                else {
                  this.SaveCoachCRUD();
                }
              }
            })
          } else if (this.ImageCertificates[0].data != "") {
            this.service.UploadFile(this.ImageCertificates[0]).subscribe((ret: any = []) => {
              if (ret.Status == true) {
                coachCertificate = ret.name;
                this.CoachDetails[0].CertifyUpload = coachCertificate;
                this.SaveCoachCRUD();
              }
            })
          }
          else {
            this.SaveCoachCRUD();
          }
        }
      })
    }
    else if (this.ImageDocuments[0].data != "") {
      this.service.UploadFile(this.ImageDocuments[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          documentImage = ret.name;
          this.CoachDetails[0].IDProodUpload = documentImage;
          if (this.ImageCertificates[0].data != "") {
            this.service.UploadFile(this.ImageCertificates[0]).subscribe((ret: any = []) => {
              if (ret.Status == true) {
                coachCertificate = ret.name;
                this.CoachDetails[0].CertifyUpload = coachCertificate;
                this.SaveCoachCRUD();
              }
            })
          }
          else {
            this.SaveCoachCRUD();
          }
        }
      })
    } else if (this.ImageCertificates[0].data != "") {
      this.service.UploadFile(this.ImageCertificates[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          coachCertificate = ret.name;
          this.CoachDetails[0].CertifyUpload = coachCertificate;
          this.SaveCoachCRUD();
        }
      })
    }
    else {
      this.SaveCoachCRUD();
    }

  }

  SaveCoachCRUD() {
    this.service.CoachMasterCRUD(this.CoachDetails[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        if (this.ButtonType == "Save") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Saved successfully..!!';
        else if (this.ButtonType == "Update") this.successAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updated successfully..!!';
        this.isShowLoader = false;
        // this.router.navigate(['/coach-list']);
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        if (this.ButtonType == "Save") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Save failed..!!';
        else if (this.ButtonType == "Update") this.errorAlert = '\n"' + this.FirstName + '\n' + this.LastName + '"\n Updation failed..!!';
      }
    })
  }

  ClearCoachDetails() {
    this.AssociationName = ""; this.FirstName = ""; this.LastName = ""; this.FatherName = ""; this.DisplayName = ""; this.Gender = ""; this.DOB = ""; this.RegistrationDate = "";
    this.RegistrationNumber = ""; this.CoachImage = ""; this.CoachImagepath = ""; this.MobileNumber = ""; this.EmergencyContactPerson = ""; this.EmergencyContactNumber = "";
    this.BloodGroup = ""; this.Nationality = ""; this.CoachLevel = ""; this.CoachSpecification = "";
    this.CoachCertificateFile = ""; this.CoachCertficatepath = "";
    this.Description = ""; this.Agency = ""; this.Address01 = ""; this.Address02 = ""; this.Pincode = ""; this.EmailId = ""; this.ValidDate = ""; this.ReferralCode = "";
    this.Pincode = ""; this.IDProof = ""; this.IDProofFile = ""; this.CoachIDProofpath = ""; this.ReferralCode = ""; this.ValidDate = "";
    this.ButtonType = "Save";
  }

  BaseToContact(stepper: MatStepper) {
    if (this.AssociationName != "" && this.AssociationName != null && this.FirstName != "" && this.FirstName != null &&
      this.LastName != "" && this.LastName != null &&
      this.FatherName != "" && this.FatherName != null &&
      this.DisplayName != "" && this.DisplayName != null &&
      this.Gender != "" && this.Gender != null &&
      this.DOB != "" && this.DOB != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!'
    return;
  }

  ContactToInfo(stepper: MatStepper) {
    if (this.RegistrationDate != "" && this.RegistrationDate != null &&
      this.MobileNumber != "" && this.MobileNumber != null && this.EmailId != "" && this.EmailId != null &&
      this.Nationality != "" && this.Nationality != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!'
    return;
  }

  InfoToAddress(stepper: MatStepper) {
    if (this.CoachLevel != "" && this.CoachLevel != null &&
      this.CoachSpecification != "" && this.CoachSpecification != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!'
    return;
    // stepper.next();
  }

  AddressVal(stepper: MatStepper) {
    if (this.Address01 != "" && this.Address01 != null && this.Continent != "" && this.Continent != null &&
      this.Country != "" && this.Country != null && this.States != "" && this.States != null &&
      this.City != "" && this.City != null && this.Pincode != "" && this.Pincode != null &&
      this.IDProof != "" && this.IDProof != null && this.ReferralCode != "" && this.ReferralCode != null &&
      this.ValidDate != "" && this.ValidDate != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!'
    return;
  }

  onFileChange(event,type) {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   this.CoachImagepath = event.target.value;
    //   reader.onload = () => {
    //     this.CoachImage = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.CoachImage, name: file.type });
    //   };
    // }

    
    const reader = new FileReader();
    this.imageChangedEvent = event;
    this.imageChangedType = type;
    $('#modalcrop').modal('show');

  }

  
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  
  Applycropper() {
    this.CoachImage = this.croppedImage;
    this.ImageDetails = [];
    this.ImageDetails.push({ data: this.CoachImage, name: 'image/png' });
    $('#modalcrop').modal('hide');
    this.CoachImagepath = "";
  }

  saveFile(file, type) {
    this.ImageDetails = [];
    this.ImageDetails.push({
      data: file, name: type
    });
  }

  onDocChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.CoachIDProofpath = event.target.value;
      reader.onload = () => {
        this.IDProofFile = reader.result as string;
        this.ImageDocuments = [];
        this.ImageDocuments.push({ data: this.IDProofFile, name: file.type });
      };
    }
  }

  onCertChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.CoachCertficatepath = event.target.value;
      reader.onload = () => {
        this.CoachCertificateFile = reader.result as string;
        this.ImageCertificates = [];
        this.ImageCertificates.push({ data: this.CoachCertificateFile, name: file.type });
      };
    }
  }

  saveDoc(file, type) {
    this.ImageDocuments = [];
    this.ImageDocuments.push({
      data: file, name: type
    });
  }



}

export interface ImageDetails { data: string, name: string }
export interface ImageDocumentDetails { data: string, name: string }

export interface Inputs { ID: string; ActionFlag: string; }