import { Component, OnInit, Input, ViewChild, TemplateRef, AfterViewInit, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventDropArg } from '@fullcalendar/angular';
//import { INITIAL_EVENTS, createEventId } from './event-utils';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import 'fullcalendar';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import * as $ from 'jquery';

export interface User {
  name: string;
}

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.css']
})
export class PlannerComponent implements OnInit {
  EventRegForm: FormGroup;
  public UserCode: string = "";
  public RefUserID: string;
  public templates: boolean = false;
  public isPlanner: boolean = false;
  public Monthlist = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


  myControl = new FormControl();
  options: User[] = [
    { name: 'Net Practice' },
    { name: 'Front Foot' },
    { name: 'Back Foot' }
  ];
  filteredOptions!: Observable<User[]>;

  calendarVisible = true;
  public calendarOptions: CalendarOptions;

  currentEvents: EventApi[] = [];



  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }


  @Input() eventData: any;
  defaultConfigurations: any;
  @Input()
  set configurations(config: any) {
    if (config) {
      this.defaultConfigurations = config;
    }
  }
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private utility: UtilitiesService, private service: MastersServiceService, private ElementRef: ElementRef, private formBuilder: FormBuilder) {


    this.defaultConfigurations = {
      editable: true,
      eventLimit: true,
      titleFormat: 'MMM D YYYY',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      views: {
        agenda: {
          eventLimit: 3
        }
      },
      allDaySlot: false,
      slotDuration: moment.duration('00:15:00'),
      slotLabelInterval: moment.duration('01:00:00'),
      firstDay: 1,
      selectable: true,
      selectHelper: true,
      events: this.eventData,

    };

  }
  public ListEvents: any = [];
  public loadedEvents: any = [];
  public EventTypeList: any = [];
  public EventStatusList: any = [];
  public MembetTypeList: any = [];
  public PlayerGroupList: any = [];
  public MembersList: any = [];
  //public PlayerGroup:string;
  //public MemberType:string;
  public StartTime: string;
  public EndTime: string;
  public lstParticipants: any = [];
  public EventID: string = "";
  public EventName: string;
  public EventTypeID: string;
  public EventTypesID: string;
  public StartDate: Date;
  public StartMeridian: string = "";
  public EndDate: Date;
  public EndMeridian: string = "";
  public Venue: string;
  public EventStatusID: string;
  public Comments: string;
  public PlayGroupID: string;
  public MemberTypeID: string;
  public ParticipantID: string;
  public ActionFlag: string = 'Create';
  public SearchParticipant: string;
  public SearchSelParticipant: string;
  public Calendarmonth: string;
  public monthlyevents: any = [];


  ngOnInit(): void {
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    this.calendarOptions = {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      initialView: 'dayGridMonth',
      initialEvents: [
        // { title: 'Batting Practice', start: '2021-03-22T06:30', end: '2021-03-22T18:20' ,color:'red'},
        // {
        //   title: 'Nets – Bowling', startTime: '06:30', endTime: '08:30',
        //   startRecur: '2021-03-26',
        //   endRecur: '2021-03-26',color:'orange'
        // },
        // {
        //   title: 'Nets – Bowlinga', start:'2021-03-26',end:'2021-03-26', color:'orange'
        // }

      ], // alternatively, use the `events` setting to fetch from a feed
      // validRange: {
      //   start: new Date()
      // },
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      displayEventTime: true,
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventsSet: this.handleEvents.bind(this),
      droppable: false,
      eventDrop: this.handleDropEvent.bind(this),
      eventResize: this.handleExtendEvent.bind(this),
      dropAccept: '.cool-event'

    };
    this.LoadCalenderValues('FullView', '');
    this.service.PlannerPageLoad({ ActionFlag: 'Load', UserID: this.UserCode }).subscribe((ret: any = []) => {
      this.EventTypeList = ret.EventTypeList;
      this.EventStatusList = ret.EventStatusList;
      this.MembetTypeList = ret.MembetTypeList;
      this.PlayerGroupList = ret.PlayerGroupList;
    });

    this.EventRegForm = this.formBuilder.group({
      n_event_name: ['', Validators.required],
      n_startdate: [],
      n_enddate: [],
      n_starttime: ['', Validators.required],
      n_endtime: ['', Validators.required],
      n_event_type: ['', Validators.required],
      n_event_status: ['', Validators.required],
      n_venue: ['', Validators.required],
      n_comments: [],
      n_player_group: [],
      n_member_type: [],
      n_filter_participant: [],
      n_filter_selparticipant: []
    });


  }
  LoadCalenderValues(type, eventId) {
    this.service.PlannerCalendarLoad({ ActionFlag: type, UserID: this.UserCode, EventID: eventId }).subscribe((ret: any = []) => {
      this.ListEvents = ret.EventsList;
      this.calendarOptions.events = this.ListEvents;
      this.Calendarmonth = document.getElementsByClassName('fc-toolbar-title')[0].innerHTML;
      this.monthlyevents = this.ListEvents;
    });
  }
  PlayerGroupChange() {
    if (this.MemberTypeID != "") {
      this.MemberTypeChange();
    }
  }

  MemberTypeChange() {
    this.MembersList = [];
    this.service.PlannerPageLoad({ ActionFlag: 'GetMembers', UserID: this.UserCode, PlayGroupID: String(this.PlayGroupID), MemberTypeID: String(this.MemberTypeID) }).subscribe((ret: any = []) => {
      this.MembersList = ret.MembersList;
      var temp = this.lstParticipants.filter(s => String(s.MemberTypeID) === String(this.MemberTypeID));
      for (var i = 0; i < temp.length; i++) {
        var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(temp[i].ParticipantID));

        if (x >= 0)
          this.MembersList[x].Status = 1;
      }
    });
  }

  onAddclick(value, event) {
    if (event.currentTarget.checked == true) {
      this.lstParticipants.push({ EventID: String(this.EventID), ParticipantID: String(value.MemberID), MemberTypeID: String(this.MemberTypeID), DisplayName: value.DisplayName, MemberName: value.MemberName, PlayGroupID: String(this.PlayGroupID) });
      // this.listAthleteRefUserID.push(value.RefUserID);
      var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(value.MemberID));
      if (x >= 0)
        this.MembersList[x].Status = 1;
    }
    else {
      var x = this.lstParticipants.findIndex(mem => String(mem.ParticipantID) === String(value.MemberID));
      this.lstParticipants.splice(x, 1);
      var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(value.MemberID));
      if (x >= 0)
        this.MembersList[x].Status = 0;

    }
  }
  removeParticipant(value, event) {
    var x = this.lstParticipants.findIndex(mem => String(mem.ParticipantID) === String(value.ParticipantID));
    this.lstParticipants.splice(x, 1);

    var x = this.MembersList.findIndex(mem => String(mem.MemberID) === String(value.ParticipantID));
    if (x >= 0)
      this.MembersList[x].Status = 0;
  }
  ClearEventDetails() {
    this.EventID = "";
    this.lstParticipants = [];
    this.EventName = "";
    // this.StartDate = null;
    // this.EndDate = null;
    this.StartTime = "";
    this.EndTime = "";
    this.Venue = "";
    this.Comments = "";
    this.EventTypeID = "";
    this.EventStatusID = "";
    this.PlayGroupID = "";
    this.MemberTypeID = "";
    this.MembersList = [];
  }
  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  hidePlanner() {
    this.isPlanner = false;
    this.LoadCalenderValues('FullView', '');
  }
  onSubmitEventReg() {
    if (this.EventRegForm.invalid) { 
      return;
    }
    else {
      if (this.StartDate && this.EndDate)
        this.SaveEvent();
    }
  }
  SaveEvent() {
    this.MembersList = [];
    this.service.PlannerEventsCRUD(
      {
        ActionFlag: this.ActionFlag,
        UserID: this.UserCode,
        EventID: String(this.EventID),
        EventName: String(this.EventName),
        EventTypeID: String(this.EventTypeID),
        StartDate: new Date(this.StartDate),
        StartTime: String(this.StartTime),
        StartMeridian: String(this.StartMeridian),
        EndDate: new Date(this.EndDate),
        EndTime: String(this.EndTime),
        EndMeridian: String(this.EndMeridian),
        Venue: String(this.Venue),
        EventStatusID: String(this.EventStatusID),
        Comments: String(this.Comments),
        PlayGroupID: String(this.PlayGroupID),
        MemberTypeID: String(this.MemberTypeID),
        ParticipantID: String(this.ParticipantID),
        lstParticipants: this.lstParticipants
      }).subscribe((ret: any = []) => {
        //window.location.reload();
        this.MembersList = ret.MembersList;
        this.LoadCalenderValues('FullView', '');
        this.hidePlanner();
      });
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    if (new Date(selectInfo.endStr) >= new Date()) {
      this.ClearEventDetails();
      //window.location.reload();
      this.isPlanner = true;
      this.StartDate = new Date(selectInfo.startStr);
      var enddatetemp = new Date(selectInfo.endStr);
      enddatetemp.setDate(enddatetemp.getDate() - 1);
      this.EndDate = enddatetemp;
      this.ActionFlag = 'Create';
    }

  }
  handleDropEvent(selectInfo: EventDropArg) {
    if (new Date(selectInfo.event._instance.range.start) >= new Date()) {
      this.ClearEventDetails();

      this.StartDate = new Date(selectInfo.event._instance.range.start);
      // var enddatetemp = new Date(selectInfo.event._instance.range.end);
      // enddatetemp.setDate(enddatetemp.getDate());
      // this.EndDate = enddatetemp;
      this.EventID = selectInfo.event.extendedProps.EventID;
      this.service.PlannerEventsCRUD(
        {
          ActionFlag: 'Edit',
          UserID: this.UserCode,
          EventID: String(this.EventID)
        }).subscribe((ret: any = []) => {
          this.ActionFlag = 'Update';
          this.lstParticipants = ret.EventParticipants;

          this.EventName = ret.EventDetails[0].EventName;
          // this.StartDate = ret.EventDetails[0].StartDate;
          // this.EndDate = ret.EventDetails[0].EndDate; 
          var diffDays = Math.ceil(Number(Number(new Date(ret.EventDetails[0].EndDate)) - Number(new Date(ret.EventDetails[0].StartDate))) / (1000 * 3600 * 24));
          var enddatetemp = new Date(this.StartDate);
          enddatetemp.setDate(enddatetemp.getDate() + diffDays);
          this.EndDate = enddatetemp;
          this.StartTime = ret.EventDetails[0].StartTime;
          this.EndTime = ret.EventDetails[0].EndTime;
          this.Venue = ret.EventDetails[0].Venue;
          this.Comments = ret.EventDetails[0].Comments;
          this.EventTypeID = ret.EventDetails[0].EventTypeID;
          this.EventStatusID = ret.EventDetails[0].EventStatusID;
          this.PlayGroupID = ret.EventDetails[0].PlayGroupID;
        });
      this.isPlanner = true;
    }
    else {
      this.hidePlanner();
    }
    // this.hidePlanner();

  }
  handleExtendEvent(selectInfo: EventResizeDoneArg) {
    this.isPlanner = true;

  }

  handleEventClick(clickInfo: EventClickArg) {
    this.ClearEventDetails();
    this.EventID = clickInfo.event._def.extendedProps.EventID;

    this.service.PlannerEventsCRUD(
      {
        ActionFlag: 'Edit',
        UserID: this.UserCode,
        EventID: String(this.EventID)
      }).subscribe((ret: any = []) => {
        this.ActionFlag = 'Update';
        this.lstParticipants = ret.EventParticipants;

        this.EventName = ret.EventDetails[0].EventName;
        this.StartDate = ret.EventDetails[0].StartDate;
        this.EndDate = ret.EventDetails[0].EndDate;
        this.StartTime = ret.EventDetails[0].StartTime;
        this.EndTime = ret.EventDetails[0].EndTime;
        this.Venue = ret.EventDetails[0].Venue;
        this.Comments = ret.EventDetails[0].Comments;
        this.EventTypeID = ret.EventDetails[0].EventTypeID;
        this.EventStatusID = ret.EventDetails[0].EventStatusID;
        this.PlayGroupID = ret.EventDetails[0].PlayGroupID;
      });

    this.isPlanner = true;
  }

  EventTypesChange() {
    var temp = this.ListEvents.filter(s => String(s.EventTypeID) === String(this.EventTypesID));
    this.monthlyevents = temp;
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  public ismonth: boolean = true;
  clickEvent(event) {
    //alert(event.path[0].classList.value);
    if (((event.path[1].classList.value == 'fc-next-button fc-button fc-button-primary'
      || event.path[0].classList.value == 'fc-next-button fc-button fc-button-primary'
      || event.path[1].classList.value == 'fc-prev-button fc-button fc-button-primary'
      || event.path[0].classList.value == 'fc-prev-button fc-button fc-button-primary'
    ) && this.ismonth == true) || (event.path[0].classList.value == 'fc-dayGridMonth-button fc-button fc-button-primary fc-button-active')) {
      this.ismonth = true;
      var x = document.getElementsByClassName('fc-toolbar-title')[0].innerHTML;
      this.Calendarmonth = x;
      var y = x.split(' ');
      var temp = this.Monthlist.findIndex(s => String(s) === String(y[0]));
      var start = new Date(Number(y[1]), temp, 1);
      var end = new Date(Number(y[1]), temp + 1, 0);
      // alert(end);
      this.service.PlannerCalendarLoad({ ActionFlag: 'RangeView', UserID: this.UserCode, StartDate: start, EndDate: end }).subscribe((ret: any = []) => {
        // this.ListEvents = ret.EventsList;
        // this.calendarOptions.events = this.ListEvents;
        // var temp = this.ListEvents;
        // if (this.EventTypesID != "")
        //   temp = this.ListEvents.filter(s => String(s.EventTypeID) === String(this.EventTypesID));
        // this.monthlyevents = temp;
        this.monthlyevents = ret.EventsList;
      });

    }
    // if (event.path[1].classList.value == 'fc-prev-button fc-button fc-button-primary') {
    //   alert(document.getElementsByClassName('fc-toolbar-title')[0].innerHTML);
    // }
    // else if (event.path[0].classList.value == 'fc-timeGridWeek-button fc-button fc-button-primary fc-button-active') {
    //   alert('week button');
    // }
    // else if (event.path[0].classList.value == 'fc-timeGridDay-button fc-button fc-button-primary fc-button-active') {
    //   // alert('Day button');
    // }
    // else if (event.path[0].classList.value == 'fc-listWeek-button fc-button fc-button-primary fc-button-active') {
    //   // alert('list button');
    // }
    else if (event.path[0].classList.value == 'fc-dayGridMonth-button fc-button fc-button-primary fc-button-active') {
      //alert('month button');
      this.ismonth = true;
    }
    else {
      this.ismonth = false;
    }
  }
}

export interface CalenderValues {
  title: string, start: string, end: string
}
