<div class="page-container my-videos-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="upload-videos-container box-shadow br-5">
                            <!-- <div class="title">Video Upload <span class="remove-list"></span></div>
                            <div class="upload-container">
                                <div class="video-upload mb10">
                                    <div class="dropzone">
                                        <div class="text-wrapper">
                                            <div class="centered">
                                                <input type="file" name="file" id="file" multiple>
                                                <label for="file"><span class="textLink">Select your file</span> or Drop it here!</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="title">Player Details</div>
                            <div class="program-player" *ngIf="MemberTypeID=='1'">
                                <div class="pgm-picture"><img src="{{utility.coachDetails.CoachImage}}"></div>
                                <figcaption>
                                    <div class="name">{{utility.coachDetails.CoachName}}</div>
                                    <div class="proficiency">
                                        <span> {{utility.coachDetails.DaysLeft}} out of
                                            {{utility.coachDetails.TotayDays}} Days Left</span>
                                        <!-- <span>Right Hand Bat</span>
                                        <span>Right Arm Fast Medium</span>
                                        <span>15 Years and 15 days</span> -->
                                    </div>
                                </figcaption>
                            </div>
                            <div class="program-player" *ngIf="MemberTypeID=='2'">
                                <div class="pgm-picture"><img src="{{utility.coachDetails.PlayerImage}}"></div>
                                <figcaption>
                                    <div class="name">{{utility.coachDetails.PlayerName}}</div>
                                    <div class="proficiency">
                                        <span>{{utility.coachDetails.PlayerRole}}</span>
                                        <!-- <span>Right Hand Bat</span>
                                        <span>Right Arm Fast Medium</span>
                                        <span>15 Years and 15 days</span> -->
                                    </div>
                                </figcaption>
                            </div>
                            <div class="upload-container upload-subs mb10">
                                <ul class="pgm-card">
                                    <li [ngClass]="{'select': selectedItem=='Session'}"
                                        (click)="selectedItem='Session'">
                                        <div class="pgm-count">08</div>
                                        <div class="pgm-name txt-right">
                                            <img src="assets/images/session.png">
                                            <p>Seesion Review</p>
                                        </div>
                                    </li>
                                    <li [ngClass]="{'select': selectedItem=='Drills'}"
                                        (click)="selectedItem='Drills';LoadDrills()">
                                        <div class="pgm-count">04</div>
                                        <div class="pgm-name txt-right">
                                            <img src="assets/images/drills.png">
                                            <p>Assigned Drills</p>
                                        </div>
                                    </li>
                                    <li [ngClass]="{'select': selectedItem=='Playlist'}"
                                        (click)="selectedItem='Playlist'">
                                        <div class="pgm-count">04</div>
                                        <div class="pgm-name txt-right">
                                            <img src="assets/images/assigntoplaylist.png">
                                            <p>Assigned Playlist</p>
                                        </div>
                                    </li>
                                    <li [ngClass]="{'select': selectedItem=='mSchedule'}"
                                        (click)="selectedItem='mSchedule'">
                                        <div class="pgm-count">02</div>
                                        <div class="pgm-name txt-right">
                                            <a href="javascript:void(0)" (click)="isSchedule();">
                                                <img src="assets/images/assigntoplaylist.png">
                                                <p>Meeting schedule</p>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="video-container" *ngIf="selectedItem=='Session'">
                            <div class="player-search">
                                <div class="row">
                                    <!-- <div class="col-md-6">
                                         <a href="javascript:void(0);" class="sorting">Sort by :
                                            <strong>Latest</strong><img src="assets/images/sorting.png"></a> 
                                        <a href="javascript:void(0);" routerLink="/play-groups" class="back"><img src="assets/images/back02.png">Back to Groups</a> 
                                    </div> -->
                                    <div class="col-md-12 txt-right">
                                        <a href="javascript:void(0);" *ngIf="currengPage=='coachpad-player'"
                                            (click)="AddNewSession()" class="theme-button"
                                            style="vertical-align: middle;"><i class="fa fa-plus"
                                                style="padding-right: 5px;" aria-hidden="true"></i>Add Session</a>
                                        <a href="javascript:void(0);" style="margin-left: 4px;"
                                            (click)="buttonClick.emit($event)" class="theme-button">Back</a>
                                    </div>
                                    <!-- <div class="col-md-6 txt-right" >
                                        <a href="javascript:void(0);" class="theme-button">Back</a>

                                    </div> -->

                                </div>
                            </div>

                            <div class="" *ngIf="newsession">
                                <div class="upload-videos-container box-shadow br-5 upload-common" *ngIf="newsession">

                                    <div class="upload-container upload-type mb10" *ngIf="videoformfields">
                                        <div class="title">Fill Video Form fields</div>
                                        <div class="fixed-container min">
                                            <div class="upload-form">
                                                <form [formGroup]="SessionForm" #ses="ngForm"
                                                    (ngSubmit)="showVideoupload()">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <mat-form-field class="md-block" appearance="outline"
                                                                floatLabel="always">
                                                                <mat-icon matSuffix class="mat-icon"><svg
                                                                        viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                        height="100%" width="100%"
                                                                        preserveAspectRatio="xMidYMid meet"
                                                                        focusable="false">
                                                                        <path opacity=".3"
                                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                        </path>
                                                                    </svg></mat-icon>
                                                                <mat-label>Enter Season Name</mat-label>
                                                                <input matInput required name="sessionName"
                                                                    formControlName="n_sessionName"
                                                                    [(ngModel)]="sessionName">
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_sessionName.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_sessionName.errors.required">
                                                                    Session Name is required.</div>
                                                            </div>
                                                            <!-- <div *ngIf="a.submitted && AthleteBasicForm.controls.n_AssociationName.invalid"
                                                            class="alert alert-danger">
                                                        <div *ngIf="AthleteBasicForm.controls.n_AssociationName.errors.required">
                                                            Association is required.</div>
                                                    </div> -->
                                                        </div>
                                                        <div class="col-md-6">
                                                            <mat-form-field appearance="fill"
                                                                class="custom-dtpkr-txt md-block" appearance="outline"
                                                                floatLabel="always">
                                                                <mat-label>Select Date</mat-label>
                                                                <input matInput [matDatepicker]="picker" name=" "
                                                                    formControlName="n_sessionDate"
                                                                    [(ngModel)]="sessionDate" readonly>
                                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_sessionDate.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_sessionDate.errors.required">
                                                                    Session Date is required.</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <mat-form-field class="md-block mat-dd-field "
                                                                appearance="outline" floatLabel="always">
                                                                <mat-icon matSuffix class="mat-icon"><svg
                                                                        viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                        height="100%" width="100%"
                                                                        preserveAspectRatio="xMidYMid meet"
                                                                        focusable="false">
                                                                        <path opacity=".3"
                                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                        </path>
                                                                    </svg></mat-icon>
                                                                <mat-label>Select Skill</mat-label>
                                                                <mat-select placeholder="Skill" name="skill"
                                                                    formControlName="n_skill" [(ngModel)]="skill"
                                                                    style="text-transform:capitalize;">
                                                                    <mat-option [value]="ses.SessionTypeID"
                                                                        *ngFor="let ses of SessionTypes"
                                                                        style="text-transform:capitalize;">
                                                                        {{ses.SessionTypeName}}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_skill.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_skill.errors.required">
                                                                    Select the Skill.</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <mat-form-field class="md-block mat-dd-field "
                                                                appearance="outline" floatLabel="always">
                                                                <mat-icon matSuffix class="mat-icon"><svg
                                                                        viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                        height="100%" width="100%"
                                                                        preserveAspectRatio="xMidYMid meet"
                                                                        focusable="false">
                                                                        <path opacity=".3"
                                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                        </path>
                                                                    </svg></mat-icon>
                                                                <mat-label>Select View</mat-label>
                                                                <mat-select placeholder="View Type" name="viewType"
                                                                    formControlName="n_viewType" [(ngModel)]="viewType"
                                                                    style="text-transform:capitalize;">
                                                                    <mat-option [value]="view.VideoViewID"
                                                                        *ngFor="let view of VideoViewTypes"
                                                                        style="text-transform:capitalize;">
                                                                        {{view.VideoViewName}}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_viewType.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_viewType.errors.required">
                                                                    Select the View Type.</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 txt-center mt10">
                                                            <!-- <button mat-raised-button color="primary" class="mb5">Save</button> -->
                                                            <button mat-raised-button color="primary" class="mb5 ml10"
                                                                type="submit">Next</button>
                                                                <button mat-raised-button color="primary" class="mb5 ml10"
                                                                type="button" (click)="ClearSessionForm();">Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upload-videos-container box-shadow br-5" *ngIf="videoupload">
                                        <div class="title">Video Upload</div>
                                        <div class="upload-container">
                                            <div class="video-upload mb10">
                                                <div class="dropzone">
                                                    <div class="text-wrapper">
                                                        <div class="centered">
                                                            <input type="file" name="file" id="file" multiple
                                                                (change)="onVideoChange($event)">
                                                            <label for="file"><span class="textLink">Select your
                                                                    file</span>
                                                                <!--or Drop it here!-->
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 txt-center mt10">
                                                <!-- <button mat-raised-button color="primary" class="mb5">Save</button> -->
                                                <button mat-raised-button color="primary" class="mb5 ml10"
                                                    (click)="showVideoFormfields();">Back</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="upload-videos-container box-shadow br-5" [hidden]="!videouploadstatus">
                                        <div class="title">Upload Skill</div>
                                        <div class="upload-container upload-type mb10">
                                            <div class="left-video-container">
                                                <!-- <video id="example_video_1" class="video-js" controls preload="none" width="640"
                                                    height="264" poster="https://i.ytimg.com/vi/MFSjLx-LkrU/maxresdefault.jpg"
                                                    data-setup="{}">
                                                    <source src="GANGA-SRIDHAR-BATTING-FRONT-VIEW.mp4" type="video/mp4">
                                                </video> -->
                                                <video id="myVideo" class="video-js" preload="none" width="640"
                                                    height="100%" controls disablePictureInPicture
                                                    controlsList="nodownload" #video
                                                    (loadedmetadata)="onMetadata($event, video)">
                                                </video>
                                                <canvas id="canvas" width="1280" height="720"
                                                    style="display: none;"></canvas>
                                                <!-- <img id="imgId" src=""/> -->
                                                <!-- <canvas id="canvas"></canvas> <br /><br /> -->
                                                <div class="video-size txt-right">{{Videoduration}}
                                                    <span>{{selectedfilesize}}</span>
                                                </div>

                                                <div class="card-profile-progress">
                                                    <div class="prg-container">
                                                        <!-- <div class="prg-rating">{{uploadedsize}}/{{selectedfilesize}}</div> -->
                                                        <div class="prg-ratio session">
                                                            <mat-progress-bar mode="determinate" [value]="percent">
                                                            </mat-progress-bar>
                                                        </div>
                                                        <div class="prg-percentage"> {{ percent }}%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 txt-center">
                                                <button mat-raised-button color="primary" class="mb5 mr5"
                                                    [disabled]="!IsShowUpload" (click)="uploadbutton()">Upload</button>
                                                <button mat-raised-button color="primary" class="mb5 mr5"
                                                    (click)="cancel($event)">Cancel</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!newsession">
                                <div class="video-item br-5" *ngFor="let sessions of SessionsList;let ind=index;">
                                    <div class="vprofile-header">
                                        <a href="javascript:void(0);" class="vprofile-left">
                                            <div class="vprofile">
                                                <img src="{{sessions.ProfileImage}}"
                                                    onerror="this.src='assets/images/no_user.png';">
                                            </div>
                                            <div class="vprofile-info">
                                                <div class="vprofile-name" style="text-transform:capitalize;">
                                                    {{sessions.PlayerName}}</div>
                                                <div class="vprofile-type">{{sessions.PlayerRoleName}}</div>
                                                <div class="vprofile-time">{{sessions.SessionDate}}
                                                    <!-- <span>5:30PM</span> -->
                                                </div>
                                            </div>
                                        </a>
                                        <!-- <div class="vprofile-more txt-right">
                                          <div class="dropdown">
                                            <a href="javascript:void(0);" class="ico-menu dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Copy Link</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                                              </div>
                                          </div>
                                    </div> -->
                                    </div>
                                    <div class="vprofile-video">
                                        <video id="my-video" class="video-js" controls
                                            poster="{{sessions.ThumbnailURL}}" preload="none" width="640" height="264"
                                            data-setup="{}" disablePictureInPicture controlsList="nodownload">
                                            <source src="{{sessions.CloudURL}}" type="video/mp4">
                                        </video>
                                    </div>
                                    <div class="vprofile-session">
                                        <div class="vpsession-name">{{sessions.SessionName}} </div>
                                        <div class="session">
                                            <span>{{sessions.SessionTypeName}}</span>
                                            <span> {{sessions.VideoViewName}}</span>
                                        </div>
                                        <div class="feed-info txt-right">
                                            <div class="vprofile-feed"> <a href="javascript:void(0);" class="fdanalyse"
                                                    (click)="GetAnalysisDetails(sessions)" matRipple>Analyse</a>
                                            </div>
                                            <!-- <a href="javascript:void(0);">{{sessions.SessionComments}} Comments</a> -->
                                        </div>
                                    </div>
                                    <div class="vprofile-feed">
                                        <div class="row">
                                            <!-- <div class="col-md-4"> <a href="javascript:void(0);" class="fist-bump"
                                                matRipple>Fist Bump</a> </div>
                                        <div class="col-md-4"> <a href="javascript:void(0);" class="comments"
                                                (click)="showCommentbox('comm'+sessions.SessionID,sessions.SessionID)"
                                                matRipple>Comments</a>
                                        </div> -->
                                            <!-- <div class="col-md-4"> <a href="javascript:void(0);" class="fdanalyse"
                                                (click)="GetAnalysisDetails(sessions)" matRipple>Analyse</a>
                                        </div>   -->
                                        </div>
                                    </div>
                                    <div class="comments-feed" *ngIf="'comm'+sessions.SessionID===openedShareid"
                                        id="comm{{sessions.SessionID}}">
                                        <div *ngIf="MessageList.length===0">
                                            <p>Waiting for review</p>
                                        </div>
                                        <div *ngIf="MessageList.length>0">
                                            <div class="main-comments">
                                                <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                                <div class="comments-box">
                                                    <div class="comments-txt">
                                                        <div class="textarea" role="textbox"
                                                            id="message{{sessions.SessionID}}" contenteditable="true"
                                                            tabindex="1"></div>
                                                    </div>
                                                    <div class="ico-sent">
                                                        <a href="javascript:void(0);"
                                                            (click)="sendMessage(sessions.SessionID)">
                                                            <img src="assets/images/ico-sent.png"></a>
                                                    </div>
                                                </div>
                                                <div class="remove-comments" *ngIf="IsShowComments">
                                                    <!-- <span class="remove-list" (click)="hideCommentbox()"></span> -->
                                                </div>
                                            </div>

                                            <div class="post-comments" *ngIf="IsShowComments">
                                                <div *ngFor="let msg of MessageList">
                                                    <div
                                                        [ngClass]="(msg.SenderID==UserCode)?'comment-sender1':'comment-sender2'">
                                                        <div class="comments-pic">
                                                            <img draggable="false" src="{{msg.ProfileImage}}"
                                                                onerror="this.src='assets/images/no_user.png';" />
                                                            <!-- <img src="assets/images/no_user.png">  -->
                                                        </div>
                                                        <div class="comments-profile">
                                                            <div class="c-name" style="text-transform:capitalize;">
                                                                {{msg.SenderName}}</div>
                                                            <div class="c-proficiency">{{msg.SenderType}}</div>
                                                        </div>
                                                        <div class="comments-msg">
                                                            {{msg.MsgContent}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="video-container" *ngIf="selectedItem=='Drills'">
                            <div class="player-search">
                                <div class="row">
                                    <div class="col-md-6">
                                        <a href="javascript:void(0);" (click)="AssignDrills()"
                                            *ngIf="currengPage=='coaching'" class="theme-button">Assign a Drill</a>
                                        <!-- <a href="javascript:void(0);" routerLink="/play-groups" class="back"><img src="assets/images/back02.png">Back to Groups</a> -->
                                    </div>
                                    <div class="col-md-6 txt-right" (click)="buttonClick.emit($event)">
                                        <a href="javascript:void(0);" class="theme-button">Back</a>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-with-tab br-5 box-shadow">
                                <div class="main-tab" rel="drills-db">
                                    <a href="javascript:void(0);" rel="batting" class="select">Drills</a>
                                </div>
                                <div class="drills-db">
                                    <div class="main-tab-container batting" style="display: block;">
                                        <div class="row">
                                            <div class="col-md-6" *ngFor="let drills of DrillsDetails">
                                                <div class="drill-list mb20 br-5">
                                                    <div class="drill-thumbnail">
                                                        <a href="javascript:void(0);" (click)="ShowMyVideo(drills)"
                                                            data-toggle="modal" data-target="#videopreview">
                                                            <img src="{{drills.ThumbnailURL}}" class="img-fluid">
                                                        </a>
                                                        <div class="ico-video"></div>
                                                        <div class="drill-type" style="text-transform:capitalize;">
                                                            {{drills.DrillTypeName}}</div>
                                                        <div class="video-time" style="text-transform:capitalize;">
                                                            {{drills.VideoLength}}</div>
                                                    </div>
                                                    <figcaption>
                                                        <div class="drill-user-date">
                                                            <div class="drill-user">
                                                                <a href="javascript:void(0);" class="video-user"
                                                                    style="text-transform:capitalize;"> <i>Created By:
                                                                    </i>
                                                                    {{drills.CreatedBy}}</a>
                                                            </div>
                                                        </div>
                                                        <div class="time">
                                                            <p>{{drills.CreatedDate}} || {{drills.CreatedTime}}</p>
                                                        </div>
                                                        <div class="drill-name-share">
                                                            <div class="drill-name" style="text-transform:capitalize;">
                                                                {{drills.DrillName}}</div>
                                                            <div class="lib-status"
                                                                [ngStyle]="{ 'right': currengPage === 'coaching' ?  '0px': '34px' }">
                                                                {{drills.Status}}</div>
                                                            <a class="ico-menu" data-toggle="modal"
                                                                *ngIf="currengPage!='coaching'"
                                                                data-target="#drillshare"
                                                                (click)="drillStatus(drills)"></a>
                                                        </div>
                                                    </figcaption>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="video-container" *ngIf="selectedItem=='mSchedule'">
                            <div class="player-search">
                                <div class="row">
                                    <div class="col-md-6">
                                        <!-- <a href="javascript:void(0);" class="sorting">Sort by :
                                            <strong>Latest</strong><img src="assets/images/sorting.png"></a> -->
                                        <!-- <a href="javascript:void(0);" routerLink="/play-groups" class="back"><img src="assets/images/back02.png">Back to Groups</a> -->
                                    </div>
                                    <div class="col-md-6 txt-right" (click)="buttonClick.emit($event)">
                                        <a href="javascript:void(0);" class="theme-button">Back</a>

                                    </div>
                                </div>
                            </div>
                            <div class="video-item" style="padding:5px; min-height:500px;">
                                <full-calendar *ngIf='calendarVisible' [options]='calendarOptions'></full-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="support">
                            <a href="javascript:void(0);">Support</a>
                            <span>
                                <i class="fa fa-question"></i>
                            </span>
                        </div>
                        <div class="video-filters box-shadow br-5">
                            <div class="title">Chat</div>
                            <div class="openchat">
                                <div class="chatcontainer">
                                    <div #chatroom class="chatpanel">
                                        <!-- <div class="chat01">
                                            <p>Heith Ingram</p>
                                            <span>Hello Suraj Karavadra</span>
                                        </div>
                                        <div class="chat02">
                                            <p>Suraj Karavadra</p>
                                            <span>Hi, Welcome</span> 

                                        </div> -->
                                        <div *ngFor="let chat of chats| orderBy : 'date'">
                                            <div
                                                [ngClass]="{'chat01': chat.from === 'Me', 'chat02': chat.from !== 'Me'}">
                                                <p><span style="font-size: 9px;">{{chat.date| date :'h:mm:ss a'}}</span>
                                                </p>
                                                <!-- <p *ngIf="chat.from != 'Me'">{{chat.Name}} {{chat.date| date :'dd-MMM-yy h:mm:ss a '}}</p> -->
                                                <span>{{chat.message}}</span>
                                            </div>

                                            <!-- <div class="chat-status" text-center *ngIf="chat.type==='join'||chat.type==='exit';else message">
                                              <span class="chat-date">{{chat.date| date :'dd-MMM-yy h:mm:ss a '}}</span>
                                              <span class="chat-content-center">{{chat.message}}</span>
                                            </div> -->
                                            <!-- <ng-template #message>
                                              <div class="chat-message">
                                                <div class="right-bubble" [ngClass]="{'right-bubble': chat.from === 'Me', 'left-bubble': chat.from !== 'Me'}">
                                                  <span class="msg-name" *ngIf="chat.from == 'Me'">Me</span>
                                                  <span class="msg-name" *ngIf="chat.from != 'Me'">{{chat.Name}}</span>
                                                  <span class="msg-date"> at {{chat.date| date :'dd-MMM-yy h:mm:ss a '}}</span>
                                                  <p text-wrap>{{chat.message}}</p>
                                                </div>
                                              </div>
                                            </ng-template> -->
                                        </div>
                                    </div>
                                    <div class="main-comments">
                                        <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                        <div class="comments-box">
                                            <div class="comments-txt">
                                                <div class="textarea" role="textbox" contenteditable="true" tabindex="1"
                                                    #fmsg></div>
                                            </div>
                                            <div class="ico-sent">
                                                <a href="javascript:void(0);" (click)="sendFireMessage(fmsg)"><img
                                                        src="assets/images/ico-sent.png"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="title">Filters</div>
                            <div class="video-filters-container">
                                <div class="search-container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <mat-form-field class="md-block search-txt" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon">
                                                    <a href="javascript:void(0);">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 373.12 373.12" width="24" height="24">
                                                            <path
                                                                d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                        </svg>
                                                    </a>
                                                </mat-icon>
                                                <input matInput placeholder="Search Table">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="title adv-filter">
                                Advance Filters
                                <a href="javascript:void(0);" (click)="advFilters=!advFilters"></a>
                            </div>
                            <div class="adv-filter-container" *ngIf="advFilters">
                                <div class="filter-type mb15">
                                    <mat-radio-group aria-label="Select an option" (change)="changeMonthDate()">
                                        <mat-radio-button value="1" [checked]="true">Month Range</mat-radio-button>
                                        <mat-radio-button value="2">Date Range</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="range-filter">
                                    <div class="row">
                                        <div class="col" *ngIf="showMonthRange">
                                            <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block"
                                                floatLabel="always">
                                                <mat-label>Month Range</mat-label>
                                                <mat-date-range-input [rangePicker]="range">
                                                    <input matStartDate placeholder="Start date">
                                                    <input matEndDate placeholder="End date">
                                                </mat-date-range-input>
                                                <mat-datepicker-toggle matSuffix [for]="range"></mat-datepicker-toggle>
                                                <mat-date-range-picker #range></mat-date-range-picker>
                                            </mat-form-field>
                                        </div>
                                        <div class="col" *ngIf="showDateRange">
                                            <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block"
                                                floatLabel="always">
                                                <mat-label>Date Range</mat-label>
                                                <mat-date-range-input [rangePicker]="range">
                                                    <input matStartDate placeholder="Start date">
                                                    <input matEndDate placeholder="End date">
                                                </mat-date-range-input>
                                                <mat-datepicker-toggle matSuffix [for]="range"></mat-datepicker-toggle>
                                                <mat-date-range-picker #range></mat-date-range-picker>
                                            </mat-form-field>
                                        </div>
                                        <!-- <div class="col">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Select Season</mat-label>
                                                <mat-select placeholder="Season">
                                                    <mat-option [value]="01">2020-2021</mat-option>
                                                    <mat-option [value]="02">2019-2020</mat-option>
                                                    <mat-option [value]="03">2018-2019</mat-option>
                                                    <mat-option [value]="04">2017-2018</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                        <div class="col">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Select Season</mat-label>
                                                <input type="text" placeholder="Season" matInput
                                                    [formControl]="myControl" [matAutocomplete]="auto">
                                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                    <mat-option *ngFor="let option of filteredOptions | async"
                                                        [value]="option">
                                                        {{option.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="select-skill mb20">
                                        <div class="skill-label">Select Skill</div>
                                        <div class="multi-select">
                                            <span><a href="javascript:void(0);"
                                                    [ngClass]="classFlag === true ? 'active' : '' "
                                                    (click)="menuToggle($event)">Batting</a></span>
                                            <span><a href="javascript:void(0);"
                                                    [ngClass]="classFlag === true ? 'active' : '' "
                                                    (click)="menuToggle($event)">Bowling</a></span>
                                            <span><a href="javascript:void(0);"
                                                    [ngClass]="classFlag === true ? 'active' : '' "
                                                    (click)="menuToggle($event)">Fielding</a></span>
                                            <span><a href="javascript:void(0);"
                                                    [ngClass]="classFlag === true ? 'active' : '' "
                                                    (click)="menuToggle($event)">Fitness</a></span>
                                            <span><a href="javascript:void(0);"
                                                    [ngClass]="classFlag === true ? 'active' : '' "
                                                    (click)="menuToggle($event)">Others</a></span>
                                            <span><a href="javascript:void(0);"
                                                    [ngClass]="classFlag === true ? 'active' : '' "
                                                    (click)="menuToggle($event)">Wicket Keeper</a></span>
                                        </div>
                                    </div>
                                    <div class="select-view ">
                                        <div class="view-label">Select View</div>
                                        <div class="single-select">
                                            <ul>
                                                <li><a href="javascript:void(0);"
                                                        [ngClass]="classFlag === true ? 'active' : '' "
                                                        (click)="menuToggle($event)">Front</a></li>
                                                <li><a href="javascript:void(0);"
                                                        [ngClass]="classFlag === true ? 'active' : '' "
                                                        (click)="menuToggle($event)">Side</a></li>
                                                <li><a href="javascript:void(0);"
                                                        [ngClass]="classFlag === true ? 'active' : '' "
                                                        (click)="menuToggle($event)">Back</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="txt-center">
                                    <button mat-raised-button color="primary" class="mb5 mt15">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Share Modal -->
<div class="modal fade" id="videopreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle" style="text-transform:capitalize;">
                    {{lstDrillEdit.DrillName}}
                    <span class="skill-type" style="text-transform:capitalize;">{{lstDrillEdit.DrillType}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="drills-popup-video">
                    <div class="vprofile-video">
                        <video id="my-video" class="video-js" controls poster="{{lstDrillEdit.ThumbnailURL}}"
                            preload="none" width="100%" src="{{lstDrillEdit.VideoURL}}" data-setup="{}"
                            disablePictureInPicture controlsList="nodownload" type="video/mp4">
                        </video>
                        <!-- <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                data-setup="{}"  disablePictureInPicture controlsList="nodownload" >
                            <source src="{{VideoURL}}" type="video/mp4" /> </video> -->
                        <!-- <div class="video-time">{{VideoLength}}</div> -->
                    </div>
                    <div class="drill-user-date">
                        <div class="drill-user">
                            <a href="javascript:void(0);" class="video-user"
                                style="text-transform:capitalize;">{{lstDrillEdit.CreatedBy}}</a>
                        </div>
                        <div class="time">
                            <p>{{lstDrillEdit.CreatedDate}} || {{lstDrillEdit.CreatedTime}}</p>
                        </div>
                    </div>
                </div>
                <div class="drills-description">
                    <div class="title">Instruction</div>
                    <ul class="instruction mb15">
                        <li style="text-transform:capitalize;" *ngFor="let stp of lstDrillEditStep;let ind=index;">Step
                            {{ind + 1}} : {{stp.StepText}}
                        </li>
                    </ul>
                    <div class="title">Required Items</div>
                    <ul class="required">
                        <li style="text-transform:capitalize;" *ngFor="let val of MaterialsBind">{{val}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="drillshare" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:350px;margin: 0 auto;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{lstDrillEdit.DrillName}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="min-height:150px;">
                <div class="row">
                    <div class="col-md-12 txt-center">
                        <mat-checkbox class="example-margin mt20" [checked]="allComplete">
                            {{DrillProgress}}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="txt-center">
                    <a href="javascript:void(0);" class="theme-button mt25" (click)="DrillsStatusUpdate()">Save</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overlay" *ngIf="scheduledate">
    <div class="playerview popup" style="width: 30%;">
        <div class="playerview-header">Schedule <span class="clr-popup" (click)="hidePopup();">x</span></div>
        <div class="playerview-body" style="background-color:#fff;">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="title" style="padding-left:0;">Schedule Date</div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline"
                                floatLabel="always">
                                <mat-label>Select Date</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="sDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <!-- <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                <mat-label>Start Time</mat-label>
                                <input matInput required>
                            </mat-form-field> -->
                            <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                <mat-icon matSuffix class="mat-icon"><svg x="0px" y="0px" width="24px" height="24px"
                                        viewBox="244 244 24 24" enable-background="new 244 244 24 24"
                                        xml:space="preserve">
                                        <g>
                                            <path fill="#97A6BA"
                                                d="M256,267.292c-6.226,0-11.292-5.065-11.292-11.292s5.065-11.292,11.292-11.292s11.292,5.065,11.292,11.292 S262.226,267.292,256,267.292z" />
                                            <path fill="#E0E4EA"
                                                d="M256,246.12c-5.448,0-9.88,4.432-9.88,9.88s4.432,9.88,9.88,9.88s9.88-4.432,9.88-9.88 S261.448,246.12,256,246.12z" />
                                            <path fill="#97A6BA"
                                                d="M260.94,262.116c-0.181,0-0.361-0.069-0.499-0.207l-4.94-4.94c-0.133-0.133-0.207-0.312-0.207-0.499v-6.587 c0-0.39,0.316-0.706,0.706-0.706s0.706,0.316,0.706,0.706v6.294l4.733,4.733c0.276,0.276,0.276,0.723,0,0.998 C261.301,262.048,261.121,262.116,260.94,262.116z" />
                                        </g>
                                    </svg>
                                </mat-icon>
                                <input matInput [ngxTimepicker]="pickers" [format]="24" [(ngModel)]="sTime">
                                <ngx-material-timepicker #pickers></ngx-material-timepicker>
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-md-12">
                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                <mat-label>End Time</mat-label>
                                <input matInput required>
                            </mat-form-field>
                        </div> -->
                        <div class="col-md-12 txt-center">
                            <button mat-raised-button color="primary" (click)="SaveSchedule()">Submit</button>
                            <button mat-raised-button color="primary" (click)="hidePopup()"
                                style="margin-left:2px">Cancel</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-7">
                     <full-calendar *ngIf='calendarVisible'[options]='calendarOptions'></full-calendar> 
                </div> -->
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</div>

<div class="overlay" *ngIf="StartMeeting">
    <div class="playerview popup" style="width: 30%;">
        <div class="playerview-header">Schedule <span class="clr-popup" (click)="StartMeeting=false">x</span></div>
        <div class="playerview-body" style="background-color:#fff;">
            <div class="row">
                <div class="col-md-2">
                    <button (click)="launchMeeting()">Launch Meeting</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="LaunchMeeting">
    <div id="video-wrapper">
        <div class="videocall-action">
            <button mat-raised-button color="primary" class="m-2" [disabled]="isCallStarted$ | async"
                (click)="showModal(false)">Start Call</button>
            <button mat-raised-button class="m-2" [disabled]="isCallStarted$ | async" (click)="showModal(true)">Join
                Call</button>
            <button mat-raised-button color="warn" class="m-2" [disabled]="!(isCallStarted$ | async)"
                (click)="endCall()">End Call</button>
        </div>
        <video #remoteVideo id="remote-video" poster="./assets/video-placeholder.jpg" autoplay
            playsinline></video>
        <video #localVideo style="width: 300px;height: 300px;" id="local-video" autoplay playsinline [muted]=true></video>
        <div class="controls">
            <a (click)="changeaudiostatus()"><img *ngIf="audiostatus=='Mute'" src="assets/images/ico-mute.png" /> <img *ngIf="audiostatus=='Unmute'" src="assets/images/ico-unmute.png" /></a>
            <a (click)="changevidiostatus()"><img *ngIf="vidiostatus=='Hide'" src="assets/images/ico-enable-camera.png" /> <img *ngIf="vidiostatus=='Open'" src="assets/images/ico-disable-camera.png" /></a>
            <!-- <a class="theme-button" href="javascript:void(0);" style="color: #fff;margin-left: 5px;" (click)="enableScreenSharing()">{{screenStatus}}</a> -->
        </div>
    </div>
</div>