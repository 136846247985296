import { Component, OnInit, Input } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
// import { INITIAL_EVENTS, createEventId } from './event-utils'; 
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
export interface User {
  name: string;
}



import * as moment from 'moment';
import 'fullcalendar';

@Component({
  selector: 'app-coachpad-coachwall',
  templateUrl: './coachpad-coachwall.component.html',
  styleUrls: ['./coachpad-coachwall.component.css']
})
export class CoachpadCoachwallComponent implements OnInit {
  myControl = new FormControl();
  options: User[] = [
    { name: '2020-2021' },
    { name: '2019-2020' },
    { name: '2018-2019' }
  ];
  filteredOptions!: Observable<User[]>;
  public commentsFeed: boolean = false;
  public showMonthRange: boolean = true;
  public showDateRange: boolean = false;
  public advFilters: boolean = false;

  scheduledate = false;
  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridDay,listWeek'
      // right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Please enter a schedule');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: "",
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  @Input() eventData: any;
  defaultConfigurations: any;
  @Input()
  set configurations(config: any) {
    if (config) {
      this.defaultConfigurations = config;
    }
  }

  constructor(private utility: UtilitiesService,public router:Router) {
    this.defaultConfigurations = {
      editable: true,
      eventLimit: true,
      titleFormat: 'MMM D YYYY',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      views: {
        agenda: {
          eventLimit: 2
        }
      },
      allDaySlot: false,
      slotDuration: moment.duration('00:15:00'),
      slotLabelInterval: moment.duration('01:00:00'),
      firstDay: 1,
      selectable: true,
      selectHelper: true,
      events: this.eventData,
    };

    this.eventData = [
      {
        title: 'event1',
        start: moment()
      },
      {
        title: 'event2',
        start: moment(),
        end: moment().add(2, 'days')
      },
    ];
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );

  }

  isSchedule() {
    this.scheduledate = true;
  }
  hidePopup() { this.scheduledate = false; }


  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  changeMonthDate() {
    this.showMonthRange = !this.showMonthRange;
    this.showDateRange = !this.showDateRange;
  }
  showCommentbox() {
    this.commentsFeed = true;
  }
  hideCommentbox() {
    this.commentsFeed = false;
  }
  classFlag: boolean = false;
  menuToggle(event: any) {
    event.target.classList.toggle('active')
  }
  Back() {
    this.router.navigate([this.utility.previousPage]);
  }
}

