import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutritionist',
  templateUrl: './nutritionist.component.html',
  styleUrls: ['./nutritionist.component.css']
})
export class NutritionistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
