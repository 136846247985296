import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';

@Component({
  selector: 'app-athlete-mapping',
  templateUrl: './athlete-mapping.component.html',
  styleUrls: ['./athlete-mapping.component.css']
})
export class AthleteMappingComponent implements OnInit {
  public playerList: boolean = false;
  public playerGroup: boolean = true;
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }

  @Input()
  public AthletGroup: string; public AthletGroups: string; public AgeCategories: string;
  today = new Date();

  public lstPlayGroup: any[]; public lstAssociationAthlete: any[]; public lstAgeCategory: any[];
  public lstGetSupportByGroup: any[]; public lstGetPlayerByGroup: any[];

  public UserCode: string = "";
  public ButtonType: string;

  public SaveAthleteID: string; public SaveRefID: string;
  public listAddAthleteGroup: any = []; public listAthleteRefUserID: any = [];
  public AthleteCounts: number;

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  public isConfirmAlert: boolean = false; public confirmAlert: string;

  public ColumnMode: ColumnMode;
  public rowLimit: number = 10;
  public lstAssociationsFiltered: any = [];
  public SearchFilter: string;
  public LIMITS = [{ value: 10 },
  { value: 20 },
  { value: 50 },
  { value: 100 },
  { value: 500 }];
  rows = [];
  public selected = [];

  public _search!:string;

  showPlayergroup() {
    this.AthletGroups="";
    this._search = "";
    this.InitAthleteMappingLoad();
    this.playerGroup = false;
    this.playerList = true;
  }
  hidePlayergroup() {
    this.playerList = false;
    this.playerGroup = true;
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.hidePlayergroup();
    this.InitAthleteMappingLoad();
  }

  InitAthleteMappingLoad() {
    this.isShowLoader = true;
    this.AthleteCounts = 0;
    this.ButtonType = "Save";
    var Athlete = { PlayGroupID: "", AthleteID: "", RefUserID: "", CoachID: "", ActionFlag: "Load", UserID: this.UserCode };
    this.service.AthleteMapping(Athlete).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstPlayGroup = ret.PlayGroupList;
        this.lstAssociationAthlete = ret.AssociationAthleteList;
        this.lstAgeCategory = ret.AgeCategoryList;
        if (this.lstPlayGroup.length > 0) {
          this.AthletGroup = (this.lstPlayGroup[0].PlayGroupID);
          this.changePlayGroup(this.lstPlayGroup[0].PlayGroupID);
          this.changePlayerGroup(this.lstPlayGroup[0].PlayGroupID);
          this.AthletGroups = this.lstPlayGroup[0].PlayGroupID;
        }
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert =  ret.Message;
        return;
      }
    })
  }


  changePlayGroup(groupId) {
    this.isShowLoader = true;
    this.ButtonType = "Save";
    var Athlete = {
      PlayGroupID: String(groupId), AthleteID: "", RefUserID: "", CoachID: "",
      ActionFlag: "GetPlayersByPlayGroup", UserID: this.UserCode
    };
    this.service.AthleteMapping(Athlete).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstGetSupportByGroup = ret.GetSupportByPlayGroupList;
        this.lstGetPlayerByGroup = ret.GetPlayersByPlayGroupList;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Athlete Grid Load Failed..!! \n';
        return;
      }
    })
  }

  changePlayerGroup(groupId) {
    this.isShowLoader = true;
    this.ButtonType = "Save";
    var Athlete = {
      PlayGroupID: String(groupId), AthleteID: "", RefUserID: "", CoachID: "",
      ActionFlag: "GetPlayersByPlayGroupSaved", UserID: this.UserCode
    };
    this.service.AthleteMapping(Athlete).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstGetSupportByGroup = ret.GetSupportByPlayGroupList;
        this.lstAssociationAthlete = ret.GetPlayersByPlayGroupList;
        this.listAddAthleteGroup=[];
        this.listAthleteRefUserID=[];
        var lstAssociationsFiltered = this.lstAssociationAthlete.filter((con) => con.Status === 1);
        for (var i = 0; i < lstAssociationsFiltered.length; i++) {
          //this.selected.push(lstAssociationsFiltered[i]);
          this.listAddAthleteGroup.push(lstAssociationsFiltered[i].AtheleteID);
          this.listAthleteRefUserID.push(lstAssociationsFiltered[i].RefUserID);
        } 
        this.AthleteCounts=lstAssociationsFiltered.length;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Athlete Grid Load Failed..!! \n';
        return;
      }
    })
  }


  onAddclick(value, event) {
    if (event.currentTarget.checked == true) {
      this.listAddAthleteGroup.push(value.AtheleteID);
      this.listAthleteRefUserID.push(value.RefUserID);
    }
    else {
      var x = this.listAddAthleteGroup.indexOf(value.AtheleteID);
      this.listAddAthleteGroup.splice(x, 1);
      this.listAthleteRefUserID.splice(x, 1);
    }
    for (var i = 0; i < this.listAddAthleteGroup.length; i++) {
      this.AthleteCounts = i + 1;
    }
  }

  // onRemoveclick(value) {
  //   this.AthleteCounts = this.AthleteCounts + 1;
  // }

  SavePlayGroup() {
    if (this.AthletGroups != "" && this.AthletGroups != undefined) {
      this.isShowLoader = true;
      this.ButtonType = "Save";

      this.SaveAthleteID = (this.listAddAthleteGroup.join(','));
      this.SaveRefID = (this.listAthleteRefUserID.join(','));

      var Athlete = {
        PlayGroupID: String(this.AthletGroups), AthleteID: String(this.SaveAthleteID),
        RefUserID: String(this.SaveRefID), CoachID: "", ActionFlag: "Register", UserID: this.UserCode
      };

      //this.isShowLoader = false;
      this.service.AthleteMapping(Athlete).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          this.successAlert = '\n Mapped successfully..!!';
          this.ngOnInit();
          this.isShowLoader = false;
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          this.errorAlert = ret.Message;
          // this.errorAlert = '\n Save Failed..!! \n';
        }
      })
    }
    else {
      this.isShowLoader = false;
      this.isWarningAlert = true;
      this.warningAlert = '\n Group should not be empty..!! \n';
    }
  }








}
