import { Component, OnInit } from '@angular/core';
import {UtilitiesService} from '../../utilities.service';

@Component({
  selector: 'app-nutritionist-list',
  templateUrl: './nutritionist-list.component.html',
  styleUrls: ['./nutritionist-list.component.css']
})
export class NutritionistListComponent implements OnInit {

  constructor(private utility:UtilitiesService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
  }

}
