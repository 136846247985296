import { Component, OnInit } from '@angular/core';
import {UtilitiesService} from '../../utilities.service';

@Component({
  selector: 'app-trainer-list',
  templateUrl: './trainer-list.component.html',
  styleUrls: ['./trainer-list.component.css']
})
export class TrainerListComponent implements OnInit {

  constructor(private utility:UtilitiesService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
  }

}
