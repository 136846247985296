import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';

@Component({
  selector: 'app-video-tagging-list',
  templateUrl: './video-tagging-list.component.html',
  styleUrls: ['./video-tagging-list.component.css']
})
export class VideoTaggingListComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { }

    public UserCode:string = String(JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).UserID);
    public isShowLoader:boolean;
    public isSuccessAlert:boolean = false; public successAlert: string;  
    public isErrorAlert:boolean = false; public errorAlert: string;
    public isWarningAlert:boolean = false; public warningAlert: string;
    
    public lstSessions:any = []; public lstAssociation:any = [];
    public _searchAssociation!:string; public _searchStatus!:string;

    public ColumnMode: ColumnMode;
    public rowLimits: number = 10;
    public lstAssociationsFiltered: any = [];
    public LIMITARRAY = [{ value: 10 },
      { value: 20 },
      { value: 50 },
      { value: 100 },
      { value: 500 }];
    public TabName: string;   

  ngOnInit(): void {
    this.utility.pageLoadScript();
    // this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.GetAllSessions();
  }

  GetAllSessions(){
    var SessionsLoad = { SessionID: "", ActionFlag: "AllSessions", RefUserID: this.UserCode };
    this.service.videoTaggingEngine(SessionsLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstSessions = ret.SessionsList;
        this.lstAssociation = ret.AssociationList;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  GotoEngine(obj){
    sessionStorage.setItem("sessionId", String(obj.SessionID));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));   
    this.router.navigate(['/video-tagging']);
  }



}
