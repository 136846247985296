import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

declare var $: any
@Component({
  selector: 'app-payer-group',
  templateUrl: './payer-group.component.html',
  styleUrls: ['./payer-group.component.css']
})
export class PayerGroupComponent implements OnInit {

  PlayerGroupForm: FormGroup;
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  @ViewChild(DatatableComponent) pgrouptable: DatatableComponent;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder) { }

  public staffList: boolean = true;
  public staffGroup: boolean = false;
  public playerList: boolean = true;
  public playerGroup: boolean = false;
  public PlayerGroupDescription: string;

  public ButtonType: string;
  public userCode: string = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  public isConfirmAlert: boolean = false; public confirmAlert: string;

  public SearchFilter: string;
  public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];
  public rowLimit: number = 10;
  public LIMITS = [
    { value: 10 },
    { value: 20 },
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  public PlayGroupID: string;
  //Player Group 
  public PlayerGroupName: string;
  public GroupShortName: string;
  public GenderName: string;
  public CategoryName: string;
  public BatchName: string;
  public PlayerGroupImage: string;
  public PlayerGroupImagePath: string;
  public PlayerGroupSearchFilter: string;

  public GenderList: any[];
  public AgeCategoryList: any[];
  public BatchList: any[];
  public PlayerGroupGrid: any[];

  public listEditPlayerGroup: any[];

  public filOfcRoleUpdate: any = []; public croppedImage: string;
  public imageChangedEvent: any = '';
  public imageChangedType: string = '';
  public displayImageCrop: string = 'none';

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.userCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.LoadPlayerGroup();
  }

  showStaffgroup() {
    this.staffGroup = true;
    this.staffList = false;
  }
  hideStaffgroup() {
    this.staffList = true;
    this.staffGroup = false;
  }
  showPlayergroup() {
    this.playerGroup = true;
    this.playerList = false;
  }
  hidePlayergroup() {
    this.playerList = true;
    this.playerGroup = false;
  }

  LoadPlayerGroup() {
    this.isShowLoader = true;
    this.PlayerGroupForm = this.formBuilder.group({
      n_PlayerGroupName: ['', Validators.required],
      n_GroupShortName: ['', Validators.required],
      n_GenderName: ['', Validators.required],
      n_CategoryName: ['', Validators.required],
      n_BatchName: ['', Validators.required],
      n_PlayerGroupDescription: []
    });
    this.ClearPlayerGroup();
    this.ButtonType = "Save";
    this.service.PlayGroupMasterCRUD({ UserID: this.userCode, ActionFlag: "Load" }).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.GenderList = ret.GenderList;
        this.AgeCategoryList = ret.AgeCategoryList;
        this.BatchList = ret.BatchList;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Player Group load failed..!! \n';
        return;
      }
    })

    this.service.PlayGroupMasterCRUD({ UserID: this.userCode, ActionFlag: "GridLoad" }).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.PlayerGroupGrid = ret.RowDetails;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Player Group load failed..!! \n';
        return;
      }
    })
  }

  onSubmitPlayerGroup() {
    if (this.PlayerGroupForm.invalid) {
      return;
    }
    else {
      this.SubmitPlayerGroup();
    }
  }

  SubmitPlayerGroup() {
    this.isShowLoader = true;
    var groupImage = "";
    let submitType: string;
    if (this.ButtonType == "Save") { submitType = "Register", this.PlayGroupID = "" }
    else { submitType = "Update", this.PlayGroupID = this.PlayGroupID }
    var playerGroup = {
      PlayGroupID: String(this.PlayGroupID), PlayGroupName: this.PlayerGroupName,
      PlayGroupShortName: this.GroupShortName, PlayGroupDescription: this.PlayerGroupDescription, GenderID: String(this.GenderName),
      CategoryID: String(this.CategoryName), BatchID: String(this.BatchName), PlayerGroupIcon: this.PlayerGroupImage,
      UserID: this.userCode, ActionFlag: String(submitType)
    };
    if (this.ImageDetails[0].data != "") {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          groupImage = ret.name;
          playerGroup.PlayerGroupIcon = groupImage;

          this.service.PlayGroupMasterCRUD(playerGroup).subscribe((ret: any = []) => {
            if (ret.Status == true) {
              this.isShowLoader = false;
              this.isSuccessAlert = true;
              if (this.ButtonType == "Save") this.successAlert = '\n"' + this.PlayerGroupName + '"\n Saved successfully..!!';
              else if (this.ButtonType == "Update") this.successAlert = '\n"' + this.PlayerGroupName + '"\n Updated successfully..!!';
              this.ngOnInit();
            }
            else {
              this.isShowLoader = false;
              this.isErrorAlert = true;
              this.errorAlert = this.PlayerGroupName + '-' + ret.Message;
              return;
            }
          });
        }
      })
    }
    else {
      this.service.PlayGroupMasterCRUD(playerGroup).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          this.isShowLoader = false;
          this.isSuccessAlert = true;
          if (this.ButtonType == "Save") this.successAlert = '\n"' + this.PlayerGroupName + '"\n Saved successfully..!!';
          else if (this.ButtonType == "Update") this.successAlert = '\n"' + this.PlayerGroupName + '"\n Updated successfully..!!';
          this.ngOnInit();
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          this.errorAlert = this.PlayerGroupName + '-' + ret.Message;
          // if (this.ButtonType == "Save") this.errorAlert = '\n"' + this.PlayerGroupName + '"\n Save failed..!!';
          // else if (this.ButtonType == "Update") this.errorAlert = '\n"' + this.PlayerGroupName + '"\n Update failed..!!';
          return;
        }
      });
    }
  }

  EditPlayerGroup(val) {
    this.isShowLoader = true;
    var playerGroup = { PlayGroupID: String(val.PlayGroupID), UserID: this.userCode, ActionFlag: "Edit" };
    this.service.PlayGroupMasterCRUD(playerGroup).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.listEditPlayerGroup = ret.RowDetails;
        this.PlayGroupID = this.listEditPlayerGroup[0].PlayGroupID;
        this.PlayerGroupName = this.listEditPlayerGroup[0].PlayGroupName;
        this.GroupShortName = this.listEditPlayerGroup[0].PlayGroupShortName;
        this.PlayerGroupDescription = this.listEditPlayerGroup[0].PlayGroupDescription;
        this.GenderName = this.listEditPlayerGroup[0].GenderID;
        this.CategoryName = this.listEditPlayerGroup[0].CategoryID;
        this.BatchName = this.listEditPlayerGroup[0].BatchID;
        this.PlayerGroupImage = this.listEditPlayerGroup[0].PlayerGroupIcon;
        this.ButtonType = "Update";
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = val.PlayerGroupName + '-' + ret.Message;
        return;
      }
    })
  }


  ActivatePlayerGroup(activate) {
    this.isConfirmAlert = false;
    this.isShowLoader = true;
    var playerGroup = { PlayGroupID: String(activate.PlayGroupID), UserID: this.userCode, ActionFlag: "Activate" };
    this.service.PlayGroupMasterCRUD(playerGroup).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        this.successAlert = '\n"' + activate.PlayGroupName + '"\n Activated successfully..!!';
        this.isShowLoader = false;
        this.ngOnInit();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = activate.PlayGroupName + '-' + ret.Message;
        return;
      }
    })
  }


  DeActivatePlayerGroup(deactivate) {
    this.isWarningAlert = false;
    this.isShowLoader = true;
    var playerGroup = { PlayGroupID: String(deactivate.PlayGroupID), UserID: this.userCode, ActionFlag: "DeActivate" };
    this.service.PlayGroupMasterCRUD(playerGroup).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        this.successAlert = '\n"' + deactivate.PlayGroupName + '"\n DeActivated successfully..!!';
        this.isShowLoader = false;
        this.ngOnInit();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = deactivate.PlayGroupName + '-' + ret.Message;
        return;
      }
    })
  }


  public rowValue: any[]; public rowValueActive: any[];
  showWarningDialogue(row) {
    if (String(row.RecordStatus) == "Active") {
      this.rowValue = row;
      this.isWarningAlert = true;
      this.warningAlert = "Are you sure want to DeActivate..?"; //+'\n' + row.CategoryName;
    }
    else if (String(row.RecordStatus) == "InActive") {
      this.rowValueActive = row;
      this.isConfirmAlert = true;
      this.confirmAlert = "Are you sure want to Activate..?"; //+'\n' + row.CategoryName;
    }
    else {
      this.isWarningAlert = false;
      return;
    }
  }

  ClearPlayerGroup() {
    this.PlayerGroupName = ""; this.GroupShortName = ""; this.GenderName = ""; this.CategoryName = "";
    this.BatchName = ""; this.PlayerGroupDescription = ""; this.PlayerGroupImage = ""; this.PlayerGroupImagePath = "";
  }

  changeRowLimits(val) {
    this.rowLimit = val;
  }

  OnGroupIconChange(event, type) {
    // const reader = new FileReader();    
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   this.PlayerGroupImagePath = event.target.value;
    //   reader.onload = () => {
    //     this.PlayerGroupImage = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.PlayerGroupImage, name: file.type });
    //   };
    // }

    const reader = new FileReader();
    this.imageChangedEvent = event;
    this.imageChangedType = type;
    $('#modalcrop').modal('show');

  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }
  
  Applycropper() {
    this.PlayerGroupImage = this.croppedImage;
    this.ImageDetails = [];
    this.ImageDetails.push({ data: this.PlayerGroupImage, name: 'image/png' });
    $('#modalcrop').modal('hide');
    this.PlayerGroupImagePath = "";
  }

}

export interface ImageDetails { data: string, name: string }
