import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { MastersServiceService } from '../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
//import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-dashboard-academy',
  templateUrl: './dashboard-academy.component.html',
  styleUrls: ['./dashboard-academy.component.css']
})

export class DashboardAcademyComponent implements OnInit {

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe, private chRef: ChangeDetectorRef) { this.today.setDate(this.today.getDate()); }

  @Input() today = new Date();
  public UserCode: string; public UserName: string;
 
  public isShowLoader: boolean = false;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;

  public lstAdminDetails: any = []; public lstAssociation: any = []; public UploadsList: any = [];
  public lstRegistraion: any = []; public lstPlayerRole: any = [];
  public lstGender: any = []; public lstAgeCutOff: any = [];

  public TotalAcademies: string; public TotalRegAthletes: string; public ActiveAthlete: string; public ActiveCoaches: string;

  public listGender: any = []; public GenderTotal: number;
  public listPlayerRole: any = []; public RoleTotal: number;

  public BarChartAthlete: any = []; public BarChartAthleteMonth: any[] = [];
  public athCountList: any = []; public regmonthList: any = [];
  public ageCategoryCountList: any = []; public ageCategoryList: any = [];


  public ageCategoryCountList1: any = []; public ageCategoryList1: any = [];
  public ageCategoryCountList2: any = []; public ageCategoryList2: any = [];
  public ageCategoryCountList3: any = []; public ageCategoryList3: any = [];
  public ageCategoryCountList4: any = []; public ageCategoryList4: any = [];
  public ageCategoryCountList5: any = []; public ageCategoryList5: any = [];
  public ageCategoryCountList6: any = []; public ageCategoryList6: any = [];

  public _associationID: string = "";

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    // this.sessionItems = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    this.GetDashBoard(this._associationID);
  }

  AcademicRefresh() {
    window.location.reload();
    // this.ngOnInit();
  }

  GetDashBoard(assoID) {
    // this.isShowLoader = true;
    this.GenderTotal = 0; this.RoleTotal = 0;
    this.listGender = []; this.listPlayerRole = [];
    //this.athCountList = []; this.regmonthList = [];
    var Dashboard = { ActionFlag: "Dashboard", UserID: String(this.UserCode), AssociationID: String(assoID) };
    this.service.AdminDashboard(Dashboard).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstAdminDetails = ret.AdminDetailsList;
        this.lstAssociation = ret.AssociationList;
        this.lstRegistraion = ret.RegistraionList;
        this.lstPlayerRole = ret.PlayerRoleList;
        this.lstGender = ret.GenderList;
        this.lstAgeCutOff = ret.AgeCutOffList;
        this.UploadsList = ret.UploadsList;

        this.TotalAcademies = this.lstAdminDetails[0].TotalAcademies;
        this.TotalRegAthletes = this.lstAdminDetails[0].TotalRegAthletes;
        this.ActiveAthlete = this.lstAdminDetails[0].ActiveAthlete;
        this.ActiveCoaches = this.lstAdminDetails[0].ActiveCoaches;

        // --- Gender --- //
        for (var i = 0; i < this.lstGender.length; i++) {
          this.GenderTotal = this.lstGender[i].GenderCount + this.GenderTotal;
        }
        for (var j = 0; j < this.lstGender.length; j++) {
          this.listGender.push({
            "GenderPercentage": ((this.lstGender[j].GenderCount / this.GenderTotal) * 100).toFixed(2) + '%',
            "GenderColors": '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6),
            "GenderToolTip": this.lstGender[j].GenderName + '(' + this.lstGender[j].GenderCount + ')'
          });
        }

        // --- Player Role --- //
        for (var i = 0; i < 4; i++) {
          this.RoleTotal = this.lstPlayerRole[i].TotalPlayers + this.RoleTotal;
        }
        for (var j = 0; j < 4; j++) {
          this.listPlayerRole.push({
            "RolePercentage": ((this.lstPlayerRole[j].TotalPlayers / this.RoleTotal) * 100).toFixed(2) + '%',
            "RoleColors": '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6),
            "RoleToolTip": this.lstPlayerRole[j].KeyType + '(' + this.lstPlayerRole[j].TotalPlayers + ')'
          });
        }

        // --- Reg Athlete by month - Bar Chart --- //
        this.athCountList = []; this.regmonthList = [];
        // if (this.athCountList.length ==0) {
        for (var i = 0; i < this.lstRegistraion.length; i++) {
          this.athCountList.push(this.lstRegistraion[i].RegCounts);
          this.regmonthList.push(this.lstRegistraion[i].Months);
        }
        this.chartData = [{
          label: 'Players',
          data: this.athCountList
        }];
        this.labels = this.regmonthList;

        this.athCountList = []; this.regmonthList = [];
        for (var i = 0; i < this.UploadsList.length; i++) {
          this.athCountList.push(this.UploadsList[i].UploadCounts);
          this.regmonthList.push(this.UploadsList[i].Months);
        }
        this.UploadchartData = [{
          label: 'Videos',
          data: this.athCountList
        }];
        this.Uploadlabels = this.regmonthList;



        // --- Athlete by Age Category - Bar Chart --- // 

        // public ageCategoryCountList1: any = []; public ageCategoryList1: any = [];
        // public ageCategoryCountList2: any = []; public ageCategoryList2: any = [];
        // public ageCategoryCountList3: any = []; public ageCategoryList3: any = [];
        // public ageCategoryCountList4: any = []; public ageCategoryList4: any = [];
        // public ageCategoryCountList5: any = []; public ageCategoryList5: any = [];
        // public ageCategoryCountList6: any = []; public ageCategoryList6: any = [];

        // for (var i = 0; i < this.lstAgeCutOff.length; i++) {
        //   this.ageCategoryCountList.push(this.lstAgeCutOff[i].Counts);
        //   this.ageCategoryList.push(this.lstAgeCutOff[i].AgeCategory);
        // }
        // this.barChartData = [{ data: this.athCountList, label: 'Players' }];
        // this.chRef.detectChanges();
        // this.ageCategoryCountList1.push(this.lstAgeCutOff[0].Counts);
        // this.ageCategoryList1.push(this.lstAgeCutOff[0].AgeCategory);

        // this.ageCategoryCountList2.push(this.lstAgeCutOff[1].Counts);
        // this.ageCategoryList2.push(this.lstAgeCutOff[1].AgeCategory);

        // this.ageCategoryCountList3.push(this.lstAgeCutOff[2].Counts);
        // this.ageCategoryList3.push(this.lstAgeCutOff[2].AgeCategory);

        // this.ageCategoryCountList4.push(this.lstAgeCutOff[3].Counts);
        // this.ageCategoryList4.push(this.lstAgeCutOff[3].AgeCategory);

        // this.ageCategoryCountList5.push(this.lstAgeCutOff[4].Counts);
        // this.ageCategoryList5.push(this.lstAgeCutOff[4].AgeCategory);

        // this.ageCategoryCountList6.push(this.lstAgeCutOff[5].Counts);
        // this.ageCategoryList6.push(this.lstAgeCutOff[5].AgeCategory);

        // this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Dashboard Details Load Failed..!! \n';
        return;
      }
    })
  }

  public barChartOptions: any = { responsive: true  };
  public barChartLabels: any = this.regmonthList;
  public barChartType: any = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  //public barChartData: any = [];
  public chartData = [
    {
      label: 'Players',
      data: [],
    }
  ];
  public labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  public UploadchartData = [
    {
      label: 'Videos',
      data: [],
    }
  ];
  public Uploadlabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  //public barChartData: ChartDataSets[];


  // public barChartOptions1: ChartOptions = { responsive: true, };
  // public barChartLabels1: Label[] = ['Age Categories'];
  // public barChartType1: ChartType = 'bar';
  // public barChartLegend1 = true;
  // public barChartPlugins1 = [];

  // public barChartData1: ChartDataSets[] = [
  //   { barThickness: 70, data: this.ageCategoryCountList1, label: this.ageCategoryList1, maxBarThickness: 50, minBarLength: 2, },
  //   { barThickness: 70, data: this.ageCategoryCountList2, label: this.ageCategoryList2, maxBarThickness: 50, minBarLength: 2, },
  //   { barThickness: 70, data: this.ageCategoryCountList3, label: this.ageCategoryList3, maxBarThickness: 50, minBarLength: 2, },
  //   { barThickness: 70, data: this.ageCategoryCountList4, label: this.ageCategoryList4, maxBarThickness: 50, minBarLength: 2, },
  //   { barThickness: 70, data: this.ageCategoryCountList5, label: this.ageCategoryList5, maxBarThickness: 50, minBarLength: 2, },
  //   { barThickness: 70, data: this.ageCategoryCountList6, label: this.ageCategoryList6, maxBarThickness: 50, minBarLength: 2, }
  // ];

  // public barChartColors1: Color[] = [
  //   { backgroundColor: '#845EC2' },
  //   { backgroundColor: '#2C73D2' },
  //   { backgroundColor: '#0081CF' },
  //   { backgroundColor: '#0089BA' },
  //   { backgroundColor: '#008E9B' },
  //   { backgroundColor: '#008F7A' },
  // ];

  // public barChartOptions: ChartOptions = { responsive: true, };
  // public barChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];
  // public barChartType: ChartType = 'bar';
  // public barChartLegend = true;
  // public barChartPlugins = [];

  // public barChartData: ChartDataSets[] = [
  //       { data: [65, 59, 80, 81, 56, 55, 40], label: 'Male' },
  //       { data: [28, 48, 40, 19, 86, 27, 90], label: 'Female' } ];



}
