<div class="page-container player-list-page">
    <div class="container">
        <div class="input-container">
            <div class="white">
                <div class="row">
                    <div class="col-md-12">
                        <div class="page-tab">
                            <a href="javascript:void(0);" [ngClass]="{'select':tabmaster=='package'}"
                                (click)="tabmaster='package'">Pakage Master</a>
                            <a href="javascript:void(0);" [ngClass]="{'select':tabmaster=='topup'}"
                                (click)="tabmaster='topup'">Topup Master</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper" *ngIf="tabmaster=='package'">
        <div class="container">
            <div class="output-container mb20">
                <div class="white">
                    <form [formGroup]="packageForm" #a="ngForm" (ngSubmit)="SavePackage()">
                        <div class="search-container finance-filter">
                            <div class="row">
                                <div class="col-md-2">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                        floatLabel="always">
                                        <!-- <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344"
                                                fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet"
                                                focusable="false">
                                                <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path>
                                                <path
                                                    d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                </path>
                                            </svg></mat-icon> -->
                                        <mat-label>Academy Type Name</mat-label>
                                        <mat-select placeholder="Academy package" [(ngModel)]="academyType"
                                            formControlName="_academyType" name="_academyType" required>
                                            <mat-option [value]="atype.AcademyTypeID" *ngFor="let atype of AcademyTypes"
                                                style="text-transform:capitalize;">{{atype.AcademyTypeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="a.submitted && packageForm.controls._academyType.invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="packageForm.controls._academyType.errors.required">
                                            Academy Type required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                        floatLabel="always">

                                        <mat-label>Player Package Name</mat-label>
                                        <mat-select placeholder="Player Package" [(ngModel)]="playerPackage" required
                                            formControlName="_playerPackage" name="_playerPackage">
                                            <mat-option [value]="pack.PackageID" *ngFor="let pack of PackageTypes"
                                                style="text-transform:capitalize;">{{pack.PackageName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="a.submitted && packageForm.controls._playerPackage.invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="packageForm.controls._playerPackage.errors.required">
                                            Player Package required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                        <mat-label>Duration</mat-label>
                                        <mat-select placeholder="Package Duraion" [(ngModel)]="packageDuration"
                                            formControlName="_packageDuration" name="_packageDuration" required>
                                            <mat-option [value]="dur.DurationID" *ngFor="let dur of PackageDurations"
                                                style="text-transform:capitalize;">{{dur.DurationName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="a.submitted && packageForm.controls._packageDuration.invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="packageForm.controls._packageDuration.errors.required">
                                            Duration required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                        <mat-label>No.of Videos per Month</mat-label>
                                        <input matInput placeholder="12" [(ngModel)]="MonthlyVideos"
                                            formControlName="_MonthlyVideos" name="_MonthlyVideos" required
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                    </mat-form-field>
                                    <div *ngIf="a.submitted && packageForm.controls._MonthlyVideos.invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="packageForm.controls._MonthlyVideos.errors.required">
                                            No.of Videos required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                        <mat-label>Package Amount <span>(Excluding GST)</span></mat-label>
                                        <input matInput placeholder="40000" [(ngModel)]="Amount"
                                            formControlName="_Amount" name="_Amount" required
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                    </mat-form-field>
                                    <div *ngIf="a.submitted && packageForm.controls._Amount.invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="packageForm.controls._Amount.errors.required">
                                            Package Amount required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                        <mat-label>GST%</mat-label>
                                        <input matInput placeholder="18" [(ngModel)]="GSTPercent"
                                            formControlName="_GSTPercent" name="_GSTPercent" required
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                    </mat-form-field>
                                    <div *ngIf="a.submitted && packageForm.controls._GSTPercent.invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="packageForm.controls._GSTPercent.errors.required">
                                            GST% required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="txt-center mb20">
                                <button type="submit" mat-raised-button color="primary" class="mr10"
                                    *ngIf="packageFlag=='Create'">Save</button>
                                <button type="submit" mat-raised-button color="primary" class="mr10"
                                    *ngIf="packageFlag=='Update'">Update</button>
                                <button mat-raised-button color="primary" (click)="clearPackage()">Clear</button>
                            </div>
                        </div>
                    </form>
                    <div>
                        <ngx-datatable class="material" [rows]="PackageMasterGrid" [columnMode]="'force'"
                            [headerHeight]="42" [footerHeight]="50" [rowHeight]="42" [limit]="rowLimit"
                            [scrollbarV]="false" [scrollbarH]="true">

                            <ngx-datatable-column name="S No" [width]="50">
                                <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                    <span>{{rowIndex+1}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Academy Type" prop="AcademyTypeName" [width]="300">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Package Name" prop="PackageName" [width]="200">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Package Duration" prop="DurationName" [width]="200">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Video" prop="NoOfVideos" [width]="100">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="GST%" prop="GST" [width]="100">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Tot Amount" prop="AmountWithGST" [width]="80">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Actions" [width]="50">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <span>
                                        <div class="action-btn">
                                            <div class="ico-action-svg edit" (click)="EditPackage(row)" title="Edit">
                                                <svg height="18" viewBox="0 -1 381.53417 381" width="18"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="m370.589844 230.964844c-5.523438 0-10 4.476562-10 10v88.792968c-.019532 16.558594-13.4375 29.980469-30 30h-280.589844c-16.5625-.019531-29.980469-13.441406-30-30v-260.589843c.019531-16.5625 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.523438-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.589843c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.789062c0-5.523438-4.476563-10.003906-10-10.003906zm0 0" />
                                                    <path
                                                        d="m156.367188 178.34375 146.011718-146.015625 47.089844 47.089844-146.011719 146.015625zm0 0" />
                                                    <path
                                                        d="m132.542969 249.257812 52.039062-14.414062-37.625-37.625zm0 0" />
                                                    <path
                                                        d="m362.488281 7.578125c-9.769531-9.746094-25.585937-9.746094-35.355469 0l-10.605468 10.605469 47.089844 47.089844 10.605468-10.605469c9.75-9.769531 9.75-25.585938 0-35.355469zm0 0" />
                                                </svg>
                                            </div>
                                            <!-- <div class="ico-action-svg delete" *ngIf="row.RecordStatus=='Active'"
                                                (click)="showWarningDialogue(row)" title="Delete"><svg height="18"
                                                    viewBox="-40 0 427 427.00131" width="18"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                    <path
                                                        d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                    <path
                                                        d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                                                    <path
                                                        d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                </svg></div>
                                            <div class="ico-action-svg" *ngIf="row.RecordStatus=='InActive'"
                                                [ngClass]="{'activate':row.RecordStatus=='Active','inactivate':row.RecordStatus=='InActive'}"
                                                (click)="showWarningDialogue(row)" title="Activate"><svg height="18"
                                                    viewBox="0 0 512 512" width="18"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="m245.332031 341.332031c-4.09375 0-8.191406-1.554687-11.304687-4.691406l-69.335938-69.332031c-6.25-6.253906-6.25-16.386719 0-22.636719 6.253906-6.25 16.386719-6.25 22.636719 0l58.027344 58.027344 106.027343-106.027344c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382813 0 22.636719l-117.332031 117.332031c-3.160156 3.136719-7.253906 4.691406-11.351563 4.691406zm0 0" />
                                                    <path
                                                        d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-394.664062c0-32.363281 26.304688-58.667969 58.667969-58.667969h394.664062c32.363281 0 58.667969 26.304688 58.667969 58.667969v394.664062c0 32.363281-26.304688 58.667969-58.667969 58.667969zm-394.664062-480c-14.699219 0-26.667969 11.96875-26.667969 26.667969v394.664062c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-394.664062c0-14.699219-11.96875-26.667969-26.667969-26.667969zm0 0" />
                                                </svg></div> -->

                                        </div>
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage"
                                    let-offset="offset" let-isVisible="isVisible">
                                    <div class="footer-page-size-position">
                                        show
                                    </div>
                                    <div class="footer-page-size-position">
                                        <mat-select style="text-align: center" class="mat-select-position"
                                            [(value)]="rowLimit" (selectionChange)="changeRowLimits($event.value)">
                                            <mat-option *ngFor="let limit of LIMITS" [value]="limit.value">
                                                {{limit.value}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="footer-page-size-position">
                                        {{rowLimit}} entries
                                    </div>

                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                        [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="table.onFooterPage($event)">
                                    </datatable-pager>

                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper" *ngIf="tabmaster=='topup'">
        <div class="container">
            <div class="output-container mb20">
                <div class="white">
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <form [formGroup]="topupForm" #b="ngForm" (ngSubmit)="SaveTopup()">
                                <div class="search-container finance-filter">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                                <mat-label>Number of Videos</mat-label>
                                                <input matInput placeholder="12" [(ngModel)]="TopupVideos" required
                                                    formControlName="_TopupVideos" name="_TopupVideos"
                                                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                            </mat-form-field>
                                            <div *ngIf="b.submitted && topupForm.controls._TopupVideos.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="topupForm.controls._TopupVideos.errors.required">
                                                    Number of videos required</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                                <mat-label>Amount <span>(Excluding GST)</span></mat-label>
                                                <input matInput placeholder="12" [(ngModel)]="TopupAmount"
                                                    required formControlName="_TopupAmount" name="_TopupAmount" (ngModelChange)="CalculateTotal()"
                                                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                            </mat-form-field>
                                            <div *ngIf="b.submitted && topupForm.controls._TopupAmount.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="topupForm.controls._TopupAmount.errors.required">
                                                    Amount required</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                                <mat-label>GST%</mat-label>
                                                <input matInput placeholder="18" [(ngModel)]="TopupGST" required
                                                    formControlName="_TopupGST" name="_TopupGST"
                                                    (ngModelChange)="CalculateTotal()"
                                                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                            </mat-form-field>
                                            <div *ngIf="b.submitted && topupForm.controls._TopupGST.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="topupForm.controls._TopupGST.errors.required">
                                                    GST% required</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">

                                                <mat-label>Total Amount</mat-label>
                                                <input matInput placeholder="1500"
                                                    [ngModel]="TopupTotalAmount|currency:'INR'"
                                                    formControlName="_TopupTotalAmount" name="_TopupTotalAmount"
                                                    disabled>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="txt-center mb20">
                                        <button mat-raised-button color="primary" class="mr10"
                                            *ngIf="TopupFlag=='Create'">Save</button>
                                        <button mat-raised-button color="primary" class="mr10"
                                            *ngIf="TopupFlag=='Update'">Update</button>
                                        <button mat-raised-button color="primary" (click)="clearTopup()">Clear</button>
                                    </div>
                                </div>
                            </form>
                            <div>
                                <ngx-datatable class="material" [rows]="TopupMasterGrid" [columnMode]="'force'"
                                    [headerHeight]="42" [footerHeight]="50" [rowHeight]="42" [limit]="rowLimit"
                                    [scrollbarV]="false" [scrollbarH]="true">

                                    <ngx-datatable-column name="S No" [width]="50">
                                        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                            <span>{{rowIndex+1}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="No of Videos" prop="NoOfVideos" [width]="300">
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Amount" prop="Amount" [width]="80">
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="GST%" prop="GST" [width]="100">
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Tot Amount" prop="AmountWithGST" [width]="80">
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Actions" [width]="50">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <span>
                                                <div class="action-btn">
                                                    <div class="ico-action-svg edit" (click)="EditTopup(row)"
                                                        title="Edit"><svg height="18" viewBox="0 -1 381.53417 381"
                                                            width="18" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m370.589844 230.964844c-5.523438 0-10 4.476562-10 10v88.792968c-.019532 16.558594-13.4375 29.980469-30 30h-280.589844c-16.5625-.019531-29.980469-13.441406-30-30v-260.589843c.019531-16.5625 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.523438-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.589843c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.789062c0-5.523438-4.476563-10.003906-10-10.003906zm0 0" />
                                                            <path
                                                                d="m156.367188 178.34375 146.011718-146.015625 47.089844 47.089844-146.011719 146.015625zm0 0" />
                                                            <path
                                                                d="m132.542969 249.257812 52.039062-14.414062-37.625-37.625zm0 0" />
                                                            <path
                                                                d="m362.488281 7.578125c-9.769531-9.746094-25.585937-9.746094-35.355469 0l-10.605468 10.605469 47.089844 47.089844 10.605468-10.605469c9.75-9.769531 9.75-25.585938 0-35.355469zm0 0" />
                                                        </svg></div>
                                                    <!-- <div class="ico-action-svg delete" *ngIf="row.RecordStatus=='Active'"
                                                        (click)="showWarningDialogue(row)" title="Delete"><svg height="18"
                                                            viewBox="-40 0 427 427.00131" width="18"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                            <path
                                                                d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                            <path
                                                                d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                                                            <path
                                                                d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                        </svg></div>
                                                    <div class="ico-action-svg" *ngIf="row.RecordStatus=='InActive'"
                                                        [ngClass]="{'activate':row.RecordStatus=='Active','inactivate':row.RecordStatus=='InActive'}"
                                                        (click)="showWarningDialogue(row)" title="Activate"><svg height="18"
                                                            viewBox="0 0 512 512" width="18"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m245.332031 341.332031c-4.09375 0-8.191406-1.554687-11.304687-4.691406l-69.335938-69.332031c-6.25-6.253906-6.25-16.386719 0-22.636719 6.253906-6.25 16.386719-6.25 22.636719 0l58.027344 58.027344 106.027343-106.027344c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382813 0 22.636719l-117.332031 117.332031c-3.160156 3.136719-7.253906 4.691406-11.351563 4.691406zm0 0" />
                                                            <path
                                                                d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-394.664062c0-32.363281 26.304688-58.667969 58.667969-58.667969h394.664062c32.363281 0 58.667969 26.304688 58.667969 58.667969v394.664062c0 32.363281-26.304688 58.667969-58.667969 58.667969zm-394.664062-480c-14.699219 0-26.667969 11.96875-26.667969 26.667969v394.664062c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-394.664062c0-14.699219-11.96875-26.667969-26.667969-26.667969zm0 0" />
                                                        </svg></div> -->

                                                </div>
                                            </span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                            let-pageSize="pageSize" let-selectedCount="selectedCount"
                                            let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                                            <div class="footer-page-size-position">
                                                show
                                            </div>
                                            <div class="footer-page-size-position">
                                                <mat-select style="text-align: center" class="mat-select-position"
                                                    [(value)]="rowLimit"
                                                    (selectionChange)="changeRowLimits($event.value)">
                                                    <mat-option *ngFor="let limit of LIMITS" [value]="limit.value">
                                                        {{limit.value}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                            <div class="footer-page-size-position">
                                                {{rowLimit}} entries
                                            </div>

                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                                [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                [size]="pageSize" [count]="rowCount"
                                                [hidden]="!((rowCount / pageSize) > 1)"
                                                (change)="table.onFooterPage($event)">
                                            </datatable-pager>

                                        </ng-template>
                                    </ngx-datatable-footer>
                                </ngx-datatable>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overlay success" *ngIf="AlertType=='Success'">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <!-- <h4>Package Registred successfully</h4> -->
                <h4>{{AlertMessage}}</h4>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="AlertType=''">Ok</button>
        </div>
    </div>
</div>
<!-- <div class="overlay error" *ngIf = "AlertType=='Error'" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
                <div  class="alcenter" > 
                    <h4>Finance Master</h4>
                <p  >{{AlertMessage}}</p> 
               </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="AlertType=''" >Ok</button>
        </div>
   </div>
</div>  -->
<div class="overlay warning" *ngIf="AlertType=='Error'">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Finance Master</h4>
                <p>{{AlertMessage}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="AlertType=''">Ok</button>
        </div>
    </div>
</div>