<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>


<div class="page-container player-list-page">
    <div class="container">
        <div class="input-container">
            <div class="white">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-tab" rel="master-setup">
                            <a href="javascript:void(0);" class="select" rel=age-category
                                (click)="ChangeTab('AgeCategory')">Age Category</a>
                            <a href="javascript:void(0);" rel="batch-master" (click)="ChangeTab('BatchMaster')">Batch
                                Master</a>
                            <a href="javascript:void(0);" rel="player-group" (click)="ChangeTab('PlayerGroup')">Player
                                Group</a>
                            <a href="javascript:void(0);" rel="staff-mapping"
                                (click)="ChangeTab('SupportStaff')">Support Staff Mapping</a>
                            <a href="javascript:void(0);" rel="athlete-mapping"
                                (click)="ChangeTab('AtleteMapping')">Athlete Mapping</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="white">
                    <div class="master-setup">
                        <div *ngIf="TabName==='AgeCategory'" class="main-tab-container age-category">
                            <app-age-category></app-age-category>
                        </div>
                        <div *ngIf="TabName==='BatchMaster'" class="main-tab-container batch-master">
                            <app-batch-master></app-batch-master>
                        </div>
                        <div *ngIf="TabName==='PlayerGroup'" class="main-tab-container player-group">
                            <app-payer-group></app-payer-group>
                        </div>
                        <div *ngIf="TabName==='SupportStaff'" class="main-tab-container staff-mapping">
                            <app-support-staff-mapping></app-support-staff-mapping>
                        </div>
                        <div *ngIf="TabName==='AtleteMapping'" class="main-tab-container athlete-mapping">
                            <app-athlete-mapping></app-athlete-mapping>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Age Category</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Age Category</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Age Category</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="DeActivateAgeCategory(rowValue)">Ok</button>
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
                (click)="isWarningAlert=false">Cancel</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isConfirmAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Age Category</h4>
                <p>{{confirmAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="ActivateAgeCategory(rowValueActive)">Ok</button>
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
                (click)="isConfirmAlert=false">Cancel</button>
        </div>
    </div>
</div>