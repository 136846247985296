import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { MastersServiceService } from '../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { selectedAssociationList, pageStatus } from '../association-list/association-list.component';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

declare var $: any

@Component({
  selector: 'app-association-registration',
  templateUrl: './association-registration.component.html',
  styleUrls: ['./association-registration.component.css']
})
export class AssociationRegistrationComponent implements OnInit {
  AssociationForm: FormGroup; AssociationInfo: FormGroup;
  AssociationContact: FormGroup; AssociationSettings: FormGroup;

  isLinear = true; isEditable = false; submitted = false;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;

  public UserCode: string = "1";
  public ButtonType: string;

  public AssociationID: string; public AssociationCode: string;
  public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];

  public filOfcRoleUpdate: any = []; public croppedImage: string;
  public imageChangedEvent: any = '';
  public imageChangedType: string = '';
  public displayImageCrop: string = 'none';

  @Input()
  public AssociationName: string; public AssoDisplayName: string; public ParentAssociation: string;
  public Sports: string; public RegistrationDate: string; public RegistrationNumber: string; public Packages: string;
  public NoofUsers: string; public ValidDate: string; public ReferralCode: string; public EmailID: string; public Contact01: string;
  public Contact02: string = ""; public Address01: string; public Address02: string = ""; public Pincode: string; public Seasons: string = "";
  public BGColor_text: string = ""; public BGColor: string = ""; public FontColor_text: string = ""; public FontColor: string = "";
  public AssociationLogo: string = "";
  public AssociationWebsite: string = ""; public Twitter: string = ""; public Facebook: string = ""; public Instagram: string = "";
  public AssociationRole: string = "";
  today = new Date();

  public minDate: Date;
  public maxDate: Date;

  public ContinentsList: any = []; public CountryByRegion: any = [];
  public StateByCountry: any = []; public CityByState: any = [];
  public Continent: string; public Country: string;
  public States: string; public City: string;

  public InputData: Inputs[] = [{ ID: "", ActionFlag: "Load" }];
  public ContinentID: string; public StateID: string; public CountryID: string; public CityID: string;

  public lstAssociationSports: any = []; public lstAssociationMember: any = [];
  public lstAssociationSubscriptionPackage: any = []; public lstAssociationParentAss: any = [];
  public lsyAssociationPackagesList: any = [];
  public lstAssociationEdit: any = []; public lstAssociationEditSports: any = [];

  ngOnInit(): void {
    var minCurrentDate = new Date();
    var maxNewDate = new Date();
    this.minDate = minCurrentDate;
    this.maxDate = new Date(maxNewDate.getFullYear(), maxNewDate.getMonth(), maxNewDate.getDate());

    var dateToday = new Date();
    this.RegistrationDate = this.datepipe.transform(dateToday, 'yyyy-MM-dd');

    this.isShowLoader = true;
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    const URL = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.AssociationForm = this.formBuilder.group({
      n_AssociationName: ['', Validators.required],
      n_AssoDisplayName: ['', Validators.required],
      n_ParentAssociation: [],
      n_Sports: ['', Validators.required],
      n_RegistrationDate: ['', Validators.required],
      n_RegistrationNumber: [],
    });
    this.AssociationInfo = this.formBuilder.group({
      n_Packages: ['', Validators.required],
      n_NoofUsers: ['', Validators.required],
      n_ValidDate: ['', Validators.required],
      n_ReferralCode: [],
    });
    this.AssociationContact = this.formBuilder.group({
      n_EmailID: ['', [Validators.required, Validators.email]],
      n_Contact01: ['', Validators.required],
      n_Contact02: [],
      n_Address01: ['', Validators.required],
      n_Address02: [],
      n_Continent: ['', Validators.required],
      n_Country: ['', Validators.required],
      n_States: ['', Validators.required],
      n_City: ['', Validators.required],
      n_Pincode: ['', Validators.required],
    });
    this.AssociationSettings = this.formBuilder.group({
      n_Seasons: [],
      n_BGColor_text: [],
      n_BGColor: [],
      n_FontColor_text: [],
      n_FontColor: [],
      n_AssociationLogo: [],
      n_AssociationWebsite: ['', Validators.pattern(reg)],
      n_Twitter: ['', [Validators.pattern(reg)]],
      n_Facebook: ['', [Validators.pattern(reg)]],
      n_Instagram: ['', [Validators.pattern(reg)]],
      n_AssociationRole: ['', [Validators.required]],
    });

    this.utility.pageLoadScript();
    this.ButtonType = "Save"
    var InitLoad = { ID: "", ActionFlag: "Load" };
    this.service.loadAssociationMaster(InitLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.ContinentsList = ret.ContinentsList;
        this.AssociationInitLoad();
        this.isShowLoader = false;

        if (sessionStorage.getItem("AssociationPageStatus") == "Edit") {
          this.isShowLoader = true;
          var EditAssociation = {
            AssociationID: String(selectedAssociationList.AssociationID), ActionFlag: "Edit",
            ModuleName: "AssoRegistration", UserID: this.UserCode
          };
          this.service.AssociationMasterCRUD(EditAssociation).subscribe((ret: any = []) => {
            let BTStatus = String(ret.Status);
            if (BTStatus == "true") {
              this.lstAssociationEdit = ret.AssociationEditList;
              this.lstAssociationEditSports = ret.AssociationEditSportsList;

              this.RegistrationDate = "";

              this.AssociationID = String(this.lstAssociationEdit[0].AssociationID);
              this.AssociationCode = this.lstAssociationEdit[0].AssociationCode;
              this.AssociationName = this.lstAssociationEdit[0].AssociationName;
              this.AssoDisplayName = this.lstAssociationEdit[0].AssoDisplayName;
              this.ParentAssociation = (this.lstAssociationEdit[0].ParentAssociation);
              this.Sports = this.lstAssociationEditSports[0].SportsID;
              this.RegistrationDate = this.lstAssociationEdit[0].RegistrationDate;
              this.RegistrationNumber = this.lstAssociationEdit[0].RegistrationNo;
              this.Packages = this.lstAssociationEdit[0].SubscriptionID;
              this.NoofUsers = this.lstAssociationEdit[0].NoOfUsers;
              this.ReferralCode = this.lstAssociationEdit[0].ReferralCode;
              this.AssociationLogo = this.lstAssociationEdit[0].AssoLogo;
              this.AssociationWebsite = this.lstAssociationEdit[0].AssoWebsite;
              this.EmailID = this.lstAssociationEdit[0].EmailID;
              this.Contact01 = this.lstAssociationEdit[0].ContactNo1;
              this.Contact02 = this.lstAssociationEdit[0].ContactNo2;
              this.Address01 = this.lstAssociationEdit[0].Address1;
              this.Address02 = this.lstAssociationEdit[0].Address2;

              this.ContinentID = this.lstAssociationEdit[0].ContinentID;
              this.Continent = this.lstAssociationEdit[0].ContinentID;
              this.onChangeContinent(this.ContinentID);

              this.Country = this.lstAssociationEdit[0].CountryID;
              this.CountryID = String(this.Country);
              this.onChangeCountry(this.Country);

              this.States = this.lstAssociationEdit[0].StateID;
              this.StateID = String(this.States);
              this.onChangeState(this.States);

              this.City = this.lstAssociationEdit[0].CityID;
              this.CityID = String(this.City);
              this.onChangeCity(this.City);

              this.CountryID = this.lstAssociationEdit[0].CountryID;
              this.StateID = this.lstAssociationEdit[0].StateID;
              this.CityID = this.lstAssociationEdit[0].CityID;

              this.Pincode = this.lstAssociationEdit[0].Pincode;

              this.Seasons = String(this.lstAssociationEdit[0].Season);
              this.BGColor_text = this.lstAssociationEdit[0].BGColor;
              this.FontColor_text = this.lstAssociationEdit[0].FontColor;
              this.Twitter = this.lstAssociationEdit[0].Twitter;
              this.Facebook = this.lstAssociationEdit[0].Facebook;
              this.Instagram = this.lstAssociationEdit[0].Instagram;
              this.AssociationRole = this.lstAssociationEdit[0].AssoMemberRoleID;
              this.ValidDate = this.lstAssociationEdit[0].ValidDate;

              sessionStorage.setItem("AssociationPageStatus", "");
              this.ButtonType = "Update";

              this.isShowLoader = false;
            }
          })
        }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Load Failed..!! \n';
        return;
      }
    })
  }

  AssociationInitLoad() {
    var AssoLoad = { AccosiationID: "", ActionFlag: "Load", ModuleName: "AssoRegistration", UserID: this.UserCode };
    this.service.AssociationMasterCRUD(AssoLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstAssociationSports = ret.AssociationSportsList;
        this.lstAssociationMember = ret.AssociationMemberList;
        this.lstAssociationSubscriptionPackage = ret.AssociationSubscriptionPackageList;
        this.lstAssociationParentAss = ret.AssociationParentAssoList;
        this.lsyAssociationPackagesList = ret.AssociationPackagesList;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Load Failed..!! \n';
        return;
      }
    })
  }

  onChangeContinent(cont) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(cont), ActionFlag: "GetCountryByRegion" });
    this.ContinentID = cont;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.CountryByRegion = ret.CountryByRegion;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Country details load failed..!! \n';
        return;
      }
    })
  }

  onChangeCountry(country) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(country), ActionFlag: "GetStateByCountry" });
    this.CountryID = country;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.StateByCountry = ret.StateByCountry;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n State details load failed..!! \n';
        return;
      }
    })
  }

  onChangeState(state) {
    this.isShowLoader = true;
    this.InputData = [];
    this.InputData.push({ ID: String(state), ActionFlag: "GetCityByState" });
    this.StateID = state;
    this.service.loadAssociationMaster(this.InputData[0]).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.CityByState = ret.CityByState;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n City details load failed..!! \n';
        return;
      }
    })
  }

  onChangeCity(city) {
    this.CityID = city;
  }

  onSubmit() {
    this.submitted = true;
    if (this.AssociationSettings.invalid) {
      this.isWarningAlert = true;
      this.warningAlert = 'Please fill out all mandatory fields..!!';
      return;
    }
    else {
      this.SaveAssociationDetails();
    }
  }

  public orderFastID: any = []
  public saveFastID: string;

  SaveAssociationDetails() {
    this.isShowLoader = true;
    var profileImage = "";

    let submitType: string;
    if (this.ButtonType == "Save") { submitType = "Register", this.AssociationID = ""; }
    else { submitType = "Update", this.AssociationID = this.AssociationID; }

    if (this.ParentAssociation == undefined || this.ParentAssociation == null) { this.ParentAssociation = "" }
    else { this.ParentAssociation = this.ParentAssociation }

    if (this.ReferralCode == undefined || this.ReferralCode == null) { this.ReferralCode = "" }
    else { this.ReferralCode = this.ReferralCode }

    if (this.ParentAssociation == undefined || this.ParentAssociation == null) { this.ParentAssociation = "" }
    else { this.ParentAssociation = this.ParentAssociation }

    if (this.ParentAssociation == undefined || this.ParentAssociation == null) { this.ParentAssociation = "" }
    else { this.ParentAssociation = this.ParentAssociation }

    if (this.ParentAssociation == undefined || this.ParentAssociation == null) { this.ParentAssociation = "" }
    else { this.ParentAssociation = this.ParentAssociation }

    if (this.ParentAssociation == undefined || this.ParentAssociation == null) { this.ParentAssociation = "" }
    else { this.ParentAssociation = this.ParentAssociation }


    let regDate = this.datepipe.transform(this.RegistrationDate, 'yyyy-MM-dd');
    let valDate = this.datepipe.transform(this.ValidDate, 'yyyy-MM-dd');

    var saveAssociation = {
      ModuleName: "AssoRegistration",
      AssociationID: this.AssociationID,
      AssociationCode: "",
      AssociationName: this.AssociationName,
      AssoDisplayName: this.AssoDisplayName,
      ParentAssociation: String(this.ParentAssociation),
      Sports: String(this.Sports),
      RegistrationDate: regDate,
      RegistrationNo: "",
      SubscriptionID: String(this.Packages),
      NoOfUsers: String(this.NoofUsers),
      ReferralCode: this.ReferralCode,
      AssoLogo: this.AssociationLogo,
      AssoWebsite: this.AssociationWebsite,
      EmailID: this.EmailID,
      ContactNo1: this.Contact01,
      ContactNo2: this.Contact02,
      Address1: this.Address01,
      Address2: this.Address02,
      CountryID: String(this.CountryID),
      StateID: String(this.StateID),
      CityID: String(this.CityID),
      Pincode: this.Pincode,
      UserID: this.UserCode,
      Season: String(this.Seasons),
      BGColor: this.BGColor_text,
      FontColor: this.FontColor_text,
      Twitter: this.Twitter,
      Facebook: this.Facebook,
      Instagram: this.Instagram,
      AssoMemberRoleID: String(this.AssociationRole),
      ValidDate: valDate,
      ActionFlag: submitType,
    };

    if (this.ImageDetails[0].data != "") {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          profileImage = ret.name;
          saveAssociation.AssoLogo = profileImage;

          this.service.AssociationMasterCRUD(saveAssociation).subscribe((ret: any = []) => {
            let BTStatus = String(ret.Status);
            if (BTStatus == "true") {
              this.isSuccessAlert = true;
              if (submitType == "Register") this.successAlert = '\n"' + this.AssociationName + '"\n Saved successfully..!!';
              else if (submitType == "Update") this.successAlert = '\n"' + this.AssociationName + '"\n Updated successfully..!!';
              this.isShowLoader = false;
              submitType = "";
            }
            else {
              this.isShowLoader = false;
              this.isErrorAlert = true;
              if (submitType == "Register") this.errorAlert = '\n"' + this.AssociationName + '"\n Save failed..!! ' + ret.Message;
              else if (submitType == "Update") this.errorAlert = '\n"' + this.AssociationName + '"\n Updation failed..!! ' + ret.Message;
            }
          })

        }
      })
    }

    else {
      this.service.AssociationMasterCRUD(saveAssociation).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          if (submitType == "Register") this.successAlert = '\n"' + this.AssociationName + '"\n Saved successfully..!!';
          else if (submitType == "Update") this.successAlert = '\n"' + this.AssociationName + '"\n Updated successfully..!!';
          this.isShowLoader = false;
          submitType = "";
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          if (submitType == "Register")
            this.errorAlert = '\n"' + this.AssociationName + '"\n Save failed..!!  ' + ret.Message;
          else if (submitType == "Update")
            this.errorAlert = '\n"' + this.AssociationName + '"\n Updation failed..!! ' + ret.Message;
        }
      })

    }
  }

  ClearAssociation() {
    this.AssociationName = ""; this.AssoDisplayName = ""; this.ParentAssociation = "";
    this.Sports = ""; this.RegistrationDate = ""; this.RegistrationNumber = ""; this.Packages = "";
    this.NoofUsers = ""; this.ValidDate = ""; this.ReferralCode = ""; this.EmailID = ""; this.Contact01 = "";
    this.Contact02 = ""; this.Address01 = ""; this.Address02 = ""; this.Pincode = ""; this.Seasons = "";
    this.BGColor_text = ""; this.BGColor = ""; this.FontColor_text = ""; this.FontColor = ""; this.AssociationLogo = "";
    this.AssociationWebsite = ""; this.Twitter = ""; this.Facebook = ""; this.Instagram = ""; this.AssociationRole = "";
    this.ButtonType = "Save";
  }

  changeBGC(color: string) {
    this.BGColor = color;
    this.BGColor_text = color;
  }

  changeFC(color: string) {
    this.FontColor = color;
    this.FontColor_text = color;
  }


  AssToSubscribe(stepper: MatStepper) {
    if (this.AssociationName != "" && this.AssociationName != null && this.AssoDisplayName != "" && this.AssoDisplayName != null &&
      this.Sports != "" && this.Sports != null && this.RegistrationDate != "" && this.RegistrationDate != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!';
    return;
  }

  AssSubToContact(stepper: MatStepper) {
    if (this.Packages != "" && this.Packages != null && this.NoofUsers != "" && this.NoofUsers != null &&
      this.ValidDate != "" && this.ValidDate != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!';
    return;
  }

  AssContactToSettings(stepper: MatStepper) {
    if (this.EmailID != "" && this.EmailID != null && this.Contact01 != "" && this.Contact01 != null &&
      this.Address01 != "" && this.Address01 != null && this.Continent != "" && this.Continent != null &&
      this.Country != "" && this.Country != null && this.States != "" && this.States != null &&
      this.City != "" && this.City != null && this.Pincode != "" && this.Pincode != null)
      stepper.next();
    else
      this.isWarningAlert = true;
    this.warningAlert = 'Please fill out all mandatory fields..!!';
    return;
  }

  onFileChange(event,type) {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.AssociationLogo = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.AssociationLogo, name: file.type });
    //   };
    // }
    const reader = new FileReader();
    this.imageChangedEvent = event;
    this.imageChangedType = type;
    $('#modalcrop').modal('show');
  }

  saveFile(file, type) {
    this.ImageDetails = [];
    this.ImageDetails.push({
      data: file, name: type
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  
  imageLoaded() {
    // show cropper
  }
  
  cropperReady() {
    // cropper ready
  }
  
  loadImageFailed() {
    // show message
  }
  
  Applycropper() {
    this.AssociationLogo = this.croppedImage;
    this.ImageDetails = [];
    this.ImageDetails.push({ data: this.AssociationLogo, name: 'image/png' });
    $('#modalcrop').modal('hide');
    // this.ProfileImage = "";
  }
  


}

export interface ImageDetails { data: string, name: string }
export interface Inputs { ID: string; ActionFlag: string; }

