import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../../masters-service.service';
import { DatePipe } from '@angular/common';
import { PlayGroupsComponent, SessionData } from '../play-groups/play-groups.component';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-coach-sessions',
  templateUrl: './coach-sessions.component.html',
  styleUrls: ['./coach-sessions.component.css']
})
export class CoachSessionsComponent implements OnInit, OnDestroy {

  @ViewChild(PlayGroupsComponent) SessionData = PlayGroupsComponent.apply;
  SessionForm: FormGroup;
  config: UploadConfig
  /** The current percent to be displayed */
  percent: number;
  uploadedsize: string;
  isUploading: boolean = false;
  public UserCode: string = "";
  public SessionTypes: any[];
  public VideoViewTypes: any[];
  public SessionsList: any[];
  public CoachList: any[];
  public SelectedCoachList: any[];
  public MessageList: any[];
  // public PlayerDetails: PlayerDetailsArr =
  //   { ProfileImage: "", FirstName: "", LastName: "", PlayerRoleName: "", BattingTypeName: "", BowlingStyleName: "", Age: "", DrillSharedCount: "" };
  public PlayerDetails: any = [];
  public skill: string; public viewType: string;
  public sessionName: string; public sessionDate: Date;
  public videoURL: string; public selectedCoach: string = "";
  public openedShareid: string; public filterStartDate: string;
  public filterEndDate: string; public FilterSessionTypeID: string; public FilterViewID: string;

  myControl = new FormControl();

  public commentsFeed: boolean = false;

  public showMonthRange: boolean = false; public showDateRange: boolean = true;
  public advFilters: boolean = false; public selectedfilesize: string;
  public durations: number; public Videoduration: string;
  public currentFile: File; public selectedFile: string;
  public videoLoaded: boolean = false; public videoSubscription: boolean = true;

  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;

  public IsShowComments: boolean = false;
  PackageDetails: any;
  constructor(private utility: UtilitiesService, private http: HttpClient, private blob: BlobService,
    private service: MastersServiceService, private snackbar: MatSnackBar
    , public datepipe: DatePipe, private formBuilder: FormBuilder, private _host: ElementRef, private router: Router) {
    this.currentFile = null;
    this.config = null;
    this.percent = 0;
  }

  public isShowLoader: boolean = false;
  public SearchFilter: string; public SearchLevel: string;

  public IsEmptyVideos: boolean = false; public IsEmptyDrill: boolean = false; public IsEmptyPlaylist: boolean = false;
  public IsSessionsListShow: boolean = true; public IsDrillsListShow: boolean = false; public IsPlayListShow: boolean = false;

  public AthleteID: string; public AthleteRefUserID: string; public AthleteSessionID: string = ""; public playGroupID!: string;
  public lstGetAthleteDrill: any[]; public lstDrillLevel: any[];
  public IsMyVideoShow: boolean = true;

  public lstReviewed: any = []; public ReviewCount: number = 0; public id: number;

  public PreviewDrillName: string; public DrillType: string;
  public ThumbNail: string; public VideoURL: string;
  public CreatedTime: string; public CreatedDate: string;
  public CreatedBy: string; public VideoLength: string;
  public lstDrillEdit: any[]; public lstDrillEditStep: any[];
  public MarialsBind: any = [];

  options: User[] = [
    { name: '2020-2021' },
    { name: '2019-2020' },
    { name: '2018-2019' }
  ];
  filteredOptions!: Observable<User[]>;
  ngOnInit(): void {
    this.utility.previousPage = this.utility.currentPage;
    this.utility.currentPage = this.router.url;
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.AthleteRefUserID = String(this.service.SelectedPlayerData.RefUserID);
    this.AthleteID = String(this.service.SelectedPlayerData.AtheleteID);
    this.playGroupID = String(this.service.SelectedPlayerData.PlayGroupID);
    this.utility.pageLoadScript();
    this.SessionListLoad(Number(1));

    this.videoSubscription = true;

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
    this.SessionForm = this.formBuilder.group({
      n_sessionName: ['', Validators.required],
      n_sessionDate: ['', Validators.required],
      n_skill: ['', Validators.required],
      n_viewType: ['', [Validators.required]]
    });
    $('#elemId').width();
  }
  ngOnDestroy(): void {
    this.snackbar.dismiss();
  }
  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  newsession: boolean = false;
  AddNewSession() {
    if (this.monthlyRemaining > 0) {
      this.IsEmptyVideos = false;
      this.IsSessionsListShow = true;
      this.SessionFiltersLoad();
      this.newsession = true;
      this.videoformfields = true;
    }
    else {
      this.snackbar.open(this.PackageDetails.RemainPlanDays == null ? 'Please subscribe any package to upload videos!!' : 'You have exceeded the limit. Please do top up to upload more videos!!', 'Close',
        {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          panelClass: ['white-snackbar'],
          duration: 2000
        });
      //alert("You have exceeded the limit. Please do top up to upload more videos")
    }
  }
  packageVideos: any;
  topupVideos: any;
  monthlyUploaded: any;
  monthlyRemaining: any;
  totalVideosUsed: any;
  SessionListLoad(id: any) {
    this.id = id;
    this.IsMyVideoShow = false;
    // let AthleteID = String(SessionData.RefUserID);
    this.service.CoachSessionList({
      ActionFlag: "MySessionsCoach", RefUserID: this.UserCode,
      AtheleteRefUserID: this.AthleteRefUserID, PlayGroupID: this.playGroupID
    }).subscribe((ret: any = []) => {
      this.SessionsList = ret.SessionsList;
      this.PackageDetails = ret.PackageDetails[0];
      this.packageVideos = this.PackageDetails.PackageVideos== null ? 0 : this.PackageDetails.PackageVideos;
     // this.topupVideos = this.PackageDetails.PackageVideos== null ? 0 : this.PackageDetails.PackageVideos;
      this.monthlyUploaded = this.PackageDetails.MonthlyUpload == null ? 0 : this.PackageDetails.MonthlyUpload;
      this.monthlyRemaining = Number(this.packageVideos) - Number(this.monthlyUploaded)
      this.totalVideosUsed = this.monthlyUploaded + '/' + this.packageVideos;

      if (ret.PlayerDetails != null) {
        this.PlayerDetails = ret.PlayerDetails[0];
      }
      if (this.SessionsList.length > 0) {
        this.SessionsList = this.SessionsList;

        this.lstReviewed = this.SessionsList.filter((con) => String(con.IsReviewed) === '1');
        for (let i = 0; i < this.lstReviewed.length; i++) {
          this.ReviewCount = i + 1;
        }

        this.IsEmptyVideos = false; this.IsEmptyDrill = false;
        this.IsSessionsListShow = true; this.IsDrillsListShow = false;
        this.IsPlayListShow = false;
      }
      else {
        this.IsEmptyVideos = true; this.IsEmptyDrill = false;
        this.IsSessionsListShow = false; this.IsDrillsListShow = false;
        this.IsPlayListShow = false;
      }
    })
  }

  DrillListLoad(id: any) {
    this.id = id;
    this.isShowLoader = true;
    this.IsMyVideoShow = false;
    var DrillsLoad = {
      ActionFlag: "GetCoachSharedDrillDetails",
      CoachRefUserID: this.UserCode, AtheleteRefUserID: this.AthleteRefUserID
    };
    this.service.SessionChattingCoachLoad(DrillsLoad).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstGetAthleteDrill = ret.CoachSharedDrillDetails;
        this.lstDrillLevel = ret.DrillLevelList;

        if (ret.PlayerDetails != null) {
          this.PlayerDetails = ret.PlayerDetails[0];
        }
        if (this.lstGetAthleteDrill.length > 0) {
          this.lstGetAthleteDrill = this.lstGetAthleteDrill;
          this.IsEmptyVideos = false; this.IsEmptyDrill = false;
          this.IsSessionsListShow = false; this.IsDrillsListShow = true;
          this.IsPlayListShow = false;
          this.isShowLoader = false;
        }
        else {
          this.IsEmptyVideos = false; this.IsEmptyDrill = true;
          this.IsSessionsListShow = false; this.IsDrillsListShow = false;
          this.IsPlayListShow = false;
          this.isShowLoader = false;
        }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  SessionFiltersLoad() {
    this.service.AthleteSessionPageLoad({ ActionFlag: "Load", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      this.SessionTypes = ret.SessionTypes;
      this.VideoViewTypes = ret.VideoViewTypes;
    })
  }

  ApplySessionFilters() {
    let AthleteID = String(SessionData.RefUserID);
    this.service.SessionChattingCoachLoad({ ActionFlag: "GetSharedSessions", CoachRefUserID: this.UserCode, AtheleteRefUserID: this.AthleteRefUserID, FilterSessionTypeID: this.selsesTypes.join(','), FilterViewID: this.selsesViews.join(','), FromDate: this.filterStartDate, ToDate: this.filterEndDate }).subscribe((ret: any = []) => {
      this.SessionsList = ret.PlayerSharedSessionsList;
    })
  }

  classFlag: boolean = false;
  public selsesTypes: any = []; public selsesViews: any = [];
  menuToggle(event: any, val, type) {
    event.target.classList.toggle('active');
    if (type == 'type') {
      if (event.target.classList.value == 'active') {
        this.selsesTypes.push(val);
      }
      else {
        var x = this.selsesTypes.indexOf(val);
        this.selsesTypes.splice(x, 1);
      }
    }
    else if (type == 'view') {
      if (event.target.classList.value == 'active' || event.target.classList.value == 'select active') {
        this.selsesViews.push(val);
      }
      else {
        var x = this.selsesViews.indexOf(val);
        this.selsesViews.splice(x, 1);
      }
    }
    // this.listAddAthleteGroup.splice(x,1);    
  }

  showCommentbox(id, SessionID) {
    this.IsShowComments = !this.IsShowComments;
    this.openedShareid = id;
    this.service.SessionChattingCoachCRUD({ ActionFlag: "Receive", CoachRefUserID: this.UserCode, SessionID: String(SessionID) }).subscribe((ret: any = []) => {
      this.MessageList = ret.CoachSessionChatInfo;
    })
  }

  hideCommentbox() {
    this.IsShowComments = false;
  }

  sendMessage(msgid) {
    var x = document.getElementById('message' + msgid).innerText;
    if (x != "" && x != null) {
      this.service.SessionChattingCoachCRUD({ ActionFlag: "SendCoach", CoachRefUserID: this.UserCode, SessionID: String(msgid), MsgContent: x }).subscribe((ret: any = []) => {
        this.MessageList = ret.CoachSessionChatInfo;
        document.getElementById('message' + msgid).innerText = "";
      })
    }
    else {
      this.isErrorAlert = true;
      this.errorAlert = "Commants should not be empty..!!";
    }
  }

  // -- Get Selected Drill Details -- //
  ShowMyVideo(video) {
    this.isShowLoader = true;
    this.IsEmptyDrill = false;
    this.IsMyVideoShow = true;
    this.MarialsBind = [];
    var Drills = { DrillID: String(video.DrillID), ActionFlag: "GetDrillDetails", RefUserID: this.UserCode };
    this.service.DrillsAthletes(Drills).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstDrillEdit = ret.DrillEditList;
        this.lstDrillEditStep = ret.DrillEditStepList;
        this.IsMyVideoShow = true;
        this.PreviewDrillName = this.lstDrillEdit[0].DrillName;
        this.DrillType = this.lstDrillEdit[0].DrillType;
        this.ThumbNail = this.lstDrillEdit[0].ThumbnailURL;
        this.VideoURL = this.lstDrillEdit[0].VideoURL;
        this.CreatedTime = this.lstDrillEdit[0].CreatedTime;
        this.CreatedDate = this.lstDrillEdit[0].CreatedDate;
        this.CreatedBy = video.SharedByUserName;
        this.VideoLength = video.VideoLength;
        var arrayMat = String(this.lstDrillEdit[0].Materials).split(",").map(String);
        for (var i = 0; i < arrayMat.length; i++) {
          this.MarialsBind.push(arrayMat[i]);
        }
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  // -- Get Selected Playlist Details -- //
  PlayLists: any = [];
  PlayListDetails: any = [];
  PlayListLoad(video) {
    this.isShowLoader = true;
    this.IsEmptyVideos = false; this.IsEmptyDrill = false;
    this.IsSessionsListShow = false; this.IsDrillsListShow = false;
    this.service.VideoPlayListView({ CoachID: String(this.UserCode), ActionFlag: "GetPlaylistbyCoach", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.PlayLists = ret.PlayLists;

        this.isShowLoader = false;
        if (this.PlayLists.length == 0) {
          this.IsEmptyPlaylist = true;
          this.IsPlayListShow = false;
        } else {
          this.IsPlayListShow = true;
          this.IsEmptyPlaylist = false;
        }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }
  public filteredPlayListDetails: any = [];
  public playlistvideo: string = "";
  public PlayListName!: string;
  public videoEndTime: Number;
  public currentVideo: number;
  public currentVideoIndex: number;
  public IsShowUpload: boolean = true;

  playlistClick(playlist, videoplayer) {
    this.service.VideoPlayListView({ CoachID: String(this.UserCode), ActionFlag: "GetPlaylistDetails", RefUserID: this.UserCode, PlayListID: String(playlist.PlayListID) }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.PlayListDetails = ret.PlayListDetails;
        this.playlistvideo = this.PlayListDetails[0].CloudURL;
        videoplayer.pause();
        videoplayer.currentTime = Number(this.PlayListDetails[0].StartTime);
        this.videoEndTime = Number(this.PlayListDetails[0].EndTime);
        this.currentVideo = 1;
        this.currentVideoIndex = 0;
        this.PlayListName = playlist.PlayListName;
        videoplayer.play();
      }
    });

  }
  setCurrentTime(videoplayer) {
    var percentage = (videoplayer.currentTime / videoplayer.duration) * 100;
    $("#custom-seekbar span").css("width", percentage + "%");
    // if (parseFloat(videoplayer.currentTime).toFixed(1) == (parseFloat(String(this.NextAnnotationTime)).toFixed(1))) {
    if (videoplayer.currentTime >= this.videoEndTime) {
      videoplayer.pause();
      this.playlistvideo = this.PlayListDetails[this.currentVideo].CloudURL;
      videoplayer.currentTime = Number(this.PlayListDetails[this.currentVideo].StartTime);
      this.videoEndTime = Number(this.PlayListDetails[this.currentVideo].EndTime);
      this.currentVideo = this.currentVideo + 1;
      this.currentVideoIndex = this.currentVideo - 1;
      videoplayer.play();
    }
  }
  playlistDetailClick(videoplayer, value, index) {
    videoplayer.pause();
    this.playlistvideo = this.PlayListDetails[index].CloudURL;
    videoplayer.currentTime = Number(this.PlayListDetails[index].StartTime);
    this.videoEndTime = Number(this.PlayListDetails[index].EndTime);
    this.currentVideo = index + 1;
    this.currentVideoIndex = index;
    videoplayer.play();
  }
  closePlayListVideo(videoplayer) {
    videoplayer.pause();
  }
  public videosubscription: boolean = false;
  public videoformfields: boolean = false;
  public videouploadstatus: boolean = false;
  public videoupload: boolean = false;
  public videouploadsession: boolean = true;
  public videowidth: number;
  public isWarningAlert: boolean = false; public warningAlert: string;
  isuploadcancelled: boolean = false;

  showVideoupload() {
    if (this.SessionForm.invalid) {
      return;
    }
    else {
      this.videouploadsession = false;
      this.videoupload = true;
      this.videouploadstatus = false;
      this.videoformfields = false;
      this.videosubscription = false;
    }
  }
  showVideouploadstatus() {
    this.videouploadsession = false;
    this.videoupload = false;
    this.videouploadstatus = true;
    this.videoformfields = false;
    this.videosubscription = false;
  }
  showVideoFormfields() {
    this.videouploadsession = false;
    this.videoupload = false;
    this.videouploadstatus = false;
    this.videoformfields = true;
    this.videosubscription = false;
  }
  onVideoChange(event) {

    var attachedFile = <File>event.target.files[0];
    this.currentFile = attachedFile;
    var splitted = this.currentFile.type;
    // var fileType = splitted.split("/", 3);  && "video/mov" && "video/mpeg" 
    if (this.currentFile.type == "video/mp4") {
      let duration: any;
      this.showVideouploadstatus();
      this.selectedFile = URL.createObjectURL(attachedFile);
      document.getElementById("myVideo").setAttribute("src", URL.createObjectURL(attachedFile));
      this.selectedfilesize = (attachedFile.size / (1024 * 1024)).toFixed(2) + 'MB';
      this.IsShowUpload = true;
    }
    else {
      this.currentFile = null;
      this.isErrorAlert = true;
      this.errorAlert = "File format not support..!!";
    }

  }

  onMetadata(e, video) {
    // this.videowidth = 0;
    this.durations = video.duration;
    this.Videoduration = Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2) + ' Mins';
    this.videowidth = video.width;

    var dura = (Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2));
    if (this.durations < 480) {
      this.videoLoaded = true;
    }
    else {
      this.Videoduration = "";
      this.selectedFile = "";
      this.videoLoaded = false;
      this.isWarningAlert = true;
      this.warningAlert = "Video duration should be 08:00 Min (or) Below..!!";
    }
  }

  pad(n, width) {
    var z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  public thumbnailURL: string;
  today = new Date();

  randomid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateBlobUrl(
    params: UploadParams,
    filename: string,
    useAzureStorageEmulator = false,
    azureStorageEmulatorBaseUrl = '') {
    const url = useAzureStorageEmulator ? azureStorageEmulatorBaseUrl : `https://${params.storageAccount}.blob.core.windows.net`
    return `${url}/${params.containerName}/${filename}`
  }

  private prependZeros(number, length) {
    let str = '' + number
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }

  private uploadFileInBlocks(reader, state) {
    if (!state.cancelled) {
      if (state.totalBytesRemaining > 0) {
        const fileContent = state.file.slice(state.currentFilePointer, state.currentFilePointer + state.maxBlockSize)
        const blockId = state.blockIdPrefix + this.prependZeros(state.blockIds.length, 6)
        state.blockIds.push(btoa(blockId))
        reader.readAsArrayBuffer(fileContent)
        state.currentFilePointer += state.maxBlockSize
        state.totalBytesRemaining -= state.maxBlockSize
        if (state.totalBytesRemaining < state.maxBlockSize) {
          state.maxBlockSize = state.totalBytesRemaining;
        }
      } else {
        this.commitBlockList(state);

      }
    }
  }
  private commitBlockList(state) {
    const uri = state.fileUrl + '&comp=blocklist'
    const headers = new HttpHeaders({ 'x-ms-blob-content-type': state.file.type, 'Accept-Ranges': 'bytes', 'x-ms-version': '2013-08-15' })
    let requestBody = '<?xml version=\'1.0\' encoding=\'utf-8\'?><BlockList>'
    for (let i = 0; i < state.blockIds.length; i++) {
      requestBody += '<Latest>' + state.blockIds[i] + '</Latest>'
    }
    requestBody += '</BlockList>'

    this.http.put(uri, requestBody, { headers: headers, responseType: 'text' })
      .subscribe(_elem => {
        if (state.complete) {
          state.complete();
          this.service.CoachSessionCreation({
            ActionFlag: "Create", SessionName: this.sessionName, SessionDate: this.datepipe.transform(this.sessionDate, 'yyyy-MM-dd')
            , SessionTypeID: String(this.skill), VideoViewID: String(this.viewType), VideoURL: state.baseUrl, IsCloudUpload: "1",
            //VideoLength: String(this.durations), 
            VideoLength: String(this.Videoduration),
            UploadDevice: "Web"
            , CloudURL: state.baseUrl, RefUserID: this.UserCode, ThumbnailURL: this.thumbnailURL, AtheleteID: this.AthleteID
            , playGroupID: this.playGroupID, AtheleteRefUserID: this.AthleteRefUserID
          }).subscribe((ret: any = []) => {
            // alert("Completed");
            if (ret.Status == true) {
              this.isSuccessAlert = true;
              this.successAlert = "Session uploaded successfully..";
              this.newsession = false;
              this.videoupload = false;
              this.videouploadstatus = false;
              this.ClearSessionForm();
              this.SessionListLoad(this.id);
              this.ngOnInit();
            }
            //  this.showVideoGoupload();
            // this.clearSession();
          })
        }
      }, err => {
        console.log({ error: err })
        if (state.error) {
          state.error(err)
        }
      })
  }

  private initializeState(config: UploadConfig) {
    let blockSize = BlobService.DefaultBlockSize
    if (config.blockSize) {
      blockSize = config.blockSize
    }
    let maxBlockSize = blockSize
    let numberOfBlocks = 1
    const file = config.file
    const fileSize = file.size
    if (fileSize < blockSize) {
      maxBlockSize = fileSize
    }
    if (fileSize % maxBlockSize === 0) {
      numberOfBlocks = fileSize / maxBlockSize
    } else {
      numberOfBlocks = fileSize / maxBlockSize + 1
    }

    return {
      maxBlockSize: maxBlockSize, // Each file will be split in 256 KB.
      numberOfBlocks: numberOfBlocks,
      totalBytesRemaining: fileSize,
      currentFilePointer: 0,
      blockIds: new Array(),
      blockIdPrefix: 'block-',
      bytesUploaded: 0,
      submitUri: null,
      file: file,
      baseUrl: config.baseUrl,
      sasToken: config.sasToken,
      fileUrl: config.baseUrl + '?' + config.sasToken,
      progress: config.progress,
      complete: config.complete,
      error: config.error,
      cancelled: false
    }
  }

  upload(config: UploadConfig) {
    this.isUploading = true;

    const state = this.initializeState(config)
    const reader = new FileReader()
    reader.onloadend = (evt: any) => {
      if (evt.target.readyState === 2 && !state.cancelled) {
        const uri = state.fileUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1]
        const requestData = evt.target.result
        const requestData2 = new Uint8Array(evt.target.result)
        const headers = new HttpHeaders({ 'x-ms-blob-type': 'BlockBlob', 'Content-Type': 'application/octet-stream' })
        this.http.put(uri, requestData, { headers: headers, responseType: 'text' })
          .subscribe(_elem => {
            state.bytesUploaded += requestData2.length
            const percentComplete = Math.round((state.bytesUploaded / state.file.size) * 1000) / 10
            if (state.progress) {
              state.progress(percentComplete)
            }
            if (this.isuploadcancelled)
              state.cancelled = true;
            this.uploadFileInBlocks(reader, state)
          }, err => {
            console.log({ error: err })
            if (state.error) {
              state.error(err)
            }
          })
      }
    }
    this.uploadFileInBlocks(reader, state)
    return {
      cancel: () => {
        state.cancelled = true
      }
    }
  }
  uploadbutton() {
    this.isuploadcancelled = false;
    var canvas = <HTMLCanvasElement>document.getElementById('canvas');
    var video = <HTMLCanvasElement>document.getElementById('myVideo');
    // var img =  <HTMLCanvasElement> document.getElementById('imgId');
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // var dataURL = canvas.toDataURL();
    // img.setAttribute('src', dataURL);     
    // alert(canvas.toDataURL());
    if (this.SessionForm.invalid) {
      return;
    }
    else {
      if (this.currentFile !== null) {
        this.IsShowUpload = false;
        this.service.UploadFile({ data: canvas.toDataURL(), name: "image/png" }).subscribe((ret: any = []) => {

          var splitted = this.currentFile.type;
          var fileType = splitted.split("/", 3);  //&& "video/mov" && "video/mpeg" 
          var now = new Date().getTime();
          var filename = this.datepipe.transform(this.today, 'yyyy-MM-dd') + now + '-' + this.randomid(10);
          var VideoFileName = this.currentFile.name.replace(this.currentFile.name, filename) + '.' + fileType[1];

          this.thumbnailURL = ret.name;
          const baseUrl = this.generateBlobUrl(Config, VideoFileName)
          this.config = {
            baseUrl: baseUrl,
            blockSize: 1024 * 1024 * 2,
            sasToken: Config.sas,
            file: this.currentFile,
            complete: () => {
              console.log('Transfer completed !')
            },
            error: (err) => {
              console.log('Error:', err)
            },
            progress: (percent) => {
              this.percent = percent, this.uploadedsize = (percent * (this.currentFile.size / (1024 * 1024 * 100))).toFixed(2)
            }
          }
          this.upload(this.config);
        })

      }
    }
  }

  GetAnalysisDetails(data) {
    sessionStorage.setItem("sessionId", String(data.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(data.CloudURL));
    sessionStorage.setItem("sessionPlayerName", String(data.PlayerName));
    sessionStorage.setItem("sessionPlayerRole", String(data.PlayerRoleName));
    sessionStorage.setItem("sessionVideoDate", String(data.SessionDate));
    sessionStorage.setItem("sessionProfileImage", String(data.ProfileImage));
    sessionStorage.setItem("sessionsThumbnail", String(data.ThumbnailURL));
    sessionStorage.setItem("SessionTypeName", String(data.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(data.VideoViewName));
    sessionStorage.setItem("SessionName", String(data.SessionName));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));
    sessionStorage.setItem("sessionFlag", String("1"));
    sessionStorage.setItem("athleteID", String(this.AthleteRefUserID));
    this.service.VideoSessionDetails = data;
    this.router.navigate(['/video-analyse']);
  }


  GetCompareVideos(data) {
    sessionStorage.setItem("sessionId", String(data.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(data.CloudURL));
    sessionStorage.setItem("sessionPlayerName", String(data.PlayerName));
    sessionStorage.setItem("sessionPlayerRole", String(data.PlayerRoleName));
    sessionStorage.setItem("sessionVideoDate", String(data.SessionDate));
    sessionStorage.setItem("sessionProfileImage", String(data.ProfileImage));
    sessionStorage.setItem("sessionsThumbnail", String(data.ThumbnailURL));
    sessionStorage.setItem("SessionTypeName", String(data.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(data.VideoViewName));
    sessionStorage.setItem("SessionName", String(data.SessionName));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));
    sessionStorage.setItem("athleteID", String(this.AthleteRefUserID));
    sessionStorage.setItem("sFlag", String("1"));

    this.router.navigate(['/video-compare']);
  }

  ClearSessionForm() {
    this.sessionName = '';
    this.sessionDate = null;
    this.skill = '';
    this.viewType = '';
  }

}

export interface PlayerDetailsArr {
  ProfileImage: string, FirstName: string, LastName: string, PlayerRoleName: string, BattingTypeName: string, BowlingStyleName: string, Age: string,
  DrillSharedCount: string
}
export interface User { name: string; }
const Config: UploadParams = {
  // sas: 'sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-05-31T14:50:55Z&st=2021-04-01T06:50:55Z&spr=https,http&sig=Pp4Dg4IeL2gfPLbj%2BdedKfpscdxOXgRfWfkCQcPF%2FMk%3D',
  // storageAccount: 'prodlivedata2020',
  // containerName: 'livecontent' 

  // -- Updated 05-05-2021 sas token -- //
  sas: 'sp=racwdl&st=2022-05-06T11:14:35Z&se=2024-05-06T19:14:35Z&sv=2020-08-04&sr=c&sig=7LqV5ZH%2B5QfucxrojJJ12tjqiKBSw5K59eeOiPlbR%2F8%3D',
  storageAccount: 'splcms',
  containerName: 'cricpass'
};