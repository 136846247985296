<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="player-group-container" *ngIf="playerList">
    <div class="row">
        <div class="col-md-12">
            <div class="search-container">
                <div class="row">
                    <div class="col-md-2"> 
                        <div class="asign-hlt">Assign to Group</div>
                    </div>
                   
                    <div class="col-md-2">
                        <div class="player-count txt-right">Total Player : <span>{{AthleteCounts}}</span></div>
                    </div>
                       
                    <!-- <div class="col-md-3" >
                        <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" 
                                height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                <path opacity=".3" 
                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                </path>
                                <path 
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                </path></svg>
                            </mat-icon>
                             <mat-select placeholder="Age Categories"  [(ngModel)]="AgeCategories" 
                              style="text-transform:capitalize;"  >
                                <mat-option  *ngFor="let category of lstAgeCategory"  [value]="category.CategoryID"  
                                style="text-transform:capitalize;" >{{category.CategoryName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>   -->
                    <div class="col-md-3">
                        <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                            floatLabel="always">
                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit=""
                                    height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path opacity=".3"
                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                    </path> 
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                    </path>
                                </svg></mat-icon>
                            <mat-select placeholder="Athlete Group" [(ngModel)]="AthletGroups"
                                (selectionChange)="changePlayerGroup(AthletGroups)" style="text-transform:capitalize;">
                                <mat-option [value]="groups.PlayGroupID" *ngFor="let groups of lstPlayGroup"
                                    style="text-transform:capitalize;">{{groups.PlayGroupName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                            <mat-icon matSuffix class="mat-icon">
                                <a href="javascript:void(0);"> <svg viewBox="0 0 373.12 373.12" width="24" height="24"><path d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z"/></svg> </a>
                            </mat-icon>
                            <input matInput [(ngModel)]="_search" placeholder="Search here..." >
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">
                        <div class="txt-center add-new back-previous">
                            <a href="javascript:void(0);" (click)="hidePlayergroup()">
                                Back
                                <!-- <div class="ico-action-svg more" title="Previous Page">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.151 219.151"
                                        width="32" height="32">
                                        <path
                                            d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575		C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z" />
                                        <path
                                            d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008 c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628 		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z" />
                                    </svg>
                                    <img src="assets/images/Back.png">  
                                </div> -->
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="customscroll-table">
                <div>
                    <ngx-datatable class="material" [rows]="lstAssociationAthlete|filter:_search" [columnMode]="'force'"
                        [headerHeight]="42" [footerHeight]="50" rowHeight="42" [limit]="rowLimit" [selected]="selected"
                        [selectionType]="checkbox"  [scrollbarV]="false" [scrollbarH]="true" >

                        <ngx-datatable-column name="Name" prop="DisplayName" [width]="200"></ngx-datatable-column>
                        <ngx-datatable-column name="Gender" prop="GenderName" [width]="80"></ngx-datatable-column>
                        <ngx-datatable-column name="Age" prop="Age" [width]="50"></ngx-datatable-column>
                        <ngx-datatable-column name="Category" prop="CategoryName" [width]="100"></ngx-datatable-column>
                        <ngx-datatable-column name="Specification" prop="PlayerRoleName" [width]="200"></ngx-datatable-column>

                        <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                            [draggable]="false" [resizeable]="false">

                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value"
                                let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                <input type="checkbox" [checked]="row.Status===1" (click)="onAddclick(row,$event)" />

                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                                let-isVisible="isVisible">  
                                <div class="footer-page-size-position">
                                    show
                                </div>
                                <div class="footer-page-size-position">
                                    <mat-select style="text-align: center" class="mat-select-position"
                                        [(value)]="rowLimit" (selectionChange)="changeRowLimits($event.value)">
                                        <mat-option *ngFor="let limit of LIMITS" [value]="limit.value">
                                            {{limit.value}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="footer-page-size-position">
                                    {{rowLimit}} entries
                                </div>

                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage" [size]="pageSize" [count]="rowCount"
                                    [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                                </datatable-pager>

                            </ng-template>
                        </ngx-datatable-footer>

                    </ngx-datatable>
                </div>


            </div>
            <div class="btn-container txt-center mtb10">
                <button mat-raised-button color="primary" (click)="SavePlayGroup()">Save Group</button>
            </div>
        </div>
    </div>
</div>

<div class="player-group-container" *ngIf="playerGroup">
    <div class="mapping-container">
        <div class="selected-players">
            <div class="row">
                <div class="col-md-4">
                    <div class="selected-player-header txt-right">View by mapping Group : </div>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%"
                                width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                <path opacity=".3"
                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                </path>
                                <path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                </path>
                            </svg></mat-icon>
                        <mat-select placeholder="Athlete Group" [(ngModel)]="AthletGroup"
                            style="text-transform:capitalize;" (selectionChange)="changePlayGroup(AthletGroup)">
                            <mat-option [value]="groups.PlayGroupID" *ngFor="let groups of lstPlayGroup"
                                style="text-transform:capitalize;">{{groups.PlayGroupName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <div class="txt-center add-new back-previous mt25">
                        <button mat-raised-button color="primary" (click)="showPlayergroup()" class="mt5">Assign Players</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="customscroll-table">

            <div>
                <ngx-datatable class="material" [rows]="lstGetPlayerByGroup" [columnMode]="'force'" [headerHeight]="42"
                    [footerHeight]="50" [rowHeight]="42" [limit]="rowLimit" [scrollbarV]="false" [scrollbarH]="true"
                    style="width: 100%"  >

                    <ngx-datatable-column name="S No" [width]="50">
                        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                            <span>{{rowIndex+1}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Name" prop="DisplayName" [width]="100">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Gender" prop="GenderName" [width]="50">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Specification" prop="PlayerRole" [width]="50">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Age" prop="Age" [width]="50">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Batting Style" prop="BatType" [width]="100">
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                            let-isVisible="isVisible">

                            <div class="footer-page-size-position">
                                show
                            </div>
                            <div class="footer-page-size-position">
                                <mat-select style="text-align: center" class="mat-select-position"
                                    [(value)]="rowLimit" (selectionChange)="changeRowLimits($event.value)">
                                    <mat-option *ngFor="let limit of LIMITS" [value]="limit.value">
                                        {{limit.value}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="footer-page-size-position">
                                {{rowLimit}} entries
                            </div>

                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                [page]="curPage" [size]="pageSize" [count]="rowCount"
                                [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                            </datatable-pager>

                        </ng-template>
                    </ngx-datatable-footer>

                </ngx-datatable>
            </div>


        </div>
    </div>
</div>



<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Athlete Mapping</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Athlete Mapping</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Athlete Mapping</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
               (click)="isWarningAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isConfirmAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Athlete Mapping</h4>
                <p>{{confirmAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
                (click)="isConfirmAlert=false">Ok</button>
        </div>
    </div>
</div>