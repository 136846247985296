import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../masters-service.service';
import { DatePipe } from '@angular/common';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';
declare var $: any
@Component({
  selector: 'app-my-playlist',
  templateUrl: './my-playlist.component.html',
  styleUrls: ['./my-playlist.component.css']
})
export class MyPlaylistComponent implements OnInit {
  public UserCode: string = "1";
  public SessionTypeList: any[];
  public VideoViewList: any[];
  public PMLengthList: any[];
  public PMLineList: any[];
  public WWRegionList: any[];
  public ImpactHeightList: any[];
  public BowlingTypeList: any[];
  public ShotTypeList: any[];
  public FootMoveList: any[];
  public BallReleaseTypeList: any[];
  public PlayListVideoTagList: any[];

  public SessionType: String = "";
  public VideoView: String = "";
  public PMLength: String = "";
  public PMLine: String = "";
  public WWRegion: String = "";
  public ImpactHeight: String = "";
  public ImpactLine: String = "";
  public BowlingType: String = "";
  public ShotType: String = "";
  public FootMove: String = "";
  public BallReleaseType: String = "";
  public ShotQuality: String = "";
  public SearchFilter: string = "";
  public ColumnMode: ColumnMode;
  public StartDate!: Date;
  public EndDate!: Date;
  public PlaylistTags: any = [];
  constructor(private utility: UtilitiesService, private http: HttpClient, private blob: BlobService,
    private service: MastersServiceService, public datepipe: DatePipe, private formBuilder: FormBuilder, private _host: ElementRef,
    private router: Router) { }

  ngOnInit(): void {
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    this.initpageload();
  }

  visible = true; selectable = true;
  removable = true; addOnBlur = true;
  selected = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  alerts(x) {
    alert(x);
  };

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.PlaylistTags.push(value.trim());
      //this.fruits.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tags): void {
    const index = this.PlaylistTags.indexOf(tags);
    if (index >= 0) {
      this.PlaylistTags.splice(index, 1);
    }
  }

  initpageload() {
    this.service.VideoPlayListCRUD({ ActionFlag: "Load", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.SessionTypeList = ret.SessionTypeList;
        this.VideoViewList = ret.VideoViewList;
        this.PMLengthList = ret.PMLengthList;
        this.PMLineList = ret.PMLineList;
        this.WWRegionList = ret.WWRegionList;
        this.ImpactHeightList = ret.ImpactHeightList;
        this.BowlingTypeList = ret.BowlingTypeList;
        this.ShotTypeList = ret.ShotTypeList;
        this.FootMoveList = ret.FootMoveList;
        this.BallReleaseTypeList = ret.BallReleaseTypeList;
        this.PlayLists = ret.PlayLists;
      }
    })
  }
  searchTags() {
    debugger;
    if (this.StartDate != undefined && this.EndDate != null && this.EndDate != undefined) {
      var unc = "";
      var beat = "";
      var mid = "";
      if (this.ShotQuality == '1')
        mid = "true"
      else if (this.ShotQuality == '2')
        unc = "true"
      else if (this.ShotQuality == '3')
        beat = "true"
      this.service.VideoPlayListCRUD({
        ActionFlag: "ListSessionTags", RefUserID: this.UserCode, StartDate: this.StartDate, EndDate: this.EndDate
        , SessionTypeID: this.SessionType.toString(), VideoViewID: this.VideoView.toString(), FootMoveID: this.FootMove.toString(),
        ShotTypeID: this.ShotType.toString(), BallReleaseID: this.BallReleaseType.toString(), BallVariation: this.BowlingType.toString(),
        RegionID: this.WWRegion.toString(), LineID: this.PMLine.toString(), LengthID: this.PMLength.toString(), ImpactLineID: this.ImpactLine.toString(),
        ImpactLengthID: this.ImpactHeight.toString(), IsUncomfortable: unc, IsBeaten: beat, IsMiddled: mid
      }).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          this.PlayListVideoTagList = ret.PlayListVideoTagList;
          //alert(this.PlayListVideoTagList);
        }
      })
    } else {
      alert("Please select Start date and End date");
      
    }
  }
  VideoURL: string = "";
  endTime: any;
  startTime: any;
  openVideo(row, videoPlayer) {
    this.VideoURL = row.CloudURL;
    videoPlayer.currentTime = row.StartTime;
    this.endTime = row.EndTime;
    this.startTime = row.StartTime;
    videoPlayer.pause();

  }
  setCurrentTime(videoPlayer) {
    if (videoPlayer.currentTime >= this.endTime) {
      videoPlayer.pause();
      videoPlayer.currentTime = this.startTime;
    }
  }

  closeVideo(videoPlayer) {
    videoPlayer.pause();
  }

  listselectedTags: any = [];
  playlistName: string = "";
  // onAddclick(value, event) {
  //   if (event.currentTarget.checked == true) {
  //     this.listselectedTags.push(value.VideoTagID);
  //   }
  //   else {
  //     var x = this.listselectedTags.indexOf(value.VideoTagID);
  //     this.listselectedTags.splice(x, 1);
  //   }
  // }
  onAddclick(event) {
    // if (event.currentTarget.checked == true) {
    //   this.listselectedTags.push(value.VideoTagID);
    // }
    // else {
    //   var x = this.listselectedTags.indexOf(value.VideoTagID);
    //   this.listselectedTags.splice(x, 1);
    // }
    this.listselectedTags = event.selected;
    // this.listselectedTags=[];
  }
  
  PlayLists: any = [];
  //SearchFilter: string = "";
  SavePlaylilst() {
    var sellist = [];
    for (var i = 0; i < this.listselectedTags.length; i++) {
      sellist.push(this.listselectedTags[i].VideoTagID);
    }
    this.service.VideoPlayListCRUD({
      ActionFlag: "CreatePlayList", RefUserID: this.UserCode
      , VideoTagID: sellist.toString(), PlayListName: this.playlistName, TagName: this.PlaylistTags.toString()
    }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.PlayLists = ret.PlayLists;
      }
    })
  }

  OpenPlaylistView(playlist) {
    // sessionStorage.setItem("playListID", playlist.PlayListID);
    this.service.playerlistID = playlist.PlayListID;
    this.router.navigate(['/playlistview']);
  }

}
