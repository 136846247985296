import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { engineTag, videoTag } from '../video';

declare let $: any;

@Component({
  selector: 'app-video-tagging',
  templateUrl: './video-tagging.component.html',
  styleUrls: ['./video-tagging.component.css']
})
export class VideoTaggingComponent implements OnInit {

  allScoringDataLoad;

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;

  public isPitchmap: boolean = true;
  public isImpact: boolean = false;

  public ispopupShow: boolean = false;
  public widget!: string;
  public Endtxt!: string;
  public StartBall!: string;
  public StartOver!: string;
  public StartCapture!: string;
  public EndCapture!: string;
  public BallSpeed: string = "";
  public BallSpeedType: string = "kmph";

  public SessionID!: string | null | undefined;
  isAggressive = false;
  objengineTag: engineTag = new engineTag();

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe, private _Activatedroute: ActivatedRoute) { }

  name = 'Angular';
  time = new Date();
  timer;

  public UserCode: string = String(JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).UserID);
  public isShowLoader: boolean = false;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  PMRHB: boolean = true;
  isStartBall: boolean = false;
  BowlSide: string;
  BallReleaseID: string;
  BowlTypeID: string;
  BallVariation: string;
  ShotTypeID: string;
  RegionID: string;
  FieldPosition: string;
  LineID: string;
  LengthID: string;
  IsUncomfortable: boolean = false;
  UncomfortID!: string;
  IsBeaten: boolean = false;
  BeatenID!: string;
  PitchMapXY!: string;
  PitchX!: string;
  PitchY!: string;
  ImpactXY!: string;
  ImpactX!: string;
  ImpactY!: string;
  WWRegionID!: string;
  SessionsTagsList: any = [];
  SessionVideoTagDetails: any = [];
  public _BallDetails: any = [];
  public _videoTagId: string = "";
  public isFullscreen = true;
  @ViewChild("WWLine") WWLines: ElementRef;
  ngOnInit(): void {
    this.utility.pageLoadScript();

    // -- Fullscreen Mode
    // let elem = document.documentElement;
    // let methodToBeInvoked = elem.requestFullscreen || elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
    // if (methodToBeInvoked) methodToBeInvoked.call(elem);
    this.utility.fullscreenlayout();

    this.Endtxt = 'Near';
    this.StartBall = 'Start Ball';
    this.StartOver = 'Start Over';
    this.StartCapture = 'Start Capture';
    this.EndCapture = 'End Capture';
    this.widget = 'Main';
    this.timer = setInterval(() => {
      this.time = new Date();
    }, 1000);

    // this._Activatedroute.paramMap.subscribe(params => {
    //   this.SessionID = params.get('SessionID')?.toString();
    // });            
    this.UserCode = sessionStorage.getItem('sessionUserCode');
    this.SessionID = sessionStorage.getItem('sessionId');
    this.TaggingInitLoad();
    this.StartSessions();
  }

  public AthleteName!: string; public SessionType!: string;   BwShow : boolean = false; ShotShow: boolean = false;
  public SessionViewType!: string; public SessionName!: string;
  public ThumbnailURL!: string; public VideoURL!: string; public BattingTypeID!: string;
  Aggressive: any = [];
  Deffensive: any = [];
  ShotTypeMore: any = [];
  BowlTypeMore: any = [];
  BowlFast: any = [];
  BowlSpin: any = [];
  TaggingInitLoad() {
    this.isShowLoader = true;
    var SessionsLoad = { SessionID: this.SessionID, ActionFlag: "GetScoringLoad", RefUserID: this.UserCode };
    this.service.VideoTaggingLoad(SessionsLoad).subscribe((data: any = []) => {
      let BTStatus = String(data['Status']);
      if (BTStatus == "true") {
        this.allScoringDataLoad = data;
        this.AthleteName = data.SessionDetails[0].AtheleteName;
        this.SessionName = data.SessionDetails[0].SessionName;
        this.SessionType = data.SessionDetails[0].SessionTypeName;
        this.SessionViewType = data.SessionDetails[0].VideoViewName;
        this.BattingTypeID = data.SessionDetails[0].BattingTypeID;
        this.ThumbnailURL = data.SessionDetails[0].ThumbnailURL;
        this.VideoURL = data.SessionDetails[0].VideoURL;
         

        this.Aggressive =  data.ShotsList.filter(Shot => Shot.ShotIntent =="1" );
        this.Deffensive =  data.ShotsList.filter(Shot => Shot.ShotIntent =="2");
        this.BowlFast =  data.BowlTypesList.filter(Balltype => Balltype.BallTypeID =="1");
        this.BowlSpin =  data.BowlTypesList.filter(Balltype => Balltype.BallTypeID =="2");
        this.ShotTypeMore=  this.Aggressive 
        this.BowlTypeMore=  this.BowlFast ;
        this.BwShow=true;
        this.ShotShow=true;
        this.isShowLoader = false;
        if (this.SessionType == "Batting") {
          if (this.BattingTypeID == "1")
            this.PMRHB = true;
          else
            this.PMRHB = false;
        }

      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = data['Message'];
        return;
      }
    });
    this.service.videoTaggingEngine({ ActionFlag: "GetSessionTagVideos", RefUserID: String(this.UserCode), SessionID: String(this.SessionID) }).subscribe(
      (data: any = []) => {
        this.SessionsTagsList = data.SessionsTagsList;
      });
  }
  
           

  toggleFullscreen() {
    if (!this.isFullscreen) {
      let elem = document.documentElement;
      let methodToBeInvoked = elem.requestFullscreen || elem['mozRequestFullscreen']
        ||
        elem['msRequestFullscreen'];
      if (methodToBeInvoked) methodToBeInvoked.call(elem);
      this.isFullscreen = true;
    }
    else {
      this.isFullscreen = false;
      document.exitFullscreen();
    }

  }
  closescreen(){
    if (this.isFullscreen) 
    document.exitFullscreen();
  }
  StartSessions() {
    this.isShowLoader = true;
    var Sessions = { SessionID: this.SessionID, ActionFlag: "StartSession", RefUserID: this.UserCode };
    this.service.videoTaggingEngine(Sessions).subscribe((data) => {
      let BTStatus = String(data['Status']);
      if (BTStatus == "true") {
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = data['Message'];
        return;
      }
    });
    this.service.videoTaggingEngine({
      SessionID: this.SessionID, ActionFlag: "GetSessionTagVideos", RefUserID: this.UserCode
    }).subscribe((data: any = []) => {
      let BTStatus = String(data['Status']);
      if (BTStatus == "true") {
        this.SessionsTagsList = data.SessionsTagsList;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = data['Message'];
        return;
      }
    });
  }

  EndSessions() {
    this.isShowLoader = true;
    var Sessions = { SessionID: this.SessionID, ActionFlag: "EndSession", RefUserID: this.UserCode };
    this.service.videoTaggingEngine(Sessions).subscribe((data) => {
      let BTStatus = String(data['Status']);
      if (BTStatus == "true") {
        this.allScoringDataLoad = data;
        this.isShowLoader = false;
        this.isSuccessAlert = true;
        this.successAlert = "Session End successfully...";
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = data['Message'];
        return;
      }
    })
  }

  showPopup() { this.ispopupShow = true; }
  closePopup() { this.ispopupShow = false; }

  togEnd() {
    if (this.Endtxt == 'Near')
      this.Endtxt = 'Far';
    else
      this.Endtxt = 'Near';
  }

  toggleBall() {
    if (this.isStartBall == false) {
      this.StartBall = 'End Ball';
      this.isStartBall = true;
    }
    else {
      this.StartBall = 'Start Ball';
      this.isStartBall = false;
      if (this._startCapture) {
        this.endCapture();
      }
      this.insertBall();
    }
  }
  insertBall() {
    if (this._videoTagId == "") {
      this.service.VideoTaggingEngineCRUD({
        SessionID: this.SessionID, VideoTagID: "", StartTime: String(this._startTime), EndTime: String(this._endTime), DownLegs: String(this._downLegs),
        BowlSide: String(this._bowlSide), BallReleaseID: String(this._releaseType), BowlTypeID: String(this._ballType), BallVariation: String(this._bowlType), ShotID: String(this._shotType), ShotTypeID: String(this._shotIntent),
        FootMoveID: String(this._footMove), RegionID: String(this.WWRegion), FieldPosition: "", LineID: String(this.LineID), LengthID: String(this.LengthID), IsUncomfortable: String(this.IsUncomfortable),
        UncomfortID: String(this._unComfort), IsBeaten: String(this.IsBeaten), BeatenID: String(this._beaten),
        PitchMapXY: String(this.PitchMapXY), PitchX: String(this.PMX), PitchY: String(this.PMY),
        ImpactX: String(this.ImpX), ImpactY: String(this.ImpY), ImpactXY: String(this.ImpactXY), ImpactLineID: String(this.ImpLine), ImpactLengthID: String(this.ImpHeight),
        WWXY: String(this.WWX) + ',' + String(this.WWY), WWX: String(this.WWX), WWY: String(this.WWY),
        BallSpeed: String(this.BallSpeed), BallSpeedType: String(this.BallSpeedType),
        CuttingDiagonal: String(this._isCD), ActionFlag: "CreateVideoTag", RefUserID: this.UserCode
      }).subscribe((data: any = []) => {
        this.SessionsTagsList = data.SessionsTagsList;
      });
    } else {
      this.service.VideoTaggingEngineCRUD({
        SessionID: this.SessionID, VideoTagID: String(this._videoTagId), StartTime: String(this._startTime), EndTime: String(this._endTime), DownLegs: String(this._downLegs),
        BowlSide: String(this._bowlSide), BallReleaseID: String(this._releaseType), BowlTypeID: String(this._ballType), BallVariation: String(this._bowlType), ShotID: String(this._shotType), ShotTypeID: String(this._shotIntent),
        FootMoveID: String(this._footMove), RegionID: String(this.WWRegion), FieldPosition: "", LineID: String(this.LineID), LengthID: String(this.LengthID), IsUncomfortable: String(this.IsUncomfortable),
        UncomfortID: String(this._unComfort), IsBeaten: String(this.IsBeaten), BeatenID: String(this._beaten),
        PitchMapXY: String(this.PitchMapXY), PitchX: String(this.PMX), PitchY: String(this.PMY),
        ImpactX: String(this.ImpX), ImpactY: String(this.ImpY), ImpactXY: String(this.ImpactXY), ImpactLineID: String(this.ImpLine), ImpactLengthID: String(this.ImpHeight),
        WWXY: String(this.WWX) + ',' + String(this.WWY), WWX: String(this.WWX), WWY: String(this.WWY),
        BallSpeed: String(this.BallSpeed), BallSpeedType: String(this.BallSpeedType),
        CuttingDiagonal: String(this._isCD), ActionFlag: "Update", RefUserID: this.UserCode
      }).subscribe((data: any = []) => {
        this.SessionsTagsList = data.SessionsTagsList;
      });
    }
    this.clearBallDetails();
  }

  toggleOver() {
    if (this.StartOver == 'Start Over')
      this.StartOver = 'End Over'
    else
      this.StartOver = 'Start Over'
  }
  PMLineX: string;
  PMLineY: string;
  PMX: string = "";
  PMY: string = "";
  PMLine: boolean = false;
  polygon: any = [[126, 176], [130, 129], [107, 129], [95, 176]];
  PMClicked(event, line, length) {
    if (this.isStartBall) {
      this.PMLine = true;
      this.PMLineX = (22 + event.layerX + 4) + "px";
      this.PMLineY = (event.layerY - 6) + "px";
      this.isImpact = true;
      this.isPitchmap = false;
      this.LineID = line;
      this.LengthID = length;
      this.PMX = event.layerX;
      this.PMY = event.layerY;
    }
    //  var isinside = this.inside([event.layerX, event.layerY], this.polygon);

  }
  public _startTime: string = ""; public _endTime: string = ""; public _startCapture: boolean;
  startCapture() {
    if (this.isStartBall)
      var myVideo: any = document.getElementById("myVideo");
    this._startTime = myVideo.currentTime;
    this._startCapture = true;
    myVideo.play();
  }

  endCapture() {
    var myVideo: any = document.getElementById("myVideo");
    this._endTime = myVideo.currentTime;
    myVideo.pause();
    this._startCapture = false;
  }

  // multiview container
  showWidget(value: string) {
    this.widget = value;
  }

  showImpact() {
    if (this.isImpact) {
      this.isImpact = false;
      this.isPitchmap = true;
    }
    else {
      this.isImpact = true;
      this.isPitchmap = false;
    }

  }

  public _unComfort: string = ""; public _beaten: string = "";
  public _footMove: string = ""; public _shotType: string = ""; public _shotIntent: string = "";
  public _bowlType: string = ""; public _ballType: string = ""; public _releaseType: string = "";
  public _downLegs: boolean = false; public _bowlSide: string = ""; _isCD: boolean = false;
  UnComfortClk(id) {
    this.IsUncomfortable = true;
    this._unComfort = String(id.UncomfortID);
    this._beaten = "";
    this.IsBeaten = false;
  }

  BeatenClk(id) {
    this._beaten = String(id.BeatenID);
    this._unComfort = "";
    this.IsBeaten = true;
    this.IsUncomfortable = false;
  }

  FootMove(id) {
    this._footMove = String(id.FootMoveID);

  }

  ReleaseType(rel) {
    this._releaseType = String(rel.BallReleaseID);
  }
  ShotType(id) {
    this._shotType = String(id.ShotID);
  }

  BowlTypeClick(id) {
    this._bowlType = String(id.BowlTypeID);
    this._ballType = String(id.BallTypeID);
    this.BwShow=false;
  }
  ShotIntentClick(data) {
    this._shotType = String(data.ShotID);
    this._shotIntent = String(data.ShotIntent);
    this.ShotShow=false;
  }
  ShotTypeClick(ShotType){
    if(ShotType=="Dff"){
      this.ShotTypeMore = this.Deffensive; 
    }else{
      this.ShotTypeMore = this.Aggressive; 
    }
  }
  BowlTypeMoreClick(BowlType){ 
  if(BowlType=="Spin"){
        this.BowlTypeMore = this.BowlSpin; 
 
  }else{
    this.BowlTypeMore = this.BowlFast; 
 
  }
}
BowlViewClick(){ 
 this.BwShow=true;}
 ShotViewClick(){ 
  this.ShotShow=true;}
 
  
  bowlsideClick(val) {
    this._bowlSide = val;
  }
  clearPM() {
    if (this.isImpact && this.isStartBall) {
      this.clearImpact();
    } else if (this.isPitchmap && this.isStartBall) {
      this.PMLine = false;
      this.PMLineX = "";
      this.PMLineY = "";
    } else {
      this.clearImpact();
      this.PMLine = false;
      this.PMLineX = "";
      this.PMLineY = "";
    }

  }
  clearImpact() {
    this.ImpactLine = false;
    this.ImpactX = "";
    this.ImpactY = "";
  }

  clearWW() {
    this.WWLines.nativeElement.removeAttribute("x1");
    this.WWLines.nativeElement.removeAttribute("y1");
    this.WWLines.nativeElement.removeAttribute("x2");
    this.WWLines.nativeElement.removeAttribute("y2");
    this.WWX = "";
    this.WWY = "";
  }
  WWLine: boolean = true;
  WWX: string = "";
  WWY: string = "";
  WWRegion: string = "";
  wwClick(event, region) {
    //alert(region+","+event.offsetX+","+event.offsetY);
    //this.WWLine=true;
    if (this.isStartBall) {
      this.WWLines.nativeElement.setAttribute("x1", 140);
      this.WWLines.nativeElement.setAttribute("y1", 121);
      this.WWLines.nativeElement.setAttribute("x2", event.offsetX);
      this.WWLines.nativeElement.setAttribute("y2", event.offsetY);
      this.WWX = event.offsetX;
      this.WWY = event.offsetY;
      this.WWRegion = region;
    }
  }
  center(event) {
    alert(event.offsetX + "," + event.offsetY);
  }
  ImpactLine!: boolean;
  ImpX: string = "";
  ImpY: string = "";
  ImpLine: string = "";
  ImpHeight: string = "";
  ImpactClick(event, line, impact) {

    if (this.isStartBall) {
      this.ImpactLine = true;
      this.ImpactX = (32 + event.layerX + 7) + "px";
      this.ImpactY = (event.layerY - 6) + "px";
      this.ImpX = event.layerX;
      this.ImpY = event.layerY;
      this.ImpLine = line;
      this.ImpHeight = impact;

    }
  }

  EditBall(videoPlayer, tags) {
    if (tags.StartTime != "")
      videoPlayer.currentTime = tags.StartTime;
    this._startTime = tags.StartTime;
    this._endTime = tags.EndTime;
    this._videoTagId = tags.VideoTagID;
    this.StartBall = 'End Ball';
    this.isStartBall = true;
    this.service.VideoTaggingEngineCRUD({
      SessionID: String(tags.SessionID), VideoTagID: String(tags.VideoTagID), ActionFlag: "Edit", RefUserID: this.UserCode
    }).subscribe((data: any = []) => {
      this.SessionVideoTagDetails = data.SessionVideoTagDetails;
      var tags = data.SessionVideoTagDetails[0];
      if (tags.DownLegs == "true")
        this._downLegs = true;
      else
        this._downLegs = false;
      if (tags.CuttingDiagonal == "true")
        this._isCD = true;
      else
        this._isCD = false;
      this._footMove = tags.FootMoveID;
      this._bowlSide = tags.BowlSide;
      this.IsUncomfortable = tags.IsUncomfortable;
      this._unComfort = tags.UncomfortID;
      this.IsBeaten = tags.IsBeaten;
      this._beaten = tags.BeatenID;
      this._shotType = tags.ShotID;
      this._shotIntent = tags.ShotTypeID;
      this._releaseType = tags.BallReleaseID;
      this.BallSpeed = tags.BallSpeed;
      this.BallSpeedType = tags.BallSpeedType;
      this._bowlType = tags.BallVariation;
      this._ballType = tags.BowlTypeID;

      this.PMX = tags.PitchX;
      this.PMY = tags.PitchY;
      this.LineID = tags.LineID;
      this.LengthID = tags.LengthID;
      this.ImpX = tags.ImpactX;
      this.ImpY = tags.ImpactY;
      this.ImpLine = tags.ImpactLineID;
      this.ImpHeight = tags.ImpactLengthID;
      this.WWX = tags.WWX;
      this.WWY = tags.WWY;
      if (tags.WWXY != ",") {
        this.WWLine = true;
        this.WWLines.nativeElement.setAttribute("x1", 140);
        this.WWLines.nativeElement.setAttribute("y1", 121);
        this.WWLines.nativeElement.setAttribute("x2", Number(tags.WWX));
        this.WWLines.nativeElement.setAttribute("y2", Number(tags.WWY));
      }
      else {
        this.WWLine = false;
        this.WWLines.nativeElement.removeAttribute("x1");
        this.WWLines.nativeElement.removeAttribute("y1");
        this.WWLines.nativeElement.removeAttribute("x2");
        this.WWLines.nativeElement.removeAttribute("y2");
      }
      if (tags.PitchMapXY != ",") {
        this.PMLine = true;
        this.PMLineX = (22 + Number(tags.PitchX) + 4) + "px";
        this.PMLineY = (Number(tags.PitchY) - 6) + "px";
      }
      else {
        this.PMLine = false;
      }
      if (tags.ImpactXY != ",") {
        this.ImpactLine = true;
        this.ImpactX = (32 + Number(tags.ImpactX) + 7) + "px";
        this.ImpactY = (Number(tags.ImpactY) - 6) + "px";
      }
      else {
        this.ImpactLine = false;
      }
    });
    videoPlayer.pause();
  }
  // true

  clearBallDetails() {
    this.clearWW();
    this.clearPM();
    this._isCD = false;
    this._downLegs = false;
    this.BowlSide = "";
    this.isImpact = false;
    this.isPitchmap = true;
    this.IsUncomfortable = false;
    this.UncomfortID = "";
    this.IsBeaten = false;
    this.BeatenID = "";
    this._shotIntent = "";
    this._shotType = "";
    this._bowlType = "";
    this._ballType = "";
    this.BallReleaseID = "";
    this.BallSpeed = "";
    this._footMove = "";
    this._releaseType = "";
    this._videoTagId = "";
  }
  inside(point, vs) {
    var x = point[0], y = point[1];
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1];
      var xj = vs[j][0], yj = vs[j][1];

      var intersect = ((yi > y) != (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  };

}

export interface BallDetails {
  VideoTagID: string, SessionID: string, StartTime: string, EndTime: string, BowlSide: string
  , BallReleaseID: string, BowlTypeID: string, BallVariation: string, ShotTypeID: string, ShotID: string, FootMoveID: string
  , RegionID: string, LineID: string, LengthID: string
  , IsUncomfortable: string, UncomfortID: string, IsBeaten: string, BeatenID: string
  , PitchMapXY: string, PitchX: string, PitchY: string, ImpactXY: string, ImpactX: string, ImpactY: string
  , WWXY: string, WWX: string, WWY: string, DownLegs: string, CuttingDiagonal: string, BallSpeed: string, BallSpeedType: string
  , userID: string, ActionFlag: string
}
