<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container admin-dashboard-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">
                            <div class="col-md-12">

                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="main-tab">
                                        <a href="javascript:void(0);" class="select">Change Password</a>
                                    </div>
                                    <form [formGroup]="ChangePasswordForm" #cp="ngForm" (ngSubmit)="onSubmit()"> 
                                    <div class="athlete-db">
                                        <div class="main-tab-container schedules">
                                            <h5 class="txt-center">Update your password here..</h5>
                                            <div class="row">
                                                <div class="col-md-3"></div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <mat-form-field class="md-block "
                                                                appearance="outline"
                                                                floatLabel="always">
                                                                <mat-label>Old Password</mat-label>
                                                                <input
                                                                    [type]="OldPasswordstatus ? 'text' : 'password'"
                                                                    matInput required maxlength="10" 
                                                                    [(ngModel)]="OldPassword"
                                                                    formControlName="n_OldPassword"
                                                                    aria-autocomplete="list" > 
                                                                <div class="pwd-toggle"
                                                                    (click)="clickOLD()"
                                                                    [ngClass]="OldPasswordstatus ? 'show' : 'hide'">
                                                                </div>
                                                            </mat-form-field>
                                                            <div *ngIf="cp.submitted  && ChangePasswordForm.controls.n_OldPassword.invalid "
                                                                class="alert alert-danger "> 
                                                                <div *ngIf="ChangePasswordForm.controls.n_OldPassword.errors.required">
                                                                    Old Password is required.</div>
                                                                 <!-- <div *ngIf="this.ChangePasswordForm.controls.n_OldPassword.errors.minlength">
                                                                    Must be at least 8 characters long. </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <mat-form-field class="md-block "
                                                                appearance="outline"
                                                                floatLabel="always">
                                                                <mat-label>New Password</mat-label>
                                                                <input
                                                                    [type]="NewPasswordstatus ? 'text' : 'password'"
                                                                    matInput required maxlength="10" 
                                                                    [(ngModel)]="NewPassword"
                                                                    formControlName="n_NewPassword"
                                                                    aria-autocomplete="list">
                                                                <div class="pwd-toggle"
                                                                    (click)="clickNew()"
                                                                    [ngClass]="NewPasswordstatus ? 'show' : 'hide'">
                                                                </div>
                                                            </mat-form-field>
                                                            <div *ngIf="cp.submitted && ChangePasswordForm.controls.n_NewPassword.invalid"
                                                                class="alert alert-danger">
                                                                <div *ngIf="ChangePasswordForm.controls.n_NewPassword.errors.required">
                                                                    New Password is required.</div>
                                                                <!-- <div *ngIf="this.ChangePasswordForm.controls.n_NewPassword.errors.minlength">
                                                                    Must be at least 8 characters long. </div>     -->
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <mat-form-field class="md-block "
                                                                appearance="outline"
                                                                floatLabel="always">
                                                                <mat-label>Confirm Password</mat-label>
                                                                <input
                                                                    [type]="ConfirmPasswordstatus ? 'text' : 'password'"
                                                                    matInput required  maxlength="10" 
                                                                    [(ngModel)]="ConfirmPassword"  
                                                                    formControlName="n_ConfirmPassword"
                                                                    aria-autocomplete="list">
                                                                <div class="pwd-toggle"
                                                                    (click)="clickConfirm()"
                                                                    [ngClass]="ConfirmPasswordstatus ? 'show' : 'hide'">
                                                                </div>
                                                            </mat-form-field>
                                                            <div *ngIf="cp.submitted  && ChangePasswordForm.controls.n_ConfirmPassword.invalid "
                                                                class="alert alert-danger">
                                                                <div *ngIf="ChangePasswordForm.controls.n_ConfirmPassword.errors.required">
                                                                    Confirm Password is required.</div>
                                                               <!-- <div *ngIf="this.ChangePasswordForm.controls.n_ConfirmPassword.errors.minlength">
                                                                        Must be at least 8 characters long.  </div>         -->
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3"></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="txt-center mb20">
                                                        <button mat-raised-button
                                                            color="primary">Save</button>
                                                        <button mat-raised-button color="primary"
                                                            class="ml10"
                                                            (click)="ClearPasswords()">Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Change Password</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Change Password</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Change Password</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
            (click)="isWarningAlert=false" >Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isConfirmAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Change Password</h4>
                <p>{{confirmAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                 (click)="isConfirmAlert=false" >Ok</button>
        </div>
    </div>
</div>