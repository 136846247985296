import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { HomeLayoutComponent, SessionData } from '../../layout/home-layout.component';
declare var $: any

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  ChangePasswordForm:FormGroup;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { }

 public UserCode: string = "";
 public UserProfile: string = "";

 submitted = false;
 public profileForm:boolean=false; public profileTbl:boolean=true; public IsChangePassword:boolean=false;

 public ProfileViewList:any[];

 public isShowLoader:boolean;
 public isSuccessAlert:boolean = false; public successAlert:string;
 public isErrorAlert:boolean = false; public errorAlert:string;
 public isWarningAlert:boolean = false; public warningAlert:string;
 public isConfirmAlert:boolean = false; public confirmAlert:string;

 public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];
 public IsUpload:boolean = false;

 @Input()
 public OldPassword:string; public NewPassword:string; public ConfirmPassword:string;
 public UserName:string; public UserAge:string; public UserAssociation:string; 
 public PlayerRole:string; public BattingStyle:string; public BowlingSpec:string; public ProfileImage:string;
 public UserFirstName:string;  public UserLastName:string;  public Level:string;
 public BattingOrder:string;  public BowlingArm:Number;  public IsWicketKeeper:boolean;
 public Gender:string;  public Height:string;  public Weight:string;  public Goal:string;
 public MobileNumber:string;  public EmailID:string; public DOB:string; public BowlingTypeID:Number;
 public PlayerRoleID:string; public BattingStyleID:string; public BowlingSpecID:Number; 

 public ImgPlayerRole:string; public ImgBattingStyle:string; public ImgBowlingSpc:string;

 public minDate: Date; public maxDate: Date;

public lstMemberLevel:any[];  public lstBattingType:any[]; public lstBattingOrder:any[]; public lstPlayerRole:any[];
public lstBowlingStyle:any[]; public lstBowlingType:any[]; public lstBowlingSpec:any[];  public BowlingSpList:any[];
public lstAthleteEdit:any[];

public filOfcRoleUpdate: any = []; public croppedImage: string;
public imageChangedEvent: any = '';
public imageChangedType: string = '';
public displayImageCrop: string = 'none';

public AthleteVideoCountList:any[];
public TotalVideos:string; public TotalUploads:string; public TotalReviewed:string;
public id:number; // id: any 

ngOnInit(): void {
  this.utility.pageLoadScript();
  this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
  // this.UserProfile = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).ProfileImage);
  this.ClearPasswords();
  this.InitProfileLoad();
}

showProfileform(id: any ){
  this.profileTbl=true;
  this.profileForm=false;
  this.IsChangePassword = false;
 // this.ngOnInit();
  this.ClearPasswords();
  this.IsUpload = false;
  this.id = id;
}

showProfile(id: any ){
  this.profileTbl=false;
  this.profileForm=true;
  this.IsChangePassword = false;
  this.MasterDataLoad();
  this.IsUpload = true;
  this.id = id;
}

showChangePassword(id: any ){
  this.profileTbl = false;
  this.profileForm = false;
  this.IsChangePassword = true;
  this.IsUpload = false;
  this.id = id;
}

InitProfileLoad(){
  this.isShowLoader = true;
 //  this.id = 1;
   this.id = SessionData;

  if ( this.id  == 1){
    this.profileTbl = true;
    this.profileForm = false;
    this.IsChangePassword = false;
    this.IsUpload = false;
  }
  else {
    this.profileTbl = false;
    this.profileForm = false;
    this.IsChangePassword = true;
    this.IsUpload = false;
   }
  this.ChangePasswordForm = this.formBuilder.group({
    n_OldPassword:['', Validators.required ],
    n_NewPassword:['', Validators.required ], 
    n_ConfirmPassword:['', Validators.required ],
   });

  var minCurrentDate = new Date();
  var maxNewDate = new Date();
  this.minDate = minCurrentDate;
  this.maxDate = new Date(maxNewDate.getFullYear(), maxNewDate.getMonth(), maxNewDate.getDate());
  var ProfileLoad = { ActionFlag: "View", UserID: this.UserCode };
  this.service.AtheleteRegistration(ProfileLoad).subscribe((ret: any = []) => {
    let BTStatus = String(ret.Status);
    if (BTStatus == "true") {
      this.ProfileViewList =  ret.RowDetails;
      this.ProfileImage  = this.ProfileViewList[0].ProfileImage;
      this.UserName = this.ProfileViewList[0].DisplayName;
      this.UserAge = this.ProfileViewList[0].Age;
      this.UserAssociation = this.ProfileViewList[0].AssociationName;
      this.PlayerRole = this.ProfileViewList[0].PlayerRoleName;
      this.BattingStyle = this.ProfileViewList[0].BattingType;
      this.BowlingSpec = this.ProfileViewList[0].BowlingSpecialization;
      this.UserFirstName = this.ProfileViewList[0].FirstName;
      this.UserLastName = this.ProfileViewList[0].LastName;
      this.Level = this.ProfileViewList[0].MemberLevelName;
      this.BattingOrder = this.ProfileViewList[0].BatOrderName;
      this.BowlingArm = this.ProfileViewList[0].BowlingStyle;
      this.IsWicketKeeper = this.ProfileViewList[0].IsWicketKeeper;
      this.Gender = this.ProfileViewList[0].GenderName;
      this.Height = this.ProfileViewList[0].Height;
      this.Weight = this.ProfileViewList[0].Weight;
      this.Goal = this.ProfileViewList[0].Goal;
      this.EmailID = this.ProfileViewList[0].LoginEmail;
      this.MobileNumber = this.ProfileViewList[0].LoginMobile;
      this.DOB = this.ProfileViewList[0].DateOfBirth;

      // this.service._userProfile = String(this.ProfileImage);
      // String(sessionStorage.setItem('ProfileImage', this.ProfileImage));

      if(this.ProfileViewList[0].PlayerRoleName == "Batsman"){
        this.ImgPlayerRole = "ico-bat02" }  
        else if (this.ProfileViewList[0].PlayerRoleName == "Bowler") {
         this.ImgPlayerRole = "ico-bowler02" }  
         else if (this.ProfileViewList[0].PlayerRoleName == "Wicket Keeper") {
           this.ImgPlayerRole = "ico-bat-wk" }  
           else if(this.ProfileViewList[0].PlayerRoleName == "All Rounder") {
             this.ImgPlayerRole = "ico-allrounder02" }  
       
       if(this.ProfileViewList[0].BattingType == "Left Hand Batsman"){
          this.ImgBattingStyle = "ico-lefthandbat" }  
            else if (this.ProfileViewList[0].BattingType == "Right Hand Batsman") {
              this.ImgBattingStyle = "ico-righthandbat" }         

       if(this.BowlingSpec == "Left Arm Chinnaman Spinner" || this.BowlingSpec == "Left Arm Chinnaman" ){
                this.ImgBowlingSpc = "ico-chinnaman" }  
          else if (this.BowlingSpec == "Left Arm Orthodox" || this.BowlingSpec == "Left Arm Orthodox Spinner" ) {
                    this.ImgBowlingSpc = "ico-orthodox" }       
          else if (this.BowlingSpec == "Left Arm Medium" || this.BowlingSpec == "Left Arm Fast" 
                       || this.BowlingSpec == "Left Arm Medium Fast" || this.BowlingSpec == "Left Arm Fast Medium" ) {
                    this.ImgBowlingSpc = "ico-leftarmfast" } 
          else if (this.BowlingSpec == "Right Arm Leg Spin"  ) {
                    this.ImgBowlingSpc = "ico-rightlegspin" }  
          else if (this.BowlingSpec == "Right Arm Off Spin"  ) {
                     this.ImgBowlingSpc = "ico-rightoffspin" } 
          else if (this.BowlingSpec == "Right Arm Medium" || this.BowlingSpec == "Right Arm Fast" 
                     || this.BowlingSpec == "Right Arm Medium Fast" || this.BowlingSpec == "Right Arm Fast Medium" ) {
                  this.ImgBowlingSpc = "ico-rightarmfast" }         

      this.AthleteVideoCountList   =  ret.AthleteVideoCountList;
      this.TotalVideos   = this.AthleteVideoCountList[0].TotalVideos;
      this.TotalUploads  = this.AthleteVideoCountList[0].UploadCount;
      this.TotalReviewed = this.AthleteVideoCountList[0].ReviewCount;

      this.ClearPasswords();  
      this.isShowLoader = false;

    }
    else {
      this.isShowLoader = false;
      this.isErrorAlert = true;
      this.errorAlert = '\n Data Load Failed..!! \n';
      return;
    }
  })
}

MasterDataLoad(){
  this.isShowLoader = true;
  var ProfileLoad = { ActionFlag: "Load", UserID: this.UserCode };
  this.service.AtheleticLoad(ProfileLoad).subscribe((ret: any = []) => {
    let BTStatus = String(ret.Status);
    if (BTStatus == "true") {
        this.lstMemberLevel  = ret.MemberLevelList;
        this.lstBattingType  = ret.BattingTypeList;
        this.lstBattingOrder = ret.BattingOrderList;
        this.lstPlayerRole   = ret.PlayerRoleList;
        this.lstBowlingStyle = ret.BowlingStyleList;
        this.lstBowlingType  = ret.BowlingTypeList;
        this.lstBowlingSpec  = ret.BowlingSpecTypeList;
        
        var ProfileLoad = { ActionFlag: "Edit", UserID: this.UserCode }; 
        this.service.AtheleteRegistration(ProfileLoad).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
              this.lstAthleteEdit  =  ret.RowDetails;
              if( this.Gender == "Men" ) { this.Gender = "1" }
              else { this.Gender = "2" }
                this.UserName = this.lstAthleteEdit[0].DisplayName;
                this.Level = this.lstAthleteEdit[0].LevelID;
                this.PlayerRoleID = this.lstAthleteEdit[0].PlayerRoleID;
                this.BattingStyleID = this.lstAthleteEdit[0].BattingTypeID;
                this.BattingOrder = this.lstAthleteEdit[0].BattingOrderID;

                this.BowlingArm = Number(this.lstAthleteEdit[0].BowlingStyleID);
                this.BowlingTypeID = Number(this.lstAthleteEdit[0].BowlingTypeID);
                // this.BowlingSpList = this.lstBowlingSpec.filter(s => s.BowlingTypeID === this.lstAthleteEdit[0].BowlingTypeID);
                this.BowlingSpecID = Number(this.lstAthleteEdit[0].BowlingSpecID);

                this.BowlingStyleChange(this.lstAthleteEdit[0].BowlingTypeID);
                // this.BowlingStyleChange(this.lstAthleteEdit[0].BowlingStyleID);

                this.IsWicketKeeper = this.lstAthleteEdit[0].IsWicketKeeper;
                this.Height = this.lstAthleteEdit[0].Height;
                this.Weight = this.lstAthleteEdit[0].Weight;
                this.Goal = this.lstAthleteEdit[0].Goal;
              this.isShowLoader = false;
          }
          else {
            this.isShowLoader = false;
            this.isErrorAlert = true;
            this.errorAlert = ret.Message;
            return;
          }
        })
      this.isShowLoader = false;
    }
    else {
      this.isShowLoader = false;
      this.isErrorAlert = true;
      this.errorAlert = ret.Message;
      return;
    }
  })
}

BowlingStyleChange(value) { 
  this.BowlingSpList = this.lstBowlingSpec.filter(s => s.BowlingTypeID === Number(value));
    if (value == "2") {
    this.BowlingSpList = this.BowlingSpList.filter(b => b.BowlingStyleName === Number(this.BowlingArm));
  }
}
  
ProfileUpdate(){
  if ((this.BowlingArm) == 0 ) {
    this.UpdateProfileDetails();
  }
  else if ((this.BowlingArm) != 0 && (((this.BowlingTypeID) == 0) || ((this.BowlingSpecID) == 0))) {
    this.isWarningAlert = true;
    this.warningAlert = "Please choose Bowling Type & Bowling Specification...";
  }
  else {
    this.UpdateProfileDetails();
  }
}

UpdateProfileDetails(){
  this.isShowLoader = true;
  let isWkt: string;
  var profileImage = "";
  if (this.IsWicketKeeper == true) { isWkt = "1" }
  else if (this.IsWicketKeeper == false) { isWkt = "0" }

  if (this.ImageDetails[0].data != "") {
    this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
            profileImage = ret.name;
            var ProfileLoad = { ActionFlag: "Register", UserID:this.UserCode , PlayerImage:profileImage , 
                    DisplayName:this.UserName, LevelID:String(this.Level), BattingTypeID:String(this.BattingStyleID),
                    BattingOrderID:String(this.BattingOrder), PlayerRoleID:String(this.PlayerRoleID),
                        BowlingStyleID:String(this.BowlingArm),
                        BowlingTypeID:String(this.BowlingTypeID), 
                        BowlingSpecID:String(this.BowlingSpecID), 
                    IsWicketKeeper:String(isWkt), Height:String(this.Height), Weight:String(this.Weight), Goal:String(this.Goal) };            
                this.service.AtheleteRegistration(ProfileLoad).subscribe((ret: any = []) => {
                let BTStatus = String(ret.Status);
                if (BTStatus == "true") {
                this.isSuccessAlert = true;
                this.successAlert = 'Updated successfully...!!';
                this.isShowLoader = false;
                this.ngOnInit();
              }
              else {  
                this.isShowLoader = false; 
                this.isErrorAlert = true;
                this.errorAlert = ret.Message;
                return;
              } 
          })
        }
    })
  }
  else{
          var ProfileLoad = { ActionFlag: "Register", UserID:this.UserCode , PlayerImage:"", 
              DisplayName:this.UserName, LevelID:String(this.Level), BattingTypeID:String(this.BattingStyleID),
              BattingOrderID:String(this.BattingOrder), PlayerRoleID:String(this.PlayerRoleID),
              BowlingStyleID:String(this.BowlingArm), BowlingTypeID:String(this.BowlingTypeID), BowlingSpecID:String(this.BowlingSpecID), 
              IsWicketKeeper:String(isWkt), Height:String(this.Height), Weight:String(this.Weight), Goal:String(this.Goal) };            
          this.service.AtheleteRegistration(ProfileLoad).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
          this.isSuccessAlert = true;
          this.successAlert = 'Updated successfully...!!';
          this.isShowLoader = false;
          this.ngOnInit();
          }
          else {  
          this.isShowLoader = false; 
          this.isErrorAlert = true;
          this.errorAlert = ret.Message;
          return;
          } 
      })
  }
}


OldPasswordstatus: boolean = false;
clickOLD(){
    this.OldPasswordstatus = !this.OldPasswordstatus;       
}

NewPasswordstatus: boolean = false;
clickNew(){
    this.NewPasswordstatus = !this.NewPasswordstatus;       
}

ConfirmPasswordstatus: boolean = false;
clickConfirm(){
    this.ConfirmPasswordstatus = !this.ConfirmPasswordstatus;       
}

onSubmit() {
  this.submitted = true;
  if (this.ChangePasswordForm.invalid) {
    return;
  }
  else {
    this.ChangePassWord();
  }
}

ChangePassWord(){
  if ( this.OldPassword != this.NewPassword && this.OldPassword != this.ConfirmPassword ){
      if ( this.NewPassword == this.ConfirmPassword  ){
        this.isShowLoader = true;
        var Changepassword = {
          OldPassword: this.OldPassword, Password: this.NewPassword , ConfirmPassword:this.ConfirmPassword, 
          ActionFlag: "Validate", UserID: this.UserCode  };
        this.service.ChangePassword(Changepassword).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
            this.isSuccessAlert = true;
            this.successAlert = "Password Changed Successfully ..!!" ;
            this.isShowLoader = false;
            //this.ClearPasswords();
             this.ngOnInit();
            // this.showChangePassword(2);
          }
          else {
            this.isShowLoader = false;
            this.isErrorAlert = true;
            this.errorAlert   = ret.Message;
            return;
          }
        })
      }
      else{
        this.isErrorAlert = true;
        this.errorAlert = "New-password & Confirm-Password Must be same ..!!";
      }
   }
   else{
      this.isErrorAlert = true;
      this.errorAlert = "Old-password should not same with New-password (OR) Confirm-Password ..!!";
   }
}

ClearPasswords(){
  this.OldPassword = "";  this.NewPassword = "";  this.ConfirmPassword = "";
}

onPlayerProfileChange(event,type) {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.ProfileImage = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.ProfileImage, name: file.type });
    //   };
    // }
  const reader = new FileReader();
  this.imageChangedEvent = event;
  this.imageChangedType = type;
  $('#modalcrop').modal('show');
}

imageCropped(event: ImageCroppedEvent) {
  this.croppedImage = event.base64;
}

imageLoaded() {
  // show cropper
}

cropperReady() {
  // cropper ready
}

loadImageFailed() {
  // show message 
}

Applycropper() {
  this.ProfileImage = this.croppedImage;
  this.ImageDetails = [];
  this.ImageDetails.push({ data: this.ProfileImage, name: 'image/png' });
  $('#modalcrop').modal('hide');
  // this.ProfileImage = "";
}



}


export interface ImageDetails { data: string, name: string }