import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { SortPipe } from '../../pipes/sort.pipe'

@Component({
  selector: 'app-finance-dashboard',
  templateUrl: './finance-dashboard.component.html',
  styleUrls: ['./finance-dashboard.component.css'],
  providers: [SortPipe]
})
export class FinanceDashboardComponent implements OnInit {
  UserCode: string = "";
  PlayersbyPackage!: any;
  PlayersbyDuration: any;
  UsersbyMonth: any;
  TotalUsers: any;
  constructor(private utility: UtilitiesService, public service: MastersServiceService, public sort: SortPipe) { }

  doughnutChartLabels: any[] = ['Premium', 'Standard', 'Basic'];
  doughnutChartData: any = [53, 30, 17];
  doughnutChartType: ChartType = 'pie';

  // public doughnutChartColors: any[] = [
  //   { backgroundColor: ['#dfba7e', '#00193e', '#000' ]} 
  // ]


  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  public usersbyMonthChartLabels: any = [];
  public usersbyMonthChartData: any = [];
  revenuebyMonthData: any = [];
  revenuebyMonthLabels: any = [];
  userbyPackageData: any = [];
  userbyPackageLabels: any = [];
  userbyDurationData: any = [];
  userbyDurationLabels: any = [];

  public barChartPlugins = [];

  public UBMChartData: any;
  public UBPChartData: any;
  public RBMChartData: any;
  public UBDChartData: any;



  public barChartLabels2: any[] = ['2021'];
  public barChartType2: ChartType = 'horizontalBar';
  public barChartLegend2 = true;
  public barChartPlugins2 = [];

  public barChartData2: any[] = [
    { data: [65], label: 'Bat', stack: 'a' },
    { data: [20], label: 'Bowl', stack: 'a' },
    { data: [75], label: 'WK', stack: 'a' },
    { data: [30], label: 'AR', stack: 'a' }
  ];

  public barChartOptions3: ChartOptions = {
    responsive: true,
  };
  public barChartLabels3: any[] = ['2021', '2020', '2019', '2018', '2017'];
  public barChartType3: ChartType = 'bar';
  public barChartLegend3 = true;
  public barChartPlugins3 = [];

  public barChartData3: any[] = [
    { data: [165], label: 'Male', stack: 'a' },
    { data: [40], label: 'Female', stack: 'a' }
  ];

  ngOnInit(): void {
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    this.LoadData();
  }


  LoadData() {
    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: { ActionFlag: "Dashboard", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      this.PlayersbyPackage = ret.PlayersbyPackage;
      this.PlayersbyDuration = ret.PlayersbyDuration;
      this.UsersbyMonth = ret.UsersbyMonth;
      this.TotalUsers = ret.TotalUsers[0];
      this.UsersbyMonth.forEach(element => {
        this.usersbyMonthChartLabels.push(element.UserMonth);
        this.usersbyMonthChartData.push(element.Users);
        this.revenuebyMonthData.push(element.AmountWithGST);
        
      });
      this.UBMChartData = [
        { data: this.usersbyMonthChartData, Label: this.usersbyMonthChartLabels }
      ];


      this.PlayersbyPackage.forEach(element => {
        this.userbyPackageData.push(element.TotalUsers);
        this.userbyPackageLabels.push(element.PackageName);
      });
      this.UBPChartData = this.userbyPackageData;

      this.revenuebyMonthLabels=this.usersbyMonthChartLabels;
      this.RBMChartData = [
        { data: this.revenuebyMonthData, Label: this.revenuebyMonthLabels }
      ];

      this.PlayersbyDuration.forEach(element => {
        this.userbyDurationData.push(element.TotalUsers);
        this.userbyDurationLabels.push(element.DurationName);
      });
      this.UBDChartData = this.userbyDurationData;
    });
  }
}
