<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
  </div>

  <div class="page-container admin-dashboard-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center"></div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card-profile-container box-shadow br-5">  
                                    <div class="card-profile-header tr-5">
                                        <div class="profile-picture">  
                                            <img src="{{ProfileImage}}" alt="Profile Picture" 
                                              onerror="this.src='assets/images/no_user.png';" >
                                        </div>
                                    </div>
                                    <div class="card-profile-body">
                                        <div class="txt-center">
                                            <div class="profile-name" style="text-transform:capitalize;"  >{{ProfileDisplayName}}</div>
                                            <div class="profile-age"  style="text-transform:capitalize;"  >{{Age}}</div>
                                            <div class="profile-association"  style="text-transform:capitalize;"  >{{AssociationName}}</div>
                                            <div class="profile-association"  style="text-transform:capitalize;"  >{{Proficiency}}</div>
                                            <div class="profile-association"  style="text-transform:capitalize;"  >{{Level}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="main-tab" rel="athlete-db">
                                        <a href="javascript:void(0);" rel="schedules" class="select">Schedules</a>
                                    </div>
                                    <div class="athlete-db">
                                        <div class="main-tab-container schedules" style="display: block;" >
                                            <full-calendar *ngIf='calendarVisible' [options]='calendarOptions' ></full-calendar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="overlay" *ngIf="isPlanner">
    <div class="planner">
        <div class="planner-header">New Event <span class="clr-popup" (click)="hidePlanner();">x</span></div>
        <div class="planner-body">
            <div class="row"> 
                    <div class="col-md-3">
                        <div class="title">Event Details</div>
                        <div class="event-popup search-container">
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="md-block search-txt" appearance="outline"
                                        floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0"
                                                fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet"
                                                focusable="false">
                                                <path opacity=".3"
                                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                </path>
                                                <path
                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                </path>
                                            </svg></mat-icon>
                                        <input matInput placeholder="Event Name" [(ngModel)]="EventName">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block search-txt"
                                        floatLabel="always">
                                        <mat-date-range-input [rangePicker]="range">
                                            <input matStartDate placeholder="Start date" [(ngModel)]="StartDate">
                                            <input matEndDate placeholder="End date" [(ngModel)]="EndDate">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="range"></mat-datepicker-toggle>
                                        <mat-date-range-picker #range></mat-date-range-picker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg x="0px" y="0px" width="24px" height="24px" viewBox="244 244 24 24" enable-background="new 244 244 24 24" xml:space="preserve"> <g> <path fill="#97A6BA" d="M256,267.292c-6.226,0-11.292-5.065-11.292-11.292s5.065-11.292,11.292-11.292s11.292,5.065,11.292,11.292 S262.226,267.292,256,267.292z"/>	<path fill="#E0E4EA" d="M256,246.12c-5.448,0-9.88,4.432-9.88,9.88s4.432,9.88,9.88,9.88s9.88-4.432,9.88-9.88 S261.448,246.12,256,246.12z"/> <path fill="#97A6BA" d="M260.94,262.116c-0.181,0-0.361-0.069-0.499-0.207l-4.94-4.94c-0.133-0.133-0.207-0.312-0.207-0.499v-6.587 c0-0.39,0.316-0.706,0.706-0.706s0.706,0.316,0.706,0.706v6.294l4.733,4.733c0.276,0.276,0.276,0.723,0,0.998 C261.301,262.048,261.121,262.116,260.94,262.116z"/> </g> </svg>
                                        </mat-icon>
                                        <input matInput [ngxTimepicker]="picker" [format]="24"  [(ngModel)]="StartTime">
                                        <ngx-material-timepicker #picker></ngx-material-timepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg x="0px" y="0px" width="24px" height="24px" viewBox="244 244 24 24" enable-background="new 244 244 24 24" xml:space="preserve"> <g> <path fill="#97A6BA" d="M256,267.292c-6.226,0-11.292-5.065-11.292-11.292s5.065-11.292,11.292-11.292s11.292,5.065,11.292,11.292 S262.226,267.292,256,267.292z"/>	<path fill="#E0E4EA" d="M256,246.12c-5.448,0-9.88,4.432-9.88,9.88s4.432,9.88,9.88,9.88s9.88-4.432,9.88-9.88 S261.448,246.12,256,246.12z"/> <path fill="#97A6BA" d="M260.94,262.116c-0.181,0-0.361-0.069-0.499-0.207l-4.94-4.94c-0.133-0.133-0.207-0.312-0.207-0.499v-6.587 c0-0.39,0.316-0.706,0.706-0.706s0.706,0.316,0.706,0.706v6.294l4.733,4.733c0.276,0.276,0.276,0.723,0,0.998 C261.301,262.048,261.121,262.116,260.94,262.116z"/> </g> </svg>
                                        </mat-icon>
                                        <input matInput [ngxTimepicker]="pickers" [format]="24" [(ngModel)]="EndTime">
                                        <ngx-material-timepicker #pickers ></ngx-material-timepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                        floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon">
                                            <svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path>
                                                <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path>
                                             </svg>
                                        </mat-icon>
                                        <mat-select placeholder="Event Type" name="eventtype" [(ngModel)]="EventTypeID">
                                            <mat-option [value]="type.EventTypeID" *ngFor="let type of EventTypeList"
                                                style="text-transform:capitalize;">
                                                {{type.EventName}}</mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                        floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon">
                                            <svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path>
                                                <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path>
                                             </svg>
                                        </mat-icon>
                                        <mat-select placeholder="Event Status" name="eventstatus" [(ngModel)]="EventStatusID">
                                            <mat-option [value]="status.EventStatusID"
                                                *ngFor="let status of EventStatusList"
                                                style="text-transform:capitalize;">
                                                {{status.EventStatusName}}</mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="md-block search-txt" appearance="outline"
                                        floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920"
                                                fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet"
                                                focusable="false">
                                                <path opacity=".3"
                                                    d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                </path>
                                                <path
                                                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                </path>
                                                <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                            </svg></mat-icon>
                                        <input matInput placeholder="Venue"  [(ngModel)]="Venue">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="md-block search-txt" appearance="outline"
                                        floatLabel="always">
                                        <textarea matInput placeholder="Enter comments..." [(ngModel)]="Comments"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="title">Select Participants</div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="event-popup">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768"
                                                        y="0" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3"
                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                        </path>
                                                        <path
                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Select Play Group</mat-label>
                                                <mat-select placeholder="Player Group" name="playergroup"  
                                                    [(ngModel)]="PlayGroupID" (ngModelChange)="MemberTypeChange()">
                                                    <mat-option [value]="group.PlayGroupID"
                                                        *ngFor="let group of PlayerGroupList"
                                                        style="text-transform:capitalize;">
                                                        {{group.PlayGroupName}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-12">
                                            <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768"
                                                        y="0" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3"
                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                        </path>
                                                        <path
                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Participants Type</mat-label>
                                                <mat-select placeholder="Participants Type" name="membertype"
                                                    [(ngModel)]="MemberTypeID" (ngModelChange)="MemberTypeChange()">
                                                    <mat-option [value]="mtype.MemberTypeID"
                                                        *ngFor="let mtype of MembetTypeList"
                                                        style="text-transform:capitalize;">
                                                        {{mtype.MemberName}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="participant-list">
                                    <div class="list-header search-container">
                                        <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon">
                                                <a href="javascript:void(0);">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 373.12 373.12" width="24" height="24">
                                                        <path
                                                            d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                    </svg>
                                                </a>
                                            </mat-icon>
                                            <input matInput placeholder="Participant List"  [(ngModel)]="SearchParticipant" >
                                        </mat-form-field>
                                    </div>
                                    <div class="list-body custom-height">
                                        <ngx-datatable class="material" [rows]="MembersList|filter:SearchParticipant" [columnMode]="'force'"
                                            [headerHeight]="0" rowHeight="30" [selected]="selected"
                                            [selectionType]="checkbox">

                                            <ngx-datatable-column name="Name" prop="DisplayName" [width]="200">
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [width]="30" [sortable]="false"
                                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">

                                                <ng-template ngx-datatable-cell-template let-row="row" let-value="value"
                                                    let-isSelected="isSelected"
                                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                                    <input type="checkbox" [checked]="row.Status===1"
                                                        (click)="onAddclick(row,$event)" />

                                                </ng-template>
                                            </ngx-datatable-column>


                                        </ngx-datatable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="title">Participants</div>
                        <div class="event-popup">
                            <div class="col-md-12">
                                <div class="participant-list">
                                    <div class="list-header search-container">
                                        <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon">
                                                <a href="javascript:void(0);">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 373.12 373.12" width="24" height="24">
                                                        <path
                                                            d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                    </svg>
                                                </a>
                                            </mat-icon>
                                            <input matInput placeholder="Search..." [(ngModel)]="SearchSelParticipant">
                                        </mat-form-field>
                                    </div>
                                    <div class="list-body custom-height2">
                                        <div class="tbl-planner">
                                            <table>
                                                <tr class="row01" *ngFor="let mem of lstParticipants|filter:SearchSelParticipant">
                                                    <td style="width:65%;">{{mem.DisplayName}}</td>
                                                    <td style="width:25%;">{{mem.MemberName}}</td>
                                                    <td style="width:10%;"><span class="remove-list" (click)="removeParticipant(mem,$event)"></span></td>
                                                </tr>
                                                 
                                            </table>
                                        </div>
                                        <!-- <ul>
                                            <li *ngFor="let mem of lstParticipants" > {{mem.DisplayName}} - <span>{{mem.MemberName}}</span><span class="remove-list"></span></li> 
                                        </ul> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
            </div>
        </div>
        <div class="planner-footer txt-center">
            <button mat-raised-button color="primary" class="mr10" (click)="SaveEvent()">{{ActionFlag}}</button>
            <button mat-raised-button color="primary" class="mr10" (click)="ClearEventDetails()">Clear</button>
            <button mat-raised-button color="primary" (click)="hidePlanner();">Cancel</button>
        </div>
    </div>
</div>

<!-- Alerts -->
<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Dashboard</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf = "isErrorAlert" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
                <div  class="alcenter" > 
                 <h4>Dashboard</h4>
                <p>{{errorAlert}}</p> 
               </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"   (click)="isErrorAlert=false" >Ok</button>
        </div>
   </div>
</div> 
