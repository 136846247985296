import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile-change-password',
  templateUrl: './profile-change-password.component.html',
  styleUrls: ['./profile-change-password.component.css']
})
export class ProfileChangePasswordComponent implements OnInit {
  ChangePasswordForm:FormGroup;
  submitted = false;
  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { }

    public UserCode: string = "";

    public isShowLoader: boolean;
    public isSuccessAlert: boolean = false; public successAlert: string;
    public isErrorAlert: boolean = false; public errorAlert: string;
    public isWarningAlert: boolean = false; public warningAlert: string;
    public isConfirmAlert: boolean = false; public confirmAlert: string;

    @Input()
    public OldPassword:string; public NewPassword:string; public ConfirmPassword:string;
   
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.ChangePasswordForm = this.formBuilder.group({
        n_OldPassword:['', Validators.required ],
        n_NewPassword:['', Validators.required ],
        n_ConfirmPassword:['', Validators.required ],
    });
  }

  OldPasswordstatus: boolean = false;
  clickOLD(){
      this.OldPasswordstatus = !this.OldPasswordstatus;       
  }

  NewPasswordstatus: boolean = false;
  clickNew(){
      this.NewPasswordstatus = !this.NewPasswordstatus;       
  }

  ConfirmPasswordstatus: boolean = false;
  clickConfirm(){
      this.ConfirmPasswordstatus = !this.ConfirmPasswordstatus;       
  }

  onSubmit() {
    this.submitted = true;
    if (this.ChangePasswordForm.invalid) {
      return;
    }
    else {
      this.ChangePassWord();
    }
  }
  
  
  ChangePassWord(){
    if ( this.OldPassword != this.NewPassword && this.OldPassword != this.ConfirmPassword ){
        if ( this.NewPassword == this.ConfirmPassword  ){
          this.isShowLoader = true;
          var Changepassword = {
            OldPassword: this.OldPassword, Password: this.NewPassword , ConfirmPassword:this.ConfirmPassword, 
            ActionFlag: "Validate", UserID: this.UserCode  };
          this.service.ChangePassword(Changepassword).subscribe((ret: any = []) => {
            let BTStatus = String(ret.Status);
            if (BTStatus == "true") {
              this.isSuccessAlert = true;
              this.successAlert = "Password Changed Successfully ..!!"  
              this.isShowLoader = false;
              this.ClearPasswords();
            }
            else {
              this.isShowLoader = false;
              this.isErrorAlert = true;
              this.errorAlert = ret.Message;
              return;
            }
          })
        }
        else{
          this.isErrorAlert = true;
          this.errorAlert = "New-password & Confirm-Password Must be same ..!!";
        }
     }
     else{
        this.isErrorAlert = true;
        this.errorAlert = "Old Password not match..!!";
     }
  }
  
  
  
  ClearPasswords(){
    this.OldPassword = "";  this.NewPassword = "";  this.ConfirmPassword = "";
  }
  


}
