import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import internal from 'stream';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { I } from '@angular/cdk/keycodes';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


export interface User {
  name: string;
}
@Component({
  selector: 'app-package-selector',
  templateUrl: './package-selector.component.html',
  styleUrls: ['./package-selector.component.css'],
  providers: [CurrencyPipe]
})

export class PackageSelectorComponent implements OnInit {
  subscriptionForm: FormGroup;
  filteredOptions!: Observable<User[]>;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  // @ViewChild('pdfTable') pdfTable: ElementRef;
  constructor(private utility: UtilitiesService, public service: MastersServiceService,
    private chRef: ChangeDetectorRef, private formBuilder: FormBuilder, public datepipe: DatePipe, public currency: CurrencyPipe) { }
  public UserCode: string;
  GridList!: any;
  AssociationList!: any;
  PackageList!: any;
  DurationList!: any;
  AcademyTypeList!: any;
  PlayerSubscriptionDetails!: any;
  AssociationID!: string;
  PlayerName: string = "";
  PlayerDetails!: any;
  myControl = new FormControl();
  AcademyTypeID: string;
  AcademyRefID: string;
  Package!: string;
  Duration!: string;
  ActionFlag: string = 'Create';
  public ColumnMode: ColumnMode;
  public LIMITS = [
    { value: 1 },
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 20 }
  ];
  rowLimit = 15;
  AlertType: string = '';
  AlertMessage: string = '';
  pdfenable = false;
  ngOnInit(): void {
    this.subscriptionForm = this.formBuilder.group({
      _document: [],
      _subscriptionType: ['', Validators.required],
      _PackageAmount: ['', Validators.required],
      _Package: ['', Validators.required],
      _Duration: ['', Validators.required],
      _NoofVideos: [],
      _packageStartdate: ['', Validators.required],
      _packageEnddate: ['', Validators.required],
      _SelectedPlayerEmail: ['', [Validators.required, Validators.email]],
      _SelectedPlayerMobile: ['', Validators.required],
      _AcademyJoinCode: [],
    });
    this.subscriptionForm.controls['_PackageAmount'].disable();
    this.subscriptionForm.controls['_SelectedPlayerMobile'].disable();
    this.subscriptionForm.controls['_SelectedPlayerEmail'].disable();
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.LoadDropdowns();
    this.GridLoad();
    this.GetTopupDetails();
  }
  subscription = true;
  topup = false;
  subscriptionmsg = false;
  mobedit = true;
  emailedit = true;

  showsubalert() {
    this.subscriptionmsg = true;
  }
  hideSubmsg() {
    this.subscriptionmsg = false;
    this.Close();
    this.ClearPlayers();
    this.clsadd();
  }
  clsadd() {
    document.body.classList.toggle('no-scroll');
  }
  removeadd() {
    document.body.classList.toggle('no-scroll');
  }
  mbclick() {
    if (this.subscriptionForm.controls['_SelectedPlayerMobile'].enabled)
      this.subscriptionForm.controls['_SelectedPlayerMobile'].disable();
    else this.subscriptionForm.controls['_SelectedPlayerMobile'].enable();
  }
  mailclick() {
    if (this.subscriptionForm.controls['_SelectedPlayerEmail'].enabled)
      this.subscriptionForm.controls['_SelectedPlayerEmail'].disable();
    else this.subscriptionForm.controls['_SelectedPlayerEmail'].enable();
  }

  LoadDropdowns() {
    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: { ActionFlag: "DropDown", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {

      this.AssociationList = ret.AssociationList;
      this.PackageList = ret.PackageList;
      this.DurationList = ret.DurationList;
      this.AcademyTypeList = ret.AcademyTypeList;
    });
  }

  GridLoad() {
    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: {
        ActionFlag: "GridLoad", RefUserID: this.UserCode,
        //  AssociationID: String(this.AssociationID == undefined ? "" : this.AssociationID),
        PackageID: ""
      }
    }).subscribe((ret: any = []) => {

      this.GridList = ret.GridDetails;
    });
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }
  SearchPlayers() {
    var selAssociation = this.AssociationList.filter((con) => String(con.AssociationID) === String(this.AssociationID))[0];
    this.AcademyTypeID = selAssociation.AcademyTypeID;
    this.AcademyRefID = selAssociation.RefUserID;

    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: { ActionFlag: "SearchPlayer", RefUserID: String(this.UserCode), AssociationID: String(this.AssociationID), PlayerName: this.PlayerName }
    }).subscribe((ret: any = []) => {

      this.PlayerDetails = ret.PlayerDetails;
    });
  }

  SelectedPlayerAssociation!: string;
  SelectedPlayerID!: string;
  SelectedPlayerName!: string;
  SelectedPlayerDOB!: string;
  SelectedPlayerMobile!: string;
  SelectedPlayerEmail!: string;
  SelectedPlayerFather!: string;
  SelectedPlayerRefUserID!: string;
  SelectedPlayerImage!: string;
  SelectedPack!: any;
  PackageDetails!: any;
  PackageAmount!: string;
  packageStartdate!: Date;
  packageEnddate!: Date;
  subscriptionType: Number = 1;
  AcademyJoinCode: string = "";
  //SelectedDuration!: any;
  SubscriptionID: string;
  PlayerSelection(player: any, el: HTMLElement) {
    this.Close();
    this.DocumentFilePath = "";
    var selAssociation = this.AssociationList.filter((con) => String(con.AssociationID) === String(this.AssociationID))[0];
    this.SelectedPlayerAssociation = selAssociation.AssociationName;
    this.SelectedPlayerID = player.AtheleteID;
    this.SelectedPlayerName = player.DisplayName;
    this.SelectedPlayerDOB = player.DOB;
    this.SelectedPlayerMobile = player.LoginMobile;
    this.SelectedPlayerEmail = player.LoginEmail;
    this.SelectedPlayerFather = player.FatherName;
    this.SelectedPlayerRefUserID = player.RefUserID;
    this.SelectedPlayerImage = player.PlayerImage;
    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: {
        ActionFlag: "GetSubscriptionByPlayer",
        RefUserID: String(this.UserCode),
        AtheleteID: String(this.SelectedPlayerID)
      }
    }).subscribe((ret: any = []) => {
      if (ret.Status) {
        this.PlayerSubscriptionDetails = ret.PlayerSubscriptionDetails[0];
        if (this.PlayerSubscriptionDetails.IsSubscriptionExist == 1) {
          this.subscriptionType = 2;
          this.SubscriptionID = this.PlayerSubscriptionDetails.SubscriptionID;
          var subdetails = ret.PackageDetails[0];
          this.packageStartdate = new Date(subdetails.StartDate);
          this.packageEnddate = new Date(subdetails.EndDate);
          this.Duration = subdetails.DurationID;
          this.Package = subdetails.PackageID;
          this.chRef.detectChanges();
          this.DocumentFilePath = subdetails.DocumentPath;
          this.SelectedPlayerMobile = subdetails.Mobile;
          this.SelectedPlayerEmail = subdetails.Email;
        }
        else {
          this.subscriptionType = 1;
          this.packageStartdate = new Date();
          this.chRef.detectChanges();
        }
      }
      el.scrollIntoView();
    });


  }
  SearchPlayerPackageDetails() {
    // this.packageStartdate = null;
    // this.packageEnddate = null;
    if (this.subscriptionType == 1) {
      if (this.Package != "" && this.Package != null && this.Package != undefined) {
        if (this.Duration != "" && this.Duration != null && this.Duration != undefined) {
          this.service.Finance({
            method: "Finance/PlayerSubscriptionManager",
            request: {
              ActionFlag: "GetPackageDetails",
              RefUserID: String(this.UserCode),
              AcademyTypeID: String(this.AssociationList.filter((con) => String(con.AssociationID) === String(this.AssociationID))[0].AcademyTypeID),
              PackageID: String(this.Package),
              DurationID: String(this.Duration),
              AtheleteID: String(this.SelectedPlayerID)
            }
          }).subscribe((ret: any = []) => {
            if (ret.Status) {
              this.PackageDetails = ret.PackageDetails[0];
              this.PackageAmount = this.PackageDetails.AmountWithGST;
              this.StartdateChange();
            }
            else {
              this.PackageAmount = "";
              this.AlertType = 'Error';
              this.AlertMessage = ret.Message;
            }
            // this.PlayerDetails  = ret.PlayerDetails;
          });
        }
        else {
          // alert("Please select Duration");
        }
      }
      else {
        // alert("Please select the package");
      }
    }

  }

  Document!: string;
  DocumentFile!: string;
  DocumentFilePath: string = "";
  public DocumentDetails: ImageDetails[] = [{ data: "", name: "" }];
  StartdateChange() {

    var days = this.DurationList.filter((con) => String(con.DurationID) === String(this.Duration))[0].NoOfDays;
    var futureDate = new Date(this.packageStartdate);
    futureDate.setDate(futureDate.getDate() + days);
    this.packageEnddate = futureDate;
  }
  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.DocumentFile = event.target.value;
      reader.onload = () => {
        this.Document = reader.result as string;
        this.DocumentDetails = [];
        this.DocumentDetails.push({ data: this.Document, name: file.type });
        this.uploadFile();
      };
    }
  }
  uploadFile() {
    this.service.UploadFile(this.DocumentDetails[0]).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.DocumentFilePath = ret.name;
        // this.CoachDetails[0].ProfileImage = profileImage;
      }
    });
  }
  submitSubscription() {
    if (this.subscriptionType === 1) {
      this.createSubscription();
    } else if (this.subscriptionType === 2) {
      this.CreateTopup();
    }
  }
  emailinValid = false;
  createSubscription() {
    if (this.subscriptionForm.invalid) {
      return;

    }
    else {
      let srtDate = this.datepipe.transform(this.packageStartdate, 'yyyy-MM-dd');
      let endDate = this.datepipe.transform(this.packageEnddate, 'yyyy-MM-dd');
      this.service.Finance({
        method: "Finance/PlayerSubscriptionManager",
        request: {
          ActionFlag: this.ActionFlag,
          RefUserID: String(this.UserCode),
          AcademyTypeID: String(this.AcademyTypeID),
          AssociationID: String(this.AssociationID),
          AssoRefID: String(this.AcademyRefID),
          PackageID: String(this.Package),
          PackageType: String("Regular"),
          DurationID: String(this.Duration),
          StartDate: srtDate,
          EndDate: endDate,
          AtheleteID: String(this.SelectedPlayerID),
          AtheleteRefUserID: String(this.SelectedPlayerRefUserID),
          Mobile: String(this.SelectedPlayerMobile),
          Email: String(this.SelectedPlayerEmail),
          DocumentPath: String(this.DocumentFilePath),
          NoOfVideos: String(this.PackageDetails.NoOfVideos),
          Amount: String(this.PackageAmount),
          GST: String(this.PackageDetails.GST),
          NoOfDays: String(this.DurationList.filter((con) => String(con.DurationID) === String(this.Duration))[0].NoOfDays),
          PackageManagerID: String(this.PackageDetails.PackageManagerID),
          SubscriptionID: String(this.SubscriptionID)
        }
      }).subscribe((ret: any = []) => {
        if (ret.Status) {

          this.GridLoad();
          this.showsubalert();
          this.ActionFlag = "Create";
          this.AlertType = 'Success';
          this.AlertMessage = ret.Message;
        }
        else {
          this.AlertType = 'Error';
          this.AlertMessage = ret.Message;
        }
      });
    }
  }
  editdata: any = []
  EditSubscription(data: any) {
    this.subscriptionForm.controls['_SelectedPlayerMobile'].disable();
    this.subscriptionForm.controls['_SelectedPlayerEmail'].disable();
    this.PlayerDetails = [];
    this.editdata = data;
    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: {
        ActionFlag: "Edit",
        RefUserID: String(this.UserCode),
        SubscriptionID: String(data.SubscriptionID)
      }
    }).subscribe((ret: any = []) => {
      if (ret.Status) {
        this.mobedit = true;
        var retdata = ret.PlayerSubscriptionDetails[0];
        this.SelectedPlayerAssociation = this.editdata.AssociationName;
        this.SelectedPlayerID = retdata.AtheleteID;
        this.SelectedPlayerName = this.editdata.PlayerName;
        this.SelectedPlayerDOB = this.editdata.DOB;
        this.SelectedPlayerMobile = this.editdata.Mobile;
        this.SelectedPlayerEmail = retdata.Email;
        this.SelectedPlayerFather = this.editdata.FatherName;
        this.SelectedPlayerRefUserID = this.editdata.PlayerRefUserID;
        this.SelectedPlayerImage = this.editdata.PlayerImage;
        this.AssociationID = retdata.AssociationID;
        var selAssociation = this.AssociationList.filter((con) => String(con.AssociationID) === String(this.AssociationID))[0];
        this.AcademyTypeID = selAssociation.AcademyTypeID;
        this.AcademyRefID = selAssociation.RefUserID;
        this.ActionFlag = "Update";
        this.PackageAmount = retdata.Amount;
        this.SubscriptionID = this.editdata.SubscriptionID;

        this.DocumentFilePath = retdata.DocumentPath;
        this.chRef.detectChanges();

        if (this.editdata.PackageType == "Topup") {
          this.subscriptionType = 2;
          this.topupID = retdata.PackageID;
          this.topupVideos = this.TopupDetails.filter((con) => String(con.TopupID) === String(retdata.PackageID))[0].TopupID;
          
          this.Package = retdata.PackageID;
          this.Duration = retdata.DurationID;
        } else {
          this.subscriptionType = 1;
          this.Package = retdata.PackageID;
          this.Duration = retdata.DurationID;
          this.SearchPlayerPackageDetails();
        }
        this.packageStartdate = new Date(retdata.StartDate);
      }
    });
  }
  TopupDetails: any = [];
  GetTopupDetails() {
    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: {
        ActionFlag: "GetTopupDetails",
        RefUserID: String(this.UserCode)
      }
    }).subscribe((ret: any = []) => {
      if (ret.Status) {
        this.TopupDetails = ret.TopupDetails;
      }
    });
  }
  topupID: string;
  topupVideos: string;
  topupGST: string;
  topupchange(data: any) {
    this.PackageAmount = data.AmountWithGST;
    this.topupID = data.TopupID;
    this.topupVideos = data.NoOfVideos;
    this.topupGST = data.GST;
  }
  CreateTopup() {
    if (this.subscriptionForm.invalid) {
      return;
    }
    else {
      this.service.Finance({
        method: "Finance/PlayerSubscriptionManager",
        request: {
          ActionFlag: this.ActionFlag,
          RefUserID: String(this.UserCode),
          PackageType: String("Topup"),
          NoOfVideos: String(this.topupVideos),
          Amount: String(this.PackageAmount),
          GST: String(this.topupGST),
          TopupID: String(this.topupID),
          SubscriptionID: String(this.SubscriptionID),
          Mobile: String(this.SelectedPlayerMobile),
          Email: String(this.SelectedPlayerEmail)
        }
      }).subscribe((ret: any = []) => {
        if (ret.Status) {
          this.Close();
          this.ClearPlayers();
          this.GridLoad();
          this.showsubalert();
          this.clsadd();
          this.ActionFlag = "Create";
          this.AlertType = 'Success';
          this.AlertMessage = ret.Message;
        }
        else {
          this.AlertType = 'Error';
          this.AlertMessage = ret.Message;
        }
      });
    }
  }
  ClearPlayers() {
    this.AssociationID = "";
    this.PlayerName = "";
    this.PlayerDetails = [];
  }
  ClearSelectedPlayer() {
    // this.SelectedPlayerID = "";
    // this.SelectedPlayerName = "";
    // this.SelectedPlayerDOB = "";
    // this.SelectedPlayerMobile = "";
    // this.SelectedPlayerEmail = "";
    // this.SelectedPlayerFather = "";
    // this.SelectedPlayerRefUserID = "";
    // this.SelectedPlayerImage = "";
    //this.SubscriptionID="";
    this.Package = "";
    this.Duration = "";
    this.topupVideos = "";
    this.packageStartdate = new Date();
    this.packageEnddate = new Date();
    this.Document = "";
    this.PackageAmount = "";
    this.SubscriptionID = "";
    this.mobedit = true;
    this.emailedit = true;
    this.topupID = "";
  }
  Close() {
    this.SelectedPlayerID = "";
    this.SelectedPlayerName = "";
    this.SelectedPlayerDOB = "";
    this.SelectedPlayerMobile = "";
    this.SelectedPlayerEmail = "";
    this.SelectedPlayerFather = "";
    this.SelectedPlayerRefUserID = "";
    this.SelectedPlayerImage = "";
    this.Package = "";
    this.Duration = "";
    this.topupVideos = "";
    this.packageStartdate = new Date();
    this.packageEnddate = new Date();
    this.Document = "";
    this.PackageAmount = "";
    this.SubscriptionID = "";
    this.mobedit = true;
    this.emailedit = true;
    this.topupID = "";
  }
  pdfData: any;
  invReferenceNo = "";
  invDate = "";
  invPlayerName = "";
  invAssociationName = "";
  invPackageType = "";
  invPackageName = "";
  invDurationName = "";
  invAmount = "";
  invGST = "";
  invGSTAmount = "";
  invAmountWithGST = "";
  invStartDate = "";
  invEndDate = "";
  invMobile = "";
  invEmail = "";
  getInvData(data: any) {
    this.SubscriptionID = data.SubscriptionID;
    this.fetchInvoiceData();
  }
  fetchInvoiceData() {
    this.service.Finance({
      method: "Finance/PlayerSubscriptionManager",
      request: {
        ActionFlag: "GetMailInfo",
        RefUserID: String(this.UserCode),
        SubscriptionID: String(this.SubscriptionID)
      }
    }).subscribe((ret: any = []) => {
      console.log(ret);
      this.pdfData = ret.PackageDetails[0];
      this.invReferenceNo = this.pdfData.ReferenceNo;
      this.invDate = this.pdfData.Date;
      this.invPlayerName = this.pdfData.PlayerName;
      this.invAssociationName = this.pdfData.AssociationName;;
      this.invPackageType = this.pdfData.PackageType;
      this.invPackageName = this.pdfData.PackageName;
      this.invDurationName = this.pdfData.DurationName;
      this.invAmount = this.pdfData.Amount;
      this.invGST = this.pdfData.GST;
      this.invGSTAmount = this.pdfData.GSTAmount;
      this.invAmountWithGST = this.pdfData.AmountWithGST;
      this.invStartDate = this.pdfData.StartDate;
      this.invEndDate = this.pdfData.EndDate;
      this.invMobile = this.pdfData.Mobile;
      this.invEmail = this.pdfData.Email;
      this.downloadAsPDF();
    });
  }
  public downloadAsPDF() {
    this.pdfenable = true;
    this.chRef.detectChanges();
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      this.pdfenable = false;
      this.chRef.detectChanges();
    });

  }
  sendInvoiceMail() {
    this.service.Finance({
      method: "Finance/InvoiceMail",
      request: {
        ActionFlag: "GetMailInfo",
        RefUserID: String(this.UserCode),
        SubscriptionID: String(this.SubscriptionID)
      }
    }).subscribe((ret: any = []) => {
      if (ret.Status) {
        this.subscriptionmsg = false;
        this.chRef.detectChanges();
      }
    });
  }

}

export interface ImageDetails { data: string, name: string }
