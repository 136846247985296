import { Component, OnInit } from '@angular/core';
import {UtilitiesService} from '../../utilities.service';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})
export class DoctorListComponent implements OnInit {

  constructor(private utility:UtilitiesService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
  }

}
