import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../../masters-service.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(private utility:UtilitiesService,private service:MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }   
     
    @Input()  today = new Date();
  public lstVideos:any = []; 
  public ListVideo: VideoList[] = [
    {  
      id:"1",
      videoTitle: "Registration", 
      url: "https://sportavideos.blob.core.windows.net/videos/CAPSULES/TTE/Third%20Eye%20Registration.mp4",
      thumbNail: "https://sportavideos.blob.core.windows.net/videos/CAPSULES/TTE/Registration.png" 
    },
    { 
      id:"2",
      videoTitle: "Play Group Creation", 
      url: "https://sportavideos.blob.core.windows.net/videos/CAPSULES/TTE/Third%20Eye%20Master%20with%20audio.mp4" , 
      thumbNail: "https://sportavideos.blob.core.windows.net/videos/CAPSULES/TTE/Play%20Groups%20Master.png"
    },
  ];

  public _videoURL!:string; public _vidoeTitle!:string; public _videoThumbnail!:string;
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.lstVideos =  this.ListVideo;
  }

  GetVideo(id){
    this._videoURL = id.url;
    this._vidoeTitle = id.videoTitle;
    this._videoThumbnail = id.thumbNail;
  }

}
export interface VideoList { id:string, videoTitle:string, url:string, thumbNail:string }