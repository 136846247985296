<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container admin-dashboard-page drill-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">

                            <div class="col-md-12">
                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="main-tab" rel="drills-db">
                                        <a href="javascript:void(0);" rel="batting" class="select"
                                            (click)="ngOnInit()">Active</a>
                                        <a href="javascript:void(0);" rel="bowling"
                                            (click)="GetCompletedDrill()">Completed</a>
                                        <a href="javascript:void(0);" rel="fielding"
                                            (click)="GetLibraryDrill()">Library</a>
                                    </div>
                                    <div class="drills-db">

                                        <div class="search-container" *ngIf="IsShowSearch">
                                            <div class="row">
                                                <div class="col-md-2"></div>
                                                <div class="col-md-4">
                                                    <mat-form-field class="md-block mat-dd-field search-txt"
                                                        appearance="outline" floatLabel="always">
                                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24"
                                                                x="768" y="0" fit="" height="100%" width="100%"
                                                                preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path opacity=".3"
                                                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                </path>
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                </path>
                                                            </svg></mat-icon>
                                                        <mat-select placeholder="Level" [(ngModel)]="SearchLevel"
                                                            name="n_SearchLevel">
                                                            <mat-option>
                                                                All
                                                            </mat-option>
                                                            <mat-option *ngFor="let level of lstDrillLevel"
                                                                [value]="level.DrillLevelName">
                                                                {{level.DrillLevelName}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4">
                                                    <mat-form-field class="md-block search-txt" appearance="outline"
                                                        floatLabel="always">
                                                        <mat-icon matSuffix class="mat-icon">
                                                            <a href="javascript:void(0);">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 373.12 373.12" width="24" height="24">
                                                                    <path
                                                                        d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                                </svg>
                                                            </a>
                                                        </mat-icon>
                                                        <input matInput placeholder="Drill Name or Keywords.."
                                                            name="n_Search" [(ngModel)]="SearchFilter">
                                                    </mat-form-field>
                                                </div>
                                                <!-- <div class="col-md-2">
                                                    <a href="javascript:void(0);" class="theme-button mt5">Search</a>
                                                </div> -->
                                                <div class="col-md-2" *ngIf="IsShowLibrayBack">
                                                    <a href="javascript:void(0);" class="theme-button mt5"
                                                        (click)="GetLibraryDrill()">Back</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="main-tab-container batting" style="display: block;">
                                            <div class="row" *ngIf="IsEmptyDrill">
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640.png" class="img-fluid"
                                                            draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3"
                                                    *ngFor="let active of lstActive|filter:SearchFilter|filter:SearchLevel">
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal"
                                                                data-target="#videopreview"
                                                                (click)="ShowMyVideo(active)">
                                                                <img src="{{active.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <div class="ico-video"></div>
                                                            <div class="drill-type" style="text-transform:capitalize;">
                                                                {{active.DrillType}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;">
                                                                {{active.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);" class="video-user"
                                                                        style="text-transform:capitalize;"> <i>Shared
                                                                            By: </i> {{active.SharedByUserName}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{active.CreatedDate}} || {{active.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name"
                                                                    style="text-transform:capitalize;">
                                                                    {{active.DrillName}}</div>
                                                                <div class="lib-status">{{active.Status}}</div>
                                                                <a href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click)="GetCheckedDrillDetails(active)"></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container bowling" style="display: none;">
                                            <div class="row" *ngIf="IsEmptyDrill">
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640.png" class="img-fluid"
                                                            draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3"
                                                    *ngFor="let completed of lstCompleted|filter:SearchFilter|filter:SearchLevel">
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal"
                                                                data-target="#videopreview"
                                                                (click)="ShowMyVideo(completed)">
                                                                <img src="{{completed.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <div class="ico-video"></div>
                                                            <div class="drill-type" style="text-transform:capitalize;">
                                                                {{completed.DrillType}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;">
                                                                {{completed.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);" class="video-user"
                                                                        style="text-transform:capitalize;"> <i>Shared
                                                                            By: </i>{{completed.SharedByUserName}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{completed.CreatedDate}} ||
                                                                    {{completed.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name"
                                                                    style="text-transform:capitalize;">
                                                                    {{completed.DrillName}}</div>
                                                                <div class="lib-status">{{completed.Status}}</div>
                                                                <a href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container fielding" style="display: none;">

                                            <ul class="library-drills" *ngIf="IsShowLibraryType">
                                                <li>
                                                    <a href="javascript:void(0);" class="box-shadow library-batting"
                                                        (click)="GetLibraryByType(1)">
                                                        <!-- <img src="assets/images/library-batting.png">  -->
                                                        <figcaption>Batting</figcaption>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" class="box-shadow library-bowling"
                                                        (click)="GetLibraryByType(2)">
                                                        <!-- <img src="assets/images/library-bowling.png">  -->
                                                        <figcaption>Bowling</figcaption>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" class="box-shadow library-fielding"
                                                        (click)="GetLibraryByType(3)">
                                                        <!-- <img src="assets/images/library-fielding.png">  -->
                                                        <figcaption>Fielding</figcaption>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);"
                                                        class="box-shadow library-wicket-keeper"
                                                        (click)="GetLibraryByType(4)">
                                                        <!-- <img src="assets/images/library-wicket-keeper.png">  -->
                                                        <figcaption>Wicket Keeper</figcaption>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" class="box-shadow library-fitness"
                                                        (click)="GetLibraryByType(5)">
                                                        <!-- <img src="assets/images/library-fitness.png">  -->
                                                        <figcaption>Fitness</figcaption>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" class="box-shadow library-others"
                                                        (click)="GetLibraryByType(6)">
                                                        <!-- <img src="assets/images/library-others.png"> -->
                                                        <figcaption>Others</figcaption>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="row" *ngIf="IsEmptyDrill">
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640.png" class="img-fluid"
                                                            draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="IsShowLibrayVideo">
                                                <div class="col-md-3"
                                                    *ngFor="let library of lstLibraryTypes|filter:SearchFilter|filter:SearchLevel">
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal"
                                                                data-target="#videopreview"
                                                                (click)="ShowMyVideo(library)">
                                                                <img src="{{library.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <div class="ico-video"></div>
                                                            <div class="drill-type" style="text-transform:capitalize;">
                                                                {{library.DrillType}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;">
                                                                {{library.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);" class="video-user"
                                                                        style="text-transform:capitalize;"> <i>Shared
                                                                            By: </i>{{library.SharedByUserName}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{library.CreatedDate}} || {{library.CreatedTime}}
                                                                </p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name"
                                                                    style="text-transform:capitalize;">
                                                                    {{library.DrillName}}</div>
                                                                <div class="lib-status">{{library.Status}}</div>
                                                                <a href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click)="GetCheckedDrillDetails(library)"></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Share Modal -->
<div class="modal fade" id="drillshare" *ngIf="IsDrillProgress" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:350px;margin: 0 auto;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{ChkDrillName}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="min-height:150px;">
                <div class="row">
                    <div class="col-md-12 txt-center">
                        <mat-checkbox class="example-margin mt20" [checked]="allComplete">
                            {{DrillProgress}}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="txt-center">
                    <a href="javascript:void(0);" class="theme-button mt25"
                        (click)="DrillsAthletesStartEndDrill()">Save</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Preview Modal -->
<div class="modal fade" *ngIf="IsMyVideoShow" id="videopreview" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle" style="text-transform:capitalize;">
                    {{PreviewDrillName}}
                    <span class="skill-type" style="text-transform:capitalize;">{{DrillType}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="drills-popup-video">
                    <div class="vprofile-video">
                        <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none"
                            width="100%" src="{{VideoURL}}" data-setup="{}" disablePictureInPicture
                            controlsList="nodownload" type="video/mp4">
                        </video>
                        <!-- <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                data-setup="{}"  disablePictureInPicture controlsList="nodownload" >
                            <source src="{{VideoURL}}" type="video/mp4" /> </video> -->
                        <!-- <div class="video-time">{{VideoLength}}</div> -->
                    </div>
                    <div class="drill-user-date">
                        <div class="drill-user">
                            <a href="javascript:void(0);" class="video-user"
                                style="text-transform:capitalize;">{{CreatedBy}}</a>
                        </div>
                        <div class="time">
                            <p>{{CreatedDate}} || {{CreatedTime}}</p>
                        </div>
                    </div>
                </div>
                <div class="drills-description">
                    <div class="title">Instruction</div>
                    <ul class="instruction mb15">
                        <li style="text-transform:capitalize;" *ngFor="let stp of lstDrillEditStep;let ind=index;">Step
                            {{ind + 1}} : {{stp.StepText}}
                        </li>
                    </ul>
                    <div class="title">Required Items</div>
                    <ul class="required">
                        <li style="text-transform:capitalize;" *ngFor="let val of MarialsBind">{{val}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Alerts -->
<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>My Drills</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false;WindowLoad();">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>My Drills</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>