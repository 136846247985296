// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // _baseURL: "http://localhost:55642/",  // Dev
  //  _baseURL: "http://52.172.96.40/ThirdEyeAPI_UAT/", //Live UAT
  // _baseURL : "http://52.172.96.40/CricPassAPI/", // Dev pub
  // _baseURL: "https://kxipservice.alphaprotracker.com/",
  // _baseURL: "https://cap.alphaprotracker.com/",
  _baseURL: "https://api.cricpass.com/",

  AZURE_ACCOUNT_KEY: "d+wiDhz+jQlNFLoIcun9CbAc7d2+4+gamSsVjN549Mp1/dJ9U7smD8MwKrNqo5FS859x+XT5Datlr8tvlVuvMA==",
  Azure_AccountName: "splcms",
  sasGeneratorUrl: "",
  firebase: {
    apiKey: "AIzaSyArXTXVO4e_-4v8VI8I-7xaFfOIiyi-pBY",
    authDomain: "cricpass-92977.firebaseapp.com",
    databaseURL: "https://cricpass-92977-default-rtdb.firebaseio.com",
    projectId: "cricpass-92977",
    storageBucket: "cricpass-92977.appspot.com",
    messagingSenderId: "713135028310",
    appId: "1:713135028310:web:adac38a019657714dda3f7",
    measurementId: "G-K7DKT1BXK7"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
