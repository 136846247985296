<div class="page-container my-videos-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="row">

                    <div class="col-md-3">
                        <div class="fixed-container min">
                            <div class="upload-videos-container box-shadow br-5">
                                <div class="program-player">
                                    <div class="pgm-picture"><img src="{{PlayerDetails.ProfileImage}}"
                                            onerror="this.src='assets/images/no_user.png';"></div>
                                    <figcaption>
                                        <div class="name" style="text-transform:capitalize;">{{PlayerDetails.FirstName}}
                                            {{PlayerDetails.LastName}}
                                        </div>
                                        <div class="proficiency">

                                            <span style="text-transform:capitalize;">{{PlayerDetails.Age}}</span>
                                            <span
                                                style="text-transform:capitalize;">{{PlayerDetails.PlayerRoleName}}</span>
                                            <span
                                                style="text-transform:capitalize;">{{PlayerDetails.BattingTypeName}}</span>
                                            <span
                                                style="text-transform:capitalize;">{{PlayerDetails.BowlingStyleName}}</span>
                                        </div>
                                    </figcaption>
                                </div>
                                <div class="upload-container upload-subs mb10">
                                    <ul class="pgm-card">
                                        <li [ngClass]="{'select': id === 1 }" id="1" (click)="SessionListLoad(id=1)">
                                            <div class="pgm-count">{{ReviewCount}}</div>
                                            <div class="pgm-name txt-right">
                                                <img src="assets/images/session.png">
                                                <p>Session Review</p>
                                            </div>
                                        </li>
                                        <li [ngClass]="{'select': id === 2 }" id="2" (click)="DrillListLoad(id=2)">
                                            <div class="pgm-count">{{PlayerDetails.DrillSharedCount}}</div>
                                            <div class="pgm-name txt-right">
                                                <img src="assets/images/drills.png">
                                                <p>Assigned Drills</p>
                                            </div>
                                        </li>
                                        <li [ngClass]="{'select': id === 3 }" id="3" (click)="PlayListLoad(id=3)">
                                            <div class="pgm-count">{{PlayerDetails.PlaylistsCount}}</div>
                                            <div class="pgm-name txt-right">
                                                <img src="assets\images\pllisticon.PNG">
                                                <p>Shared Playlists</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Session -->
                    <div class="col-md-9" *ngIf="IsEmptyVideos">
                        <div class="profile-with-tab br-5 box-shadow">
                            <a href="javascript:void(0);" routerLink="/play-groups" class="theme-button mt5">Back</a>

                            <div class="no-videos txt-center">
                                <img src="assets/images/No-Session-640-Coach.png" class="img-fluid" draggable="false">
                            </div>
                            <div class="row">
                                <div class="col-md-12 txt-center">
                                    <a href="javascript:void(0);" (click)="AddNewSession()"
                                        class="theme-button mt5">Click here to add New Session</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="IsSessionsListShow">
                        <div class="video-container">

                            <div class="player-search">
                                <div class="row">
                                    <div class="col-md-6">
                                        <a href="javascript:void(0);" routerLink="/play-groups"
                                            class="theme-button mr5">Back</a>
                                        <a href="javascript:void(0);" (click)="AddNewSession()"
                                            class="theme-button ">Create New Session</a>
                                         
                                    </div>
                                    <div class="col-md-6">
                                        <p class="theme-button mt5 txt-right">Videos used this month : <span>{{totalVideosUsed}}</span></p>
                                    </div>
                                    <!-- <div class="col-md-6 txt-right">
                                        <a href="javascript:void(0);" class="sorting">Sort by :
                                            <strong>Latest</strong><img src="assets/images/sorting.png"></a>
                                    </div> -->
                                </div>
                            </div>
                            <div class="" *ngIf="newsession">
                                <div class="upload-videos-container box-shadow br-5 upload-common" *ngIf="newsession">

                                    <div class="upload-container upload-type mb10" *ngIf="videoformfields">
                                        <div class="title">Fill Video Form fields</div>
                                        <div class="fixed-container min">
                                            <div class="upload-form">
                                                <form [formGroup]="SessionForm" #ses="ngForm"
                                                    (ngSubmit)="showVideoupload()">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <mat-form-field class="md-block" appearance="outline"
                                                                floatLabel="always">
                                                                <mat-icon matSuffix class="mat-icon"><svg
                                                                        viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                        height="100%" width="100%"
                                                                        preserveAspectRatio="xMidYMid meet"
                                                                        focusable="false">
                                                                        <path opacity=".3"
                                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                        </path>
                                                                    </svg></mat-icon>
                                                                <mat-label>Enter Season Name</mat-label>
                                                                <input matInput required name="sessionName"
                                                                    formControlName="n_sessionName"
                                                                    [(ngModel)]="sessionName">
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_sessionName.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_sessionName.errors.required">
                                                                    Session Name is required.</div>
                                                            </div>
                                                            <!-- <div *ngIf="a.submitted && AthleteBasicForm.controls.n_AssociationName.invalid"
                                                            class="alert alert-danger">
                                                        <div *ngIf="AthleteBasicForm.controls.n_AssociationName.errors.required">
                                                            Association is required.</div>
                                                    </div> -->
                                                        </div>
                                                        <div class="col-md-6">
                                                            <mat-form-field appearance="fill"
                                                                class="custom-dtpkr-txt md-block" appearance="outline"
                                                                floatLabel="always">
                                                                <mat-label>Select Date</mat-label>
                                                                <input matInput [matDatepicker]="picker" name=" "
                                                                    formControlName="n_sessionDate"
                                                                    [(ngModel)]="sessionDate" readonly>
                                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_sessionDate.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_sessionDate.errors.required">
                                                                    Session Date is required.</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <mat-form-field class="md-block mat-dd-field "
                                                                appearance="outline" floatLabel="always">
                                                                <mat-icon matSuffix class="mat-icon"><svg
                                                                        viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                        height="100%" width="100%"
                                                                        preserveAspectRatio="xMidYMid meet"
                                                                        focusable="false">
                                                                        <path opacity=".3"
                                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                        </path>
                                                                    </svg></mat-icon>
                                                                <mat-label>Select Skill</mat-label>
                                                                <mat-select placeholder="Skill" name="skill"
                                                                    formControlName="n_skill" [(ngModel)]="skill"
                                                                    style="text-transform:capitalize;">
                                                                    <mat-option [value]="ses.SessionTypeID"
                                                                        *ngFor="let ses of SessionTypes"
                                                                        style="text-transform:capitalize;">
                                                                        {{ses.SessionTypeName}}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_skill.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_skill.errors.required">
                                                                    Select the Skill.</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <mat-form-field class="md-block mat-dd-field "
                                                                appearance="outline" floatLabel="always">
                                                                <mat-icon matSuffix class="mat-icon"><svg
                                                                        viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                        height="100%" width="100%"
                                                                        preserveAspectRatio="xMidYMid meet"
                                                                        focusable="false">
                                                                        <path opacity=".3"
                                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                        </path>
                                                                    </svg></mat-icon>
                                                                <mat-label>Select View</mat-label>
                                                                <mat-select placeholder="View Type" name="viewType"
                                                                    formControlName="n_viewType" [(ngModel)]="viewType"
                                                                    style="text-transform:capitalize;">
                                                                    <mat-option [value]="view.VideoViewID"
                                                                        *ngFor="let view of VideoViewTypes"
                                                                        style="text-transform:capitalize;">
                                                                        {{view.VideoViewName}}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <div *ngIf="ses.submitted && SessionForm.controls.n_viewType.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="SessionForm.controls.n_viewType.errors.required">
                                                                    Select the View Type.</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 txt-center mt10">
                                                            <!-- <button mat-raised-button color="primary" class="mb5">Save</button> -->
                                                            <button mat-raised-button color="primary" class="mb5 ml10"
                                                                type="submit">Next</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upload-videos-container box-shadow br-5" *ngIf="videoupload">
                                        <div class="title">Video Upload</div>
                                        <div class="upload-container">
                                            <div class="video-upload mb10">
                                                <div class="dropzone">
                                                    <div class="text-wrapper">
                                                        <div class="centered">
                                                            <input type="file" name="file" id="file" multiple
                                                                (change)="onVideoChange($event)">
                                                            <label for="file"><span class="textLink">Select your
                                                                    file</span>
                                                                <!--or Drop it here!-->
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 txt-center mt10">
                                                <!-- <button mat-raised-button color="primary" class="mb5">Save</button> -->
                                                <button mat-raised-button color="primary" class="mb5 ml10"
                                                    (click)="showVideoFormfields();">Back</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="upload-videos-container box-shadow br-5" [hidden]="!videouploadstatus">
                                        <div class="title">Upload Skill</div>
                                        <div class="upload-container upload-type mb10">
                                            <div class="left-video-container">
                                                <!-- <video id="example_video_1" class="video-js" controls preload="none" width="640"
                                                    height="264" poster="https://i.ytimg.com/vi/MFSjLx-LkrU/maxresdefault.jpg"
                                                    data-setup="{}">
                                                    <source src="GANGA-SRIDHAR-BATTING-FRONT-VIEW.mp4" type="video/mp4">
                                                </video> -->
                                                <video id="myVideo" class="video-js" preload="none" width="640"
                                                    height="100%" controls disablePictureInPicture
                                                    controlsList="nodownload" #video
                                                    (loadedmetadata)="onMetadata($event, video)">
                                                </video>
                                                <canvas id="canvas" width="1280" height="720"
                                                    style="display: none;"></canvas>
                                                <!-- <img id="imgId" src=""/> -->
                                                <!-- <canvas id="canvas"></canvas> <br /><br /> -->
                                                <div class="video-size txt-right">{{Videoduration}}
                                                    <span>{{selectedfilesize}}</span>
                                                </div>

                                                <div class="card-profile-progress">
                                                    <div class="prg-container">
                                                        <!-- <div class="prg-rating">{{uploadedsize}}/{{selectedfilesize}}</div> -->
                                                        <div class="prg-ratio session">
                                                            <mat-progress-bar mode="determinate" [value]="percent">
                                                            </mat-progress-bar>
                                                        </div>
                                                        <div class="prg-percentage"> {{ percent }}%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 txt-center">
                                                <button mat-raised-button color="primary" class="mb5 mr5"
                                                    [disabled]="!IsShowUpload" (click)="uploadbutton()">Upload</button>
                                                <button mat-raised-button color="primary" class="mb5 mr5"
                                                    (click)="cancel($event)">Cancel</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="" *ngIf="!newsession">
                                <div class="video-item br-5"
                                    *ngFor="let sessions of SessionsList|filter:SearchFilter;let ind=index;">
                                    <div class="vprofile-header">
                                        <a href="javascript:void(0);" class="vprofile-left">
                                            <div class="vprofile">
                                                <img src="{{sessions.ProfileImage}}"
                                                    onerror="this.src='assets/images/no_user.png';">
                                            </div>
                                            <div class="vprofile-info">
                                                <div class="vprofile-name" style="text-transform:capitalize;">
                                                    {{sessions.PlayerName}}</div>
                                                <div class="vprofile-type">{{sessions.PlayerRoleName}}</div>
                                                <div class="vprofile-time">{{sessions.SessionDate}}</div>
                                            </div>
                                        </a>
                                        <!-- <div class="vprofile-more txt-right">
                                            <div class="dropdown">
                                                <a href="javascript:void(0);" class="ico-menu dropdown-toggle"
                                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"></a>
                                                <div class="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Copy Link</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="vprofile-video">
                                        <video id="my-video" class="video-js" controls
                                            poster="{{sessions.ThumbnailURL}}" preload="none" width="640" height="264"
                                            data-setup="{}" disablePictureInPicture controlsList="nodownload">
                                            <source src="{{sessions.CloudURL}}" type="video/mp4">
                                        </video>
                                        <canvas id="canvas"></canvas> <br /><br />
                                    </div>
                                    <div class="vprofile-session">
                                        <div class="vpsession-name">{{sessions.SessionName}} </div>
                                        <div class="session">
                                            <span>{{sessions.SessionTypeName}}</span>
                                            <span> {{sessions.VideoViewName}}</span>
                                        </div>
                                        <div class="feed-info txt-right">
                                            <!-- <a href="javascript:void(0);">4 Fist Bump</a> -->
                                            <a href="javascript:void(0);">{{sessions.SessionComments}} Comments</a>
                                        </div>
                                        <!-- <div class="session">
                                            {{sessions.SessionName}} : <span> {{sessions.SessionTypeName}}</span> <span>
                                                {{sessions.VideoViewName}}</span>
                                        </div>
                                        <div class="feed-info txt-right">
                                            <a href="javascript:void(0);">{{sessions.SessionComments}} Comments</a>
                                        </div> -->
                                    </div>
                                    <div class="vprofile-feed">
                                        <div class="row">
                                            <div class="col-md-3"> <a href="javascript:void(0);" class="fist-bump"
                                                    matRipple>Fist Bump</a> </div>
                                            <div class="col-md-3"> <a href="javascript:void(0);" class="comments"
                                                    (click)="showCommentbox('comm'+sessions.SessionID,sessions.SessionID)"
                                                    matRipple>Comments</a> </div>
                                            <div class="col-md-3"> <a href="javascript:void(0);" class="fdanalyse"
                                                    (click)="GetAnalysisDetails(sessions)" matRipple>Analyse</a>
                                            </div>
                                            <div class="col-md-3">
                                                <a href="javascript:void(0);" class="compare"
                                                    (click)="GetCompareVideos(sessions)" matRipple>Compare </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments-feed" *ngIf="'comm'+sessions.SessionID===openedShareid"
                                        id="comm{{sessions.SessionID}}">
                                        <div>
                                            <div class="main-comments">
                                                <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                                <div class="comments-box">
                                                    <div class="comments-txt">
                                                        <div class="textarea" role="textbox"
                                                            id="message{{sessions.SessionID}}" contenteditable="true"
                                                            tabindex="1"></div>
                                                    </div>
                                                    <div class="ico-sent">
                                                        <a href="javascript:void(0);"
                                                            (click)="sendMessage(sessions.SessionID)"><img
                                                                src="assets/images/ico-sent.png"></a>
                                                    </div>
                                                </div>
                                                <!-- <div class="remove-comments" *ngIf = "IsShowComments" >  
                                                    <span class="remove-list" (click)="hideCommentbox()"></span> </div> -->
                                            </div>

                                            <div class="post-comments" *ngIf="IsShowComments">
                                                <div *ngFor="let msg of MessageList">
                                                    <div
                                                        [ngClass]="(msg.SenderID==UserCode)?'comment-sender1':'comment-sender2'">
                                                        <div class="comments-pic">
                                                            <img draggable="false" src="{{msg.ProfileImage}}"
                                                                onerror="this.src='assets/images/no_user.png';" />
                                                        </div>
                                                        <div class="comments-profile">
                                                            <div class="c-name" style="text-transform:capitalize;">
                                                                {{msg.SenderName}}</div>
                                                            <div class="c-proficiency">{{msg.SenderType}}</div>
                                                        </div>
                                                        <div class="comments-msg">
                                                            {{msg.MsgContent}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments-feed sharetag"
                                        *ngIf="'share'+sessions.SessionID===openedShareid"
                                        id="share{{sessions.SessionID}}">
                                        <div *ngIf="SelectedCoachList.length>0">
                                            <h3>Shared with:</h3>
                                            <p *ngFor="let c of SelectedCoachList">{{c.CoachName}}</p>
                                        </div>
                                        <div *ngIf="SelectedCoachList.length==0">
                                            <mat-form-field class="mat-dd-field col-md-9" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768"
                                                        y="0" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3"
                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                        </path>
                                                        <path
                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Select Coach to share</mat-label>
                                                <mat-select id="coach{{sessions.SessionID}}"
                                                    placeholder="Select Coach to share" name="selectedCoach"
                                                    [(ngModel)]="selectedCoach" style="text-transform:capitalize;">
                                                    <mat-option [value]="coach.CoachRefUserID"
                                                        *ngFor="let coach of CoachList"
                                                        style="text-transform:capitalize;">
                                                        {{coach.CoachName}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <button mat-raised-button id="sh{{sessions.SessionID}}"
                                                (click)="shareVideotoCoach(sessions.SessionID,selectedCoach)"
                                                color="primary">Send</button>
                                            <!-- <button type="button" class="mat-dd-field col-md-3">send</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-3" *ngIf="IsSessionsListShow">
                        <div class="fixed-container min">
                            <div class="video-filters box-shadow br-5">
                                <div class="title">Filters</div>
                                <div class="video-filters-container">
                                    <div class="search-container">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-form-field class="md-block search-txt" appearance="outline"
                                                    floatLabel="always">
                                                    <mat-icon matSuffix class="mat-icon">
                                                        <a href="javascript:void(0);">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 373.12 373.12" width="24" height="24">
                                                                <path
                                                                    d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                            </svg>
                                                        </a>
                                                    </mat-icon>
                                                    <input matInput placeholder="Search Table"
                                                        [(ngModel)]="SearchFilter">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="title adv-filter">
                                    Advance Filters
                                    <a href="javascript:void(0);" (click)="advFilters=!advFilters"></a>
                                </div>
                                <div class="adv-filter-container" *ngIf="advFilters">
                                    <div class="filter-type mb15">
                                        <mat-radio-group aria-label="Select an option" (change)="changeMonthDate()">
                                            <!-- <mat-radio-button value="1">Month Range</mat-radio-button> -->
                                            <mat-radio-button value="2" [checked]="true">Date Range</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="range-filter">
                                        <div class="row">
                                            <div class="col" *ngIf="showMonthRange">
                                                <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block"
                                                    floatLabel="always">
                                                    <mat-label>Month Range</mat-label>
                                                    <mat-date-range-input [rangePicker]="range">
                                                        <input matStartDate placeholder="Start date">
                                                        <input matEndDate placeholder="End date">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="range">
                                                    </mat-datepicker-toggle>
                                                    <mat-date-range-picker #range></mat-date-range-picker>
                                                </mat-form-field>
                                            </div>
                                            <div class="col" *ngIf="showDateRange">
                                                <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block"
                                                    floatLabel="always">
                                                    <mat-label>Date Range</mat-label>
                                                    <mat-date-range-input [rangePicker]="range">
                                                        <input matStartDate placeholder="Start date"
                                                            [(ngModel)]="filterStartDate">
                                                        <input matEndDate placeholder="End date"
                                                            [(ngModel)]="filterEndDate">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="range">
                                                    </mat-datepicker-toggle>
                                                    <mat-date-range-picker #range></mat-date-range-picker>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                        <div class="select-skill mb20">
                                            <div class="skill-label">Select Skill</div>
                                            <div class="multi-select">
                                                <span *ngFor="let ses of SessionTypes"><a href="javascript:void(0);"
                                                        [ngClass]="classFlag === true ? 'active' : '' "
                                                        (click)="menuToggle($event,ses.SessionTypeID,'type')">{{ses.SessionTypeName}}</a>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="select-view ">
                                            <div class="view-label">Select View</div>
                                            <div class="single-select">
                                                <ul>
                                                    <li *ngFor="let view of VideoViewTypes"><a
                                                            href="javascript:void(0);"
                                                            [ngClass]="classFlag === true ? 'active' : '' "
                                                            (click)="menuToggle($event,view.VideoViewID,'view')">{{view.VideoViewCode}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="txt-center">
                                        <button mat-raised-button color="primary" class="mb5 mt15"
                                            (click)="ApplySessionFilters()">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Drills -->
                    <div class="col-md-9" *ngIf="IsEmptyDrill">
                        <div class="profile-with-tab br-5 box-shadow">
                            <a href="javascript:void(0);" routerLink="/play-groups" class="theme-button mt5">Back</a>
                            <div class="no-videos txt-center">
                                <img src="assets/images/No-Drills-640.png" class="img-fluid" draggable="false">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9" *ngIf="IsDrillsListShow">
                        <div class="profile-with-tab br-5 box-shadow">
                            <div class="main-tab" rel="drills-db">
                                <a href="javascript:void(0);" rel="batting" class="select">Drills</a>
                            </div>
                            <div class="drills-db">
                                <div class="search-container">
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block mat-dd-field search-txt"
                                                appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768"
                                                        y="0" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3"
                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                        </path>
                                                        <path
                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-select placeholder="Level" [(ngModel)]="SearchLevel"
                                                    name="n_SearchLevel">
                                                    <mat-option>
                                                        All
                                                    </mat-option>
                                                    <mat-option *ngFor="let level of lstDrillLevel"
                                                        [value]="level.DrillLevelName">
                                                        {{level.DrillLevelName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block search-txt" appearance="outline"
                                                floatLabel="always">
                                                <input matInput placeholder="Drill Name or Keywords.." name="n_Search"
                                                    [(ngModel)]="SearchFilter">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2">
                                            <a href="javascript:void(0);" routerLink="/play-groups"
                                                class="theme-button mt5">Back</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="main-tab-container batting" style="display: block;">
                                    <div class="row">
                                        <div class="col-md-4"
                                            *ngFor="let drills of lstGetAthleteDrill|filter:SearchFilter|filter:SearchLevel">
                                            <div class="drill-list mb20 br-5">
                                                <div class="drill-thumbnail">
                                                    <a href="javascript:void(0);" (click)="ShowMyVideo(drills)"
                                                        data-toggle="modal" data-target="#videopreview">
                                                        <img src="{{drills.ThumbnailURL}}" class="img-fluid">
                                                    </a>
                                                    <div class="ico-video"></div>
                                                    <div class="drill-type" style="text-transform:capitalize;">
                                                        {{drills.DrillTypeName}}</div>
                                                    <div class="video-time" style="text-transform:capitalize;">
                                                        {{drills.VideoLength}}</div>
                                                </div>
                                                <figcaption>
                                                    <div class="drill-user-date">
                                                        <div class="drill-user">
                                                            <a href="javascript:void(0);" class="video-user"
                                                                style="text-transform:capitalize;"> <i>Shared By: </i>
                                                                {{drills.CreatedBy}}</a>
                                                        </div>
                                                    </div>
                                                    <div class="time">
                                                        <p>{{drills.CreatedDate}} || {{drills.CreatedTime}}</p>
                                                    </div>
                                                    <div class="drill-name-share">
                                                        <div class="drill-name" style="text-transform:capitalize;">
                                                            {{drills.DrillName}}</div>
                                                        <div class="lib-status">{{drills.Status}}</div>
                                                        <a class="ico-menu" data-toggle="modal"
                                                            data-target="#drillshare"></a>
                                                    </div>
                                                </figcaption>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-9" *ngIf="IsEmptyPlaylist">
                        <div class="profile-with-tab br-5 box-shadow">
                            <a href="javascript:void(0);" routerLink="/play-groups" class="theme-button mt5">Back</a>
                            <div class="no-videos txt-center">
                                <img src="assets/images/no-playlist.png" class="img-fluid" draggable="false">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 coach-sessions-page" *ngIf="IsPlayListShow">
                        <div class="profile-with-tab br-5 box-shadow">
                            <div class="main-tab" rel="drills-db">
                                <a href="javascript:void(0);" rel="batting" class="select">Play Lists</a>
                            </div>
                            <div class="drills-db">
                                <!-- <div class="search-container">
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block mat-dd-field search-txt"
                                                appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768"
                                                        y="0" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3"
                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                        </path>
                                                        <path
                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-select placeholder="Level" [(ngModel)]="SearchLevel"
                                                    name="n_SearchLevel">
                                                    <mat-option>
                                                        All
                                                    </mat-option>
                                                    <mat-option *ngFor="let level of lstDrillLevel"
                                                        [value]="level.DrillLevelName">
                                                        {{level.DrillLevelName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block search-txt" appearance="outline"
                                                floatLabel="always">
                                                <input matInput placeholder="Drill Name or Keywords.." name="n_Search"
                                                    [(ngModel)]="SearchFilter">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2">
                                            <a href="javascript:void(0);" routerLink="/play-groups"
                                                class="theme-button mt5">Back</a>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="main-tab-container batting" style="display: block;">
                                    <div class="row">
                                        <div class="col-md-4" *ngFor="let pl of PlayLists">
                                            <div class="drill-list mb20 br-5">
                                                <div class="drill-thumbnail">
                                                    <a href="javascript:void(0);"
                                                        (click)="playlistClick(pl,videoplayer)" data-toggle="modal"
                                                        data-target="#playlistpreview">
                                                        <img src="{{pl.ThumbnailURL}}" class="img-fluid">
                                                    </a>
                                                    <div class="ico-video"></div>
                                                    <div class="drill-type" style="text-transform:capitalize;">
                                                        {{pl.PlayListName}}</div>
                                                    <!-- <div class="video-time" style="text-transform:capitalize;">
                                                        {{drills.VideoLength}}</div> -->
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div class="row">
                    <div class="col-md-12"></div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Session</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false;">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Session</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>

<div class="modal fade" *ngIf="IsMyVideoShow" id="videopreview" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle" style="text-transform:capitalize;">
                    {{PreviewDrillName}}
                    <span class="skill-type" style="text-transform:capitalize;">{{DrillType}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="drills-popup-video">
                    <div class="vprofile-video">
                        <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none"
                            width="100%" src="{{VideoURL}}" data-setup="{}" disablePictureInPicture
                            controlsList="nodownload" type="video/mp4">
                        </video>
                        <!-- <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                data-setup="{}"  disablePictureInPicture controlsList="nodownload" >
                            <source src="{{VideoURL}}" type="video/mp4" /> </video> -->
                        <!-- <div class="video-time">{{VideoLength}}</div> -->
                    </div>
                    <div class="drill-user-date">
                        <div class="drill-user">
                            <a href="javascript:void(0);" class="video-user"
                                style="text-transform:capitalize;">{{CreatedBy}}</a>
                        </div>
                        <div class="time">
                            <p>{{CreatedDate}} || {{CreatedTime}}</p>
                        </div>
                    </div>
                </div>
                <div class="drills-description">
                    <div class="title">Instruction</div>
                    <ul class="instruction mb15">
                        <li style="text-transform:capitalize;" *ngFor="let stp of lstDrillEditStep;let ind=index;">Step
                            {{ind + 1}} : {{stp.StepText}}
                        </li>
                    </ul>
                    <div class="title">Required Items</div>
                    <ul class="required">
                        <li style="text-transform:capitalize;" *ngFor="let val of MarialsBind">{{val}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="playlistpreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 60%;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle" style="text-transform:capitalize;">
                    {{PlayListName}}

                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closePlayListVideo(videoplayer)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="drills-popup-video">
                    <div class="vprofile-video">
                        <video id="playlist-video" class="video-js" #videoplayer controls preload="none" width="100%"
                            src="{{playlistvideo}}" (timeupdate)="setCurrentTime(videoplayer)" data-setup="{}"
                            disablePictureInPicture controlsList="nodownload" type="video/mp4">
                        </video>
                        <!-- <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                data-setup="{}"  disablePictureInPicture controlsList="nodownload" >
                            <source src="{{VideoURL}}" type="video/mp4" /> </video> -->
                        <!-- <div class="video-time">{{VideoLength}}</div> -->
                    </div>
                    <!-- <div class="drill-user-date">
                        <div class="drill-user">
                            <a href="javascript:void(0);" class="video-user"
                                style="text-transform:capitalize;">{{CreatedBy}}</a>
                        </div>
                        <div class="time">
                            <p>{{CreatedDate}} || {{CreatedTime}}</p>
                        </div>
                    </div> -->
                </div>
                <div class="drills-description">
                    <div class="title">Play List</div>
                    <ul class="instruction mb15">
                        <li style="text-transform:capitalize;" [ngClass]="{'select': ind == currentVideoIndex }"
                            (click)="playlistDetailClick(videoplayer,x,ind)"
                            *ngFor="let x of PlayListDetails;let ind=index;">
                            {{x.SessionName}} : {{x.PMLineLength}}
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>