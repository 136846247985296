<div class="page-container my-videos-page video-analyse-page video-compare-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="row" [ngStyle]="{'flex-direction': isswapped ? 'row-reverse' : 'row' }">
                    <div class="col-md-6">
                        <div class="video-container">
                            <div class="video-item br-5">
                                <div class="vprofile-session vcompare-filter pre-video">

                                    <div class="session">
                                        {{SessionName}} : <span>{{SessionTypeName}}</span>
                                        <span>{{VideoViewName}}</span>
                                    </div>
                                </div>
                                <div class="vprofile-video video-overlay-position">
                                    <video id="videoCompare1" #videoCompare1 controls width="100%"
                                        (loadedmetadata)="LoadMetadata($event, 'videoCompare1')"
                                        (timeupdate)="setCurrentTime(videoCompare1,videoCompare2)"
                                        (ended)="videoEnded(videoCompare1)" crossorigin="anonymous"
                                        disablePictureInPicture controlsList="nodownload" poster="{{sessionsThumbnail}}"
                                        [ngStyle]="{'z-index': showcanvas===true?'1': '5'}" src="{{SessionVideo}}">
                                    </video>
                                    <!-- <video id="videoCompare1" #videoCompare1 controls width="100%"   (loadedmetadata)="LoadMetadata($event, 'videoCompare1')"
                                        (timeupdate)="video1update()" preload="auto" (ended)="videoEnded(videoCompare1)"
                                        crossorigin="anonymous" disablePictureInPicture controlsList="nodownload"
                                        poster="{{sessionsThumbnail}}"
                                        [ngStyle]="{'z-index': showcanvas===true?'1': '5'}" src="{{SessionVideo}}">
                                        
                                    </video> -->

                                    <video [hidden]="!isOverlay" id="my-video" #myvideo
                                        class="video-js video-superimpose" controls preload="none" width="100%"
                                        poster="{{superImposeThum}}" src="{{superImposeURL}}" data-setup="{}">
                                    </video>
                                    <!-- <div class="custom-seekbar" style="z-index:100"
                                        (click)="seekbarclick($event,videoCompare1)"
                                        (mousedown)="seekbarclick($event,videoCompare1)"
                                        (mousemove)="seekbarclick($event,videoCompare1)"
                                        (mouseup)="seekbarclick($event,videoCompare1)" id="custom-seekbar">
                                        <span></span>
                                    </div> -->
                                    <div class="customized-video-controls">
                                        <div class="slidecontainer">
                                            <div class="video-action-progress">
                                                <div class="video-nextprevframe" style="width: 135px;">
                                                    <a href="javascript:void(0);"
                                                        (click)="resume(videoCompare1,'play1')">
                                                        <img *ngIf="play1"
                                                            src="assets/images/annotation/ico_play_128.png"
                                                            title="Play">
                                                        <img *ngIf="!play1"
                                                            src="assets/images/annotation/ico_pause_128.png"
                                                            title="Pause">
                                                    </a>
                                                    <a href="javascript:void(0);" (click)="skip(-0.03,videoCompare1)">
                                                        <img _ngcontent-uol-c192=""
                                                            src="assets/images/annotation/prevframe.png"
                                                            title="Previous Frame"></a>
                                                    <a _ngcontent-uol-c192="" (click)="skip(0.03,videoCompare1)"
                                                        href="javascript:void(0);"><img _ngcontent-uol-c192=""
                                                            src="assets/images/annotation/nextframe.png"
                                                            title="Next Frame"></a>

                                                </div>
                                                <div class="video-progress-container"
                                                    (click)="seekbarclick($event,videoCompare1)"
                                                    (mousemove)="seekbarclick($event,videoCompare1)"
                                                    (mouseup)="seekbarclick($event,videoCompare1)">
                                                    <div class="video-progress-track" title="{{videoCompare1}}"
                                                        (click)="seekbarclick($event,videoCompare1)"
                                                        (mousedown)="seekbarclick($event,videoCompare1)"
                                                        (mousemove)="seekbarclick($event,videoCompare1)"
                                                        (mouseup)="seekbarclick($event,videoCompare1)">
                                                        <div class="video-progress-bar" id="custom-seekbar">
                                                            <!-- <div class="vtrack" title="4.20"></div> -->
                                                        </div>
                                                        <span class="start-time">{{Video1SeekTime}}</span>
                                                        <span class="end-time">{{Video1Duration}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="canvas-parent">
                                        <div class="canvas-container" style="height: 280px;">
                                            <canvas class="drawing1" #canvasDraw width="580" height="280"
                                                (mousedown)="handleMouseDown($event)"
                                                (mousemove)="handleMouseMove($event)" (click)="handleclick($event)"
                                                (mouseup)="handleMouseUpOut($event)"
                                                [ngStyle]="{'z-index': showcanvas===true?'10': '-5'}"></canvas>
                                            <canvas class="drawing2" id="canvasDraw2" #canvasDraw2 width="580"
                                                height="280" (mousedown)="handleMouseDown($event)"
                                                [ngStyle]="{'z-index': showcanvas===true?'9': '-5'}"></canvas>
                                            <canvas class="drawing3" #canvasDraw3 width="580" height="280"
                                                (mousedown)="handleMouseDown($event)"
                                                (mousemove)="handleMouseMove($event)" (click)="handleclick($event)"
                                                (mouseup)="handleMouseUpOut($event)"
                                                [ngStyle]="{'z-index': showcanvas===true && isTextEditor===true?'11': showcanvas===true?'8':'-5'}"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="video-container">
                            <div class="video-item br-5">
                                <div class="vprofile-session vcompare-filter search-container txt-right"
                                    style="padding:15px 10px;">
                                    <div class="session" [hidden]="IsEmptyVideo"
                                        style="width: 70%;float: left;text-align: left;">
                                        {{CompareVideoSession}} : <span>{{CompareVideoSesType}}</span>
                                        <span>{{CompareVideoSesView}}</span>
                                    </div>
                                    <a href="javascript:void(0);" class="theme-button" data-toggle="modal"
                                        data-target="#videofilter" (click)="filterClick()"
                                        style="margin-right: 5px;">Filter</a>
                                    <a href="javascript:void(0);" class="theme-button" data-toggle="modal"
                                        (click)="Back()">Back</a>
                                </div>
                                <div class="vprofile-video" [hidden]="isOverlay">
                                    <div class="col-md-10" [hidden]="!IsEmptyVideo">
                                        <div class="no-videos txt-center">
                                            <img src="assets/images/Comparison_Session.png" style="height: 330px;"
                                                class="img-fluid" draggable="false">
                                        </div>
                                    </div>
                                    <video [hidden]="IsEmptyVideo" width="100%"
                                        (timeupdate)="setCurrentTime(videoCompare1,videoCompare2)" id="videocompare2"
                                        (loadedmetadata)="LoadMetadata($event, 'videoCompare2')" #videoCompare2
                                        class="video-js" controls preload="auto" poster="{{CompareVideoThumbnail}}"
                                        data-setup="{}" src="{{CompareVideoURL}}">
                                    </video>
                                    <!-- <video [hidden]="IsEmptyVideo" (timeupdate)="video2update(videoCompare2)" (loadedmetadata)="LoadMetadata($event, 'videoCompare2')"
                                        id="videocompare2" #videoCompare2 class="video-js" controls preload="auto"
                                        width="100%" poster="{{CompareVideoThumbnail}}" data-setup="{}"
                                        src="{{CompareVideoURL}}">
                                    </video> -->
                                    <div [hidden]="IsEmptyVideo" class="canvas-parent">
                                        <div class="canvas-container" style="height: 280px;">
                                            <canvas class="drawing1" #canvasDraw4 width="580" height="280"
                                                (mousedown)="handleMouseDown($event)"
                                                (mousemove)="handleMouseMove2($event)" (click)="handleclick($event)"
                                                (mouseup)="handleMouseUpOut2($event)"
                                                [ngStyle]="{'z-index': showcanvas===true?'10': '-5'}"></canvas>
                                            <canvas class="drawing2" id="canvasDraw2" #canvasDraw5 width="580"
                                                height="280" (mousedown)="handleMouseDown($event)"
                                                [ngStyle]="{'z-index': showcanvas===true?'9': '-5'}"></canvas>
                                            <canvas class="drawing3" #canvasDraw6 width="580" height="280"
                                                (mousedown)="handleMouseDown($event)"
                                                (mousemove)="handleMouseMove2($event)" (click)="handleclick($event)"
                                                (mouseup)="handleMouseUpOut2($event)"
                                                [ngStyle]="{'z-index': showcanvas===true && isTextEditor===true?'11': showcanvas===true?'8':'-5'}"></canvas>
                                        </div>
                                    </div>
                                    <!-- <div class="custom-seekbar" style="z-index:100"
                                        (click)="seekbarclick($event,videoCompare2)"
                                        (mousedown)="seekbarclick($event,videoCompare2)"
                                        (mousemove)="seekbarclick($event,videoCompare2)"
                                        (mouseup)="seekbarclick($event,videoCompare2)" id="custom-seekbar2">
                                        <span></span>
                                    </div> -->
                                    <div [hidden]="IsEmptyVideo" class="customized-video-controls">
                                        <div class="slidecontainer">
                                            <div class="video-action-progress">
                                                <div class="video-nextprevframe" style="width: 135px;">
                                                    <a href="javascript:void(0);"
                                                        (click)="resume(videoCompare2,'play2')">
                                                        <img *ngIf="play2"
                                                            src="assets/images/annotation/ico_play_128.png"
                                                            title="Play">
                                                        <img *ngIf="!play2"
                                                            src="assets/images/annotation/ico_pause_128.png"
                                                            title="Pause">
                                                    </a>
                                                    <a href="javascript:void(0);" (click)="skip(-0.03,videoCompare2)">
                                                        <img _ngcontent-uol-c192=""
                                                            src="assets/images/annotation/prevframe.png"
                                                            title="Previous Frame"></a>
                                                    <a _ngcontent-uol-c192="" (click)="skip(0.03,videoCompare2)"
                                                        href="javascript:void(0);"><img _ngcontent-uol-c192=""
                                                            src="assets/images/annotation/nextframe.png"
                                                            title="Next Frame"></a>
                                                </div>
                                                <div class="video-progress-container"
                                                    (mousemove)="seekbarclick($event,videoCompare2)"
                                                    (mouseup)="seekbarclick($event,videoCompare2)">
                                                    <div class="video-progress-track" title="{{videoCompare2}}"
                                                        (click)="seekbarclick($event,videoCompare2)"
                                                        (mousedown)="seekbarclick($event,videoCompare2)"
                                                        (mousemove)="seekbarclick($event,videoCompare2)"
                                                        (mouseup)="seekbarclick($event,videoCompare2)">
                                                        <div class="video-progress-bar" id="custom-seekbar2">
                                                            <!-- <div class="vtrack" title="4.20"></div> -->
                                                        </div>
                                                        <span class="start-time" (click)="$event.stopPropagation();">{{Video2SeekTime}}</span>
                                                        <span class="end-time" (click)="$event.stopPropagation();">{{Video2Duration}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <div class="comparison-control box-shadow">
                            <div class="row">
                                <div class="col-md-4" *ngIf="!isTimelineLinked">
                                    <a href="javascript:void(0);" class="linkvideotimeline select"
                                        [ngClass]="{'select':videoType==='linkvideotimeline'}"
                                        (click)="Linktimeline(videoCompare1,videoCompare2)">Link Video Timeline</a>
                                    <!-- <a href="javascript:void(0);" class="linkvideotimeline select"
                                        [ngClass]="{'select':videoType==='linkvideotimeline'}"
                                        (click)="linkVideo(videoCompare1,videoCompare2)">Link Video Timeline</a> -->
                                </div>
                                <div class="col-md-4" *ngIf="isTimelineLinked">
                                    <a href="javascript:void(0);" class="linkvideotimeline select"
                                        [ngClass]="{'select':videoType==='linkvideotimeline'}"
                                        (click)="isTimelineLinked=false">Unlink</a>

                                </div>
                                <div class="col-md-4" *ngIf="isTimelineLinked">
                                    <a href="javascript:void(0);" class="swapvideos"
                                        [ngClass]="{'select':videoType==='swapvideos'}" (click)="SwapVideos();">Swap
                                        Videos</a>
                                </div>

                                <div class="col-md-4" *ngIf="isSuperImpose">
                                    <a href="javascript:void(0);" class="superimpose"
                                        [ngClass]="{'select':videoType==='superimpose'}"
                                        (click)="superImpose(myvideo,videoCompare1,videoCompare2)">Superimpose</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="video-action-progress col-md-12">
                                    <div class="video-playpause " style="width: 126px;">
                                        <a href="javascript:void(0);"
                                            (click)="changeVideoStatus(videoCompare1,videoCompare2,myvideo)">
                                            <img *ngIf="videoStatus=='play'" style="width: 42px;"
                                                src="assets/images/annotation/ico_play_128.png">
                                            <img *ngIf="videoStatus=='pause'" style="width: 42px;"
                                                src="assets/images/annotation/ico_pause_128.png">
                                        </a>
                                        <a href="javascript:void(0);" (click)="skipCommon(-0.03,videoCompare1,videoCompare2)">
                                            <img _ngcontent-uol-c192="" style="width: 42px;" src="assets/images/annotation/prevframe.png"
                                                title="Previous Frame"></a>
                                    <a _ngcontent-uol-c192="" (click)="skipCommon(0.03,videoCompare1,videoCompare2)"
                                            href="javascript:void(0);"><img _ngcontent-uol-c192="" style="width: 42px;"
                                                src="assets/images/annotation/nextframe.png" title="Next Frame"></a>
                                    </div>
                                    <div class="video-progress-container" style= "width: calc(100% - 136px);"
                                        (mousemove)="compareSeekbarclick($event,videoCompare1,videoCompare2,myvideo)"
                                        (mouseup)="compareSeekbarclick($event,videoCompare1,videoCompare2,myvideo)">
                                        <div class="video-progress-track"
                                            (click)="compareSeekbarclick($event,videoCompare1,videoCompare2,myvideo)"
                                            (mousedown)="compareSeekbarclick($event,videoCompare1,videoCompare2,myvideo)"
                                            (mousemove)="compareSeekbarclick($event,videoCompare1,videoCompare2,myvideo)"
                                            (mouseup)="compareSeekbarclick($event,videoCompare1,videoCompare2,myvideo)"
                                            id="compare-seekbar">
                                            <span class="start-time">{{currentCompareTime}}</span>
                                            <span class="end-time">{{currentDurationTime}}</span>
                                            <div class="video-progress-bar" id="video-progress-bar"></div>
                                            <div class="vpoint1" [ngStyle]="{'left':comStartPercentage+'%'}"></div>
                                            <div class="vpoint2" [ngStyle]="{'left':secondvideoStartPercentage+'%'}">
                                            </div>
                                        </div>


                                    </div>

                                    <!-- <div class="video-progress-container">
                                    <div class="commonCtrl">
                                        <span class="commonPlay" id="commonPlay" *ngIf="showCommonPlay"
                                            (click)="playPause(videoCompare1,videoCompare2)">Play</span>
                                        <span class="commonPause" id="commonPause" *ngIf="!showCommonPlay"
                                            (click)="playPause(videoCompare1,videoCompare2)">Pause</span>
                                        <span class="commonSeekBar">
                                            <input type="range" id="seek-bar" value="0" #seekVal
                                                (change)="seekVideo(seekVal.value,videoCompare1,videoCompare2)"
                                                (mousedown)="pause(videoCompare1,videoCompare2)">
                                            <span *ngIf="videoLinkEnabled" id="videoLinkIndicator"
                                                class="videoLinkIndicator"
                                                [style.left.%]="videoLinkIndicatorPosLeft"><span
                                                    class="videoLinkIndicatorLine"></span></span>
                                        </span>

                                        <span id="commonPlayFromFirstV1" *ngIf="commonPlayFromFirstV1"></span>
                                        <span id="commonPlayFromFirstV2" *ngIf="commonPlayFromFirstV2"></span>

                                    </div> 
                                    <div class="video-progress-track point-fixed" id="compare-seekbar">
                                        <span class="start-time">{{currentCompareTime}}</span>
                                        <span class="end-time">{{currentDurationTime}}</span>
                                        <div class="video-progress-bar" id="video-progress-bar">
                                            
                                        </div>
                                        <div class="vpoint1" style="left:50px;"></div>
                                        <div class="vpoint2" style="left:150px;"></div>
                                    </div>
                                </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>

                </div>
                <div class="row">
                    <div class="vprofile-annotation" style="margin: 0 auto;">

                        <div class="annotation-controls">
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='freehand'}"><img
                                    src="assets/images/annotation/ico_bend_128.png"
                                    (click)="changeAnnotation('freehand',videoCompare1)"></a>
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='line'}"><img
                                    src="assets/images/annotation/ico_line_128.png"
                                    (click)="changeAnnotation('line',videoCompare1)"></a>
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='rectangle'}"><img
                                    src="assets/images/annotation/ico_square_128.png"
                                    (click)="changeAnnotation('rectangle',videoCompare1)"></a>
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='circle'}"><img
                                    src="assets/images/annotation/ico_cricle_128.png"
                                    (click)="changeAnnotation('circle',videoCompare1)"></a>
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='arrow'}"><img
                                    src="assets/images/annotation/ico_arrow_128.png"
                                    (click)="changeAnnotation('arrow',videoCompare1)"></a>
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='angle'}"><img
                                    src="assets/images/annotation/ico_angle_128.png"
                                    (click)="changeAnnotation('angle',videoCompare1)"></a>
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='scale'}"><img
                                    src="assets/images/annotation/ico_ruler_128.png"
                                    (click)="changeAnnotation('scale',videoCompare1)"></a>
                            <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='text'}"><img
                                    src="assets/images/annotation/ico_addtext_128.png"
                                    (click)="changeAnnotation('text',videoCompare1)"></a>
                            <!-- <a href="javascript:void(0);"><img
                                    src="assets/images/annotation/ico_saveaudio_128.png"
                                    (click)="playVideo(videoCompare1)"></a> -->
                            <a href="javascript:void(0);"><img src="assets/images/annotation/ico_rotateleft_128.png"
                                    (click)="undoAnnotation(videoCompare1)"></a>
                            <!-- <a href="javascript:void(0);"><img
                                    src="assets/images/annotation/ico_rotateright_128.png" (click)="redoAnnotation(videoCompare1)"></a> -->
                            <!-- <a href="javascript:void(0);"><img 
                                    src="assets/images/annotation/ico_save_128.png" (click)="saveAnnotation(videoCompare1)"></a> -->
                            <a href="javascript:void(0);" title="Start Annotation"><img
                                    src="assets/images/annotation/ico_close_128.png"
                                    (click)="startAnnotation(videoCompare1)"></a>
                            <!-- <a href="javascript:void(0);" (click)="resume(videoCompare1,'play1')">V1</a>
                            <a href="javascript:void(0);" (click)="resume(videoCompare2,'play2')">V2</a> -->
                            <input id="colorPicker" [(colorPicker)]="drawcolor" [style.background]="drawcolor"
                                style="padding: 12px;height: 20px;width: 20px;border-radius: 15px;cursor: pointer;"
                                [(ngModel)]="drawcolor" />

                            <div class="measurementInputs" *ngIf="isScaleRular">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input type="number" class="form-controls" [(ngModel)]="unitValue"
                                            (selectionChange)="unitTypeChange()" placeholder="Enter unit value">
                                    </div>
                                    <div class="col-md-4">
                                        <select class="form-controls" (selectionChange)="unitTypeChange()"
                                            [(ngModel)]="unitType">
                                            <option value="feet">Feet</option>
                                            <option value="meter">Meter</option>
                                            <option value="cm">CM</option>
                                            <option value="inches">Inches</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="measure-btn">
                                            <a href="javascript:void(0);" (click)="setScaleRular()">Ok</a>
                                            <a href="javascript:void(0);" (click)="isScaleRular=false;">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="measurementInputs" *ngIf="isTextEditor">
                                <div class="row">
                                    <div class="col-md-8">
                                        <input id="text_tool" type="text" class="form-controls">
                                    </div>
                                    <div class="col-md-4">
                                        <div class="measure-btn">
                                            <a href="javascript:void(0);" (click)="addText()">Ok</a>
                                            <a href="javascript:void(0);" (click)="isTextEditor=false;">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Video Preview Modal -->
<div class="modal fade" id="videofilter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Advance Filter</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="" style="border-bottom: 1px solid #ccc;">
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                floatLabel="always">
                                <mat-select placeholder="Select Skill" [(ngModel)]="_filterSkills">
                                    <mat-option>All</mat-option>
                                    <mat-option [value]="typ.SessionTypeName" *ngFor="let typ of listSessionType">
                                        {{typ.SessionTypeName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                floatLabel="always">
                                <mat-select placeholder="Select View" [(ngModel)]="_filterView">
                                    <mat-option>All</mat-option>
                                    <mat-option [value]="viw.VideoViewName" *ngFor="let viw of listVideoView">
                                        {{viw.VideoViewName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                floatLabel="always">
                                <mat-select placeholder="Select Session Name" [(ngModel)]="_filterSessions">
                                    <mat-option>All</mat-option>
                                    <mat-option [value]="vid.SessionName" *ngFor="let vid of lsitFilterVideos">
                                        {{vid.SessionName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <div class="txt-center">
                                <a href="javascript:void(0);" class="theme-button mt25" data-dismiss="modal"
                                    aria-label="Close">Apply</a>
                            </div>
                            <!-- <div class="txt-center">
                                <a href="javascript:void(0);" class="theme-button mt25">Submit</a>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4"
                        *ngFor="let filVid of lsitFilterVideos| filter:_filterSkills | filter:_filterView | filter:_filterSessions">
                        <div class="drill-list mb20 br-5" [ngClass]="(filVid.SessionID==CompareSessionID)?'select':''">
                            <div class="drill-thumbnail">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                    (click)="chooseVideoToCompare(filVid);">
                                    <img src="{{filVid.ThumbnailURL}}" class="img-fluid">
                                </a>
                                <div class="ico-video"></div>
                                <div class="drill-type">{{filVid.SessionTypeName}}</div>
                                <div class="video-time">{{filVid.VideoLength}}</div>
                            </div>
                            <figcaption>
                                <div class="drill-user-date">
                                    <div class="drill-user">
                                        <a href="javascript:void(0);" class="video-user">{{filVid.PlayerName}}</a>
                                    </div>
                                    <div class="time">
                                        <p>{{filVid.SessionDate}}</p>
                                    </div>
                                </div>
                                <div class="drill-name-share">
                                    <div class="drill-name">{{filVid.SessionName}}</div>
                                    <a href="javascript:void(0);" class="ico-menu" data-toggle="modal"
                                        data-target="#drillshare"></a>
                                </div>
                            </figcaption>
                        </div>
                    </div>

                </div>

                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="txt-center">
                            <a href="javascript:void(0);" class="theme-button mt5" data-dismiss="modal"
                                aria-label="Close">Apply</a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>