<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container">
    <div class="container">
        <div class="input-container">
            <!-- <div class="white">
                <div class="row">
                    <div class="col-md-12">
                        Video Tagging
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="white">   

                    <div class="search-container">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                                    <mat-select placeholder="Accademy"  [(ngModel)]="_searchAssociation"   style="text-transform:capitalize;">
                                        <mat-option> All </mat-option>
                                        <mat-option [value]="asso.AssociationName"  *ngFor="let asso of lstAssociation" >
                                            {{asso.AssociationName}}</mat-option>
                                    </mat-select> 
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-md-4">
                                <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                                    <mat-select placeholder="Select Status" [(ngModel)]="_searchStatus" style="text-transform:capitalize;" >
                                        <mat-option [value]="1">Start</mat-option>
                                        <mat-option [value]="2">InProgress</mat-option>
                                        <mat-option [value]="3">Completed</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> -->
                            <div class="col-md-2">
                                <div class="txt-right add-new">
                                    <!-- <a routerLink="/video-tagging">Add New</a> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="customscroll-table video-tag-tbl">
                        <div>
                            <ngx-datatable class="material" [rows]="lstSessions|filter:_searchAssociation|filter:_searchStatus"
                                [columnMode]="'force'" [headerHeight]="42" [footerHeight]="50"
                                [rowHeight]="42" [limit]="rowLimits" [scrollbarV]="false" [scrollbarH]="true" >
            
                                <ngx-datatable-column name="S No" [width]="20">
                                    <ng-template let-rowIndex="rowIndex" let-row="row"
                                        ngx-datatable-cell-template>
                                        <span>{{rowIndex+1}}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Session Date" prop="SessionDate" [width]="50">
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Academy Name" prop="AssociationName" [width]="200">
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Athlete Name" prop="AthleteName" [width]="100">
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Session Name" prop="SessionName" [width]="100">
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Tagged Date" prop="TaggedDate" [width]="50">
                                </ngx-datatable-column>

                                <ngx-datatable-column  name="Status" [width]="100" prop="Status" >
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template >
                                        <span>
                                            <div class="status" *ngIf="row.Status=='Start'"
                                                 (click)="GotoEngine(row)">{{value}}
                                            </div>
                                            <div class="status completed" *ngIf="row.Status=='Completed'"
                                                    (click)="GotoEngine(row)">{{value}}
                                            </div>
                                            <div class="status" *ngIf="row.Status=='In Progress'"
                                                    (click)="GotoEngine(row)">{{value}}
                                            </div>
                                        </span>
                                     </ng-template>
                                </ngx-datatable-column>

                                 <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                        let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage"
                                        let-offset="offset" let-isVisible="isVisible">
            
                                        <div class="footer-page-size-position">show</div>
                                        <div class="footer-page-size-position">
                                            <mat-select style="text-align: center" class="mat-select-position"
                                                [(value)]="rowLimits" (selectionChange)="changeRowLimits($event.value)">
                                                <mat-option *ngFor="let limit of LIMITARRAY" [value]="limit.value">
                                                    {{limit.value}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="footer-page-size-position">{{rowLimits}} entries</div>
                                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                                [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                                [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)" [selectAllRowsOnPage]="false"
                                                (change)="table.onFooterPage($event)">
                                        </datatable-pager>
                                    </ng-template>
                                </ngx-datatable-footer>

                            </ngx-datatable>
                        </div>
                    </div>

                    

                </div>
            </div>
        </div>
    </div>
</div>



<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Video Tagging</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Video Tagging</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Video Tagging</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
                (click)="isWarningAlert=false">Ok</button>
        </div>
    </div>
</div>