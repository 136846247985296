import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MastersServiceService } from '../../masters-service.service';
import { CoachpadPlayerwallComponent } from '../coachpad-playerwall/coachpad-playerwall.component';

import * as moment from 'moment';
import 'fullcalendar';

export interface User {
  name: string;
}

@Component({
  selector: 'app-coaching',
  templateUrl: './coaching.component.html',
  styleUrls: ['./coaching.component.css']
})
export class CoachingComponent implements OnInit {
  @ViewChild(CoachpadPlayerwallComponent) child;
  constructor(public utility: UtilitiesService, private service: MastersServiceService,) { }

  public UserCode: string;
  public ActiveSessions: any;
  public ActiveRequests: any;
  public CompletedSessions: any;
  Pagetitle: string = "Coaching";
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).UserID);
    this.LoadActivePlayers();
    this.LoadRequestedPlayers();
    this.LoadCompletedPlayers();
  }

  LoadActivePlayers() {
    this.service.CoachProfileSettings({
      method: "CoachPad/CoachResponses",
      request: { ActionFlag: "MyActive", RefUserID: String(this.UserCode) }
    }).subscribe((ret: any = []) => {
      if (ret.Message = 'Success') {
        this.ActiveSessions = ret.MyPendingRequests;
      }
    });
  }
  LoadRequestedPlayers() {
    this.service.CoachProfileSettings({
      method: "CoachPad/CoachResponses",
      request: { ActionFlag: "MyRequest", RefUserID: String(this.UserCode) }
    }).subscribe((ret: any = []) => {
      if (ret.Message = 'Success') {
        this.ActiveRequests = ret.MyPendingRequests;
      }
    });
  }
  LoadCompletedPlayers() {
    this.service.CoachProfileSettings({
      method: "CoachPad/CoachResponses",
      request: { ActionFlag: "MyCompleted", RefUserID: String(this.UserCode) }
    }).subscribe((ret: any = []) => {
      if (ret.Message = 'Success') {
        this.CompletedSessions = ret.MyPendingRequests;
      }
    });
  }
  AcceptRequest(data) {
    this.service.CoachProfileSettings({
      method: "CoachPad/CoachResponses",
      request: { ActionFlag: "Accept", RefUserID: String(this.UserCode), RequestID: String(data.RequestID) }
    }).subscribe((ret: any = []) => {

      this.LoadActivePlayers();
      this.LoadRequestedPlayers();
    });
  }
  IgnoreRequest(data) {
    this.service.CoachProfileSettings({
      method: "CoachPad/CoachResponses",
      request: { ActionFlag: "Reject", RefUserID: String(this.UserCode), RequestID: String(data.RequestID) }
    }).subscribe((ret: any = []) => {
      this.LoadRequestedPlayers();
    });
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }
  Back() { 
    this.Pagetitle = "Coaching";
  }
  loadPlayerwall(data) {
    this.utility.coachDetails = data;
    this.Pagetitle = "PlayerWall";
  }

  ngOnChanges() {
    this.Pagetitle = this.child.PageTitle;
  }
}
