import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

//import { LoginComponent } from './login/LoginComponent.ts';
import { AuthService } from './auth.service';

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {

     
  }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> { 
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn:boolean) => {
        if (!isLoggedIn.valueOf()) { 
          this.router.navigate(['/landing']);
          return false;
        }
        // else  if (isLoggedIn.valueOf()){
        //     this.router.navigate(['/']);
        //     return false;
        // } 
        return true;
      })
    );
  }
}