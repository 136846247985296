<div class="page-container academy-dashboard-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-3">
                    <div class="academy-hdr-widget one package">
                        <div class="academy-body">
                            <p>Total Registered Users</p>
                            <h2>{{TotalUsers.RegisteredUsers}} 
                                <!-- <span><i class="fa fa-arrow-up" aria-hidden="true"></i> 23% (from last
                                    month)</span> -->
                                </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="academy-hdr-widget two package">
                        <div class="academy-body">
                            <p>Active Users</p>
                            <h2>{{TotalUsers.ActiveUsers}} 
                                <!-- <span><i class="fa fa-arrow-up" aria-hidden="true"></i> 23% (from last month)</span> -->
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="academy-hdr-widget three package">
                        <div class="academy-body">
                            <p>Users by Package Category</p>
                            <ul class="dash-list">
                                <li *ngFor="let packs of PlayersbyPackage|sort:'PackageID'">
                                    <strong>{{packs.TotalUsers}}</strong>
                                    <span>{{packs.PackageName}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="academy-hdr-widget three package">
                        <div class="academy-body">
                            <p>Users by Package Duration</p>
                            <ul class="dash-list">
                                <li *ngFor="let packs of PlayersbyDuration|sort:'DurationID'">
                                    <strong>{{packs.TotalUsers}}</strong>
                                    <span>{{packs.DurationName}}</span>
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="card-container box-shadow">
                                <div class="card-hdr">
                                    <div class="cart-title">Active Users Per Month</div>
                                </div>
                                <div class="card-body">
                                    <div class="chart-container">
                                        <div class="chartjs-container">
                                            <canvas baseChart [datasets]="UBMChartData" [labels]="usersbyMonthChartLabels"
                                                [options]="barChartOptions" [plugins]="barChartPlugins"
                                                [legend]="false" [chartType]="'bar'">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="card-container box-shadow">
                                <div class="card-hdr">
                                    <div class="cart-title">Active Users by Package Category</div>
                                </div>
                                <div class="card-body">
                                    <div class="chart-container">
                                        <div class="chartjs-container" style="height: 328px; width: 100%;">
                                            <canvas baseChart [data]="UBPChartData" [labels]="userbyPackageLabels"
                                                [chartType]="'pie'">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <div class="card-container box-shadow">
                                <div class="card-hdr">
                                    <div class="cart-title">Revenue by Month</div>
                                </div>
                                <div class="card-body">
                                    <div class="chart-container">
                                        <div class="chartjs-container">
                                            <canvas baseChart [datasets]="RBMChartData" [labels]="revenuebyMonthLabels"
                                                [options]="barChartOptions" [plugins]="barChartPlugins"
                                                [legend]="false" [chartType]="'bar'">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="card-container box-shadow">
                                <div class="card-hdr">
                                    <div class="cart-title">Active Users by Package Duration</div>
                                </div>
                                <div class="card-body">
                                    <div class="chart-container">
                                        <!-- <div class="chartjs-container" style="height: 328px; width: 100%;">
                                        <canvas baseChart 
                                        [data]="doughnutChartData" 
                                        [labels]="doughnutChartLabels" 
                                        [colors]="doughnutChartColors"
                                        [chartType]="doughnutChartType">
                                        </canvas>
                                    </div> -->
                                        <div class="chartjs-container" style="height: 328px; width: 100%;">
                                            <canvas baseChart [data]="UBDChartData" [labels]="userbyDurationLabels"
                                                [chartType]="'pie'">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>