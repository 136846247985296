import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { CoachpadPlayerComponent } from '../coachpad-player/coachpad-player.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig, matSnackBarAnimations, MatSnackBarDismiss } from '@angular/material/snack-bar';

@Component({
  selector: 'app-coach-profile',
  templateUrl: './coach-profile.component.html',
  styleUrls: ['./coach-profile.component.css']
})
export class CoachProfileComponent implements OnInit {
  constructor(public utility: UtilitiesService, private service: MastersServiceService, private mats: MatSnackBar) { }
  public UserCode: string;
  @Output() buttonClicks: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.getCoachDetails(this.utility.coachDetails);
    this.UserCode = String(JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).UserID);
  }
  CoachesDetails: any = [];
  CoachPhotos: any = [];
  CoachVideos: any = [];
  CoachReviews: any = [];
  videopreview: boolean = false;
  videoFile: string;
  totalsession: number = 1;
  getCoachDetails(data) {
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachMarketPlace",
      request: { ActionFlag: "GetCoachProfile", RefUserID: String(data.RefUserID), CoachID: String(data.CoachID), ProSettingsID: String(data.ProSettingsID) }
    }).subscribe((ret: any = []) => {
      this.CoachesDetails = ret.CoachesDetails[0];
      this.CoachPhotos = ret.CoachPhotos;
      this.CoachVideos = ret.Coachvideos;
      this.CoachReviews = ret.CoachReviews;
    });
  }
  back() {
    this.utility.coachDetails.Pagetitle = '';
    this.utility.coachDetails.selectedTab = 'LoadCoaches';
  }
  openVideo(File) {
    this.videopreview = true;
    this.videoFile = File;
  }
  addsession(val) {
    if ((this.totalsession + val) > 0 && (this.totalsession + val) <= 3)
      this.totalsession += val;
  }

  sendRequest() {
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/PlayerRequests",
      request: {
        ActionFlag: "SendRequest", RefUserID: String(this.UserCode), CoachID: String(this.CoachesDetails.RefUserID),
        NoOfSessionRequired: String(this.totalsession), FeePerSession: String(this.CoachesDetails.FeePerSession)
      }
    }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.mats.open('Request sent', 'Close', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000
        });
      }
      else {
        this.mats.open(ret.Message, 'Close', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000
        });
      }
    });
  }
}
