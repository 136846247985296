import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { AuthService } from '../auth/auth.service';
import { MastersServiceService } from '../masters-service.service';
import { ThrowStmt } from '@angular/compiler';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

import { AngularFirestore } from '@angular/fire/firestore';
//import { getDatabase, ref, onValue} from "firebase/database";
import { AngularFireDatabase, AngularFireList, DatabaseSnapshot } from '@angular/fire/database';

declare var $: any

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  RegisterForm: FormGroup;
  athleteProfile: FormGroup;
  LoginForm: FormGroup;
  coachProfile: FormGroup;
  public InputData: Inputs[] = [
    { LoginName: "", Password: "", ActionFlag: "Validate" }
  ]
  public isRegister: boolean = false;
  public isLogin: boolean = false;
  public isProfile: boolean = false;
  public isCoach: boolean = false;

  public FirstName: string;
  public LastName: string;
  public UserName: string;
  public LoginMobile: string;
  public LoginEmail: string;

  public LPassword: string;
  public ConfLoginPwd: string;
  public Gender: string;
  public DOB: string;
  public MemberTypeID: string = "1";
  public submitDisable: boolean = true;
  public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];

  public ageconfirm: boolean;
  public privacyconfirm: boolean;
  public athleteProfileReg: boolean;
  public coachProfileReg: boolean;
  public MemberLevelList: any[];
  public BattingTypeList: any[];
  public BattingOrderList: any[];
  public PlayerRoleList: any[];
  public BowlingStyleList: any[];
  public BowlingTypeList: any[];
  public BowlingSpecTypeList: any[];
  public MemberSpecList: any[];
  public IsWicketKeeper: boolean;

  public DisplayName: string;
  public MemberLevelID: string = "";
  public BatStyle: string = "";
  public BatOrder: string = "";
  public PlayerRole: string = "";
  public BowlingStyle: string = "";
  public bowltype: string = "";
  public bowlspec: string = "";
  public Height: string;
  public Weight: string;
  public PGoal: string;
  public PlayerImage: string;

  public CoachDisplayName: string;
  public CoachMemberLevelID: string;
  public MemberSpec: string;
  public CoachDesc: string;
  public CoachCertificate: string;
  public CoachImage: string;

  public userName: string;
  public userPassword: string;
  public isShowLoaderSubmit: boolean = false;
  public isShowLoader: boolean = false;
  public minDate: Date;
  public maxDate: Date;
  public BowlingSPTypeListFiltered: any = [];
  //public emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  public emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;

  public filOfcRoleUpdate: any = []; public croppedImage: string;
  public imageChangedEvent: any = '';
  public imageChangedType: string = '';
  public displayImageCrop: string = 'none';

  public RegUserID: string = "";
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  constructor(private utility: UtilitiesService, private service: MastersServiceService,
    private aservice: AuthService, private formBuilder: FormBuilder, firestore: AngularFirestore) {
    this.utility.pageLoadScript();

  }

  ngOnInit(): void {
    this.RegUserID = "";
    //alert('dfaf');
    this.RegisterForm = this.formBuilder.group({
      n_first_name: ['', Validators.required],
      n_last_name: [],
      // n_Username: ['', Validators.required],
      n_mobile_number: ['', Validators.required],
      n_email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      //n_password: ['', Validators.required],
      //n_confirm_password: ['', Validators.required],
      n_DOB: ['', Validators.required],
      gender: ['', Validators.required],
      n_ageconfirms: [],
      n_privacyconfirms: []
    }, {
      validator: DOBValidate('n_DOB')
    });
    this.athleteProfile = this.formBuilder.group({
      n_DisplayName: [],
      n_MemberLevel: ['', Validators.required],
      n_BatStyle: ['', Validators.required],
      n_BatOrder: ['', Validators.required],
      n_PlayerRole: ['', Validators.required],
      n_IsWicketKeeper: [],
      n_BowlingStyle: [],
      n_bowltype: [],
      n_bowlspec: [],
      n_Height: ['', Validators.required],
      n_Weight: ['', Validators.required],
      n_PGoal: []
    });
    this.LoginForm = this.formBuilder.group({
      n_userName: ['', Validators.required],
      n_Password: ['', Validators.required]
    });
    this.coachProfile = this.formBuilder.group({
      n_CoachDisplayName: ['', Validators.required],
      n_CoachMemberLevelID: ['', Validators.required],
      n_MemberSpec: ['', Validators.required],
      n_CoachDesc: [],
      n_CoachCertificate: []
    });
  }


  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  showRegister() {
    this.ngOnInit();
    this.isRegister = true;
    this.isLogin = false;
    this.submitDisable = true;
    this.isProfile = false;
    this.isCoach = false;
    this.clearValues();
    this.MemberTypeID = "1";

    var minCurrentDate = new Date();
    var maxNewDate = new Date();
    this.minDate = minCurrentDate;
    this.maxDate = new Date(maxNewDate.getFullYear() - 5, maxNewDate.getMonth(), maxNewDate.getDate());
    //this.LoadAthleteReg();
  }
  hideRegister() {
    this.isRegister = false;
    this.ngOnInit();
  }
  showLogin() {
    this.isLogin = true;
    this.isRegister = false;
    this.ngOnInit();
  }
  hideLogin() {
    this.isLogin = false;

  }
  showProfile() {
    this.isProfile = true;
    this.isRegister = false;
    this.ngOnInit();
  }
  hideProfile() {
    this.isProfile = false;
  }
  showCoach() {
    this.isCoach = true;
    this.isRegister = false;
  }
  hideCoach() {
    this.isCoach = false;
  }
  GenderChange(gen) {
    debugger;

    this.Gender = gen;
  }

  SelectMemberType(type) {
    debugger;
    this.MemberTypeID = type;

  }

  onSubmitSignUp() {

    // stop here if form is invalid
    if (this.RegisterForm.invalid) {
      return;
    }
    else {
      this.submitSignUp();
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.associationBasic.value, null, 4));
  }

  onDOBChange() {
    var startDate = new Date(this.DOB);
    var currDate = new Date();
    var diffDate = new Date(currDate).valueOf() - new Date(startDate).valueOf();
    diffDate = diffDate / (1000 * 60 * 60 * 24);
    diffDate = diffDate / 365;
    if (diffDate < 13) {
    }
  }
  submitSignUp() {
    this.isShowLoaderSubmit = true;

    this.service.DeviceSignup({
      FirstName: this.FirstName, LastName: this.LastName, LoginMobile: this.LoginMobile,
      LoginEmail: this.LoginEmail, Gender: this.Gender, dob: this.DOB,
      MemberTypeID: this.MemberTypeID, DeviceID: "", ActionFlag: "Register"
    }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        sessionStorage.setItem("userId", ret.UserID);
        this.RegUserID = ret.UserID;
        //this.utility.openSnackBar(sessionStorage.getItem("userId"));
        this.isShowLoaderSubmit = false;

        this.successAlert = 'Thank you for choosing Third Eye, please check your mail for Credentials.';
        this.isSuccessAlert = true;

        if (this.MemberTypeID == "1") {
          this.LoadAthleteReg();
          this.isCoach = false;
          this.isRegister = false;
          this.isProfile = true;
          this.isShowLoaderSubmit = false;

        } else if (this.MemberTypeID == "2") {
          this.isCoach = true;
          this.isProfile = false;
          this.isRegister = false;
          this.LoadCoachReg();
          this.isShowLoaderSubmit = false;
        }
      }
      else {
        this.isShowLoaderSubmit = false;
        this.utility.openSnackBar(ret.Message);
      }
    });
  }

  onPwdChange() {
    // if (this.LPassword === this.ConfLoginPwd) {
    //   alert("ok");
    // } else {
    //   alert("not ok");
    // }
  }
  ClearProfile() {
    this.DisplayName = "";
    this.IsWicketKeeper = false;
    this.Height = "";
    this.Weight = "";
    this.PGoal = "";
    this.CoachDisplayName = "";
    this.CoachDesc = "";
    this.CoachCertificate = "";
    this.MemberSpec = "";
    this.CoachMemberLevelID = "-1";
  }

  onConfirmChange() {
    if (this.ageconfirm == true && this.privacyconfirm == true) {
      this.submitDisable = false;
    }
    else {
      this.submitDisable = true;
    }
  }
  LoadAthleteReg() {
    this.ClearProfile();
    this.isShowLoader = true;
    this.service.AtheleticLoad({ UserID: this.RegUserID, ActionFlag: "Load" }).subscribe((ret: any = []) => {
      this.MemberLevelList = ret.MemberLevelList;
      this.BattingTypeList = ret.BattingTypeList;
      this.BattingOrderList = ret.BattingOrderList;
      this.PlayerRoleList = ret.PlayerRoleList;
      this.BowlingStyleList = ret.BowlingStyleList;
      this.BowlingTypeList = ret.BowlingTypeList;
      this.BowlingSpecTypeList = ret.BowlingSpecTypeList;
      this.BowlingSPTypeListFiltered = ret.BowlingSpecTypeList;

      this.isShowLoader = false;
    });

    this.RegisterForm = this.formBuilder.group({
      n_MemberLevel: [],
      n_DisplayName: [],
      n_BatStyle: [],
      n_BatOrder: [],
      n_PlayerRole: [],
      n_BowlingStyle: [],
      n_bowltype: [],
      n_bowlspec: [],
      n_Height: [],
      n_Weight: [],
      n_PGoal: []

    });
  }

  showbowltype() {

    this.BowlingSPTypeListFiltered = this.BowlingSpecTypeList.filter(sptype => sptype.BowlingTypeID === Number(this.bowltype));
    if (this.bowltype == "2") {
      this.BowlingSPTypeListFiltered = this.BowlingSPTypeListFiltered.filter(b => b.BowlingStyleName === Number(this.BowlingStyle));
    }
    // if (this.bowltype == "2") {
    //   this.BowlingSPTypeListFiltered = this.BowlingSPTypeListFiltered.filter(sptype => sptype.StyleID === this.BowlingStyle);
    // }
  }

  onSubmitAthleteInfo() {

    // stop here if form is invalid
    if (this.athleteProfile.invalid) {
      return;
    }
    else {
      this.saveAthleteInfo();
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.associationBasic.value, null, 4));
  }
  saveAthleteInfo() {
    var profileImage = "";
    let isWkt: string;
    if (this.IsWicketKeeper == true) { isWkt = "1" }
    else if (this.IsWicketKeeper == false) { isWkt = "0" }

    if (this.ImageDetails[0].data != "") {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          // profileImage = ret.name;
          this.service.AtheleteRegistration({
            DisplayName: this.DisplayName, LevelID: this.MemberLevelID, BattingTypeID: this.BatStyle
            , BattingOrderID: this.BatOrder, PlayerRoleID: this.PlayerRole, BowlingStyleID: this.BowlingStyle, BowlingTypeID: this.bowltype
            , BowlingSpecID: this.bowlspec, IsWicketKeeper: String(isWkt), Height: this.Height, Weight: this.Weight, Goal: this.PGoal, PlayerImage: profileImage
            , ActionFlag: "Register", UserID: sessionStorage.getItem("userId")
          }).subscribe((ret: any = []) => {
            if (ret.Status == true) {
              this.successAlert = 'Profile updated Successfully.';
              this.isSuccessAlert = true;
              this.isRegister = false;
              this.isCoach = false;
              this.isProfile = false;
              this.isLogin = true;
            }
            else {
              this.errorAlert = ret.Message;
              this.isErrorAlert = true;
            }
          });
        }
      })
    }
    else {
      this.service.AtheleteRegistration({
        DisplayName: this.DisplayName, LevelID: this.MemberLevelID, BattingTypeID: this.BatStyle
        , BattingOrderID: this.BatOrder, PlayerRoleID: this.PlayerRole, BowlingStyleID: this.BowlingStyle, BowlingTypeID: this.bowltype
        , BowlingSpecID: this.bowlspec, IsWicketKeeper: "", Height: this.Height, Weight: this.Weight, Goal: this.PGoal, PlayerImage: profileImage
        , ActionFlag: "Register", UserID: sessionStorage.getItem("userId")
      }).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          this.successAlert = 'Profile updated Successfully.';
          this.isSuccessAlert = true;
          this.isRegister = false;
          this.isCoach = false;
          this.isProfile = false;
          this.isLogin = true;
        }
        else {
          this.errorAlert = ret.Message;
          this.isErrorAlert = true;
        }
      });
    }
  }
  LoadCoachReg() {
    this.ClearProfile();
    this.service.CoachLoad({ UserID: this.RegUserID, ActionFlag: "Load" }).subscribe((ret: any = []) => {
      this.MemberLevelList = ret.MemberLevelList;
      this.MemberSpecList = ret.MemberSpecList;
    });
  }
  saveCoachInfo() {
    if (this.ImageDetails[0].data != "") {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          this.service.CoachRegistration({
            DisplayName: this.CoachDisplayName, LevelID: this.CoachMemberLevelID, SpecID: this.MemberSpec
            , Description: this.CoachDesc, CoachImage: ret.name, CertifyAgency: this.CoachCertificate
            , ActionFlag: "Register", UserID: sessionStorage.getItem("userId")
          }).subscribe((ret: any = []) => {

            if (ret.Status == true) {
              this.successAlert = 'Profile updated Successfully.';
              this.isSuccessAlert = true;
              this.isRegister = false;
              this.isCoach = false;
              this.isProfile = false;
              this.isLogin = true;
            } else {
              this.errorAlert = ret.Message;
              this.isErrorAlert = true;
            }
          });
        }
      });
    }
    else {
      this.service.CoachRegistration({
        DisplayName: this.CoachDisplayName, LevelID: this.CoachMemberLevelID, SpecID: this.MemberSpec
        , Description: this.CoachDesc, CoachImage: "", CertifyAgency: this.CoachCertificate
        , ActionFlag: "Register", UserID: sessionStorage.getItem("userId")
      }).subscribe((ret: any = []) => {

        if (ret.Status == true) {
          this.successAlert = 'Profile updated Successfully.';
          this.isSuccessAlert = true;
          this.isRegister = false;
          this.isCoach = false;
          this.isProfile = false;
          this.isLogin = true;
        } else {
          this.errorAlert = ret.Message;
          this.isErrorAlert = true;
        }
      });
    }
  }
  LoginValidate() {
    this.service.LoginValidate({
      LoginName: this.userName, Password: this.userPassword, MemberType: ""
      , ActionFlag: "Validate"
    }).subscribe((ret: any = []) => {
      //  this.aservice.login(ret.Status);
      let key: string;
      sessionStorage.setItem('loginresponse', JSON.stringify(ret.Status));
      if (ret.Status == true) {
        sessionStorage.setItem('loginUserDetails', JSON.stringify(ret.RowDetails[0]));
        this.service.FiresignIn(ret.RowDetails[0].LoginEmail, ret.RowDetails[0].RegisterNumber).then(indata => {
          this.service.CurrentUserID = indata.user.uid;
          sessionStorage.setItem('firebaseid',indata.user.uid);
         // alert("success");
        }, err => {
          this.service.Firesignup(ret.RowDetails[0].LoginEmail, ret.RowDetails[0].RegisterNumber).then(data => {
            this.service.FiresignIn(ret.RowDetails[0].LoginEmail, ret.RowDetails[0].RegisterNumber).then(retdata => {
              this.service.CurrentUserID = retdata.user.uid;
              console.log('user', retdata.user.uid);
              sessionStorage.setItem('firebaseid',retdata.user.uid);
             // alert("success");
            });
            this.service.SignupLoad({
              UserID: String(ret.RowDetails[0].UserID), FirebaseUID: String(data.user.uid),ActionFlag: "FirebaseSignup"
            }).subscribe((ret: any = []) => {
            });
          }, err => {
           // alert(err);
          })
        });


      }
      this.aservice.login(JSON.parse(sessionStorage.getItem('loginresponse')));
    });
  }
  clearValues() {
    this.FirstName = "";
    this.LastName = "";
    this.UserName = "";
    this.LoginMobile = "";
    this.LoginEmail = "";
    this.LPassword = "";
    this.ConfLoginPwd = "";
    this.DOB = "";
    this.Gender = "";
    this.ageconfirm = false;
    this.privacyconfirm = false;
    this.PlayerImage = "";
    this.CoachImage = "";
  }

  SkiptoLogin() {
    this.isCoach = false;
    this.isLogin = true;
    this.isProfile = false;
    this.isRegister = true;
  }

  onPlayerProfileChange(event, type) {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.PlayerImage = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.PlayerImage, name: file.type });
    //   };
    // }
    const reader = new FileReader();
    this.imageChangedEvent = event;
    this.imageChangedType = type;
    $('#modalcrop').modal('show');
  }

  onCoachProfileChange(event, type) {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.CoachImage = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.CoachImage, name: file.type });
    //   };
    // }
    const reader = new FileReader();
    this.imageChangedEvent = event;
    this.imageChangedType = type;
    $('#modalcrop').modal('show');
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  Applycropper() {
    if (this.imageChangedType == 'athlete') {
      this.PlayerImage = this.croppedImage;
      this.ImageDetails = [];
      this.ImageDetails.push({ data: this.PlayerImage, name: 'image/png' });
      $('#modalcrop').modal('hide');
      // this.PlayerImage="";
    }
    else {
      this.CoachImage = this.croppedImage;
      this.ImageDetails = [];
      this.ImageDetails.push({ data: this.CoachImage, name: 'image/png' });
      $('#modalcrop').modal('hide');
      // this.CoachImage="";
    }


  }





}


export interface Inputs {
  LoginName: string;
  Password: string;
  ActionFlag: string;
}

export function DOBValidate(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    var startDate = new Date(control.value);
    var currDate = new Date();
    var diffDate = new Date(currDate).valueOf() - new Date(startDate).valueOf();
    diffDate = diffDate / (1000 * 60 * 60 * 24);
    diffDate = diffDate / 365;
    if (diffDate < 13) {
      control.setErrors({ DOBValidate: true });
    } else {
      control.setErrors(null);
    }
    //return ((diffDate.toISOString().slice(0, 4) - 1970);

  }
  // return (formGroup: FormGroup) => {
  //   const control = formGroup.controls[controlName];
  //   const matchingControl = formGroup.controls[matchingControlName];

  //   if (matchingControl.errors && !matchingControl.errors.mustMatch) {
  //     // return if another validator has already found an error on the matchingControl
  //     return;
  //   }

  //   // set error on matchingControl if validation fails
  //   if (control.value !== matchingControl.value) {
  //     matchingControl.setErrors({ mustMatch: true });
  //   } else {
  //     matchingControl.setErrors(null);
  //   }
  // }
}

export interface ImageDetails { data: string, name: string }