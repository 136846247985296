import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { LandingLayoutComponent } from './layout/landing-layout.component';
import { HomeLayoutComponent } from './layout/home-layout.component';

import { LandingComponent } from './landing/landing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlayerComponent } from './registration/player/player.component';
import { CoachComponent } from './registration/coach/coach.component';
import { TrainerComponent } from './registration/trainer/trainer.component';
import { PhysioComponent } from './registration/physio/physio.component';
import { NutritionistComponent } from './registration/nutritionist/nutritionist.component';
import { AssociationRegistrationComponent } from './association-registration/association-registration.component';
import { AssociationListComponent } from './association-list/association-list.component';
import { PlayerListComponent } from './registration/player-list/player-list.component';
import { CoachListComponent } from './registration/coach-list/coach-list.component';
import { TrainerListComponent } from './registration/trainer-list/trainer-list.component';
import { PhysioListComponent } from './registration/physio-list/physio-list.component';
import { DoctorComponent } from './registration/doctor/doctor.component';
import { DoctorListComponent } from './registration/doctor-list/doctor-list.component';
import { NutritionistListComponent } from './registration/nutritionist-list/nutritionist-list.component';
import { MastersComponent } from './masters/masters.component';
import { BatchMasterComponent } from './masters/batch-master/batch-master.component';
import { PayerGroupComponent } from './masters/payer-group/payer-group.component';
import { AthleteMappingComponent } from './masters/athlete-mapping/athlete-mapping.component';
import { SupportStaffMappingComponent } from './masters/support-staff-mapping/support-staff-mapping.component';
import { DashboardAthleteComponent } from './dashboard-athlete/dashboard-athlete.component';
import { MyVideosComponent } from './my-videos/my-videos.component';
import { CoachPlayersessionsComponent } from './coach/coach-playersessions/coach-playersessions.component';
import { ProfileComponent } from './profiles/profile/profile.component';
import { ProfileChangePasswordComponent } from './profiles/profile-change-password/profile-change-password.component';
import { ProfileCoachComponent } from './profiles/profile-coach/profile-coach.component';
import { DashboardCoachComponent } from './dashboard-coach/dashboard-coach.component';
import { PlannerComponent } from './planner/planner/planner.component';
import { PlayGroupsComponent } from './coach/play-groups/play-groups.component';
import { DrillsComponent } from './drills/drills/drills.component';
import { DrillsAthletesComponent } from './drills/drills-athletes/drills-athletes.component';
import { VideoAnalyseComponent } from './video-analyse/video-analyse.component';

import { FullScreenLayoutComponent } from './layout/full-screen.component';
import { VideoTaggingListComponent } from './video-tagging/video-tagging-list/video-tagging-list.component';
import { VideoTaggingComponent } from './video-tagging/video-tagging/video-tagging.component';
import { VideoCompareComponent } from './video-compare/video-compare.component';
import { HelpComponent } from './help/help/help.component';
import { MyPlaylistComponent } from './my-playlist/my-playlist.component';
import { MyPlaylistViewComponent } from './my-playlist-view/my-playlist-view.component';
import { DashboardAcademyComponent } from './dashboard-academy/dashboard-academy.component';
import { CoachPadComponent } from './coach/coach-pad/coach-pad.component';
import { CoachpadPlayerComponent } from './coach/coachpad-player/coachpad-player.component';
import { CoachProfileComponent } from './coach/coach-profile/coach-profile.component';
import { CoachingComponent } from './coach/coaching/coaching.component';
import { CoachSessionsComponent } from './coach/coach-sessions/coach-sessions.component';
import { AcademicPlayerSesssionsComponent } from './player/academic-player-sesssions/academic-player-sesssions.component';
import { FinanceDashboardComponent } from './Finance/finance-dashboard/finance-dashboard.component';
import { FinanceMasterComponent } from './Finance/finance-master/finance-master.component';
import { PackageSelectorComponent } from './Finance/package-selector/package-selector.component';


const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'association-list', component: AssociationListComponent },
      { path: 'association', component: AssociationRegistrationComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'player', component: PlayerComponent },
      { path: 'coach', component: CoachComponent },
      { path: 'trainer', component: TrainerComponent },
      { path: 'physio', component: PhysioComponent },
      { path: 'nutrition', component: NutritionistComponent },
      { path: 'player-list', component: PlayerListComponent },
      { path: 'coach-list', component: CoachListComponent },
      { path: 'trainer-list', component: TrainerListComponent },
      { path: 'physio-list', component: PhysioListComponent },
      { path: 'doctor', component: DoctorComponent },
      { path: 'doctor-list', component: DoctorListComponent },
      { path: 'nutrition-list', component: NutritionistListComponent },
      { path: 'master', component: MastersComponent },
      { path: 'batch-master', component: BatchMasterComponent },
      { path: 'player-group', component: PayerGroupComponent },
      { path: 'athlete-mapping', component: AthleteMappingComponent },
      { path: 'supportstaff-mapping', component: SupportStaffMappingComponent },
      { path: 'athlete-dashboard', component: DashboardAthleteComponent },
      //{ path: 'my-videos', component: MyVideosComponent },
      { path: 'my-videos', component: AcademicPlayerSesssionsComponent },
      // {path:'coach-sessions',component:CoachPlayersessionsComponent},
      { path: 'coach-sessions', component: CoachSessionsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'change-password', component: ProfileChangePasswordComponent },
      { path: 'profile-coach', component: ProfileCoachComponent },
      { path: 'coach-dashboard', component: DashboardCoachComponent },
      { path: 'planner', component: PlannerComponent },
      { path: 'play-groups', component: PlayGroupsComponent },
      { path: 'drills', component: DrillsComponent },
      { path: 'drills-athletes', component: DrillsAthletesComponent },
      { path: 'video-analyse', component: VideoAnalyseComponent },
      { path: 'video-list', component: VideoTaggingListComponent },
      { path: 'help', component: HelpComponent },
      { path: 'video-compare', component: VideoCompareComponent },
      { path: 'myplaylist', component: MyPlaylistComponent },
      { path: 'playlistview', component: MyPlaylistViewComponent },
      { path: 'academy-dashboard', component: DashboardAcademyComponent },
      { path: 'coach-pad', component: CoachPadComponent },
      { path: 'coachpad-player', component: CoachpadPlayerComponent },
      { path: 'coach-profile', component: CoachProfileComponent },
      { path: 'coaching', component: CoachingComponent },
      { path: 'finance-dashboard', component: FinanceDashboardComponent },
      { path: 'finance-master', component: FinanceMasterComponent },
      { path: 'package-selector', component: PackageSelectorComponent } 
    ]
  },
  {
    path: '',
    component: FullScreenLayoutComponent,
    children: [
      { path: 'video-tagging', component: VideoTaggingComponent }
    ]
  },
  {
    path: '',
    component: LandingLayoutComponent,
    children: [
      {
        path: 'landing',
        component: LandingComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const AllRoutes = [
  LandingComponent,
  LandingLayoutComponent,
  HomeLayoutComponent,
  DashboardComponent,
  FullScreenLayoutComponent
]
