import { Component, OnInit } from '@angular/core';

import { UtilitiesService } from '../utilities.service';
@Component({
  selector: 'app-landing-layout',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class LandingLayoutComponent implements OnInit {

  constructor(private utility:UtilitiesService) { }

  ngOnInit(): void {
       
  }
}