import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnnotationsService {

  constructor() { }
  public lineOffset: number = 4;
  public anchrSize: number = 2;
  findCurrentArea(annotationlist, event, x, y, type) {
    if (type == 'rectangle') {
      var box = { x1: annotationlist.X0, y1: annotationlist.Y0, x2: annotationlist.X1, y2: annotationlist.Y1 };
      var xCenter = box.x1 + (box.x2 - box.x1) / 2;
      var yCenter = box.y1 + (box.y2 - box.y1) / 2;
      if (box.x1 - this.lineOffset < x && x < box.x1 + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y1 + this.lineOffset) {
          return { box: 0, pos: 'tl' };
        } else if (box.y2 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: 0, pos: 'bl' };
        } else if (yCenter - this.lineOffset < y && y < yCenter + this.lineOffset) {
          return { box: 0, pos: 'l' };
        }
      } else if (box.x2 - this.lineOffset < x && x < box.x2 + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y1 + this.lineOffset) {
          return { box: 0, pos: 'tr' };
        } else if (box.y2 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: 0, pos: 'br' };
        } else if (yCenter - this.lineOffset < y && y < yCenter + this.lineOffset) {
          return { box: 0, pos: 'r' };
        }
      } else if (xCenter - this.lineOffset < x && x < xCenter + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y1 + this.lineOffset) {
          return { box: 0, pos: 't' };
        } else if (box.y2 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: 0, pos: 'b' };
        } else if (box.y1 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: 0, pos: 'i' };
        }
      } else if (box.x1 - this.lineOffset < x && x < box.x2 + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: 0, pos: 'i' };
        }
      }
      return { box: -1, pos: 'o' };
    }
    else if (type == 'circle') {
      var box = { x1: annotationlist.X0, y1: annotationlist.Y0, x2: annotationlist.X1, y2: annotationlist.Y1 };
      var xCenter = box.x1;
      var yCenter = box.y1;
      var xDiff = box.x1 - box.x2;
      var yDiff = box.y1 - box.y2;

      var rad = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
      var dx = x - box.x1,
        dy = y - box.y1,
        dist = Math.sqrt(dx * dx + dy * dy);
      if (dist < rad) {
        if (box.x1 - rad - 1 < x && x < box.x1 - rad + 1) {
          if (box.y1 - 1 < y && y < box.y1 + 1) {
            return { box: 0, pos: 'cl' };
          }
          return { box: 0, pos: 'i' };
        }
        if (box.x1 + rad - 1 < x && x < box.x1 + rad + 1) {
          if (box.y1 - 1 < y && y < box.y1 + 1) {
            return { box: 0, pos: 'cl' };
          }
          return { box: 0, pos: 'i' };
        }
        if (box.x1 - 1 < x && x < box.x1 + 1) {
          if (box.y1 - rad - 1 < y && y < box.y1 - rad + 1) {
            return { box: 0, pos: 'cl' };
          }
          return { box: 0, pos: 'i' };
        }
        if (box.x1 - 1 < x && x < box.x1 + 1) {
          if (box.y1 + rad - 1 < y && y < box.y1 + rad + 1) {
            return { box: 0, pos: 'cl' };
          }
          return { box: 0, pos: 'i' };
        }
        return { box: 0, pos: 'i' };
      }
      else {
        return { box: -1, pos: 'o' };
      } 
    }
  }

  drawBoxOn(box, context) {
    var xCenter = box.x1 + (box.x2 - box.x1) / 2;
    var yCenter = box.y1 + (box.y2 - box.y1) / 2;

    context.strokeStyle = box.color;
    context.fillStyle = box.color;

    context.rect(box.x1, box.y1, (box.x2 - box.x1), (box.y2 - box.y1));
    context.lineWidth = box.lineWidth;
    context.stroke();

    context.fillRect(box.x1 - this.anchrSize, box.y1 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(box.x1 - this.anchrSize, yCenter - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(box.x1 - this.anchrSize, box.y2 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(xCenter - this.anchrSize, box.y1 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(xCenter - this.anchrSize, yCenter - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(xCenter - this.anchrSize, box.y2 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(box.x2 - this.anchrSize, box.y1 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(box.x2 - this.anchrSize, yCenter - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(box.x2 - this.anchrSize, box.y2 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
  }
}
