import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-play-groups',
  templateUrl: './play-groups.component.html',
  styleUrls: ['./play-groups.component.css']
})
export class PlayGroupsComponent implements OnInit {

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder, private router: Router,) { }

  @Output() GetSessionDeta = new EventEmitter<any>();

  public UserCode: string;

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;

  public PlayerGroupList: any[]; public PlayerGroupPlayers: any[];
  public PlayerTypeList: any[];
  public GetSessionData: any = [];

  public IsEmptyGroups: boolean = false; public IsEmptyAthletes: boolean = false;

  public _playerTypeId: string;

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.GetPlayGroup();
  }

  GetPlayGroup() {
    this.isShowLoader = true;
    this.service.SessionChattingCoachLoad({ ActionFlag: "GetPlayGroup", CoachRefUserID: this.UserCode }).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.PlayerGroupList = ret.PlayerGroupList;
        if (this.PlayerGroupList.length > 0) {
          if (this.PlayerGroupList.length > 0) {
            this.GetAtheleteByPlayGroup("", this.PlayerGroupList[0].PlayGroupID);
          }
          this.isShowLoader = false;
          this.IsEmptyGroups = false;
          this.IsEmptyAthletes = false;
        }
        else {
          this.isShowLoader = false;
          this.IsEmptyGroups = true;
          this.IsEmptyAthletes = true;
        }
      }
      else {
        this.isShowLoader = false;
        this.IsEmptyGroups = false;
        this.IsEmptyAthletes = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  public selectedItem: string;
  public playGroupID: string;
  GetAtheleteByPlayGroup(event, playGroupID) {
    this.selectedItem = playGroupID;
    this.playGroupID = playGroupID;
    this.isShowLoader = true;
    var playerGroup = {
      ActionFlag: "GetAtheleteByPlayGroup", PlayGroupID: String(playGroupID),
      CoachRefUserID: this.UserCode, AtheleteRefUserID: "",
    };
    this.service.SessionChattingCoachLoad(playerGroup).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.PlayerGroupPlayers = ret.PlayerGroupPlayersList;
        this.GetPlayerType(this._playerTypeId);
        if (this.PlayerGroupPlayers.length > 0) {
          this.IsEmptyAthletes = false;
          this.isShowLoader = false;
        }
        else {
          this.IsEmptyAthletes = true;
          this.isShowLoader = false;
        }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  GetPlayerType(TypeId) {
    this._playerTypeId = TypeId;
    this.isShowLoader = true;
    if (TypeId == '0') {
      if (this.PlayerGroupPlayers != undefined) {
        this.PlayerTypeList = this.PlayerGroupPlayers;
        if (this.PlayerTypeList.length > 0) {
          this.IsEmptyAthletes = false;
          this.isShowLoader = false;
        }
        else {
          this.IsEmptyAthletes = true;
          this.isShowLoader = false;
        }
      }
      else {
        this.IsEmptyAthletes = true;
        this.isShowLoader = false;
      }
    }
    else {
      if (this.PlayerGroupPlayers != undefined) {
        this.PlayerTypeList = this.PlayerGroupPlayers.filter((con) => String(con.PlayerRoleID) === (TypeId));
        if (this.PlayerTypeList.length > 0) {
          this.IsEmptyAthletes = false;
          this.isShowLoader = false;
        }
        else {
          this.IsEmptyAthletes = true;
          this.isShowLoader = false;
        }
      }
      else {
        this.IsEmptyAthletes = true;
        this.isShowLoader = false;
      }
    }
    this.isShowLoader = false;
  }

  GetAthleteSharedInfo(AthleteID) {
    this.GetSessionData = AthleteID;
    sessionStorage.setItem("sessionAthleteRefID", String(AthleteID.RefUserID));
    sessionStorage.setItem("SelectedPlayer", String(AthleteID));
    SessionData = AthleteID;
    this.GetSessionDeta.emit(this.GetSessionData);

    this.service.SelectedPlayerData=this.GetSessionData;
    this.router.navigate(['/coach-sessions']);
  }

}

export var SessionData: any = [];