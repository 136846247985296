<div class="page-container player-list-page" [hidden]="pdfenable">
    <div class="container">
        <div class="input-container">
            <div class="white">
                <div class="row">
                    <div class="col-md-12">
                        <div>Player Academy Transfers</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container mb20">
                <div class="white">
                    <div class="finance-filter">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                    <mat-label>Search Academy</mat-label>
                                    <!-- <input type="text" placeholder="Season" matInput [formControl]="myControl" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                          {{option.name}}
                                        </mat-option>
                                      </mat-autocomplete> -->
                                    <mat-select placeholder="Player Package" [(ngModel)]="AssociationID">
                                        <mat-option [value]="ass.AssociationID" *ngFor="let ass of AssociationList"
                                            style="text-transform:capitalize;">{{ass.AssociationName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                    <mat-icon matSuffix class="mat-icon">
                                        <a href="javascript:void(0);">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.12 373.12"
                                                width="24" height="24">
                                                <path
                                                    d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                            </svg>
                                        </a>
                                    </mat-icon>
                                    <mat-label>Search Player</mat-label>
                                    <input matInput placeholder="Search Player" [(ngModel)]="PlayerName">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 txt-right">
                                <button mat-raised-button color="primary" class="mr10 mt20"
                                    (click)="SearchPlayers()">Search</button>
                                <button mat-raised-button color="primary" class="mt20"
                                    (click)="ClearPlayers()">Clear</button>
                            </div>
                        </div>
                        <div class="searchlist">
                            <div class="row">
                                <div class="col-md-3" *ngFor="let player of PlayerDetails"
                                    (click)="PlayerSelection(player,SelectedPlayer)">
                                    <a class="academyplayer" href="javascript:void(0);"
                                        [ngClass]="player.AtheleteID==this.SelectedPlayerID?'select':''">
                                        <img src="{{player.PlayerImage}}">
                                        <div class="academypi">
                                            <div class="name">{{player.DisplayName}}</div>
                                            <div class="dob"><span>DOB :</span> {{player.DOB| date: 'dd MMM yyyy'}}
                                            </div>
                                            <div class="mobile">{{player.LoginMobile}}</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div #SelectedPlayer>
                                <div class="packager" *ngIf="SelectedPlayerName">
                                    <div class="title">{{SelectedPlayerAssociation}}</div>
                                    <form [formGroup]="subscriptionForm" #a="ngForm" (ngSubmit)="submitSubscription()">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <a class="academyplayer active select"
                                                            href="javascript:void(0);">
                                                            <img src="{{SelectedPlayerImage}}">
                                                            <div class="academypi">
                                                                <div class="name">{{SelectedPlayerName}}</div>
                                                                <div class="dob"><span>DOB :</span> {{SelectedPlayerDOB|
                                                                    date: 'dd MMM yyyy'}}</div>
                                                                <div class="mobile">{{SelectedPlayerMobile}}</div>
                                                            </div>
                                                            <div class="fname"><span>Father Name
                                                                    :</span>{{SelectedPlayerFather}}</div>
                                                        </a>
                                                        <div *ngIf="subscriptionType===1">
                                                            <div class="fileupload mt28">
                                                                <div class="placeholder">Doc <span>(.pdf, .jpeg,
                                                                        .png)</span>
                                                                </div>
                                                                <!-- <input type="file" name="" class="fileupload-container" size="60"> -->
                                                                <input type="file" name="" class="fileupload-container"
                                                                    size="60" [(ngModel)]="Document"
                                                                    (change)="onFileChange($event)"
                                                                    formControlName="_document" name="_document">
                                                                <div class="browse-upload">
                                                                    <input name="" type="text" class="upload-txt"
                                                                        value="" placeholder="Select file">
                                                                    <input type="submit" value="Browse"
                                                                        class="upload-btn">
                                                                </div>
                                                            </div>
                                                            <div class="showpreview"
                                                                *ngIf="DocumentFilePath.length>0 && DocumentFilePath!='undefined'">
                                                                <a href="{{DocumentFilePath}}" target="_blank"> <img
                                                                        src="assets/images/preview-icon.png"
                                                                        class="img-responsive"> </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-9">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div>
                                                            <div class="md-block">
                                                                <div class="radio-grp">Subscription Type</div>
                                                            </div>
                                                            <mat-radio-group aria-label="Select an option"
                                                                formControlName="_subscriptionType"
                                                                name="_subscriptionType" [(ngModel)]="subscriptionType"
                                                                disabled>
                                                                <mat-radio-button value=1
                                                                    [checked]="subscriptionType===1">
                                                                    Subscription</mat-radio-button>
                                                                <mat-radio-button value=2
                                                                    [checked]="subscriptionType===2">
                                                                    Top up
                                                                </mat-radio-button>
                                                            </mat-radio-group>
                                                            <div *ngIf="a.submitted && subscriptionForm.controls._subscriptionType.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="subscriptionForm.controls._subscriptionType.errors.required">
                                                                    Subscription Type required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8" *ngIf="subscriptionType===2">
                                                        <div>
                                                            <div class="md-block">
                                                                <div class="radio-grp">No.of Videos</div>
                                                            </div>
                                                            <mat-radio-group aria-label="Select an option"
                                                                [(ngModel)]="topupVideos" formControlName="_NoofVideos"
                                                                name="_NoofVideos">
                                                                <mat-radio-button *ngFor="let top of TopupDetails"
                                                                    [checked]="topupID==top.TopupID"
                                                                    (click)="topupchange(top)" [value]="top.TopupID"
                                                                    class="rbt-click">
                                                                    {{top.NoOfVideos}}</mat-radio-button>
                                                            </mat-radio-group>
                                                            <div *ngIf="a.submitted && subscriptionForm.controls._NoofVideos.invalid"
                                                                class="alert alert-danger">
                                                                <div
                                                                    *ngIf="subscriptionForm.controls._NoofVideos.errors.required">
                                                                    No Of Videos required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" [hidden]="subscriptionType!=1">
                                                        <mat-form-field class="md-block mat-dd-field"
                                                            appearance="outline" floatLabel="always">
                                                            <mat-label>Package Option</mat-label>
                                                            <!-- <mat-select placeholder="Premium">
                                                        <mat-option [value]="01">Basic</mat-option>
                                                        <mat-option [value]="02">Standard</mat-option>
                                                        <mat-option [value]="03">Enterprise</mat-option>
                                                    </mat-select> -->
                                                            <mat-select placeholder="Player Package"
                                                                [(ngModel)]="Package" formControlName="_Package"
                                                                name="_Package"
                                                                (ngModelChange)="SearchPlayerPackageDetails()" required>
                                                                <mat-option [value]="pack.PackageID"
                                                                    *ngFor="let pack of PackageList"
                                                                    style="text-transform:capitalize;">
                                                                    {{pack.PackageName}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="a.submitted && subscriptionForm.controls._Package.invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="subscriptionForm.controls._Package.errors.required">
                                                                Select package option</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" [hidden]="subscriptionType!=1">
                                                        <mat-form-field class="md-block mat-dd-field"
                                                            appearance="outline" floatLabel="always">
                                                            <mat-label>Package Duration</mat-label>
                                                            <mat-select placeholder="Player Package"
                                                                [(ngModel)]="Duration" formControlName="_Duration"
                                                                name="_Duration"
                                                                (ngModelChange)="SearchPlayerPackageDetails()" required>
                                                                <mat-option [value]="dur.DurationID"
                                                                    *ngFor="let dur of DurationList"
                                                                    style="text-transform:capitalize;">
                                                                    {{dur.DurationName}}-{{dur.NoOfDays}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="a.submitted && subscriptionForm.controls._Duration.invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="subscriptionForm.controls._Duration.errors.required">
                                                                Select package duration</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <mat-form-field class="md-block" appearance="outline"
                                                            floatLabel="always">

                                                            <mat-label>Package Amount <span>(Including GST)</span>
                                                            </mat-label>
                                                            <input matInput [ngModel]="PackageAmount |currency:'INR'"
                                                                formControlName="_PackageAmount" required />
                                                        </mat-form-field>
                                                        <div *ngIf="a.submitted && subscriptionForm.controls._PackageAmount.invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="subscriptionForm.controls._PackageAmount.errors.required">
                                                                Select Package Amount</div>
                                                        </div>

                                                    </div>


                                                    <div class="col-md-4" [hidden]="subscriptionType!=1">
                                                        <mat-form-field appearance="fill"
                                                            class="custom-dtpkr-txt md-block" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-label>Start Date</mat-label>
                                                            <input matInput [matDatepicker]="startdate"
                                                                [(ngModel)]="packageStartdate"
                                                                (ngModelChange)="StartdateChange()"
                                                                formControlName="_packageStartdate"
                                                                name="_packageStartdate" required>
                                                            <mat-datepicker-toggle matSuffix [for]="startdate">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #startdate></mat-datepicker>
                                                        </mat-form-field>
                                                        <div *ngIf="a.submitted && subscriptionForm.controls._packageStartdate.invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="subscriptionForm.controls._packageStartdate.errors.required">
                                                                Select Start Date</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" [hidden]="subscriptionType!=1">
                                                        <mat-form-field appearance="fill"
                                                            class="custom-dtpkr-txt md-block" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-label>End Date</mat-label>
                                                            <input matInput [matDatepicker]="enddate"
                                                                [(ngModel)]="packageEnddate" disabled
                                                                formControlName="_packageEnddate" name="_packageEnddate"
                                                                required>
                                                            <mat-datepicker-toggle matSuffix [for]="enddate">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #enddate></mat-datepicker>
                                                        </mat-form-field>
                                                        <div *ngIf="a.submitted && subscriptionForm.controls._packageEnddate.invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="subscriptionForm.controls._packageEnddate.errors.required">
                                                                Select End Date</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <mat-form-field class="md-block" appearance="outline"
                                                            floatLabel="always">

                                                            <mat-label>Reg Mobile Number</mat-label>
                                                            <input matInput [(ngModel)]="SelectedPlayerMobile"
                                                                [disabled]="mobedit" placeholder="9876543217"
                                                                formControlName="_SelectedPlayerMobile"
                                                                name="_SelectedPlayerMobile" required [maxLength]="13"
                                                                [minLength]="10">
                                                        </mat-form-field>
                                                        <mat-icon matSuffix class="mat-icon ico-edit"><a
                                                                href="javascript:void(0);" (click)="mbclick()"><img
                                                                    src="assets/images/edit.png"></a></mat-icon>
                                                        <div *ngIf="a.submitted && subscriptionForm.controls._SelectedPlayerMobile.invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="subscriptionForm.controls._SelectedPlayerMobile.errors.required">
                                                                Player Mobile Number is Required</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <mat-form-field class="md-block" appearance="outline"
                                                            floatLabel="always">

                                                            <mat-label>Reg Mail ID</mat-label>
                                                            <input matInput [(ngModel)]="SelectedPlayerEmail"
                                                                formControlName="_SelectedPlayerEmail"
                                                                name="_SelectedPlayerEmail" [disabled]="true"
                                                                placeholder="info@cricpass.com" required>
                                                        </mat-form-field>
                                                        <mat-icon matSuffix class="mat-icon ico-edit"><a
                                                                href="javascript:void(0);" (click)="mailclick()"><img
                                                                    src="assets/images/edit.png"></a></mat-icon>
                                                        <div *ngIf="a.submitted && subscriptionForm.controls._SelectedPlayerEmail.invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="subscriptionForm.controls._SelectedPlayerEmail.errors.required">
                                                                Player Email Required</div>
                                                            <div
                                                                *ngIf="this.subscriptionForm.controls._SelectedPlayerEmail.errors.email">
                                                                Email Id is Incorrect</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <mat-form-field class="md-block" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><img
                                                                    src="assets/images/copy.png"></mat-icon>
                                                            <mat-label>Academy Join Code</mat-label>
                                                            <input matInput placeholder="CSD-201-DSS-232"
                                                                [(ngModel)]="AcademyJoinCode"
                                                                formControlName="_AcademyJoinCode"
                                                                name="_AcademyJoinCode">
                                                        </mat-form-field>
                                                        <!-- <div *ngIf="a.submitted && subscriptionForm.controls._AcademyJoinCode.invalid"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="subscriptionForm.controls._AcademyJoinCode.errors.required">
                                                            Player Email Required</div>
                                                    </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 txt-center">
                                                <button mat-raised-button color="primary" type="submit"
                                                    class="mr10">Save</button>
                                                <button mat-raised-button color="primary"
                                                    (click)="ClearSelectedPlayer()" class="mr10">Clear</button>
                                                <button mat-raised-button color="primary" (click)="Close()"
                                                    class="mr10">Close</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <ngx-datatable class="material" [rows]="GridList" [columnMode]="'force'" [headerHeight]="42"
                            [footerHeight]="50" [rowHeight]="42" [limit]="rowLimit" [scrollbarV]="false"
                            [scrollbarH]="true">
                            <ngx-datatable-column name="S No" [width]="20">
                                <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                    <span>{{rowIndex+1}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Academy Name" prop="AssociationName" [width]="200">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Player Name" prop="PlayerName" [width]="150">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Mobile" prop="Mobile" [width]="70">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Package" prop="PackageName" [width]="70">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Videos" prop="NoOfVideos" [width]="50">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Duration" prop="DurationName" [width]="50">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Start" prop="StartDate" [width]="80">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="End" prop="EndDate" [width]="80">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Actions" [width]="50">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <span>
                                        <div class="action-btn">
                                            <div class="ico-action-svg edit" (click)="EditSubscription(row)"
                                                title="Edit">
                                                <svg height="18" viewBox="0 -1 381.53417 381" width="18"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="m370.589844 230.964844c-5.523438 0-10 4.476562-10 10v88.792968c-.019532 16.558594-13.4375 29.980469-30 30h-280.589844c-16.5625-.019531-29.980469-13.441406-30-30v-260.589843c.019531-16.5625 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.523438-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.589843c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.789062c0-5.523438-4.476563-10.003906-10-10.003906zm0 0" />
                                                    <path
                                                        d="m156.367188 178.34375 146.011718-146.015625 47.089844 47.089844-146.011719 146.015625zm0 0" />
                                                    <path
                                                        d="m132.542969 249.257812 52.039062-14.414062-37.625-37.625zm0 0" />
                                                    <path
                                                        d="m362.488281 7.578125c-9.769531-9.746094-25.585937-9.746094-35.355469 0l-10.605468 10.605469 47.089844 47.089844 10.605468-10.605469c9.75-9.769531 9.75-25.585938 0-35.355469zm0 0" />
                                                </svg>
                                            </div>
                                            <div class="ico-action-svg" title="Download Invoice"> <a
                                                    href="javascript:void(0);" (click)="getInvData(row);">
                                                    <p><i class="fa fa-download" aria-hidden="true"></i></p>
                                                </a></div>
                                        </div>
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage"
                                    let-offset="offset" let-isVisible="isVisible">
                                    <div class="footer-page-size-position">
                                        show
                                    </div>
                                    <div class="footer-page-size-position">
                                        <mat-select style="text-align: center" class="mat-select-position"
                                            [(value)]="rowLimit" (selectionChange)="changeRowLimits($event.value)">
                                            <mat-option *ngFor="let limit of LIMITS" [value]="limit.value">
                                                {{limit.value}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="footer-page-size-position">
                                        {{rowLimit}} entries
                                    </div>

                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                        [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="table.onFooterPage($event)">
                                    </datatable-pager>

                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [hidden]="!pdfenable">

    <!DOCTYPE html
        PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" id="contentToConvert">

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Invoice</title>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;500;600&display=swap');
        </style>
    </head>

    <body
        style="background:#eeeeee;width:100%; font-family:Calibri; color:#333;margin-left: -70px; font-size:24px; font-style:normal; padding:0;">
        <table style="width:100%; margin:10px auto; padding:0; border-collapse:collapse;background-color:#fff;"
            cellpadding="0" cellspacing="0">
            <tr style="padding:0; margin:0;">
                <td style="padding:10px; margin:0;border-bottom:2px solid #57b3e1;">
                    <table style="padding:0; margin:0;width:100%;">
                        <tr>
                            <td style="width:64px;padding:0;margin:0;" valign="top">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px"
                                    height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"
                                    xml:space="preserve">
                                    <g>
                                        <path fill="#67B4E4" d="M32.066,11.907c4.739,0,9.086,1.649,12.519,4.395l0.137-0.088l8.346-7.096
							c-5.528-5.08-12.9-8.186-21.002-8.186C14.909,0.933,1,14.842,1,31.999c0,17.158,13.909,31.068,31.066,31.068
							c8.032,0,15.346-3.053,20.858-8.056l-8.483-7.212c-3.409,2.682-7.698,4.296-12.375,4.296c-11.096,0-20.092-8.998-20.092-20.095
							C11.974,20.903,20.97,11.907,32.066,11.907" />
                                        <path fill="#DCB978" d="M55.56,11.764l-8.324,7.077c3.06,3.526,4.917,8.125,4.917,13.158c0,5.036-1.857,9.634-4.917,13.16
							l8.324,7.077C60.2,46.784,63,39.719,63,31.999C63,24.281,60.2,17.216,55.56,11.764" />
                                        <path fill="#081B3E" d="M22.676,32c0-5.181,4.201-9.382,9.384-9.382c5.182,0,9.383,4.201,9.383,9.382
							c0,5.182-4.201,9.384-9.383,9.384C26.877,41.384,22.676,37.181,22.676,32" />
                                        <path fill="#C0E7F6" d="M47.236,45.159c3.058-3.526,4.917-8.123,4.917-13.16c0-5.033-1.858-9.632-4.917-13.158L31.759,31.999
							L47.236,45.159z" />
                                    </g>
                                </svg>
                            </td>
                            <td style="width:calc(50% - 64px);padding:0;margin:0;padding-left:10px;">
                                <p style="padding:0;margin:0;">
                                    <span style="font-family:Mukta;font-weight:600;display:block;">CRICPASS TECH PRIVATE
                                        LIMITED</span>
                                    <span
                                        style="display:block;font-size:20px;color:#383838;font-family:'Mukta';line-height: 30px;">Office
                                        No-2, S.N.13B/123 and 14 PART</span>
                                    <span
                                        style="display:block;font-size:20px;color:#383838;font-family:'Mukta';line-height: 30px;">B3-3F,
                                        Wadgaon Sheri, PUNE, Pune,</span>
                                    <span
                                        style="display:block;font-size:20px;color:#383838;font-family:'Mukta';line-height: 30px;">Maharashtra,
                                        411014</span>
                                    <span
                                        style="display:block;font-size:20px;color:#383838;font-family:'Mukta';line-height: 30px;">State
                                        Name : Maharashtra, Code : 27</span>
                                    <span
                                        style="display:block;font-size:20px;color:#383838;font-family:'Mukta';line-height: 30px;">E-Mail
                                        : accounts@cricpass.com</span>
                                </p>
                            </td>
                            <td style="width:50%; padding:0; margin:0;text-align:right;" valign="top">
                                <table style="width:100%; padding:0; margin:0;">
                                    <tr>
                                        <td style="width:100px; padding:0; margin:0;"></td>
                                        <td
                                            style="width:309px; line-height:20px; text-align:right; padding:0; margin:0;">
                                            <span
                                                style="color:#222; font-size:24px;display:block;font-family:'Mukta';font-weight:600;">Invoice
                                                Number: <i
                                                    style="font-weight:400;font-size:20px;">{{invReferenceNo}}</i></span>
                                            <span
                                                style="color:#222; font-size:24px;display:block;font-family:'Mukta';font-weight:600;">Date
                                                : <i style="font-weight:400;font-size:20px;">{{invDate}}</i></span>
                                            <span
                                                style="display:block;font-size:24px;color:#383838;font-family:'Mukta';line-height:24px;font-weight:600;">CIN:
                                                <i
                                                    style="font-weight:400;font-size:20px;">U72900PN2021PTC206604</i></span>
                                            <span
                                                style="display:block;font-size:24px;color:#383838;font-family:'Mukta';line-height:24px;font-weight:600;">GSTIN/UIN:
                                                <i style="font-weight:400;font-size:20px;">27AAJCC9538M1ZX</i></span>

                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style="padding:0;margin:0;text-align:center;" colspan="2">
                    <h1
                        style="color:#c63631;display:inline-block;border-bottom:1px solid;font-size:42px;font-weight: 600; font-family:Mukta; padding:10px 0 5px; margin:10px 0;text-align:center;line-height:24px;">
                        Invoice Receipt</h1>
                </td>
            </tr>
            <tr>
                <td style="padding:5px;">
                    <table style="padding:0;margin:0 auto;width:80%;border-spacing:0;border-collapse:collapse;">
                        <tr>
                            <td style="padding:10px 0;margin:0;padding-top:0;" colspan="2">
                                <p style="font-weight:600;margin:0;margin-bottom:5px;"> <span
                                        style="font-weight:400;font-size:20px;">Consignee,</span> {{invPlayerName}}
                                </p>
                                <span
                                    style="font-size:24px;color:#383838;font-family:'Mukta';line-height: 28px; display:block;">{{invAssociationName}},</span>
                                <!-- <span
                                    style="font-size:24px;color:#383838;font-family:'Mukta';line-height: 28px; display:block;">Gurgaon
                                    122002</span>
                                <span
                                    style="font-size:24px;color:#383838;font-family:'Mukta';line-height: 28px; display:block;">State
                                    Name : Haryana, Code : 06</span>
                                <span
                                    style="font-size:24px;color:#383838;font-family:'Mukta';line-height: 28px; display:block;">GSTIN/UIN
                                    : 06AAJFC3057A1ZY</span> -->
                            </td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Name</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invPlayerName}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Subscription Type</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invPackageType}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Package Option</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invPackageName}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Package Duration</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invDurationName}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Amount</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invAmount}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">GST%</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invGST}} %</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">GST</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invGSTAmount}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Package Amount</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invAmountWithGST}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Start Date</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invStartDate}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">End Date</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invEndDate}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Mobile Number</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invMobile}}</span></td>
                        </tr>
                        <tr>
                            <td style="padding:10px 4px; margin:0;width:40%;border:1px solid #ccc;"><span
                                    style="color:#7a7a7a;font-size:24px;">Email ID</span></td>
                            <td style="padding:10px 4px; margin:0;width:60%;border:1px solid #ccc;"><span
                                    style="color:#222;font-size:24px;font-weight:600;">{{invEmail}}</span></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <p style="color:#000;font-family:Mukta;margin-bottom:300px;">* This is system generated
                                    reference receipt. Please use this for your reference.</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr style=" padding:0; margin:0;">
                <td style=" padding:0; margin:0; background:#fff; padding:5px 0 0 0; border-top:1px solid #ccc;"
                    align="center">
                    <p style="font-family:Calibri; font-size:24px; margin:0; padding:5px 0 10px 0;">Copyright © 2022
                        Cricpass. All rights reserved</p>
                </td>
            </tr>
        </table>
    </body>

    </html>


</div>

<div class="overlay" *ngIf="subscriptionmsg">
    <div class="alertContainer white txt-center">
        <div class="title">Package Receipt</div>
        <p class="mb20"><i class="fa fa-check" aria-hidden="true"></i> <br>Player Package Updated Successfully </p>
        <div class="down txt-center">
            <!-- <a href="javascript:void(0);"> -->
            <!-- <a href="assets/images/invoice.pdf" target="_blank">
                <p><i class="fa fa-download" aria-hidden="true"></i></p>
                <span>Download Receipt</span>
            </a> -->
            <a href="javascript:(void);" (click)="fetchInvoiceData()">
                <p><i class="fa fa-download" aria-hidden="true"></i></p>
                <span>Download Receipt</span>
            </a>
            <a href="javascript:void(0);" (click)="sendInvoiceMail()">
                <p><i class="fa fa-envelope" aria-hidden="true"></i></p>
                <span>Send Receipt in Mail</span>
            </a>
        </div>

        <div class="title"></div>
        <div class="txt-center mb10">
            <button mat-raised-button color="primary" (click)="hideSubmsg();removeadd();">Done</button>
        </div>
    </div>
</div>

<div class="overlay success" *ngIf="AlertType=='Success'">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Package Selector</h4>
                <p>{{AlertMessage}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="AlertType=''">Ok</button>
        </div>
    </div>
</div>
<!-- <div class="overlay error" *ngIf = "AlertType=='Error'" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
                <div  class="alcenter" > 
                    <h4>Finance Master</h4>
                <p  >{{AlertMessage}}</p> 
               </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="AlertType=''" >Ok</button>
        </div>
   </div>
</div>  -->
<div class="overlay warning" *ngIf="AlertType=='Error'">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Package Selector</h4>
                <p>{{AlertMessage}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="AlertType=''">Ok</button>
        </div>
    </div>
</div>