<div class="page-container coach-pad-page">
    <div class="container">
        <div class="input-container">
            <!-- <div class="white">
                <div class="row">
                    <div class="col-md-12">
                        Association Registration
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="page-wrapper no-input">
        <div class="container">
            <div class="output-container" *ngIf="coachpad=='formQualified'">
                <div class="white">
                    <div class="qualified-coach txt-center">
                        <a href="javascript:void(0);" (click)="showCoachform()"><img
                                src="assets/images/qualified-coach.png"></a>
                    </div>
                </div>
            </div>
            <div class="output-container" *ngIf="coachpad=='regForm'">
                <div class="white">
                    <div class="coach-form">
                        <mat-vertical-stepper #stepper>
                            <mat-step [completed]="false" [stepControl]="CoachBasicForm"
                                (click)="SaveCoachInfo(stepper)">
                                <form [formGroup]="CoachInfoForm" #ci="ngForm">
                                    <ng-template matStepLabel>Coach Info</ng-template>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Certificate Level</mat-label>
                                                <mat-select placeholder="Certificate Level"
                                                    formControlName="n_CertificateLevel" [(ngModel)]="CertificateLevel">
                                                    <mat-option *ngFor="let level of CertificateLevels"
                                                        [value]="level.CertificateLevelID">
                                                        {{level.CertificateLevel}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div *ngIf="ci.submitted && CoachInfoForm.controls.n_CertificateLevel.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="CoachInfoForm.controls.n_CertificateLevel.errors.required">
                                                    Certification Level is required.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Certificate Agency</mat-label>
                                                <textarea matInput placeholder="Certificate Agency"
                                                    formControlName="n_CertificateAgency"
                                                    [(ngModel)]="CertificateAgency"></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Specialization</mat-label>
                                                <textarea matInput placeholder="Specialization"
                                                    formControlName="n_Specialization"
                                                    [(ngModel)]="Specialization"></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="fileupload">
                                                <div class="placeholder">Certificate Upload</div>
                                                <input type="file" name="" class="fileupload-container" size="60"
                                                    [(ngModel)]="CertificateDocument" formControlName="n_CertificateDoc"
                                                    (change)="onCertificateDocChange($event)">
                                                <div class="browse-upload">
                                                    <input name="" type="text" class="upload-txt"
                                                        value="{{CertificateDocumentValue}}" placeholder="Select file"
                                                        [(ngModel)]="CertificateDocument"
                                                        formControlName="n_CertificateDoc">
                                                    <input type="submit" value="Browse" class="upload-btn"
                                                        (change)="onCertificateDocChange($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Years of Experience</mat-label>
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-select placeholder="Experience in years"
                                                    formControlName="n_Experience" [(ngModel)]="CoachExperience">
                                                    <mat-option *ngFor="let exp of Experience" [value]="exp.Experience">
                                                        {{exp.Experience}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 txt-center">
                                            <button mat-raised-button color="primary" class="mr10"
                                                (click)="SaveCoachInfo(stepper)">Next</button>
                                            <button mat-raised-button color="primary">Clear</button>
                                        </div>
                                    </div>
                                </form>
                            </mat-step>
                            <mat-step [completed]="false">
                                <form [formGroup]="CoachAboutMeForm" #am="ngForm">
                                    <ng-template matStepLabel>About Myself</ng-template>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="row">
                                                <div class="col-md-6">

                                                    <mat-form-field class="md-block" appearance="outline"
                                                        floatLabel="always">
                                                        <mat-label>About Me</mat-label>
                                                        <textarea matInput placeholder="About Me"
                                                            formControlName="n_CoachAboutMe"
                                                            [(ngModel)]="CoachAboutMe"></textarea>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-6">
                                                    <mat-form-field class="md-block" appearance="outline"
                                                        floatLabel="always">
                                                        <mat-label>Tag Line</mat-label>
                                                        <textarea matInput placeholder="Tag Line"
                                                            formControlName="n_CoachTagLine"
                                                            [(ngModel)]="CoachTagLine"></textarea>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="fileupload">
                                                        <div class="placeholder">Photo Upload</div>
                                                        <input type="file" name="photos" class="fileupload-container"
                                                            multiple size="60" [(ngModel)]="CoachPhotos"
                                                            formControlName="n_CoachPhotos"
                                                            (change)="onPhotosChange($event)">
                                                        <div class="browse-upload">
                                                            <input name="cphoto" type="text" class="upload-txt"
                                                                value="{{CoachPhotosvalue}}" placeholder="Select file"
                                                                [(ngModel)]="CoachPhotos"
                                                                formControlName="n_CoachPhotos">
                                                            <input type="submit" value="Browse" class="upload-btn"
                                                                (change)="onPhotosChange($event)">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="fileupload">
                                                        <div class="placeholder">Video Upload</div>
                                                        <input type="file" name="videos" class="fileupload-container"
                                                            multiple size="60" [(ngModel)]="CoachVideos"
                                                            formControlName="n_CoachVideos"
                                                            (change)="onVideosChange($event)">
                                                        <div class="browse-upload">
                                                            <input name="cphoto" type="text" class="upload-txt"
                                                                value="{{CoachPhotosvalue}}" placeholder="Select file"
                                                                [(ngModel)]="CoachVideos"
                                                                formControlName="n_CoachVideos">
                                                            <input type="submit" value="Browse" class="upload-btn"
                                                                (change)="onVideosChange($event)">
                                                        </div>
                                                        <!-- <input type="file" name="" class="fileupload-container"
                                                            size="60">
                                                        <div class="browse-upload">
                                                            <input name="" type="text" class="upload-txt" value=""
                                                                placeholder="Select file">
                                                            <input type="submit" value="Browse" class="upload-btn">
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="title">List of Videos</div>
                                            <ul class="upload-document">
                                                <li *ngFor="let videos of selectedvideosList">
                                                    <a href="javascript:void(0);" data-toggle="modal"
                                                        data-target="#documentview">{{videos.VideoURL}}</a>
                                                    <span><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                </li>

                                            </ul>
                                            <div class="title">List of Photos</div>
                                            <ul class="upload-document">
                                                <li *ngFor="let photos of selectedphotosList">
                                                    <a href="javascript:void(0);" data-toggle="modal"
                                                        data-target="#documentview">{{photos.PhotoURL}}</a>
                                                    <span><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 txt-center">
                                            <button mat-raised-button color="primary" matStepperPrevious
                                                class="mr10">Back</button>
                                            <button mat-raised-button color="primary"
                                                (click)="SaveCoachAboutInfo(stepper)">Next</button>
                                        </div>
                                    </div>
                                </form>
                            </mat-step>
                            <mat-step [completed]="false">
                                <form [formGroup]="CoachBankInfo" #cb="ngForm">
                                    <ng-template matStepLabel>Bank Info</ng-template>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Bank Name</mat-label>
                                                <input matInput [(ngModel)]="BankName" formControlName="n_BankName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Bank Branch Name</mat-label>
                                                <input matInput [(ngModel)]="BranchName" formControlName="n_BranchName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768"
                                                        y="0" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3"
                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                        </path>
                                                        <path
                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Account Holder Name</mat-label>
                                                <input matInput [(ngModel)]="AccountHolderName"
                                                    formControlName="n_AccountHolderName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Account Number</mat-label>
                                                <input matInput [(ngModel)]="AccountNumber" name="n_AccountNumber"
                                                    formControlName="n_AccountNumber">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Confirm Account Number</mat-label>
                                                <input matInput [(ngModel)]="ConfirmAccountNumber"
                                                    name="n_ConfirmAccountNumber"
                                                    formControlName="n_ConfirmAccountNumber">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>IFSC Code</mat-label>
                                                <input matInput [(ngModel)]="IFSCcode" formControlName="n_IFSCcode">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>SWIFT Code</mat-label>
                                                <input matInput [(ngModel)]="SwiftCode" formControlName="n_SwiftCode">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="txt-center col-md-12">
                                            <button mat-raised-button color="primary" matStepperPrevious
                                                class="mr10">Back</button>
                                            <button mat-raised-button color="primary"
                                                (click)="SaveCoachBankInfo(stepper)">Save
                                                & Next</button>
                                        </div>
                                    </div>
                                </form>
                            </mat-step>
                            <mat-step [completed]="false">
                                <form [formGroup]="CoachFeesInfo" #cf="ngForm">
                                    <ng-template matStepLabel>Coach Fee</ng-template>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Coach Fee per Session</mat-label>
                                                <input matInput [(ngModel)]="FeesperSession" NumbersOnly
                                                    formControlName="n_FeesperSession">
                                            </mat-form-field>
                                        </div>
                                        <!-- <div class="col-md-4">
                                            <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Max session per day</mat-label>
                                                <mat-select placeholder="Day per Session" [(ngModel)]="SessionsperDay"
                                                    formControlName="n_SessionsperDay">
                                                    <mat-option [value]="01">1</mat-option>
                                                    <mat-option [value]="02">2</mat-option>
                                                    <mat-option [value]="03">3</mat-option>
                                                    <mat-option [value]="04">4</mat-option>
                                                    <mat-option [value]="04">5</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>Max days per session</mat-label>
                                                <mat-select placeholder="Day per Session" [(ngModel)]="DaysperSession"
                                                    formControlName="n_DaysperSession">
                                                    <mat-option [value]="01">1</mat-option>
                                                    <mat-option [value]="02">2</mat-option>
                                                    <mat-option [value]="03">3</mat-option>
                                                    <mat-option [value]="04">4</mat-option>
                                                    <mat-option [value]="04">5</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                        <div class="col-md-4">
                                            <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912"
                                                        y="1344" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z">
                                                        </path>
                                                        <path
                                                            d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <mat-label>No of Video Sessions</mat-label>
                                                <mat-select placeholder="No of Video Sessions"
                                                    [(ngModel)]="VideosperSession" formControlName="n_VideosperSession">
                                                    <mat-option [value]="01">1</mat-option>
                                                    <mat-option [value]="02">2</mat-option>
                                                    <mat-option [value]="03">3</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <div class="col-md-6">
                                            <section class="example-section coachpad-checklist">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ChatEnabled"
                                                    formControlName="n_ChatEnabled">IsChatEnabled</mat-checkbox>
                                                <mat-checkbox class="example-margin" [(ngModel)]="VideoUpload"
                                                    formControlName="n_VideoUpload">IsVideoUpload</mat-checkbox>
                                                <mat-checkbox class="example-margin" [(ngModel)]="AudioNotes"
                                                    formControlName="n_AudioNotes">IsAudioNotes</mat-checkbox>
                                            </section>
                                        </div> -->
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 txt-center">
                                            <button mat-raised-button color="primary" matStepperPrevious
                                                class="mr10">Back</button>
                                            <button mat-raised-button color="primary" matStepperNext
                                                (click)="SaveCoachFeesInfo(stepper)">Next</button>
                                        </div>
                                    </div>
                                </form>
                            </mat-step>
                            <mat-step [completed]="false">
                                <ng-template matStepLabel>Terms & Conditions </ng-template>
                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- <div class="sub-title">Terms & Conditions</div> -->
                                        <div class="bullets-title"> <sup>*</sup>Per Session </div>
                                        <ul class="bullets1">
                                            <li>One Session consists of two video upload from the Athlete (8 mins
                                                each).
                                            </li>
                                            <li>Coaching window – 5* days from the time of upload by the athlete.
                                            </li>
                                        </ul>
                                        <div class="bullets-title"> Terms </div>
                                        <ul class="bullets1">
                                            <li>Video will be available for a period of 5 days only for analysis &
                                                feedback during which the athlete can respond back to your comments
                                                through text and voice notes.</li>
                                            <li>If analysis and feedback is not provided by the coach within the
                                                coaching window of 5 days, the coaching window will be closed
                                                automatically and the coaching fee will not be remitted.</li>
                                            <li>X % of amount per session will be deducted towards service fee by
                                                Third
                                                eye</li>
                                            <li>Session rating will be given by the athlete at the completion of
                                                each
                                                session</li>
                                            <li>Automated rating will be given for the coach based on his response
                                                time
                                                and quality of feedback</li>
                                        </ul>
                                        <p class="termsconditions">
                                            <mat-checkbox></mat-checkbox> I Accept the Terms & Conditions
                                        </p>
                                    </div>
                                    <div class="col-md-12 txt-center">
                                        <button mat-raised-button color="primary" matStepperPrevious
                                            class="mr10">Back</button>
                                        <button mat-raised-button color="primary" matStepperPrevious
                                            class="mr10">Update</button>
                                        <button mat-raised-button color="primary"
                                            (click)="stepper.reset()">Reset</button>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-vertical-stepper>
                    </div>
                </div>
            </div>
            <div class="output-container" *ngIf="coachpad=='coachProfile'">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="coachprofile-container">
                            <div class="coachoprofile-header">
                                <div class="coachimg">
                                    <img src="{{CoachImage}}">
                                </div>
                            </div>
                            <div class="coachoprofile-detail">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h4>{{coachName}}

                                            <span class="coachinfo active" title="Active" *ngIf="CoachAvailability==1">
                                                Active </span>
                                            <span class="coachinfo inactive" title="Active"
                                                *ngIf="CoachAvailability==2"> Inactive </span>
                                            <span class="edit-status" title="Edit Status">
                                                <img src="assets/images/ico-edit.png" (click)="showEditStatus();" />
                                                <div class="editstatus box-shadow" *ngIf="isEditStatus">
                                                    <section class="coachtxt">
                                                        <mat-radio-group>
                                                            <mat-radio-button class="example-margin" value="active"
                                                                (click)="ChangeStatus(1)" [checked]="AvailableStatus==1">Active</mat-radio-button>
                                                            <mat-radio-button class="example-margin" value="inactive"
                                                                (click)="ChangeStatus(2)" [checked]="AvailableStatus==2">Inactive</mat-radio-button>
                                                        </mat-radio-group>
                                                    </section>
                                                    <div class="txt-center">
                                                        <button mat-raised-button color="primary" class="updatestatus"
                                                            (click)="confirmUpdate('status',$event)">Submit</button>
                                                    </div>
                                                </div>
                                            </span>
                                        </h4>
                                        <p class="coachoprofile-proficiency">{{coachLevel}}</p>
                                        <!-- <section class="coachtxt">
                                            <mat-radio-group>
                                                <mat-radio-button class="example-margin" value="active"
                                                    (click)="ChangeStatus(1)">Active</mat-radio-button>
                                                <mat-radio-button class="example-margin" value="inactive"
                                                    (click)="ChangeStatus(2)">Inactive</mat-radio-button>
                                            </mat-radio-group>
                                        </section>
                                        <button mat-raised-button color="primary" class="updatestatus"
                                            (click)="UpdateStatus()">Update Status</button> -->
                                    </div>
                                    <div class="col-md-4 txt-right">
                                        <div><a href="javascript:void(0);" class="coachedit"
                                                (click)="showCoachform()">Edit
                                                Profile</a></div>
                                        <!-- <div><span class="coachinfo active" *ngIf="CoachAvailability==1">Active</span></div>
                                        <div><span class="coachinfo inactive" *ngIf="CoachAvailability==2">InActive</span></div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fees-title"> Coach Fees Details </div>
                        <div class="session-details">
                            <a href="javascript:void(0);">
                                <p>$ {{coachFees}}</p>
                                <span class="sessionname">Coach Fee per Session</span>
                            </a>
                            <a href="javascript:void(0);">
                                <p>{{CoachVideos}}</p>
                                <span class="sessionname">No of Sessions per month</span>
                            </a>
                            <div class="ico-setting"></div>
                            <div class="ico-editsetting">
                                <a href="javascript:void(0);" (click)="openEditFees()" title="Edit Fees">Edit
                                    Fees</a>
                            </div>
                            <div class="autoschedule">
                                <mat-slide-toggle (change)="confirmUpdate('autoupdate',$event)" #autoupdatetoggle [checked]="isAutoUpdate">Auto
                                    Update Every Month</mat-slide-toggle>
                            </div>
                            <div class="editfeescontainer" *ngIf="isEditFees">
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                            <mat-label>Coach Fee per Session</mat-label>
                                            <input matInput NumbersOnly [(ngModel)]="coachFeesedit">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                            <mat-label>Max Session per Month</mat-label>
                                            <mat-select placeholder="No of Video Sessions"  [(ngModel)]="CoachVideosedit">
                                                <mat-option [value]="1">1</mat-option>
                                                <mat-option [value]="2">2</mat-option>
                                                <mat-option [value]="3">3</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 txt-center">
                                        <button mat-raised-button color="primary" (click)="updateCoachFeesInfo();">Update</button>
                                        <button style="margin-left: 5px;" mat-raised-button color="primary" (click)="isEditFees=false;">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Video Analyse</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false;">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Video Analyse</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Video Analyse</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isWarningAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isDeleteConfirm">
    <div class="warning-widget">
       <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="warning-container">
           <div class="alcenter">
                <p>{{deleteAlert}}</p>           
                <p>If Confirm Press OK Button</p> 
           </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="confirmedupdate()">Ok</button>
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top" (click)="cancelDelete(statustoggle)">Cancel</button>
        </div>
   </div>
</div>