import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-coachpad-player',
  templateUrl: './coachpad-player.component.html',
  styleUrls: ['./coachpad-player.component.css']
})
export class CoachpadPlayerComponent implements OnInit {
  public UserCode: string;
  public isPlayer = false;
  public Pagetitle: string = "";
  selectedTab: string = 'ActiveSession';
  SearchFilter!: string;
  constructor(public utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).UserID);
    this.loadMyActive();
    
  }
  Back() { 
    this.Pagetitle = "";
  }
  ActiveSessions: any;
  ActiveRequests: any;
  CompletedSessions: any;
  loadMyRequests() {
    this.selectedTab = 'ActiveRequests';
    this.service.CoachProfileSettings({
      method: "CoachPad/PlayerRequests",
      request: { ActionFlag: "MyActive", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      this.ActiveRequests = ret.MyPendingRequests;
    });
  }
  loadMyActive() {
    this.selectedTab = 'ActiveSessions';
    this.service.CoachProfileSettings({
      method: "CoachPad/PlayerRequests",
      request: { ActionFlag: "MyProgress", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      this.ActiveSessions = ret.MyPendingRequests;

    });
  }
  loadCompletedSessions() {
    this.selectedTab = 'CompletedSessions';
    this.service.CoachProfileSettings({
      method: "CoachPad/PlayerRequests",
      request: { ActionFlag: "MyCompleted", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      this.CompletedSessions = ret.MyPendingRequests;
    });
  }
  AvailableCoachesList: any = [];
  CoachCount: number = 0;
  sessionRate:number=3.5;
  loadCoaches() {
    this.selectedTab = 'LoadCoaches';
    this.service.CoachProfileSettings({
      method: "CoachProfileSettings/CoachMarketPlace",
      request: { ActionFlag: "GetMarketCoach", RefUserID: this.UserCode }
    }).subscribe((ret: any = []) => {
      this.AvailableCoachesList = ret.AvailableCoachesList;
      this.CoachCount = ret.AvailableCoachesList.length;
    });
  }
  PayNow(val) {
    if (val.RequestStatus == 'Pay Now') {
      this.service.CoachProfileSettings({
        method: "CoachPad/PlayerRequests",
        request: { ActionFlag: "PayNow", RefUserID: this.UserCode, RequestID: String(val.RequestID) }
      }).subscribe((ret: any = []) => {

        if (ret.Status == true) {
          alert('Payment Done');
          this.loadMyRequests();
          this.loadMyActive();
        }
      });
    }
  }
  CoachesDetails: any = [];
  CoachPhotos: any = [];
  CoachVideos: any = [];
  public coachdet!: coachdetails[];
  LoadCoachDetails(data) {
    this.utility.coachDetails = data;
      this.Pagetitle = "CoachProfile";
     
  }
  loadPlayerwall(data) {
    this.utility.coachDetails = data;
     this.Pagetitle = "PlayerWall";
  }
}

export interface coachdetails {
  CoachID: string, ProSettingsID: string
}
