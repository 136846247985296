import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';

@Component({
  selector: 'app-coach-list',
  templateUrl: './coach-list.component.html',
  styleUrls: ['./coach-list.component.css']
})
export class CoachListComponent implements OnInit {
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }

  @Input() today = new Date();
  @Output() EditAthleteID = new EventEmitter<any>();

  public UserCode: string = "2";
  public ButtonType: string;

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  public isConfirmAlert:boolean = false; public confirmAlert:string;

  public lstCoachGridLoad: any = [];
  public editCoachData: any = [];
  public SearchFilter:string;

  public ColumnMode: ColumnMode;
  public rowLimit: number = 10;
  public LIMITS = [
    { value: 10 },
    { value: 20 },
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  ngOnInit(): void {
    this.UserCode=String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    this.InitGridLoad();
  }


  InitGridLoad() {
    this.isShowLoader = true;
    pageStatus = "GridLoad";
    var AssoLoad = { ActionFlag: "GridLoad", ModuleName: "", UserID: this.UserCode };
    this.service.AssoCoachRegLoad(AssoLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstCoachGridLoad = ret.AssoCoachList;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Grid load failed..!! \n';
        return;
      }
    })
  }


  DeleteCoach(coach) {
    this.isWarningAlert =false;
    this.isShowLoader = true;
    let deleteReferenceID = String(coach.UserID);
    var AssoLoad = { RefUserID: deleteReferenceID, ActionFlag: "DeActivate", ModuleName: "AssoCoachReg", UserID: this.UserCode };
    this.service.CoachMasterCRUD(AssoLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        this.successAlert = '\n "' + coach.DisplayName + '" \n DeActivated successfully \n';
        this.InitGridLoad();
        // this.utility.openSnackBar('Deleted Successfully..!!' );
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n  "' + coach.DisplayName + '"\n DeActivation failed \n';
        //this.utility.openSnackBar('Load Failed..!!' );
      }
    })
    pageStatus = "GridLoad";
  }


  RecordStatusChange(status) {
    this.isConfirmAlert = false;
    this.isShowLoader = true;
    let AssoID = String(status.UserID);
    let AssoStatus = String(status.RecordStatus);
    let RefUserID = String(status.UserID);
    let flag: string;
    if (AssoStatus == "InActive") { flag = "Activate" }
    else { flag = "DeActivate" }

    if (AssoStatus == "InActive") {
      var AssoLoad = { RefUserID: RefUserID, ActionFlag: "Activate", ModuleName: "AssoCoachReg", UserID: this.UserCode };
      this.service.CoachMasterCRUD(AssoLoad).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          if (flag == "Activate") this.successAlert = '\n"' + status.DisplayName + '"\n Activated successfully..!!';
          else if (flag == "DeActivate") this.successAlert = '\n"' + status.DisplayName + '"\n DeActivated successfully..!!';
          this.isShowLoader = false;
          this.InitGridLoad();
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          if (flag == "Activate") this.successAlert = '\n"' + status.DisplayName + '"\n Activation failed..!!';
          else if (flag == "DeActivate") this.successAlert = '\n"' + status.DisplayName + '"\n DeActivation failed..!!';
          return;
          // this.utility.openSnackBar('Load Failed..!!' );
        }
      })
    }
    else {
      this.isShowLoader = false;
      this.isWarningAlert = true;
      this.warningAlert = '\n"' + status.DisplayName + '"\n Already in active status.!!';
    }
    pageStatus = "GridLoad";
  }


  EditCoach(coach) {
    let Status: string;
    Status = String(coach.RecordStatus);
    if (Status != "InActive") {
      this.isShowLoader = true;
      this.editCoachData = coach;
      pageStatus = "Edit";
      sessionStorage.setItem("CoachPageStatus", "Edit");
      sessionStorage.setItem("CoachID", String(coach.UserID));
      selectedCoachList = coach;
      this.EditAthleteID.emit(this.editCoachData);
      this.router.navigate(['/coach']);
      this.isShowLoader = false;
    }
    else {
      this.isShowLoader = false;
      this.isWarningAlert = true;
      this.warningAlert = '\n"' + coach.DisplayName + '"\n Could not be Edit,in DeActive status..!!';
      return;
    }
    pageStatus = "GridLoad";
  }



  // (click)="DeleteCoach(row)"  // (click)="RecordStatusChange(row)" 

  public rowValue:any[];
  public rowValueActive:any[];

  showWarningDialogue(row){
      if ( String(row.RecordStatus) == "Active" ){
        this.rowValue=row;
        this.isWarningAlert = true;
        this.warningAlert = "Are you sure want to Delete..?"
      }
      else if ( String(row.RecordStatus) == "InActive" ){
        this.rowValueActive=row;
        this.isConfirmAlert = true;
        this.confirmAlert = "Are you sure want to Activate..?"
      }
      else{
        this.isWarningAlert = false;
        return;
      }
  }



}



export var selectedCoachList: any = [];
export var pageStatus: string = "";

