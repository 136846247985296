import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';

@Component({
  selector: 'app-batch-master',
  templateUrl: './batch-master.component.html',
  styleUrls: ['./batch-master.component.css']
})
export class BatchMasterComponent implements OnInit {
  
  BatchMasterFrom:FormGroup;
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  @ViewChild(DatatableComponent) batchtable: DatatableComponent;

  constructor( private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe ) { this.today.setDate(this.today.getDate()); }

  submitted = false;

  @Input()
  public BatchName: string; public BatchShortName: string;
  public BatchDescription: string;
  today = new Date();
  
  public UserCode: string = "";
  public ButtonType: string;

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  public isConfirmAlert: boolean = false; public confirmAlert: string;

  public ColumnMode: ColumnMode;
  public rowLimit: number = 10;
  public lstAssociationsFiltered: any = [];
  public SearchFilter: string;
  public LIMITS = [
    { value: 10 },
    { value: 20 },
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  ngOnInit(): void {
    this.utility.pageLoadScript(); 
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.InitLoadBatchMaster();
  }

  // ----- Batch Master Begin ----- //
  public BatchID: string;
  public listBatchMasterGridLoad: any[]; public listBatchMasterEditLoad:any;

  InitLoadBatchMaster() {
    this.isShowLoader = true;
    this.BatchMasterFrom = this.formBuilder.group({
        n_BatchName:['', Validators.required ],
        n_BatchShortName:['', Validators.required ],
        n_BatchDescription:[ ],
    });
    this.ClearBatchMaster();
    this.ButtonType = "Save";
    var BatchMaster = { BatchID: "", ActionFlag: "GridLoad", UserID: this.UserCode };
    this.service.BatchMasterMaster(BatchMaster).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.listBatchMasterGridLoad = ret.BatchMasterGridLoad;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert =  ret.Message;
        return;
      }
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.BatchMasterFrom.invalid) {
      return;
    }
    else {
      this.SaveBatchMaster();
    }
  }
 
  SaveBatchMaster() {
    this.isShowLoader = true;
    let submitType: string;
    if (this.ButtonType == "Save") { submitType = "Register", this.BatchID = "" }
    else { submitType = "Update", this.BatchID = this.BatchID }
    var BatchMaster = {
      BatchID: String(this.BatchID), BatchName: this.BatchName, BatchShortName: this.BatchShortName,
      BatchDescription: this.BatchDescription, ActionFlag: String(submitType),  UserID: this.UserCode
    };
    this.service.BatchMasterMaster(BatchMaster).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        if (this.ButtonType == "Save") this.successAlert = '\n"' + this.BatchName + '"\n Saved successfully..!!';
        else if (this.ButtonType == "Update") this.successAlert = '\n"' + this.BatchName + '"\n Updated successfully..!!';
        this.ngOnInit();
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        // if (this.ButtonType == "Save") this.errorAlert = '\n"' + this.BatchName + '"\n Save failed..!!';
        // else if (this.ButtonType == "Update") this.errorAlert = '\n"' + this.BatchName + '"\n Update failed..!!';
        this.errorAlert =  this.BatchName + '-' + ret.Message;
        return;
      }
    })
  }


  EditBatchMaster(edit) {
    this.isShowLoader = true;
    var BatchMaster = { BatchID: String(edit.BatchID), ActionFlag: "Edit", UserID: this.UserCode };
    this.service.BatchMasterMaster(BatchMaster).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
          this.listBatchMasterEditLoad = ret.BatchMasterEditLoad;
          this.BatchID = this.listBatchMasterEditLoad[0].BatchID;
          this.BatchName = this.listBatchMasterEditLoad[0].BatchName;
          this.BatchShortName = this.listBatchMasterEditLoad[0].BatchShortName;
          this.BatchDescription = this.listBatchMasterEditLoad[0].BatchDescription;
        this.ButtonType = "Update";
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert =  edit.BatchName + '-' + ret.Message;
        return;
      }
    })
  }


  ActivateBatchMaster(activate) {
    this.isConfirmAlert = false;
    this.isShowLoader = true;
    var BatchMaster = { BatchID: String(activate.BatchID), ActionFlag: "Activate", UserID: this.UserCode   };
    this.service.BatchMasterMaster(BatchMaster).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        this.successAlert = '\n"' + activate.BatchName + '"\n Activated successfully..!!';
        this.isShowLoader = false;
        this.ngOnInit();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert =  activate.BatchName + '-' + ret.Message;
        return;
      }
    }) 
  }


  DeActivateBatchMaster(deactivate) {
    this.isWarningAlert =false;
    this.isShowLoader = true;
    var BatchMaster = { BatchID: String(deactivate.BatchID), ActionFlag: "DeActivate", UserID: this.UserCode  };
    this.service.BatchMasterMaster(BatchMaster).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        this.successAlert = '\n"' + deactivate.BatchName + '"\n DeActivated successfully..!!';
        this.isShowLoader = false;
        this.ngOnInit();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert =  deactivate.BatchName + '-' + ret.Message;
        return;
      }
    })
  }


  ClearBatchMaster(){
    this.BatchName = ""; this.BatchShortName = ""; this.BatchDescription = "";
    this.ButtonType = "Save";
  }

  public rowValueBatchMaster: any[];
  public rowValueActiveBatchMaster: any[];
  showWarningDialogueBatchMaster(row) {
    if (String(row.RecordStatus) == "Active") {
      this.rowValueBatchMaster = row;
      this.isWarningAlert = true;
      this.warningAlert = "Are you sure want to DeActivate..?"
    }
    else if (String(row.RecordStatus) == "InActive") {
      this.rowValueActiveBatchMaster = row;
      this.isConfirmAlert = true;
      this.confirmAlert = "Are you sure want to Activate..?"
    }
    else {
      this.isWarningAlert = false;
      return;
    }
  }





}

export var pageStatus: string = "";