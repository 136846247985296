<div class="page-container my-videos-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container" style="min-height:800px;">
                <div class="row">
                    <div class="col-md-3">
                        <div class="fixed-container min">
                            <div class="upload-videos-container box-shadow br-5" *ngIf="videouploadsession">
                                <div class="title">Add Your Session</div>
                                <div class="upload-container">
                                    <div class="video-upload mb10">
                                        <a href="javascript:void(0);" (click)="showVideoFormfields();">
                                            <img src="assets/images/add-your-first-session.png" class="img-fluid">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="upload-videos-container box-shadow br-5 upload-common" *ngIf="videoformfields">
                                <div class="title">Fill Video Form fields</div>
                                <div class="upload-container upload-type mb10">
                                    <div class="upload-form">
                                        <form [formGroup]="SessionForm" #ses="ngForm" (ngSubmit)="showVideoupload()">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <mat-form-field class="md-block" appearance="outline"
                                                        floatLabel="always">
                                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24"
                                                                x="768" y="0" fit="" height="100%" width="100%"
                                                                preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path opacity=".3"
                                                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                </path>
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                </path>
                                                            </svg></mat-icon>
                                                        <mat-label>Enter Season Name</mat-label>
                                                        <input matInput required name="sessionName"
                                                            formControlName="n_sessionName" [(ngModel)]="sessionName">
                                                    </mat-form-field>
                                                    <div *ngIf="ses.submitted && SessionForm.controls.n_sessionName.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="SessionForm.controls.n_sessionName.errors.required">
                                                            Session Name is required.</div>
                                                    </div>
                                                    <!-- <div *ngIf="a.submitted && AthleteBasicForm.controls.n_AssociationName.invalid"
                                                            class="alert alert-danger">
                                                        <div *ngIf="AthleteBasicForm.controls.n_AssociationName.errors.required">
                                                            Association is required.</div>
                                                    </div> -->
                                                </div>
                                                <div class="col">
                                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block"
                                                        appearance="outline" floatLabel="always">
                                                        <mat-label>Select Date</mat-label>
                                                        <input matInput [matDatepicker]="picker" name=" "
                                                            formControlName="n_sessionDate" [(ngModel)]="sessionDate"
                                                            readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                    <div *ngIf="ses.submitted && SessionForm.controls.n_sessionDate.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="SessionForm.controls.n_sessionDate.errors.required">
                                                            Session Date is required.</div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field class="md-block mat-dd-field " appearance="outline"
                                                        floatLabel="always">
                                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24"
                                                                x="768" y="0" fit="" height="100%" width="100%"
                                                                preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path opacity=".3"
                                                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                </path>
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                </path>
                                                            </svg></mat-icon>
                                                        <mat-label>Select Skill</mat-label>
                                                        <mat-select placeholder="Skill" name="skill"
                                                            formControlName="n_skill" [(ngModel)]="skill"
                                                            style="text-transform:capitalize;">
                                                            <mat-option [value]="ses.SessionTypeID"
                                                                *ngFor="let ses of SessionTypes"
                                                                style="text-transform:capitalize;">
                                                                {{ses.SessionTypeName}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div *ngIf="ses.submitted && SessionForm.controls.n_skill.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="SessionForm.controls.n_skill.errors.required">
                                                            Select the Skill.</div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field class="md-block mat-dd-field " appearance="outline"
                                                        floatLabel="always">
                                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24"
                                                                x="768" y="0" fit="" height="100%" width="100%"
                                                                preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path opacity=".3"
                                                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                </path>
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                </path>
                                                            </svg></mat-icon>
                                                        <mat-label>Select View</mat-label>
                                                        <mat-select placeholder="View Type" name="viewType"
                                                            formControlName="n_viewType" [(ngModel)]="viewType"
                                                            style="text-transform:capitalize;">
                                                            <mat-option [value]="view.VideoViewID"
                                                                *ngFor="let view of VideoViewTypes"
                                                                style="text-transform:capitalize;">
                                                                {{view.VideoViewName}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div *ngIf="ses.submitted && SessionForm.controls.n_viewType.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="SessionForm.controls.n_viewType.errors.required">
                                                            Select the View Type.</div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 txt-center mt10">
                                                    <!-- <button mat-raised-button color="primary" class="mb5">Save</button> -->
                                                    <button mat-raised-button color="primary" class="mb5 ml10"
                                                        type="submit">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div class="upload-videos-container box-shadow br-5" *ngIf="videoupload">
                                <div class="title">Video Upload</div>
                                <div class="upload-container">
                                    <div class="video-upload mb10">
                                        <div class="dropzone">
                                            <div class="text-wrapper">
                                                <div class="centered">
                                                    <input type="file" name="file" id="file" multiple
                                                        (change)="onVideoChange($event)">
                                                    <label for="file"><span class="textLink">Select your file</span>
                                                        <!--or Drop it here!-->
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 txt-center mt10">
                                        <!-- <button mat-raised-button color="primary" class="mb5">Save</button> -->
                                        <button mat-raised-button color="primary" class="mb5 ml10"
                                        (click)="showVideoFormfields();">Back</button>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="upload-videos-container box-shadow br-5" [hidden]="!videouploadstatus">
                                <div class="title">Upload Skill</div>
                                <div class="upload-container upload-type mb10">
                                    <div class="left-video-container">
                                        <!-- <video id="example_video_1" class="video-js" controls preload="none" width="640"
                                            height="264" poster="https://i.ytimg.com/vi/MFSjLx-LkrU/maxresdefault.jpg"
                                            data-setup="{}">
                                            <source src="GANGA-SRIDHAR-BATTING-FRONT-VIEW.mp4" type="video/mp4">
                                        </video> -->
                                        <video id="myVideo" class="video-js" preload="none" width="640" height="100%"
                                            controls disablePictureInPicture controlsList="nodownload" #video
                                            (loadedmetadata)="onMetadata($event, video)">
                                        </video>
                                        <canvas id="canvas" width="1280" height="720" style="display: none;"></canvas>
                                        <!-- <img id="imgId" src=""/> -->
                                        <!-- <canvas id="canvas"></canvas> <br /><br /> -->
                                        <div class="video-size txt-right">{{Videoduration}}
                                            <span>{{selectedfilesize}}</span>
                                        </div>

                                        <div class="card-profile-progress">
                                            <div class="prg-container">
                                                <!-- <div class="prg-rating">{{uploadedsize}}/{{selectedfilesize}}</div> -->
                                                <div class="prg-ratio session">
                                                    <mat-progress-bar mode="determinate" [value]="percent">
                                                    </mat-progress-bar>
                                                </div>
                                                <div class="prg-percentage"> {{ percent }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 txt-center">
                                        <button mat-raised-button color="primary" class="mb5 mr5" [disabled]="!IsShowUpload" (click)="uploadbutton()">Upload</button>
                                        <button mat-raised-button color="primary" class="mb5 mr5" (click)="cancel($event)">Cancel</button>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="upload-videos-container box-shadow br-5" *ngIf="videosubscription">
                                <div class="title">Video Subscription</div>
                                <div class="upload-container upload-subs mb10">
                                    <div class="left-video-container">
                                        <video id="example_video_1" class="video-js" controls preload="none" width="640"
                                            height="264" poster="https://i.ytimg.com/vi/MFSjLx-LkrU/maxresdefault.jpg"
                                            data-setup="{}">
                                            <source src="GANGA-SRIDHAR-BATTING-FRONT-VIEW.mp4" type="video/mp4">
                                        </video>
                                        <div class="video-size txt-right">7:30 Mins <span>20MP</span></div>
                                    </div>
                                    <div class="subscription-warning txt-center">
                                        <p class="mb15">You have reached your maximum monthly limit of 8 Session Uploads
                                        </p>
                                        <p>Upgrade your Subscription to upload more videos !!</p>
                                        <div class="txt-center">
                                            <button mat-raised-button color="primary" class="mb5">Upgrade</button>
                                            <button mat-raised-button color="primary" class="mb5 ml10"
                                                (click)="showVideoGoupload();">Go Upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row" *ngIf="IsEmptyVideos">
                            <div class="col-md-12">
                                <div class="white">
                                    <div class="no-videos txt-center">
                                        <img src="assets/images/No-Session-640.png" class="img-fluid" draggable="false">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="video-container">
                            <div class="video-item br-5"
                                *ngFor="let sessions of SessionsList|filter:SearchFilter;let ind=index;">
                                <div class="vprofile-header">
                                    <a href="javascript:void(0);" class="vprofile-left">
                                        <div class="vprofile">
                                            <img src="{{sessions.ProfileImage}}"
                                                onerror="this.src='assets/images/no_user.png';">
                                        </div>
                                        <div class="vprofile-info">
                                            <div class="vprofile-name" style="text-transform:capitalize;">
                                                {{sessions.PlayerName}}</div>
                                            <div class="vprofile-type">{{sessions.PlayerRoleName}}</div>
                                            <div class="vprofile-time">{{sessions.SessionDate}}
                                                <!-- <span>5:30PM</span> -->
                                            </div>
                                        </div>
                                    </a>
                                    <!-- <div class="vprofile-more txt-right">
                                        <div class="dropdown">
                                            <a href="javascript:void(0);" class="ico-menu dropdown-toggle"
                                                id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false"></a>
                                            <div class="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Copy Link</a>
                                                <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- poster="http://vjs.zencdn.net/v/oceans.png"  -->
                                <div class="vprofile-video">
                                    <video id="my-video" class="video-js" controls poster="{{sessions.ThumbnailURL}}"
                                        preload="none" width="640" height="264" data-setup="{}" disablePictureInPicture
                                        controlsList="nodownload">
                                        <source src="{{sessions.CloudURL}}" type="video/mp4">
                                    </video>
                                </div>
                                <div class="vprofile-session">
                                    <div class="vpsession-name">{{sessions.SessionName}} </div> 
                                    <div class="session">
                                        <span>{{sessions.SessionTypeName}}</span>
                                        <span> {{sessions.VideoViewName}}</span>
                                    </div>
                                    <div class="feed-info txt-right">
                                        <!-- <a href="javascript:void(0);">4 Fist Bump</a> -->
                                        <a href="javascript:void(0);">{{sessions.SessionComments}} Comments</a>
                                    </div>
                                </div>
                                <div class="vprofile-feed" >
                                    <div class="row">
                                        <div class="col-md-3"> <a href="javascript:void(0);" class="fist-bump"
                                                matRipple>Fist Bump</a> </div>
                                        <div class="col-md-3"> <a href="javascript:void(0);" class="comments"
                                                (click)="showCommentbox('comm'+sessions.SessionID,sessions.SessionID)"
                                                matRipple>Comments</a>
                                        </div>
                                        <div class="col-md-3"> <a href="javascript:void(0);" class="fdanalyse"
                                                (click)="GetAnalysisDetails(sessions)" matRipple>Analyse</a>
                                        </div> <!-- (click)="GetAnalysisDetails(sessions)" -->
                                        <div class="col-md-3"> <a href="javascript:void(0);" class="share"
                                                (click)="showSharebox('share'+sessions.SessionID,sessions.SessionID)"
                                                matRipple>Share</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="comments-feed" *ngIf="'comm'+sessions.SessionID===openedShareid"
                                    id="comm{{sessions.SessionID}}">
                                    <div *ngIf="MessageList.length===0">
                                        <p>Waiting for review</p>
                                    </div>
                                    <div *ngIf="MessageList.length>0">
                                        <div class="main-comments">
                                            <div class="comments-pic"><img src="assets/images/no_user.png"> </div>
                                            <div class="comments-box">
                                                <div class="comments-txt">
                                                    <div class="textarea" role="textbox"
                                                        id="message{{sessions.SessionID}}" contenteditable="true"
                                                        tabindex="1"></div>
                                                </div>
                                                <div class="ico-sent">
                                                    <a href="javascript:void(0);"
                                                        (click)="sendMessage(sessions.SessionID)">
                                                        <img src="assets/images/ico-sent.png"></a>
                                                </div>
                                            </div>
                                            <div class="remove-comments" *ngIf="IsShowComments">
                                                <!-- <span class="remove-list" (click)="hideCommentbox()"></span> -->
                                            </div>
                                        </div>

                                        <div class="post-comments" *ngIf="IsShowComments">
                                            <div *ngFor="let msg of MessageList">
                                                <div
                                                    [ngClass]="(msg.SenderID==UserCode)?'comment-sender1':'comment-sender2'">
                                                    <div class="comments-pic">
                                                        <img draggable="false" src="{{msg.ProfileImage}}"
                                                            onerror="this.src='assets/images/no_user.png';" />
                                                        <!-- <img src="assets/images/no_user.png">  -->
                                                    </div>
                                                    <div class="comments-profile">
                                                        <div class="c-name" style="text-transform:capitalize;">
                                                            {{msg.SenderName}}</div>
                                                        <div class="c-proficiency">{{msg.SenderType}}</div>
                                                    </div>
                                                    <div class="comments-msg">
                                                        {{msg.MsgContent}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="comments-feed sharetag" *ngIf="'share'+sessions.SessionID===openedShareid"
                                    id="share{{sessions.SessionID}}">
                                    <div *ngIf="SelectedCoachList.length>0">
                                        <h3>Shared with:</h3>
                                        <p *ngFor="let c of SelectedCoachList">{{c.CoachName}}</p>
                                    </div>
                                    <div *ngIf="SelectedCoachList.length==0">
                                        <mat-form-field class="mat-dd-field col-md-9" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0"
                                                    fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                    </path>
                                                    <path
                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                    </path>
                                                </svg></mat-icon>
                                            <mat-label>Select Coach to share</mat-label>
                                            <mat-select id="coach{{sessions.SessionID}}"
                                                placeholder="Select Coach to share" name="selectedCoach" 
                                                [(ngModel)]="selectedCoach" style="text-transform:capitalize;">
                                                <mat-option [value]="coach" (click)="coachChange(coach)"
                                                    *ngFor="let coach of CoachList" style="text-transform:capitalize;">
                                                    {{coach.CoachName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button mat-raised-button id="sh{{sessions.SessionID}}"
                                            (click)="shareVideotoCoach(sessions.SessionID,selectedCoach)"
                                            color="primary">Send</button>
                                        <!-- <button type="button" class="mat-dd-field col-md-3">send</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-3">
                        <div class="fixed-container min">
                            <div class="video-filters box-shadow br-5">
                                <div class="title">Filters</div>
                                <div class="video-filters-container">
                                    <div class="search-container">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-form-field class="md-block search-txt" appearance="outline"
                                                    floatLabel="always">
                                                    <mat-icon matSuffix class="mat-icon">
                                                        <a href="javascript:void(0);">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 373.12 373.12" width="24" height="24">
                                                                <path
                                                                    d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                            </svg>
                                                        </a>
                                                    </mat-icon>
                                                    <input matInput placeholder="Search Table"
                                                        [(ngModel)]="SearchFilter">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="title adv-filter">
                                    Advance Filters
                                    <a href="javascript:void(0);" (click)="advFilters=!advFilters"></a>
                                </div>
                                <div class="adv-filter-container" *ngIf="advFilters">
                                    <div class="filter-type mb15">
                                        <mat-radio-group aria-label="Select an option" (change)="changeMonthDate()">
                                            <!-- <mat-radio-button value="1">Month Range</mat-radio-button> -->
                                            <mat-radio-button value="2" [checked]="true">Date Range</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="range-filter">
                                        <div class="row">
                                            <div class="col" *ngIf="showMonthRange">
                                                <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block"
                                                    floatLabel="always">
                                                    <mat-label>Month Range</mat-label>
                                                    <mat-date-range-input [rangePicker]="range">
                                                        <input matStartDate placeholder="Start date">
                                                        <input matEndDate placeholder="End date">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="range">
                                                    </mat-datepicker-toggle>
                                                    <mat-date-range-picker #range></mat-date-range-picker>
                                                </mat-form-field>
                                            </div>
                                            <div class="col" *ngIf="showDateRange">
                                                <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block"
                                                    floatLabel="always">
                                                    <mat-label>Date Range</mat-label>
                                                    <mat-date-range-input [rangePicker]="range">
                                                        <input matStartDate placeholder="Start date"
                                                            [(ngModel)]="filterStartDate">
                                                        <input matEndDate placeholder="End date"
                                                            [(ngModel)]="filterEndDate">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="range">
                                                    </mat-datepicker-toggle>
                                                    <mat-date-range-picker #range></mat-date-range-picker>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                        <div class="select-skill mb20">
                                            <div class="skill-label">Select Skill</div>
                                            <div class="multi-select">
                                                <span *ngFor="let ses of SessionTypes"><a href="javascript:void(0);"
                                                        [ngClass]="classFlag === true ? 'active' : '' "
                                                        (click)="menuToggle($event,ses.SessionTypeID,'type')">{{ses.SessionTypeName}}</a></span>

                                            </div>
                                        </div>
                                        <div class="select-view ">
                                            <div class="view-label">Select View</div>
                                            <div class="single-select">
                                                <ul>
                                                    <li *ngFor="let view of VideoViewTypes"><a
                                                            href="javascript:void(0);"
                                                            [ngClass]="classFlag === true ? 'active' : '' "
                                                            (click)="menuToggle($event,view.VideoViewID,'view')">{{view.VideoViewCode}}</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="txt-center">
                                        <button mat-raised-button color="primary" class="mb5 mt15"
                                            (click)="ApplySessionFilters()">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Session</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false;">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert" >
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Session</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert" >
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Session</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isWarningAlert=false;ngOnInit();">Ok</button>
        </div>
    </div>
</div>