<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container admin-dashboard-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="left-container">
                                    <div class="white br-5 box-shadow">
                                        <div class="title">My Groups</div>

                                        <div class="row" *ngIf = "IsEmptyGroups" >
                                            <div class="col-md-12">
                                                <div class="no-videos txt-center">
                                                    <img src="assets/images/Cric-No-Data-Available-Large.png" class="img-fluid" draggable="false">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="group-container">
                                           <ul class="select" >    
                                                <li [ngClass]="{'select': selectedItem  == group.PlayGroupID }"
                                                    *ngFor="let group of PlayerGroupList; let i=index; "  >
                                                    <a  href="javascript:void(0);"   (click) = "GetAtheleteByPlayGroup($event,group.PlayGroupID)" >
                                                        <div class="group-image"> <img src="{{group.PlayerGroupIcon}}" alt="Play Group" 
                                                            onerror="this.src='assets/images/app-logo2.png';"  /> </div>
                                                        <div class="group-name">
                                                            <p>{{group.PlayGroupName}}</p>
                                                            <span>{{group.AthleteCount}} - Athletes</span>
                                                        </div>
                                                    </a>
                                                </li> 
                                            </ul>
                                        </div> 

                                    </div> 
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="main-tab" rel="player-groups">
                                        <a href="javascript:void(0);" rel="allplayers" class="select" (click)="GetPlayerType('0')"  >All Players</a>
                                        <a href="javascript:void(0);" rel="batsmen" (click)="GetPlayerType('1')" >Batsmen</a>
                                        <a href="javascript:void(0);" rel="bowlers" (click)="GetPlayerType('2')" >Bowlers</a>
                                        <a href="javascript:void(0);" rel="wicketkeepers" (click)="GetPlayerType('3')" >Wicket Keepers</a>
                                        <a href="javascript:void(0);" rel="allrounders"   (click)="GetPlayerType('4')" >All Rounders</a>
                                    </div>
                                    <div class="player-groups">

                                        <div class="row" *ngIf = "IsEmptyAthletes" >
                                            <div class="col-md-11">
                                                <div class="no-videos txt-center">
                                                    <img src="assets/images/Cric-No-Data-Available-Large.png" class="img-fluid" draggable="false">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="main-tab-container allplayers" style="display: block;"  >
                                            <div class="row">
                                                 <!-- All Players -->
                                                <div class="col-md-3"  *ngFor="let players of PlayerGroupPlayers"   >
                                                    <a href="javascript:void(0);" class="coach-player box-shadow"
                                                       (click) = "GetAthleteSharedInfo(players)" >
                                                        <figure>
                                                            <picture>
                                                                <img src="{{players.ProfileImage}}"   onerror="this.src='assets/images/no_user.png';" >
                                                            </picture>
                                                            <figcaption>
                                                                <div class="player-name" style="text-transform:capitalize;" >{{players.DisplayName}}</div>
                                                                <div class="player-proficiency" style="text-transform:capitalize;" >{{players.PlayerRoleName}}</div>
                                                                <div class="group-tbl">
                                                                    <table>
                                                                        <tr>
                                                                            <td style="width:50%;" style="text-transform:capitalize;" >{{players.BattingType}}</td>
                                                                            <td style="width:50%;" style="text-transform:capitalize;" >{{players.BowlingSpecialization}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container batsmen" style="display: none;"  >
                                            <div class="row">
                                                  <!-- Batsmen -->
                                                <div class="col-md-3"  *ngFor="let batsmen of PlayerTypeList"  >
                                                    <a href="javascript:void(0);" class="coach-player box-shadow" 
                                                    (click) = "GetAthleteSharedInfo(batsmen)"   >
                                                        <figure>
                                                            <picture>
                                                                <img src="{{batsmen.ProfileImage}}"   onerror="this.src='assets/images/no_user.png';" >
                                                            </picture>
                                                            <figcaption>
                                                                <div class="player-name" style="text-transform:capitalize;" >{{batsmen.DisplayName}}</div>
                                                                <div class="player-proficiency" style="text-transform:capitalize;" >{{batsmen.PlayerRoleName}}</div>
                                                                <div class="group-tbl">
                                                                    <table>
                                                                        <tr>
                                                                            <td style="width:50%;" >{{batsmen.BattingType}}</td>
                                                                            <td style="width:50%;" >{{batsmen.BowlingSpecialization}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container bowlers" style="display: none;"   >
                                            <div class="row">
                                                  <!-- Bowler -->
                                                  <div class="col-md-3"  *ngFor="let bowler of PlayerTypeList"  >
                                                    <a href="javascript:void(0);" class="coach-player box-shadow"
                                                    (click) = "GetAthleteSharedInfo(bowler)"  >
                                                        <figure>
                                                            <picture>
                                                                <img src="{{bowler.ProfileImage}}"   onerror="this.src='assets/images/no_user.png';" >
                                                            </picture>
                                                            <figcaption>
                                                                <div class="player-name" style="text-transform:capitalize;" >{{bowler.DisplayName}}</div>
                                                                <div class="player-proficiency" style="text-transform:capitalize;" >{{bowler.PlayerRoleName}}</div>
                                                                <div class="group-tbl">
                                                                    <table>
                                                                        <tr>
                                                                            <td style="width:50%;" >{{bowler.BattingType}}</td>
                                                                            <td style="width:50%;" >{{bowler.BowlingSpecialization}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="main-tab-container wicketkeepers" style="display: none;"  >
                                            <div class="row">
                                                  <!-- WK -->
                                                  <div class="col-md-3"  *ngFor="let wk of PlayerTypeList"  >
                                                    <a href="javascript:void(0);" class="coach-player box-shadow"
                                                    (click) = "GetAthleteSharedInfo(wk)" >
                                                        <figure>
                                                            <picture>
                                                                <img src="{{wk.ProfileImage}}"   onerror="this.src='assets/images/no_user.png';" >
                                                            </picture>
                                                            <figcaption>
                                                                <div class="player-name" style="text-transform:capitalize;" >{{wk.DisplayName}}</div>
                                                                <div class="player-proficiency">{{wk.PlayerRoleName}}</div>
                                                                <div class="group-tbl">
                                                                    <table>
                                                                        <tr>
                                                                            <td style="width:50%;">{{wk.BattingType}}</td>
                                                                            <td style="width:50%;">{{wk.BowlingSpecialization}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container allrounders" style="display: none;"  >
                                            <div class="row">
                                                  <!-- All Rounder -->
                                                  <div class="col-md-3"  *ngFor="let AR of PlayerTypeList"  >
                                                    <a href="javascript:void(0);" class="coach-player box-shadow" 
                                                    (click) = "GetAthleteSharedInfo(AR)" >
                                                        <figure>
                                                            <picture>
                                                                <img src="{{AR.ProfileImage}}"   onerror="this.src='assets/images/no_user.png';" >
                                                            </picture>
                                                            <figcaption>
                                                                <div class="player-name" style="text-transform:capitalize;" >{{AR.DisplayName}}</div>
                                                                <div class="player-proficiency">{{AR.PlayerRoleName}}</div>
                                                                <div class="group-tbl">
                                                                    <table>
                                                                        <tr>
                                                                            <td style="width:50%;">{{AR.BattingType}}</td>
                                                                            <td style="width:50%;">{{AR.BowlingSpecialization}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overlay success"  *ngIf = "isSuccessAlert" >
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div  class="alcenter" > 
                <h4>Player Group</h4>
                <p>{{successAlert}}</p> 
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="isSuccessAlert=false" >Ok</button>
        </div>
    </div>
</div> 
 <div class="overlay error" *ngIf = "isErrorAlert" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
                <div  class="alcenter" > 
                 <h4>Player Group</h4>
                <p>{{errorAlert}}</p> 
               </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"   (click)="isErrorAlert=false" >Ok</button>
        </div>
   </div>
</div> 
