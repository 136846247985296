import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { AngularFireDatabase, AngularFireList, DatabaseSnapshot } from '@angular/fire/database';
import { ListView } from '@fullcalendar/list';
//import { AngularFireDatabase, ref, set } from "@angular/fire/database";

@Injectable({
  providedIn: 'root'
})
export class MastersServiceService {
  headers: any;
  _baseURL: string;
  _userProfile: string;
  public playerlistID: any;
  public lastPage: string;
  public VideoSessionDetails: any;
  public CurrentUserID: any;

  public SelectedPlayerData: any;

  constructor(private httpClient: HttpClient, private afAuth: AngularFireAuth, private afs: AngularFirestore
    , private db: AngularFireDatabase) {
    this.headers = new HttpHeaders();
    this.headers.append('content-type', 'applicatsion/json');
    this.headers.append('Access-Control-Allow-Headers', 'Content-Type');
    this.headers.append('Access-Control-Allow-Methods', 'GET');
    this.headers.append('Access-Control-Allow-Methods', 'POST');
    this.headers.append('Access-Control-Allow-Origin', '*');
    this._baseURL = environment._baseURL;
    //this._baseURL = "http://52.172.96.40/CricPassAPI/";    
    // this._baseURL = "http://52.172.96.40/ThirdEyeAPI_UAT/";     
    //  this._baseURL = "https://api.cricpass.com/";
  }
  public Firesignup(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  public FiresignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  public sendFireMessage(data) {
    const tutRef = this.db.list(String(data.RequestID)+'/message');
    return tutRef.push({ fromID: data.fromID, message: data.message, timestamp: data.timestamp, toID: data.toID });

  }
  public getuserData() {

  }
  // -- Load Association Load and Basic Location Loads -- //
  public loadAssociationMaster(data) {
    return this.httpClient.post(this._baseURL + "AssociationRegistration/AssociationRegistrationLoad", data, { headers: this.headers });
  }
  // --- Association Master CRUD --- //
  public AssociationMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "AssociationRegistration/AssociationRegistrationCRUD", data, { headers: this.headers });
  }
  // --- Athlete Master CRUD --- //
  public AthleteMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "AtheleteRegistration/AtheleteRegistrationCRUD", data, { headers: this.headers });
  }
  public DeviceSignup(data) {
    return this.httpClient.post(this._baseURL + "Registration/DeviceSignup", data, { headers: this.headers });
  }

  public SignupLoad(data) {
    return this.httpClient.post(this._baseURL + "Registration/SignupLoad", data, { headers: this.headers });
  }
  public AtheleticLoad(data) {
    return this.httpClient.post(this._baseURL + "Registration/AtheleticLoad", data, { headers: this.headers });
  }
  public AtheleteRegistration(data) {
    return this.httpClient.post(this._baseURL + "Registration/AtheleteRegistration", data, { headers: this.headers });
  }
  public LoginValidate(data) {
    return this.httpClient.post(this._baseURL + "Registration/LoginValidate", data, { headers: this.headers });
  }
  public CoachLoad(data) {
    return this.httpClient.post(this._baseURL + "Registration/CoachLoad", data, { headers: this.headers });
  }
  public CoachRegistration(data) {
    return this.httpClient.post(this._baseURL + "Registration/CoachRegistration", data, { headers: this.headers });
  }

  // --- Coach Master CRUD --- //
  public AssoCoachRegLoad(data) {
    return this.httpClient.post(this._baseURL + "CoachRegistration/AssoCoachRegLoad", data, { headers: this.headers });
  }

  // --- Coach Master CRUD --- //
  public CoachMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "CoachRegistration/CoachRegistrationCRUD", data, { headers: this.headers });
  }
  public UploadFile(data) {
    return this.httpClient.post(this._baseURL + "Registration/UploadFile", data, { headers: this.headers });
  }

  // --- Association Dashboard CRUD --- //
  public AssociationDashboardLoad(data) {
    return this.httpClient.post(this._baseURL + "AssociationDashboard/AssociationDashboardLoad", data, { headers: this.headers });
  }

  // --- AgeCategory Master CRUD --- //
  public AgeCategoryMaster(data) {
    return this.httpClient.post(this._baseURL + "Masters/AgeCategoryMaster", data, { headers: this.headers });
  }

  // --- Batch Master CRUD --- //
  public BatchMasterMaster(data) {
    return this.httpClient.post(this._baseURL + "Masters/BatchMaster", data, { headers: this.headers });
  }

  // --- Player Group Master CRUD --- //
  public PlayGroupMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "Masters/PlayGroupMaster", data, { headers: this.headers });
  }

  // --- Support-Staff Mapping --- //
  public SupportStaffMapping(data) {
    return this.httpClient.post(this._baseURL + "Masters/SupportStaffMappingMaster", data, { headers: this.headers });
  }

  // --- Athlete Mapping --- //
  public AthleteMapping(data) {
    return this.httpClient.post(this._baseURL + "Masters/AthleteMappingMaster", data, { headers: this.headers });
  }

  // --- Athelete Dashboard  --- //
  public AthleteDashboard(data) {
    return this.httpClient.post(this._baseURL + "AssociationDashboard/AthleteDashboard", data, { headers: this.headers });
  }

  // --- Coach Dashboard  --- //
  public CoachDashboard(data) {
    return this.httpClient.post(this._baseURL + "AssociationDashboard/CoachDashboard", data, { headers: this.headers });
  }

  // --- Change Password  --- //
  public ChangePassword(data) {
    return this.httpClient.post(this._baseURL + "Registration/ChangePassword", data, { headers: this.headers });
  }

  // --- Athlete Session PageLoad  --- //
  public AthleteSessionPageLoad(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionLoad", data, { headers: this.headers });
  }
  public AthleteSessionCreation(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionCreation", data, { headers: this.headers });
  }
  public CoachSessionCreation(data) {
    return this.httpClient.post(this._baseURL + "SessionsCoach/CoachSessionCreation", data, { headers: this.headers });
  }
  public AthleteSessionsList(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionsList", data, { headers: this.headers });
  }
  public AthleteSessionSharing(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionSharing", data, { headers: this.headers });
  }
  public AthleteSessionChatting(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionChatting", data, { headers: this.headers });
  }
  public SessionChattingCoachLoad(data) {
    return this.httpClient.post(this._baseURL + "CoachSession/SessionChattingCoachLoad", data, { headers: this.headers });
  }
  public CoachSessionList(data) {
    return this.httpClient.post(this._baseURL + "SessionsCoach/CoachSessionsList", data, { headers: this.headers });
  }
  
  public SessionChattingCoachCRUD(data) {
    return this.httpClient.post(this._baseURL + "CoachSession/SessionChattingCoachCRUD", data, { headers: this.headers });
  }
//Coachpad Sessions
  public CoachpadSessionsLoad(data) {
    return this.httpClient.post(this._baseURL + "Coachpad/CoachpadSessionsLoad", data, { headers: this.headers });
  }
  public CoachpadSessionCreation(data) {
    return this.httpClient.post(this._baseURL + "Coachpad/CoachpadSessionCreation", data, { headers: this.headers });
  }
  public CoachpadSessionsList(data) {
    return this.httpClient.post(this._baseURL + "Coachpad/CoachpadSessionsList", data, { headers: this.headers });
  }
  

  // --- My Drills  --- //
  public DrillsLoad(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillsLoad", data, { headers: this.headers });
  }
  public DrillsCRUD(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillsCRUD", data, { headers: this.headers });
  }
  public DrillSharing(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillSharing", data, { headers: this.headers });
  }
  public DrillsAthletes(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillsAthletes", data, { headers: this.headers });
  }

  //Planner 
  public PlannerCalendarLoad(data) {
    return this.httpClient.post(this._baseURL + "Planner/PlannerCalendarLoad", data, { headers: this.headers });
  }
  public PlannerPageLoad(data) {
    return this.httpClient.post(this._baseURL + "Planner/PlannerPageLoad", data, { headers: this.headers });
  }
  public PlannerEventsCRUD(data) {
    return this.httpClient.post(this._baseURL + "Planner/PlannerEventsCRUD", data, { headers: this.headers });
  }

  //Video Annotations
  public VideoAnalyseCRUD(data) {
    return this.httpClient.post(this._baseURL + "VideoAnalyse/VideoAnalyseCRUD", data, { headers: this.headers });
  }
  public VideoAnalyseTags(data) {
    return this.httpClient.post(this._baseURL + "VideoAnalyse/VideoAnalyseTags", data, { headers: this.headers });
  }
  public VideoAnalyseGraphs(data) {
    return this.httpClient.post(this._baseURL + "VideoAnalyse/VideoAnalyseGraphs", data, { headers: this.headers });
  }
  //Video Tagging
  public VideoTaggingLoad(data) {
    return this.httpClient.post(this._baseURL + "Videos/VideoTaggingLoad", data, { headers: this.headers });
  }
  public videoTaggingEngine(data) {
    return this.httpClient.post(this._baseURL + "Videos/VideoTaggingEngine", data, { headers: this.headers });
  }
  public VideoTaggingEngineCRUD(data) {
    return this.httpClient.post(this._baseURL + "Videos/VideoTaggingEngineCRUD", data, { headers: this.headers });
  }

  //Video Compare
  public VideoCompareFilters(data) {
    return this.httpClient.post(this._baseURL + "VideoAnalyse/VideoCompareFilters", data, { headers: this.headers });
  }

  //Video Playlist
  public VideoPlayListCRUD(data) {
    return this.httpClient.post(this._baseURL + "Video/VideoPlayListCRUD", data, { headers: this.headers });
  }
  public VideoPlayListView(data) {
    return this.httpClient.post(this._baseURL + "Video/VideoPlayListView", data, { headers: this.headers });
  }


  // Admin Dashboard
  public AdminDashboard(data) {
    return this.httpClient.post(this._baseURL + "AssociationDashboard/AdminDashboard", data, { headers: this.headers });
  }

  public CoachProfileSettings(data) {
    var methodName = data.method;
    var request = data.request;
    return this.httpClient.post(this._baseURL + methodName, request, { headers: this.headers });
  }

  public Finance(data) {
    var methodName = data.method;
    var request = data.request;
    return this.httpClient.post(this._baseURL + methodName, request, { headers: this.headers });
  }



}


