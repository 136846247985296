<div class="page-container my-videos-page video-analyse-page playlistview-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="row">
                    <div class="col-md-9">
                        <div class="playlist-left box-shadow br-5">
                            <div class="advance-search-container">
                                <div class="row">
                                    <div class="col-md-12 txt-center">
                                        <div class="sub-title">
                                            More Filters
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- <div class="col-md-4">
                                        <mat-radio-group aria-label="Select an option" class="playlist-date">
                                            <mat-radio-button value="1">Month Range</mat-radio-button>
                                            <mat-radio-button value="2">Date Range</mat-radio-button>
                                        </mat-radio-group>
                                    </div> -->
                                    <div class="col-md-4">
                                        <!-- <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block"
                                            appearance="outline" floatLabel="always">
                                            <input matInput [matDatepicker]="dob" placeholder="Date">
                                            <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                                            <mat-datepicker #dob></mat-datepicker>
                                        </mat-form-field> -->

                                        <mat-form-field appearance="outline" class="custom-dtpkr-txt md-block search-txt"
                                        floatLabel="always">
                                        <mat-date-range-input [rangePicker]="range">
                                            <input matStartDate placeholder="Start date"  
                                                [(ngModel)]="StartDate" (change)="alerts('x')">
                                            <input matEndDate placeholder="End date" 
                                                [(ngModel)]="EndDate">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="range"></mat-datepicker-toggle>
                                        <mat-date-range-picker #range></mat-date-range-picker>
                                    </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Skill" multiple [(ngModel)]="SessionType">
                                                <mat-option [value]="ses.SessionTypeID"
                                                    *ngFor="let ses of SessionTypeList"
                                                    style="text-transform:capitalize;">
                                                    {{ses.SessionTypeName}}</mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="View" multiple [(ngModel)]="VideoView">
                                                <mat-option [value]="view.VideoViewID"
                                                    *ngFor="let view of VideoViewList"
                                                    style="text-transform:capitalize;">
                                                    {{view.VideoViewCode}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                            <mat-select placeholder="View" multiple [(ngModel)]="VideoView">
                                                <mat-option [value]="view.VideoViewID"
                                                *ngFor="let view of VideoViewList" style="text-transform:capitalize;">
                                                {{view.VideoViewCode}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Foot Movement" multiple [(ngModel)]="FootMove">
                                                <mat-option [value]="foot.FootMoveID" *ngFor="let foot of FootMoveList"
                                                    style="text-transform:capitalize;">
                                                    {{foot.StatusValue}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Shot" multiple [(ngModel)]="ShotType">
                                                <mat-option [value]="shot.ShotID" *ngFor="let shot of ShotTypeList"
                                                    style="text-transform:capitalize;">
                                                    {{shot.ShotName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Arm Trajectory" multiple
                                                [(ngModel)]="BallReleaseType">
                                                <mat-option [value]="release.BallReleaseID"
                                                    *ngFor="let release of BallReleaseTypeList"
                                                    style="text-transform:capitalize;">
                                                    {{release.ReleaseName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Bowl Type" multiple [(ngModel)]="BowlingType">
                                                <mat-option [value]="btype.VariationID"
                                                    *ngFor="let btype of BowlingTypeList"
                                                    style="text-transform:capitalize;">
                                                    {{btype.VariationName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="WW Region" multiple [(ngModel)]="WWRegion">
                                                <mat-option [value]="region.RegionID"
                                                    *ngFor="let region of WWRegionList"
                                                    style="text-transform:capitalize;">
                                                    {{region.RegionName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Pitchmap Line" multiple [(ngModel)]="PMLine">
                                                <mat-option [value]="line.LineID" *ngFor="let line of PMLineList"
                                                    style="text-transform:capitalize;">
                                                    {{line.LineName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Pitchmap Length" multiple [(ngModel)]="PMLength">
                                                <mat-option [value]="len.LengthID" *ngFor="let len of PMLengthList"
                                                    style="text-transform:capitalize;">
                                                    {{len.LengthName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Impact Line" multiple [(ngModel)]="ImpactLine">
                                                <mat-option [value]="iline.LineID" *ngFor="let iline of PMLineList"
                                                    style="text-transform:capitalize;">
                                                    {{iline.LineName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Impact Height" multiple [(ngModel)]="ImpactHeight">
                                                <mat-option [value]="implen.ImpactID"
                                                    *ngFor="let implen of ImpactHeightList"
                                                    style="text-transform:capitalize;">
                                                    {{implen.ImpactName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528"
                                                    y="1920" fit="" height="100%" width="100%"
                                                    preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path opacity=".3"
                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                    </path>
                                                    <path
                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                    </path>
                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                                                </svg></mat-icon>
                                            <mat-select placeholder="Shot Quality" [(ngModel)]="ShotQuality">
                                                <mat-option [value]="1">Middled</mat-option>
                                                <mat-option [value]="2">Edged</mat-option>
                                                <mat-option [value]="3">Beaten</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col txt-center">
                                        <a href="javascript:void(0);" class="theme-button"
                                            (click)="searchTags()">Submit</a>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:void(0);" class="search-toggle collapsed" [(ngModel)]="SearchFilter"
                                title="Click to Advance Search"></a>
                            <div class="title">Search Filters</div>
                            <div class="common-body">
                                <div class="playlist-tbl">
                                    <div class="customscroll-table">
                                        <div>
                                            <ngx-datatable class="material"
                                                [rows]="PlayListVideoTagList|filter:SearchFilter" [columnMode]="'force'"
                                                [headerHeight]="42" [footerHeight]="50" [rowHeight]="42"
                                                [scrollbarV]="false" 
                                                [scrollbarH]="true" [selected]="selected"
                                                [selectionType]="'checkbox'"
                                                [selectAllRowsOnPage]="false"
                                                (select)='onAddclick($event)'>
                                                <ngx-datatable-column [width]="30" [sortable]="false"
                                                    [canAutoResize]="false" [draggable]="false" [resizeable]="false" [headerCheckboxable]= "true"
                                                    [checkboxable]="true"  > 
                                                    <ng-template ngx-datatable-cell-template let-row="row"
                                                        let-value="value" let-isSelected="isSelected"
                                                        let-onCheckboxChangeFn="onCheckboxChangeFn">
                                                        <!-- <input type="checkbox"  (click)="onAddclick(row,$event)"
                                                           /> -->
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="S No" [width]="50">
                                                    <ng-template let-rowIndex="rowIndex" let-row="row"
                                                        ngx-datatable-cell-template>
                                                        <span>{{rowIndex+1}}</span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Skill" prop="SessionTypeName" [width]="200">
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Region" prop="RegionName" [width]="100">
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Line" prop="LineName" [width]="100">
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Length" prop="LengthName" [width]="100">
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Actions" [width]="50">
                                                    <ng-template let-row="row" let-value="value"
                                                        ngx-datatable-cell-template>
                                                        <span>
                                                            <div class="action-btn">
                                                                <div class="ico-action-svg edit" title="Edit">
                                                                    <div (click)="openVideo(row,videoPlayer)"
                                                                        class="ico-action-svg edit" title="Edit"
                                                                        class="tbl-bdy-icon" data-toggle="modal"
                                                                        data-target="#plpopupvideos"><i
                                                                            class="fa fa-play-circle-o"
                                                                            aria-hidden="true"></i></div>

                                                                </div>
                                                                <div class="ico-action-svg delete" title="Delete">
                                                                    <div class="ico-action-svg delete" title="Delete">
                                                                        <svg height="18" viewBox="-40 0 427 427.00131"
                                                                            width="18"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                                            <path
                                                                                d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                                            <path
                                                                                d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                                                                            <path
                                                                                d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                                        </svg></div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="palylist-video">
                                            <div id="myElement"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="playlist-btn-container">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <mat-form-field class="md-block search-txt" appearance="outline"
                                                floatLabel="always">
                                                <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768"
                                                        y="0" fit="" height="100%" width="100%"
                                                        preserveAspectRatio="xMidYMid meet" focusable="false">
                                                        <path opacity=".3"
                                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                        </path>
                                                        <path
                                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                        </path>
                                                    </svg></mat-icon>
                                                <input matInput placeholder="Playlist Name" [(ngModel)]="playlistName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-7">
                                            <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-chip-list #chipList aria-label="Fruit selection">
                                                    <mat-chip *ngFor="let tags of PlaylistTags" [selectable]="selectable"
                                                        [removable]="removable" (removed)="remove(tags)">
                                                        {{tags}}
                                                        <mat-icon matChipRemove *ngIf="removable"></mat-icon>
                                                    </mat-chip>
                                                    <input placeholder="Enter Tag..." [matChipInputFor]="chipList"
                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                        [matChipInputAddOnBlur]="addOnBlur"
                                                        (matChipInputTokenEnd)="add($event)">
                                                </mat-chip-list>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="txt-center">
                                                <button mat-raised-button color="primary" class="mt25" (click)="SavePlaylilst()">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="playlist-right box-shadow br-5">
                            <div class="title">My Playlist</div>
                            <div class="common-body">
                                <div class="search-container">
                                    <mat-form-field class="md-block search-txt" appearance="outline"
                                        floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon">
                                            <a href="javascript:void(0);">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.12 373.12"
                                                    width="24" height="24">
                                                    <path
                                                        d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                </svg>
                                            </a>
                                        </mat-icon>
                                        <input matInput placeholder="Search Playlist" [(ngModel)]="SearchFilter" name="n_Search">
                                    </mat-form-field>
                                </div>
                                <!-- <div class="analyse-video-container">
                                    <div class="analyse-list mb15" *ngFor="let playlist of PlayLists" >
                                        <div class="analyse-ttile">{{playlist.PlayListName}}</div>
                                        <a href="javascript:void(0)" (click)="OpenPlaylistView(playlist)">
                                            <div class="analyse-description">
                                            </div>
                                            <div class="analyse-play-icon">
                                                <img src="assets/images/play.png">
                                            </div>
                                        </a>
                                    </div>
                                    
                                </div> -->
                                <div class="analyse-video-container">
                                    <div class="analyse-list mb15"  (click)="changePlaylist(videoPlayer,play,ind)" 
                                    [ngClass]="(play.PlayListID==PlaylistID)?'selected':''" *ngFor="let play of PlayLists|filter:SearchFilter;let ind=index">
                                        <div class="analyse-ttile">{{play.PlayListName}}</div>
                                        <a href="javascript:void(0)" (click)="OpenPlaylistView(play)">
                                            <div class="playlistview-thumbnail">
                                                <img src="{{play.ThumbnailURL}}">
                                            </div>
                                            <div class="analyse-description">
                                                
                                               <div class="batting-style">{{play.Tags}}</div>
                                                <!-- <div class="bowlings-style">{{play.PMLineLength}}</div> -->
                                            </div>
                                            
                                            <div class="analyse-play-icon">
                                                <img src="assets/images/play.png">
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Preview Modal -->
<div class="modal fade" id="plpopupvideos" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"> Playlist Video</h5>
                <button type="button" (click)="closeVideo(videoPlayer)" class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="help-video">
                    <video width="100%" (timeupdate)="setCurrentTime(videoPlayer)" #videoPlayer controls
                        disablePictureInPicture controlsList="nodownload" src="{{VideoURL}}">
                    </video>
                </div>
            </div>
        </div>
    </div>
</div>