import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { MastersServiceService } from '../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';
@Component({
  selector: 'app-association-list',
  templateUrl: './association-list.component.html',
  styleUrls: ['./association-list.component.css']
})
export class AssociationListComponent implements OnInit {
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }

  @Input() today = new Date();
  @Output() EditAssociationID = new EventEmitter<any>();

  public UserCode: string = "1";
  public ButtonType: string;

  public isShowLoader: boolean;
  public isSuccessAlert:boolean = false; public successAlert:string;
  public isErrorAlert:boolean = false; public errorAlert:string;
  public isWarningAlert:boolean = false; public warningAlert:string;
  public isConfirmAlert:boolean = false; public confirmAlert:string;

  public lstAssociations: any = [];
  public lstCountries: any = [];
  public Country: string;
  public editAssociationData: any = [];
  public ColumnMode: ColumnMode;
  public rowLimit: number = 10;
  public lstAssociationsFiltered: any = [];
  public SearchFilter:string;
  public LIMITS = [
    { value: 10 },
    { value: 20 },
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];
  ngOnInit(): void {
    this.UserCode=String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    this.InitGridLoad();
  }

  InitGridLoad() {
    this.isShowLoader = true;
    pageStatus = "GridLoad";
    var AssoLoad = { AccosiationID: "", ActionFlag: "GridLoad", ModuleName: "AssoRegistration", UserID: this.UserCode };
    this.service.AssociationMasterCRUD(AssoLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstAssociations = ret.AssociationsList;
        this.lstAssociationsFiltered = ret.AssociationsList;
        this.lstCountries = ret.CountryByRegion;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Grid Load Failed..!! \n';
        return;
      }
    })
  }


  DeleteAssociation(asso) {
    this.isWarningAlert =false;
    this.isShowLoader = true;
    let deleteAssoID = String(asso.AssociationID);
    var AssoLoad = {
      AccosiationID: String(deleteAssoID), RefUserID: String(asso.RefUserID), ActionFlag: "DeActivate",
      ModuleName: "AssoRegistration", UserID: String(asso.RefUserID),
    };
    this.service.AssociationMasterCRUD(AssoLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.isSuccessAlert = true;
        this.successAlert = '\n "' + asso.AssociationName + '"\n DeActivated successfully \n';
        this.isShowLoader = false;
        this.InitGridLoad();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n "' + asso.AssociationName + '"\n DeActivation failed \n';
        return;
      }
    })
    pageStatus = "GridLoad";
    this.rowValue=[];
  }

  RecordStatusChange(status) {
    this.isConfirmAlert = false;
    this.isShowLoader = true;
    let AssoID = String(status.AssociationID);
    let AssoStatus = String(status.RecordStatus);
    let flag: string;

    if (AssoStatus == "InActive") { flag = "Activate" }
    else { flag = "DeActivate" }

    if (AssoStatus == "InActive") {
      var AssoLoad = {
        AccosiationID: String(AssoID), RefUserID: String(status.RefUserID), ActionFlag: "Activate",
        ModuleName: "AssoRegistration", UserID: String(status.RefUserID),
      };
      this.service.AssociationMasterCRUD(AssoLoad).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          if (flag == "Activate") this.successAlert = '\n"' + status.AssociationName + '"\n Activated successfully..!!';
          else if (flag == "DeActivate") this.successAlert = '\n"' + status.AssociationName + '"\n DeActivated successfully..!!';
          this.isShowLoader = false;
          this.InitGridLoad();
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          if (flag == "Activate") this.successAlert = '\n"' + status.AssociationName + '"\n Activation failed..!!';
          else if (flag == "DeActivate") this.successAlert = '\n"' + status.AssociationName + '"\n DeActivation failed..!!';
          return;
          // this.utility.openSnackBar('Load Failed..!!' );
        }
      })
    }
    else {
      this.isShowLoader = false;
      this.isWarningAlert = true;
      this.warningAlert = '\n"' + status.AssociationName + '"\n Already in active status.!!';
    }
    pageStatus = "GridLoad";
  }



  EditAssociation(asso) {
    let Status: string;
    Status = String(asso.RecordStatus);
    if (Status != "InActive") {
      pageStatus = "GridLoad";
      this.isShowLoader = true;
      
      this.editAssociationData = asso;
      pageStatus = "Edit";
      sessionStorage.setItem("AssociationPageStatus", "Edit");
      sessionStorage.setItem("AssociationID", String(asso.AssociationID));
      selectedAssociationList = asso;
      this.EditAssociationID.emit(this.editAssociationData);
      this.router.navigate(['/association']);
      this.isShowLoader = false;
    }
    else {
      this.isShowLoader = false;
      this.isWarningAlert = true;
      this.warningAlert = '\n"' + asso.AssociationName + '"\n Could not be Edit,in DeActive status..!!';
      return;
    }
  }


  // (click)="DeleteAssociation(row)"  // (click)="RecordStatusChange(row)"

  public rowValue:any[];
  public rowValueActive:any[];

  showWarningDialogue(row){
      if ( String(row.RecordStatus) == "Active" ){
        this.rowValue=row;
        this.isWarningAlert = true;
        this.warningAlert = "Are you sure want to Delete..?"
      }
      else if ( String(row.RecordStatus) == "InActive" ){
        this.rowValueActive=row;
        this.isConfirmAlert = true;
        this.confirmAlert = "Are you sure want to Activate..?"
      }
      else{
        this.isWarningAlert = false;
        return;
      }
  }


  filter(country) {
    if (country == "" || country == undefined) {
      this.lstAssociationsFiltered = this.lstAssociations;
    } else {
      this.lstAssociationsFiltered = this.lstAssociations.filter((con) => con.CountryID === country);
    }
  }

// filters results
filterDatatable(event){
  // get the value of the key pressed and make it lowercase
  let val = event.target.value.toLowerCase();
  // get the amount of columns in the table
  let colsAmt = this.lstAssociations[0].length;
  // get the key names of each column in the dataset
  let keys = Object.keys(this.lstAssociationsFiltered[0]);
  // assign filtered matches to the active datatable
  this.lstAssociationsFiltered = this.lstAssociations.filter(function(item){
    // iterate through each row's column data
    for (let i=0; i<colsAmt; i++){
      // check for a match
      if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
        // found match, return true to add to result set
        return true;
      }
    }
  });
  // whenever the filter changes, always go back to the first page
  this.table.offset = 0;
}



}


export var selectedAssociationList: any = [];
export var pageStatus: string = "";