<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>
 
<div class="page-container academy-dashboard-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-3">
                    <div class="academy-hdr-widget one">
                        <div class="dbico-holder"><i class="fa fa-graduation-cap" aria-hidden="true"></i></div>
                        <div class="academy-body">
                            <h2>{{TotalAcademies}}</h2>
                            <p>Total Academies</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="academy-hdr-widget two">
                        <div class="dbico-holder"><i class="fa fa-star-half-o" aria-hidden="true"></i></div>
                        <div class="academy-body">
                            <h2>{{TotalAcademies}}</h2>
                            <p>Active Academies</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="academy-hdr-widget three">
                        <div class="dbico-holder"><i class="fa fa-user" aria-hidden="true"></i></div>
                        <div class="academy-body">
                            <h2>{{TotalRegAthletes}}</h2>
                            <p>Registered Players</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="academy-hdr-widget four">
                        <div class="dbico-holder"><i class="fa fa-user-plus" aria-hidden="true"></i></div>
                        <div class="academy-body">
                            <h2>{{ActiveAthlete}}</h2>
                            <p>Active Players</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card-container box-shadow" style="height: 730px;">
                                <!-- <div class="card-hdr">Active Academies</div> -->
                                <div class="card-hdr">Active Academies
                                    <a href="javascript:void(0);" class="reload" title="Refresh"
                                        (click)="AcademicRefresh()">
                                        <img src="assets/images/reload.png">
                                    </a>
                                </div>
                                <div class="card-body">
                                    <div class="academy-tbl">
                                        <div class="customscroll-table">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <td class="col150">Academies</td>
                                                        <td class="col90">Active Players</td>
                                                        <td class="col100">Uploaded Videos</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let asso of lstAssociation">
                                                        <td class="col150" (click)="GetDashBoard(asso.AssociationID)">
                                                            {{asso.AssociationName}}
                                                        </td>
                                                        <!-- (click) = "GetDashBoard(asso.AssociationID)" -->
                                                        <td class="col90 txt-center">
                                                            <div class="academy-count">{{asso.ActiveAthlete}}</div>
                                                            <div class="academy-track">
                                                                <span class="academy-value"
                                                                    style="width:20%; background-color: blue;"></span>
                                                            </div>
                                                        </td>
                                                        <td class="col100 txt-center">
                                                            <div class="academy-count">{{asso.UploadVideos}}</div>
                                                            <div class="academy-track">
                                                                <span class="academy-value"
                                                                    style="width:20%; background-color: blue;"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="card-container box-shadow">
                                <div class="card-hdr">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="cart-title">Registered Players Per Month</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="chart-container">
                                        <div class="chartjs-container">
                                            <!-- <canvas baseChart 
                                            [datasets]="barChartData"
                                            [labels]="barChartLabels"
                                            [options]="barChartOptions"
                                            [plugins]="barChartPlugins"
                                            [legend]="barChartLegend"
                                            [chartType]="barChartType"  >
                                        </canvas> -->
                                            <canvas baseChart [plugins]="barChartPlugins" [datasets]="chartData"
                                                [labels]="labels" [options]="barChartOptions" [legend]="true"
                                                [chartType]="'bar'">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- <div class="col-md-3">
                                    <div class="card-container box-shadow">
                                        <div class="card-hdr">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="cart-title">Players Split by Gender</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body custom">
                                            <div class="chart-container">
                                                <div class="progress">
                                                    <div *ngFor="let bar of listGender"
                                                        [ngStyle]="{ 'width' : bar.GenderPercentage, 'background-color' : bar.GenderColors }"
                                                        style="margin: 1px;border-radius: 5px;" [style.background-color]
                                                        [attr.title]="bar.GenderToolTip">
                                                    </div>
                                                </div>
                                               
                                                <div class="progress-legend">
                                                    <div class="legend-item blue ico-male">Male</div>
                                                    <div class="legend-item lightblue ico-female">Female</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-3">
                                    <div class="card-container box-shadow">
                                        <div class="card-hdr">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="cart-title">Registered Coaches</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart-container txt-center">
                                                <div class="chartjs-container db-allcoaches">
                                                    <p>{{ActiveCoaches}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-6">
                                    <div class="card-container box-shadow">
                                        <div class="card-hdr">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="cart-title">Players split by Role</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart-container">
                                               
                                                <div class="progress">
                                                    <div *ngFor="let bar of listPlayerRole"
                                                        [ngStyle]="{ 'width' : bar.RolePercentage, 'background-color' : bar.RoleColors }"
                                                        style="margin: 1px;border-radius: 5px;" [style.background-color]
                                                        [attr.title]="bar.RoleToolTip">
                                                    </div>
                                                </div>

                                                <div class="progress-legend">
                                                    <div class="legend-item" *ngFor="let bars of listPlayerRole">
                                                        <span
                                                            [ngStyle]="{ 'background-color': bars.RoleColors }"></span>
                                                        {{bars.RoleToolTip}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-12">
                                    <div class="card-container box-shadow">
                                        <div class="card-hdr">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="cart-title">Uploaded videos by month</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart-container">
                                                <div class="chartjs-container">
                                                    <!-- <canvas baseChart 
                                                        [datasets]="barChartData1"
                                                        [labels]="barChartLabels1"
                                                        [options]="barChartOptions1"
                                                        [plugins]="barChartPlugins1"
                                                        [legend]="barChartLegend1"
                                                        [chartType]="barChartType1"   
                                                        [colors]="barChartColors1"  >
                                                </canvas> -->
                                                    <canvas baseChart [datasets]="UploadchartData"
                                                        [labels]="Uploadlabels" [options]="barChartOptions"
                                                        [legend]="true" [chartType]="'bar'">
                                                    </canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Alerts -->
<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Dashboard</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false;">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Dashboard</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Dashboard</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isWarningAlert=false">Ok</button>
        </div>
    </div>
</div>