<div class="loadercontainer" *ngIf="isShowLoader">
  <div class="loader-container">
      <img src="assets/images/cricket-ball.gif" />
  </div>
</div>

<div class="page-container admin-dashboard-page" *ngIf="MemberTypeID=='5'">
  <div class="pagination-container">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                    <h4>{{AssociationName}}</h4>
                    <p class="placeofacademy">{{AssociationCity}}</p>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="page-wrapper">
    <div class="container">
      <div class="output-container">
          <div class="dashboard-container">
              <div class="dashboard-header box-shadow">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="academy-logo"> 
                          <img src="{{AssociationLogo}}" class="img-fluid" onerror="this.src='assets/images/no_user.png';">
                       </div>
                    </div>

                    <div class="col-md-6 txt-right">
                      <div class="monthly-count txt-center">
                        <p>{{AthleteCount}}</p>
                        <span>Total Athlete</span>
                      </div>
                    </div>

                    <!-- <div class="col-md-6 txt-right">
                        <div class="monthly-count txt-center">
                          <p>{{CoachCount}}</p>
                          <span>Total Coach </span>
                        </div>
                    </div> -->

                  </div>
              </div>
              <div class="dashboard-body">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="card-container box-shadow">
                        <div class="card-hdr">
                            <div class="row">
                              <div class="col-md-6"> 
                                  <div class="cart-title">Monthly Athlete Status</div>
                              </div>  
                              <!-- <div class="col-md-6">
                                  <div class="cart-view txt-right">  
                                    <select class="custom-select" [(ngModel)]="AssociationRole" style="text-transform:capitalize;" 
                                       (change)= "CategorySelectionChange(AssociationRole)" >
                                      <option [value] ="category.CategoryID"  *ngFor="let category of AthleteCategoryMonthWise" >
                                         {{category.CategoryName}}</option>
                                    </select>
                                    <div class="chart-more-view">
                                      <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <svg class="svg-inline--fa fa-ellipsis-h fa-w-16 fs--2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-h" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"></path></svg>
                                        </button>
                                      </div>
                                    </div>
                                </div>
                              </div> -->
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="chart-container">
                              <div class="chartjs-container" style="height: 210px; width: 100%;">
                                <canvas baseChart 
                                    [datasets]="lineChartData"
                                    [labels]="lineChartLabels"
                                    [colors]="lineChartColors"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType"
                                    [plugins]="lineChartPlugins">
                                </canvas>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                        <div class="card-container box-shadow">
                            <div class="academy-coach-container">
                              <div class="card-hdr"><div class="card-title">Association Coaches</div></div>
                              <div class="card-body" >
                                    <ng-image-slider [images]="imageObject" #nav [imagePopup]="sliderImagePopup" ></ng-image-slider>
                              </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <div class="card-container box-shadow">
                          <div class="academy-coach-container">
                            <div class="card-hdr"><div class="card-title">Player Groups</div></div>
                            <div class="card-body">

                              <div class="progress"  >
                                   <div  *ngFor = "let bar of listCategoryPercentage" 
                                        [ngStyle]="{ 'width' : bar.Percentage, 'background-color' : bar.Colors }" 
                                        style="margin: 1px;border-radius: 5px;"
                                        [style.background-color]  [attr.title]="bar.ToolTip" >
                                    </div>
                              </div>
                              <div class="progress-legend">
                                <div class="legend-item" *ngFor = "let bars of listCategoryPercentage">
                                   <span  [ngStyle]="{ 'background-color': bars.Colors }" ></span> {{bars.ToolTip}} </div>
                              </div>  

                            </div>
                          </div>
                      </div> 
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="card-container box-shadow">
                        <div class="card-hdr">
                            <div class="row">
                              <div class="col-md-6">
                                  <div class="cart-title">Athlete Focus</div>
                              </div>
                              <div class="col-md-6">
                                <div class="cart-view txt-right">
                                  <div class="chart-more-view">
                                    <div class="dropdown">
                                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg class="svg-inline--fa fa-ellipsis-h fa-w-16 fs--2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-h" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"></path></svg>
                                      </button>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="chart-container">
                              <div class="chartjs-container" style="height: 328px; width: 100%;">
                                <canvas baseChart 
                                    [data]="demodoughnutChartData" 
                                    [labels]="demodoughnutChartLabels" 
                                    [chartType]="demodoughnutChartType"
                                    [colors] = "doughnutChartColors" >
                                </canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="card-container box-shadow">
                        <div class="card-hdr">
                            <div class="row">
                              <div class="col-md-6">
                                  <div class="cart-title">Session Uploads per Month</div>
                              </div>

                              <div class="col-md-6">  
                                <div class="cart-view txt-right">
                                  <div class="chart-more-view">
                                    <div class="dropdown">
                                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg class="svg-inline--fa fa-ellipsis-h fa-w-16 fs--2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-h" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"></path></svg>
                                      </button>
                                      <!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="javascript:void(0);">Refresh</a>
                                        <a class="dropdown-item" href="javascript:void(0);">Export PDF</a>
                                      </div> -->
                                    </div>
                                  </div>
                              </div>
                            </div>

                            <div class="col-md-6" *ngIf="IsAthleteFocusShow" >  
                                 <div class="row">
                                   <div class="col-md-12">
                                    <div class="white box-shadow br-5" style="min-height: 200px; margin-top: 20px;">
                                      <div class="dummy-contianer txt-center" style="padding: 20% 20%;">
                                        <p style="font-size: 48px; font-weight: 600;">No Data..</p>
                                        <p>Comes here..</p>
                                      </div>
                                     </div>
                                   </div>
                                 </div>
                          </div>

                            
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="chart-container">
                              <div class="chartjs-container" style="height: 328px; width: 100%;">
                                <canvas baseChart 
                                    [datasets]="barChartData"
                                    [labels]="barChartLabels"
                                    [options]="barChartOptions"
                                    [plugins]="barChartPlugins"
                                    [legend]="barChartLegend"
                                    [chartType]="barChartType"
                                    [colors]="barChartColors">
                                </canvas>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
  </div>
</div>

<div class="page-container admin-dashboard-page" *ngIf="MemberTypeID=='1'">
   <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="white box-shadow br-5" style="min-height: 600px; margin-top: 20px;">
            <div class="dummy-contianer txt-center" style="padding: 20% 20%;">
              <p style="font-size: 48px; font-weight: 600;">Player Dashboard</p>
            <p>Comes here</p>
            </div>
           </div>
        </div>
      </div>
   </div>
</div>



<div class="overlay success"  *ngIf = "isSuccessAlert" >
  <div class="success-widget">
      <div class="success-header"><i class="fa fa-check"></i>Success</div>
      <div class="success-container">
          <p  style="text-transform:capitalize" >{{successAlert}}</p>
      </div>
      <div class="button-container">
          <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="isSuccessAlert=false" >Ok</button>
      </div>
  </div>
</div> 
<div class="overlay error" *ngIf = "isErrorAlert" >
  <div class="error-widget">
     <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
     <div class="error-container">
          <p style="text-transform:capitalize;" >{{errorAlert}}</p>   
         <p>If Confirm Press OK Button</p> 
     </div>
     <div class="button-container">
          <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="isErrorAlert=false" >Ok</button>
          <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"  (click)="isErrorAlert=false" >Cancel</button>
      </div>
 </div>
</div> 
<div class="overlay warning" *ngIf = "isWarningAlert" >
  <div class="warning-widget">
     <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
     <div class="warning-container">
         <p style="text-transform:capitalize;" >{{warningAlert}}</p>          
         <p>If Confirm Press OK Button</p> 
     </div>
     <div class="button-container">
          <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="isWarningAlert=false" >Ok</button>
          <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"  (click)="isWarningAlert=false" >Cancel</button>
      </div>
 </div>
</div>