import { Component, OnInit } from '@angular/core';
import{UtilitiesService}from '../../utilities.service';

@Component({
  selector: 'app-physio',
  templateUrl: './physio.component.html',
  styleUrls: ['./physio.component.css']
})
export class PhysioComponent implements OnInit {

  constructor(private utility:UtilitiesService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
  }

}
