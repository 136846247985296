import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../../masters-service.service';
import { DatePipe } from '@angular/common';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as $ from 'jquery';
import { StringifyOptions } from 'querystring';

export interface Materials { name: string; }
export interface Keywords { name: string; }
export interface StepsValues { name: string; }

@Component({
  selector: 'app-drills',
  templateUrl: './drills.component.html',
  styleUrls: ['./drills.component.css']
})
export class DrillsComponent implements OnInit {
  DrillForm: FormGroup;
  myForm: FormGroup;

  constructor(private utility: UtilitiesService, private http: HttpClient, private blob: BlobService, private service: MastersServiceService
    , public datepipe: DatePipe, private formBuilder: FormBuilder, private _host: ElementRef,) {
    this.currentFile = null
    this.config = null
    this.percent = 0
  }

  public UserCode: string = "1"; public MemberTypeID: string = "2";

  public isShowLoader: boolean = false;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;

  public ButtonType: string = "Save";

  public showMonthRange: boolean = false; public showDateRange: boolean = true;
  public advFilters: boolean = false; public selectedfilesize: string;
  public durations: number; public Videoduration: string;
  public currentFile: File; public selectedFile: string;
  public videoLoaded: boolean = false; public videoSubscription: boolean = true;

  config: UploadConfig; percent: number;
  uploadedsize: string; isUploading: boolean = false;

  public lstDrillSessionTypes: any[];
  public lstDrillLevel: any = []; public lstPlayGroup: any[];
  public lstDrillFullView: any[];

  public DrillName: string; public Skills: string; public Levels: string;
  public SearchLevel: String; public SearchFilter: string; public IsPublic: boolean = false;
  public IsMyVideoShow: boolean = true;

  public sessionDate: Date;
  public videoURL: string;
  public thumbnailURL: string;
  public selectedCoach: string = "";
  public openedShareid: string;
  public filterStartDate: string;
  public filterEndDate: string;
  public FilterSessionTypeID: string;
  public FilterViewID: string;
  public videowidth: number;

  myControl = new FormControl();
  options: User[] = [
    { name: '2020-2021' },
    { name: '2019-2020' },
    { name: '2018-2019' }
  ];
  filteredOptions!: Observable<User[]>;

  public commentsFeed: boolean = false;
  public stepcount: number = 0;
  public SteperArr: any[] = [{ stepValue: '', }];

  public SaveMaterials: string; public matArrList: any = [];
  public SaveKeywords: string; public keyArrList: any = [];
  public SaveStepValues: string; public stepArrList: any = [];

  public PreviewDrillName: string; public DrillType: string;
  public ThumbNail: string; public VideoURL: string;
  public CreatedTime: string; public CreatedDate: string; public CreatedBy: string;

  public lstDrillEdit: any[]; public lstDrillEditStep: any[]; public lstMembers: any[];
  public MarialsBind: any = [];
  public lstMyDrills: any = [];

  public lstBattingView: any = []; public lstBowlingView: any = []; public lstFieldingView: any = [];
  public lstWicketKeepingView: any = []; public lstFitnessView: any = []; public lstOthersView: any = [];
  public lstMyDrillsView: any = [];

  public IsEmptyDrill: boolean = false;
  public IsShareIconShow: boolean = false;
  public coachpaddetails: any = [];
  isfromcoachpad: boolean = false;
  @ViewChild('stepVar') element: ElementRef;

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.MemberTypeID = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID);
    this.DrillForm = this.formBuilder.group({
      n_DrillName: ['', Validators.required],
      n_Skills: ['', Validators.required],
      n_Level: ['', Validators.required],
      n_Keywords: ['', [Validators.required]],
      n_Materials: ['', [Validators.required]],
      n_StepsValue: [],
      // n_Steps: ['', [Validators.required]],
      n_IsPublic: [],
      // n_Beginer: ['', [Validators.required]],
      // n_Intermediate: ['', [Validators.required]],
      // n_Advanced: ['', [Validators.required]],
    });
    this.DrillsInitLoad();
    if (this.service.lastPage == "coachpad") {
      this.coachpaddetails = this.utility.coachDetails;
      this.isfromcoachpad = true;
    }
  }
  ngOnDestroy() {
    this.service.lastPage = 'drills';
  }

  Clear() {
    this.DrillName = ""; this.IsPublic = false;
    this.materials = []; this.SaveMaterials = ""; //this.materials.push({ name: "" });
    this.keywords = []; this.SaveKeywords = ""; //this.keywords.push({ name: "" });
    this.listStepperTempList = []; this.StepsValue = "";
    this.percent = 0;
  }

  DrillsInitLoad() {
    this.isShowLoader = true;
    this.ButtonType = "Save";
    if (this.MemberTypeID == '2') {
      this.IsShareIconShow = true;
    }
    else {
      this.IsShareIconShow = false;
    }
    this.service.DrillsLoad({ ActionFlag: "Load", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.videoLoaded = false;
        this.lstDrillSessionTypes = ret.DrillSessionTypesList;
        this.lstDrillLevel = ret.DrillLevelList;
        this.lstPlayGroup = ret.PlayGroupList;
        this.DrillViewDetails();
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  DrillViewDetails() {
    this.isShowLoader = true;
    this.service.DrillsLoad({ ActionFlag: "FullView", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.videoLoaded = false;
        this.lstDrillFullView = ret.DrillFullViewList;

        if (this.lstDrillFullView.length > 0) {
          this.lstBattingView = this.lstDrillFullView.filter(s => s.DrillTypeID === 1);
          this.lstBowlingView = this.lstDrillFullView.filter(s => s.DrillTypeID === 2);
          this.lstFieldingView = this.lstDrillFullView.filter(s => s.DrillTypeID === 3);
          this.lstWicketKeepingView = this.lstDrillFullView.filter(s => s.DrillTypeID === 4);
          this.lstFitnessView = this.lstDrillFullView.filter(s => s.DrillTypeID === 5);
          this.lstOthersView = this.lstDrillFullView.filter(s => s.DrillTypeID === 6);

          if (this.lstBattingView.length > 0) {
            this.lstBattingView = this.lstBattingView;
            this.IsEmptyDrill = false;
          }
          else {
            this.IsEmptyDrill = true;
          }
          this.isShowLoader = false;
        }
        else {
          this.IsEmptyDrill = true;
          this.isShowLoader = false;
        }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  // addSteps(event) {
  //   this.SteperArr.push({
  //     stepValue: '',
  //   });
  //   this.stepcount = this.SteperArr.length - 1;
  // }
  // removeSteps(i: number) {
  //   this.SteperArr.splice(i, 1);
  // }

  stepsArrays: StepsValues[] = [];
  public stepArrayValue: any = [];

  addSteps(event): void {
    const value = event;
    if ((value || '').trim()) {
      this.SteperArr.push({ stepValue: value.trim() });
      this.stepcount = this.SteperArr.length - 1;
    }
    // this.SteperArr.push({
    //   stepValue: '',
    // }); 
    // this.stepcount = this.SteperArr.length - 1;
  }

  onBlurEvent(event, i) {
    // alert(<HTMLElement>document.getElementById(event));
    // this.stepArrayValue.push(this.element.nativeElement.value); 
    var canvas: any = <HTMLElement>document.getElementById(event);
    this.SteperArr[i].stepValue = canvas.value;
  }

  removeSteps(i: number) {
    this.SteperArr.splice(i, 1);
  }

  // -- Input Chips KeyWords & Matarials -- //
  visible = true; selectable = true;
  removable = true; addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  materials: Materials[] = [];

  keyvisible = true; keyselectable = true;
  keyremovable = true; keyaddOnBlur = true;
  readonly separatorKeys: number[] = [ENTER, COMMA];
  keywords: Keywords[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.materials.push({ name: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(mat: Materials): void {
    const index = this.materials.indexOf(mat);
    if (index >= 0) {
      this.materials.splice(index, 1);
    }
  }

  addKeywords(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.keywords.push({ name: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeKeywords(key: Keywords): void {
    const index = this.keywords.indexOf(key);
    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  // -- Input Chips KeyWords & Matarials -- //  


  //classFlag01:string = ""; classFlag02:string = ""; classFlag03:string = "";
  classFlag: boolean = false;
  public seldrillLevel: any = []; public selsesViews: any = [];
  menuToggle(event: any, val, type) {
    this.seldrillLevel = [];
    if (type == 'type') {
      event.target.classList.toggle('active');
      if (event.target.classList.value == 'active') {
        this.seldrillLevel.push(val);
      }
      else {
        var x = this.seldrillLevel.indexOf(val);
        this.seldrillLevel.splice(x, 1);
      }
    }
  }

  // menuToggle(event: any, val, type) {
  //   event.target.classList.toggle('active');
  //   if (type == 'type') {
  //     if (event.target.classList.value == 'active') {
  //       this.selsesTypes.push(val);
  //     }
  //     else {
  //       var x = this.selsesTypes.indexOf(val);
  //       this.selsesTypes.splice(x, 1);
  //     }
  //   }
  //   else if (type == 'view') {
  //     if (event.target.classList.value == 'active' || event.target.classList.value == 'select active') {
  //       this.selsesViews.push(val);
  //     }
  //     else {
  //       var x = this.selsesViews.indexOf(val);
  //       this.selsesViews.splice(x, 1);
  //     }
  //   }
  //   // this.listAddAthleteGroup.splice(x,1); 
  // }

  today = new Date();

  // -- Video Upload -- //
  onVideoChange(event) {
    var attachedFile = <File>event.target.files[0];
    this.currentFile = attachedFile;
    if (this.currentFile.type == "video/mp4") {
      let duration: any;
      this.videoLoaded = true;
      this.selectedFile = URL.createObjectURL(attachedFile);
      document.getElementById("myVideo").setAttribute("src", URL.createObjectURL(attachedFile));
      this.selectedfilesize = (attachedFile.size / (1024 * 1024)).toFixed(2) + 'MB';
    }
    else {
      this.currentFile = null;
      this.isErrorAlert = true;
      this.errorAlert = "File format not support..!!";
    }
  }

  onMetadata(e, video) {
    this.videowidth = 0;
    this.durations = video.duration;
    this.Videoduration = Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2) + ' Mins';
    this.videowidth = video.width;

    var dura = (Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2));
    if (this.durations < 480) {
      this.videoLoaded = true;
    }
    else {
      this.Videoduration = "";
      this.selectedFile = "";
      this.videoLoaded = false;
      this.isWarningAlert = true;
      this.warningAlert = "Video duration should 08:00 Min (or) Below..";
    }

  }

  pad(n, width) {
    var z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  generateBlobUrl(
    params: UploadParams,
    filename: string,
    useAzureStorageEmulator = false,
    azureStorageEmulatorBaseUrl = '') {
    const url = useAzureStorageEmulator ? azureStorageEmulatorBaseUrl : `https://${params.storageAccount}.blob.core.windows.net`
    return `${url}/${params.containerName}/${filename}`
  }

  private prependZeros(number, length) {
    let str = '' + number
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }

  uploadbutton() {
    //if (this.StepsValue != undefined && this.StepsValue != "" ){ listStepperTempList
    if (this.listStepperTempList.length > 0) {
      var canvas = <HTMLCanvasElement>document.getElementById('canvas');
      var video = <HTMLCanvasElement>document.getElementById('myVideo');
      // var img =  <HTMLCanvasElement> document.getElementById('imgId');
      var context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      // var dataURL = canvas.toDataURL();
      // img.setAttribute('src', dataURL);
      // alert(canvas.toDataURL());
      if (this.DrillForm.invalid) {
        return;
      }
      else {
        if (this.currentFile !== null) {
          this.service.UploadFile({ data: canvas.toDataURL(), name: "image/png" }).subscribe((ret: any = []) => {

            var splitted = this.currentFile.type;
            var fileType = splitted.split("/", 3);  //&& "video/mov" && "video/mpeg" 
            var now = new Date().getTime();
            var filename = this.datepipe.transform(this.today, 'yyyy-MM-dd') + now + '-' + this.randomid(10);
            var VideoFileName = this.currentFile.name.replace(this.currentFile.name, filename) + '.' + fileType[1];

            this.thumbnailURL = ret.name;
            const baseUrl = this.generateBlobUrl(Config, VideoFileName)
            this.config = {
              baseUrl: baseUrl,
              blockSize: 1024 * 1024 * 2,
              sasToken: Config.sas,
              file: this.currentFile,
              complete: () => {
                console.log('Transfer completed !')
              },
              error: (err) => {
                console.log('Error:', err)
              },
              progress: (percent) => {
                this.percent = percent, this.uploadedsize = (percent * (this.currentFile.size / (1024 * 1024 * 100))).toFixed(2)
              }
            }
            this.upload(this.config);
          })
        }
      }
    }
    else {
      this.isWarningAlert = true;
      this.warningAlert = "Minimum one step value required..";
      //this.StepsValue.nativeElement.focus();
    }
  }

  private initializeState(config: UploadConfig) {
    let blockSize = BlobService.DefaultBlockSize
    if (config.blockSize) {
      blockSize = config.blockSize
    }
    let maxBlockSize = blockSize
    let numberOfBlocks = 1
    const file = config.file
    const fileSize = file.size
    if (fileSize < blockSize) {
      maxBlockSize = fileSize
    }
    if (fileSize % maxBlockSize === 0) {
      numberOfBlocks = fileSize / maxBlockSize
    } else {
      numberOfBlocks = fileSize / maxBlockSize + 1
    }
    return {
      maxBlockSize: maxBlockSize,    // Each file will be split in 256 KB.
      numberOfBlocks: numberOfBlocks,
      totalBytesRemaining: fileSize,
      currentFilePointer: 0,
      blockIds: new Array(),
      blockIdPrefix: 'block-',
      bytesUploaded: 0,
      submitUri: null,
      file: file,
      baseUrl: config.baseUrl,
      sasToken: config.sasToken,
      fileUrl: config.baseUrl + '?' + config.sasToken,
      progress: config.progress,
      complete: config.complete,
      error: config.error,
      cancelled: false
    }
  }

  upload(config: UploadConfig) {
    this.isUploading = true;
    const state = this.initializeState(config)
    const reader = new FileReader()
    reader.onloadend = (evt: any) => {
      if (evt.target.readyState === 2 && !state.cancelled) {
        const uri = state.fileUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1]
        const requestData = evt.target.result
        const requestData2 = new Uint8Array(evt.target.result)
        const headers = new HttpHeaders({ 'x-ms-blob-type': 'BlockBlob', 'Content-Type': 'application/octet-stream' })
        this.http.put(uri, requestData, { headers: headers, responseType: 'text' })
          .subscribe(_elem => {
            state.bytesUploaded += requestData2.length
            const percentComplete = Math.round((state.bytesUploaded / state.file.size) * 1000) / 10
            if (state.progress) {
              state.progress(percentComplete)
            }
            this.uploadFileInBlocks(reader, state)
          }, err => {
            console.log({ error: err })
            if (state.error) {
              state.error(err)
            }
          })
      }
    }
    this.uploadFileInBlocks(reader, state)
    return {
      cancel: () => {
        state.cancelled = true
      }
    }
  }

  private uploadFileInBlocks(reader, state) {
    if (!state.cancelled) {
      if (state.totalBytesRemaining > 0) {
        const fileContent = state.file.slice(state.currentFilePointer, state.currentFilePointer + state.maxBlockSize)
        const blockId = state.blockIdPrefix + this.prependZeros(state.blockIds.length, 6)
        state.blockIds.push(btoa(blockId))
        reader.readAsArrayBuffer(fileContent)
        state.currentFilePointer += state.maxBlockSize
        state.totalBytesRemaining -= state.maxBlockSize
        if (state.totalBytesRemaining < state.maxBlockSize) {
          state.maxBlockSize = state.totalBytesRemaining;
        }
      } else {
        this.SaveMyDrills(state);
      }
    }
  }

  private SaveMyDrills(state) {
    const uri = state.fileUrl + '&comp=blocklist'
    const headers = new HttpHeaders({ 'x-ms-blob-content-type': state.file.type })
    let requestBody = '<?xml version=\'1.0\' encoding=\'utf-8\'?><BlockList>'
    for (let i = 0; i < state.blockIds.length; i++) {
      requestBody += '<Latest>' + state.blockIds[i] + '</Latest>'
    }
    requestBody += '</BlockList>'

    for (var i = 0; i < this.materials.length; i++) {
      this.matArrList.push(this.materials[i].name)
    }
    this.SaveMaterials = (this.matArrList.join(','));

    for (var i = 0; i < this.keywords.length; i++) {
      this.keyArrList.push(this.keywords[i].name)
    }
    this.SaveKeywords = (this.keyArrList.join(','));


    for (var i = 0; i < this.listStepperTempList.length; i++) {
      this.stepArrList.push(this.listStepperTempList[i].stepData)
    }
    this.SaveStepValues = (this.stepArrList.join(','));

    let isPub: string;
    if (this.IsPublic == true) { isPub = "1" }
    else if (this.IsPublic == false) { isPub = "0" }

    this.http.put(uri, requestBody, { headers: headers, responseType: 'text' })
      .subscribe(_elem => {
        if (state.complete) {
          state.complete();

          var DrillCreate = {
            ActionFlag: "Create", RefUserID: this.UserCode,
            DrillID: "",
            DrillName: String(this.DrillName),
            DrillTypeID: String(this.Skills),
            DrillLevelID: String(this.Levels), //String(this.seldrillLevel[0]), 
            Materials: this.SaveMaterials,
            Keywords: this.SaveKeywords,
            Steps: this.SaveStepValues,
            VideoURL: state.baseUrl,
            ThumbnailURL: this.thumbnailURL,
            //VideoLength: String(this.durations),
            VideoLength: String(this.Videoduration),
            IsPublic: String(isPub)
          };
          this.service.DrillsCRUD(DrillCreate).subscribe((ret: any = []) => {
            if (ret.Status == true) {
              this.isSuccessAlert = true;
              this.successAlert = "Drill \n" + String(this.DrillName) + "\n uploaded successfully..";
              this.videoLoaded = false;
              this.isShowLoader = false;
              this.Clear();
              this.ngOnInit();
              //window.location.reload();
            }
            else {
              this.isShowLoader = false;
              this.isErrorAlert = true;
              this.errorAlert = ret.Message;;
              return;
            }
            // this.SessionLoad();
          })
        }
      }, err => {
        console.log({ error: err })
        if (state.error) {
          state.error(err)
        }
      })
  }

  randomid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  // -- Video Upload -- //

  ShowMyVideo(video) {
    this.isShowLoader = true;
    this.MarialsBind = [];
    var DrillsEdit = { DrillID: String(video.DrillID), ActionFlag: "Edit", RefUserID: this.UserCode };
    this.service.DrillsCRUD(DrillsEdit).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.videoLoaded = false;
        this.lstDrillEdit = ret.DrillEditList;
        this.lstDrillEditStep = ret.DrillEditStepList;
        this.lstMembers = ret.RowDetails;

        this.IsMyVideoShow = true;
        this.PreviewDrillName = this.lstDrillEdit[0].DrillName;
        this.DrillType = this.lstDrillEdit[0].DrillType;
        this.ThumbNail = this.lstDrillEdit[0].ThumbnailURL;
        this.VideoURL = this.lstDrillEdit[0].VideoURL;
        this.CreatedTime = this.lstDrillEdit[0].CreatedTime;
        this.CreatedDate = this.lstDrillEdit[0].CreatedDate;
        this.CreatedBy = this.lstDrillEdit[0].CreatedBy;
        var arrayMat = String(this.lstDrillEdit[0].Materials).split(",").map(String);
        for (var i = 0; i < arrayMat.length; i++) {
          this.MarialsBind.push(arrayMat[i]);
        }
        this.isShowLoader = false;
      }
      else {
        this.IsMyVideoShow = false;
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  ShowMyDrills() {
    this.isShowLoader = true;
    var DrillsEdit = { ActionFlag: "MyDrills", RefUserID: this.UserCode };
    this.service.DrillsCRUD(DrillsEdit).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstMyDrills = ret.MyDrillsList;

        if (this.lstMyDrills.length > 0) {
          this.lstMyDrills = this.lstMyDrills;
          this.IsEmptyDrill = false;
        }
        else { this.IsEmptyDrill = true; }

        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  IsUploadShow() {
    this.videoLoaded = false;
  }

  public IsShareShow: boolean = true;
  public PlayGroups: string; public Athletes: string;
  public lstGetCoachPlayGroup: any[]; public lstGetAtheleteByPlayGroup: any[];
  public SaveAthleteID: string; public listAthleteUserID: any = []; public listAthleteFirebaseID: any = [];
  public tempDillID: string = ""; public tempPlayGroupID: string = "";

  // -- Drill Sharing -- // 
  DrillPlayGroupLoad(drill) {
    this.isShowLoader = true;
    this.videoLoaded = false;
    this.tempDillID = String(drill.DrillID);
    var DrillShare = { DrillID: String(drill.DrillID), ActionFlag: "GetCoachPlayGroup", RefUserID: this.UserCode };
    this.service.DrillSharing(DrillShare).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.IsShareShow = true;
        this.lstGetCoachPlayGroup = ret.GetCoachPlayGroupList;
        this.isShowLoader = false;

        //   if (this.MemberTypeID == '2'){
        //   this.IsShareShow = true;
        //   this.lstGetCoachPlayGroup = ret.GetCoachPlayGroupList;
        //   this.isShowLoader = false;
        //   }
        //   else {
        //   this.IsShareShow = false;
        //   this.isShowLoader = false;
        //    $(".modal").hide();
        //    $('.modal').remove();
        //   //  document.getElementById('.modal').setAttribute('aria-hidden', 'true');
        //   this.isWarningAlert = true;
        //   this.warningAlert = "Share option is not available for Association Members";
        // }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  DrillAthleteLoad(drills) {
    this.isShowLoader = true;
    this.tempPlayGroupID = String(drills.PlayGroupID);
    var DrillShare = {
      DrillID: String(this.tempDillID), PlayGroupID: String(drills.PlayGroupID),
      ActionFlag: "GetAtheleteByPlayGroup", RefUserID: String(drills.RefUserID)
    };
    this.service.DrillSharing(DrillShare).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstGetAtheleteByPlayGroup = ret.GetAtheleteByPlayGroupList;
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  onAthelteAdd(value, event) {
    if (event.currentTarget.ariaSelected == "false") {
      this.listAthleteUserID.push(value);
    }
    else {
      var x = this.listAthleteUserID.indexOf(value);
      this.listAthleteUserID.splice(x, 1);
    }
  }

  DrillShare() {
    this.isShowLoader = true;
    this.SaveAthleteID = (this.listAthleteUserID.join(','));
    var RequestID = "";
    if (this.isfromcoachpad) {
      this.SaveAthleteID = String(this.coachpaddetails.RefPlayerID);
      RequestID = String(this.coachpaddetails.RequestID);
    }
    var DrillShare = {
      DrillID: String(this.tempDillID), PlayGroupID: String(this.tempPlayGroupID),
      AtheleteRefUserID: this.SaveAthleteID, ActionFlag: "ShareDrill", RefUserID: this.UserCode
      , RequestID: RequestID, AthleteFirebaseID: this.listAthleteFirebaseID
    };

    this.service.DrillSharing(DrillShare).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.IsShareShow = false;
        this.isShowLoader = false;
        this.IsShareShow = false;
        $(".modal-backdrop").hide();
        $('.modal-backdrop').remove();
        // $('.modal-open').hide();
        // $('.modal-open').remove();
        this.isSuccessAlert = true;
        this.successAlert = "Drill Shared successfully...";
        // this.ngOnInit();
        this.DrillsInitLoad();
        // window.location.reload();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  DrillBatClick() {
    if (this.lstBattingView.length > 0) {
      this.lstBattingView = this.lstBattingView;
      this.IsEmptyDrill = false;
    }
    else { this.IsEmptyDrill = true; }
  }

  DrillBawlClick() {
    if (this.lstBowlingView.length > 0) {
      this.lstBowlingView = this.lstBowlingView;
      this.IsEmptyDrill = false;
    }
    else { this.IsEmptyDrill = true; }
  }

  DrillFieldClick() {
    if (this.lstFieldingView.length > 0) {
      this.lstFieldingView = this.lstFieldingView;
      this.IsEmptyDrill = false;
    }
    else { this.IsEmptyDrill = true; }
  }

  DrillWicketClick() {
    if (this.lstWicketKeepingView.length > 0) {
      this.lstWicketKeepingView = this.lstWicketKeepingView;
      this.IsEmptyDrill = false;
    }
    else { this.IsEmptyDrill = true; }
  }

  DrillFitnessClick() {
    if (this.lstFitnessView.length > 0) {
      this.lstFitnessView = this.lstFitnessView;
      this.IsEmptyDrill = false;
    }
    else { this.IsEmptyDrill = true; }
  }

  DrillOthersClick() {
    if (this.lstOthersView.length > 0) {
      this.lstOthersView = this.lstOthersView;
      this.IsEmptyDrill = false;
    }
    else { this.IsEmptyDrill = true; }
  }



  public StepsValue!: string;
  public listStepperTempList: any = [];

  public editIputs: StepperInputs[] = [{ stepData: "" }]

  AddingStepperData() {
    this.editIputs = [];
    if (this.StepsValue != undefined && this.StepsValue != "") {
      this.editIputs.push({ stepData: String(this.StepsValue) });
      this.listStepperTempList.push(this.editIputs[0]);
      this.StepsValue = "";
    }
    else {
      this.isWarningAlert = true;
      this.warningAlert = "Please fill steps data..."
    }
  }

  RemoveStepperData(id: number) {
    this.listStepperTempList.splice(id, 1);
  }

  WindowLoad() {
    window.location.reload();
  }

}


export interface User { name: string; }

const Config: UploadParams = {
  // sas: 'sp=racwdl&st=2021-03-09T09:28:47Z&se=2021-03-31T17:28:47Z&sv=2020-02-10&sr=c&sig=mtEL6aaQZybgbzn9i5SU8uR3JB9JO05E8ArFG2yrAAc%3D',
  // sas: 'sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-05-31T14:50:55Z&st=2021-04-01T06:50:55Z&spr=https,http&sig=Pp4Dg4IeL2gfPLbj%2BdedKfpscdxOXgRfWfkCQcPF%2FMk%3D',
  // storageAccount: 'prodlivedata2020',
  // containerName: 'livecontent'

  // -- Updated 05-05-2021 sas token -- //
  sas: 'sp=racwdl&st=2022-05-06T11:14:35Z&se=2024-05-06T19:14:35Z&sv=2020-08-04&sr=c&sig=7LqV5ZH%2B5QfucxrojJJ12tjqiKBSw5K59eeOiPlbR%2F8%3D',
  storageAccount: 'splcms',
  containerName: 'cricpass'

};


export interface StepperInputs { stepData: string; }

