import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { Location, DatePipe } from '@angular/common'

import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';

import { filter, switchMap } from 'rxjs/operators';
import { MastersServiceService } from '../../masters-service.service';
import { VideoCallService } from '../../video-call.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';

import * as firebase from 'firebase';
import { AngularFireDatabase, AngularFireList, DatabaseSnapshot } from '@angular/fire/database';
// import { INITIAL_EVENTS, createEventId } from './event-utils'; 

export interface User {
  name: string;
}



import * as moment from 'moment';
import 'fullcalendar';

@Component({
  selector: 'app-coachpad-playerwall',
  templateUrl: './coachpad-playerwall.component.html',
  styleUrls: ['./coachpad-playerwall.component.css']
})
export class CoachpadPlayerwallComponent implements OnInit {
  @ViewChild('chatroom') chatroom: ElementRef;
  myControl = new FormControl();
  options: User[] = [];
  filteredOptions!: Observable<User[]>;
  public commentsFeed: boolean = false;
  public showMonthRange: boolean = true;
  public showDateRange: boolean = false;
  public advFilters: boolean = false;
  public PageTitle: string = "";
  scheduledate = false;
  calendarVisible = true;
  @Input() childMessage: string;
  StartMeeting: boolean = true;
  LaunchMeeting: boolean = false;
  selectedEvent!: string;

  SessionForm: FormGroup;

  @Output() buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public isCallStarted$: Observable<boolean>;
  chats!: any;
  MemberTypeID: string;
  @ViewChild('localVideo') localVideo!: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideo!: ElementRef<HTMLVideoElement>;

  constructor(public utility: UtilitiesService, private http: HttpClient, private blob: BlobService, private service: MastersServiceService, private router: Router, private formBuilder: FormBuilder
    , private location: Location, private VideoService: VideoCallService, private AngularFireDatabase: AngularFireDatabase, public datepipe: DatePipe) {
    this.isCallStarted$ = this.VideoService.isCallStarted$;
    this.MemberTypeID = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID);
    this.defaultConfigurations = {
      editable: true,
      eventLimit: true,
      titleFormat: 'MMM D YYYY',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      views: {
        agenda: {
          eventLimit: 2
        }
      },
      allDaySlot: false,
      slotDuration: moment.duration('00:15:00'),
      slotLabelInterval: moment.duration('01:00:00'),
      firstDay: 1,
      selectable: true,
      selectHelper: true,
      events: this.eventData,
    };

    this.eventData = [
      {
        title: 'event1',
        start: moment()
      },
      {
        title: 'event2',
        start: moment(),
        end: moment().add(2, 'days')
      },
    ];
    // const itemsRef: AngularFireList<any> = AngularFireDatabase.list(String(this.utility.coachDetails.RequestID) + '/message');
    // this.chats = [];
    // if (itemsRef)
    //   this.chats = snapshotToArray(itemsRef);
    AngularFireDatabase.list(String(this.utility.coachDetails.RequestID) + '/message').valueChanges()
      .subscribe(items => {
        this.chats = [];
        this.chats = snapshotToArray(items, this.utility.coachDetails);
      });
  }
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridWeek,timeGridDay,listWeek'
      // right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'timeGridWeek',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID == 1 ? false : true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }


  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  @Input() eventData: any;
  defaultConfigurations: any;
  @Input()
  set configurations(config: any) {
    if (config) {
      this.defaultConfigurations = config;
    }
  }
  currengPage: string = "";
  scrollToBottom(): void {
    try {
      this.chatroom.nativeElement.scrollTop = this.chatroom.nativeElement.scrollHeight;
    } catch (err) { }
  }
  ngAfterContentChecked() {
    this.scrollToBottom();
  }

  public skill: string; public viewType: string;
  public sessionName: string; public sessionDate: Date;
  public videoURL: string; public selectedCoach: string = "";

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.SessionListLoad();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.SessionForm = this.formBuilder.group({
      n_sessionName: ['', Validators.required],
      n_sessionDate: ['', Validators.required],
      n_skill: ['', Validators.required],
      n_viewType: ['', [Validators.required]]
    });
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
    this.currengPage = this.location.path().replace('/', '');
    //Video Calling
    this.peerId = this.VideoService.initPeer();
    this.VideoService.localStream$
      .pipe(filter(res => !!res))
      .subscribe(stream => this.localVideo.nativeElement.srcObject = stream)
    this.VideoService.remoteStream$
      .pipe(filter(res => !!res))
      .subscribe(stream => this.remoteVideo.nativeElement.srcObject = stream)
    this.StartMeeting = false;

  }

  isSchedule() {
    this.GetAllSchedules();

    //   this.scheduledate = true;
  }
  hidePopup() { this.scheduledate = false; }


  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  changeMonthDate() {
    this.showMonthRange = !this.showMonthRange;
    this.showDateRange = !this.showDateRange;
  }
  IsShowComments: boolean = false;
  MessageList: any = [];
  openedShareid: number;
  showCommentbox(id, SessionID) {
    this.IsShowComments = !this.IsShowComments;
    this.openedShareid = id;
    this.service.AthleteSessionChatting({ ActionFlag: "ReceiveAthelete", RefUserID: this.UserCode, SessionID: String(SessionID) }).subscribe((ret: any = []) => {
      this.MessageList = ret.MessageList;
    })
  }

  classFlag: boolean = false;
  menuToggle(event: any) {
    event.target.classList.toggle('active')
  }

  public UserCode: string = "";
  public SessionsList: any[];
  public PlayerDetails: any = [];
  public lstReviewed: any = []; public ReviewCount: number = 0;
  selectedItem: string = 'Session';
  SeesionCreation: boolean = false;
  SessionListLoad() {
    this.service.CoachProfileSettings({
      method: "CoachPad/CoachpadSessionsList",
      request: {
        ActionFlag: "SessionsByCoachpad",
        RefUserID: String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID), RequestID: String(this.utility.coachDetails.RequestID)
      }
    }).subscribe((ret: any = []) => {

      if (ret.Status == true) {
        this.SessionsList = ret.SessionsList;
        if (this.SessionsList.length == 0) {
          this.SeesionCreation = true;
        }
      }
    });
  }



  public SessionTypes: any[];
  public VideoViewTypes: any[];
  SessionFiltersLoad() {
    this.service.CoachpadSessionsLoad({ ActionFlag: "Load", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      this.SessionTypes = ret.SessionTypes;
      this.VideoViewTypes = ret.VideoViewTypes;
    })
  }
  newsession: boolean = false;
  videoformfields: boolean = false;
  public videoupload: boolean = false;
  public videouploadstatus: boolean = false;
  AddNewSession() {
    this.SessionFiltersLoad();
    this.newsession = true;
    this.videoformfields = true;
  }

  showVideoupload() {
    if (this.SessionForm.invalid) {
      return;
    }
    else {
      this.videoupload = true;
      this.videouploadstatus = false;
      this.videoformfields = false;
    }
  }
  showVideouploadstatus() {
    this.videoupload = false;
    this.videouploadstatus = true;
    this.videoformfields = false;
  }
  public currentFile: File;
  public selectedFile: string;
  public selectedfilesize: string;
  public IsShowUpload: boolean = true;
  onVideoChange(event) {

    var attachedFile = <File>event.target.files[0];
    this.currentFile = attachedFile;
    var splitted = this.currentFile.type;
    // var fileType = splitted.split("/", 3);  && "video/mov" && "video/mpeg" 
    if (this.currentFile.type == "video/mp4") {
      let duration: any;
      this.showVideouploadstatus();
      this.selectedFile = URL.createObjectURL(attachedFile);
      document.getElementById("myVideo").setAttribute("src", URL.createObjectURL(attachedFile));
      this.selectedfilesize = (attachedFile.size / (1024 * 1024)).toFixed(2) + 'MB';
      this.IsShowUpload = true;
    }
    else {
      this.currentFile = null;
      // this.isErrorAlert = true;
      // this.errorAlert = "File format not support..!!";
    }

  }
  ClearSessionForm() {
    this.sessionName = '';
    this.sessionDate = null;
    this.skill = '';
    this.viewType = '';
    this.newsession = false;
  }
  //File upload Start//
  public durations: number; public Videoduration: string;
  public videoLoaded: boolean = false; public videoSubscription: boolean = true;
  public videowidth: number; public isWarningAlert: boolean = false; public warningAlert: string;
  isUploading: boolean = false; isuploadcancelled: boolean = false; config: UploadConfig;
  percent: number; uploadedsize: string;
  onMetadata(e, video) {
    // this.videowidth = 0;
    this.durations = video.duration;
    this.Videoduration = Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2) + ' Mins';
    this.videowidth = video.width;

    var dura = (Math.floor(this.durations / 60) % 60 + ':' + this.pad((Math.floor(this.durations % 60)), 2));
    if (this.durations < 480) {
      this.videoLoaded = true;
    }
    else {
      this.Videoduration = "";
      this.selectedFile = "";
      this.videoLoaded = false;
      this.isWarningAlert = true;
      this.warningAlert = "Video duration should be 08:00 Min (or) Below..!!";
    }
  }

  pad(n, width) {
    var z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  public thumbnailURL: string;
  today = new Date();

  randomid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateBlobUrl(
    params: UploadParams,
    filename: string,
    useAzureStorageEmulator = false,
    azureStorageEmulatorBaseUrl = '') {
    const url = useAzureStorageEmulator ? azureStorageEmulatorBaseUrl : `https://${params.storageAccount}.blob.core.windows.net`
    return `${url}/${params.containerName}/${filename}`
  }

  private prependZeros(number, length) {
    let str = '' + number
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }

  private uploadFileInBlocks(reader, state) {
    if (!state.cancelled) {
      if (state.totalBytesRemaining > 0) {
        const fileContent = state.file.slice(state.currentFilePointer, state.currentFilePointer + state.maxBlockSize)
        const blockId = state.blockIdPrefix + this.prependZeros(state.blockIds.length, 6)
        state.blockIds.push(btoa(blockId))
        reader.readAsArrayBuffer(fileContent)
        state.currentFilePointer += state.maxBlockSize
        state.totalBytesRemaining -= state.maxBlockSize
        if (state.totalBytesRemaining < state.maxBlockSize) {
          state.maxBlockSize = state.totalBytesRemaining;
        }
      } else {
        this.commitBlockList(state);

      }
    }
  }
  private commitBlockList(state) {
    const uri = state.fileUrl + '&comp=blocklist'
    const headers = new HttpHeaders({ 'x-ms-blob-content-type': state.file.type, 'Accept-Ranges': 'bytes', 'x-ms-version': '2013-08-15' })
    let requestBody = '<?xml version=\'1.0\' encoding=\'utf-8\'?><BlockList>'
    for (let i = 0; i < state.blockIds.length; i++) {
      requestBody += '<Latest>' + state.blockIds[i] + '</Latest>'
    }
    requestBody += '</BlockList>'

    this.http.put(uri, requestBody, { headers: headers, responseType: 'text' })
      .subscribe(_elem => {
        if (state.complete) {
          state.complete();
          this.service.CoachpadSessionCreation({
            ActionFlag: "Create", SessionName: this.sessionName, SessionDate: this.datepipe.transform(this.sessionDate, 'yyyy-MM-dd')
            , SessionTypeID: String(this.skill), VideoViewID: String(this.viewType), VideoURL: state.baseUrl, IsCloudUpload: "1",
            RequestID: String(this.utility.coachDetails.RequestID),
            VideoLength: String(this.Videoduration),
            UploadDevice: "Web"
            , CloudURL: state.baseUrl, RefUserID: this.UserCode, ThumbnailURL: this.thumbnailURL
          }).subscribe((ret: any = []) => {
            // alert("Completed");
            if (ret.Status == true) {
              this.isSuccessAlert = true;
              this.successAlert = "Session uploaded successfully..";
              this.newsession = false;
              this.videoupload = false;
              this.videouploadstatus = false;
              this.ClearSessionForm();
              this.SessionListLoad();
              this.ngOnInit();
            }
            //  this.showVideoGoupload();
            // this.clearSession();
          })
        }
      }, err => {
        console.log({ error: err })
        if (state.error) {
          state.error(err)
        }
      })
  }

  private initializeState(config: UploadConfig) {
    let blockSize = BlobService.DefaultBlockSize
    if (config.blockSize) {
      blockSize = config.blockSize
    }
    let maxBlockSize = blockSize
    let numberOfBlocks = 1
    const file = config.file
    const fileSize = file.size
    if (fileSize < blockSize) {
      maxBlockSize = fileSize
    }
    if (fileSize % maxBlockSize === 0) {
      numberOfBlocks = fileSize / maxBlockSize
    } else {
      numberOfBlocks = fileSize / maxBlockSize + 1
    }

    return {
      maxBlockSize: maxBlockSize, // Each file will be split in 256 KB.
      numberOfBlocks: numberOfBlocks,
      totalBytesRemaining: fileSize,
      currentFilePointer: 0,
      blockIds: new Array(),
      blockIdPrefix: 'block-',
      bytesUploaded: 0,
      submitUri: null,
      file: file,
      baseUrl: config.baseUrl,
      sasToken: config.sasToken,
      fileUrl: config.baseUrl + '?' + config.sasToken,
      progress: config.progress,
      complete: config.complete,
      error: config.error,
      cancelled: false
    }
  }

  upload(config: UploadConfig) {
    this.isUploading = true;

    const state = this.initializeState(config)
    const reader = new FileReader()
    reader.onloadend = (evt: any) => {
      if (evt.target.readyState === 2 && !state.cancelled) {
        const uri = state.fileUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1]
        const requestData = evt.target.result
        const requestData2 = new Uint8Array(evt.target.result)
        const headers = new HttpHeaders({ 'x-ms-blob-type': 'BlockBlob', 'Content-Type': 'application/octet-stream' })
        this.http.put(uri, requestData, { headers: headers, responseType: 'text' })
          .subscribe(_elem => {
            state.bytesUploaded += requestData2.length
            const percentComplete = Math.round((state.bytesUploaded / state.file.size) * 1000) / 10
            if (state.progress) {
              state.progress(percentComplete)
            }
            if (this.isuploadcancelled)
              state.cancelled = true;
            this.uploadFileInBlocks(reader, state)
          }, err => {
            console.log({ error: err })
            if (state.error) {
              state.error(err)
            }
          })
      }
    }
    this.uploadFileInBlocks(reader, state)
    return {
      cancel: () => {
        state.cancelled = true
      }
    }
  }
  uploadbutton() {
    this.isuploadcancelled = false;
    var canvas = <HTMLCanvasElement>document.getElementById('canvas');
    var video = <HTMLCanvasElement>document.getElementById('myVideo');
    // var img =  <HTMLCanvasElement> document.getElementById('imgId');
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // var dataURL = canvas.toDataURL();
    // img.setAttribute('src', dataURL);     
    // alert(canvas.toDataURL());
    if (this.SessionForm.invalid) {
      return;
    }
    else {
      if (this.currentFile !== null) {
        this.IsShowUpload = false;
        this.service.UploadFile({ data: canvas.toDataURL(), name: "image/png" }).subscribe((ret: any = []) => {

          var splitted = this.currentFile.type;
          var fileType = splitted.split("/", 3);  //&& "video/mov" && "video/mpeg" 
          var now = new Date().getTime();
          var filename = this.datepipe.transform(this.today, 'yyyy-MM-dd') + now + '-' + this.randomid(10);
          var VideoFileName = this.currentFile.name.replace(this.currentFile.name, filename) + '.' + fileType[1];

          this.thumbnailURL = ret.name;
          const baseUrl = this.generateBlobUrl(Config, VideoFileName)
          this.config = {
            baseUrl: baseUrl,
            blockSize: 1024 * 1024 * 2,
            sasToken: Config.sas,
            file: this.currentFile,
            complete: () => {
              console.log('Transfer completed !')
            },
            error: (err) => {
              console.log('Error:', err)
            },
            progress: (percent) => {
              this.percent = percent, this.uploadedsize = (percent * (this.currentFile.size / (1024 * 1024 * 100))).toFixed(2)
            }
          }
          this.upload(this.config);
        })

      }
    }
  }
  //File upload End//

  DrillsDetails: any = [];
  LoadDrills() {
    this.service.CoachProfileSettings({
      method: "CoachPad/MyAssignedDrillsByRequest",
      request: {
        ActionFlag: "MyDrills", RefUserID: String(this.UserCode),
        RequestID: String(this.utility.coachDetails.RequestID)
      }
    }).subscribe((ret: any = []) => {

      if (ret.Status == true) {
        this.DrillsDetails = ret.DrillsDetails;

      }
    });
  }
  AssignDrills() {
    this.service.lastPage = "coachpad";
    this.router.navigate(['/drills']);
  }
  lstDrillEdit: any = [];
  lstDrillEditStep: any = [];
  MaterialsBind: any = [];
  DrillProgress: string;
  allComplete: boolean = false;
  ShowMyVideo(video) {
    var Drills = { DrillID: String(video.DrillID), ActionFlag: "GetDrillDetails", RefUserID: this.UserCode };
    this.service.DrillsAthletes(Drills).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstDrillEdit = ret.DrillEditList[0];
        this.lstDrillEditStep = ret.DrillEditStepList;

        // this.IsMyVideoShow = true;
        // this.PreviewDrillName = this.lstDrillEdit[0].DrillName;
        // this.DrillType = this.lstDrillEdit[0].DrillType;
        // this.ThumbNail = this.lstDrillEdit[0].ThumbnailURL;
        // this.VideoURL  = this.lstDrillEdit[0].VideoURL;
        // this.CreatedTime = this.lstDrillEdit[0].CreatedTime;
        // this.CreatedDate = this.lstDrillEdit[0].CreatedDate;
        // this.CreatedBy   = video.SharedByUserName;
        // this.VideoLength = video.VideoLength;
        this.MaterialsBind = [];
        var arrayMat = String(this.lstDrillEdit.Materials).split(",").map(String);
        for (var i = 0; i < arrayMat.length; i++) {
          this.MaterialsBind.push(arrayMat[i]);
        }
        // this.isShowLoader = false;
      }
      else {
        // this.isShowLoader = false;
        // this.isErrorAlert = true;
        // this.errorAlert = ret.Message;
        return;
      }
    })
  }
  drillStatus(drill) {
    this.lstDrillEdit = drill;
    if (this.lstDrillEdit.Status == 'Active') {
      this.allComplete = false;
      this.DrillProgress = "Start Drill";
    }
    else if (this.lstDrillEdit.Status == 'InProgress') {
      this.allComplete = false;
      this.DrillProgress = "End Drill";
    }
    else if (this.lstDrillEdit.Status == 'Completed') {
      this.allComplete = true;
      this.DrillProgress = "Drill Completed";
    }
  }
  isSuccessAlert: boolean = false;
  successAlert: string = "";

  DrillsStatusUpdate() {
    //this.isShowLoader = true;
    let submitType: string;
    if (this.lstDrillEdit.Status == 'Active') { submitType = "StartDrill"; }
    else { submitType = "EndDrill"; }
    var Drills = { ActionFlag: submitType, RefUserID: this.UserCode, DrillID: String(this.lstDrillEdit.DrillID) };
    this.service.DrillsAthletes(Drills).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        $(".modal-backdrop").hide();
        $('.modal-backdrop').remove();
        this.isSuccessAlert = true;
        if (submitType == "StartDrill") { this.successAlert = "Drill Started successfully.."; }
        else { this.successAlert = "Drill Ended successfully.."; }
        this.LoadDrills();
      }
    })
  }
  private peerId!: string;
  SaveSchedule() {
    this.service.CoachProfileSettings({
      method: "CoachPad/MeetingSchedule",
      request: {
        ActionFlag: "Create", RefCoachID: String(this.utility.coachDetails.CoachRefUserID),
        RefPlayerID: String(this.utility.coachDetails.RefPlayerID), RequestID: String(this.utility.coachDetails.RequestID),
        ScheduleDate: this.sDate, StartTime: String(this.sTime), RefUserID: String(this.UserCode), PeerID: String(this.peerId)
      }
    }).subscribe((ret: any = []) => {

      if (ret.Status == true) {
        this.ShedulesList = ret.ShedulesList;
        this.calendarOptions.events = this.ShedulesList;
        this.hidePopup();
      }
    });
  }

  ShedulesList!: any;
  GetAllSchedules() {
    this.service.CoachProfileSettings({
      method: "CoachPad/MeetingSchedule",
      request: {
        ActionFlag: "MySchedule", RefCoachID: String(this.utility.coachDetails.CoachRefUserID),
        RefPlayerID: String(this.utility.coachDetails.RefPlayerID), RequestID: String(this.utility.coachDetails.RequestID),
        RefUserID: String(this.UserCode)
      }
    }).subscribe((ret: any = []) => {

      if (ret.Status == true) {
        this.ShedulesList = ret.ShedulesList;
        this.calendarOptions.events = this.ShedulesList;
      }
    });
  }
  sDate!: Date;
  sTime!: Date;
  handleDateSelect(selectInfo: DateSelectArg) {
    if (this.MemberTypeID != "1") {
      this.scheduledate = true;
      this.sDate = new Date(selectInfo.startStr);
      this.sTime = new Date(selectInfo.startStr);
    }

  }

  handleEventClick(clickInfo: EventClickArg) {
    // alert(clickInfo.event._def.extendedProps.ScheduleID);
    this.selectedEvent = String(clickInfo.event._def.extendedProps.ScheduleID);
    this.StartMeeting = true;
  }
  launchMeeting() {
    // this.VideoService.localStream$
    //   .pipe(filter(res => !!res))
    //   .subscribe(stream => this.localVideo.nativeElement.srcObject = stream)
    // this.VideoService.remoteStream$
    //   .pipe(filter(res => !!res))
    //   .subscribe(stream => this.remoteVideo.nativeElement.srcObject = stream)

    this.service.CoachProfileSettings({
      method: "CoachPad/MeetingSchedule",
      request: {
        ActionFlag: "StartMeeting", RequestID: String(this.utility.coachDetails.RequestID),
        EventID: String(this.selectedEvent), RefUserID: String(this.UserCode), PeerID: String(this.peerId)
      }
    }).subscribe(async (ret: any = []) => {
      if (ret.Status == true) {
        if (ret.MeetingStatus == 1) {
          this.StartMeeting = false;
          this.LaunchMeeting = true;

          if (ret.PeerStatus == 0) {
            const a = await this.VideoService.initPeers(ret.PeerID);
          }

          if (ret.PeerStatus == 0) {
            this.VideoService.enableCallAnswer(ret.PeerID);

          } else {
            this.VideoService.establishMediaCall(ret.PeerID);
          }
        }
        else {
          alert('Meeting already closed');
          this.StartMeeting = false;
        }


      }
    });
  }
  public endCall() {
    this.VideoService.closeMediaCall();
    this.LaunchMeeting = false;
    this.audiostatus = "Mute";
    this.vidiostatus = "Hide";
    this.service.CoachProfileSettings({
      method: "CoachPad/MeetingSchedule",
      request: {
        ActionFlag: "CloseMeeting", RequestID: String(this.utility.coachDetails.RequestID),
        EventID: String(this.selectedEvent), RefUserID: String(this.UserCode), PeerID: String(this.peerId)
      }
    }).subscribe(async (ret: any = []) => {
      if (ret.Status == true) {
      }
    });
  }
  audiostatus: string = "Mute";
  vidiostatus: string = "Hide";
  screenStatus: string = "Start screen sharing";
  changeaudiostatus() {

    if (this.audiostatus == "Mute") {
      this.audiostatus = "Unmute";
      this.VideoService.changeaudiostatus();
    }
    else {
      this.audiostatus = "Mute";
      this.VideoService.changeaudiostatus();
    }
  }
  changevidiostatus() {

    if (this.vidiostatus == "Hide") {
      this.vidiostatus = "Open";
      this.VideoService.changevidiostatus();
    }
    else {
      this.vidiostatus = "Hide";
      this.VideoService.changevidiostatus();
    }
  }
  // enableScreenSharing() {
  //   if (this.screenStatus == "Start screen sharing") {
  //     this.screenStatus = "Stop screen sharing";
  //     this.VideoService.screenSharing(true);
  //   } else {
  //     this.screenStatus = "Start screen sharing";
  //     this.VideoService.screenSharing(false);
  //   }

  // }
  FireMessage!: string;
  sendFireMessage(msg) {
    var time = new Date().getTime();
    var x = this.service.sendFireMessage({
      RequestID: String(this.utility.coachDetails.RequestID),
      fromID: String(this.utility.coachDetails.FromUID), toID: String(this.utility.coachDetails.ToUID), message: String(msg.innerText), timestamp: time
    });
    msg.innerText = "";
  }
  GetAnalysisDetails(data) {
    sessionStorage.setItem("sessionFlag", String("2"));
    this.service.VideoSessionDetails = data;
    this.service.VideoSessionDetails.RequestID = String(this.utility.coachDetails.RequestID);
    this.service.VideoSessionDetails.CoachRefUserID = String(this.utility.coachDetails.CoachRefUserID);
    this.service.VideoSessionDetails.RequestID = String(this.utility.coachDetails.RequestID)
    this.service.lastPage = "myvideos";
    this.router.navigate(['/video-analyse']);
  }
}
export const snapshotToArray = (snapshot: any, Details) => {
  const returnArr = [];
  if (snapshot != undefined) {
    snapshot.forEach((childSnapshot: any) => {
      const item = childSnapshot.fromID;
      if (item == sessionStorage.getItem('firebaseid')) {
        returnArr.push({ from: "Me", Name: Details.Name, date: childSnapshot.timestamp, message: childSnapshot.message });
      }
      else {
        returnArr.push({ from: "user", Name: Details.Name, date: childSnapshot.timestamp, message: childSnapshot.message });
      }
    });
  }
  return returnArr;
};

const Config: UploadParams = {
  // sas: 'sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-05-31T14:50:55Z&st=2021-04-01T06:50:55Z&spr=https,http&sig=Pp4Dg4IeL2gfPLbj%2BdedKfpscdxOXgRfWfkCQcPF%2FMk%3D',
  // storageAccount: 'prodlivedata2020',
  // containerName: 'livecontent' 

  // -- Updated 05-05-2021 sas token -- //
  sas: 'sp=racwdl&st=2022-05-06T11:14:35Z&se=2024-05-06T19:14:35Z&sv=2020-08-04&sr=c&sig=7LqV5ZH%2B5QfucxrojJJ12tjqiKBSw5K59eeOiPlbR%2F8%3D',
  storageAccount: 'splcms',
  containerName: 'cricpass'
};