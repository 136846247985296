import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Peer from 'peerjs';
import { BehaviorSubject, Subject, } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  private peer!: Peer;
  private mediaCall!: Peer.MediaConnection;
  private localStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject<any>(null);
  public localStream$ = this.localStreamBs.asObservable();
  private remoteStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject<any>(null);
  public remoteStream$ = this.remoteStreamBs.asObservable();

  private isCallStartedBs = new Subject<boolean>();
  public isCallStarted$ = this.isCallStartedBs.asObservable();
  constructor(private snackBar: MatSnackBar) { }

  public initPeer(): string {
    this.destroyPeer();
    try {
      this.peer = undefined;
      if (!this.peer || this.peer.disconnected) {
        const peerJsOptions: Peer.PeerJSOption = {
          debug: 3,
          config: {
            iceServers: [
              {
                urls: [
                  'stun:stun1.l.google.com:19302',
                  'stun:stun2.l.google.com:19302',
                ],
              }]
          }
        };
        try {
          let id = uuidv4();
          this.peer = new Peer(id, peerJsOptions);
          return id;
        } catch (error) {
          console.error(error);
        }
      }
    }
    catch (error) {
      console.error(error);
    }
    return "";
  }

  public initPeers(PeerID: string): string {
    this.destroyPeer();
    try {
      this.peer = undefined;
      if (!this.peer || this.peer.disconnected) {
        const peerJsOptions: Peer.PeerJSOption = {
          debug: 3,
          config: {
            iceServers: [
              {
                urls: [
                  'stun:stun1.l.google.com:19302',
                  'stun:stun2.l.google.com:19302',
                ],
              }]
          }
        };
        try {
          this.peer = new Peer(PeerID, peerJsOptions);
        } catch (error) {
          console.error(error);
        }
      }
    }
    catch (error) {
      console.error(error);
    }
    return "";
  }
  public stream = null;
  public senders=[];
  public async establishMediaCall(remotePeerId: string) {
    try {
      //   const peerJsOptions: Peer.PeerJSOption = {
      //     debug: 3,
      //     config: {
      //       iceServers: [
      //         {
      //           urls: [
      //             'stun:stun1.l.google.com:19302',
      //             'stun:stun2.l.google.com:19302',
      //           ],
      //         }]
      //     }
      //   };
      //   let id = uuidv4();
      //   this.peer = new Peer(id, peerJsOptions);
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      //var stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
     
      var connection = this.peer.connect(remotePeerId);
      connection.on('error', err => {
        console.error(err);
        this.snackBar.open(err, 'Close');
      }); 

      this.mediaCall = this.peer.call(remotePeerId, this.stream);
      this.mediaCall.on('stream',
        (remoteStream) => {
          this.remoteStreamBs.next(remoteStream);
        });
      if (!this.mediaCall) {
        let errorMessage = 'Unable to connect to remote peer';
        this.snackBar.open(errorMessage, 'Close');
        throw new Error(errorMessage);
      }

      this.localStreamBs.next(this.stream);
      this.isCallStartedBs.next(true);

      // this.mediaCall.on('stream', (remoteStream) => {
      //   console.log('remote stream ok');
      //   this.remoteStreamBs.next(remoteStream);

      // });
      // alert(a);
      this.mediaCall.on('open', () => {
        alert('opened')
      });

      // setTimeout(() => {
      //   this.mediaCall.on('stream',
      //     (remoteStream) => {
      //       this.remoteStreamBs.next(remoteStream);
      //     });
      // }, 2000);

      this.mediaCall.on('error', err => {
        this.snackBar.open(err, 'Close');
        console.error(err);
        this.isCallStartedBs.next(false);
      });
      this.mediaCall.on('close', () => this.onCallClose());
    }
    catch (ex) {
      console.error(ex);
      this.snackBar.open(String(ex), 'Close');
      this.isCallStartedBs.next(false);
    }
  }
  public async enableCallAnswer(remotePeer: any) {
    //   const peerJsOptions: Peer.PeerJSOption = {
    //     debug: 3,
    //     config: {
    //       iceServers: [
    //         {
    //           urls: [
    //             'stun:stun1.l.google.com:19302',
    //             'stun:stun2.l.google.com:19302',
    //           ],
    //         }]
    //     }
    //   };
    //  // let id = uuidv4();
    //   this.peer = new Peer(remotePeer, peerJsOptions);
    this.stream = null;
    //let stream = null;
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true }); 

      this.localStreamBs.next(this.stream);
      this.peer.on('call', async (call) => {

        this.mediaCall = call;
        this.isCallStartedBs.next(true);

        this.mediaCall.answer(this.stream);
        this.mediaCall.on('stream', (remoteStream) => {
          this.remoteStreamBs.next(remoteStream);
        });
        this.mediaCall.on('error', err => {
          this.snackBar.open(err, 'Close');
          this.isCallStartedBs.next(false);
          console.error(err);
        });
        this.mediaCall.on('close', () => this.onCallClose());
      });
    }
    catch (ex) {
      console.error(ex);
      this.snackBar.open(String(ex), 'Close');
      this.isCallStartedBs.next(false);
    }
  }
  private onCallClose() {

    this.stream.getVideoTracks().forEach(track => track.enabled = !track.enabled);
    this.remoteStreamBs?.value.getTracks().forEach(track => {
      track.stop();
    });
    this.localStreamBs?.value.getTracks().forEach(track => {
      track.stop();
    });
    this.snackBar.open('Call Ended', 'Close');
  }

  changeaudiostatus() {
    this.stream.getAudioTracks().forEach(track => track.enabled = !track.enabled);
  }
  changevidiostatus() {
    this.stream.getVideoTracks().forEach(track => track.enabled = !track.enabled);
  }

  public closeMediaCall() {
    this.mediaCall?.close();
    if (!this.mediaCall) {
      this.onCallClose()
    }
    this.isCallStartedBs.next(false);
  }

  // public async screenSharing(status: boolean) {
  //   if (status) {
  //    var stream1 = await navigator.mediaDevices.getDisplayMedia(); 
  //    this.stream.getTracks().forEach(track => {if(track.kind=='video'){track.enabled=false}});
  //     this.stream.removeTrack(this.stream.getTracks().forEach(track => track.kind==='video'));
  //     this.stream.addTrack(stream1.getTracks()[0]);
  //   // this.stream.replaceTrack(stream1.getTracks()[0]);
  //    //this.stream.getVideoTracks().forEach(track=>track.replace(this.stream,stream1));
  //   }
  //   else { 
  //     this.stream.getTracks().forEach(track => { if (track.kind == 'video') { track.enabled = true } });
  //   }
  // }

  public destroyPeer() {
    this.mediaCall?.close();
    this.peer?.disconnect();
    this.peer?.destroy();
  }


}
