<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="row">
    <div class="col-md-3 add-cat-divider">
        <div class="add-category-title">Add Player Group</div>
        <form [formGroup]="PlayerGroupForm" #pg="ngForm"  (ngSubmit)="onSubmitPlayerGroup()"> 
        <div class="row">
            <div class="col">
                <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                    <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                    <mat-label>Player Group Name</mat-label>
                    <input matInput required name="PlayerGroup" formControlName="n_PlayerGroupName"  [(ngModel)]="PlayerGroupName">
                </mat-form-field>
                <div *ngIf="pg.submitted && PlayerGroupForm.controls.n_PlayerGroupName.invalid"
                                class="alert alert-danger">
                <div *ngIf="PlayerGroupForm.controls.n_PlayerGroupName.errors.required">
                  Player Group Name is required.</div>
                </div>    
            </div>
            <div class="col">
                <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                    <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                    <mat-label>Group Short Name</mat-label>
                    <input matInput required name="GroupShortName" formControlName="n_GroupShortName"  [(ngModel)]="GroupShortName">
                </mat-form-field>
                <div *ngIf="pg.submitted && PlayerGroupForm.controls.n_GroupShortName.invalid"
                                class="alert alert-danger">
                <div *ngIf="PlayerGroupForm.controls.n_GroupShortName.errors.required">
                 Group Short Name is required.</div>
                </div>    
            </div>
            <div class="col">
                <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                    <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                    <mat-label>Gender</mat-label>
                    <mat-select placeholder="Gender" name="Gender" formControlName="n_GenderName"  style="text-transform:capitalize;" 
                    [(ngModel)]="GenderName"  >
                        <mat-option [value]="gen.GenderID"  *ngFor="let gen of GenderList"  style="text-transform:capitalize;"  >{{gen.GenderName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="pg.submitted && PlayerGroupForm.controls.n_GenderName.invalid"
                                class="alert alert-danger">
                <div *ngIf="PlayerGroupForm.controls.n_GenderName.errors.required">
                Gender is required.</div>
                </div>    
            </div>
            <div class="col-md">
                <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                    <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                    <mat-label>Age Category</mat-label>
                    <mat-select placeholder="Age Category" name="AgeCategory" formControlName="n_CategoryName"  style="text-transform:capitalize;" [(ngModel)]="CategoryName"  >
                        <mat-option [value]="age.CategoryID"  *ngFor="let age of AgeCategoryList"  style="text-transform:capitalize;"  >{{age.CategoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="pg.submitted && PlayerGroupForm.controls.n_CategoryName.invalid"
                                    class="alert alert-danger" >
                    <div *ngIf="PlayerGroupForm.controls.n_CategoryName.errors.required" >
                        Age Category is required.</div>
                    </div>    
            </div>
            <div class="col-md">
                <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                    <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                    <mat-label>Batch</mat-label>
                    <mat-select placeholder="Batch Name" name="BatchName" formControlName="n_BatchName"  style="text-transform:capitalize;" [(ngModel)]="BatchName">
                        <mat-option [value]="batch.BatchID"  *ngFor="let batch of BatchList"  style="text-transform:capitalize;"  >{{batch.BatchShortName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="pg.submitted && PlayerGroupForm.controls.n_BatchName.invalid"
                                class="alert alert-danger">
                <div *ngIf="PlayerGroupForm.controls.n_BatchName.errors.required">
                    Batch is required.</div>
                </div>  
            </div>
            <div class="col">
                <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                    <mat-label>Player Group Description</mat-label>
                    <textarea matInput placeholder="Group Description" name="PlayerGroupDescription" formControlName="n_PlayerGroupDescription"  style="text-transform:capitalize;" [(ngModel)]="PlayerGroupDescription"></textarea>
                </mat-form-field>
                <div *ngIf="pg.submitted && PlayerGroupForm.controls.n_BatchName.invalid"
                                class="alert alert-danger">
                <div *ngIf="PlayerGroupForm.controls.n_BatchName.errors.required">
                    Description is required.</div>
                </div>  
            </div>
            <div class="col-md-12">
                <div class="fileupload">
                    <div class="placeholder">Group Logo (If Necessary)</div>                                            
                    <input type="file" name="" class="fileupload-container" size="60" (change)="OnGroupIconChange($event,'group')">
                    <div class="browse-upload">
                        <input name="" type="text" class="upload-txt" value="{{PlayerGroupImagePath}}" placeholder="Select file">
                        <input type="submit" value="Browse" class="upload-btn">
                    </div>
                </div>
            </div>
            <div class="col txt-center">
                <div class="img-preview mt10" >
                    <img  src="{{PlayerGroupImage}}" class="img-fluid"
                     onerror="this.src='assets/images/no_user.png';"  >
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="btn-container txt-center mt20 mb20">
                    <button mat-raised-button color="primary" (click)="ClearPlayerGroup()" class="mr10">Clear</button>
                    <button mat-raised-button color="primary">{{ButtonType}}</button>
                </div>
            </div>
        </div>

    </form>
    </div>
    <div class="col-md-9">
        <div class="search-container">
            <div class="row">
                <div class="col-md-8">
                </div>
                <div class="col-md-4">
                    <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                        <mat-icon matSuffix class="mat-icon">
                            <a href="javascript:void(0);">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.12 373.12" width="24" height="24"><path d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z"/></svg>
                            </a>
                        </mat-icon>
                        <input matInput name="n_SearchFilter" [(ngModel)]="SearchFilter" placeholder="Search here...">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="customscroll-table"> 
            <div>
                <ngx-datatable class="material" [rows]="PlayerGroupGrid|filter:SearchFilter" [columnMode]="'force'"
                    [headerHeight]="42" [footerHeight]="50" [rowHeight]="42" [limit]="rowLimit"
                    [scrollbarV]="false" [scrollbarH]="true" >

                    <ngx-datatable-column name="S No" [width]="50"  >
                        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                            <span>{{rowIndex+1}}</span>
                        </ng-template>
                    </ngx-datatable-column> 
                    <ngx-datatable-column name="Player Group Name" prop="PlayGroupName" [width]="200" >
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Age Category" prop="CategoryName" [width]="100" >
                    </ngx-datatable-column> 
                    <ngx-datatable-column name="Batch Name" prop="BatchName" [width]="100" >
                    </ngx-datatable-column> 
                    <ngx-datatable-column name="Status" prop="RecordStatus" [width]="50">
                    </ngx-datatable-column>  
                    <ngx-datatable-column name="Actions" [width]="50" >
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span>
                                <div class="action-btn">
                                    <div class="ico-action-svg edit" 
                                            *ngIf="row.RecordStatus=='Active'"
                                            (click)="EditPlayerGroup(row)"
                                        title="Edit"><svg height="18" viewBox="0 -1 381.53417 381"
                                            width="18" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="m370.589844 230.964844c-5.523438 0-10 4.476562-10 10v88.792968c-.019532 16.558594-13.4375 29.980469-30 30h-280.589844c-16.5625-.019531-29.980469-13.441406-30-30v-260.589843c.019531-16.5625 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.523438-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.589843c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.789062c0-5.523438-4.476563-10.003906-10-10.003906zm0 0" />
                                            <path
                                                d="m156.367188 178.34375 146.011718-146.015625 47.089844 47.089844-146.011719 146.015625zm0 0" />
                                            <path
                                                d="m132.542969 249.257812 52.039062-14.414062-37.625-37.625zm0 0" />
                                            <path
                                                d="m362.488281 7.578125c-9.769531-9.746094-25.585937-9.746094-35.355469 0l-10.605468 10.605469 47.089844 47.089844 10.605468-10.605469c9.75-9.769531 9.75-25.585938 0-35.355469zm0 0" />
                                        </svg></div>
                                    <div class="ico-action-svg delete" 
                                            *ngIf="row.RecordStatus=='Active'"
                                            (click)="showWarningDialogue(row)"
                                        title="Delete"><svg height="18" viewBox="-40 0 427 427.00131"
                                            width="18" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                            <path
                                                d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                            <path
                                                d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                                            <path
                                                d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                        </svg></div>
                                    <div class="ico-action-svg"
                                         *ngIf="row.RecordStatus=='InActive'"
                                        [ngClass]="{'activate':row.RecordStatus=='Active','inactivate':row.RecordStatus=='InActive'}"
                                        (click)="showWarningDialogue(row)"
                                        title="Activate"><svg
                                            height="18" viewBox="0 0 512 512" width="18"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="m245.332031 341.332031c-4.09375 0-8.191406-1.554687-11.304687-4.691406l-69.335938-69.332031c-6.25-6.253906-6.25-16.386719 0-22.636719 6.253906-6.25 16.386719-6.25 22.636719 0l58.027344 58.027344 106.027343-106.027344c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382813 0 22.636719l-117.332031 117.332031c-3.160156 3.136719-7.253906 4.691406-11.351563 4.691406zm0 0" />
                                            <path
                                                d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-394.664062c0-32.363281 26.304688-58.667969 58.667969-58.667969h394.664062c32.363281 0 58.667969 26.304688 58.667969 58.667969v394.664062c0 32.363281-26.304688 58.667969-58.667969 58.667969zm-394.664062-480c-14.699219 0-26.667969 11.96875-26.667969 26.667969v394.664062c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-394.664062c0-14.699219-11.96875-26.667969-26.667969-26.667969zm0 0" />
                                        </svg></div>

                                </div>
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                            let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage"
                            let-offset="offset" let-isVisible="isVisible">

                            <div class="footer-page-size-position">
                                show
                            </div>
                            <div class="footer-page-size-position">
                                <mat-select style="text-align: center" class="mat-select-position"
                                    [(value)]="rowLimit" (selectionChange)="changeRowLimits($event.value)">
                                    <mat-option *ngFor="let limit of LIMITS" [value]="limit.value">
                                        {{limit.value}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="footer-page-size-position">
                                {{rowLimit}} entries
                            </div>

                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                (change)="pgrouptable.onFooterPage($event)">
                            </datatable-pager>

                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
  
    </div>
</div>



<div class="overlay success"  *ngIf = "isSuccessAlert" >
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div  class="alcenter" > 
                <h4>Player Group</h4>
                <p>{{successAlert}}</p> 
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="isSuccessAlert=false" >Ok</button>
        </div>
    </div>
</div> 
 <div class="overlay error" *ngIf = "isErrorAlert" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
                <div  class="alcenter" > 
                 <h4>Player Group</h4>
                <p>{{errorAlert}}</p> 
               </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"   (click)="isErrorAlert=false" >Ok</button>
        </div>
   </div>
</div> 
 <div class="overlay warning" *ngIf = "isWarningAlert" >
    <div class="warning-widget">
       <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="warning-container">
            <div class="alcenter"> 
                <h4>Player Group</h4>
                <p>{{warningAlert}}</p>   
            </div>   
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="DeActivatePlayerGroup(rowValue)" >Ok</button>
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"  (click)="isWarningAlert=false" >Cancel</button>
        </div>
   </div>
</div>
<div class="overlay warning" *ngIf = "isConfirmAlert" >
    <div class="warning-widget">
       <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="warning-container">
            <div class="alcenter"> 
                <h4>Player Group</h4>
                <p>{{confirmAlert}}</p> 
            </div>   
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="ActivatePlayerGroup(rowValueActive)" >Ok</button>
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"  (click)="isConfirmAlert=false" >Cancel</button>
        </div>
   </div>
</div>
 


<div class="modal fade" id="modalcrop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" >
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Resize Image</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <!-- <span  data-toggle="modal" data-target="#modalcrop">click</span>   -->
                    <div class="col-md-8" style="float:left">
                        <image-cropper [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                            [cropperMinWidth]="200" [hideResizeSquares]="false" format="png"
                            (imageCropped)="imageCropped($event)"
                            (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()" style="float:left;">
                        </image-cropper>

                    </div>
                    <!-- <button type="button" (click)=zoonIn()>+</button> <button type="button" (click)=zoonOut()>-</button> -->
                    <div class="col-md-4" style="float:left">
                        
                        <img draggable="false" src="{{croppedImage}}"
                            class="upload-preview" style="width: 100%"/> 
                    </div>

                </div>
            </div>
            <div class="footer txt-center">
                <button type="button" class="btn btn-primary" (click)="Applycropper()">Apply</button>
            </div>
        </div>
    </div>
</div>