<div class="page-container admin-dashboard-page help-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="help-title"> We are happy to assist you!! </div>
                                    <div class="drills-db">
                                        <div class="main-tab-container">
                                            <div class="row">

                                                <div class="col-md-3" *ngFor="let video of lstVideos;" >
                                                    <div class="drill-list mb20 br-5" (click)="GetVideo(video)" >
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#helpvideos">
                                                                <img src="{{video.thumbNail}}" class="img-fluid" >
                                                            </a>
                                                            <div class="ico-video"></div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="time"><p>{{video.videoTitle}}</p></div>
                                                        </figcaption>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Preview Modal -->
<div class="modal fade" id="helpvideos" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"> {{_vidoeTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="help-video">
                    <video width="100%" controls disablePictureInPicture controlsList="nodownload" 
                    poster="{{_videoThumbnail}}" src="{{_videoURL}}"
                    data-setup="{}"  disablePictureInPicture controlsList="nodownload" type="video/mp4"  >
                    </video>                        
                </div>
            </div>
        </div>
    </div>
</div>