import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
@Component({
  selector: 'app-fullscreen-layout',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class FullScreenLayoutComponent implements OnInit {

  constructor(private utility:UtilitiesService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();    
  }
}