<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container my-videos-page video-analyse-page customvheight">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper" (mouseup)="pagemouseup($event)">
        <div class="container">
            <div class="output-container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="video-container">
                            <div class="video-item br-5">
                                <!-- <div class="vprofile-header">
                                    <a href="javascript:void(0);" (click)="routeBack()" class="theme-button">Back</a>
                                </div> -->
                                <div class="vprofile-header">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <a href="javascript:void(0);" class="vprofile-left">
                                                <div class="vprofile">
                                                    <img src="assets/images/no_user.png">
                                                </div>
                                                <div class="vprofile-info">
                                                    <div class="vprofile-name"> {{PlayerName}}</div>
                                                    <div class="vprofile-type">{{PlayerRole}}</div>
                                                    <div class="vprofile-time">{{VideoDate}}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="vprofile-session custom-session-hdr">
                                                <div class="vpsession-name">{{SessionName}} :</div>
                                                <div class="session">
                                                    <span>{{SessionTypeName}}</span> <span>{{VideoViewName}}</span>
                                                    <a href="javascript:void(0);" class="compare"
                                                        (click)="GetCompareVideos()">Compare Videos</a>
                                                </div>
                                                <a href="javascript:void(0);" class="session-back"
                                                    (click)="routeBack()">
                                                    <img src="assets/images/back02.png">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <a href="javascript:void(0);" class="vprofile-left">
                                        <div class="vprofile">
                                            <img src="{{ProfileImage}}" onerror="this.src='assets/images/no_user.png';">
                                        </div>
                                        <div class="vprofile-info">
                                            <div class="vprofile-name" style="text-transform:capitalize;">
                                                {{PlayerName}}
                                            </div>
                                            <div class="vprofile-type" style="text-transform:capitalize;">
                                                {{PlayerRole}}
                                            </div>
                                            <div class="vprofile-time">
                                                <span>{{VideoDate}}</span>
                                            </div>
                                        </div>
                                    </a> -->
                                </div>
                                <!-- <div class="vprofile-session">
                                    <div class="vpsession-name">{{SessionName}} </div>
                                    <div class="session">
                                        <span>{{SessionTypeName}}</span>
                                        <span> {{VideoViewName}}</span>
                                    </div>
                                    <div class="feed-info txt-right">
                                        <a href="javascript:void(0);" class="compare"
                                            (click)="GetCompareVideos()">Compare Videos</a>
                                    </div>
                                </div> -->
                                <!-- <div class="vprofile-session">
                                        <div class="session"> {{SessionName}} : <span>{{SessionTypeName}}</span>
                                            <span>{{VideoViewName}}</span>
                                        </div>
                                        
                                        <div class="feed-info txt-right" *ngIf = "IsShowCompare">
                                            <a href="javascript:void(0);" class="compare"
                                               (click)="GetCompareVideos()">Compare Videos</a>
                                        </div>
                                    </div> -->
                                <div class="vprofile-video video-portrait" id="cropvideo">
                                    <video [hidden]="isTagVideo" id="videoplayer" #videoplayer controls="false"
                                        width="796" height="450" preload="auto"
                                        (timeupdate)="setCurrentTime(videoplayer)" (ended)="videoEnded(videoplayer)"
                                        disablePictureInPicture crossorigin="anonymous" controlsList="nodownload">
                                        <!-- (loadeddata)="ondata($event, videoplayer)" -->
                                        <source src="{{SessionVideo}}" type="video/mp4">
                                    </video>

                                    <!-- <video [hidden]="!isTagVideo" id="tagvideoplayer" #tagvideoplayer controls="false"
                                        width="796" height="450" preload="auto"
                                        (timeupdate)="setCurrentTimeTag(videoplayer)" crossorigin="anonymous"
                                        src="{{tagVideo}}" (ended)="TagvideoEnded(videoplayer)" disablePictureInPicture
                                        controlsList="nodownload">

                                    </video> -->
                                    <!-- <canvas id="canvasSnap"  width="796" height="450" style="display: none;" crossorigin="anonymous" ></canvas>  -->
                                    <!-- <div *ngIf="isText" [ngStyle]="{'left':textLeft+'px','top':textTop+'px'}"
                                        style="z-index:15;position: absolute">

                                        <input type="text" id="textInput" autofocus [(ngModel)]="textInput" (keydown)="inputEnter($event)"/>
                                    </div> -->
                                    <div class="video-txteditor" [ngStyle]="{'left':textLeft+'px','top':textTop+'px'}"
                                        style="width: 200px;z-index:15;" *ngIf=isText>
                                        <!-- <div class="txtbox-container">
                                            <div class="txt-control txt-center">
                                                <a href="javascript:void(0);" class="ico-txt fontdec"
                                                    (click)="changeFontSize('-1')">
                                                    <img src="assets/images/annotation/new/fontdec.png"
                                                        title="Font Decrease">
                                                </a>
                                                <a href="javascript:void(0);" class="ico-txt fontinc"
                                                    (click)="changeFontSize('1')">
                                                    <img src="assets/images/annotation/new/fontinc.png"
                                                        title="Font Increase">
                                                </a>
                                                <a href="javascript:void(0);" class="ico-txt fontbg" title="Font Color"> 
                                                    <input id="fontcolorPicker" [(colorPicker)]="fontcolor"
                                                        class="colorpicker font" [style.background]="fontcolor"
                                                        style="padding: 9px;cursor: pointer;" [(ngModel)]="fontcolor" />
                                                </a>
                                                <a href="javascript:void(0);" class="ico-txt fontbg"
                                                    title="Background Color">
                                                    <input id="backcolorPicker" class="colorpicker"
                                                        [style.background]="fontbackcolor"
                                                        style="padding: 9px;cursor: pointer;"
                                                        [(colorPicker)]="fontbackcolor" [(ngModel)]="fontbackcolor" />
                                                </a>
                                            </div>
                                        </div> -->
                                        <div class="txtbody">
                                            <div class="editable" contenteditable="true" id="text_tool"
                                                placeholder="Add text here..."
                                                [ngStyle]=" {'font-size': textFontSize+'px','color':fontcolor,'background-color': fontbackcolor}">
                                            </div>
                                            <div class="btn-container txt-center">
                                                <a href="javascript:void(0);" (click)="inputEnter()">Submit</a>
                                                <a href="javascript:void(0);" (click)="isText=false">Cancel</a>
                                            </div>
                                        </div>
                                        <a href="javascript:void(0);" class="resize" title="Resize"></a>
                                    </div>

                                    <div class="canvas-parent" [hidden]="isTagVideo">
                                        <div class="canvas-container">

                                            <canvas class="drawing1" #canvasDraw width="796" height="450"
                                                (mousedown)="handleMouseDown($event)"
                                                (mousemove)="handleMouseMove($event)"
                                                (click)="handleclick($event,videoplayer,canvasDraw2)"
                                                (mouseup)="handleMouseUpOut($event)"
                                                [ngStyle]="{'z-index': showcanvas===true?'10': '-5'}"></canvas>
                                            <canvas class="drawing2" id="canvasDraw2" #canvasDraw2 width="796"
                                                height="450" (mousedown)="handleMouseDown($event)"
                                                crossorigin="anonymous"
                                                [ngStyle]="{'z-index': showcanvas===true?'9': '-5'}">

                                            </canvas>
                                            <canvas class="drawing3" #canvasDraw3 width="796" height="450"
                                                (mousedown)="handleMouseDown($event)"
                                                (mousemove)="handleMouseMove($event)"
                                                (click)="handleclick($event,videoplayer,canvasDraw2)"
                                                (mouseup)="handleMouseUpOut($event)"
                                                [ngStyle]="{'z-index': showcanvas===true && isTextEditor===true?'11': showcanvas===true?'8':'-5'}"></canvas>
                                            <canvas class="drawing4" id="canvasDraw4" #canvasDraw4 width="796"
                                                height="450" [ngStyle]="{'z-index': '-5'}"></canvas>

                                        </div>
                                    </div>

                                </div>
                                <div class="customized-video-controls">
                                    <div class="slidecontainer">
                                        <div class="video-action-progress">
                                            <div class="video-nextprevframe">
                                                <a href="javascript:void(0);" (click)="resume(videoplayer)">
                                                    <img *ngIf="play" src="assets/images/annotation/ico_play_128.png"
                                                        title="Play">
                                                    <img *ngIf="!play" src="assets/images/annotation/ico_pause_128.png"
                                                        title="Pause">
                                                </a>
                                                <!-- <a href="javascript:void(0);" [hidden]="!isTagVideo"
                                                    (click)="playTags(tagvideoplayer)">
                                                    <img *ngIf="tagplay"
                                                        src="assets/images/annotation/ico_play_128.png">
                                                    <img *ngIf="!tagplay"
                                                        src="assets/images/annotation/ico_pause_128.png"></a> -->
                                                <a href="javascript:void(0);" (click)="skip(-0.03,videoplayer)"><img
                                                        src="assets/images/annotation/prevframe.png"
                                                        title="Previous Frame"></a>
                                                <a href="javascript:void(0);" (click)="skip(0.03,videoplayer)"><img
                                                        src="assets/images/annotation/nextframe.png"
                                                        title="Next Frame"></a>
                                            </div>
                                            <div class="video-progress-container"
                                                (mousemove)="seekbarclick($event,videoplayer)"
                                                (mouseup)="seekbarclick($event,videoplayer)">
                                                <div class="video-progress-track" title="{{seektotime}}"
                                                    (click)="seekbarclick($event,videoplayer)"
                                                    (mousedown)="seekbarclick($event,videoplayer)"
                                                    (mousemove)="seekbarclick($event,videoplayer)"
                                                    (mouseup)="seekbarclick($event,videoplayer)">
                                                    <span class="start-time">{{videocurrtime}}</span>
                                                    <span class="end-time">{{videoduration}}</span>
                                                    <div class="video-progress-bar" id="custom-seekbar">
                                                        <!-- <div class="vtrack" title="4.20"></div> -->
                                                    </div>
                                                    <ng-container *ngIf="selectedTab=='R'">
                                                        <div (click)="tagpointClick($event,review,videoplayer,ind);$event.stopPropagation();"
                                                            *ngFor="let review of lstAnnotationReviews; let ind=index; "
                                                            class="taggedpoints" [title]="review.FrameTimes"
                                                            [ngStyle]="{'left':((review.FrameTime / videoplayer.duration) * 100)+'%'}">
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="selectedTab=='T'">
                                                        <div (click)="tagpointClick($event,review,videoplayer,ind);$event.stopPropagation();"
                                                            *ngFor="let review of lstSessionTagdetails; let ind=index; "
                                                            class="taggedpoints"
                                                            [ngStyle]="{'left':((review.StartTime / videoplayer.duration) * 100)+'%','width':(((review.EndTime-review.StartTime) / videoplayer.duration) * 100)+'%'}">
                                                        </div>
                                                    </ng-container>
                                                    <div class="view-audio"
                                                        *ngIf="audiocommentUrl && selectedTab == 'R'"
                                                        [ngStyle]="{'left': seekpercent}">
                                                        <audio controls #audioRec [src]="audiocommentUrl">
                                                            <!-- <source [src]="sanitize(audiocommentUrl)" type="audio/wav"> -->
                                                        </audio>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="range-container">
                                                <!-- <input type="range" min="1" max="100" value="50" class="rslider"> -->
                                                <p>Speed</p>
                                            </div>
                                            <div class="range-dd">
                                                <select [(ngModel)]="videoSpeed"
                                                    (change)="videoSpeedchange(videoplayer)">
                                                    <option value="0.25">-3x</option>
                                                    <option value="0.5">-2x</option>
                                                    <option value="0.75">-1x</option>
                                                    <option value="1" selected class="normalspeed">1x</option>
                                                    <option value="1.5">2x</option>
                                                    <option value="2">3x</option>
                                                    <option value="2.5">4x</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vprofile-annotation txt-center" *ngIf="!isPlayer">
                                    <div class="annotation-controls">
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='freehand'}"
                                            (click)="changeAnnotation('freehand')"><img
                                                *ngIf="annotationType!='freehand'"
                                                src="assets/images/annotation/new/freehand.png" title="Free Hand">
                                            <img *ngIf="annotationType==='freehand'"
                                                src="assets/images/annotation/new/freehand2.png" title="Free Hand"></a>
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='line'}"
                                            (click)="changeAnnotation('line')"><img *ngIf="annotationType!='line'"
                                                src="assets/images/annotation/new/line.png" title="Line">
                                            <img *ngIf="annotationType==='line'"
                                                src="assets/images/annotation/new/line2.png" title="Line"></a>
                                        <a href="javascript:void(0);"
                                            [ngClass]="{'select':annotationType==='rectangle'}"
                                            (click)="changeAnnotation('rectangle')"><img
                                                *ngIf="annotationType!='rectangle'"
                                                src="assets/images/annotation/new/rectangle.png" title="Rectangle">
                                            <img *ngIf="annotationType==='rectangle'"
                                                src="assets/images/annotation/new/rectangle2.png" title="Rectangle"></a>
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='circle'}"
                                            (click)="changeAnnotation('circle')">
                                            <img *ngIf="annotationType!='circle'"
                                                src="assets/images/annotation/new/circle.png" title="Circle">
                                            <img *ngIf="annotationType==='circle'"
                                                src="assets/images/annotation/new/circle2.png" title="Circle"></a>
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='arrow'}"
                                            (click)="changeAnnotation('arrow')"><img *ngIf="annotationType!='arrow'"
                                                src="assets/images/annotation/new/arrow.png" title="Arrow">
                                            <img *ngIf="annotationType==='arrow'"
                                                src="assets/images/annotation/new/arrow2.png" title="Arrow"></a>
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='angle'}"
                                            (click)="changeAnnotation('angle')"><img *ngIf="annotationType!='angle'"
                                                src="assets/images/annotation/new/angle.png" title="Angle">
                                            <img *ngIf="annotationType==='angle'"
                                                src="assets/images/annotation/new/angle2.png" title="Angle"></a>
                                        <a href="javascript:void(0);" class="addmeasure"><img
                                                (click)="changeAnnotation('scale')" *ngIf="annotationType!='scale'"
                                                src="assets/images/annotation/new/measurement.png" title="Ruler">
                                            <img (click)="changeAnnotation('scale')" *ngIf="annotationType==='scale'"
                                                src="assets/images/annotation/new/measurement2.png" title="Ruler">
                                            <div class="measurementInputs" *ngIf="isScaleRular">
                                                <div class="txtbox-container">
                                                    <div class="txt-header txt-center">
                                                        Measurement
                                                    </div>
                                                </div>
                                                <div class="txtbody">
                                                    <div class="measurement">
                                                        <!-- <div class="editable" contenteditable="true"
                                                            placeholder="Enter..."></div> -->
                                                        <input type="number" class="form-controls"
                                                            [(ngModel)]="unitValue" (selectionChange)="unitTypeChange()"
                                                            placeholder="Enter unit value">
                                                        <select class="form-controls"
                                                            (selectionChange)="unitTypeChange()" [(ngModel)]="unitType">
                                                            <option value="feet">Feet</option>
                                                            <option value="meter">Meter</option>
                                                            <option value="cm">CM</option>
                                                            <option value="inches">Inches</option>
                                                        </select>
                                                        <!-- <select class="form-controls">
                                                            <option value="01">Feet</option>
                                                            <option value="02">Meter</option>
                                                            <option value="03">CM</option>
                                                            <option value="04">Pixel</option>
                                                        </select> -->
                                                    </div>
                                                    <div class="btn-container">
                                                        <div class="measure-btn">
                                                            <a href="javascript:void(0);"
                                                                (click)="setScaleRular()">Submit</a>
                                                            <a href="javascript:void(0);"
                                                                (click)="isScaleRular=false;">Cancel</a>
                                                            <!-- <a href="javascript:void(0);">Submit</a>
                                                            <a href="javascript:void(0);"
                                                                (click)="hideMeasurement();">Cancel</a> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='text'}"
                                            class="insert-txt"><img (click)="changeAnnotation('text')"
                                                *ngIf="annotationType!='text'"
                                                src="assets/images/annotation/new/text.png" title="Add Text">
                                            <img (click)="changeAnnotation('text')" *ngIf="annotationType==='text'"
                                                src="assets/images/annotation/new/text2.png" title="Add Text">
                                            <div class="measurementInputs" *ngIf=isText>
                                                <div class="txtbox-container">
                                                    <div class="txt-header txt-center">
                                                        <ul>
                                                            <li>
                                                                <div class="fontsize">
                                                                    <a href="javascript:void(0);"
                                                                        (click)="changeFontSize('-1')"><img
                                                                            src="assets/images/decrease-font.png"
                                                                            title="Decrease"></a>
                                                                    <span>A</span>
                                                                    <span>A</span>
                                                                    <span>A</span>
                                                                    <a href="javascript:void(0);"><img
                                                                            src="assets/images/increase-font.png"
                                                                            title="Increase"
                                                                            (click)="changeFontSize('1')"></a>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <!-- <a href="javascript:void(0);">
                                                                    <input id="colorPicker" class="colorpicker"
                                                                        title="Strok Color" />
                                                                </a> -->
                                                                <a href="javascript:void(0);" class="ico-txt fontbg"
                                                                    title="Font Color">
                                                                    <!--<input id="colorPicker" class="colorpicker font" title="Font Color" />-->
                                                                    <input id="fontcolorPicker"
                                                                        [(colorPicker)]="fontcolor"
                                                                        class="colorpicker font"
                                                                        [style.background]="fontcolor"
                                                                        style="padding: 9px;cursor: pointer;"
                                                                        [(ngModel)]="fontcolor" />
                                                                </a>
                                                                <a href="javascript:void(0);" class="ico-txt fontbg"
                                                                    title="Background Color">
                                                                    <input id="backcolorPicker" class="colorpicker"
                                                                        [style.background]="fontbackcolor"
                                                                        style="padding: 9px;cursor: pointer;"
                                                                        [(colorPicker)]="fontbackcolor"
                                                                        [(ngModel)]="fontbackcolor" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <!-- <div class="txtbody">
                                                    <div class="editable" contenteditable="true"
                                                        [ngStyle]=" {'font-size': textFontSize+'px'}"
                                                        placeholder="Add text here..." id="text_tool"></div>
                                                    <div class="btn-container">
                                                        <div class="measure-btn">
                                                            <a href="javascript:void(0);" (click)="addText()">Submit</a>
                                                            <a href="javascript:void(0);"
                                                                (click)="isTextEditor=false;">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" (click)="undoAnnotation(videoplayer)"><img
                                                src="assets/images/annotation/new/undo.png" title="Undo"></a>
                                        <a href="javascript:void(0);" (click)="changeAnnotation('rotateright')"><img
                                                src="assets/images/annotation/new/redo.png" title="Redo"></a>
                                        <a href="javascript:void(0);"
                                            [ngClass]="{'select':annotationType==='audio'}"><img
                                                *ngIf="annotationType!='audio'" (click)="changeAnnotation('audio');"
                                                src="assets/images/annotation/new/audio.png" title="Audio">
                                            <img *ngIf="annotationType==='audio'" (click)="changeAnnotation('audio');"
                                                src="assets/images/annotation/new/audio2.png" title="Audio">

                                            <ng-template [ngIf]="isaudio">
                                                <div class="audiocontainer">
                                                    <div class="audiobody">
                                                        <div class="audio-container">
                                                            <div class="recording">
                                                                <a href="javascript:void(0);" *ngIf="!recording"
                                                                    (click)="initiateRecording()">
                                                                    <img src="assets/images/annotation/new/audio.png"
                                                                        title="Start Record">
                                                                </a>
                                                                <a href="javascript:void(0);" *ngIf="recording"
                                                                    (click)="stopRecording()">
                                                                    <img src="assets/images/annotation/new/stopaudio.png"
                                                                        title="Stop Record">
                                                                </a>
                                                            </div>
                                                            <div class="audiocontrol">
                                                                <audio controls *ngIf="!url" #audioRec>
                                                                    <source [src]="sanitize(url)" type="audio/wav">
                                                                </audio>
                                                                <audio controls *ngIf="url"
                                                                    (loadedmetadata)="onAudiodata($event, audioRec)"
                                                                    #audioRec>
                                                                    <source [src]="sanitize(url)" type="audio/wav">
                                                                </audio>
                                                            </div>
                                                        </div>
                                                        <div class="btn-container">
                                                            <a href="javascript:void(0);"
                                                                (click)="saveAudioComment();">OK</a>
                                                            <a href="javascript:void(0);"
                                                                (click)="cancelAudio();event.cancelBubble=true;">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>

                                            <!-- <div class="measurementInputs" *ngIf="isaudio">
                                                <div style="text-align:center;margin-top: 2px;">
                                                    <button (click)="initiateRecording()" class="btn btn-primary"
                                                        *ngIf="!recording"
                                                        style="cursor: pointer;background-color: green;color: white;font-size: 10px;">
                                                        Start Recording </button>
                                                    <button (click)="stopRecording()" class="btn btn-danger"
                                                        *ngIf="recording"
                                                        style="cursor: pointer;background-color: red;color: white;font-size: 10px;">
                                                        Stop Recording </button>
                                                    <audio controls="" *ngIf="url" #audioRec
                                                        (loadedmetadata)="onAudiodata($event, audioRec)">
                                                        <source [src]="sanitize(url)" type="audio/wav">
                                                    </audio>
                                                </div>
                                            </div> -->
                                        </a>
                                        <!-- <a href="javascript:void(0);"
                                            [ngClass]="{'select':annotationType==='shapeedit'}"
                                            (click)="changeAnnotation('shapeedit')"><img
                                                *ngIf="annotationType!='shapeedit'"
                                                src="assets/images/annotation/new/edit.png" title="Video">
                                            <img *ngIf="annotationType==='shapeedit'"
                                                src="assets/images/annotation/new/edit2.png" title="Edit">

                                        </a> -->
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='save'}"
                                            (click)="saveAnnotation(videoplayer)"><img
                                                src="assets/images/annotation/new/save.png" title="Save"></a>
                                        <a href="javascript:void(0);" [ngClass]="{'select':annotationType==='clear'}"
                                            (click)="ClearAll()"><img src="assets/images/annotation/new/delete.png"
                                                title="Clear"></a>
                                        <a href="javascript:void(0);">
                                            <input id="colorPicker" [(colorPicker)]="drawcolor"
                                                [style.background]="drawcolor"
                                                style="padding: 12px;height: 20px;width: 20px;border-radius: 15px;cursor: pointer;"
                                                [(ngModel)]="drawcolor" />
                                        </a>

                                        <div class="filter-by">
                                            <a href="javascript:void(0);" class="view-by-icon">
                                                <span class="search-label">Stroke Width</span>
                                                <span class="search-value">1px</span>
                                            </a>
                                            <div class="dd-panel">
                                                <a href="javascript:void(0);" (click)="strokewidth=1">
                                                    <span>1px</span>
                                                    <span><img src="assets/images/1xline.png"></span>
                                                </a>
                                                <a href="javascript:void(0);" (click)="strokewidth=2">
                                                    <span>2px</span>
                                                    <span><img src="assets/images/2xline.png"></span>
                                                </a>
                                                <a href="javascript:void(0);" (click)="strokewidth=3">
                                                    <span>3px</span>
                                                    <span><img src="assets/images/3xline.png"></span>
                                                </a>
                                                <a href="javascript:void(0);" (click)="strokewidth=4">
                                                    <span>4px</span>
                                                    <span><img src="assets/images/4xline.png"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="custom-seekbar" style="z-index: 100;"
                                    (click)="seekbarclick($event,videoplayer)"
                                    (mousedown)="seekbarclick($event,videoplayer)"
                                    (mousemove)="seekbarclick($event,videoplayer)"
                                    (mouseup)="seekbarclick($event,videoplayer)" id="custom-seekbar">
                                    <span></span>
                                </div> -->

                                <!-- <div class="vprofile-annotation" [hidden]="isTagVideo">

                                    <div class="annotation-controls">
                                        <a href="javascript:void(0);" title="Free Hand"
                                            [ngClass]="{'select':annotationType==='freehand'}"><img
                                                src="assets/images/annotation/ico_bend_128.png"
                                                (click)="changeAnnotation('freehand')"></a>
                                        <a href="javascript:void(0);" title="Line"
                                            [ngClass]="{'select':annotationType==='line'}"><img
                                                src="assets/images/annotation/ico_line_128.png"
                                                (click)="changeAnnotation('line')"></a>
                                        <a href="javascript:void(0);" title="Rectange"
                                            [ngClass]="{'select':annotationType==='rectangle'}"><img
                                                src="assets/images/annotation/ico_square_128.png"
                                                (click)="changeAnnotation('rectangle')"></a>
                                        <a href="javascript:void(0);" title="Circle"
                                            [ngClass]="{'select':annotationType==='circle'}"><img
                                                src="assets/images/annotation/ico_cricle_128.png"
                                                (click)="changeAnnotation('circle')"></a>
                                        <a href="javascript:void(0);" title="Arrow"
                                            [ngClass]="{'select':annotationType==='arrow'}"><img
                                                src="assets/images/annotation/ico_arrow_128.png"
                                                (click)="changeAnnotation('arrow')"></a>
                                        <a href="javascript:void(0);" title="Angle"
                                            [ngClass]="{'select':annotationType==='angle'}"><img
                                                src="assets/images/annotation/ico_angle_128.png"
                                                (click)="changeAnnotation('angle')"></a>
                                        <a href="javascript:void(0);" title="Scale"
                                            [ngClass]="{'select':annotationType==='scale'}"><img
                                                src="assets/images/annotation/ico_ruler_128.png"
                                                (click)="changeAnnotation('scale')"></a>
                                        <a href="javascript:void(0);" title="Text"
                                            [ngClass]="{'select':annotationType==='text'}"><img
                                                src="assets/images/annotation/ico_addtext_128.png"
                                                (click)="changeAnnotation('text')"></a>
                                        <a href="javascript:void(0);"  title="Save"><img
                                                src="assets/images/annotation/ico_saveaudio_128.png"
                                                (click)="playVideo(videoplayer)"></a>
                                        <a href="javascript:void(0);" title="Undo"><img
                                                src="assets/images/annotation/ico_rotateleft_128.png"
                                                (click)="undoAnnotation(videoplayer)"></a>
                                        <a href="javascript:void(0);"><img
                                                src="assets/images/annotation/ico_rotateright_128.png" (click)="redoAnnotation(videoplayer)"></a>
                                        <a href="javascript:void(0);" title="Save"><img
                                                src="assets/images/annotation/ico_save_128.png"
                                                (click)="saveAnnotation(videoplayer)"></a>
                                        <a href="javascript:void(0);"><img
                                                    src="assets/images/annotation/ico_close_128.png"
                                                    (click)="startAnnotation(videoplayer)"></a>
                                        <a href="javascript:void(0);" (click)="resume(videoplayer)">
                                            <img *ngIf="play" title="Play"
                                                src="assets/images/annotation/ico_play_128.png">
                                            <img *ngIf="!play" title="Pause"
                                                src="assets/images/annotation/ico_pause_128.png"></a>
                                        <a href="javascript:void(0);" (click)="skip(-0.03,videoplayer)"
                                            title="Previous Fame">
                                            <img src="assets/images/cp-prev.png"> </a>
                                        <a href="javascript:void(0);" (click)="skip(0.03,videoplayer)"
                                            title="Next Fame">
                                            <img src="assets/images/cp-next.png"> </a>
                                        <input id="colorPicker" [(colorPicker)]="drawcolor"
                                            [style.background]="drawcolor"
                                            style="padding: 12px;height: 20px;width: 20px;border-radius: 15px;cursor: pointer;"
                                            [(ngModel)]="drawcolor" />
                                        <div class="slidecontainer">
                                            <input type="range" min="0" max="2" step="0.1" [(ngModel)]="videoSpeed"
                                                class="slider" id="myRange" (change)="videoSpeedchange(videoplayer)">
                                            <p>Speed : {{videoSpeed}}X</p>

                                        </div>
                                        <div class="measurementInputs" *ngIf="isScaleRular">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <input type="number" class="form-controls" [(ngModel)]="unitValue"
                                                        (selectionChange)="unitTypeChange()"
                                                        placeholder="Enter unit value">
                                                </div>
                                                <div class="col-md-4">
                                                    <select class="form-controls" (selectionChange)="unitTypeChange()"
                                                        [(ngModel)]="unitType">
                                                        <option value="feet">Feet</option>
                                                        <option value="meter">Meter</option>
                                                        <option value="cm">CM</option>
                                                        <option value="inches">Inches</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="measure-btn">
                                                        <a href="javascript:void(0);" (click)="setScaleRular()">Ok</a>
                                                        <a href="javascript:void(0);"
                                                            (click)="isScaleRular=false;">Cancel</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="measurementInputs" *ngIf="isTextEditor">
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <input id="text_tools" type="text" class="form-controls">
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="measure-btn">
                                                        <a href="javascript:void(0);" (click)="addText()">Ok</a>
                                                        <a href="javascript:void(0);"
                                                            (click)="isTextEditor=false;">Cancel</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="playballonly">
                                        <mat-slide-toggle [(ngModel)]="playballonly" (change)="playballonlyChange()">
                                            Play ball only</mat-slide-toggle>
                                    </div>
                                </div> -->
                                <!-- <div class="vprofile-annotation" [hidden]="!isTagVideo">

                                    <div class="annotation-controls">

                                        <a href="javascript:void(0);" (click)="playTags(tagvideoplayer)">
                                            <img *ngIf="tagplay" src="assets/images/annotation/ico_play_128.png">
                                            <img *ngIf="!tagplay" src="assets/images/annotation/ico_pause_128.png"></a>

                                    </div>

                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">

                        <div class="video-filters box-shadow br-5">
                            <div class="video-filters-container min-height3">
                                <div class="secondary-tab" rel="videotagging">
                                    <a href="javascript:void(0);" class="select" rel="reviews"
                                        (click)="ReviewsLoad(videoplayer)">Reviews</a>
                                    <a href="javascript:void(0);" rel="tags" (click)="TagsLoad(videoplayer)">Tags</a>
                                    <a href="javascript:void(0);" rel="graphs" (click)="GraphsLoad()">Graphs</a>
                                </div>

                                <div class="videotagging" id="style-1">
                                    <div class="secondary-tab-container reviews" style="display:block;">
                                        <div class="reviewcontainer" *ngIf="isfeedbackenable">
                                            <div class="txt-right" *ngIf="sessionRate==0"><a href="javascript:void(0);"
                                                    data-toggle="modal" data-target="#ratingModel"
                                                    class="theme-button">Feedback Rating</a>
                                            </div>
                                            <div class="txt-right" *ngIf="sessionRate>0">
                                                <div class="prating">
                                                    <span [ngClass]="sessionRate>0?'select':'unselect'">
                                                        <i class="fa fa-star"></i>
                                                    </span>
                                                    <span [ngClass]="sessionRate>1?'select':'unselect'">
                                                        <i class="fa fa-star"></i>
                                                    </span>
                                                    <span [ngClass]="sessionRate>2?'select':'unselect'">
                                                        <i class="fa fa-star"></i>
                                                    </span>
                                                    <span [ngClass]="sessionRate>3?'select':'unselect'">
                                                        <i class="fa fa-star"></i>
                                                    </span>
                                                    <span [ngClass]="sessionRate>4?'select':'unselect'">
                                                        <i class="fa fa-star"></i>
                                                    </span>
                                                </div>
                                                <ng-template #t let-fill="fill">
                                                    <span class="gift" [class.full]="fill === 100">
                                                        <span class="half" [style.width.%]="fill">&#9734;</span>&#9734;
                                                    </span>
                                                </ng-template>

                                                <ngb-rating [(rate)]="sessionRate" [starTemplate]="t" [readonly]="true"
                                                    max="5">
                                                </ngb-rating>
                                            </div>
                                        </div>
                                        <div class="video-tagging" id="style-1">
                                            <ul class="video-review-container">
                                                <li class="review-list"
                                                    *ngFor="let review of lstAnnotationReviews; let ind=index;">
                                                    <div href="javascript:void(0);" class="review-time">
                                                        {{review.FrameTimes}}</div>
                                                    <div class="review-txt">
                                                        <div class="review-thumbnail-container"
                                                            (click)="playVideoByFrame(review,videoplayer,ind)">
                                                            <div class="review-thumbnail">
                                                                <img src="{{review.Thumbnail}}"
                                                                    onerror="this.src='assets/images/no_user.png';">
                                                            </div>
                                                            <p>{{review.TextContent}}</p>
                                                        </div>
                                                        <div class="review-reply txt-right">
                                                            <a href="javascript:void(0);" (click)="DeleteReview(review)"
                                                                class="ico-delete">
                                                                <img src="assets/images/remove-list.png" title="Remove">
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="secondary-tab-container tags" style="display:none;">
                                        <div class="txt-center templates mb10">
                                            <a href="javascript:void(0);" (click)="tagContainer=!tagContainer">
                                                <span *ngIf="!tagContainer">Show Tags </span>
                                                <span *ngIf="tagContainer">Hide Tags </span><span
                                                    class="ico-arrow"></span></a>
                                            <!-- <a href="javascript:void(0);">Play all Tags<span
                                                        class="ico-tagplay"></span></a> -->
                                        </div>
                                        <div class="tags-container" *ngIf="tagContainer">
                                            <ul>
                                                <li *ngFor="let side of lstSides"><a href="javascript:void(0);"
                                                        (click)="GetTagsDetails('Side',side.side)">{{side.side}}
                                                        ({{side.counts}})</a></li>
                                            </ul>
                                            <ul>
                                                <p><span>foot Movement</span></p>
                                                <li *ngFor="let foot of lstFootMovements"><a href="javascript:void(0);"
                                                        (click)="GetTagsDetails('FootMove',foot.FootMoveID)">{{foot.FootMove}}
                                                        ({{foot.counts}})</a></li>
                                            </ul>
                                            <ul>
                                                <p><span>Length</span></p>
                                                <li *ngFor="let length of lstPMLengths"><a href="javascript:void(0);"
                                                        (click)="GetTagsDetails('PMLength',length.LengthID)">{{length.LengthName}}
                                                        ({{length.counts}})</a></li>
                                            </ul>
                                            <ul>
                                                <p><span>Quality</span></p>
                                                <li *ngFor="let qual of lstShotQuality"><a href="javascript:void(0);"
                                                        (click)="GetTagsDetails('Quality',qual.Quality)">{{qual.Quality}}
                                                        ({{qual.counts}})</a></li>
                                            </ul>
                                            <ul>
                                                <p><span>Shots</span></p>
                                                <li *ngFor="let shots of lstShots"><a href="javascript:void(0);"
                                                        (click)="GetTagsDetails('Shot',shots.ShotID)">{{shots.ShotName}}
                                                        ({{shots.counts}})</a></li>
                                            </ul>
                                            <ul>
                                                <p><span>Ball</span></p>
                                                <li *ngFor="let btype of lstBallTypes"><a href="javascript:void(0);"
                                                        (click)="GetTagsDetails('BallVariation',btype.BallVariation)">{{btype.BowlTypeName}}
                                                        ({{btype.counts}})</a></li>
                                            </ul>
                                        </div>
                                        <div class="analyse-video-container">
                                            <div class="analyse-list mb15" [ngClass]="{'select':ind===tagIndex}"
                                                (click)="PlayTagVideo(tags,videoplayer,ind)"
                                                *ngFor="let tags of lstSessionTagdetails; let ind=index;">
                                                <div class="analyse-ttile">Delivery {{ind+1}}</div>
                                                <a href="javascript:void(0)">
                                                    <div class="analyse-description">
                                                        <div class="batting-style" *ngIf="SessionTypeName=='Bowling'">
                                                            Line : {{tags.LineName}}</div>
                                                        <div class="bowlings-style" *ngIf="SessionTypeName=='Bowling'">
                                                            Length : {{tags.LengthName}}</div>
                                                        <div class="batting-style" *ngIf="SessionTypeName=='Batting'">
                                                            Foot Movement : {{tags.FootMove}}</div>
                                                        <div class="bowlings-style" *ngIf="SessionTypeName=='Batting'">
                                                            Shot Quality : {{tags.ShotQuality}}</div>
                                                    </div>
                                                    <div class="analyse-play-icon">
                                                        <img src="assets/images/play.png">
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="secondary-tab-container graphs" style="display:none;">
                                        <div class="profile-with-tab">
                                            <div class="main-tab" rel="analyse-graph">
                                                <a href="javascript:void(0);" class="select" rel="wagonwheel"
                                                    (click)="GraphsLoad()">Wagon
                                                    Wheel</a>
                                                <a href="javascript:void(0);" rel="pitchmap"
                                                    (click)="GraphslistLoad('PitchMap')">Pitch Map</a>
                                                <a href="javascript:void(0);" rel="impact"
                                                    (click)="GraphslistLoad('Impact')">Impact</a>
                                            </div>
                                            <div class="analyse-graph">
                                                <div class="main-tab-container wagonwheel" style="display:block;">
                                                    <div class="txt-center">
                                                        <!-- <img
                                                                src="assets/images/RHWagonWheel-Small.png"> -->
                                                        <svg *ngIf="!isRHB" version="1.1" id="wagonwheeldata"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                            width="284px" height="284px" viewBox="0 0 284 284"
                                                            enable-background="new 0 0 284 284" xml:space="preserve">
                                                            <path fill="#379300"
                                                                d="M142.105,201.471c17.409-0.168,32.654-9.544,41.081-23.49l-41.081-55.623V201.471z" />
                                                            <path fill="#379300"
                                                                d="M192.516,126.773v7.599l83.165,22.761c0.556-4.929,0.852-9.938,0.852-15.015
                                    c0-31.07-10.695-59.64-28.6-82.237L183.156,97.42C189.041,105.721,192.516,115.846,192.516,126.773z" />
                                                            <path fill="#379300" d="M141.159,75.902V9.621c-41.485,0.873-78.277,20.805-101.98,51.396l61.48,35.629
                                    C109.845,84.187,124.564,76.055,141.159,75.902z" />
                                                            <path fill="#379300"
                                                                d="M90.749,126.773c0-10.927,3.474-21.052,9.359-29.353l-61.51-35.646
                                    c-17.018,22.291-27.133,50.135-27.133,80.344c0,4.646,0.242,9.236,0.708,13.759l78.575-21.505V126.773z" />
                                                            <path fill="#379300"
                                                                d="M180.55,97.836c-8.764-11.756-22.722-19.416-38.444-19.568v41.847L180.55,97.836z" />
                                                            <path fill="#379300"
                                                                d="M190.149,133.724v-6.951c0-10.496-3.361-20.215-9.048-28.162l-38.252,22.168L190.149,133.724z" />
                                                            <path fill="#379300"
                                                                d="M183.702,177.085c4.093-7.111,6.447-15.343,6.447-24.119v-18.26l-47.31-12.948L183.702,177.085z" />
                                                            <path fill="#379300"
                                                                d="M141.159,78.269c-15.722,0.152-29.68,7.812-38.444,19.568l38.444,22.279V78.269z" />
                                                            <path fill="#379300" d="M182.605,96.645l64.726-37.51c-24.292-30.21-61.551-49.55-103.333-49.55c-0.633,0-1.262,0.015-1.893,0.024
                                    v66.293C158.701,76.055,173.42,84.187,182.605,96.645z" />
                                                            <path fill="#379300" d="M98.576,180.032L54.433,239.8c22.939,21.045,53.31,34.113,86.726,34.816v-70.778
                                    C123.241,203.672,107.515,194.201,98.576,180.032z" />
                                                            <path fill="#379300" d="M142.105,203.838v70.79c0.631,0.009,1.26,0.024,1.893,0.024c33.162,0,63.479-12.18,86.722-32.309
                                    l-46.026-62.319C175.756,194.198,160.027,203.672,142.105,203.838z" />
                                                            <path fill="#379300"
                                                                d="M102.164,98.611c-5.687,7.947-9.048,17.667-9.048,28.162v6.951l47.3-12.946L102.164,98.611z" />
                                                            <path fill="#379300" d="M192.516,135.354v17.612c0,9.575-2.662,18.539-7.28,26.196l46.196,62.549
                                    c23.922-21.018,40.205-50.514,44.159-83.797l-0.045,0.164L192.516,135.354z" />
                                                            <path fill="#379300"
                                                                d="M93.115,134.706v18.26c0,8.78,2.357,17.016,6.452,24.128l40.872-55.341L93.115,134.706z" />
                                                            <path fill="#379300"
                                                                d="M100.083,177.99c8.428,13.941,23.67,23.313,41.075,23.481v-79.096L100.083,177.99z" />
                                                            <path fill="#379300" d="M90.749,152.966v-17.613l-78.467,21.476c3.578,32.393,18.827,61.264,41.454,82.322l44.298-59.979
                                    C93.413,171.512,90.749,162.545,90.749,152.966z" />
                                                            <path fill="#62A835" d="M190.149,134.706v-0.982l-47.3-12.946l38.252-22.168c-0.184-0.258-0.362-0.521-0.551-0.775l-38.444,22.279
                                    V78.269c-0.158-0.001-0.314-0.012-0.473-0.012s-0.315,0.01-0.473,0.012v41.847l-38.444-22.279c-0.189,0.254-0.367,0.517-0.551,0.774
                                    l38.252,22.168l-47.3,12.946v0.982l47.325-12.952l-40.872,55.341c0.172,0.298,0.338,0.601,0.516,0.895l41.075-55.616v79.096
                                    c0.158,0.001,0.314,0.012,0.473,0.012s0.315-0.01,0.473-0.012v-79.113l41.081,55.623c0.178-0.294,0.344-0.597,0.516-0.896
                                    l-40.863-55.328L190.149,134.706z" />
                                                            <path fill="#B7AE48" d="M183.156,97.42c-0.183-0.259-0.363-0.52-0.551-0.774c-9.185-12.458-23.904-20.591-40.5-20.743
                                    c-0.158-0.001-0.314-0.012-0.473-0.012s-0.315,0.01-0.473,0.012c-16.595,0.153-31.314,8.285-40.5,20.743
                                    c-0.188,0.255-0.368,0.516-0.551,0.775c-5.885,8.301-9.359,18.426-9.359,29.353v7.599v0.982v17.613
                                    c0,9.579,2.664,18.546,7.285,26.205c0.175,0.29,0.361,0.574,0.542,0.86c8.939,14.17,24.665,23.641,42.583,23.806
                                    c0.158,0.001,0.314,0.012,0.473,0.012s0.315-0.01,0.473-0.012c17.922-0.165,33.651-9.64,42.589-23.814
                                    c0.181-0.287,0.366-0.57,0.541-0.861c4.618-7.657,7.28-16.621,7.28-26.196v-17.612v-0.982v-7.599
                                    C192.516,115.846,189.041,105.721,183.156,97.42z M190.149,133.724v0.982v18.26c0,8.777-2.354,17.009-6.447,24.119
                                    c-0.172,0.299-0.338,0.601-0.516,0.896c-8.427,13.946-23.671,23.322-41.081,23.49c-0.158,0.001-0.314,0.012-0.473,0.012
                                    s-0.315-0.01-0.473-0.012c-17.406-0.168-32.648-9.54-41.075-23.481c-0.178-0.295-0.344-0.597-0.516-0.895
                                    c-4.096-7.113-6.452-15.348-6.452-24.128v-18.26v-0.982v-6.951c0-10.496,3.361-20.215,9.048-28.162
                                    c0.184-0.258,0.362-0.521,0.551-0.774c8.764-11.756,22.722-19.416,38.444-19.568c0.158-0.001,0.314-0.012,0.473-0.012
                                    s0.315,0.01,0.473,0.012c15.722,0.152,29.68,7.812,38.444,19.568c0.189,0.254,0.367,0.517,0.551,0.775
                                    c5.687,7.947,9.048,17.667,9.048,28.162V133.724z" />
                                                            <g>
                                                                <g>
                                                                    <rect x="131.501" y="115.276" fill="#AD9200"
                                                                        width="16.567" height="47.333" />
                                                                </g>
                                                            </g>
                                                            <g opacity="0.8">
                                                                <g>
                                                                    <path fill="#FFFFFF" d="M156.174,121.548h-32.778c-0.131,0-0.237-0.106-0.237-0.237s0.106-0.237,0.237-0.237h32.778
                                            c0.131,0,0.237,0.106,0.237,0.237S156.304,121.548,156.174,121.548z" />
                                                                </g>
                                                            </g>
                                                            <g display="none">
                                                                <g display="inline" opacity="0.2">
                                                                    <g>

                                                                        <rect x="24.896" y="90.25"
                                                                            transform="matrix(0.8652 0.5014 -0.5014 0.8652 57.1469 -31.1389)"
                                                                            fill="#FFFFFF" width="123.19"
                                                                            height="0.946" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline" opacity="0.2">
                                                                    <g>

                                                                        <rect x="192.604" y="29.128"
                                                                            transform="matrix(0.5011 0.8654 -0.8654 0.5011 174.8434 -121.8257)"
                                                                            fill="#FFFFFF" width="0.946"
                                                                            height="123.19" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline" opacity="0.2">
                                                                    <g>
                                                                        <rect x="139.311" y="7.889" fill="#FFFFFF"
                                                                            width="0.947" height="113.718" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline" opacity="0.2">
                                                                    <g>

                                                                        <rect x="137.32" y="139.477"
                                                                            transform="matrix(0.9645 0.264 -0.264 0.9645 44.2801 -49.6282)"
                                                                            fill="#FFFFFF" width="138.968"
                                                                            height="0.947" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline" opacity="0.2">
                                                                    <g>

                                                                        <rect x="72.291" y="70.466"
                                                                            transform="matrix(0.2636 0.9646 -0.9646 0.2636 188.5828 32.8663)"
                                                                            fill="#FFFFFF" width="0.947"
                                                                            height="138.969" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline" opacity="0.2">
                                                                    <g>
                                                                        <rect x="139.311" y="121.607" fill="#FFFFFF"
                                                                            width="0.947" height="155.845" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline" opacity="0.2">
                                                                    <g>

                                                                        <rect x="94.301" y="106.788"
                                                                            transform="matrix(0.8047 0.5937 -0.5937 0.8047 126.8988 -20.6121)"
                                                                            fill="#FFFFFF" width="0.947"
                                                                            height="151.524" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline" opacity="0.2">
                                                                    <g>

                                                                        <rect x="109.044" y="182.077"
                                                                            transform="matrix(0.5941 0.8044 -0.8044 0.5941 221.8565 -74.5591)"
                                                                            fill="#FFFFFF" width="151.524"
                                                                            height="0.947" />
                                                                    </g>
                                                                </g>
                                                                <g display="inline">
                                                                    <g>
                                                                        <path fill="#B7AE48" d="M139.784,204.52c-28.057,0-50.883-22.826-50.883-50.883v-26.193c0-28.057,22.826-50.883,50.883-50.883
                                                s50.883,22.826,50.883,50.883v26.193C190.668,181.694,167.841,204.52,139.784,204.52z M139.784,78.927
                                                c-26.752,0-48.517,21.765-48.517,48.517v26.193c0,26.752,21.765,48.517,48.517,48.517s48.517-21.765,48.517-48.517v-26.193
                                                C188.301,100.692,166.537,78.927,139.784,78.927z" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <circle fill="#4F4F4F" cx="139.784" cy="121.607"
                                                                        r="2.367" />
                                                                </g>
                                                                <g>
                                                                    <path fill="#FFFFFF"
                                                                        d="M139.784,124.211c-1.435,0-2.603-1.168-2.603-2.603s1.168-2.603,2.603-2.603
                                            c1.435,0,2.603,1.168,2.603,2.603S141.22,124.211,139.784,124.211z M139.784,119.477c-1.175,0-2.13,0.955-2.13,2.13
                                            s0.955,2.13,2.13,2.13c1.175,0,2.13-0.955,2.13-2.13S140.959,119.477,139.784,119.477z" />
                                                                </g>
                                                            </g>
                                                            <path fill="none" d="M141.632,75.889c-0.176,0-0.348,0.012-0.523,0.013V11.22C69.286,11.7,11.209,70.064,11.209,142
                                    c0,72.234,58.557,130.791,130.791,130.791S272.791,214.234,272.791,142c0-72.182-58.474-130.705-130.636-130.789v64.691
                                    C141.98,75.9,141.808,75.889,141.632,75.889z" />
                                                            <path fill="#62A835" d="M141.109,11.22v64.682c0.175-0.002,0.347-0.013,0.523-0.013c0.175,0,0.348,0.012,0.523,0.013V11.211
                                    c-0.052,0-0.103-0.002-0.155-0.002C141.702,11.209,141.407,11.218,141.109,11.22z" />
                                                            <path fill="#62A835" d="M182.605,96.645c0.188,0.255,0.368,0.516,0.551,0.774l61.946-35.899c-0.196-0.251-0.396-0.5-0.594-0.749
                                    L182.605,96.645z" />
                                                            <path fill="#62A835"
                                                                d="M254.801,55.734l-0.635-0.818l-0.003,0.002c0.212,0.272,0.419,0.548,0.629,0.821L254.801,55.734z" />
                                                            <path fill="#62A835"
                                                                d="M192.516,134.372v0.982l79.403,21.746c0.037-0.322,0.076-0.643,0.11-0.966L192.516,134.372z" />
                                                            <path fill="#62A835" d="M185.236,179.162c-0.175,0.291-0.36,0.574-0.541,0.861l44.143,59.769c0.237-0.211,0.475-0.42,0.71-0.632
                                    L185.236,179.162z" />
                                                            <path fill="#D8D261" d="M282.858,160.096l-10.939-2.996c-3.732,32.454-19.342,61.292-42.371,82.06l1.885,2.552l4.853,6.469
                                    C261.308,225.945,278.418,194.999,282.858,160.096z" />
                                                            <path fill="#62A835"
                                                                d="M142.105,272.79v-68.952c-0.158,0.001-0.314,0.012-0.473,0.012s-0.315-0.01-0.473-0.012v68.943
                                    c0.281,0.002,0.56,0.011,0.841,0.011C142.035,272.791,142.07,272.79,142.105,272.79z" />
                                                            <path fill="#D8D261" d="M142.105,283.999c35.803-0.026,68.501-13.306,93.464-35.194l-4.849-6.463l-1.883-2.55
                                    c-23.07,20.505-53.443,32.971-86.732,32.998v1.838V283.999z" />
                                                            <path fill="#62A835" d="M12.282,156.829l78.467-21.476v-0.982l-78.575,21.505l-0.228,0.056c0.034,0.318,0.073,0.634,0.109,0.952
                                    L12.282,156.829z" />
                                                            <path fill="#62A835" d="M98.576,180.032c-0.181-0.287-0.366-0.57-0.542-0.86l-44.015,59.596c0.234,0.213,0.473,0.422,0.709,0.633
                                    L98.576,180.032z" />
                                                            <path fill="#62A835" d="M100.108,97.42c0.183-0.259,0.363-0.52,0.551-0.775L39.262,61.065c-0.197,0.25-0.395,0.5-0.591,0.751
                                    L100.108,97.42z" />
                                                            <path fill="#D8D261" d="M29.677,55.127l9.502,5.889l0.083,0.048C63.043,30.924,99.8,11.496,141.109,11.22V2.655V0.011
                                    C95.774,0.29,55.482,21.815,29.677,55.127z" />
                                                            <path fill="#D8D261"
                                                                d="M29.1,55.884C10.849,79.771,0,109.616,0,142c0,5.629,0.338,11.177,0.975,16.635l10.97-2.701
                                    c-0.485-4.578-0.737-9.226-0.737-13.933c0-30.219,10.252-58.04,27.462-80.184l-0.073-0.042L29.1,55.884z" />
                                                            <path fill="#D8D261" d="M29.677,55.127c-0.194,0.251-0.384,0.504-0.577,0.756l9.498,5.89l0.073,0.042
                                    c0.196-0.252,0.394-0.501,0.591-0.751l-0.083-0.048L29.677,55.127z" />
                                                            <path fill="#D8D261" d="M54.019,238.767c-22.847-20.782-38.3-49.544-41.965-81.882l-10.963,2.696
                                    c4.297,34.777,21.161,65.657,45.918,87.952l6.727-8.383L54.019,238.767z" />
                                                            <path fill="#D8D261" d="M11.946,155.933l-10.97,2.701c0.037,0.316,0.077,0.631,0.116,0.947l10.963-2.696
                                    C12.018,156.568,11.979,156.251,11.946,155.933z" />
                                                            <path fill="#D8D261" d="M54.728,239.4l-0.295,0.4l-6.717,8.365c24.883,22.111,57.585,35.616,93.443,35.825v-9.374v-1.835
                                    C107.951,272.571,77.684,259.98,54.728,239.4z" />
                                                            <path fill="#D8D261" d="M54.728,239.4c-0.236-0.211-0.475-0.42-0.709-0.633l-0.283,0.384l-6.727,8.383
                                    c0.235,0.211,0.471,0.421,0.707,0.631l6.717-8.365L54.728,239.4z" />
                                                            <path fill="#D8D261"
                                                                d="M142.105,283.999v-9.371v-1.838c-0.035,0-0.07,0.001-0.105,0.001c-0.281,0-0.56-0.009-0.841-0.011v1.835
                                    v9.374c0.281,0.002,0.56,0.011,0.841,0.011C142.035,284,142.07,283.999,142.105,283.999z" />
                                                            <path fill="#D8D261" d="M229.548,239.16c-0.235,0.212-0.473,0.422-0.71,0.632l1.883,2.55l4.849,6.463
                                    c0.238-0.209,0.479-0.414,0.715-0.624l-4.853-6.469L229.548,239.16z" />
                                                            <path fill="#D8D261"
                                                                d="M282.967,159.143C283.643,153.523,284,147.803,284,142c0-32.448-10.891-62.348-29.207-86.26l-6.861,4.141
                                    l-2.83,1.64c17.348,22.192,27.689,50.127,27.689,80.479c0,4.776-0.263,9.49-0.762,14.134l3.652,0.999L282.967,159.143z" />
                                                            <path fill="#D8D261" d="M275.68,157.134l-3.652-0.999c-0.035,0.323-0.073,0.644-0.11,0.966l10.939,2.996
                                    c0.04-0.316,0.071-0.636,0.109-0.953L275.68,157.134z" />
                                                            <path fill="#D8D261" d="M254.164,54.919C228.213,21.542,187.697,0.051,142.155,0.002v2.64v8.569
                                    c41.475,0.048,78.424,19.405,102.352,49.561l2.824-1.636L254.164,54.919z" />
                                                            <path fill="#D8D261" d="M254.793,55.74c-0.21-0.274-0.417-0.549-0.629-0.821l-6.832,4.217l-2.824,1.636
                                    c0.198,0.25,0.398,0.498,0.594,0.749l2.83-1.64L254.793,55.74z" />
                                                            <path fill="#D8D261" d="M141.109,11.22c0.297-0.002,0.593-0.011,0.891-0.011c0.052,0,0.103,0.002,0.155,0.002V2.642v-2.64
                                    C142.103,0.002,142.052,0,142,0c-0.298,0-0.593,0.009-0.891,0.011v2.644V11.22z" />
                                                            <g opacity="0.8">
                                                                <g>
                                                                    <path fill="#FFFFFF" d="M156.174,157.167h-32.778c-0.131,0-0.237-0.106-0.237-0.237s0.106-0.237,0.237-0.237h32.778
                                            c0.131,0,0.237,0.106,0.237,0.237S156.304,157.167,156.174,157.167z" />
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path d="M9.7,138.103c-0.01,0.295-0.061,0.576-0.154,0.844c-0.092,0.267-0.226,0.51-0.401,0.729s-0.385,0.408-0.632,0.567
                                            c-0.246,0.159-0.532,0.281-0.856,0.367l-0.44-1.24c0.35-0.078,0.643-0.226,0.878-0.442c0.235-0.217,0.36-0.509,0.372-0.875
                                            c0.005-0.135-0.008-0.265-0.039-0.392c-0.031-0.126-0.079-0.24-0.143-0.342c-0.064-0.102-0.147-0.184-0.247-0.245
                                            c-0.101-0.061-0.219-0.094-0.354-0.098c-0.129-0.005-0.245,0.014-0.349,0.055c-0.104,0.042-0.203,0.114-0.297,0.217
                                            c-0.093,0.103-0.185,0.238-0.274,0.405c-0.089,0.167-0.183,0.374-0.282,0.622l-0.169,0.418c-0.07,0.184-0.163,0.369-0.279,0.555
                                            c-0.116,0.186-0.253,0.353-0.412,0.502c-0.159,0.149-0.345,0.27-0.557,0.362c-0.212,0.093-0.45,0.135-0.713,0.125
                                            c-0.27-0.009-0.52-0.071-0.751-0.184C3.37,139.938,3.17,139.78,3,139.578c-0.17-0.202-0.3-0.441-0.39-0.717
                                            c-0.091-0.276-0.13-0.581-0.119-0.915c0.012-0.347,0.069-0.645,0.171-0.896s0.228-0.462,0.378-0.633
                                            c0.151-0.172,0.314-0.309,0.491-0.413c0.177-0.103,0.344-0.178,0.5-0.224l0.443,1.163c-0.195,0.058-0.373,0.169-0.533,0.334
                                            c-0.159,0.165-0.245,0.395-0.255,0.691c-0.01,0.283,0.049,0.52,0.174,0.71c0.125,0.191,0.294,0.29,0.506,0.297
                                            c0.206,0.007,0.384-0.077,0.534-0.252c0.15-0.175,0.3-0.456,0.448-0.843l0.159-0.428c0.105-0.273,0.225-0.521,0.358-0.745
                                            c0.133-0.223,0.289-0.414,0.468-0.572c0.179-0.158,0.381-0.276,0.606-0.355c0.224-0.079,0.481-0.114,0.77-0.104
                                            c0.36,0.012,0.667,0.095,0.922,0.248c0.255,0.153,0.463,0.345,0.622,0.576c0.159,0.231,0.276,0.489,0.35,0.774
                                            C9.677,137.556,9.709,137.833,9.7,138.103z" />
                                                                    <path
                                                                        d="M9.904,130c0.078,0.232,0.133,0.475,0.166,0.729c0.033,0.255,0.039,0.516,0.017,0.785
                                            c-0.043,0.513-0.176,0.98-0.398,1.403c-0.222,0.423-0.509,0.781-0.859,1.074c-0.35,0.293-0.752,0.513-1.206,0.658
                                            s-0.93,0.198-1.43,0.156c-0.499-0.042-0.961-0.172-1.384-0.392s-0.783-0.502-1.08-0.85c-0.296-0.347-0.519-0.748-0.668-1.202
                                            c-0.149-0.454-0.202-0.937-0.159-1.45c0.043-0.512,0.175-0.98,0.398-1.403c0.222-0.423,0.508-0.781,0.859-1.074
                                            c0.35-0.293,0.752-0.512,1.206-0.658c0.454-0.146,0.93-0.197,1.43-0.156c0.532,0.044,1.016,0.185,1.454,0.422
                                            c0.438,0.237,0.805,0.548,1.102,0.934l0.832-0.462l0.563,1.015L9.904,130z M7.592,131.277l-0.573-1.016l1.196-0.654
                                            c-0.184-0.19-0.405-0.345-0.662-0.466c-0.257-0.122-0.546-0.196-0.866-0.223c-0.352-0.03-0.679,0.003-0.98,0.097
                                            c-0.301,0.094-0.563,0.237-0.785,0.428s-0.403,0.421-0.541,0.69s-0.221,0.563-0.248,0.884c-0.027,0.32,0.006,0.624,0.098,0.912
                                            c0.092,0.288,0.232,0.545,0.419,0.77c0.188,0.225,0.422,0.41,0.704,0.552c0.282,0.143,0.598,0.229,0.95,0.258
                                            c0.352,0.03,0.679-0.003,0.98-0.097c0.301-0.094,0.563-0.236,0.785-0.428c0.222-0.19,0.403-0.421,0.541-0.69
                                            c0.139-0.269,0.221-0.563,0.248-0.883c0.012-0.141,0.01-0.276-0.005-0.407c-0.015-0.13-0.04-0.258-0.074-0.384L7.592,131.277z" />
                                                                    <path d="M10.866,124.472c-0.055,0.401-0.171,0.757-0.346,1.066c-0.176,0.31-0.404,0.562-0.683,0.757
                                            c-0.28,0.195-0.605,0.332-0.976,0.41c-0.371,0.079-0.782,0.087-1.234,0.024l-4.115-0.567l0.178-1.289l4.192,0.578
                                            c0.229,0.031,0.443,0.032,0.642,0.001s0.376-0.094,0.532-0.19c0.156-0.095,0.285-0.223,0.389-0.384
                                            c0.103-0.161,0.17-0.353,0.201-0.575c0.032-0.229,0.02-0.435-0.036-0.618c-0.056-0.183-0.146-0.341-0.27-0.475
                                            c-0.124-0.134-0.278-0.243-0.461-0.326s-0.389-0.141-0.618-0.173l-4.192-0.578l0.178-1.289l4.115,0.567
                                            c0.427,0.059,0.808,0.174,1.144,0.347s0.614,0.393,0.833,0.66c0.22,0.267,0.377,0.574,0.471,0.921
                                            C10.903,123.688,10.923,124.065,10.866,124.472z" />
                                                                    <path
                                                                        d="M4.858,117.386l0.276-1.458l7.263-1.266l-0.267,1.411l-1.725,0.253l-0.494,2.614l1.514,0.865l-0.267,1.411L4.858,117.386z
                                             M9.152,116.52l-1.81,0.286l-0.87,0.091l-0.014,0.076l0.777,0.402l1.58,0.927L9.152,116.52z" />
                                                                    <path d="M5.864,112.339l0.587-2.446c0.08-0.338,0.207-0.631,0.379-0.881c0.172-0.25,0.376-0.45,0.61-0.602
                                            c0.235-0.152,0.495-0.25,0.779-0.294c0.285-0.044,0.58-0.03,0.887,0.044c0.225,0.054,0.427,0.142,0.606,0.264
                                            c0.179,0.122,0.332,0.268,0.46,0.437c0.128,0.17,0.232,0.356,0.312,0.561c0.08,0.204,0.131,0.411,0.153,0.621l0.063,0.025
                                            l3.009-1.182l0.075,0.018l-0.348,1.453l-2.937,1.13l-0.141,0.59l2.522,0.604l-0.303,1.266L5.864,112.339z M9.476,110.627
                                            c0.069-0.288,0.038-0.544-0.093-0.771c-0.131-0.226-0.331-0.372-0.6-0.436c-0.118-0.029-0.239-0.036-0.361-0.022
                                            c-0.122,0.014-0.237,0.051-0.343,0.111c-0.107,0.06-0.202,0.145-0.284,0.254c-0.082,0.109-0.142,0.242-0.18,0.398l-0.285,1.19
                                            l1.865,0.447L9.476,110.627z" />
                                                                    <path d="M8.893,105.395l1.538,0.44l0.761-2.66l1.186,0.339l-0.76,2.66l1.538,0.44l0.845-2.956l1.186,0.339l-1.203,4.208
                                            l-6.635-1.898l1.203-4.207l1.187,0.339L8.893,105.395z" />
                                                                    <path
                                                                        d="M9.652,98.666l0.428-1.229l5.353,1.861l0.959-2.758l1.165,0.405l-1.387,3.988L9.652,98.666z" />
                                                                    <path d="M13.064,92.856l1.489,0.586l1.013-2.574l1.148,0.452l-1.013,2.574l1.489,0.586l1.126-2.861l1.148,0.452l-1.603,4.072
                                            l-6.422-2.528l1.603-4.072l1.148,0.452L13.064,92.856z" />
                                                                    <path d="M17.697,86.663l1.391-3.084c0.078,0.014,0.17,0.038,0.275,0.071c0.105,0.033,0.208,0.072,0.307,0.117
                                            c0.404,0.183,0.757,0.416,1.059,0.7c0.302,0.284,0.534,0.621,0.696,1.012c0.172,0.416,0.251,0.857,0.239,1.323
                                            c-0.013,0.466-0.133,0.952-0.36,1.456c-0.206,0.457-0.483,0.85-0.829,1.181c-0.346,0.33-0.733,0.579-1.161,0.745
                                            c-0.428,0.167-0.881,0.247-1.362,0.242c-0.48-0.005-0.958-0.115-1.432-0.329c-0.474-0.214-0.873-0.499-1.195-0.856
                                            c-0.322-0.357-0.562-0.75-0.72-1.181c-0.158-0.431-0.228-0.885-0.21-1.363c0.018-0.478,0.13-0.946,0.337-1.403
                                            c0.23-0.509,0.523-0.925,0.88-1.247c0.357-0.322,0.755-0.557,1.195-0.704l0.431,1.178c-0.309,0.107-0.578,0.261-0.806,0.461
                                            c-0.228,0.2-0.421,0.476-0.579,0.828c-0.129,0.287-0.201,0.582-0.215,0.886c-0.014,0.304,0.03,0.595,0.13,0.873
                                            c0.1,0.278,0.258,0.534,0.473,0.769c0.215,0.234,0.487,0.426,0.814,0.574c0.328,0.148,0.651,0.225,0.969,0.231
                                            c0.318,0.006,0.615-0.047,0.891-0.159c0.276-0.112,0.524-0.273,0.742-0.485c0.219-0.211,0.394-0.464,0.526-0.757
                                            c0.151-0.334,0.23-0.638,0.238-0.913c0.007-0.275-0.028-0.528-0.106-0.761c-0.052-0.165-0.14-0.326-0.263-0.483
                                            c-0.122-0.158-0.273-0.305-0.451-0.441l-0.892,1.977L17.697,86.663z" />
                                                                    <path d="M64.267,26.92l3.666-2.39l0.674,1.033l-2.576,1.68l0.916,1.405l2.317-1.511l0.673,1.034l-2.317,1.511l1.506,2.309
                                            l-1.09,0.711L64.267,26.92z" />
                                                                    <path
                                                                        d="M68.924,23.934l1.111-0.678l3.594,5.892l-1.111,0.678L68.924,23.934z" />
                                                                    <path
                                                                        d="M71.371,22.43l1.319-0.741l4.686,2.674l0.067-0.037l-0.718-1.122l-1.615-2.874l1.126-0.633l3.38,6.017l-1.193,0.671
                                            l-4.948-2.826l-0.067,0.038l0.718,1.122l1.742,3.101l-1.118,0.628L71.371,22.43z" />
                                                                    <path d="M79.412,19.459l0.717,1.43l2.473-1.24l0.553,1.103l-2.473,1.24l0.717,1.43l2.748-1.378l0.553,1.102l-3.911,1.962
                                            l-3.094-6.169l3.911-1.962l0.553,1.103L79.412,19.459z" />
                                                                    <path
                                                                        d="M84.889,15.457l1.194-0.517l2.253,5.201l2.68-1.161l0.49,1.132l-3.874,1.678L84.889,15.457z" />
                                                                    <path d="M91.471,14.036l0.576,1.493l2.58-0.997l0.445,1.151l-2.58,0.997l0.576,1.493l2.868-1.108l0.445,1.151L92.3,19.792
                                            l-2.487-6.438l4.082-1.577l0.444,1.151L91.471,14.036z" />
                                                                    <path d="M99.161,13.257l3.212-1.064c0.043,0.067,0.087,0.151,0.134,0.25c0.047,0.1,0.087,0.202,0.121,0.305
                                            c0.14,0.421,0.207,0.839,0.202,1.253c-0.005,0.415-0.096,0.814-0.273,1.198c-0.19,0.408-0.461,0.765-0.813,1.071
                                            c-0.352,0.306-0.79,0.546-1.315,0.72c-0.476,0.158-0.953,0.221-1.43,0.19c-0.477-0.031-0.922-0.148-1.334-0.35
                                            c-0.412-0.202-0.779-0.482-1.1-0.839c-0.321-0.357-0.564-0.783-0.728-1.277c-0.164-0.494-0.223-0.98-0.179-1.459
                                            c0.045-0.478,0.172-0.922,0.382-1.33c0.21-0.408,0.497-0.767,0.862-1.077c0.364-0.31,0.784-0.544,1.26-0.702
                                            c0.531-0.176,1.035-0.241,1.514-0.197c0.478,0.045,0.921,0.179,1.327,0.403l-0.575,1.115c-0.288-0.155-0.583-0.249-0.885-0.281
                                            c-0.302-0.032-0.636,0.012-1.002,0.134c-0.299,0.099-0.565,0.246-0.798,0.442c-0.233,0.196-0.418,0.424-0.555,0.686
                                            c-0.136,0.262-0.218,0.551-0.245,0.869c-0.027,0.317,0.016,0.646,0.129,0.988c0.113,0.342,0.275,0.631,0.486,0.869
                                            c0.211,0.238,0.451,0.421,0.72,0.548c0.269,0.128,0.555,0.201,0.859,0.218c0.304,0.018,0.608-0.024,0.913-0.125
                                            c0.348-0.115,0.625-0.263,0.833-0.444c0.208-0.18,0.37-0.378,0.489-0.593c0.086-0.15,0.145-0.324,0.178-0.521
                                            c0.033-0.197,0.039-0.407,0.019-0.631l-2.059,0.682L99.161,13.257z" />
                                                                    <path d="M173.995,14.063l1.391-5.494l-1.869-0.473l0.303-1.196l4.999,1.266l-0.303,1.196l-1.869-0.473l-1.391,5.494
                                            L173.995,14.063z" />
                                                                    <path d="M179.957,8.477l1.245,0.378l-0.801,2.638l2.776,0.843l0.802-2.638l1.245,0.378l-2.006,6.604l-1.245-0.379l0.846-2.785
                                            l-2.776-0.844l-0.846,2.785l-1.245-0.379L179.957,8.477z" />
                                                                    <path
                                                                        d="M186.711,10.599l1.231,0.422l-2.243,6.527l-1.23-0.423L186.711,10.599z" />
                                                                    <path d="M189.421,11.528l2.351,0.894c0.324,0.123,0.599,0.287,0.824,0.489c0.226,0.203,0.399,0.43,0.52,0.682
                                            c0.121,0.252,0.184,0.522,0.192,0.81c0.007,0.288-0.045,0.579-0.157,0.874c-0.082,0.216-0.196,0.405-0.339,0.567
                                            c-0.144,0.162-0.308,0.295-0.493,0.401c-0.184,0.105-0.383,0.184-0.596,0.237c-0.213,0.053-0.425,0.078-0.636,0.073l-0.033,0.06
                                            l0.787,3.136l-0.027,0.072l-1.396-0.532l-0.745-3.057l-0.568-0.216l-0.922,2.424l-1.216-0.463L189.421,11.528z M190.656,15.329
                                            c0.276,0.105,0.535,0.107,0.776,0.006c0.241-0.101,0.411-0.28,0.509-0.539c0.043-0.114,0.066-0.233,0.068-0.355
                                            c0.002-0.123-0.02-0.241-0.066-0.355c-0.046-0.114-0.118-0.218-0.216-0.314c-0.098-0.096-0.222-0.172-0.372-0.229l-1.145-0.435
                                            l-0.682,1.793L190.656,15.329z" />
                                                                    <path d="M195.235,13.771l2.138,0.934c0.5,0.219,0.917,0.492,1.251,0.82c0.333,0.328,0.581,0.694,0.741,1.097
                                            c0.16,0.403,0.231,0.832,0.211,1.286c-0.02,0.454-0.131,0.914-0.334,1.379c-0.206,0.471-0.469,0.868-0.789,1.191
                                            c-0.32,0.323-0.681,0.561-1.085,0.714c-0.404,0.153-0.839,0.219-1.306,0.197c-0.468-0.022-0.951-0.142-1.452-0.361l-2.137-0.934
                                            L195.235,13.771z M195.051,19.874c0.347,0.152,0.675,0.237,0.982,0.256c0.307,0.019,0.588-0.022,0.842-0.121
                                            c0.254-0.099,0.48-0.255,0.677-0.467c0.198-0.211,0.363-0.471,0.497-0.777c0.134-0.306,0.211-0.604,0.232-0.893
                                            c0.021-0.289-0.018-0.56-0.117-0.814c-0.1-0.254-0.261-0.487-0.483-0.7c-0.223-0.213-0.508-0.395-0.855-0.547l-0.892-0.39
                                            l-1.775,4.063L195.051,19.874z" />
                                                                    <path d="M203.835,17.656l1.134,0.597l0.166,4.216l0.068,0.036l3.57-2.249l1.143,0.601l-3.215,6.107l-1.143-0.601l1.585-3.011
                                            l0.607-0.987l-0.068-0.036l-2.994,1.921l-0.691-0.364l-0.119-3.56l-0.068-0.036l-0.471,1.059l-1.585,3.011l-1.134-0.598
                                            L203.835,17.656z" />
                                                                    <path
                                                                        d="M213.109,22.743l1.272,0.765l-1.334,7.251l-1.23-0.74l0.361-1.706l-2.28-1.371l-1.337,1.12l-1.231-0.74L213.109,22.743z
                                             M212.431,27.07l0.361-1.796l0.217-0.848l-0.066-0.04l-0.647,0.59l-1.417,1.161L212.431,27.07z" />
                                                                    <path
                                                                        d="M217.509,25.438l1.255,0.846l-0.283,5.388l0.064,0.043l0.679-1.146l1.842-2.734l1.071,0.722l-3.856,5.723l-1.135-0.765
                                            l0.301-5.69l-0.064-0.043l-0.679,1.146l-1.988,2.95l-1.063-0.716L217.509,25.438z" />
                                                                    <path
                                                                        d="M265.948,95.634l6.491-2.345l0.826,2.284c0.111,0.308,0.165,0.615,0.163,0.92c-0.003,0.305-0.059,0.59-0.168,0.855
                                            s-0.269,0.5-0.478,0.706c-0.209,0.205-0.465,0.363-0.767,0.472c-0.308,0.111-0.609,0.155-0.901,0.131
                                            c-0.292-0.024-0.565-0.104-0.819-0.237c-0.253-0.134-0.478-0.318-0.675-0.551c-0.197-0.233-0.351-0.503-0.462-0.812l-0.383-1.06
                                            l-2.384,0.862L265.948,95.634z M270.325,96.656c0.116,0.32,0.294,0.529,0.534,0.627c0.24,0.098,0.478,0.104,0.714,0.018
                                            c0.115-0.041,0.22-0.101,0.314-0.18c0.094-0.079,0.168-0.174,0.22-0.285c0.052-0.111,0.08-0.236,0.085-0.374
                                            c0.005-0.139-0.023-0.289-0.081-0.453l-0.39-1.078l-1.786,0.645L270.325,96.656z" />
                                                                    <path d="M268.63,104.001c-0.15-0.492-0.2-0.975-0.15-1.451c0.049-0.476,0.183-0.914,0.399-1.316
                                            c0.216-0.402,0.508-0.755,0.876-1.059c0.367-0.304,0.791-0.529,1.27-0.675c0.479-0.146,0.956-0.196,1.431-0.149
                                            c0.475,0.046,0.914,0.176,1.318,0.389c0.404,0.212,0.759,0.502,1.066,0.869c0.306,0.367,0.535,0.796,0.685,1.287
                                            c0.15,0.491,0.2,0.975,0.151,1.451c-0.05,0.475-0.183,0.914-0.399,1.316c-0.217,0.402-0.508,0.755-0.876,1.059
                                            c-0.367,0.304-0.791,0.528-1.27,0.675c-0.48,0.147-0.957,0.196-1.431,0.15c-0.474-0.047-0.914-0.177-1.318-0.389
                                            c-0.404-0.213-0.76-0.502-1.066-0.869C269.009,104.921,268.78,104.492,268.63,104.001z M269.81,103.641
                                            c0.094,0.307,0.237,0.578,0.429,0.811c0.192,0.233,0.417,0.42,0.675,0.56c0.258,0.14,0.544,0.224,0.858,0.252
                                            c0.314,0.028,0.64-0.009,0.979-0.112c0.338-0.104,0.629-0.255,0.874-0.453c0.245-0.199,0.435-0.428,0.571-0.688
                                            c0.136-0.26,0.218-0.54,0.247-0.841c0.029-0.301-0.004-0.606-0.098-0.913c-0.094-0.307-0.237-0.578-0.429-0.811
                                            c-0.192-0.233-0.417-0.42-0.675-0.56s-0.544-0.224-0.858-0.252c-0.314-0.029-0.641,0.009-0.979,0.112
                                            c-0.338,0.103-0.63,0.254-0.875,0.453c-0.245,0.199-0.435,0.428-0.571,0.688c-0.136,0.26-0.218,0.54-0.247,0.842
                                            C269.684,103.029,269.716,103.333,269.81,103.641z" />
                                                                    <path
                                                                        d="M276.717,106.613l0.33,1.259l-6.676,1.75l-0.33-1.259L276.717,106.613z" />
                                                                    <path
                                                                        d="M277.458,109.401l0.331,1.476l-3.909,3.72l0.017,0.075l1.281-0.366l3.217-0.72l0.282,1.26l-6.735,1.508l-0.299-1.335
                                            l4.129-3.927l-0.017-0.075l-1.281,0.366l-3.471,0.777l-0.28-1.251L277.458,109.401z" />
                                                                    <path d="M272.437,119.094l5.583-0.976l-0.332-1.899l1.215-0.213l0.888,5.08l-1.215,0.212l-0.332-1.899l-5.583,0.976
                                            L272.437,119.094z" />
                                                                </g>
                                                                <g>
                                                                    <path d="M13.829,177.087l0.4,1.268l-2.954,3.227l0.024,0.076l4.271,0.949l0.403,1.277l-6.825,2.152l-0.403-1.277l3.365-1.061
                                            l1.168-0.284l-0.024-0.076l-3.607-0.77l-0.243-0.772l2.509-2.709l-0.024-0.076l-1.12,0.437l-3.365,1.061l-0.4-1.267
                                            L13.829,177.087z" />
                                                                    <path
                                                                        d="M16.465,185.077l0.471,1.265l-6.706,2.499l-0.471-1.264L16.465,185.077z" />
                                                                    <path d="M17.385,187.593l0.936,2.23c0.22,0.522,0.337,1.026,0.353,1.511c0.016,0.485-0.061,0.936-0.228,1.353
                                            c-0.168,0.417-0.422,0.79-0.762,1.116c-0.34,0.327-0.752,0.593-1.237,0.796c-0.491,0.207-0.973,0.317-1.444,0.33
                                            c-0.471,0.014-0.913-0.066-1.326-0.24c-0.413-0.174-0.787-0.436-1.121-0.787c-0.336-0.35-0.612-0.787-0.832-1.309l-0.936-2.23
                                            L17.385,187.593z M12.879,192.043c0.152,0.362,0.337,0.661,0.555,0.894c0.217,0.234,0.457,0.404,0.718,0.511
                                            c0.262,0.107,0.543,0.152,0.842,0.134c0.3-0.018,0.61-0.093,0.929-0.227s0.59-0.302,0.813-0.504
                                            c0.222-0.202,0.387-0.434,0.494-0.695c0.107-0.262,0.153-0.552,0.138-0.871c-0.015-0.319-0.098-0.66-0.251-1.022l-0.391-0.93
                                            l-4.239,1.78L12.879,192.043z" />
                                                                    <path d="M20.595,195.149l0.65,1.273l-3.215,2.889l-0.612,0.493l0.037,0.071l0.781-0.164l3.334-0.548l0.573,1.121l-2.393,2.39
                                            l-0.586,0.524l0.037,0.071l0.745-0.212l4.193-0.976l0.65,1.273l-7.236,1.566l-0.591-1.157l2.561-2.566l0.608-0.546l-0.037-0.071
                                            l-0.799,0.172l-3.58,0.572l-0.591-1.157L20.595,195.149z" />
                                                                    <path
                                                                        d="M25.223,203.984l0.664,1.175l-6.229,3.523l-0.664-1.175L25.223,203.984z" />
                                                                    <path d="M24.622,214.731c-0.548-0.079-1.042-0.266-1.482-0.559c-0.441-0.294-0.815-0.693-1.124-1.2
                                            c-0.278-0.455-0.453-0.928-0.526-1.418c-0.073-0.49-0.054-0.964,0.057-1.422c0.111-0.458,0.311-0.888,0.6-1.291
                                            c0.289-0.403,0.658-0.741,1.108-1.015s0.919-0.448,1.409-0.52c0.49-0.073,0.964-0.054,1.422,0.057
                                            c0.458,0.111,0.888,0.311,1.291,0.6c0.403,0.289,0.743,0.661,1.021,1.116c0.298,0.49,0.476,0.972,0.532,1.449
                                            c0.056,0.477,0.011,0.959-0.136,1.447l-1.279-0.332c0.099-0.31,0.14-0.622,0.121-0.934s-0.127-0.63-0.325-0.955
                                            c-0.173-0.284-0.386-0.521-0.638-0.711c-0.252-0.19-0.526-0.321-0.82-0.395c-0.295-0.074-0.605-0.086-0.93-0.036
                                            c-0.324,0.049-0.646,0.171-0.965,0.366c-0.318,0.194-0.575,0.424-0.767,0.69c-0.193,0.266-0.324,0.547-0.393,0.843
                                            c-0.069,0.296-0.078,0.599-0.024,0.91c0.053,0.311,0.166,0.609,0.34,0.893c0.208,0.342,0.462,0.602,0.763,0.781
                                            c0.301,0.18,0.642,0.294,1.022,0.342L24.622,214.731z" />
                                                                    <path
                                                                        d="M30.27,212.383l0.754,1.119l-2.544,1.715l0.045,0.067l4.008,0.455l0.911,1.351l-0.066,0.045l-3.9-0.492l-1.75,4.627
                                            l-0.066,0.045l-0.922-1.367l1.337-3.469l-1.245-0.149l-1.74,1.174l-0.754-1.119L30.27,212.383z" />
                                                                    <path d="M33.748,219.665l-1.337,0.982l1.698,2.312l-1.031,0.757l-1.698-2.312l-1.337,0.982l1.887,2.57l-1.031,0.757l-2.686-3.657
                                            l5.767-4.236l2.686,3.657l-1.031,0.758L33.748,219.665z" />
                                                                    <path d="M32.695,228.014l4.603-3.654l-1.243-1.566l1.002-0.796l3.324,4.188l-1.002,0.796l-1.243-1.566l-4.603,3.653
                                            L32.695,228.014z" />
                                                                    <path
                                                                        d="M71.168,254.25l1.132,0.734l-3.195,4.933l2.542,1.646l-0.695,1.074l-3.674-2.38L71.168,254.25z" />
                                                                    <path d="M74.669,265.031c-0.461-0.267-0.841-0.599-1.139-0.994c-0.299-0.396-0.508-0.823-0.627-1.281
                                            c-0.12-0.458-0.147-0.932-0.083-1.422c0.064-0.49,0.227-0.96,0.487-1.41c0.26-0.45,0.587-0.825,0.98-1.124
                                            c0.393-0.3,0.818-0.512,1.275-0.637c0.457-0.124,0.931-0.155,1.423-0.094c0.492,0.061,0.968,0.226,1.43,0.493
                                            c0.461,0.267,0.841,0.599,1.139,0.995c0.298,0.396,0.507,0.823,0.627,1.281c0.119,0.458,0.147,0.932,0.083,1.422
                                            c-0.064,0.49-0.227,0.96-0.487,1.41c-0.26,0.45-0.587,0.825-0.98,1.125c-0.393,0.3-0.818,0.512-1.275,0.636
                                            c-0.457,0.124-0.931,0.156-1.423,0.094C75.607,265.462,75.131,265.298,74.669,265.031z M75.31,263.924
                                            c0.288,0.167,0.588,0.271,0.899,0.312c0.311,0.042,0.614,0.025,0.908-0.052c0.294-0.076,0.57-0.214,0.829-0.415
                                            c0.258-0.201,0.48-0.459,0.663-0.777c0.184-0.317,0.298-0.638,0.343-0.962c0.045-0.324,0.027-0.633-0.053-0.926
                                            s-0.216-0.564-0.407-0.813c-0.191-0.249-0.43-0.457-0.719-0.624c-0.288-0.167-0.588-0.271-0.899-0.312
                                            c-0.311-0.042-0.614-0.024-0.908,0.052c-0.294,0.076-0.571,0.214-0.829,0.415c-0.259,0.201-0.48,0.459-0.663,0.777
                                            c-0.184,0.317-0.298,0.637-0.343,0.962c-0.045,0.324-0.027,0.633,0.053,0.925c0.08,0.293,0.216,0.564,0.407,0.813
                                            C74.782,263.549,75.022,263.757,75.31,263.924z" />
                                                                    <path
                                                                        d="M82.399,260.993l1.402,0.704l0.419,5.579l0.071,0.036l0.548-1.268l1.534-3.054l1.197,0.601l-3.212,6.395l-1.268-0.637
                                            l-0.441-5.892l-0.072-0.036l-0.547,1.268l-1.655,3.295l-1.188-0.596L82.399,260.993z" />
                                                                    <path d="M90.462,268.401l3.223,1.386c-0.013,0.081-0.037,0.177-0.069,0.286s-0.071,0.216-0.116,0.32
                                            c-0.182,0.422-0.417,0.793-0.706,1.111c-0.289,0.318-0.635,0.565-1.036,0.74c-0.428,0.186-0.884,0.276-1.368,0.271
                                            c-0.484-0.005-0.989-0.121-1.515-0.347c-0.477-0.205-0.89-0.485-1.239-0.838c-0.349-0.353-0.613-0.75-0.793-1.19
                                            c-0.181-0.44-0.273-0.91-0.276-1.408c-0.003-0.498,0.102-0.995,0.315-1.491c0.213-0.496,0.502-0.914,0.866-1.254
                                            c0.364-0.34,0.768-0.596,1.212-0.768c0.444-0.172,0.913-0.252,1.41-0.242c0.496,0.011,0.983,0.118,1.46,0.324
                                            c0.533,0.229,0.969,0.526,1.309,0.89c0.34,0.364,0.59,0.773,0.751,1.226l-1.213,0.468c-0.117-0.318-0.282-0.594-0.493-0.826
                                            c-0.212-0.233-0.502-0.428-0.869-0.586c-0.3-0.129-0.608-0.198-0.923-0.207c-0.315-0.009-0.616,0.042-0.902,0.15
                                            c-0.286,0.109-0.549,0.277-0.788,0.504c-0.239,0.227-0.433,0.512-0.58,0.855c-0.147,0.343-0.221,0.679-0.222,1.009
                                            c0,0.33,0.06,0.637,0.181,0.921c0.121,0.284,0.293,0.538,0.516,0.76c0.223,0.223,0.488,0.4,0.794,0.532
                                            c0.349,0.15,0.666,0.227,0.951,0.23s0.548-0.038,0.788-0.123c0.17-0.058,0.335-0.151,0.496-0.282
                                            c0.161-0.13,0.311-0.289,0.449-0.476l-2.065-0.889L90.462,268.401z" />
                                                                    <path d="M98.601,275.892c-0.505-0.171-0.943-0.42-1.313-0.749c-0.371-0.329-0.66-0.706-0.868-1.132
                                            c-0.208-0.425-0.329-0.885-0.363-1.378c-0.034-0.493,0.032-0.986,0.199-1.478c0.166-0.492,0.412-0.924,0.738-1.296
                                            c0.326-0.372,0.7-0.664,1.124-0.876c0.423-0.212,0.882-0.337,1.376-0.374c0.494-0.037,0.994,0.03,1.499,0.2
                                            c0.505,0.171,0.943,0.421,1.314,0.749c0.37,0.329,0.66,0.706,0.868,1.132c0.208,0.426,0.329,0.885,0.363,1.378
                                            c0.034,0.493-0.032,0.986-0.199,1.479c-0.166,0.492-0.412,0.924-0.738,1.296s-0.7,0.664-1.124,0.876
                                            c-0.423,0.212-0.882,0.337-1.376,0.374C99.606,276.129,99.106,276.062,98.601,275.892z M99.01,274.68
                                            c0.316,0.106,0.63,0.149,0.943,0.129c0.313-0.021,0.606-0.098,0.88-0.231c0.273-0.133,0.517-0.323,0.731-0.571
                                            c0.214-0.248,0.379-0.545,0.496-0.893c0.117-0.347,0.165-0.684,0.146-1.011c-0.02-0.327-0.098-0.625-0.235-0.897
                                            c-0.137-0.271-0.324-0.51-0.56-0.716c-0.236-0.206-0.513-0.363-0.828-0.469c-0.316-0.107-0.63-0.15-0.943-0.129
                                            c-0.313,0.021-0.606,0.098-0.88,0.23c-0.274,0.133-0.517,0.323-0.731,0.571c-0.214,0.248-0.379,0.545-0.496,0.893
                                            c-0.117,0.348-0.166,0.685-0.146,1.011c0.02,0.327,0.098,0.626,0.235,0.897c0.137,0.271,0.324,0.51,0.56,0.716
                                            C98.419,274.417,98.695,274.573,99.01,274.68z" />
                                                                    <path
                                                                        d="M105.361,270.395l1.515,0.409l1.528,5.382l0.077,0.021l0.282-1.353l0.892-3.3l1.293,0.349l-1.867,6.908l-1.37-0.37
                                            l-1.612-5.685l-0.077-0.021l-0.282,1.353l-0.962,3.56l-1.283-0.347L105.361,270.395z" />
                                                                    <path
                                                                        d="M167.765,272.977l1.321-0.277l1.206,5.751l2.964-0.622l0.263,1.252l-4.284,0.899L167.765,272.977z" />
                                                                    <path d="M177.83,278.167c-0.515,0.135-1.019,0.168-1.509,0.097c-0.49-0.071-0.939-0.227-1.347-0.468
                                            c-0.408-0.241-0.761-0.558-1.061-0.951c-0.3-0.393-0.515-0.841-0.648-1.344c-0.132-0.503-0.164-0.999-0.096-1.488
                                            c0.068-0.49,0.22-0.94,0.457-1.35c0.237-0.41,0.551-0.766,0.943-1.069s0.846-0.522,1.362-0.657
                                            c0.515-0.135,1.018-0.167,1.509-0.097c0.49,0.071,0.939,0.227,1.347,0.468c0.408,0.241,0.761,0.558,1.061,0.951
                                            c0.3,0.393,0.515,0.841,0.648,1.344c0.132,0.502,0.164,0.999,0.096,1.488s-0.22,0.94-0.457,1.349
                                            c-0.237,0.41-0.551,0.767-0.943,1.07C178.799,277.812,178.345,278.032,177.83,278.167z M177.505,276.929
                                            c0.322-0.085,0.608-0.221,0.858-0.411c0.25-0.19,0.453-0.415,0.608-0.676c0.155-0.261,0.254-0.554,0.296-0.879
                                            c0.043-0.324,0.017-0.664-0.076-1.018c-0.093-0.355-0.238-0.663-0.434-0.925c-0.196-0.262-0.426-0.468-0.69-0.619
                                            c-0.264-0.151-0.551-0.248-0.862-0.291c-0.311-0.043-0.627-0.021-0.949,0.063c-0.323,0.085-0.608,0.222-0.858,0.411
                                            c-0.25,0.19-0.453,0.415-0.608,0.676c-0.155,0.261-0.254,0.554-0.296,0.878c-0.043,0.325-0.017,0.664,0.076,1.019
                                            c0.093,0.355,0.238,0.663,0.434,0.924c0.196,0.262,0.426,0.469,0.69,0.62c0.264,0.151,0.551,0.248,0.862,0.29
                                            C176.866,277.035,177.182,277.014,177.505,276.929z" />
                                                                    <path
                                                                        d="M180.475,269.855l1.492-0.487l4.226,3.666l0.076-0.025l-0.504-1.286l-1.062-3.249l1.273-0.416l2.223,6.802l-1.349,0.441
                                            l-4.462-3.873l-0.076,0.025l0.504,1.286l1.146,3.506l-1.263,0.413L180.475,269.855z" />
                                                                    <path d="M191.427,269.435l3.265-1.283c0.048,0.067,0.098,0.151,0.153,0.251c0.054,0.101,0.102,0.203,0.143,0.309
                                            c0.168,0.428,0.262,0.857,0.28,1.286c0.018,0.429-0.053,0.848-0.215,1.255c-0.173,0.433-0.434,0.819-0.781,1.155
                                            c-0.348,0.337-0.788,0.61-1.321,0.82c-0.483,0.19-0.973,0.282-1.469,0.277c-0.496-0.006-0.963-0.101-1.402-0.287
                                            c-0.438-0.186-0.833-0.455-1.187-0.807c-0.353-0.351-0.628-0.778-0.826-1.281c-0.197-0.502-0.286-1.003-0.267-1.5
                                            c0.019-0.498,0.126-0.964,0.32-1.398c0.195-0.435,0.472-0.822,0.832-1.164c0.36-0.342,0.781-0.607,1.265-0.797
                                            c0.539-0.212,1.058-0.308,1.556-0.289c0.498,0.019,0.963,0.134,1.396,0.343l-0.533,1.186c-0.307-0.144-0.618-0.224-0.932-0.24
                                            c-0.314-0.016-0.657,0.049-1.029,0.195c-0.304,0.119-0.571,0.287-0.802,0.502c-0.23,0.216-0.409,0.463-0.535,0.742
                                            c-0.127,0.279-0.195,0.583-0.205,0.913c-0.01,0.33,0.053,0.668,0.19,1.016c0.136,0.347,0.321,0.638,0.552,0.873
                                            c0.232,0.235,0.49,0.41,0.776,0.527c0.286,0.117,0.587,0.176,0.902,0.177c0.316,0.001,0.628-0.059,0.938-0.181
                                            c0.354-0.139,0.633-0.307,0.838-0.506c0.205-0.198,0.362-0.412,0.472-0.642c0.08-0.16,0.132-0.343,0.155-0.549
                                            c0.023-0.206,0.018-0.424-0.015-0.655l-2.093,0.822L191.427,269.435z" />
                                                                    <path
                                                                        d="M202.467,269.047c-0.479,0.233-0.967,0.363-1.461,0.389c-0.495,0.026-0.966-0.039-1.413-0.196
                                            c-0.447-0.157-0.856-0.399-1.226-0.726c-0.371-0.327-0.669-0.724-0.897-1.192c-0.227-0.468-0.355-0.948-0.385-1.441
                                            c-0.029-0.494,0.033-0.965,0.185-1.413c0.152-0.448,0.391-0.859,0.717-1.232c0.326-0.373,0.728-0.677,1.208-0.91
                                            c0.479-0.233,0.967-0.363,1.462-0.389s0.966,0.04,1.413,0.196c0.447,0.157,0.856,0.399,1.226,0.726s0.669,0.724,0.897,1.192
                                            c0.227,0.467,0.355,0.948,0.385,1.441c0.029,0.494-0.033,0.965-0.185,1.413c-0.153,0.448-0.392,0.859-0.717,1.232
                                            C203.349,268.51,202.946,268.814,202.467,269.047z M201.907,267.896c0.3-0.146,0.554-0.336,0.762-0.57
                                            c0.208-0.235,0.363-0.495,0.465-0.782c0.101-0.287,0.141-0.593,0.119-0.919c-0.022-0.327-0.113-0.655-0.273-0.984
                                            c-0.16-0.33-0.362-0.604-0.606-0.822c-0.244-0.219-0.509-0.377-0.797-0.474c-0.288-0.097-0.588-0.136-0.902-0.117
                                            c-0.313,0.019-0.619,0.101-0.919,0.247c-0.3,0.146-0.553,0.336-0.761,0.57c-0.208,0.235-0.363,0.496-0.465,0.782
                                            c-0.102,0.287-0.141,0.593-0.12,0.919c0.022,0.327,0.113,0.655,0.273,0.984c0.16,0.33,0.362,0.604,0.606,0.822
                                            c0.244,0.219,0.509,0.377,0.797,0.474c0.288,0.097,0.588,0.136,0.901,0.117C201.302,268.124,201.608,268.042,201.907,267.896z" />
                                                                    <path d="M203.447,260.356l3.969-2.199l0.62,1.119l-2.789,1.545l0.843,1.521l2.509-1.39l0.62,1.119l-2.509,1.39l1.385,2.5
                                            l-1.18,0.654L203.447,260.356z" />
                                                                    <path d="M208.204,257.688l3.872-2.365l0.667,1.092l-2.721,1.662l0.907,1.484l2.448-1.495l0.667,1.091l-2.448,1.496l1.49,2.439
                                            l-1.151,0.703L208.204,257.688z" />
                                                                    <path d="M257.07,219.535c0.142,0.535,0.165,1.063,0.068,1.583c-0.097,0.52-0.318,1.022-0.662,1.504
                                            c-0.309,0.434-0.675,0.781-1.098,1.04c-0.422,0.259-0.866,0.428-1.33,0.505c-0.465,0.078-0.939,0.063-1.423-0.045
                                            c-0.484-0.108-0.94-0.315-1.369-0.62c-0.428-0.306-0.772-0.67-1.032-1.092c-0.259-0.422-0.428-0.866-0.505-1.33
                                            c-0.078-0.465-0.063-0.939,0.045-1.423c0.108-0.484,0.317-0.943,0.626-1.377c0.333-0.466,0.707-0.819,1.123-1.059
                                            c0.416-0.239,0.878-0.387,1.384-0.443l0.197,1.307c-0.324,0.031-0.627,0.116-0.907,0.256c-0.28,0.14-0.53,0.365-0.751,0.674
                                            c-0.193,0.271-0.328,0.56-0.403,0.866c-0.075,0.306-0.089,0.61-0.042,0.91c0.048,0.3,0.159,0.59,0.332,0.869
                                            s0.412,0.527,0.716,0.744c0.304,0.217,0.616,0.361,0.936,0.435c0.32,0.073,0.63,0.083,0.93,0.031
                                            c0.299-0.052,0.581-0.164,0.847-0.335c0.265-0.171,0.494-0.392,0.688-0.663c0.232-0.325,0.372-0.661,0.419-1.009
                                            c0.047-0.347,0.018-0.705-0.086-1.075L257.07,219.535z" />
                                                                    <path d="M260.64,216.436c-0.287,0.449-0.635,0.814-1.043,1.095c-0.409,0.281-0.845,0.471-1.307,0.57
                                            c-0.463,0.1-0.938,0.107-1.425,0.021c-0.487-0.086-0.949-0.268-1.387-0.548c-0.438-0.28-0.798-0.623-1.081-1.028
                                            c-0.282-0.406-0.476-0.839-0.58-1.302c-0.104-0.462-0.115-0.937-0.032-1.426c0.083-0.489,0.267-0.958,0.554-1.407
                                            c0.287-0.449,0.635-0.814,1.043-1.095c0.408-0.281,0.844-0.471,1.307-0.57c0.463-0.1,0.938-0.107,1.425-0.021
                                            c0.487,0.086,0.949,0.269,1.388,0.548c0.438,0.28,0.798,0.622,1.081,1.028c0.282,0.406,0.476,0.839,0.58,1.301
                                            c0.104,0.462,0.115,0.937,0.032,1.426C261.112,215.518,260.927,215.987,260.64,216.436z M259.562,215.748
                                            c0.179-0.281,0.296-0.575,0.351-0.885c0.055-0.309,0.051-0.612-0.012-0.909c-0.063-0.297-0.19-0.58-0.379-0.847
                                            c-0.189-0.267-0.438-0.499-0.747-0.696c-0.309-0.197-0.624-0.325-0.946-0.385c-0.322-0.059-0.631-0.055-0.927,0.013
                                            c-0.296,0.068-0.573,0.191-0.83,0.371c-0.257,0.18-0.475,0.41-0.655,0.691c-0.179,0.281-0.297,0.575-0.351,0.884
                                            c-0.055,0.309-0.051,0.612,0.012,0.909c0.063,0.297,0.19,0.579,0.379,0.846c0.19,0.267,0.439,0.499,0.747,0.697
                                            c0.309,0.197,0.624,0.325,0.946,0.385c0.322,0.059,0.631,0.055,0.927-0.013c0.296-0.067,0.573-0.191,0.83-0.371
                                            C259.165,216.259,259.383,216.029,259.562,215.748z" />
                                                                    <path d="M256.508,209.292l0.725-1.267l4.474,0.855l0.927,0.231l0.04-0.069l-0.659-0.7l-2.975-3.475l0.725-1.267l4.916,5.818
                                            l-0.705,1.232L256.508,209.292z" />
                                                                    <path d="M261.838,202.354l1.474,0.761l1.316-2.549l1.137,0.587l-1.316,2.549l1.474,0.761l1.463-2.833l1.137,0.587l-2.082,4.032
                                            l-6.359-3.283l2.082-4.032l1.137,0.587L261.838,202.354z" />
                                                                    <path d="M262.658,197.929l1.098-2.366c0.152-0.326,0.34-0.599,0.566-0.817c0.227-0.218,0.474-0.38,0.744-0.487
                                            c0.27-0.106,0.554-0.153,0.852-0.139c0.299,0.014,0.596,0.089,0.892,0.227c0.217,0.101,0.404,0.232,0.561,0.392
                                            c0.157,0.161,0.283,0.341,0.378,0.539c0.095,0.199,0.162,0.41,0.202,0.634c0.039,0.224,0.049,0.445,0.028,0.663l0.059,0.038
                                            l3.301-0.584l0.073,0.034l-0.652,1.405l-3.217,0.545l-0.265,0.571l2.439,1.132l-0.568,1.224L262.658,197.929z M266.68,196.931
                                            c0.129-0.278,0.15-0.545,0.064-0.802c-0.086-0.257-0.26-0.446-0.52-0.566c-0.115-0.053-0.236-0.086-0.363-0.097
                                            c-0.127-0.011-0.251,0.003-0.372,0.042c-0.121,0.039-0.235,0.106-0.341,0.2c-0.106,0.094-0.194,0.217-0.264,0.367l-0.534,1.151
                                            l1.804,0.837L266.68,196.931z" />
                                                                    <path d="M272.781,193.172c-0.117,0.283-0.269,0.538-0.455,0.764c-0.187,0.226-0.404,0.414-0.654,0.563
                                            c-0.249,0.149-0.522,0.257-0.819,0.322c-0.297,0.065-0.618,0.08-0.965,0.046l0.022-1.364c0.368,0.051,0.706,0.014,1.013-0.112
                                            c0.308-0.126,0.534-0.364,0.679-0.715c0.053-0.129,0.088-0.261,0.104-0.395c0.015-0.134,0.011-0.262-0.015-0.385
                                            c-0.025-0.122-0.076-0.231-0.152-0.327c-0.076-0.096-0.178-0.171-0.308-0.224c-0.123-0.051-0.243-0.075-0.359-0.073
                                            c-0.116,0.003-0.238,0.037-0.367,0.103s-0.266,0.165-0.413,0.294c-0.147,0.13-0.314,0.298-0.499,0.502l-0.315,0.346
                                            c-0.135,0.153-0.293,0.3-0.472,0.438c-0.18,0.139-0.374,0.251-0.583,0.338c-0.208,0.087-0.433,0.137-0.673,0.15
                                            c-0.239,0.013-0.485-0.032-0.738-0.136c-0.258-0.107-0.479-0.257-0.662-0.451c-0.183-0.195-0.32-0.42-0.412-0.678
                                            c-0.092-0.258-0.132-0.537-0.12-0.839c0.012-0.301,0.085-0.612,0.217-0.932c0.137-0.332,0.3-0.602,0.49-0.808
                                            c0.19-0.207,0.388-0.366,0.597-0.478s0.417-0.187,0.627-0.223c0.209-0.036,0.398-0.049,0.567-0.037l0.009,1.29
                                            c-0.21-0.014-0.423,0.03-0.638,0.132c-0.215,0.102-0.381,0.295-0.498,0.579c-0.112,0.271-0.141,0.522-0.088,0.753
                                            c0.053,0.231,0.181,0.388,0.384,0.471c0.197,0.081,0.401,0.064,0.611-0.051c0.209-0.115,0.456-0.334,0.741-0.657l0.31-0.359
                                            c0.202-0.227,0.407-0.425,0.618-0.594c0.21-0.169,0.431-0.298,0.662-0.386c0.231-0.089,0.47-0.131,0.717-0.127
                                            c0.247,0.005,0.508,0.064,0.786,0.178c0.345,0.142,0.614,0.334,0.806,0.575s0.324,0.503,0.396,0.784
                                            c0.071,0.282,0.092,0.575,0.06,0.879S272.888,192.913,272.781,193.172z" />
                                                                </g>
                                                            </g>
                                                            <!-- <line    *ngFor="let ww of lstWagonWheel;let ind=index;" id="{{'mySvgElementWagonWheel' + ind}}"
                                                                    style="stroke:rgb(255,0,0);stroke-width:2" /> -->
                                                        </svg>

                                                        <svg *ngIf="isRHB" version="1.1" id="wagonwheeldata"
                                                            version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                            width="284px" height="284px" viewBox="0 0 284 284"
                                                            enable-background="new 0 0 284 284" xml:space="preserve">

                                                            <g>
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'16')"
                                                                                d="M142.105,201.471c17.409-0.168,32.654-9.544,41.081-23.49l-41.081-55.623V201.471z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'2')"
                                                                                d="M192.516,126.773v7.599l83.165,22.761c0.556-4.929,0.852-9.938,0.852-15.015
                                           c0-31.07-10.695-59.64-28.6-82.237L183.156,97.42C189.041,105.721,192.516,115.846,192.516,126.773z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'4')" d="M141.159,75.902V9.621c-41.485,0.873-78.277,20.805-101.98,51.396l61.48,35.629
                                           C109.845,84.187,124.564,76.055,141.159,75.902z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'5')"
                                                                                d="M90.749,126.773c0-10.927,3.474-21.052,9.359-29.353l-61.51-35.646
                                           c-17.018,22.291-27.133,50.135-27.133,80.344c0,4.646,0.242,9.236,0.708,13.759l78.575-21.505V126.773z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'11')"
                                                                                d="M180.55,97.836c-8.764-11.756-22.722-19.416-38.444-19.568v41.847L180.55,97.836z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'10')"
                                                                                d="M190.149,133.724v-6.951c0-10.496-3.361-20.215-9.048-28.162l-38.252,22.168L190.149,133.724z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'9')"
                                                                                d="M183.702,177.085c4.093-7.111,6.447-15.343,6.447-24.119v-18.26l-47.31-12.948L183.702,177.085z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'12')"
                                                                                d="M141.159,78.269c-15.722,0.152-29.68,7.812-38.444,19.568l38.444,22.279V78.269z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'3')"
                                                                                d="M182.605,96.645l64.726-37.51c-24.292-30.21-61.551-49.55-103.333-49.55
                                           c-0.633,0-1.262,0.015-1.893,0.024v66.293C158.701,76.055,173.42,84.187,182.605,96.645z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'7')" d="M98.576,180.032L54.433,239.8c22.939,21.045,53.31,34.113,86.726,34.816v-70.778
                                           C123.241,203.672,107.515,194.201,98.576,180.032z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'8')" d="M142.105,203.838v70.79c0.631,0.009,1.26,0.024,1.893,0.024c33.162,0,63.479-12.18,86.722-32.309
                                           l-46.026-62.319C175.756,194.198,160.027,203.672,142.105,203.838z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'13')"
                                                                                d="M102.164,98.611c-5.687,7.947-9.048,17.667-9.048,28.162v6.951l47.3-12.946L102.164,98.611z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'1')" d="M192.516,135.354v17.612c0,9.575-2.662,18.539-7.28,26.196l46.196,62.549
                                           c23.922-21.018,40.205-50.514,44.159-83.797l-0.045,0.164L192.516,135.354z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'14')"
                                                                                d="M93.115,134.706v18.26c0,8.78,2.357,17.016,6.452,24.128l40.872-55.341L93.115,134.706z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'15')"
                                                                                d="M100.083,177.99c8.428,13.941,23.67,23.313,41.075,23.481v-79.096L100.083,177.99z" />
                                                                            <path fill="#379300"
                                                                                (click)="wwClick($event,'6')" d="M90.749,152.966v-17.613l-78.467,21.476c3.578,32.393,18.827,61.264,41.454,82.322l44.298-59.979
                                           C93.413,171.512,90.749,162.545,90.749,152.966z" />
                                                                        </g>
                                                                        <g>
                                                                            <path fill="#62A835" d="M100.108,97.42c0.183-0.259,0.363-0.52,0.551-0.775l-61.48-35.629l-3.896-2.258l-0.474,0.819
                                           l3.789,2.196L100.108,97.42z" />
                                                                            <path fill="#62A835" d="M247.932,59.881l0.523-0.303l-0.474-0.819l-0.649,0.376l-64.726,37.51
                                           c0.188,0.255,0.368,0.516,0.551,0.774L247.932,59.881z" />
                                                                            <path fill="#62A835" d="M142.105,75.902V9.609v-2.39h-0.947v2.402v66.281c0.158-0.001,0.314-0.012,0.473-0.012
                                           S141.947,75.901,142.105,75.902z" />
                                                                            <polygon fill="#62A835" points="275.68,157.134 192.516,134.372 192.516,135.354 275.546,158.078 275.591,157.914 
                                           275.796,157.165 				" />
                                                                            <path fill="#62A835"
                                                                                d="M190.149,134.706v-0.982l-47.3-12.946l38.252-22.168c-0.184-0.258-0.362-0.521-0.551-0.775
                                           l-38.444,22.279V78.269c-0.158-0.001-0.314-0.012-0.473-0.012s-0.315,0.01-0.473,0.012v41.847l-38.444-22.279
                                           c-0.189,0.254-0.367,0.517-0.551,0.774l38.252,22.168l-47.3,12.946v0.982l47.325-12.952l-40.872,55.341
                                           c0.172,0.298,0.338,0.601,0.516,0.895l41.075-55.616v79.096c0.158,0.001,0.314,0.012,0.473,0.012s0.315-0.01,0.473-0.012
                                           v-79.113l41.081,55.623c0.178-0.294,0.344-0.597,0.516-0.896l-40.863-55.328L190.149,134.706z" />
                                                                            <polygon fill="#62A835" points="90.749,134.372 12.173,155.877 7.468,157.165 7.718,158.078 12.282,156.829 90.749,135.354 				
                                           " />
                                                                            <path fill="#62A835" d="M141.159,203.838v70.778v2.166h0.947v-2.154v-70.79c-0.158,0.001-0.314,0.012-0.473,0.012
                                           S141.317,203.839,141.159,203.838z" />
                                                                            <path fill="#62A835" d="M53.736,239.151l-2.504,3.39l0.762,0.562l2.439-3.303l44.142-59.768c-0.181-0.287-0.366-0.57-0.542-0.86
                                           L53.736,239.151z" />
                                                                            <path fill="#62A835" d="M185.236,179.162c-0.175,0.291-0.36,0.574-0.541,0.861l46.026,62.319l0.562,0.761l0.762-0.562
                                           l-0.612-0.829L185.236,179.162z" />
                                                                        </g>
                                                                        <path fill="#B7AE48" d="M183.156,97.42c-0.183-0.259-0.363-0.52-0.551-0.774c-9.185-12.458-23.904-20.591-40.5-20.743
                                       c-0.158-0.001-0.314-0.012-0.473-0.012s-0.315,0.01-0.473,0.012c-16.595,0.153-31.314,8.285-40.5,20.743
                                       c-0.188,0.255-0.368,0.516-0.551,0.775c-5.885,8.301-9.359,18.426-9.359,29.353v7.599v0.982v17.613
                                       c0,9.579,2.664,18.546,7.285,26.205c0.175,0.29,0.361,0.574,0.542,0.86c8.939,14.17,24.665,23.641,42.583,23.806
                                       c0.158,0.001,0.314,0.012,0.473,0.012s0.315-0.01,0.473-0.012c17.922-0.165,33.651-9.64,42.589-23.814
                                       c0.181-0.287,0.366-0.57,0.541-0.861c4.618-7.657,7.28-16.621,7.28-26.196v-17.612v-0.982v-7.599
                                       C192.516,115.846,189.041,105.721,183.156,97.42z M190.149,133.724v0.982v18.26c0,8.777-2.354,17.009-6.447,24.119
                                       c-0.172,0.299-0.338,0.601-0.516,0.896c-8.427,13.946-23.671,23.322-41.081,23.49c-0.158,0.001-0.314,0.012-0.473,0.012
                                       s-0.315-0.01-0.473-0.012c-17.406-0.168-32.648-9.54-41.075-23.481c-0.178-0.295-0.344-0.597-0.516-0.895
                                       c-4.096-7.113-6.452-15.348-6.452-24.128v-18.26v-0.982v-6.951c0-10.496,3.361-20.215,9.048-28.162
                                       c0.184-0.258,0.362-0.521,0.551-0.774c8.764-11.756,22.722-19.416,38.444-19.568c0.158-0.001,0.314-0.012,0.473-0.012
                                       s0.315,0.01,0.473,0.012c15.722,0.152,29.68,7.812,38.444,19.568c0.189,0.254,0.367,0.517,0.551,0.775
                                       c5.687,7.947,9.048,17.667,9.048,28.162V133.724z" />
                                                                    </g>
                                                                </g>
                                                                <g>
                                                                    <g>
                                                                        <rect (click)="wwClick($event,'pitch')"
                                                                            x="131.501" y="115.276" fill="#AD9200"
                                                                            width="16.567" height="47.333" />
                                                                    </g>
                                                                </g>
                                                                <g opacity="0.8">
                                                                    <g>
                                                                        <path fill="#FFFFFF" d="M156.174,121.548h-32.778c-0.131,0-0.237-0.106-0.237-0.237s0.106-0.237,0.237-0.237h32.778
                                       c0.131,0,0.237,0.106,0.237,0.237S156.304,121.548,156.174,121.548z" />
                                                                    </g>
                                                                </g>
                                                                <g display="none">
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>

                                                                            <rect x="24.896" y="90.25"
                                                                                transform="matrix(0.8652 0.5014 -0.5014 0.8652 57.1469 -31.1389)"
                                                                                fill="#FFFFFF" width="123.19"
                                                                                height="0.946" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>

                                                                            <rect x="192.604" y="29.128"
                                                                                transform="matrix(0.5011 0.8654 -0.8654 0.5011 174.8434 -121.8257)"
                                                                                fill="#FFFFFF" width="0.946"
                                                                                height="123.19" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>
                                                                            <rect x="139.311" y="7.889" fill="#FFFFFF"
                                                                                width="0.947" height="113.718" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>

                                                                            <rect x="137.32" y="139.477"
                                                                                transform="matrix(0.9645 0.264 -0.264 0.9645 44.2801 -49.6282)"
                                                                                fill="#FFFFFF" width="138.968"
                                                                                height="0.947" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>

                                                                            <rect x="72.291" y="70.466"
                                                                                transform="matrix(0.2636 0.9646 -0.9646 0.2636 188.5828 32.8663)"
                                                                                fill="#FFFFFF" width="0.947"
                                                                                height="138.969" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>
                                                                            <rect x="139.311" y="121.607" fill="#FFFFFF"
                                                                                width="0.947" height="155.845" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>

                                                                            <rect x="94.301" y="106.788"
                                                                                transform="matrix(0.8047 0.5937 -0.5937 0.8047 126.8988 -20.6121)"
                                                                                fill="#FFFFFF" width="0.947"
                                                                                height="151.524" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline" opacity="0.2">
                                                                        <g>

                                                                            <rect x="109.044" y="182.077"
                                                                                transform="matrix(0.5941 0.8044 -0.8044 0.5941 221.8565 -74.5591)"
                                                                                fill="#FFFFFF" width="151.524"
                                                                                height="0.947" />
                                                                        </g>
                                                                    </g>
                                                                    <g display="inline">
                                                                        <g>
                                                                            <path fill="#B7AE48" d="M139.784,204.52c-28.057,0-50.883-22.826-50.883-50.883v-26.193c0-28.057,22.826-50.883,50.883-50.883
                                           s50.883,22.826,50.883,50.883v26.193C190.668,181.694,167.842,204.52,139.784,204.52z M139.784,78.927
                                           c-26.752,0-48.517,21.765-48.517,48.517v26.193c0,26.752,21.765,48.517,48.517,48.517s48.517-21.765,48.517-48.517v-26.193
                                           C188.301,100.692,166.537,78.927,139.784,78.927z" />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g>
                                                                    <g>
                                                                        <circle fill="#4F4F4F" cx="139.784" cy="121.607"
                                                                            r="2.367" />
                                                                    </g>
                                                                    <g>
                                                                        <path fill="#FFFFFF" d="M139.784,124.211c-1.435,0-2.603-1.168-2.603-2.603s1.168-2.603,2.603-2.603s2.603,1.168,2.603,2.603
                                       S141.22,124.211,139.784,124.211z M139.784,119.477c-1.175,0-2.13,0.955-2.13,2.13s0.955,2.13,2.13,2.13s2.13-0.955,2.13-2.13
                                       S140.959,119.477,139.784,119.477z" />
                                                                    </g>
                                                                </g>
                                                                <g opacity="0.8">
                                                                    <g>
                                                                        <path fill="#FFFFFF" d="M156.174,157.167h-32.778c-0.131,0-0.237-0.106-0.237-0.237s0.106-0.237,0.237-0.237h32.778
                                       c0.131,0,0.237,0.106,0.237,0.237S156.304,157.167,156.174,157.167z" />
                                                                    </g>
                                                                </g>
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <path fill="#D8D261"
                                                                                d="M142,0C63.576,0,0,63.576,0,142s63.576,142,142,142s142-63.576,142-142S220.424,0,142,0z M142,272.791
                                           c-72.234,0-130.791-58.557-130.791-130.791S69.766,11.209,142,11.209S272.791,69.766,272.791,142S214.234,272.791,142,272.791z" />
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <path fill="none" stroke="#379300"
                                                                                stroke-miterlimit="10"
                                                                                d="M138.573,5.325" />
                                                                        </g>
                                                                    </g>
                                                                    <g opacity="0">
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'1')" d="M282.858,160.096l-10.939-2.996c-3.732,32.454-19.342,61.292-42.371,82.06l1.885,2.552l4.853,6.469
                                       C261.308,225.945,278.418,194.999,282.858,160.096z" />
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'8')" d="M142.105,283.999c35.803-0.026,68.501-13.306,93.464-35.194l-4.849-6.463l-1.883-2.55
                                       c-23.07,20.505-53.443,32.971-86.732,32.998v1.838V283.999z" />
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'4')" d="M29.677,55.127l9.502,5.889l0.083,0.048C63.043,30.924,99.8,11.496,141.109,11.22V2.655V0.011
                                       C95.774,0.29,55.482,21.815,29.677,55.127z" />
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'5')"
                                                                            d="M29.1,55.884C10.849,79.771,0,109.616,0,142c0,5.629,0.338,11.177,0.975,16.635l10.97-2.701
                                       c-0.485-4.578-0.737-9.226-0.737-13.933c0-30.219,10.252-58.04,27.462-80.184l-0.073-0.042L29.1,55.884z" />
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'6')" d="M54.019,238.767c-22.847-20.782-38.3-49.544-41.965-81.882l-10.963,2.696
                                       c4.297,34.777,21.161,65.657,45.918,87.952l6.727-8.383L54.019,238.767z" />
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'7')" d="M54.728,239.4l-0.295,0.4l-6.717,8.365c24.883,22.111,57.585,35.616,93.443,35.825v-9.374v-1.835
                                       C107.951,272.571,77.684,259.98,54.728,239.4z" />
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'2')"
                                                                            d="M282.967,159.143C283.643,153.523,284,147.803,284,142c0-32.448-10.891-62.348-29.207-86.26l-6.861,4.141
                                       l-2.83,1.64c17.348,22.192,27.689,50.127,27.689,80.479c0,4.776-0.263,9.49-0.762,14.134l3.652,0.999L282.967,159.143z" />
                                                                        <path fill="#D8D261"
                                                                            (click)="wwClick($event,'3')" d="M254.164,54.919C228.213,21.542,187.697,0.051,142.155,0.002v2.64v8.569
                                       c41.475,0.048,78.424,19.405,102.352,49.561l2.824-1.636L254.164,54.919z" />
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path
                                                                                    d="M10.87,123.234l-6.811-1.116l0.393-2.397c0.053-0.324,0.155-0.618,0.306-0.883c0.151-0.265,0.339-0.486,0.563-0.664
                                               c0.225-0.178,0.478-0.306,0.761-0.383c0.283-0.077,0.583-0.09,0.9-0.038c0.323,0.053,0.606,0.161,0.85,0.325
                                               s0.443,0.366,0.599,0.606c0.156,0.24,0.263,0.51,0.321,0.81c0.058,0.299,0.061,0.611,0.008,0.934l-0.182,1.113l2.501,0.41
                                               L10.87,123.234z M7.547,120.208c0.055-0.336,0.002-0.605-0.16-0.808c-0.162-0.202-0.367-0.324-0.614-0.364
                                               c-0.12-0.02-0.241-0.018-0.362,0.004c-0.121,0.023-0.231,0.07-0.331,0.141c-0.1,0.072-0.185,0.166-0.257,0.285
                                               c-0.072,0.118-0.122,0.263-0.15,0.435l-0.185,1.132l1.874,0.307L7.547,120.208z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M12.608,114.62c-0.109,0.503-0.3,0.949-0.575,1.34c-0.275,0.391-0.605,0.71-0.99,0.955
                                               c-0.385,0.245-0.812,0.411-1.281,0.497c-0.469,0.086-0.949,0.076-1.438-0.03s-0.93-0.294-1.322-0.567
                                               c-0.392-0.272-0.712-0.599-0.961-0.982c-0.249-0.382-0.418-0.808-0.508-1.278s-0.08-0.955,0.029-1.458
                                               c0.109-0.502,0.3-0.949,0.576-1.34c0.275-0.391,0.605-0.709,0.99-0.955c0.385-0.245,0.812-0.411,1.281-0.497
                                               c0.469-0.086,0.949-0.076,1.438,0.03c0.49,0.106,0.931,0.295,1.322,0.567c0.392,0.272,0.712,0.599,0.961,0.982
                                               c0.249,0.383,0.418,0.808,0.508,1.278S12.716,114.118,12.608,114.62z M11.402,114.36c0.068-0.314,0.075-0.62,0.021-0.918
                                               s-0.159-0.57-0.316-0.818c-0.157-0.248-0.366-0.46-0.626-0.638s-0.564-0.304-0.909-0.379c-0.345-0.074-0.673-0.085-0.985-0.03
                                               s-0.589,0.162-0.834,0.323c-0.245,0.16-0.454,0.365-0.626,0.614c-0.172,0.249-0.292,0.53-0.36,0.844
                                               c-0.068,0.314-0.074,0.62-0.021,0.918c0.054,0.298,0.159,0.57,0.317,0.818c0.157,0.248,0.366,0.46,0.626,0.638
                                               s0.564,0.304,0.909,0.379c0.345,0.074,0.673,0.085,0.985,0.03c0.311-0.054,0.589-0.162,0.834-0.323
                                               c0.245-0.16,0.453-0.366,0.626-0.614C11.214,114.955,11.334,114.674,11.402,114.36z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M6.82,108.394l0.325-1.26l6.683,1.725l-0.325,1.26L6.82,108.394z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M7.533,105.592l0.431-1.451l5.226-1.342l0.022-0.074l-1.297-0.305l-3.159-0.939l0.368-1.238l6.615,1.966l-0.39,1.312
                                               l-5.519,1.416l-0.022,0.074l1.297,0.305l3.409,1.013l-0.365,1.229L7.533,105.592z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M16.641,99.581l-5.35-1.87l-0.636,1.82L9.49,99.124l1.702-4.868l1.164,0.407l-0.636,1.82l5.35,1.87L16.641,99.581z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M65.458,34.5l-3.254-4.64l-1.578,1.107l-0.709-1.01l4.222-2.961l0.708,1.01l-1.578,1.107l3.254,4.64L65.458,34.5z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M65.116,26.339l1.099-0.697l1.476,2.328l2.45-1.554l-1.476-2.328l1.099-0.697l3.695,5.829l-1.099,0.697l-1.559-2.459
                                               l-2.45,1.554l1.559,2.459l-1.099,0.697L65.116,26.339z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M71.119,22.61l1.123-0.657l3.486,5.956l-1.123,0.657L71.119,22.61z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M73.598,21.159l2.211-1.2c0.305-0.165,0.607-0.267,0.908-0.306c0.3-0.039,0.586-0.022,0.856,0.051
                                               c0.27,0.073,0.516,0.201,0.74,0.383c0.223,0.182,0.41,0.412,0.56,0.689c0.11,0.203,0.18,0.412,0.208,0.627
                                               c0.029,0.215,0.023,0.426-0.018,0.635c-0.04,0.208-0.11,0.41-0.208,0.606s-0.218,0.373-0.36,0.53l0.024,0.064l2.891,1.448
                                               l0.037,0.068l-1.313,0.712l-2.803-1.429l-0.534,0.289l1.236,2.279l-1.144,0.62L73.598,21.159z M77.285,22.701
                                               c0.26-0.141,0.43-0.336,0.511-0.584c0.081-0.248,0.055-0.494-0.076-0.737c-0.058-0.107-0.133-0.202-0.225-0.283
                                               c-0.092-0.082-0.196-0.142-0.312-0.182c-0.116-0.039-0.242-0.053-0.379-0.041c-0.136,0.012-0.275,0.056-0.416,0.132
                                               l-1.076,0.584l0.914,1.686L77.285,22.701z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M79.086,18.214l2.101-1.012c0.492-0.237,0.971-0.375,1.438-0.414c0.466-0.039,0.905,0.012,1.315,0.153
                                               c0.41,0.141,0.781,0.367,1.113,0.679c0.331,0.311,0.607,0.695,0.828,1.152c0.223,0.464,0.353,0.922,0.389,1.375
                                               c0.037,0.453-0.018,0.883-0.165,1.289c-0.147,0.406-0.381,0.779-0.702,1.119c-0.321,0.34-0.728,0.629-1.22,0.865l-2.101,1.012
                                               L79.086,18.214z M83.594,22.334c0.342-0.165,0.619-0.357,0.834-0.578c0.215-0.221,0.367-0.46,0.457-0.717
                                               c0.09-0.257,0.119-0.53,0.087-0.818c-0.031-0.288-0.12-0.582-0.265-0.883c-0.145-0.301-0.32-0.554-0.525-0.758
                                               c-0.205-0.204-0.437-0.351-0.694-0.441c-0.257-0.09-0.539-0.12-0.845-0.09c-0.307,0.03-0.631,0.127-0.973,0.291l-0.877,0.422
                                               l1.923,3.995L83.594,22.334z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M87.639,14.226l1.192-0.471l3.305,2.623l0.072-0.029l0.622-4.173l1.201-0.474l2.534,6.42l-1.201,0.474l-1.249-3.165
                                               l-0.353-1.104l-0.072,0.028l-0.496,3.522l-0.726,0.287l-2.777-2.231l-0.072,0.028l0.496,1.048l1.249,3.165l-1.192,0.471
                                               L87.639,14.226z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M97.541,10.514l1.409-0.465l4.627,5.741l-1.364,0.45l-1.057-1.387l-2.526,0.834l-0.023,1.743l-1.364,0.451
                                               L97.541,10.514z M100.378,13.85l-1.127-1.445l-0.501-0.717l-0.073,0.024l0.025,0.875l-0.045,1.832L100.378,13.85z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M102.462,8.933l1.46-0.399l3.898,3.731l0.074-0.02l-0.425-1.263l-0.869-3.18l1.246-0.341l1.82,6.657l-1.321,0.361
                                               l-4.115-3.942l-0.074,0.02l0.425,1.263l0.937,3.431l-1.236,0.338L102.462,8.933z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M179.209,8.282l4.201,1.226l-0.345,1.184l-2.952-0.861l-0.47,1.61l2.656,0.775l-0.346,1.184l-2.656-0.775l-0.772,2.646
                                               l-1.249-0.364L179.209,8.282z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M184.515,9.876l1.237,0.402l-2.133,6.563l-1.237-0.402L184.515,9.876z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M187.259,10.758l1.421,0.52l1.019,5.299l0.073,0.026l0.384-1.276l1.132-3.096l1.213,0.444l-2.369,6.482l-1.285-0.47
                                               l-1.074-5.596l-0.073-0.026l-0.384,1.276l-1.221,3.341l-1.204-0.44L187.259,10.758z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M194.741,14.928l-0.619,1.475l2.551,1.071l-0.478,1.138l-2.551-1.071l-0.619,1.475l2.835,1.191l-0.477,1.138
                                               l-4.035-1.695l2.672-6.363l4.034,1.695l-0.477,1.138L194.741,14.928z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M201.337,16.482l1.169,0.57l-2.483,5.094l2.625,1.28l-0.541,1.109l-3.795-1.85L201.337,16.482z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M206.701,20.557l-0.758,1.409l2.436,1.311l-0.585,1.086l-2.436-1.31l-0.758,1.409l2.708,1.457l-0.585,1.086
                                               l-3.854-2.074l3.27-6.077l3.853,2.074l-0.584,1.086L206.701,20.557z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M212.309,25.896l2.898,1.746c-0.023,0.076-0.058,0.164-0.103,0.264c-0.045,0.1-0.096,0.197-0.153,0.291
                                               c-0.229,0.379-0.502,0.703-0.82,0.969c-0.318,0.266-0.68,0.456-1.087,0.571c-0.434,0.122-0.881,0.148-1.343,0.08
                                               c-0.461-0.068-0.929-0.245-1.402-0.53c-0.429-0.258-0.787-0.579-1.074-0.962c-0.287-0.383-0.488-0.796-0.603-1.241
                                               c-0.115-0.444-0.141-0.905-0.079-1.381c0.062-0.477,0.227-0.938,0.496-1.383c0.269-0.446,0.599-0.808,0.992-1.085
                                               c0.392-0.277,0.812-0.469,1.258-0.575c0.447-0.106,0.906-0.122,1.378-0.047c0.473,0.074,0.924,0.241,1.353,0.5
                                               c0.479,0.288,0.857,0.629,1.135,1.021c0.277,0.392,0.463,0.816,0.557,1.27l-1.221,0.289c-0.07-0.32-0.191-0.605-0.363-0.855
                                               c-0.172-0.25-0.423-0.474-0.753-0.673c-0.269-0.163-0.555-0.269-0.855-0.318c-0.3-0.05-0.594-0.041-0.882,0.025
                                               c-0.288,0.067-0.561,0.193-0.819,0.379c-0.258,0.186-0.48,0.433-0.666,0.741c-0.186,0.309-0.3,0.62-0.344,0.935
                                               c-0.044,0.315-0.026,0.616,0.052,0.904c0.078,0.287,0.209,0.552,0.394,0.794c0.184,0.242,0.414,0.446,0.689,0.612
                                               c0.313,0.189,0.606,0.304,0.879,0.344c0.272,0.04,0.528,0.035,0.769-0.015c0.17-0.033,0.34-0.101,0.511-0.204
                                               c0.171-0.104,0.335-0.235,0.492-0.396l-1.858-1.119L212.309,25.896z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M259.696,82.051c-0.135-0.263-0.228-0.533-0.278-0.811c-0.05-0.278-0.052-0.555-0.007-0.832
                                               c0.045-0.277,0.136-0.545,0.274-0.803c0.138-0.259,0.327-0.505,0.568-0.739l0.99,0.866c-0.267,0.239-0.45,0.511-0.55,0.816
                                               c-0.099,0.305-0.065,0.619,0.103,0.945c0.062,0.12,0.137,0.227,0.227,0.323c0.089,0.095,0.186,0.171,0.292,0.229
                                               c0.106,0.057,0.218,0.088,0.336,0.092c0.117,0.004,0.237-0.024,0.356-0.086c0.114-0.059,0.207-0.132,0.277-0.22
                                               c0.071-0.087,0.122-0.198,0.153-0.334c0.031-0.135,0.045-0.298,0.041-0.488c-0.004-0.19-0.024-0.416-0.058-0.68l-0.058-0.447
                                               c-0.029-0.195-0.038-0.402-0.028-0.621c0.01-0.218,0.048-0.432,0.114-0.639c0.067-0.208,0.169-0.404,0.309-0.588
                                               c0.14-0.184,0.326-0.337,0.561-0.458c0.24-0.124,0.488-0.192,0.745-0.206c0.257-0.013,0.509,0.026,0.756,0.119
                                               c0.247,0.093,0.477,0.238,0.691,0.435s0.398,0.444,0.551,0.741c0.159,0.308,0.256,0.596,0.289,0.864
                                               c0.034,0.269,0.027,0.514-0.02,0.738c-0.047,0.223-0.123,0.423-0.227,0.6c-0.104,0.177-0.213,0.323-0.326,0.44l-0.955-0.797
                                               c0.142-0.146,0.242-0.33,0.301-0.551c0.059-0.222,0.02-0.465-0.115-0.727c-0.13-0.251-0.296-0.429-0.499-0.534
                                               c-0.202-0.105-0.398-0.109-0.586-0.012c-0.183,0.094-0.297,0.254-0.343,0.481c-0.046,0.226-0.039,0.545,0.021,0.955
                                               l0.071,0.452c0.041,0.29,0.059,0.565,0.052,0.825c-0.007,0.26-0.049,0.503-0.128,0.728c-0.079,0.225-0.197,0.427-0.354,0.606
                                               s-0.364,0.335-0.621,0.467c-0.32,0.165-0.629,0.244-0.926,0.234c-0.298-0.009-0.572-0.075-0.824-0.198
                                               c-0.252-0.123-0.479-0.291-0.684-0.504C259.984,82.517,259.819,82.291,259.696,82.051z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M263.481,89.235c-0.181-0.165-0.348-0.35-0.502-0.556s-0.286-0.431-0.398-0.677c-0.213-0.468-0.325-0.941-0.338-1.419
                                               c-0.012-0.478,0.063-0.93,0.225-1.357c0.163-0.427,0.406-0.814,0.731-1.163s0.716-0.627,1.172-0.834
                                               c0.456-0.208,0.923-0.318,1.399-0.334c0.476-0.015,0.929,0.056,1.357,0.214c0.428,0.158,0.818,0.399,1.17,0.723
                                               s0.634,0.719,0.847,1.187c0.213,0.468,0.325,0.941,0.338,1.419c0.012,0.478-0.063,0.93-0.225,1.357
                                               c-0.163,0.427-0.406,0.815-0.731,1.163c-0.325,0.349-0.716,0.627-1.172,0.834c-0.485,0.221-0.977,0.335-1.475,0.342
                                               c-0.498,0.007-0.97-0.085-1.417-0.277l-0.5,0.81l-0.987-0.611L263.481,89.235z M264.876,86.993l0.995,0.606l-0.724,1.155
                                               c0.253,0.076,0.521,0.104,0.806,0.084c0.284-0.019,0.572-0.096,0.865-0.229c0.322-0.146,0.591-0.334,0.808-0.563
                                               c0.217-0.229,0.376-0.481,0.477-0.757c0.101-0.275,0.146-0.564,0.136-0.866c-0.01-0.302-0.082-0.6-0.215-0.892
                                               s-0.31-0.542-0.531-0.749c-0.221-0.207-0.468-0.362-0.742-0.467c-0.274-0.105-0.568-0.151-0.883-0.139
                                               c-0.316,0.013-0.634,0.092-0.956,0.239s-0.591,0.334-0.808,0.563c-0.217,0.229-0.376,0.481-0.477,0.757
                                               c-0.101,0.275-0.146,0.564-0.136,0.866c0.01,0.302,0.082,0.6,0.215,0.892c0.058,0.129,0.126,0.246,0.202,0.353
                                               c0.077,0.106,0.16,0.206,0.252,0.299L264.876,86.993z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M265.334,94.518c-0.147-0.377-0.22-0.744-0.218-1.1c0.002-0.356,0.078-0.688,0.227-0.994
                                               c0.149-0.307,0.367-0.585,0.652-0.835c0.286-0.25,0.641-0.457,1.065-0.624l3.869-1.512l0.474,1.212l-3.941,1.541
                                               c-0.215,0.084-0.403,0.189-0.561,0.312c-0.158,0.124-0.282,0.266-0.372,0.425c-0.09,0.159-0.141,0.334-0.152,0.525
                                               c-0.012,0.191,0.024,0.391,0.105,0.6c0.085,0.215,0.196,0.389,0.333,0.522c0.138,0.132,0.294,0.227,0.468,0.283
                                               c0.174,0.056,0.361,0.076,0.562,0.06c0.201-0.016,0.409-0.067,0.624-0.151l3.941-1.541l0.474,1.212l-3.869,1.513
                                               c-0.401,0.157-0.79,0.242-1.168,0.255c-0.378,0.013-0.728-0.043-1.05-0.169c-0.322-0.126-0.609-0.318-0.861-0.574
                                               C265.685,95.221,265.484,94.901,265.334,94.518z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M274.039,97.781l0.471,1.408l-5.724,4.646l-0.455-1.362l1.383-1.062l-0.843-2.523l-1.744-0.017l-0.455-1.362
                                               L274.039,97.781z M270.713,100.63l1.441-1.132l0.716-0.504l-0.025-0.073l-0.875,0.028l-1.832-0.038L270.713,100.63z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M275.619,102.67l0.681,2.422c0.094,0.334,0.127,0.652,0.098,0.954s-0.108,0.576-0.239,0.823
                                               c-0.131,0.247-0.31,0.459-0.537,0.637s-0.492,0.309-0.795,0.394c-0.223,0.063-0.442,0.085-0.658,0.065
                                               c-0.216-0.02-0.421-0.072-0.615-0.158c-0.194-0.086-0.376-0.198-0.545-0.337c-0.169-0.139-0.315-0.295-0.437-0.468
                                               l-0.068,0.009l-2.05,2.499l-0.074,0.021l-0.404-1.438l2.013-2.418l-0.164-0.585l-2.496,0.702l-0.352-1.253L275.619,102.67z
                                                M273.301,105.925c0.08,0.285,0.233,0.494,0.457,0.628c0.224,0.134,0.47,0.163,0.736,0.088
                                               c0.117-0.033,0.226-0.086,0.326-0.157c0.1-0.072,0.182-0.16,0.246-0.264c0.064-0.105,0.105-0.225,0.124-0.361
                                               c0.018-0.135,0.006-0.281-0.037-0.435l-0.331-1.178l-1.846,0.519L273.301,105.925z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M276.36,110.213l-1.557,0.366l0.633,2.693l-1.201,0.282l-0.633-2.693l-1.557,0.366l0.703,2.993l-1.201,0.282
                                               l-1.001-4.26l6.719-1.579l1.001,4.26l-1.201,0.282L276.36,110.213z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M278.978,116.454l0.226,1.281l-5.581,0.985l0.508,2.876l-1.215,0.215l-0.734-4.158L278.978,116.454z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M278.836,123.2l-1.585,0.215l0.371,2.741l-1.223,0.165l-0.371-2.741l-1.585,0.215l0.413,3.047l-1.223,0.165
                                               l-0.587-4.336l6.839-0.927l0.587,4.336l-1.223,0.165L278.836,123.2z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M277.814,130.862l0.292,3.371c-0.075,0.026-0.167,0.05-0.275,0.072c-0.108,0.022-0.216,0.038-0.325,0.048
                                               c-0.441,0.038-0.863,0.007-1.266-0.094c-0.402-0.1-0.77-0.281-1.102-0.543c-0.353-0.279-0.638-0.625-0.855-1.039
                                               c-0.216-0.413-0.348-0.895-0.396-1.446c-0.043-0.499,0.006-0.978,0.147-1.435c0.141-0.457,0.357-0.863,0.649-1.217
                                               c0.292-0.355,0.649-0.647,1.071-0.876c0.422-0.23,0.892-0.367,1.411-0.413c0.519-0.045,1.005,0.01,1.461,0.164
                                               c0.455,0.154,0.857,0.38,1.205,0.679c0.349,0.299,0.631,0.661,0.849,1.088c0.217,0.426,0.348,0.889,0.391,1.388
                                               c0.048,0.557-0.005,1.063-0.159,1.518c-0.154,0.455-0.387,0.854-0.699,1.197l-0.951-0.817c0.217-0.245,0.377-0.51,0.477-0.796
                                               c0.101-0.286,0.135-0.621,0.102-1.005c-0.027-0.314-0.109-0.607-0.245-0.879c-0.136-0.272-0.316-0.505-0.539-0.698
                                               c-0.223-0.194-0.486-0.34-0.788-0.44c-0.302-0.099-0.633-0.134-0.991-0.103c-0.359,0.031-0.678,0.122-0.959,0.272
                                               c-0.281,0.15-0.514,0.341-0.7,0.573c-0.187,0.233-0.324,0.494-0.411,0.785c-0.088,0.292-0.118,0.597-0.09,0.918
                                               c0.032,0.365,0.111,0.669,0.239,0.913c0.128,0.244,0.282,0.448,0.464,0.613c0.127,0.118,0.281,0.215,0.466,0.293
                                               c0.184,0.078,0.387,0.133,0.609,0.165l-0.187-2.161L277.814,130.862z" />
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <path d="M14.036,195.161c-0.529-0.163-0.989-0.422-1.379-0.78c-0.39-0.357-0.699-0.81-0.926-1.358
                                               c-0.205-0.492-0.305-0.986-0.302-1.481c0.003-0.496,0.095-0.961,0.275-1.397c0.18-0.435,0.443-0.83,0.791-1.183
                                               c0.347-0.354,0.764-0.631,1.25-0.833c0.486-0.202,0.977-0.301,1.473-0.298s0.961,0.095,1.397,0.275
                                               c0.435,0.18,0.83,0.443,1.183,0.79c0.354,0.348,0.632,0.767,0.837,1.26c0.22,0.529,0.321,1.034,0.304,1.513
                                               c-0.017,0.48-0.136,0.949-0.356,1.409l-1.213-0.524c0.146-0.292,0.233-0.593,0.263-0.905c0.029-0.312-0.029-0.643-0.175-0.993
                                               c-0.128-0.308-0.302-0.575-0.522-0.801c-0.22-0.226-0.47-0.398-0.75-0.516s-0.584-0.178-0.913-0.178
                                               c-0.329-0.001-0.665,0.07-1.01,0.214c-0.345,0.143-0.633,0.331-0.864,0.564c-0.232,0.233-0.404,0.491-0.518,0.772
                                               c-0.114,0.282-0.169,0.581-0.164,0.896c0.005,0.315,0.071,0.627,0.199,0.935c0.153,0.369,0.365,0.666,0.635,0.889
                                               c0.269,0.224,0.589,0.389,0.958,0.495L14.036,195.161z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M14.816,199.846c-0.233-0.48-0.361-0.967-0.387-1.462c-0.025-0.495,0.041-0.966,0.198-1.413
                                               c0.157-0.447,0.4-0.855,0.728-1.225c0.328-0.37,0.725-0.668,1.193-0.895c0.468-0.227,0.949-0.354,1.442-0.383
                                               c0.494-0.028,0.964,0.034,1.413,0.187c0.448,0.153,0.858,0.393,1.232,0.719c0.373,0.326,0.675,0.729,0.908,1.209
                                               c0.233,0.48,0.361,0.967,0.386,1.462c0.025,0.495-0.041,0.966-0.198,1.413c-0.158,0.447-0.4,0.855-0.728,1.224
                                               c-0.328,0.37-0.725,0.668-1.193,0.895c-0.468,0.227-0.949,0.354-1.442,0.382c-0.494,0.028-0.965-0.034-1.413-0.187
                                               c-0.448-0.153-0.859-0.393-1.231-0.719C15.351,200.728,15.048,200.325,14.816,199.846z M15.967,199.288
                                               c0.145,0.3,0.335,0.554,0.569,0.762c0.234,0.208,0.495,0.364,0.781,0.465c0.286,0.102,0.593,0.142,0.919,0.121
                                               c0.326-0.021,0.655-0.112,0.985-0.272c0.33-0.159,0.604-0.361,0.823-0.605c0.219-0.243,0.377-0.508,0.475-0.796
                                               c0.098-0.288,0.137-0.588,0.118-0.901c-0.018-0.313-0.1-0.62-0.246-0.919c-0.145-0.3-0.335-0.554-0.569-0.762
                                               c-0.234-0.208-0.495-0.364-0.781-0.465c-0.286-0.102-0.593-0.142-0.919-0.121c-0.326,0.021-0.655,0.112-0.984,0.272
                                               c-0.33,0.16-0.605,0.361-0.823,0.605c-0.219,0.243-0.377,0.509-0.475,0.797c-0.098,0.288-0.137,0.588-0.118,0.901
                                               C15.739,198.681,15.821,198.988,15.967,199.288z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M23.062,200.052l0.702,1.279l-3.065,3.368l-0.681,0.67l0.038,0.07l0.941-0.196l4.517-0.722l0.702,1.279l-7.529,1.155
                                               l-0.682-1.244L23.062,200.052z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M26.199,208.229l-1.42,0.858l1.484,2.454l-1.095,0.662l-1.484-2.454l-1.42,0.858l1.649,2.729l-1.095,0.662
                                               l-2.348-3.883l6.124-3.702l2.347,3.883l-1.095,0.661L26.199,208.229z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M29.546,211.251l1.436,2.178c0.198,0.3,0.331,0.604,0.398,0.912c0.067,0.307,0.074,0.603,0.023,0.888
                                               c-0.051,0.285-0.162,0.551-0.33,0.798c-0.169,0.247-0.389,0.46-0.661,0.64c-0.2,0.132-0.41,0.223-0.63,0.271
                                               c-0.219,0.049-0.438,0.061-0.657,0.038c-0.219-0.024-0.434-0.077-0.645-0.161c-0.211-0.085-0.404-0.193-0.579-0.324
                                               l-0.064,0.03l-1.238,3.115l-0.067,0.044l-0.853-1.293l1.227-3.024l-0.347-0.526l-2.245,1.48l-0.742-1.126L29.546,211.251z
                                                M28.282,215.197c0.168,0.256,0.385,0.415,0.649,0.476c0.263,0.061,0.515,0.013,0.754-0.144
                                               c0.106-0.07,0.197-0.156,0.273-0.258c0.076-0.102,0.129-0.215,0.159-0.339c0.031-0.124,0.033-0.255,0.009-0.395
                                               c-0.024-0.14-0.082-0.279-0.174-0.418l-0.699-1.06l-1.66,1.095L28.282,215.197z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M28.249,222.356c-0.178-0.249-0.314-0.513-0.406-0.79c-0.093-0.278-0.136-0.563-0.131-0.853
                                               c0.005-0.291,0.059-0.579,0.161-0.865c0.103-0.286,0.26-0.567,0.473-0.842l1.145,0.741c-0.238,0.286-0.386,0.592-0.442,0.919
                                               c-0.057,0.327,0.025,0.646,0.247,0.955c0.081,0.114,0.175,0.213,0.28,0.297c0.105,0.085,0.217,0.148,0.334,0.191
                                               c0.117,0.043,0.236,0.058,0.358,0.045c0.122-0.013,0.239-0.061,0.353-0.142c0.108-0.078,0.192-0.166,0.252-0.266
                                               c0.059-0.1,0.095-0.222,0.107-0.366s0.001-0.312-0.031-0.507c-0.033-0.194-0.086-0.424-0.162-0.689l-0.126-0.451
                                               c-0.058-0.196-0.098-0.407-0.121-0.632c-0.023-0.226-0.015-0.45,0.022-0.673c0.037-0.223,0.113-0.44,0.229-0.65
                                               c0.116-0.21,0.285-0.394,0.507-0.553c0.227-0.163,0.472-0.27,0.734-0.323c0.262-0.052,0.526-0.049,0.794,0.009
                                               c0.267,0.059,0.525,0.173,0.774,0.343c0.249,0.17,0.474,0.396,0.676,0.678c0.21,0.292,0.352,0.574,0.426,0.844
                                               c0.075,0.27,0.104,0.523,0.089,0.759c-0.015,0.236-0.063,0.453-0.143,0.65s-0.17,0.363-0.269,0.5l-1.099-0.676
                                               c0.124-0.171,0.199-0.374,0.226-0.611c0.027-0.237-0.049-0.479-0.226-0.728c-0.171-0.239-0.368-0.397-0.592-0.474
                                               s-0.425-0.052-0.603,0.076c-0.173,0.124-0.267,0.306-0.28,0.545c-0.013,0.239,0.042,0.564,0.165,0.976l0.14,0.453
                                               c0.086,0.291,0.145,0.571,0.177,0.839c0.032,0.268,0.024,0.523-0.023,0.766c-0.047,0.243-0.138,0.468-0.273,0.675
                                               c-0.135,0.207-0.324,0.398-0.568,0.572c-0.303,0.217-0.608,0.343-0.915,0.379c-0.307,0.035-0.599,0.008-0.875-0.08
                                               c-0.277-0.089-0.536-0.227-0.777-0.415C28.613,222.791,28.411,222.583,28.249,222.356z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M72.038,254.828l1.138,0.724l-3.155,4.958l2.555,1.626l-0.687,1.079l-3.693-2.35L72.038,254.828z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M75.625,265.58c-0.463-0.263-0.846-0.592-1.147-0.985c-0.302-0.394-0.514-0.819-0.637-1.276s-0.155-0.931-0.094-1.421
                                               c0.06-0.491,0.219-0.962,0.476-1.414c0.257-0.452,0.58-0.829,0.971-1.132c0.39-0.303,0.814-0.519,1.269-0.647
                                               c0.456-0.128,0.93-0.163,1.422-0.106c0.492,0.058,0.97,0.218,1.434,0.482c0.463,0.263,0.845,0.592,1.147,0.985
                                               c0.301,0.393,0.514,0.819,0.637,1.276c0.123,0.457,0.155,0.931,0.095,1.422c-0.061,0.491-0.219,0.962-0.476,1.414
                                               c-0.257,0.452-0.58,0.829-0.971,1.132c-0.391,0.303-0.814,0.519-1.27,0.647c-0.456,0.128-0.93,0.163-1.422,0.105
                                               C76.566,266.004,76.088,265.843,75.625,265.58z M76.257,264.468c0.289,0.165,0.59,0.266,0.901,0.305
                                               c0.311,0.039,0.613,0.019,0.907-0.059c0.293-0.078,0.569-0.219,0.826-0.422c0.257-0.202,0.476-0.463,0.657-0.782
                                               c0.181-0.318,0.293-0.64,0.336-0.964s0.023-0.633-0.061-0.925c-0.083-0.293-0.22-0.563-0.413-0.81
                                               c-0.193-0.247-0.434-0.453-0.724-0.618c-0.289-0.165-0.59-0.266-0.901-0.306c-0.311-0.039-0.613-0.019-0.907,0.06
                                               c-0.294,0.078-0.569,0.219-0.826,0.422c-0.257,0.202-0.476,0.463-0.657,0.782c-0.181,0.319-0.293,0.64-0.336,0.965
                                               c-0.043,0.324-0.023,0.633,0.061,0.925c0.083,0.293,0.22,0.562,0.413,0.81C75.726,264.097,75.967,264.303,76.257,264.468z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M83.322,261.479l1.408,0.692l0.465,5.575l0.072,0.036l0.537-1.273l1.509-3.067l1.202,0.591l-3.158,6.421l-1.274-0.626
                                               l-0.49-5.888l-0.072-0.036l-0.537,1.273l-1.628,3.309l-1.193-0.587L83.322,261.479z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M91.448,268.82l3.234,1.359c-0.012,0.081-0.035,0.177-0.066,0.287c-0.032,0.11-0.069,0.217-0.113,0.321
                                               c-0.178,0.423-0.41,0.796-0.697,1.116c-0.286,0.32-0.63,0.57-1.03,0.749c-0.427,0.189-0.882,0.283-1.365,0.282
                                               c-0.484-0.001-0.99-0.112-1.518-0.335c-0.479-0.201-0.894-0.477-1.246-0.827c-0.352-0.35-0.62-0.745-0.804-1.183
                                               c-0.184-0.439-0.28-0.907-0.288-1.406c-0.007-0.498,0.093-0.996,0.303-1.493c0.209-0.497,0.494-0.918,0.855-1.261
                                               c0.361-0.343,0.763-0.602,1.205-0.778c0.442-0.176,0.912-0.26,1.408-0.254c0.496,0.006,0.984,0.11,1.463,0.312
                                               c0.534,0.224,0.973,0.517,1.317,0.878c0.343,0.361,0.597,0.768,0.761,1.22l-1.209,0.478c-0.119-0.318-0.286-0.592-0.5-0.823
                                               c-0.214-0.231-0.505-0.423-0.874-0.578c-0.301-0.127-0.609-0.193-0.924-0.199c-0.316-0.006-0.616,0.047-0.901,0.158
                                               c-0.285,0.111-0.546,0.281-0.783,0.511c-0.238,0.229-0.428,0.516-0.573,0.86c-0.145,0.344-0.216,0.681-0.214,1.01
                                               c0.002,0.33,0.065,0.637,0.188,0.919c0.123,0.283,0.297,0.535,0.522,0.756s0.491,0.396,0.798,0.525
                                               c0.35,0.147,0.668,0.221,0.953,0.222s0.547-0.043,0.787-0.13c0.169-0.059,0.334-0.154,0.494-0.286
                                               c0.16-0.131,0.308-0.291,0.445-0.479l-2.073-0.871L91.448,268.82z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M99.647,276.239c-0.507-0.166-0.947-0.412-1.32-0.738c-0.374-0.326-0.666-0.7-0.878-1.124
                                               c-0.212-0.423-0.337-0.882-0.375-1.375c-0.038-0.493,0.024-0.986,0.186-1.48c0.162-0.494,0.404-0.928,0.727-1.303
                                               c0.323-0.374,0.695-0.67,1.116-0.886c0.422-0.215,0.879-0.344,1.373-0.386c0.494-0.041,0.994,0.021,1.5,0.187
                                               c0.507,0.166,0.946,0.412,1.32,0.738s0.666,0.701,0.878,1.124c0.212,0.423,0.337,0.882,0.375,1.375
                                               c0.038,0.493-0.024,0.986-0.186,1.48c-0.162,0.494-0.404,0.928-0.727,1.302c-0.323,0.375-0.695,0.67-1.116,0.886
                                               c-0.422,0.216-0.88,0.344-1.373,0.385C100.653,276.468,100.153,276.406,99.647,276.239z M100.045,275.024
                                               c0.316,0.104,0.631,0.144,0.944,0.12c0.313-0.024,0.605-0.103,0.877-0.238c0.272-0.135,0.514-0.327,0.726-0.577
                                               c0.212-0.25,0.375-0.549,0.489-0.897s0.16-0.685,0.137-1.012c-0.023-0.326-0.104-0.624-0.243-0.895
                                               c-0.139-0.27-0.328-0.507-0.566-0.711s-0.515-0.359-0.832-0.462c-0.317-0.104-0.631-0.144-0.944-0.121
                                               c-0.313,0.024-0.606,0.103-0.877,0.238c-0.273,0.135-0.514,0.328-0.726,0.577s-0.374,0.549-0.489,0.897
                                               c-0.114,0.348-0.16,0.686-0.138,1.012c0.023,0.326,0.104,0.625,0.243,0.895c0.139,0.27,0.328,0.507,0.566,0.711
                                               S99.729,274.92,100.045,275.024z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M106.398,270.679l4.383,1.175l-0.331,1.236l-3.079-0.825l-0.45,1.68l2.771,0.742l-0.331,1.236l-2.771-0.743
                                               l-0.74,2.761l-1.303-0.349L106.398,270.679z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M111.663,272.06l4.431,0.979l-0.276,1.249l-3.113-0.688l-0.375,1.698l2.801,0.618l-0.276,1.249l-2.801-0.619
                                               l-0.617,2.791l-1.317-0.291L111.663,272.06z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M168.341,272.864l1.319-0.283l1.232,5.746l2.961-0.635l0.268,1.251l-4.28,0.918L168.341,272.864z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M178.432,278.007c-0.515,0.138-1.018,0.172-1.509,0.104c-0.49-0.068-0.941-0.222-1.349-0.461
                                               c-0.409-0.239-0.764-0.555-1.065-0.947c-0.301-0.392-0.519-0.839-0.654-1.341c-0.134-0.502-0.169-0.998-0.103-1.488
                                               c0.066-0.49,0.216-0.94,0.451-1.352c0.235-0.411,0.548-0.769,0.939-1.073c0.391-0.305,0.844-0.526,1.359-0.664
                                               c0.515-0.138,1.018-0.172,1.509-0.103s0.941,0.222,1.349,0.461c0.409,0.239,0.764,0.555,1.065,0.946
                                               c0.301,0.392,0.519,0.839,0.653,1.341c0.135,0.502,0.169,0.998,0.103,1.488c-0.065,0.49-0.215,0.941-0.451,1.352
                                               c-0.235,0.411-0.548,0.769-0.939,1.073C179.4,277.648,178.947,277.87,178.432,278.007z M178.102,276.771
                                               c0.322-0.086,0.607-0.225,0.857-0.415c0.249-0.191,0.451-0.417,0.605-0.679c0.154-0.262,0.251-0.555,0.292-0.88
                                               c0.041-0.324,0.014-0.664-0.081-1.018c-0.094-0.354-0.24-0.661-0.438-0.923c-0.198-0.261-0.428-0.466-0.692-0.616
                                               c-0.264-0.15-0.552-0.246-0.863-0.287c-0.311-0.041-0.628-0.018-0.949,0.067s-0.607,0.225-0.856,0.415
                                               c-0.249,0.191-0.451,0.417-0.605,0.679c-0.154,0.262-0.251,0.555-0.292,0.88c-0.041,0.325-0.014,0.664,0.081,1.018
                                               c0.095,0.354,0.241,0.662,0.438,0.923c0.197,0.261,0.428,0.467,0.692,0.617c0.264,0.15,0.552,0.245,0.863,0.287
                                               C177.464,276.88,177.78,276.857,178.102,276.771z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M181.027,269.686l1.489-0.494l4.243,3.647l0.076-0.025l-0.51-1.284l-1.077-3.244l1.271-0.422l2.254,6.792l-1.347,0.447
                                               l-4.48-3.852l-0.076,0.025l0.511,1.284l1.162,3.501l-1.261,0.419L181.027,269.686z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M191.979,269.215l3.259-1.298c0.048,0.067,0.099,0.151,0.154,0.251c0.054,0.101,0.102,0.203,0.144,0.308
                                               c0.17,0.427,0.265,0.856,0.286,1.285s-0.05,0.848-0.21,1.256c-0.171,0.434-0.43,0.82-0.776,1.159
                                               c-0.346,0.338-0.785,0.614-1.317,0.826c-0.483,0.192-0.973,0.287-1.469,0.283c-0.496-0.003-0.964-0.097-1.403-0.281
                                               c-0.439-0.184-0.836-0.451-1.19-0.801s-0.631-0.776-0.831-1.278c-0.2-0.501-0.291-1.001-0.274-1.499
                                               c0.017-0.498,0.122-0.964,0.314-1.4c0.193-0.435,0.468-0.825,0.826-1.168c0.358-0.343,0.779-0.611,1.261-0.803
                                               c0.539-0.215,1.057-0.313,1.555-0.296c0.498,0.017,0.964,0.129,1.398,0.336l-0.527,1.189c-0.308-0.143-0.619-0.222-0.933-0.237
                                               c-0.314-0.014-0.657,0.052-1.028,0.2c-0.304,0.121-0.57,0.289-0.799,0.506c-0.23,0.217-0.407,0.465-0.532,0.744
                                               c-0.126,0.28-0.193,0.584-0.201,0.914c-0.009,0.33,0.056,0.668,0.194,1.015s0.324,0.637,0.556,0.87
                                               c0.233,0.233,0.493,0.408,0.779,0.524s0.587,0.173,0.903,0.173c0.315,0,0.628-0.061,0.937-0.185
                                               c0.353-0.141,0.631-0.311,0.835-0.51c0.203-0.2,0.36-0.414,0.47-0.644c0.079-0.161,0.13-0.344,0.152-0.55
                                               c0.022-0.206,0.016-0.424-0.018-0.654l-2.089,0.832L191.979,269.215z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M203.03,268.771c-0.478,0.235-0.965,0.367-1.459,0.396c-0.495,0.028-0.966-0.035-1.414-0.19
                                               c-0.447-0.154-0.857-0.395-1.229-0.72c-0.372-0.325-0.673-0.722-0.902-1.188c-0.23-0.466-0.36-0.946-0.392-1.439
                                               c-0.031-0.493,0.028-0.964,0.178-1.414c0.15-0.449,0.388-0.861,0.712-1.236c0.324-0.375,0.725-0.68,1.203-0.916
                                               c0.478-0.235,0.965-0.367,1.46-0.396c0.495-0.028,0.966,0.035,1.414,0.19c0.447,0.155,0.857,0.395,1.229,0.72
                                               c0.372,0.326,0.673,0.722,0.902,1.188c0.23,0.466,0.36,0.946,0.392,1.439c0.031,0.493-0.028,0.965-0.178,1.414
                                               c-0.151,0.449-0.388,0.861-0.712,1.236C203.91,268.23,203.509,268.535,203.03,268.771z M202.465,267.623
                                               c0.299-0.147,0.552-0.338,0.759-0.574c0.207-0.236,0.361-0.497,0.46-0.784c0.1-0.287,0.139-0.594,0.116-0.92
                                               c-0.024-0.326-0.116-0.654-0.278-0.983c-0.162-0.329-0.365-0.602-0.61-0.819c-0.245-0.218-0.511-0.374-0.8-0.47
                                               c-0.288-0.095-0.589-0.133-0.902-0.113c-0.313,0.02-0.619,0.104-0.918,0.251c-0.299,0.147-0.552,0.339-0.759,0.574
                                               c-0.207,0.236-0.361,0.497-0.461,0.784c-0.1,0.287-0.138,0.594-0.115,0.92c0.024,0.326,0.116,0.654,0.278,0.983
                                               c0.162,0.329,0.365,0.602,0.61,0.82c0.245,0.217,0.511,0.374,0.8,0.47c0.288,0.096,0.589,0.133,0.902,0.113
                                               C201.86,267.854,202.166,267.77,202.465,267.623z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M203.947,260.107l1.364-0.775l4.873,2.749l0.069-0.04l-0.751-1.159l-1.689-2.972l1.165-0.661l3.535,6.222l-1.234,0.701
                                               l-5.145-2.905l-0.07,0.04l0.751,1.159l1.822,3.207l-1.156,0.657L203.947,260.107z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M243.999,226.961l0.821-1.045l4.331,0.619l0.049-0.063l-1.626-4.061l0.827-1.053l5.627,4.422l-0.828,1.053l-2.774-2.18
                                               l-0.894-0.804l-0.049,0.063l1.399,3.413l-0.5,0.637l-3.653-0.544l-0.049,0.063l0.992,0.678l2.774,2.18l-0.821,1.045
                                               L243.999,226.961z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M249.158,220.265l0.792-1.092l5.791,4.204l-0.792,1.092L249.158,220.265z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M250.75,218.097l1.355-2.003c0.317-0.469,0.674-0.843,1.071-1.121c0.398-0.278,0.817-0.461,1.258-0.549
                                               c0.441-0.088,0.892-0.079,1.351,0.027c0.459,0.105,0.907,0.306,1.343,0.601c0.441,0.299,0.797,0.641,1.066,1.028
                                               c0.269,0.387,0.444,0.801,0.524,1.241c0.08,0.44,0.065,0.897-0.045,1.369c-0.11,0.472-0.324,0.943-0.642,1.412l-1.355,2.003
                                               L250.75,218.097z M256.94,219.435c0.22-0.325,0.369-0.643,0.446-0.954c0.077-0.31,0.088-0.604,0.035-0.881
                                               c-0.054-0.278-0.17-0.537-0.348-0.778c-0.178-0.241-0.411-0.459-0.698-0.653c-0.287-0.194-0.576-0.329-0.866-0.405
                                               c-0.291-0.076-0.575-0.087-0.852-0.033c-0.277,0.054-0.546,0.174-0.805,0.361c-0.259,0.187-0.499,0.443-0.719,0.769
                                               l-0.566,0.836l3.809,2.575L256.94,219.435z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M255.347,211.254l0.718-1.236l4.17,1.139l0.746,0.248l0.04-0.069l-0.56-0.568l-2.265-2.507l0.632-1.089l3.305,0.717
                                               l0.757,0.209l0.04-0.069l-0.581-0.511l-3.091-2.997l0.718-1.236l5.234,5.236l-0.653,1.124l-3.544-0.764l-0.788-0.215
                                               l-0.04,0.069l0.577,0.578l2.419,2.699l-0.653,1.124L255.347,211.254z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M260.269,202.586l0.628-1.194l6.333,3.331l-0.628,1.194L260.269,202.586z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M269.618,197.281c0.231,0.503,0.342,1.02,0.335,1.548c-0.007,0.529-0.14,1.061-0.398,1.595
                                               c-0.232,0.48-0.534,0.884-0.906,1.211c-0.373,0.327-0.781,0.568-1.226,0.723c-0.445,0.155-0.915,0.221-1.41,0.196
                                               c-0.495-0.024-0.979-0.151-1.454-0.38c-0.474-0.229-0.875-0.529-1.202-0.902c-0.327-0.372-0.568-0.781-0.723-1.226
                                               c-0.155-0.445-0.22-0.915-0.196-1.41c0.025-0.495,0.152-0.982,0.384-1.463c0.249-0.516,0.558-0.927,0.928-1.233
                                               c0.37-0.306,0.8-0.53,1.289-0.671l0.416,1.255c-0.315,0.085-0.598,0.22-0.85,0.405c-0.252,0.185-0.461,0.449-0.626,0.791
                                               c-0.145,0.3-0.228,0.607-0.251,0.922c-0.022,0.315,0.015,0.616,0.113,0.904c0.098,0.288,0.256,0.555,0.474,0.8
                                               c0.218,0.246,0.495,0.45,0.831,0.612c0.336,0.162,0.668,0.252,0.996,0.269c0.328,0.018,0.635-0.025,0.921-0.127
                                               s0.545-0.26,0.778-0.474c0.232-0.213,0.421-0.47,0.566-0.77c0.173-0.36,0.254-0.715,0.242-1.065
                                               c-0.012-0.35-0.101-0.698-0.267-1.045L269.618,197.281z" />
                                                                            </g>
                                                                            <g>
                                                                                <path
                                                                                    d="M264.581,193.806l0.524-1.243l2.828,1.191l0.031-0.073l-1.811-3.605l0.632-1.501l0.074,0.031l1.721,3.534l4.831-1.066
                                               l0.073,0.031l-0.64,1.52l-3.636,0.778l0.557,1.124l1.934,0.815l-0.524,1.243L264.581,193.806z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M268.753,186.924l1.558,0.57l0.986-2.693l1.201,0.44l-0.987,2.693l1.558,0.57l1.096-2.993l1.201,0.44l-1.561,4.26
                                               l-6.719-2.461l1.561-4.26l1.201,0.44L268.753,186.924z" />
                                                                            </g>
                                                                            <g>
                                                                                <path d="M276.283,183.137l-5.608-1.757l-0.597,1.908l-1.221-0.383l1.598-5.102l1.221,0.382l-0.597,1.907l5.608,1.757
                                               L276.283,183.137z" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>

                                                        </svg>

                                                    </div>
                                                    <!-- <line *ngIf="WWLine" #WWLine style="stroke:rgb(255,0,0);stroke-width:2" /> -->
                                                </div>
                                                <div class="main-tab-container pitchmap" style="display:none;">
                                                    <div class="txt-center">


                                                        <svg *ngIf="isRHB" id="picthmapdata" style="position: relative;"
                                                            version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                            width="280px" height="289px" viewBox="0 0 280 289"
                                                            enable-background="new 0 0 280 289" xml:space="preserve">
                                                            <g id="Layer_1" display="none">
                                                            </g>
                                                            <g id="Layer_2">
                                                                <rect x="-2" y="-3.5" fill="#6A823F" width="284"
                                                                    height="296" />
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <g>
                                                                                        <defs>
                                                                                            <polygon id="SVGID_23_"
                                                                                                points="262.581,288.99 17.419,288.99 78.5,64.328 200.663,64.328 								" />
                                                                                        </defs>
                                                                                        <clipPath id="SVGID_2_">
                                                                                            <use xlink:href="#SVGID_23_"
                                                                                                overflow="visible" />
                                                                                        </clipPath>
                                                                                        <g clip-path="url(#SVGID_2_)">
                                                                                            <polygon fill="#BBAC9E"
                                                                                                points="262.572,289.012 17.43,289.012 77.731,63.916 201.041,63.916 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.5">
                                                                                        <g>
                                                                                            <g>
                                                                                                <polygon fill="#BBB18E"
                                                                                                    points="206.889,86.92 72.374,86.92 78.5,64.328 200.663,64.328 									" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.3">
                                                                                        <g>
                                                                                            <polygon fill="#808080"
                                                                                                points="262.581,288.99 17.419,288.99 78.5,64.328 200.663,64.328 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.3">
                                                                                        <g>
                                                                                            <g>
                                                                                                <polygon fill="#FFD54F"
                                                                                                    points="211.963,105.328 67.353,105.328 72.472,86.501 206.774,86.501 									" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.3">
                                                                                        <g>
                                                                                            <g>
                                                                                                <polygon fill="#EF657D"
                                                                                                    points="219.516,132.731 59.87,132.731 67.353,105.328 211.963,105.328 									" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.3">
                                                                                        <g>
                                                                                            <g>
                                                                                                <polygon fill="#5FC447"
                                                                                                    points="232.838,181.052 46.77,181.052 59.87,132.731 219.516,132.731 									" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.3">
                                                                                        <g>
                                                                                            <g>
                                                                                                <polygon fill="#9A77C9"
                                                                                                    points="248.053,236.276 31.751,236.276 46.77,181.052 232.838,181.052 									" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.3">
                                                                                        <g>
                                                                                            <polygon fill="#A55E5E"
                                                                                                points="258.34,273.576 21.6,273.576 31.751,236.276 248.053,236.276 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.5">
                                                                                        <g>
                                                                                            <polygon fill="#333333"
                                                                                                points="262.581,288.99 17.419,288.99 21.6,273.576 258.34,273.576 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.4">
                                                                                        <g>
                                                                                            <polygon fill="#363E4D"
                                                                                                points="167.349,288.968 113.403,288.968 132.694,86.501 149.592,86.501 								" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <line fill="none"
                                                                                                    stroke="#FFFFFF"
                                                                                                    stroke-miterlimit="10"
                                                                                                    x1="138.377"
                                                                                                    y1="86.454"
                                                                                                    x2="128.377"
                                                                                                    y2="273.626" />
                                                                                                <line fill="none"
                                                                                                    stroke="#FFFFFF"
                                                                                                    stroke-miterlimit="10"
                                                                                                    x1="144.377"
                                                                                                    y1="86.454"
                                                                                                    x2="153.377"
                                                                                                    y2="273.626" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#FFFFFF"
                                                                                                        d="M205.127,97.797H73.618c-0.127,0-0.248-0.058-0.327-0.157c-0.079-0.099-0.109-0.23-0.081-0.353
                                                                           l5.739-22.173c0.05-0.225,0.274-0.368,0.5-0.316c0.226,0.051,0.367,0.275,0.315,0.5L74.142,96.96h130.461l-5.623-21.663
                                                                           c-0.051-0.225,0.09-0.449,0.315-0.5c0.227-0.053,0.449,0.091,0.5,0.316l5.739,22.173c0.029,0.124-0.002,0.254-0.081,0.353
                                                                           C205.375,97.739,205.254,97.797,205.127,97.797z" />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#FFFFFF"
                                                                                                        d="M83.271,89.298c-0.023,0-0.047-0.002-0.071-0.007c-0.179-0.039-0.293-0.216-0.254-0.396
                                                                           l1.361-6.265c0.039-0.18,0.217-0.293,0.396-0.255c0.179,0.039,0.293,0.216,0.254,0.396l-1.361,6.265
                                                                           C83.563,89.192,83.425,89.298,83.271,89.298z" />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#FFFFFF"
                                                                                                        d="M195.975,89.298c-0.153,0-0.291-0.106-0.325-0.262l-1.362-6.265
                                                                           c-0.039-0.18,0.075-0.357,0.254-0.396c0.183-0.038,0.358,0.075,0.396,0.254l1.362,6.265
                                                                           c0.039,0.18-0.075,0.357-0.254,0.396C196.022,89.296,195.998,89.298,195.975,89.298z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#FFFFFF"
                                                                                                        d="M201.946,86.71H76.846c-0.231,0-0.418-0.187-0.418-0.418s0.187-0.418,0.418-0.418h125.099
                                                                           c0.231,0,0.418,0.187,0.418,0.418S202.177,86.71,201.946,86.71z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <polygon
                                                                                                        fill="#FFFFFF"
                                                                                                        points="224.254,97.797 55.034,97.797 55.261,96.96 224.023,96.96 										" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g opacity="0.7">
                                                                                        <g>
                                                                                            <g>

                                                                                                <rect x="89.844"
                                                                                                    y="82.999"
                                                                                                    transform="matrix(0.9703 0.2418 -0.2418 0.9703 48.0159 -16.2322)"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="0.598"
                                                                                                    height="209.03" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>

                                                                                                <rect x="84.694"
                                                                                                    y="187.215"
                                                                                                    transform="matrix(0.2424 0.9702 -0.9702 0.2424 325.2883 -41.5139)"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="209.059"
                                                                                                    height="0.598" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>

                                                                                                <rect x="123.03"
                                                                                                    y="85.402"
                                                                                                    transform="matrix(0.9955 0.0947 -0.0947 0.9955 18.3057 -10.8399)"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="0.598"
                                                                                                    height="203.984" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>

                                                                                                <rect x="56.409"
                                                                                                    y="187.215"
                                                                                                    transform="matrix(0.085 0.9964 -0.9964 0.085 331.5812 13.9595)"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="203.563"
                                                                                                    height="0.598" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <rect x="67.427"
                                                                                                    y="105.069"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="144.519"
                                                                                                    height="0.598" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <rect x="59.892"
                                                                                                    y="132.456"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="159.587"
                                                                                                    height="0.598" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <rect x="46.857"
                                                                                                    y="180.651"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="186.017"
                                                                                                    height="0.598" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <rect x="31.789"
                                                                                                    y="236.022"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="216.274"
                                                                                                    height="0.598" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <rect x="21.623"
                                                                                                    y="273.334"
                                                                                                    fill="#F2F2F2"
                                                                                                    width="236.724"
                                                                                                    height="0.598" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <polygon
                                                                                                        fill="#FCD462"
                                                                                                        points="136.002,86.214 134.313,86.214 134.313,57.005 136.002,57.005 										" />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <polygon
                                                                                                        fill="#FCD462"
                                                                                                        points="142.185,86.214 140.495,86.214 140.495,57.005 142.185,57.005 										" />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <polygon
                                                                                                        fill="#FCD462"
                                                                                                        points="148.418,86.214 146.728,86.214 146.728,57.005 148.418,57.005 										" />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <polygon fill="#F6C358"
                                                                                                    points="141.215,56.133 140.287,56.133 140.287,55.747 136.208,55.747 136.208,56.133 
                                                                       134.981,56.133 134.981,56.997 136.208,56.997 136.208,57.384 140.287,57.384 140.287,56.997 141.215,56.997 									" />
                                                                                            </g>
                                                                                            <g>
                                                                                                <polygon fill="#F6C358"
                                                                                                    points="147.778,56.133 146.484,56.133 146.484,55.747 142.399,55.747 142.399,56.133 
                                                                       141.544,56.133 141.544,56.997 142.399,56.997 142.399,57.384 146.484,57.384 146.484,56.997 147.778,56.997 									" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>

                                                                                            <image overflow="visible"
                                                                                                width="280" height="600"
                                                                                                xlink:href="assets/images/Cric.png"
                                                                                                transform="matrix(0.1771 0 0 0.1771 125.3803 -2.4488)">
                                                                                            </image>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M27.353,279.321h1.048l0.607,4.064h0.108L30,279.321h1.157l0.882,4.064h0.108l0.607-4.064h1.048
                                                                   l-0.94,4.917h-1.511l-0.774-3.738l-0.774,3.738h-1.511L27.353,279.321z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M34.475,284.238v-4.917h0.998v4.917H34.475z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF" d="M38.199,284.238h-1.656v-4.917h1.656c0.424,0,0.774,0.045,1.048,0.134
                                                                   c0.275,0.089,0.488,0.236,0.64,0.441c0.152,0.205,0.257,0.446,0.314,0.723c0.058,0.277,0.087,0.638,0.087,1.081
                                                                   c0,0.444-0.027,0.811-0.08,1.103c-0.053,0.292-0.153,0.552-0.3,0.781c-0.147,0.229-0.36,0.395-0.64,0.499
                                                                   C38.989,284.186,38.633,284.238,38.199,284.238z M39.247,282.286c0.01-0.154,0.014-0.367,0.014-0.64
                                                                   c0-0.272-0.01-0.49-0.029-0.654c-0.019-0.164-0.065-0.313-0.137-0.448c-0.072-0.135-0.18-0.228-0.322-0.278
                                                                   c-0.142-0.051-0.334-0.076-0.575-0.076h-0.658v3.181h0.658c0.362,0,0.624-0.091,0.788-0.275
                                                                   C39.127,282.946,39.214,282.676,39.247,282.286z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M41.156,284.238v-4.917h3.181v0.868h-2.184v1.15h1.75v0.853h-1.75v1.179h2.184v0.868H41.156z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M50.154,283.714c-0.306,0.407-0.833,0.611-1.58,0.611s-1.274-0.204-1.58-0.611
                                                                   c-0.306-0.407-0.459-1.045-0.459-1.913c0-0.868,0.153-1.512,0.459-1.934c0.306-0.422,0.833-0.633,1.58-0.633
                                                                   s1.274,0.211,1.58,0.633c0.306,0.422,0.459,1.066,0.459,1.934C50.613,282.669,50.46,283.307,50.154,283.714z
                                                                    M47.775,283.078c0.142,0.253,0.408,0.38,0.799,0.38c0.39,0,0.657-0.126,0.799-0.38c0.142-0.253,0.213-0.677,0.213-1.273
                                                                   c0-0.595-0.072-1.028-0.217-1.298c-0.145-0.27-0.41-0.405-0.795-0.405s-0.651,0.135-0.795,0.405
                                                                   c-0.145,0.27-0.217,0.703-0.217,1.298C47.562,282.4,47.633,282.824,47.775,283.078z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M51.336,284.238v-1.2h1.041v1.2H51.336z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M56.719,283.714c-0.306,0.407-0.833,0.611-1.58,0.611s-1.274-0.204-1.58-0.611
                                                                   c-0.306-0.407-0.459-1.045-0.459-1.913c0-0.868,0.153-1.512,0.459-1.934c0.306-0.422,0.833-0.633,1.58-0.633
                                                                   s1.274,0.211,1.58,0.633c0.306,0.422,0.459,1.066,0.459,1.934C57.179,282.669,57.026,283.307,56.719,283.714z
                                                                    M54.341,283.078c0.142,0.253,0.409,0.38,0.799,0.38c0.39,0,0.657-0.126,0.799-0.38c0.142-0.253,0.213-0.677,0.213-1.273
                                                                   c0-0.595-0.072-1.028-0.217-1.298c-0.145-0.27-0.41-0.405-0.795-0.405c-0.386,0-0.651,0.135-0.795,0.405
                                                                   c-0.145,0.27-0.217,0.703-0.217,1.298C54.127,282.4,54.198,282.824,54.341,283.078z" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M224.375,279.321h1.048l0.607,4.064h0.109l0.882-4.064h1.157l0.882,4.064h0.109l0.607-4.064h1.049
                                                                   l-0.94,4.917h-1.511L227.6,280.5l-0.774,3.738h-1.511L224.375,279.321z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M231.497,284.238v-4.917h0.998v4.917H231.497z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M235.221,284.238h-1.656v-4.917h1.656c0.424,0,0.774,0.045,1.048,0.134
                                                                   c0.275,0.089,0.488,0.236,0.64,0.441c0.152,0.205,0.257,0.446,0.315,0.723c0.058,0.277,0.087,0.638,0.087,1.081
                                                                   c0,0.444-0.026,0.811-0.08,1.103c-0.053,0.292-0.153,0.552-0.3,0.781c-0.147,0.229-0.36,0.395-0.64,0.499
                                                                   C236.011,284.186,235.654,284.238,235.221,284.238z M236.269,282.286c0.01-0.154,0.014-0.367,0.014-0.64
                                                                   c0-0.272-0.01-0.49-0.029-0.654c-0.019-0.164-0.065-0.313-0.137-0.448c-0.072-0.135-0.18-0.228-0.322-0.278
                                                                   c-0.142-0.051-0.334-0.076-0.575-0.076h-0.658v3.181h0.658c0.362,0,0.624-0.091,0.788-0.275
                                                                   C236.149,282.946,236.235,282.676,236.269,282.286z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M238.178,284.238v-4.917h3.181v0.868h-2.184v1.15h1.75v0.853h-1.75v1.179h2.184v0.868H238.178z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M245.43,284.238h-1.656v-4.917h1.656c0.424,0,0.774,0.045,1.048,0.134
                                                                   c0.275,0.089,0.488,0.236,0.64,0.441c0.152,0.205,0.257,0.446,0.315,0.723c0.058,0.277,0.087,0.638,0.087,1.081
                                                                   c0,0.444-0.026,0.811-0.08,1.103c-0.053,0.292-0.153,0.552-0.3,0.781c-0.147,0.229-0.36,0.395-0.64,0.499
                                                                   C246.22,284.186,245.864,284.238,245.43,284.238z M246.478,282.286c0.01-0.154,0.014-0.367,0.014-0.64
                                                                   c0-0.272-0.01-0.49-0.029-0.654c-0.019-0.164-0.065-0.313-0.137-0.448c-0.072-0.135-0.18-0.228-0.322-0.278
                                                                   c-0.142-0.051-0.334-0.076-0.575-0.076h-0.658v3.181h0.658c0.362,0,0.624-0.091,0.788-0.275
                                                                   C246.358,282.946,246.444,282.676,246.478,282.286z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M248.257,284.238v-1.2h1.041v1.2H248.257z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M253.036,284.238h-2.798v-4.917h0.998v4.035h1.8V284.238z" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M73.933,283.714c-0.306,0.407-0.833,0.611-1.58,0.611c-0.747,0-1.274-0.204-1.58-0.611
                                                                   c-0.306-0.407-0.459-1.045-0.459-1.913c0-0.868,0.153-1.512,0.459-1.934c0.306-0.422,0.833-0.633,1.58-0.633
                                                                   c0.747,0,1.274,0.211,1.58,0.633c0.306,0.422,0.459,1.066,0.459,1.934C74.393,282.669,74.239,283.307,73.933,283.714z
                                                                    M71.555,283.078c0.142,0.253,0.408,0.38,0.799,0.38c0.39,0,0.657-0.126,0.799-0.38c0.142-0.253,0.213-0.677,0.213-1.273
                                                                   c0-0.595-0.072-1.028-0.217-1.298c-0.145-0.27-0.41-0.405-0.795-0.405c-0.386,0-0.651,0.135-0.795,0.405
                                                                   c-0.145,0.27-0.217,0.703-0.217,1.298C71.341,282.4,71.412,282.824,71.555,283.078z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M76.2,282.597c0,0.574,0.292,0.86,0.875,0.86s0.875-0.287,0.875-0.86v-3.275h0.998v3.254
                                                                   c0,0.603-0.155,1.045-0.466,1.327c-0.311,0.282-0.78,0.423-1.406,0.423c-0.627,0-1.095-0.141-1.406-0.423
                                                                   c-0.311-0.282-0.466-0.724-0.466-1.327v-3.254H76.2V282.597z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M79.533,280.203v-0.882h3.615v0.882h-1.301v4.035h-0.998v-4.035H79.533z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M85.289,280.102c-0.525,0-0.788,0.174-0.788,0.521c0,0.154,0.065,0.272,0.195,0.354
                                                                   c0.13,0.082,0.435,0.2,0.915,0.354c0.48,0.154,0.816,0.329,1.009,0.524c0.193,0.195,0.289,0.495,0.289,0.9
                                                                   c0,0.516-0.157,0.906-0.47,1.171c-0.314,0.265-0.723,0.398-1.229,0.398c-0.376,0-0.836-0.058-1.381-0.174l-0.26-0.051
                                                                   l0.101-0.774c0.646,0.087,1.14,0.13,1.482,0.13c0.511,0,0.766-0.212,0.766-0.636c0-0.154-0.059-0.275-0.177-0.362
                                                                   c-0.118-0.087-0.353-0.181-0.705-0.282c-0.559-0.159-0.953-0.348-1.182-0.568c-0.229-0.219-0.343-0.524-0.343-0.915
                                                                   c0-0.492,0.149-0.858,0.448-1.099c0.299-0.241,0.708-0.362,1.229-0.362c0.357,0,0.81,0.048,1.359,0.145l0.26,0.051
                                                                   l-0.08,0.788C86.048,280.141,85.568,280.102,85.289,280.102z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M87.711,284.238v-4.917h0.998v4.917H87.711z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF" d="M91.435,284.238h-1.656v-4.917h1.656c0.424,0,0.774,0.045,1.048,0.134
                                                                   c0.275,0.089,0.488,0.236,0.64,0.441c0.152,0.205,0.257,0.446,0.314,0.723c0.058,0.277,0.087,0.638,0.087,1.081
                                                                   c0,0.444-0.027,0.811-0.08,1.103c-0.053,0.292-0.153,0.552-0.3,0.781c-0.147,0.229-0.36,0.395-0.64,0.499
                                                                   C92.225,284.186,91.868,284.238,91.435,284.238z M92.483,282.286c0.01-0.154,0.014-0.367,0.014-0.64
                                                                   c0-0.272-0.01-0.49-0.029-0.654c-0.019-0.164-0.065-0.313-0.137-0.448c-0.072-0.135-0.18-0.228-0.322-0.278
                                                                   c-0.142-0.051-0.334-0.076-0.575-0.076h-0.658v3.181h0.658c0.362,0,0.624-0.091,0.788-0.275
                                                                   C92.363,282.946,92.449,282.676,92.483,282.286z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M94.392,284.238v-4.917h3.181v0.868H95.39v1.15h1.75v0.853h-1.75v1.179h2.184v0.868H94.392z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M103.39,283.714c-0.306,0.407-0.833,0.611-1.58,0.611s-1.274-0.204-1.58-0.611
                                                                   c-0.306-0.407-0.459-1.045-0.459-1.913c0-0.868,0.153-1.512,0.459-1.934c0.306-0.422,0.833-0.633,1.58-0.633
                                                                   s1.274,0.211,1.58,0.633c0.306,0.422,0.459,1.066,0.459,1.934C103.849,282.669,103.696,283.307,103.39,283.714z
                                                                    M101.011,283.078c0.142,0.253,0.409,0.38,0.799,0.38c0.39,0,0.657-0.126,0.799-0.38c0.142-0.253,0.213-0.677,0.213-1.273
                                                                   c0-0.595-0.072-1.028-0.217-1.298c-0.145-0.27-0.41-0.405-0.795-0.405s-0.651,0.135-0.795,0.405
                                                                   c-0.145,0.27-0.217,0.703-0.217,1.298C100.798,282.4,100.869,282.824,101.011,283.078z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M104.702,284.238v-4.917h3.138v0.868h-2.14v1.446h1.75v0.868h-1.75v1.735H104.702z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M108.542,284.238v-4.917h3.138v0.868h-2.14v1.446h1.75v0.868h-1.75v1.735H108.542z" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M173.594,283.714c-0.306,0.407-0.833,0.611-1.58,0.611c-0.747,0-1.274-0.204-1.58-0.611
                                                                   c-0.306-0.407-0.459-1.045-0.459-1.913c0-0.868,0.153-1.512,0.459-1.934c0.306-0.422,0.833-0.633,1.58-0.633
                                                                   c0.747,0,1.274,0.211,1.58,0.633c0.306,0.422,0.459,1.066,0.459,1.934C174.053,282.669,173.9,283.307,173.594,283.714z
                                                                    M171.215,283.078c0.142,0.253,0.408,0.38,0.799,0.38c0.39,0,0.657-0.126,0.799-0.38c0.142-0.253,0.213-0.677,0.213-1.273
                                                                   c0-0.595-0.072-1.028-0.217-1.298c-0.145-0.27-0.41-0.405-0.795-0.405c-0.386,0-0.651,0.135-0.795,0.405
                                                                   c-0.145,0.27-0.217,0.703-0.217,1.298C171.002,282.4,171.073,282.824,171.215,283.078z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M175.861,282.597c0,0.574,0.292,0.86,0.875,0.86c0.583,0,0.875-0.287,0.875-0.86v-3.275h0.998v3.254
                                                                   c0,0.603-0.155,1.045-0.466,1.327c-0.311,0.282-0.78,0.423-1.406,0.423c-0.627,0-1.096-0.141-1.406-0.423
                                                                   c-0.311-0.282-0.466-0.724-0.466-1.327v-3.254h0.998V282.597z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M179.194,280.203v-0.882h3.615v0.882h-1.301v4.035h-0.998v-4.035H179.194z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M184.949,280.102c-0.525,0-0.788,0.174-0.788,0.521c0,0.154,0.065,0.272,0.195,0.354
                                                                   c0.13,0.082,0.435,0.2,0.915,0.354c0.48,0.154,0.816,0.329,1.009,0.524c0.193,0.195,0.289,0.495,0.289,0.9
                                                                   c0,0.516-0.157,0.906-0.47,1.171c-0.313,0.265-0.723,0.398-1.229,0.398c-0.376,0-0.836-0.058-1.381-0.174l-0.26-0.051
                                                                   l0.101-0.774c0.646,0.087,1.14,0.13,1.482,0.13c0.511,0,0.766-0.212,0.766-0.636c0-0.154-0.059-0.275-0.177-0.362
                                                                   c-0.118-0.087-0.353-0.181-0.705-0.282c-0.559-0.159-0.953-0.348-1.182-0.568c-0.229-0.219-0.343-0.524-0.343-0.915
                                                                   c0-0.492,0.149-0.858,0.448-1.099c0.299-0.241,0.709-0.362,1.229-0.362c0.357,0,0.81,0.048,1.359,0.145l0.26,0.051
                                                                   l-0.08,0.788C185.709,280.141,185.229,280.102,184.949,280.102z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M187.372,284.238v-4.917h0.998v4.917H187.372z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF" d="M191.095,284.238h-1.656v-4.917h1.656c0.424,0,0.774,0.045,1.048,0.134
                                                                   c0.275,0.089,0.488,0.236,0.64,0.441c0.152,0.205,0.257,0.446,0.315,0.723c0.058,0.277,0.087,0.638,0.087,1.081
                                                                   c0,0.444-0.026,0.811-0.08,1.103c-0.053,0.292-0.153,0.552-0.3,0.781c-0.147,0.229-0.36,0.395-0.64,0.499
                                                                   C191.886,284.186,191.529,284.238,191.095,284.238z M192.144,282.286c0.01-0.154,0.014-0.367,0.014-0.64
                                                                   c0-0.272-0.01-0.49-0.029-0.654c-0.019-0.164-0.065-0.313-0.137-0.448c-0.072-0.135-0.18-0.228-0.322-0.278
                                                                   c-0.142-0.051-0.334-0.076-0.575-0.076h-0.658v3.181h0.658c0.362,0,0.624-0.091,0.788-0.275
                                                                   C192.023,282.946,192.11,282.676,192.144,282.286z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M194.052,284.238v-4.917h3.181v0.868h-2.184v1.15h1.75v0.853h-1.75v1.179h2.184v0.868H194.052z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M202.447,284.238h-2.798v-4.917h0.998v4.035h1.8V284.238z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M203.061,284.238v-4.917h3.181v0.868h-2.184v1.15h1.75v0.853h-1.75v1.179h2.184v0.868H203.061z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF" d="M209.07,282.474v-0.868h1.432v2.552c-0.689,0.111-1.249,0.166-1.677,0.166
                                                                   c-0.766,0-1.293-0.205-1.58-0.615c-0.287-0.41-0.43-1.06-0.43-1.952c0-0.892,0.149-1.535,0.448-1.931
                                                                   c0.299-0.395,0.807-0.593,1.526-0.593c0.448,0,0.933,0.051,1.453,0.152l0.26,0.051l-0.029,0.774
                                                                   c-0.578-0.063-1.052-0.094-1.421-0.094c-0.369,0-0.628,0.043-0.777,0.13c-0.149,0.087-0.259,0.247-0.329,0.481
                                                                   c-0.07,0.234-0.105,0.634-0.105,1.2c0,0.566,0.07,0.963,0.21,1.189c0.14,0.227,0.429,0.34,0.868,0.34l0.6-0.029v-0.954
                                                                   H209.07z" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M123.477,280.102c-0.525,0-0.788,0.174-0.788,0.521c0,0.154,0.065,0.272,0.195,0.354
                                                                   c0.13,0.082,0.435,0.2,0.915,0.354c0.48,0.154,0.816,0.329,1.009,0.524c0.193,0.195,0.289,0.495,0.289,0.9
                                                                   c0,0.516-0.157,0.906-0.47,1.171c-0.313,0.265-0.723,0.398-1.229,0.398c-0.376,0-0.836-0.058-1.381-0.174l-0.26-0.051
                                                                   l0.101-0.774c0.646,0.087,1.14,0.13,1.482,0.13c0.511,0,0.766-0.212,0.766-0.636c0-0.154-0.059-0.275-0.177-0.362
                                                                   c-0.118-0.087-0.353-0.181-0.705-0.282c-0.559-0.159-0.953-0.348-1.182-0.568c-0.229-0.219-0.343-0.524-0.343-0.915
                                                                   c0-0.492,0.149-0.858,0.448-1.099c0.299-0.241,0.709-0.362,1.229-0.362c0.357,0,0.81,0.048,1.359,0.145l0.26,0.051
                                                                   l-0.08,0.788C124.237,280.141,123.757,280.102,123.477,280.102z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M125.458,280.203v-0.882h3.615v0.882h-1.301v4.035h-0.998v-4.035H125.458z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M130.657,282.597c0,0.574,0.292,0.86,0.875,0.86c0.583,0,0.875-0.287,0.875-0.86v-3.275h0.998v3.254
                                                                   c0,0.603-0.155,1.045-0.466,1.327c-0.311,0.282-0.78,0.423-1.406,0.423c-0.627,0-1.095-0.141-1.406-0.423
                                                                   c-0.311-0.282-0.466-0.724-0.466-1.327v-3.254h0.998V282.597z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M134.431,284.238v-4.917h1.699l0.868,3.586l0.868-3.586h1.699v4.917h-0.998v-3.803h-0.108
                                                                   l-0.962,3.586h-0.998l-0.962-3.586h-0.108v3.803H134.431z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M142.443,282.806h-0.81v1.432h-0.998v-4.917h1.808c1.166,0,1.75,0.569,1.75,1.706
                                                                   c0,0.574-0.148,1.014-0.445,1.32C143.451,282.653,143.016,282.806,142.443,282.806z M141.633,281.953h0.802
                                                                   c0.497,0,0.745-0.308,0.745-0.926c0-0.304-0.06-0.522-0.181-0.654c-0.121-0.133-0.309-0.199-0.564-0.199h-0.802V281.953z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M149.283,284.238h-2.798v-4.917h0.998v4.035h1.8V284.238z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M149.897,284.238v-4.917h0.998v4.917H149.897z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF" d="M151.965,284.238v-4.917h1.685l1.186,4.049h0.072v-4.049h0.998v4.917h-1.641l-1.229-4.049h-0.072
                                                                   v4.049H151.965z" />
                                                                                        </g>
                                                                                        <g>
                                                                                            <path fill="#FFFFFF"
                                                                                                d="M156.976,284.238v-4.917h3.181v0.868h-2.184v1.15h1.75v0.853h-1.75v1.179h2.184v0.868H156.976z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.5">
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M165.951,80.892l-1.824-7.05h4.612l0.264,0.988h-3.414l0.617,2.366h2.85l0.266,0.999h-2.855
                                                                           l0.704,2.697H165.951z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M173.044,75.728h1.176l1.424,5.163h-1.187l-0.088-0.323c-0.455,0.292-0.91,0.438-1.364,0.438
                                                                           c-0.755,0-1.312-0.193-1.672-0.578c-0.36-0.385-0.667-1.058-0.924-2.016l-0.719-2.684h1.187l0.727,2.694
                                                                           c0.167,0.621,0.335,1.039,0.503,1.253c0.167,0.215,0.452,0.322,0.855,0.322c0.395,0,0.738-0.069,1.029-0.208l0.147-0.062
                                                                           L173.044,75.728z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M176.831,80.892l-2.024-7.284h1.182l2.04,7.284H176.831z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M179.281,80.892l-2.056-7.284h1.182l2.071,7.284H179.281z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M185.466,76.681h-1.505l0.664,2.268c0.123,0.421,0.238,0.701,0.344,0.84
                                                                           c0.106,0.139,0.295,0.208,0.566,0.208l0.891-0.031l0.322,0.905c-0.464,0.09-0.825,0.136-1.081,0.136
                                                                           c-0.623,0-1.089-0.136-1.399-0.406c-0.309-0.271-0.572-0.78-0.789-1.526l-0.695-2.392h-0.698l-0.276-0.953h0.697
                                                                           l-0.43-1.48h1.173l0.433,1.48h1.503L185.466,76.681z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M186.291,76.282c0.242-0.444,0.798-0.666,1.669-0.666c0.871,0,1.56,0.222,2.069,0.666
                                                                           c0.51,0.445,0.901,1.113,1.173,2.006c0.273,0.896,0.296,1.574,0.07,2.031c-0.227,0.458-0.791,0.688-1.693,0.688
                                                                           c-0.901,0-1.604-0.23-2.106-0.688c-0.501-0.457-0.885-1.135-1.15-2.031C186.059,77.395,186.048,76.726,186.291,76.282z
                                                                            M188.18,79.633c0.251,0.277,0.621,0.415,1.112,0.415c0.49,0,0.777-0.138,0.862-0.415
                                                                           c0.085-0.276,0.032-0.729-0.158-1.356c-0.189-0.626-0.413-1.068-0.67-1.329c-0.257-0.26-0.618-0.39-1.083-0.39
                                                                           c-0.465,0-0.748,0.13-0.849,0.39c-0.101,0.261-0.058,0.703,0.129,1.329C187.71,78.905,187.929,79.357,188.18,79.633z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M195.381,76.784c-0.892-0.109-1.531-0.164-1.917-0.164c-0.385,0-0.639,0.043-0.762,0.128
                                                                           c-0.123,0.086-0.156,0.221-0.099,0.406c0.057,0.185,0.179,0.316,0.366,0.391c0.187,0.076,0.6,0.163,1.24,0.263
                                                                           c0.639,0.1,1.12,0.256,1.442,0.469c0.322,0.214,0.569,0.593,0.74,1.138c0.171,0.547,0.112,0.949-0.177,1.205
                                                                           c-0.29,0.257-0.79,0.386-1.501,0.386c-0.447,0-1.029-0.059-1.747-0.177l-0.356-0.052l-0.246-0.946
                                                                           c0.911,0.111,1.561,0.166,1.949,0.166c0.388,0,0.65-0.045,0.786-0.135c0.137-0.09,0.172-0.238,0.108-0.446
                                                                           c-0.064-0.207-0.187-0.35-0.369-0.43c-0.182-0.079-0.585-0.165-1.208-0.259c-0.624-0.093-1.105-0.239-1.443-0.439
                                                                           c-0.338-0.199-0.588-0.564-0.749-1.092c-0.161-0.528-0.091-0.921,0.211-1.181c0.302-0.259,0.75-0.389,1.345-0.389
                                                                           c0.465,0,1.051,0.055,1.761,0.164l0.357,0.061L195.381,76.784z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M200.17,76.784c-0.893-0.109-1.533-0.164-1.918-0.164c-0.385,0-0.639,0.043-0.761,0.128
                                                                           c-0.122,0.086-0.154,0.221-0.095,0.406c0.059,0.185,0.182,0.316,0.369,0.391c0.188,0.076,0.602,0.163,1.242,0.263
                                                                           c0.64,0.1,1.122,0.256,1.446,0.469c0.324,0.214,0.574,0.593,0.749,1.138c0.176,0.547,0.12,0.949-0.167,1.205
                                                                           c-0.288,0.257-0.787,0.386-1.498,0.386c-0.447,0-1.03-0.059-1.748-0.177l-0.357-0.052l-0.254-0.946
                                                                           c0.912,0.111,1.563,0.166,1.951,0.166c0.388,0,0.649-0.045,0.785-0.135c0.136-0.09,0.17-0.238,0.104-0.446
                                                                           c-0.066-0.207-0.19-0.35-0.373-0.43c-0.182-0.079-0.586-0.165-1.21-0.259c-0.625-0.093-1.107-0.239-1.447-0.439
                                                                           c-0.34-0.199-0.593-0.564-0.759-1.092c-0.166-0.528-0.099-0.921,0.201-1.181c0.299-0.259,0.747-0.389,1.342-0.389
                                                                           c0.465,0,1.052,0.055,1.762,0.164l0.357,0.061L200.17,76.784z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M178.793,102.841h-1.272l-0.736-3.044l-3.409-4.245h1.385l2.359,3.085l0.865-3.085h1.374
                                                                           l-1.315,4.245L178.793,102.841z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M180.314,98.054c0.266-0.457,0.845-0.685,1.737-0.685s1.59,0.228,2.095,0.685
                                                                           c0.507,0.459,0.886,1.149,1.136,2.074c0.251,0.931,0.253,1.637,0.004,2.114c-0.25,0.479-0.841,0.719-1.772,0.719
                                                                           c-0.932,0-1.648-0.24-2.149-0.719c-0.499-0.477-0.866-1.183-1.102-2.114C180.029,99.203,180.047,98.513,180.314,98.054z
                                                                            M182.122,101.527c0.248,0.289,0.625,0.433,1.131,0.433c0.505,0,0.807-0.144,0.904-0.433
                                                                           c0.097-0.288,0.058-0.758-0.115-1.41c-0.172-0.648-0.386-1.106-0.64-1.375c-0.254-0.269-0.619-0.403-1.096-0.403
                                                                           c-0.477,0-0.773,0.134-0.886,0.403c-0.114,0.269-0.087,0.727,0.08,1.375C181.666,100.769,181.874,101.239,182.122,101.527
                                                                           z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M187.241,102.841l-1.468-5.356h1.205l0.176,0.633c0.525-0.387,1.087-0.636,1.687-0.748l0.327,1.15
                                                                           c-0.608,0.12-1.113,0.276-1.515,0.467l-0.22,0.096l1.046,3.758H187.241z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M192.578,102.841h-1.238l-2.166-7.528h1.207l1.255,4.302l0.677-0.064l0.708-2.066h1.361
                                                                           l-0.867,2.483l2.581,2.873h-1.397l-2.057-2.284l-0.71,0.075L192.578,102.841z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M200.611,101.863l0.306-0.033l0.305,0.88c-0.809,0.167-1.548,0.251-2.214,0.251
                                                                           c-0.84,0-1.513-0.222-2.016-0.664c-0.502-0.441-0.899-1.144-1.192-2.104c-0.576-1.888-0.055-2.823,1.558-2.823
                                                                           c1.561,0,2.6,0.805,3.121,2.427l0.189,0.836h-3.494c0.145,0.445,0.348,0.772,0.609,0.981
                                                                           c0.261,0.209,0.659,0.314,1.196,0.314C199.515,101.927,200.059,101.906,200.611,101.863z M199.256,99.732
                                                                           c-0.167-0.526-0.371-0.893-0.612-1.101c-0.241-0.208-0.571-0.312-0.988-0.312c-0.418,0-0.687,0.11-0.81,0.328
                                                                           c-0.123,0.22-0.11,0.581,0.039,1.086H199.256z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M202.649,102.841l-1.742-5.356h1.205l0.208,0.633c0.505-0.387,1.054-0.636,1.649-0.748l0.386,1.15
                                                                           c-0.602,0.12-1.099,0.276-1.491,0.467l-0.215,0.096l1.238,3.758H202.649z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M194.428,160.022l-0.2-1.11h2.755l0.777,4c-0.249,0.055-0.764,0.114-1.546,0.176
                                                                           c-0.782,0.063-1.352,0.094-1.707,0.094c-1.501,0-2.591-0.336-3.267-1.006c-0.671-0.665-1.116-1.699-1.336-3.089
                                                                           c-0.217-1.37-0.091-2.358,0.372-2.973c0.461-0.611,1.378-0.915,2.755-0.915c0.813,0,1.741,0.072,2.786,0.217l0.501,0.076
                                                                           l0.131,0.962c-1.147-0.117-2.166-0.175-3.058-0.175c-0.892,0-1.47,0.197-1.733,0.594
                                                                           c-0.264,0.398-0.307,1.139-0.125,2.231c0.184,1.105,0.471,1.874,0.862,2.301c0.392,0.428,1.077,0.643,2.053,0.643
                                                                           c0.698,0,1.213-0.035,1.545-0.104l-0.358-1.921H194.428z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M198.953,157.948c0.382-0.482,1.133-0.722,2.253-0.722c1.12,0,1.976,0.24,2.568,0.722
                                                                           c0.595,0.485,1.008,1.216,1.238,2.199c0.232,0.994,0.17,1.749-0.19,2.261c-0.362,0.515-1.135,0.773-2.319,0.773
                                                                           c-1.183,0-2.07-0.259-2.656-0.773c-0.583-0.512-0.975-1.268-1.175-2.261C198.474,159.164,198.569,158.432,198.953,157.948
                                                                           z M200.879,161.641c0.285,0.309,0.749,0.464,1.39,0.464c0.641,0,1.037-0.155,1.188-0.464
                                                                           c0.15-0.308,0.146-0.811-0.011-1.505c-0.156-0.689-0.381-1.175-0.676-1.46c-0.294-0.284-0.741-0.426-1.341-0.426
                                                                           c-0.6,0-0.986,0.142-1.156,0.426c-0.171,0.285-0.184,0.771-0.039,1.46C200.38,160.83,200.595,161.333,200.879,161.641z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M206.403,157.948c0.364-0.482,1.105-0.722,2.226-0.722c1.12,0,1.985,0.24,2.596,0.722
                                                                           c0.614,0.485,1.055,1.216,1.322,2.199c0.27,0.994,0.237,1.749-0.104,2.261c-0.342,0.515-1.106,0.773-2.289,0.773
                                                                           c-1.183,0-2.08-0.259-2.685-0.773c-0.603-0.512-1.023-1.268-1.261-2.261C205.971,159.164,206.037,158.432,206.403,157.948
                                                                           z M208.47,161.641c0.297,0.309,0.767,0.464,1.408,0.464c0.641,0,1.031-0.155,1.17-0.464
                                                                           c0.138-0.308,0.115-0.811-0.068-1.505c-0.182-0.689-0.426-1.175-0.732-1.46c-0.305-0.284-0.757-0.426-1.358-0.426
                                                                           c-0.6,0-0.98,0.142-1.14,0.426c-0.16,0.285-0.154,0.771,0.016,1.46C207.94,160.83,208.174,161.333,208.47,161.641z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M218.312,155.069l2.469,7.983h-1.558l-0.092-0.305c-0.615,0.289-1.216,0.434-1.803,0.434
                                                                           c-0.943,0-1.699-0.231-2.266-0.692c-0.564-0.458-0.995-1.222-1.29-2.286c-0.293-1.052-0.266-1.809,0.076-2.279
                                                                           c0.34-0.467,1.009-0.7,2.008-0.7c0.336,0,0.882,0.048,1.64,0.144l-0.693-2.301H218.312z M218.599,161.838l0.219-0.081
                                                                           l-1.017-3.373c-0.586-0.074-1.118-0.112-1.596-0.112c-0.948,0-1.242,0.628-0.879,1.898
                                                                           c0.201,0.702,0.453,1.192,0.756,1.465c0.304,0.275,0.7,0.412,1.188,0.412
                                                                           C217.757,162.047,218.201,161.978,218.599,161.838z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M205.716,207.984c-1.074,0-1.551,0.343-1.431,1.032c0.062,0.359,0.235,0.615,0.518,0.767
                                                                           c0.283,0.153,0.883,0.342,1.8,0.566c0.919,0.225,1.59,0.494,2.011,0.807c0.422,0.314,0.701,0.812,0.835,1.495
                                                                           c0.172,0.875,0.034,1.535-0.418,1.974c-0.454,0.441-1.146,0.662-2.075,0.662c-0.745,0-1.584-0.083-2.515-0.248
                                                                           l-0.488-0.086l-0.04-1.096c1.201,0.147,2.111,0.22,2.73,0.22c1.081,0,1.536-0.448,1.367-1.34
                                                                           c-0.066-0.346-0.235-0.602-0.507-0.766c-0.272-0.164-0.848-0.344-1.727-0.539c-0.877-0.195-1.551-0.463-2.021-0.804
                                                                           c-0.468-0.339-0.763-0.873-0.884-1.599c-0.12-0.721,0.045-1.262,0.493-1.626c0.447-0.362,1.12-0.543,2.019-0.543
                                                                           c0.647,0,1.425,0.069,2.334,0.205l0.455,0.069l0.088,1.035C207.054,208.045,206.206,207.984,205.716,207.984z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M212.779,215.151h-1.433l-1.714-8.417h1.373l0.57,2.704c0.568-0.288,1.12-0.433,1.655-0.433
                                                                           c0.849,0,1.479,0.22,1.891,0.661c0.413,0.444,0.739,1.179,0.978,2.215l0.755,3.27h-1.433l-0.724-3.234
                                                                           c-0.145-0.647-0.32-1.103-0.525-1.372c-0.205-0.268-0.543-0.402-1.012-0.402c-0.41,0-0.807,0.063-1.191,0.189l-0.19,0.071
                                                                           L212.779,215.151z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M217.587,209.766c0.316-0.508,0.983-0.761,2.002-0.761c1.02,0,1.814,0.253,2.386,0.761
                                                                           c0.575,0.511,1,1.282,1.276,2.319c0.279,1.048,0.274,1.846-0.019,2.386c-0.295,0.543-0.982,0.817-2.06,0.817
                                                                           c-1.078,0-1.903-0.273-2.472-0.817c-0.565-0.541-0.973-1.338-1.222-2.386C217.233,211.047,217.27,210.277,217.587,209.766
                                                                           z M219.589,213.661c0.281,0.326,0.713,0.49,1.297,0.49c0.584,0,0.934-0.164,1.051-0.49
                                                                           c0.116-0.325,0.078-0.856-0.112-1.588c-0.188-0.727-0.426-1.239-0.714-1.54c-0.287-0.299-0.703-0.449-1.25-0.449
                                                                           c-0.547,0-0.888,0.15-1.024,0.449c-0.136,0.301-0.116,0.813,0.062,1.54C219.079,212.805,219.309,213.336,219.589,213.661z
                                                                           " />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M225.488,215.151l-1.645-6.018h1.377l0.197,0.703c0.603-0.43,1.247-0.707,1.934-0.832l0.373,1.279
                                                                           c-0.697,0.135-1.277,0.308-1.739,0.521l-0.253,0.107l1.188,4.238H225.488z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M232.221,210.225h-1.77l0.803,2.632c0.151,0.494,0.291,0.824,0.419,0.987
                                                                           c0.129,0.164,0.354,0.245,0.676,0.245l1.061-0.037l0.402,1.073c-0.553,0.108-0.984,0.162-1.29,0.162
                                                                           c-0.746,0-1.305-0.162-1.677-0.483c-0.371-0.321-0.689-0.923-0.951-1.801l-0.828-2.778h-0.821l-0.321-1.092h0.816
                                                                           l-0.501-1.679h1.366l0.512,1.679h1.76L232.221,210.225z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M209.958,252.311h2.973c0.801,0,1.416,0.146,1.848,0.438c0.433,0.294,0.689,0.764,0.768,1.412
                                                                           c0.052,0.428,0.018,0.767-0.103,1.014c-0.121,0.249-0.335,0.454-0.643,0.618c0.794,0.271,1.246,0.842,1.353,1.717
                                                                           c0.174,1.42-0.603,2.138-2.332,2.138h-3.19L209.958,252.311z M212.97,253.304h-1.645l0.204,2.063h1.71
                                                                           c0.76,0,1.099-0.357,1.018-1.069C214.181,253.635,213.752,253.304,212.97,253.304z M213.371,256.373h-1.743l0.219,2.211
                                                                           h1.787c0.434,0,0.75-0.084,0.948-0.253c0.197-0.168,0.272-0.457,0.225-0.864c-0.047-0.406-0.205-0.689-0.473-0.851
                                                                           C214.066,256.454,213.745,256.373,213.371,256.373z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M217.451,254.805c0.337-0.458,0.967-0.685,1.891-0.685c0.924,0,1.619,0.228,2.086,0.685
                                                                           c0.469,0.459,0.778,1.153,0.924,2.086c0.148,0.943,0.063,1.66-0.257,2.146c-0.321,0.488-0.971,0.734-1.948,0.734
                                                                           c-0.976,0-1.696-0.245-2.157-0.734c-0.458-0.486-0.748-1.203-0.869-2.146
                                                                           C217.002,255.958,217.112,255.264,217.451,254.805z M218.875,258.309c0.222,0.293,0.597,0.44,1.126,0.44
                                                                           c0.529,0,0.863-0.147,1.001-0.44c0.138-0.293,0.157-0.77,0.058-1.429c-0.098-0.654-0.262-1.115-0.493-1.385
                                                                           c-0.23-0.27-0.592-0.404-1.088-0.404c-0.495,0-0.82,0.134-0.972,0.404c-0.153,0.271-0.186,0.732-0.098,1.385
                                                                           C218.499,257.539,218.654,258.017,218.875,258.309z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M226.687,254.235h1.248l1.027,5.413h-1.286l-0.063-0.345c-0.522,0.311-1.03,0.468-1.522,0.468
                                                                           c-0.818,0-1.4-0.206-1.746-0.618c-0.344-0.409-0.598-1.122-0.763-2.132l-0.455-2.786h1.26l0.476,2.797
                                                                           c0.111,0.652,0.245,1.094,0.402,1.321c0.157,0.227,0.453,0.341,0.887,0.341c0.426,0,0.804-0.073,1.132-0.22l0.165-0.066
                                                                           L226.687,254.235z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M231.83,259.648h-1.298l-1.072-5.413h1.248l0.067,0.327c0.502-0.295,1.003-0.442,1.504-0.442
                                                                           c0.77,0,1.339,0.198,1.709,0.596c0.371,0.399,0.661,1.061,0.871,1.992l0.661,2.941h-1.286l-0.633-2.908
                                                                           c-0.127-0.582-0.284-0.992-0.473-1.235c-0.188-0.241-0.491-0.362-0.909-0.362c-0.395,0-0.76,0.071-1.095,0.213
                                                                           l-0.161,0.064L231.83,259.648z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M237.905,254.119c0.408,0,0.902,0.049,1.481,0.147l0.302,0.053l0.185,0.909
                                                                           c-0.626-0.057-1.087-0.085-1.381-0.085c-0.589,0-0.955,0.12-1.098,0.362c-0.144,0.242-0.136,0.7,0.025,1.375
                                                                           c0.162,0.68,0.373,1.154,0.633,1.417c0.26,0.264,0.703,0.396,1.327,0.396l1.363-0.088l0.28,0.964
                                                                           c-0.768,0.134-1.354,0.201-1.759,0.201c-0.905,0-1.596-0.229-2.072-0.684c-0.473-0.453-0.828-1.191-1.062-2.207
                                                                           c-0.232-1.005-0.207-1.713,0.074-2.134C236.484,254.328,237.051,254.119,237.905,254.119z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M246.188,258.65l0.322-0.033l0.279,0.897c-0.856,0.171-1.634,0.256-2.333,0.256
                                                                           c-0.881,0-1.575-0.226-2.077-0.678c-0.501-0.45-0.877-1.164-1.129-2.136c-0.492-1.901,0.101-2.837,1.772-2.837
                                                                           c1.617,0,2.656,0.805,3.123,2.437l0.16,0.845h-3.646c0.128,0.451,0.322,0.783,0.584,0.996
                                                                           c0.262,0.213,0.674,0.319,1.234,0.319S245.609,258.694,246.188,258.65z M244.875,256.492
                                                                           c-0.148-0.53-0.343-0.899-0.583-1.109c-0.24-0.209-0.577-0.313-1.01-0.313c-0.434,0-0.719,0.11-0.858,0.33
                                                                           c-0.139,0.22-0.145,0.584-0.018,1.093H244.875z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M248.28,259.648l-1.585-5.413h1.248l0.19,0.633c0.54-0.387,1.119-0.637,1.739-0.749l0.358,1.152
                                                                           c-0.63,0.121-1.152,0.277-1.568,0.469l-0.228,0.096l1.142,3.812H248.28z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M189.908,125.285l-1.81-7.631l4.235-0.044l0.266,1.066l-3.145,0.029l0.615,2.555l2.634-0.015
                                                                           l0.27,1.088l-2.644,0.011l0.708,2.942L189.908,125.285z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M196.381,119.62l1.102-0.009l1.496,5.672l-1.123,0l-0.093-0.357
                                                                           c-0.421,0.322-0.845,0.484-1.273,0.484c-0.71,0-1.238-0.212-1.585-0.636c-0.346-0.422-0.651-1.16-0.915-2.209
                                                                           l-0.734-2.921l1.103-0.009l0.747,2.936c0.173,0.68,0.341,1.138,0.504,1.373c0.163,0.236,0.433,0.353,0.811,0.353
                                                                           c0.371-0.001,0.693-0.077,0.963-0.231l0.137-0.069L196.381,119.62z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M200.104,125.283l-2.134-7.988l1.11-0.012l2.164,8L200.104,125.283z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path fill="#333333"
                                                                                                        d="M202.44,125.282l-2.195-8.012l1.117-0.012l2.225,8.024L202.44,125.282z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M252.681,235.496v5.017h-1.049v-3.832l-1.087,0.699l-0.479-0.783l1.657-1.102H252.681z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M254.434,236.028c0.327-0.415,0.812-0.623,1.456-0.623c0.643,0,1.129,0.208,1.456,0.623
                                                           c0.327,0.416,0.49,1.083,0.49,2.003s-0.162,1.579-0.487,1.977c-0.325,0.398-0.811,0.597-1.46,0.597
                                                           c-0.649,0-1.135-0.199-1.46-0.597c-0.325-0.398-0.487-1.057-0.487-1.977S254.107,236.443,254.434,236.028z M256.757,238.008
                                                           c0-0.626-0.066-1.064-0.198-1.315c-0.132-0.251-0.353-0.376-0.665-0.376c-0.311,0-0.535,0.127-0.669,0.38
                                                           c-0.135,0.253-0.201,0.692-0.201,1.315s0.068,1.059,0.205,1.308c0.137,0.248,0.36,0.372,0.669,0.372s0.53-0.124,0.661-0.372
                                                           C256.691,239.071,256.757,238.634,256.757,238.008z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M259.703,240.604h-0.951v-3.545h0.943v0.199c0.326-0.189,0.605-0.284,0.837-0.284
                                                           c0.383,0,0.678,0.111,0.886,0.333c0.435-0.222,0.832-0.333,1.191-0.333c0.468,0,0.793,0.136,0.975,0.408
                                                           s0.273,0.705,0.273,1.301v1.922h-0.951v-1.893c0-0.303-0.033-0.526-0.099-0.67c-0.066-0.144-0.199-0.216-0.397-0.216
                                                           c-0.151,0-0.336,0.033-0.553,0.099l-0.106,0.035c0.018,0.355,0.028,0.624,0.028,0.809v1.836h-0.95v-1.822
                                                           c0-0.35-0.031-0.597-0.092-0.741c-0.061-0.144-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.546,0.099l-0.085,0.028
                                                           V240.604z" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M235.376,179.538c0.33-0.241,0.771-0.361,1.323-0.361s0.998,0.122,1.338,0.365s0.51,0.58,0.51,1.011
                                                           c0,0.294-0.036,0.514-0.106,0.661c-0.071,0.147-0.218,0.309-0.441,0.487c0.223,0.188,0.383,0.365,0.479,0.532
                                                           c0.097,0.167,0.145,0.398,0.145,0.692c0,0.497-0.177,0.863-0.532,1.098c-0.355,0.236-0.819,0.354-1.391,0.354
                                                           c-1.267,0-1.901-0.456-1.901-1.368c0-0.324,0.049-0.576,0.148-0.757c0.098-0.18,0.259-0.364,0.483-0.551
                                                           c-0.213-0.172-0.357-0.341-0.434-0.506c-0.076-0.165-0.114-0.381-0.114-0.65C234.882,180.115,235.047,179.779,235.376,179.538
                                                           z M235.923,182.766c0,0.446,0.262,0.669,0.787,0.669s0.787-0.223,0.787-0.669c0-0.264-0.149-0.454-0.448-0.57h-0.669
                                                           C236.076,182.312,235.923,182.502,235.923,182.766z M236.714,180.12c-0.228,0-0.403,0.051-0.525,0.152
                                                           c-0.122,0.101-0.183,0.256-0.183,0.464s0.124,0.398,0.373,0.57h0.669c0.248-0.172,0.372-0.37,0.372-0.593
                                                           C237.421,180.318,237.185,180.12,236.714,180.12z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M240.489,184.377h-0.951v-3.545h0.943v0.199c0.326-0.189,0.605-0.284,0.837-0.284
                                                           c0.383,0,0.678,0.111,0.886,0.333c0.435-0.222,0.832-0.333,1.191-0.333c0.468,0,0.793,0.136,0.975,0.408
                                                           c0.182,0.272,0.273,0.705,0.273,1.301v1.922h-0.951v-1.893c0-0.303-0.033-0.526-0.099-0.67
                                                           c-0.066-0.144-0.199-0.216-0.397-0.216c-0.151,0-0.336,0.033-0.553,0.099l-0.106,0.035c0.018,0.355,0.028,0.624,0.028,0.809
                                                           v1.836h-0.95v-1.822c0-0.35-0.031-0.597-0.092-0.741c-0.061-0.144-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.546,0.099
                                                           l-0.085,0.028V184.377z" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M224.732,132.031c-0.517-0.076-0.966-0.114-1.346-0.114c-0.72,0-1.079,0.39-1.079,1.171l0.159-0.046
                                                           c0.335-0.096,0.601-0.145,0.798-0.145c0.573,0,1.004,0.132,1.292,0.395c0.289,0.264,0.434,0.673,0.434,1.228
                                                           c0,0.555-0.162,0.975-0.487,1.258c-0.325,0.284-0.794,0.426-1.411,0.426c-0.616,0-1.08-0.224-1.395-0.673
                                                           c-0.314-0.449-0.471-1.109-0.471-1.98c0-0.872,0.18-1.514,0.539-1.927c0.36-0.413,0.877-0.62,1.551-0.62
                                                           c0.395,0,0.818,0.056,1.27,0.167l0.236,0.053L224.732,132.031z M223.174,133.81c-0.239,0-0.487,0.043-0.745,0.129
                                                           l-0.129,0.038c0,0.877,0.284,1.315,0.852,1.315c0.243,0,0.431-0.067,0.562-0.201c0.132-0.134,0.198-0.323,0.198-0.566
                                                           C223.911,134.048,223.665,133.81,223.174,133.81z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M226.866,136.205h-0.951v-3.545h0.943v0.199c0.326-0.189,0.605-0.284,0.837-0.284
                                                           c0.383,0,0.678,0.111,0.886,0.333c0.435-0.222,0.832-0.333,1.191-0.333c0.468,0,0.793,0.136,0.975,0.408
                                                           c0.182,0.272,0.273,0.705,0.273,1.301v1.922h-0.951v-1.893c0-0.303-0.033-0.526-0.099-0.67
                                                           c-0.066-0.144-0.199-0.216-0.397-0.216c-0.151,0-0.336,0.033-0.553,0.099l-0.106,0.035c0.018,0.355,0.028,0.624,0.028,0.809
                                                           v1.836h-0.95v-1.822c0-0.35-0.031-0.597-0.092-0.741c-0.061-0.144-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.546,0.099
                                                           l-0.085,0.028V136.205z" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M217.35,107.803h-3.312v-0.905l1.091-1.107c0.387-0.397,0.656-0.702,0.809-0.913
                                                           c0.152-0.212,0.228-0.437,0.228-0.677s-0.063-0.409-0.189-0.507s-0.306-0.147-0.538-0.147c-0.346,0-0.722,0.031-1.13,0.093
                                                           l-0.186,0.023l-0.054-0.828c0.505-0.155,1.029-0.232,1.571-0.232c1.083,0,1.625,0.488,1.625,1.462
                                                           c0,0.382-0.083,0.712-0.248,0.99s-0.464,0.617-0.898,1.014l-0.89,0.805h2.12V107.803z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M219.585,107.803h-0.951v-3.548h0.944v0.199c0.327-0.189,0.606-0.284,0.838-0.284
                                                           c0.383,0,0.679,0.111,0.887,0.334c0.435-0.222,0.833-0.334,1.192-0.334c0.469,0,0.794,0.136,0.976,0.408
                                                           c0.182,0.272,0.274,0.706,0.274,1.302v1.923h-0.951v-1.895c0-0.303-0.033-0.526-0.099-0.671
                                                           c-0.066-0.144-0.199-0.216-0.397-0.216c-0.152,0-0.336,0.033-0.554,0.099l-0.106,0.035c0.018,0.355,0.028,0.625,0.028,0.809
                                                           v1.838h-0.951v-1.824c0-0.35-0.031-0.597-0.092-0.742s-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.547,0.099
                                                           l-0.085,0.028V107.803z" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g opacity="0">
                                                                        <polygon (click)="PMClicked($event,'0','1')"
                                                                            points="206.774,86.501 206.889,86.92 206.889,86.92 192.663,34.328 86.5,34.328 72.487,86.501 			" />
                                                                        <polygon (click)="PMClicked($event,'7','2')"
                                                                            points="168.937,105.07 211.891,105.07 206.889,86.92 164.403,86.92 			" />
                                                                        <polygon (click)="PMClicked($event,'1','2')"
                                                                            points="67.427,105.328 67.427,105.07 110.381,105.07 114.904,86.92 72.374,86.92 72.487,86.501 72.472,86.501 
                                               70.412,94.078 67.353,105.328 			" />
                                                                        <polygon (click)="PMClicked($event,'2','2')"
                                                                            points="132.595,86.92 115.52,86.92 110.997,105.07 130.867,105.07 			" />
                                                                        <polygon (click)="PMClicked($event,'6','2')"
                                                                            points="168.321,105.07 163.787,86.92 149.91,86.92 151.458,105.07 			" />
                                                                        <polygon (click)="PMClicked($event,'6','3')"
                                                                            points="153.794,132.456 175.162,132.456 168.47,105.667 151.509,105.667 			" />
                                                                        <polygon (click)="PMClicked($event,'1','3')"
                                                                            points="110.232,105.667 67.427,105.667 67.427,105.328 67.353,105.328 59.978,132.456 103.556,132.456 			" />
                                                                        <polygon (click)="PMClicked($event,'2','3')"
                                                                            points="110.848,105.667 104.172,132.456 128.26,132.456 130.81,105.667 			" />
                                                                        <polygon (click)="PMClicked($event,'7','3')"
                                                                            points="211.945,105.328 211.945,105.667 169.087,105.667 175.778,132.456 219.439,132.456 212.312,106.595 
                                               211.963,105.328 			" />
                                                                        <polygon (click)="PMClicked($event,'7','4')"
                                                                            points="219.48,133.054 175.928,133.054 187.818,180.651 232.722,180.651 219.515,132.731 219.48,132.731 			" />
                                                                        <polygon (click)="PMClicked($event,'6','4')"
                                                                            points="187.201,180.651 175.311,133.054 153.845,133.054 157.905,180.651 			" />
                                                                        <polygon (click)="PMClicked($event,'1','4')"
                                                                            points="91.545,180.651 103.407,133.054 59.892,133.054 59.892,132.769 48.386,175.091 46.879,180.651 			" />
                                                                        <polygon (click)="PMClicked($event,'2','4')"
                                                                            points="128.203,133.054 104.023,133.054 92.161,180.651 123.671,180.651 			" />
                                                                        <polygon (click)="PMClicked($event,'7','5')"
                                                                            points="187.967,181.249 201.65,236.022 247.983,236.022 232.874,181.202 232.874,181.249 			" />
                                                                        <polygon (click)="PMClicked($event,'6','5')"
                                                                            points="159.864,203.623 162.706,236.022 201.033,236.022 187.351,181.249 157.956,181.249 			" />
                                                                        <polygon (click)="PMClicked($event,'2','5')"
                                                                            points="123.614,181.249 92.012,181.249 78.362,236.022 118.399,236.022 			" />
                                                                        <polygon (click)="PMClicked($event,'1','5')"
                                                                            points="77.746,236.022 91.396,181.249 46.857,181.249 46.857,181.052 46.77,181.052 31.855,235.895 31.82,236.022 			
                                               " />
                                                                        <polygon (click)="PMClicked($event,'6','6')"
                                                                            points="165.978,273.334 210.354,273.334 201.183,236.62 162.758,236.62 			" />
                                                                        <polygon (click)="PMClicked($event,'7','6')"
                                                                            points="201.799,236.62 210.97,273.334 258.266,273.334 248.062,236.31 248.062,236.62 			" />
                                                                        <polygon (click)="PMClicked($event,'2','6')"
                                                                            points="118.342,236.62 78.213,236.62 69.063,273.334 114.847,273.334 			" />
                                                                        <polygon (click)="PMClicked($event,'1','6')"
                                                                            points="77.597,236.62 31.788,236.62 31.788,236.276 31.751,236.276 21.675,273.334 68.447,273.334 			" />
                                                                        <polygon
                                                                            points="262.566,288.99 262.581,288.99 260.717,282.227 			" />
                                                                        <polygon
                                                                            points="17.419,288.99 17.435,288.99 18.543,284.854 			" />
                                                                        <polygon points="258.347,273.627 258.347,273.932 211.12,273.932 214.848,288.855 214.307,288.99 262.566,288.99 
                                               260.717,282.227 			" />
                                                                        <polygon points="68.298,273.932 21.623,273.932 21.623,273.576 21.61,273.576 18.543,284.854 17.435,288.99 65.12,288.99 
                                               64.579,288.855 			" />
                                                                        <polygon points="166.03,273.932 167.349,288.968 113.403,288.968 113.41,288.903 113.365,288.899 114.79,273.932 68.914,273.932 
                                               65.161,288.99 214.265,288.99 210.503,273.932 			" />
                                                                        <polygon (click)="PMClicked($event,'3','2')"
                                                                            points="136.882,105.07 137.852,86.92 133.196,86.92 131.468,105.07 			" />
                                                                        <polygon (click)="PMClicked($event,'5','2')"
                                                                            points="150.858,105.07 149.309,86.92 144.9,86.92 145.772,105.07 			" />
                                                                        <polygon (click)="PMClicked($event,'4','2')"
                                                                            points="144.772,105.07 143.899,86.92 138.853,86.92 137.883,105.07 			" />
                                                                        <polygon (click)="PMClicked($event,'3','3')"
                                                                            points="136.85,105.667 131.411,105.667 128.86,132.456 135.419,132.456 			" />
                                                                        <polygon (click)="PMClicked($event,'4','3')"
                                                                            points="146.089,132.456 144.801,105.667 137.851,105.667 136.42,132.456 			" />
                                                                        <polygon (click)="PMClicked($event,'5','3')"
                                                                            points="153.194,132.456 150.909,105.667 145.801,105.667 147.089,132.456 			" />
                                                                        <polygon (click)="PMClicked($event,'5','4')"
                                                                            points="149.407,180.651 157.305,180.651 153.245,133.054 147.118,133.054 			" />
                                                                        <polygon (click)="PMClicked($event,'3','4')"
                                                                            points="135.387,133.054 128.803,133.054 124.272,180.651 132.844,180.651 			" />
                                                                        <polygon (click)="PMClicked($event,'4','4')"
                                                                            points="146.118,133.054 136.388,133.054 133.845,180.651 148.406,180.651 			" />
                                                                        <polygon (click)="PMClicked($event,'3','5')"
                                                                            points="132.812,181.249 124.215,181.249 119,236.022 129.886,236.022 			" />
                                                                        <polygon (click)="PMClicked($event,'5','5')"
                                                                            points="152.069,236.022 162.028,236.022 157.356,181.249 149.435,181.249 			" />
                                                                        <polygon (click)="PMClicked($event,'4','5')"
                                                                            points="133.813,181.249 130.886,236.022 151.069,236.022 148.435,181.249 			" />
                                                                        <polygon (click)="PMClicked($event,'3','6')"
                                                                            points="129.854,236.62 118.943,236.62 115.447,273.334 127.892,273.334 			" />
                                                                        <polygon (click)="PMClicked($event,'5','6')"
                                                                            points="152.098,236.62 153.863,273.334 165.21,273.334 162.079,236.62 			" />
                                                                        <polygon (click)="PMClicked($event,'4','6')"
                                                                            points="130.854,236.62 128.893,273.334 152.863,273.334 151.097,236.62 			" />
                                                                        <polygon points="167.138,288.902 166.543,288.953 165.261,273.932 115.39,273.932 113.96,288.956 113.41,288.903 
                                               113.403,288.968 167.349,288.968 166.03,273.932 165.861,273.932 			" />
                                                                        <polygon
                                                                            points="65.159,289 65.161,288.99 65.12,288.99 			" />
                                                                        <polygon
                                                                            points="214.267,289 214.307,288.99 214.265,288.99 			" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>

                                                        <svg *ngIf="!isRHB" id="picthmapdata"
                                                            style="position: relative;" version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                            width="280px" height="289px" viewBox="0 0 280 289"
                                                            enable-background="new 0 0 280 289" xml:space="preserve">
                                                            <g id="Layer_1" display="none">
                                                            </g>
                                                            <g id="Layer_2">
                                                                <rect x="-1.375" y="-6.375" fill="#6A823F" width="284"
                                                                    height="296" />
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <defs>
                                                                                        <polygon id="SVGID_21_"
                                                                                            points="262.581,288.99 17.419,288.99 78.5,64.328 200.663,64.328 							" />
                                                                                    </defs>
                                                                                    <clipPath id="SVGID_2_">
                                                                                        <use xlink:href="#SVGID_21_"
                                                                                            overflow="visible" />
                                                                                    </clipPath>
                                                                                    <g clip-path="url(#SVGID_2_)">
                                                                                        <polygon fill="#BBAC9E"
                                                                                            points="262.572,289.012 17.43,289.012 77.731,63.916 201.041,63.916 							" />
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.5">
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#BBB18E"
                                                                                                points="206.889,86.92 72.374,86.92 78.5,64.328 200.663,64.328 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.3">
                                                                                    <g>
                                                                                        <polygon fill="#808080"
                                                                                            points="262.581,288.99 17.419,288.99 78.5,64.328 200.663,64.328 							" />
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.3">
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#FFD54F"
                                                                                                points="211.963,105.328 67.353,105.328 72.472,86.501 206.774,86.501 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.3">
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#EF657D"
                                                                                                points="219.516,132.731 59.87,132.731 67.353,105.328 211.963,105.328 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.3">
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#5FC447"
                                                                                                points="232.838,181.052 46.77,181.052 59.87,132.731 219.516,132.731 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.3">
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#9A77C9"
                                                                                                points="248.053,236.276 31.751,236.276 46.77,181.052 232.838,181.052 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.3">
                                                                                    <g>
                                                                                        <polygon fill="#A55E5E"
                                                                                            points="258.34,273.576 21.6,273.576 31.751,236.276 248.053,236.276 							" />
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.5">
                                                                                    <g>
                                                                                        <polygon fill="#333333"
                                                                                            points="262.581,288.99 17.419,288.99 21.6,273.576 258.34,273.576 							" />
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.4">
                                                                                    <g>
                                                                                        <polygon fill="#363E4D"
                                                                                            points="167.349,288.968 113.403,288.968 132.694,86.501 149.592,86.501 							" />
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <line fill="none"
                                                                                                stroke="#FFFFFF"
                                                                                                stroke-miterlimit="10"
                                                                                                x1="138.377" y1="86.454"
                                                                                                x2="128.377"
                                                                                                y2="273.626" />
                                                                                            <line fill="none"
                                                                                                stroke="#FFFFFF"
                                                                                                stroke-miterlimit="10"
                                                                                                x1="144.377" y1="86.454"
                                                                                                x2="153.377"
                                                                                                y2="273.626" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#FFFFFF" d="M205.127,97.797H73.618c-0.127,0-0.248-0.058-0.327-0.157c-0.079-0.099-0.109-0.23-0.081-0.353
										l5.739-22.173c0.05-0.225,0.274-0.368,0.5-0.316c0.226,0.051,0.367,0.275,0.315,0.5L74.142,96.96h130.461l-5.623-21.663
										c-0.051-0.225,0.09-0.449,0.315-0.5c0.227-0.053,0.449,0.091,0.5,0.316l5.739,22.173c0.029,0.124-0.002,0.254-0.081,0.353
										C205.375,97.739,205.254,97.797,205.127,97.797z" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#FFFFFF" d="M83.271,89.298c-0.023,0-0.047-0.002-0.071-0.007c-0.179-0.039-0.293-0.216-0.254-0.396
										l1.361-6.265c0.039-0.18,0.217-0.293,0.396-0.255c0.179,0.039,0.293,0.216,0.254,0.396l-1.361,6.265
										C83.563,89.192,83.425,89.298,83.271,89.298z" />
                                                                                            </g>
                                                                                        </g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#FFFFFF" d="M195.975,89.298c-0.153,0-0.291-0.106-0.325-0.262l-1.362-6.265
										c-0.039-0.18,0.075-0.357,0.254-0.396c0.183-0.038,0.358,0.075,0.396,0.254l1.362,6.265
										c0.039,0.18-0.075,0.357-0.254,0.396C196.022,89.296,195.998,89.298,195.975,89.298z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#FFFFFF" d="M201.946,86.71H76.846c-0.231,0-0.418-0.187-0.418-0.418s0.187-0.418,0.418-0.418h125.099
										c0.231,0,0.418,0.187,0.418,0.418S202.177,86.71,201.946,86.71z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <polygon fill="#FFFFFF"
                                                                                                    points="224.254,97.797 55.034,97.797 55.261,96.96 224.023,96.96 									" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g opacity="0.7">
                                                                                    <g>
                                                                                        <g>

                                                                                            <rect x="89.844" y="82.999"
                                                                                                transform="matrix(0.9703 0.2418 -0.2418 0.9703 48.0159 -16.2322)"
                                                                                                fill="#F2F2F2"
                                                                                                width="0.598"
                                                                                                height="209.03" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>

                                                                                            <rect x="84.694" y="187.215"
                                                                                                transform="matrix(0.2424 0.9702 -0.9702 0.2424 325.2883 -41.5139)"
                                                                                                fill="#F2F2F2"
                                                                                                width="209.059"
                                                                                                height="0.598" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>

                                                                                            <rect x="123.03" y="85.402"
                                                                                                transform="matrix(0.9955 0.0947 -0.0947 0.9955 18.3057 -10.8399)"
                                                                                                fill="#F2F2F2"
                                                                                                width="0.598"
                                                                                                height="203.984" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>

                                                                                            <rect x="56.409" y="187.215"
                                                                                                transform="matrix(0.085 0.9964 -0.9964 0.085 331.5812 13.9595)"
                                                                                                fill="#F2F2F2"
                                                                                                width="203.563"
                                                                                                height="0.598" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <rect x="67.427" y="105.069"
                                                                                                fill="#F2F2F2"
                                                                                                width="144.519"
                                                                                                height="0.598" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <rect x="59.892" y="132.456"
                                                                                                fill="#F2F2F2"
                                                                                                width="159.587"
                                                                                                height="0.598" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <rect x="46.857" y="180.651"
                                                                                                fill="#F2F2F2"
                                                                                                width="186.017"
                                                                                                height="0.598" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <rect x="31.789" y="236.022"
                                                                                                fill="#F2F2F2"
                                                                                                width="216.274"
                                                                                                height="0.598" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <rect x="21.623" y="273.334"
                                                                                                fill="#F2F2F2"
                                                                                                width="236.724"
                                                                                                height="0.598" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M223.339,278.679h1.036l0.601,4.017h0.107l0.872-4.017h1.144l0.872,4.017h0.107l0.601-4.017h1.036
							l-0.929,4.861h-1.494l-0.765-3.696l-0.765,3.696h-1.494L223.339,278.679z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M230.38,283.539v-4.861h0.986v4.861H230.38z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M234.061,283.539h-1.637v-4.861h1.637c0.419,0,0.765,0.044,1.036,0.132
							c0.272,0.088,0.483,0.234,0.633,0.436c0.15,0.203,0.254,0.441,0.311,0.715c0.057,0.274,0.086,0.63,0.086,1.069
							c0,0.439-0.026,0.802-0.079,1.09c-0.053,0.288-0.151,0.546-0.297,0.772c-0.145,0.226-0.356,0.391-0.633,0.493
							C234.842,283.488,234.49,283.539,234.061,283.539z M235.097,281.609c0.01-0.152,0.014-0.363,0.014-0.633
							c0-0.269-0.01-0.485-0.029-0.647c-0.019-0.162-0.064-0.31-0.136-0.443c-0.071-0.133-0.178-0.225-0.318-0.275
							c-0.141-0.05-0.33-0.075-0.568-0.075h-0.651v3.145h0.651c0.357,0,0.617-0.09,0.779-0.272
							C234.978,282.262,235.064,281.995,235.097,281.609z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M236.984,283.539v-4.861h3.145v0.858h-2.159v1.137h1.73v0.843h-1.73v1.165h2.159v0.858H236.984z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M245.88,283.021c-0.303,0.403-0.823,0.604-1.562,0.604c-0.739,0-1.259-0.201-1.562-0.604
							c-0.303-0.403-0.454-1.033-0.454-1.891c0-0.858,0.151-1.495,0.454-1.912c0.303-0.417,0.823-0.625,1.562-0.625
							c0.739,0,1.259,0.209,1.562,0.625c0.302,0.417,0.454,1.054,0.454,1.912C246.334,281.988,246.182,282.618,245.88,283.021z
							 M243.528,282.392c0.141,0.25,0.404,0.375,0.79,0.375c0.386,0,0.649-0.125,0.79-0.375c0.141-0.25,0.211-0.669,0.211-1.258
							c0-0.589-0.071-1.016-0.214-1.283c-0.143-0.267-0.405-0.4-0.786-0.4s-0.643,0.133-0.786,0.4
							c-0.143,0.267-0.214,0.695-0.214,1.283C243.317,281.722,243.387,282.142,243.528,282.392z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M247.048,283.539v-1.187h1.029v1.187H247.048z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M252.37,283.021c-0.303,0.403-0.823,0.604-1.562,0.604c-0.739,0-1.259-0.201-1.562-0.604
							c-0.303-0.403-0.454-1.033-0.454-1.891c0-0.858,0.151-1.495,0.454-1.912c0.302-0.417,0.823-0.625,1.562-0.625
							c0.739,0,1.259,0.209,1.562,0.625c0.303,0.417,0.454,1.054,0.454,1.912C252.824,281.988,252.673,282.618,252.37,283.021z
							 M250.018,282.392c0.141,0.25,0.404,0.375,0.79,0.375c0.386,0,0.649-0.125,0.79-0.375c0.14-0.25,0.211-0.669,0.211-1.258
							c0-0.589-0.071-1.016-0.214-1.283c-0.143-0.267-0.405-0.4-0.786-0.4c-0.381,0-0.643,0.133-0.786,0.4
							c-0.143,0.267-0.214,0.695-0.214,1.283C249.808,281.722,249.878,282.142,250.018,282.392z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M28.424,279.111h1.036l0.6,4.017h0.107l0.872-4.017h1.144l0.872,4.017h0.107l0.6-4.017H34.8
							l-0.929,4.861h-1.494l-0.765-3.696l-0.765,3.696h-1.494L28.424,279.111z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M35.465,283.971v-4.861h0.987v4.861H35.465z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M39.146,283.971h-1.637v-4.861h1.637c0.419,0,0.765,0.044,1.036,0.132
							c0.272,0.088,0.482,0.234,0.633,0.436c0.15,0.203,0.254,0.441,0.311,0.715c0.057,0.274,0.086,0.63,0.086,1.069
							c0,0.439-0.026,0.802-0.079,1.09c-0.052,0.288-0.151,0.546-0.297,0.772c-0.145,0.226-0.356,0.391-0.633,0.493
							C39.928,283.92,39.575,283.971,39.146,283.971z M40.183,282.041c0.009-0.152,0.014-0.363,0.014-0.633
							c0-0.269-0.01-0.485-0.029-0.647c-0.019-0.162-0.064-0.31-0.136-0.443c-0.072-0.133-0.178-0.225-0.318-0.275
							c-0.141-0.05-0.33-0.075-0.568-0.075h-0.65v3.145h0.65c0.357,0,0.617-0.09,0.779-0.272
							C40.063,282.694,40.149,282.427,40.183,282.041z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M42.069,283.971v-4.861h3.145v0.858h-2.159v1.137h1.73v0.843h-1.73v1.165h2.159v0.858H42.069z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M49.239,283.971h-1.637v-4.861h1.637c0.419,0,0.765,0.044,1.036,0.132
							c0.272,0.088,0.482,0.234,0.633,0.436c0.15,0.203,0.254,0.441,0.311,0.715c0.057,0.274,0.086,0.63,0.086,1.069
							c0,0.439-0.026,0.802-0.079,1.09c-0.052,0.288-0.151,0.546-0.297,0.772c-0.145,0.226-0.356,0.391-0.633,0.493
							C50.02,283.92,49.668,283.971,49.239,283.971z M50.275,282.041c0.009-0.152,0.014-0.363,0.014-0.633
							c0-0.269-0.01-0.485-0.029-0.647c-0.019-0.162-0.064-0.31-0.136-0.443c-0.072-0.133-0.178-0.225-0.318-0.275
							c-0.141-0.05-0.33-0.075-0.568-0.075h-0.65v3.145h0.65c0.357,0,0.617-0.09,0.779-0.272
							C50.156,282.694,50.242,282.427,50.275,282.041z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M52.033,283.971v-1.187h1.029v1.187H52.033z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M56.758,283.971h-2.766v-4.861h0.986v3.989h1.78V283.971z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M173.141,283.436c-0.303,0.403-0.823,0.604-1.562,0.604c-0.739,0-1.259-0.201-1.562-0.604
							c-0.303-0.403-0.454-1.033-0.454-1.891c0-0.858,0.151-1.495,0.454-1.912c0.302-0.417,0.823-0.625,1.562-0.625
							c0.739,0,1.259,0.209,1.562,0.625c0.303,0.417,0.454,1.054,0.454,1.912C173.595,282.403,173.444,283.034,173.141,283.436z
							 M170.79,282.807c0.14,0.25,0.404,0.375,0.79,0.375c0.386,0,0.649-0.125,0.79-0.375c0.14-0.25,0.211-0.669,0.211-1.258
							c0-0.589-0.072-1.016-0.215-1.283c-0.143-0.267-0.405-0.4-0.786-0.4c-0.381,0-0.643,0.133-0.786,0.4
							c-0.143,0.267-0.215,0.695-0.215,1.283C170.579,282.138,170.649,282.557,170.79,282.807z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M175.382,282.332c0,0.567,0.288,0.851,0.865,0.851c0.577,0,0.865-0.283,0.865-0.851v-3.238h0.986
							v3.217c0,0.596-0.154,1.033-0.461,1.312c-0.307,0.279-0.771,0.418-1.39,0.418c-0.62,0-1.083-0.139-1.39-0.418
							c-0.307-0.279-0.461-0.716-0.461-1.312v-3.217h0.986V282.332z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M178.677,279.966v-0.872h3.574v0.872h-1.287v3.989h-0.986v-3.989H178.677z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M184.367,279.866c-0.519,0-0.779,0.172-0.779,0.515c0,0.153,0.064,0.269,0.193,0.35
							c0.129,0.081,0.43,0.198,0.904,0.35c0.474,0.153,0.807,0.325,0.997,0.518c0.19,0.193,0.286,0.49,0.286,0.89
							c0,0.51-0.155,0.896-0.465,1.158c-0.31,0.262-0.715,0.393-1.215,0.393c-0.372,0-0.827-0.057-1.365-0.172l-0.257-0.05
							l0.1-0.765c0.638,0.086,1.127,0.129,1.465,0.129c0.505,0,0.758-0.21,0.758-0.629c0-0.152-0.058-0.272-0.175-0.357
							c-0.117-0.086-0.349-0.179-0.697-0.279c-0.553-0.157-0.942-0.344-1.169-0.561c-0.226-0.217-0.339-0.518-0.339-0.904
							c0-0.486,0.148-0.848,0.443-1.087c0.295-0.238,0.7-0.357,1.215-0.357c0.353,0,0.801,0.048,1.344,0.143l0.257,0.05
							l-0.079,0.779C185.118,279.904,184.644,279.866,184.367,279.866z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M186.762,283.955v-4.861h0.986v4.861H186.762z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M190.443,283.955h-1.637v-4.861h1.637c0.419,0,0.765,0.044,1.036,0.132
							c0.272,0.088,0.483,0.234,0.633,0.436c0.15,0.203,0.254,0.441,0.311,0.715c0.057,0.274,0.086,0.63,0.086,1.069
							c0,0.439-0.026,0.802-0.079,1.09c-0.053,0.288-0.151,0.546-0.297,0.772c-0.145,0.226-0.356,0.391-0.633,0.493
							C191.224,283.903,190.872,283.955,190.443,283.955z M191.479,282.025c0.01-0.152,0.014-0.363,0.014-0.633
							c0-0.269-0.01-0.485-0.029-0.647c-0.019-0.162-0.064-0.31-0.136-0.443c-0.071-0.133-0.178-0.225-0.318-0.275
							c-0.141-0.05-0.33-0.075-0.568-0.075h-0.651v3.145h0.651c0.357,0,0.617-0.09,0.779-0.272
							C191.36,282.678,191.446,282.411,191.479,282.025z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M193.367,283.955v-4.861h3.145v0.858h-2.159v1.137h1.73v0.843h-1.73v1.165h2.159v0.858H193.367z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M202.262,283.436c-0.303,0.403-0.823,0.604-1.562,0.604c-0.739,0-1.259-0.201-1.562-0.604
							c-0.303-0.403-0.454-1.033-0.454-1.891c0-0.858,0.151-1.495,0.454-1.912c0.302-0.417,0.823-0.625,1.562-0.625
							c0.739,0,1.259,0.209,1.562,0.625c0.303,0.417,0.454,1.054,0.454,1.912C202.716,282.403,202.565,283.034,202.262,283.436z
							 M199.91,282.807c0.141,0.25,0.404,0.375,0.79,0.375c0.386,0,0.649-0.125,0.79-0.375c0.14-0.25,0.211-0.669,0.211-1.258
							c0-0.589-0.071-1.016-0.214-1.283c-0.143-0.267-0.405-0.4-0.786-0.4s-0.643,0.133-0.786,0.4
							c-0.143,0.267-0.214,0.695-0.214,1.283C199.699,282.138,199.77,282.557,199.91,282.807z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M203.559,283.955v-4.861h3.102v0.858h-2.116v1.43h1.73v0.858h-1.73v1.716H203.559z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M207.355,283.955v-4.861h3.102v0.858h-2.116v1.43h1.73v0.858h-1.73v1.716H207.355z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M74.68,283.436c-0.303,0.403-0.823,0.604-1.562,0.604c-0.739,0-1.259-0.201-1.562-0.604
							c-0.303-0.403-0.454-1.033-0.454-1.891c0-0.858,0.151-1.495,0.454-1.912c0.303-0.417,0.823-0.625,1.562-0.625
							c0.739,0,1.259,0.209,1.562,0.625c0.303,0.417,0.454,1.054,0.454,1.912C75.134,282.403,74.983,283.034,74.68,283.436z
							 M72.329,282.807c0.14,0.25,0.404,0.375,0.79,0.375c0.386,0,0.649-0.125,0.79-0.375c0.14-0.25,0.211-0.669,0.211-1.258
							c0-0.589-0.071-1.016-0.214-1.283c-0.143-0.267-0.405-0.4-0.786-0.4c-0.381,0-0.643,0.133-0.786,0.4
							c-0.143,0.267-0.214,0.695-0.214,1.283C72.118,282.138,72.188,282.557,72.329,282.807z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M76.921,282.332c0,0.567,0.288,0.851,0.865,0.851c0.576,0,0.865-0.283,0.865-0.851v-3.238h0.986v3.217
							c0,0.596-0.154,1.033-0.461,1.312c-0.307,0.279-0.771,0.418-1.39,0.418c-0.62,0-1.083-0.139-1.39-0.418
							c-0.307-0.279-0.461-0.716-0.461-1.312v-3.217h0.986V282.332z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M80.216,279.966v-0.872h3.574v0.872h-1.287v3.989h-0.986v-3.989H80.216z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M85.906,279.866c-0.519,0-0.779,0.172-0.779,0.515c0,0.153,0.064,0.269,0.193,0.35
							c0.129,0.081,0.43,0.198,0.904,0.35c0.474,0.153,0.807,0.325,0.997,0.518c0.191,0.193,0.286,0.49,0.286,0.89
							c0,0.51-0.155,0.896-0.465,1.158c-0.31,0.262-0.715,0.393-1.215,0.393c-0.372,0-0.827-0.057-1.365-0.172l-0.257-0.05
							l0.1-0.765c0.638,0.086,1.127,0.129,1.465,0.129c0.505,0,0.758-0.21,0.758-0.629c0-0.152-0.059-0.272-0.175-0.357
							c-0.117-0.086-0.349-0.179-0.697-0.279c-0.553-0.157-0.942-0.344-1.169-0.561c-0.226-0.217-0.339-0.518-0.339-0.904
							c0-0.486,0.148-0.848,0.443-1.087c0.296-0.238,0.701-0.357,1.215-0.357c0.353,0,0.801,0.048,1.344,0.143l0.257,0.05
							l-0.079,0.779C86.657,279.904,86.182,279.866,85.906,279.866z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M88.301,283.955v-4.861h0.986v4.861H88.301z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M91.982,283.955h-1.637v-4.861h1.637c0.419,0,0.765,0.044,1.036,0.132
							c0.272,0.088,0.482,0.234,0.633,0.436c0.15,0.203,0.254,0.441,0.311,0.715c0.057,0.274,0.086,0.63,0.086,1.069
							c0,0.439-0.026,0.802-0.079,1.09c-0.052,0.288-0.151,0.546-0.297,0.772c-0.145,0.226-0.356,0.391-0.633,0.493
							C92.763,283.903,92.411,283.955,91.982,283.955z M93.018,282.025c0.009-0.152,0.014-0.363,0.014-0.633
							c0-0.269-0.01-0.485-0.029-0.647c-0.019-0.162-0.064-0.31-0.136-0.443c-0.072-0.133-0.178-0.225-0.318-0.275
							c-0.141-0.05-0.33-0.075-0.568-0.075h-0.65v3.145h0.65c0.357,0,0.617-0.09,0.779-0.272
							C92.899,282.678,92.985,282.411,93.018,282.025z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M94.905,283.955v-4.861h3.145v0.858h-2.159v1.137h1.73v0.843h-1.73v1.165h2.159v0.858H94.905z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M103.204,283.955h-2.766v-4.861h0.986v3.989h1.78V283.955z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M103.812,283.955v-4.861h3.145v0.858h-2.159v1.137h1.73v0.843h-1.73v1.165h2.159v0.858H103.812z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M109.751,282.21v-0.858h1.415v2.523c-0.682,0.11-1.234,0.164-1.658,0.164
							c-0.758,0-1.278-0.202-1.562-0.608c-0.284-0.405-0.425-1.048-0.425-1.93c0-0.882,0.148-1.518,0.443-1.909
							c0.295-0.391,0.798-0.586,1.508-0.586c0.443,0,0.922,0.05,1.437,0.15l0.257,0.05l-0.029,0.765
							c-0.572-0.062-1.04-0.093-1.405-0.093c-0.365,0-0.621,0.043-0.768,0.129c-0.148,0.086-0.256,0.244-0.325,0.475
							c-0.069,0.231-0.104,0.627-0.104,1.187c0,0.56,0.069,0.952,0.207,1.176c0.138,0.224,0.424,0.336,0.858,0.336l0.593-0.029
							v-0.944H109.751z" />
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M123.677,279.866c-0.519,0-0.779,0.172-0.779,0.515c0,0.153,0.064,0.269,0.193,0.35
							c0.129,0.081,0.43,0.198,0.904,0.35c0.474,0.153,0.807,0.325,0.997,0.518c0.191,0.193,0.286,0.49,0.286,0.89
							c0,0.51-0.155,0.896-0.465,1.158c-0.31,0.262-0.715,0.393-1.215,0.393c-0.372,0-0.827-0.057-1.365-0.172l-0.257-0.05
							l0.1-0.765c0.638,0.086,1.127,0.129,1.465,0.129c0.505,0,0.758-0.21,0.758-0.629c0-0.152-0.059-0.272-0.175-0.357
							c-0.117-0.086-0.349-0.179-0.697-0.279c-0.553-0.157-0.942-0.344-1.169-0.561c-0.226-0.217-0.339-0.518-0.339-0.904
							c0-0.486,0.148-0.848,0.443-1.087c0.296-0.238,0.701-0.357,1.215-0.357c0.353,0,0.801,0.048,1.344,0.143l0.257,0.05
							l-0.079,0.779C124.428,279.904,123.953,279.866,123.677,279.866z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M125.635,279.966v-0.872h3.574v0.872h-1.287v3.989h-0.986v-3.989H125.635z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M130.775,282.332c0,0.567,0.288,0.851,0.865,0.851c0.577,0,0.865-0.283,0.865-0.851v-3.238h0.986
							v3.217c0,0.596-0.154,1.033-0.461,1.312c-0.307,0.279-0.771,0.418-1.39,0.418c-0.62,0-1.083-0.139-1.39-0.418
							c-0.307-0.279-0.461-0.716-0.461-1.312v-3.217h0.986V282.332z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M134.506,283.955v-4.861h1.68l0.858,3.545l0.858-3.545h1.68v4.861h-0.986v-3.76h-0.107l-0.951,3.545
							h-0.986l-0.951-3.545h-0.107v3.76H134.506z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M142.426,282.539h-0.801v1.415h-0.986v-4.861h1.787c1.153,0,1.73,0.562,1.73,1.687
							c0,0.567-0.146,1.002-0.44,1.305C143.423,282.388,142.993,282.539,142.426,282.539z M141.626,281.696h0.793
							c0.491,0,0.736-0.305,0.736-0.915c0-0.3-0.06-0.516-0.179-0.647c-0.119-0.131-0.305-0.197-0.558-0.197h-0.793V281.696z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M149.188,283.955h-2.766v-4.861h0.986v3.989h1.78V283.955z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M149.795,283.955v-4.861h0.986v4.861H149.795z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF" d="M151.84,283.955v-4.861h1.665l1.172,4.003h0.072v-4.003h0.986v4.861h-1.623l-1.215-4.003h-0.072v4.003
							H151.84z" />
                                                                                </g>
                                                                                <g>
                                                                                    <path fill="#FFFFFF"
                                                                                        d="M156.793,283.955v-4.861h3.145v0.858h-2.159v1.137h1.73v0.843h-1.73v1.165h2.159v0.858H156.793z" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <g opacity="0.7">
                                                                                <g>
                                                                                    <g>

                                                                                        <rect x="123.095" y="86.181"
                                                                                            transform="matrix(0.9955 0.0947 -0.0947 0.9955 18.3428 -10.8441)"
                                                                                            fill="#F2F2F2" width="0.596"
                                                                                            height="203.205" />
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>

                                                                                        <rect x="56.728" y="187.604"
                                                                                            transform="matrix(0.085 0.9964 -0.9964 0.085 331.9041 14.3837)"
                                                                                            fill="#F2F2F2"
                                                                                            width="202.785"
                                                                                            height="0.596" />
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <rect x="22.076" y="273.394"
                                                                                            fill="#F2F2F2"
                                                                                            width="235.819"
                                                                                            height="0.596" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#FCD462"
                                                                                                points="146.288,86.214 147.978,86.214 147.978,57.005 146.288,57.005 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#FCD462"
                                                                                                points="140.106,86.214 141.796,86.214 141.796,57.005 140.106,57.005 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <polygon fill="#FCD462"
                                                                                                points="133.873,86.214 135.563,86.214 135.563,57.005 133.873,57.005 								" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <polygon fill="#F6C358" points="141.076,56.133 142.004,56.133 142.004,55.747 146.083,55.747 146.083,56.133 147.31,56.133 
								147.31,56.997 146.083,56.997 146.083,57.384 142.004,57.384 142.004,56.997 141.076,56.997 							" />
                                                                                    </g>
                                                                                    <g>
                                                                                        <polygon fill="#F6C358"
                                                                                            points="134.512,56.133 135.807,56.133 135.807,55.747 139.892,55.747 139.892,56.133 
								140.747,56.133 140.747,56.997 139.892,56.997 139.892,57.384 135.807,57.384 135.807,56.997 134.512,56.997 							" />
                                                                                    </g>
                                                                                </g>
                                                                                <g>

                                                                                    <image overflow="visible"
                                                                                        width="280" height="600"
                                                                                        xlink:href="assets/images/Cric.png"
                                                                                        transform="matrix(-0.1771 0 0 0.1771 156.9792 -2.4488)">
                                                                                    </image>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                        <g opacity="0.5">
                                                                            <g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#333333" d="M31.267,252.391h2.92c0.786,0,1.337,0.143,1.654,0.43c0.317,0.287,0.395,0.745,0.235,1.374
									c-0.105,0.414-0.261,0.74-0.469,0.977c-0.207,0.239-0.489,0.435-0.845,0.592c0.672,0.259,0.903,0.802,0.693,1.629
									c-0.338,1.331-1.333,1.998-2.983,1.998h-3.043L31.267,252.391z M33.869,253.362H32.26l-0.52,1.993H33.4
									c0.737,0,1.194-0.343,1.37-1.029C34.934,253.684,34.634,253.362,33.869,253.362z M33.174,256.316H31.49l-0.543,2.084h1.712
									c0.416,0,0.748-0.079,0.996-0.237c0.248-0.158,0.421-0.428,0.52-0.812c0.098-0.383,0.046-0.652-0.156-0.805
									C33.818,256.393,33.536,256.316,33.174,256.316z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#333333"
                                                                                                d="M37.694,254.816c0.498-0.441,1.197-0.662,2.098-0.662c0.901,0,1.491,0.22,1.769,0.662
									c0.279,0.441,0.31,1.105,0.093,1.992c-0.217,0.89-0.571,1.562-1.06,2.015c-0.49,0.454-1.201,0.682-2.132,0.682
									c-0.931,0-1.529-0.228-1.794-0.682c-0.264-0.453-0.283-1.126-0.059-2.015C36.834,255.921,37.195,255.257,37.694,254.816z
									 M37.781,258.143c0.105,0.275,0.411,0.412,0.918,0.412c0.507,0,0.881-0.137,1.123-0.412
									c0.241-0.274,0.439-0.723,0.592-1.345c0.153-0.621,0.169-1.061,0.049-1.319c-0.12-0.259-0.421-0.387-0.903-0.387
									c-0.481,0-0.846,0.129-1.095,0.387c-0.249,0.259-0.451,0.698-0.607,1.319C37.701,257.42,37.675,257.869,37.781,258.143z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#333333" d="M46.906,254.266h1.217l-1.206,5.125h-1.227l0.076-0.32c-0.621,0.289-1.167,0.434-1.636,0.434
									c-0.78,0-1.256-0.191-1.429-0.573c-0.172-0.381-0.143-1.049,0.088-2l0.647-2.665h1.228l-0.644,2.675
									c-0.148,0.615-0.191,1.031-0.129,1.243c0.062,0.213,0.301,0.319,0.717,0.319c0.408,0,0.799-0.068,1.172-0.206l0.185-0.062
									L46.906,254.266z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#333333" d="M49.654,259.391h-1.238l1.194-5.125h1.217l-0.073,0.315c0.614-0.285,1.165-0.428,1.653-0.428
									c0.751,0,1.22,0.192,1.407,0.575c0.188,0.384,0.182,1.018-0.018,1.904l-0.622,2.757h-1.227l0.62-2.726
									c0.126-0.553,0.149-0.945,0.07-1.176c-0.079-0.231-0.322-0.347-0.728-0.347c-0.383,0-0.768,0.068-1.153,0.204l-0.183,0.061
									L49.654,259.391z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#333333" d="M57.892,254.154c0.398,0,0.857,0.048,1.377,0.142l0.271,0.051l-0.235,0.876
									c-0.582-0.054-1.016-0.082-1.302-0.082c-0.572,0-0.981,0.116-1.227,0.347c-0.247,0.232-0.442,0.668-0.584,1.309
									c-0.142,0.643-0.147,1.088-0.012,1.335c0.134,0.247,0.5,0.371,1.098,0.371l1.345-0.082l-0.161,0.897
									c-0.792,0.124-1.381,0.186-1.767,0.186c-0.863,0-1.424-0.212-1.682-0.635c-0.257-0.423-0.279-1.114-0.065-2.072
									c0.214-0.955,0.549-1.634,1.008-2.038C56.413,254.356,57.058,254.154,57.892,254.154z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#333333" d="M63.817,258.462l0.324-0.031l-0.152,0.836c-0.896,0.158-1.678,0.238-2.344,0.238
									c-0.841,0-1.4-0.21-1.676-0.63c-0.277-0.419-0.316-1.088-0.118-2.005c0.391-1.813,1.401-2.715,3.03-2.715
									c1.577,0,2.203,0.777,1.879,2.336l-0.246,0.8h-3.509c-0.083,0.425-0.048,0.737,0.106,0.936
									c0.154,0.199,0.5,0.298,1.036,0.298C62.685,258.524,63.241,258.503,63.817,258.462z M63.564,256.429
									c0.106-0.505,0.091-0.857-0.043-1.058c-0.135-0.201-0.412-0.301-0.833-0.301c-0.421,0-0.75,0.106-0.987,0.316
									c-0.237,0.211-0.411,0.558-0.522,1.042H63.564z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <path fill="#333333" d="M65.349,259.391l1.057-5.125h1.217l-0.125,0.611c0.715-0.373,1.402-0.614,2.062-0.723l-0.224,1.11
									c-0.67,0.116-1.254,0.265-1.75,0.449l-0.267,0.092l-0.733,3.586H65.349z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M50.194,207.375c-1.089,0-1.732,0.333-1.929,1.001c-0.102,0.347-0.046,0.593,0.169,0.74
										c0.215,0.147,0.733,0.328,1.553,0.544c0.821,0.216,1.371,0.473,1.647,0.773c0.277,0.3,0.324,0.774,0.139,1.422
										c-0.236,0.827-0.68,1.447-1.332,1.859c-0.654,0.412-1.443,0.619-2.367,0.619c-0.741,0-1.537-0.078-2.388-0.232
										l-0.446-0.081l0.45-1.028c1.132,0.138,2.006,0.207,2.623,0.207c1.078,0,1.738-0.422,1.981-1.264
										c0.095-0.328,0.043-0.571-0.154-0.727c-0.197-0.156-0.692-0.327-1.485-0.513c-0.792-0.186-1.347-0.443-1.664-0.768
										c-0.317-0.325-0.371-0.839-0.162-1.539c0.208-0.698,0.625-1.224,1.248-1.579c0.623-0.354,1.391-0.531,2.307-0.531
										c0.659,0,1.418,0.067,2.278,0.201l0.43,0.067l-0.404,1.009C51.523,207.434,50.691,207.375,50.194,207.375z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M53.926,214.205h-1.424l2.268-8.051h1.398l-0.732,2.628c0.714-0.278,1.341-0.418,1.883-0.418
										c0.859,0,1.388,0.213,1.587,0.638c0.199,0.427,0.166,1.132-0.1,2.12l-0.83,3.082h-1.424l0.83-3.048
										c0.168-0.616,0.213-1.052,0.137-1.31c-0.077-0.257-0.352-0.386-0.825-0.386c-0.414,0-0.844,0.06-1.291,0.181l-0.226,0.068
										L53.926,214.205z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333"
                                                                                                    d="M61.346,209.099c0.572-0.49,1.374-0.734,2.405-0.734c1.031,0,1.706,0.245,2.024,0.734
										c0.319,0.491,0.354,1.23,0.104,2.218c-0.251,0.993-0.658,1.745-1.222,2.252c-0.565,0.509-1.383,0.764-2.455,0.764
										c-1.071,0-1.758-0.255-2.059-0.764c-0.3-0.508-0.317-1.259-0.053-2.252C60.354,210.328,60.772,209.59,61.346,209.099z
										 M61.426,212.808c0.119,0.307,0.47,0.461,1.052,0.461c0.582,0,1.013-0.154,1.292-0.461
										c0.278-0.307,0.506-0.808,0.685-1.503c0.177-0.692,0.197-1.182,0.059-1.47c-0.137-0.287-0.481-0.431-1.033-0.431
										c-0.551,0-0.97,0.144-1.257,0.431c-0.287,0.288-0.521,0.778-0.703,1.47C61.339,212,61.307,212.501,61.426,212.808z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M66.56,214.205l1.428-5.716h1.393l-0.167,0.678c0.833-0.415,1.629-0.682,2.389-0.802l-0.298,1.233
										c-0.773,0.129-1.447,0.295-2.023,0.5l-0.31,0.103l-0.987,4.005H66.56z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M75.869,209.541h-1.784l-0.59,2.509c-0.11,0.467-0.144,0.778-0.102,0.931
										c0.042,0.154,0.224,0.231,0.545,0.231l1.077-0.034l-0.167,1.005c-0.607,0.101-1.063,0.151-1.368,0.151
										c-0.741,0-1.212-0.151-1.415-0.452c-0.203-0.301-0.205-0.866-0.008-1.694l0.631-2.646h-0.827l0.253-1.052h0.825l0.388-1.63
										h1.388l-0.383,1.63h1.78L75.869,209.541z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M64.233,159.261l0.212-1.027h2.767l-0.77,3.629c-0.264,0.049-0.789,0.1-1.575,0.155
										c-0.786,0.055-1.353,0.083-1.701,0.083c-1.465,0-2.414-0.297-2.85-0.891c-0.437-0.596-0.529-1.532-0.275-2.813
										c0.255-1.287,0.73-2.229,1.423-2.823c0.695-0.595,1.752-0.893,3.172-0.893c0.838,0,1.766,0.071,2.784,0.213l0.484,0.075
										l-0.255,0.934c-1.126-0.113-2.146-0.17-3.058-0.17c-0.913,0-1.577,0.191-1.991,0.572c-0.414,0.38-0.724,1.084-0.93,2.108
										c-0.205,1.02-0.189,1.721,0.049,2.106c0.237,0.385,0.835,0.577,1.795,0.577c0.687,0,1.206-0.031,1.558-0.093l0.364-1.741
										H64.233z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333"
                                                                                                    d="M69.587,157.329c0.587-0.457,1.451-0.686,2.589-0.686c1.139,0,1.901,0.229,2.286,0.686
										c0.385,0.456,0.473,1.139,0.267,2.047c-0.205,0.905-0.595,1.584-1.169,2.041c-0.573,0.456-1.436,0.684-2.591,0.684
										c-1.155,0-1.918-0.228-2.289-0.684c-0.372-0.457-0.461-1.137-0.266-2.041C68.609,158.469,69,157.786,69.587,157.329z
										 M70.001,160.732c0.156,0.277,0.549,0.416,1.179,0.416c0.63,0,1.084-0.138,1.363-0.416
										c0.279-0.277,0.489-0.733,0.631-1.366c0.142-0.635,0.127-1.086-0.045-1.352c-0.173-0.266-0.562-0.4-1.168-0.4
										c-0.606,0-1.055,0.133-1.345,0.4c-0.29,0.266-0.504,0.718-0.642,1.352C69.836,159.999,69.845,160.455,70.001,160.732z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M77.121,157.329c0.594-0.457,1.461-0.686,2.599-0.686c1.139,0,1.898,0.229,2.276,0.686
										c0.378,0.456,0.457,1.139,0.237,2.047c-0.219,0.905-0.618,1.584-1.198,2.041c-0.579,0.456-1.446,0.684-2.601,0.684
										c-1.155,0-1.914-0.228-2.279-0.684c-0.365-0.457-0.444-1.137-0.237-2.041C76.127,158.469,76.528,157.786,77.121,157.329z
										 M77.486,160.732c0.152,0.277,0.543,0.416,1.173,0.416c0.63,0,1.086-0.138,1.369-0.416c0.283-0.277,0.5-0.733,0.651-1.366
										c0.151-0.635,0.143-1.086-0.025-1.352c-0.169-0.266-0.556-0.4-1.162-0.4c-0.606,0-1.056,0.133-1.351,0.4
										c-0.294,0.266-0.514,0.718-0.662,1.352C77.332,159.999,77.334,160.455,77.486,160.732z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M90.774,154.553l-1.901,7.434h-1.522l0.068-0.269c-0.75,0.256-1.411,0.383-1.984,0.383
										c-0.921,0-1.544-0.204-1.871-0.611c-0.328-0.408-0.375-1.095-0.139-2.062c0.237-0.97,0.649-1.678,1.235-2.12
										c0.587-0.443,1.389-0.665,2.405-0.665c0.342,0,0.871,0.046,1.588,0.137l0.563-2.227H90.774z M87.37,160.909l0.258-0.073
										l0.782-3.096c-0.55-0.07-1.067-0.105-1.55-0.105c-0.957,0-1.58,0.589-1.87,1.762c-0.158,0.641-0.157,1.083,0.003,1.33
										c0.16,0.246,0.48,0.369,0.96,0.369C86.434,161.096,86.905,161.034,87.37,160.909z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M75.361,125.282l1.879-7.488l4.284-0.045l-0.269,1.068l-3.168,0.028l-0.635,2.53l2.628-0.015
										l-0.268,1.065l-2.627,0.011l-0.714,2.844L75.361,125.282z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M84.563,119.758l1.106-0.008l-1.396,5.531l-1.103,0l0.086-0.342
										c-0.574,0.309-1.071,0.464-1.491,0.464c-0.697,0-1.112-0.203-1.246-0.61c-0.134-0.407-0.072-1.119,0.184-2.138l0.723-2.872
										l1.107-0.008l-0.727,2.887c-0.166,0.661-0.228,1.105-0.183,1.332c0.044,0.227,0.252,0.341,0.625,0.34
										c0.366-0.001,0.721-0.075,1.064-0.222l0.17-0.066L84.563,119.758z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333"
                                                                                                    d="M85.38,125.28l1.982-7.847l1.124-0.012L86.5,125.28L85.38,125.28z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333"
                                                                                                    d="M87.674,125.28l1.991-7.871l1.131-0.012l-1.995,7.883L87.674,125.28z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M77.74,101.904h-1.291l0.676-3.035l-1.518-4.357h1.447l0.969,3.18l2.4-3.18h1.435l-3.439,4.357
										L77.74,101.904z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M81.605,97.099c0.502-0.468,1.216-0.703,2.141-0.703s1.533,0.234,1.823,0.703
										c0.29,0.468,0.329,1.17,0.116,2.104c-0.213,0.934-0.565,1.637-1.057,2.11c-0.492,0.473-1.21,0.709-2.155,0.709
										c-0.945,0-1.556-0.236-1.834-0.709c-0.278-0.473-0.312-1.176-0.102-2.11C80.746,98.269,81.102,97.567,81.605,97.099z
										 M81.756,100.603c0.112,0.287,0.425,0.43,0.941,0.43c0.515,0,0.893-0.143,1.135-0.43c0.242-0.287,0.437-0.757,0.586-1.411
										c0.149-0.654,0.16-1.118,0.033-1.391c-0.127-0.274-0.436-0.41-0.929-0.41c-0.493,0-0.865,0.137-1.115,0.41
										c-0.251,0.274-0.449,0.737-0.597,1.391C81.661,99.846,81.643,100.316,81.756,100.603z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M86.313,101.904l1.232-5.389h1.249l-0.149,0.649c0.746-0.396,1.46-0.652,2.142-0.767l-0.272,1.178
										c-0.691,0.123-1.293,0.281-1.808,0.475l-0.276,0.097l-0.862,3.757H86.313z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M91.73,101.904h-1.256l1.766-7.641h1.262l-1.026,4.422l0.731-0.065l1.854-2.106h1.41l-2.253,2.526
										l1.084,2.863h-1.417l-0.878-2.27l-0.767,0.075L91.73,101.904z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M100.43,100.936l0.33-0.032l-0.183,0.871c-0.915,0.165-1.71,0.247-2.386,0.247
										c-0.853,0-1.414-0.218-1.684-0.656c-0.27-0.437-0.293-1.136-0.067-2.099c0.448-1.913,1.51-2.872,3.182-2.872
										c1.619,0,2.232,0.825,1.841,2.473l-0.28,0.841h-3.577c-0.097,0.445-0.07,0.772,0.081,0.98
										c0.151,0.208,0.499,0.312,1.045,0.312C99.276,101.001,99.843,100.98,100.43,100.936z M100.242,98.804
										c0.126-0.532,0.123-0.904-0.007-1.117c-0.131-0.212-0.412-0.319-0.843-0.319c-0.431,0-0.772,0.112-1.02,0.335
										c-0.249,0.223-0.437,0.59-0.565,1.101H100.242z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M101.952,101.904l1.283-5.389h1.249l-0.155,0.649c0.749-0.396,1.466-0.652,2.149-0.767
										l-0.283,1.178c-0.692,0.123-1.296,0.281-1.812,0.475l-0.277,0.097l-0.897,3.757H101.952z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M76.823,79.808l2.12-6.497h4.63l-0.276,0.899h-3.434l-0.697,2.169h2.88L81.761,77.3H78.87
										l-0.805,2.507H76.823z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M86.874,75.031h1.185l-1.366,4.776h-1.208l0.088-0.302c-0.624,0.272-1.168,0.409-1.631,0.409
										c-0.769,0-1.229-0.18-1.381-0.54c-0.152-0.359-0.091-0.985,0.181-1.874l0.753-2.47h1.196l-0.744,2.479
										c-0.173,0.575-0.233,0.963-0.182,1.163c0.052,0.2,0.282,0.3,0.691,0.3c0.401,0,0.789-0.065,1.161-0.194l0.184-0.058
										L86.874,75.031z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333"
                                                                                                    d="M87.901,79.808l1.886-6.709h1.186l-1.852,6.709H87.901z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333"
                                                                                                    d="M90.397,79.808l1.818-6.709H93.4l-1.784,6.709H90.397z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M98.898,75.905h-1.52l-0.516,2.094c-0.096,0.391-0.127,0.651-0.092,0.78
										c0.035,0.129,0.19,0.194,0.465,0.194l0.922-0.029l-0.147,0.845c-0.52,0.084-0.911,0.127-1.172,0.127
										c-0.634,0-1.038-0.127-1.21-0.38c-0.171-0.253-0.169-0.727,0.005-1.421l0.555-2.209h-0.705l0.222-0.874h0.702l0.339-1.35
										h1.178l-0.333,1.35h1.514L98.898,75.905z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333"
                                                                                                    d="M99.937,75.538c0.473-0.407,1.148-0.61,2.025-0.61s1.459,0.203,1.746,0.61
										c0.288,0.408,0.343,1.023,0.163,1.848c-0.181,0.831-0.504,1.46-0.97,1.887c-0.467,0.428-1.16,0.642-2.078,0.642
										s-1.513-0.215-1.785-0.642c-0.271-0.426-0.306-1.056-0.108-1.887C99.127,76.562,99.463,75.946,99.937,75.538z
										 M100.114,78.634c0.111,0.258,0.416,0.387,0.914,0.387c0.498,0,0.862-0.129,1.091-0.387
										c0.228-0.257,0.407-0.677,0.537-1.258c0.129-0.578,0.13-0.986,0.003-1.226c-0.126-0.239-0.424-0.359-0.894-0.359
										c-0.47,0-0.822,0.119-1.058,0.359c-0.236,0.24-0.421,0.648-0.556,1.226C100.016,77.958,100.003,78.378,100.114,78.634z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M108.856,76c-0.846-0.101-1.464-0.151-1.853-0.151c-0.389,0-0.667,0.039-0.834,0.118
										c-0.167,0.079-0.269,0.203-0.305,0.373c-0.036,0.171,0.021,0.291,0.173,0.36c0.151,0.07,0.525,0.151,1.122,0.242
										c0.597,0.092,1.005,0.237,1.224,0.434c0.219,0.198,0.279,0.549,0.179,1.055c-0.101,0.509-0.363,0.883-0.787,1.123
										c-0.425,0.24-1,0.36-1.724,0.36c-0.455,0-1.018-0.056-1.689-0.166l-0.336-0.049l0.235-0.882
										c0.87,0.103,1.502,0.155,1.896,0.155c0.394,0,0.683-0.042,0.868-0.126c0.184-0.084,0.296-0.222,0.335-0.415
										c0.039-0.192-0.014-0.325-0.158-0.399c-0.144-0.073-0.509-0.153-1.094-0.24c-0.585-0.086-0.998-0.222-1.24-0.407
										c-0.241-0.184-0.308-0.521-0.203-1.008c0.105-0.485,0.375-0.846,0.811-1.085c0.435-0.238,0.952-0.356,1.551-0.356
										c0.468,0,1.032,0.05,1.692,0.15l0.329,0.056L108.856,76z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g>
                                                                                        <g>
                                                                                            <g>
                                                                                                <path fill="#333333" d="M113.693,76c-0.848-0.101-1.467-0.151-1.856-0.151c-0.389,0-0.666,0.039-0.831,0.118
										c-0.166,0.079-0.265,0.203-0.297,0.373c-0.033,0.171,0.027,0.291,0.18,0.36c0.153,0.07,0.528,0.151,1.126,0.242
										c0.599,0.092,1.01,0.237,1.233,0.434c0.223,0.198,0.29,0.549,0.2,1.055c-0.09,0.509-0.345,0.883-0.764,1.123
										c-0.42,0.24-0.992,0.36-1.716,0.36c-0.455,0-1.019-0.056-1.692-0.166l-0.337-0.049l0.217-0.882
										c0.872,0.103,1.505,0.155,1.899,0.155c0.394,0,0.682-0.042,0.865-0.126c0.183-0.084,0.291-0.222,0.327-0.415
										c0.035-0.192-0.02-0.325-0.166-0.399c-0.146-0.073-0.512-0.153-1.099-0.24c-0.587-0.086-1.003-0.222-1.248-0.407
										c-0.245-0.184-0.319-0.521-0.224-1.008c0.095-0.485,0.358-0.846,0.789-1.085c0.43-0.238,0.944-0.356,1.544-0.356
										c0.468,0,1.033,0.05,1.695,0.15l0.33,0.056L113.693,76z" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF"
                                                                                    d="M18.661,233.806v5.017h-1.049v-3.832l-1.087,0.699l-0.479-0.783l1.657-1.102H18.661z" />
                                                                            </g>
                                                                            <g>
                                                                                <path fill="#FFFFFF" d="M20.414,234.338c0.327-0.415,0.812-0.623,1.456-0.623c0.643,0,1.129,0.208,1.456,0.623
						c0.327,0.416,0.49,1.083,0.49,2.003s-0.162,1.579-0.487,1.977c-0.325,0.398-0.811,0.597-1.46,0.597
						c-0.649,0-1.135-0.199-1.46-0.597c-0.325-0.398-0.487-1.057-0.487-1.977S20.087,234.754,20.414,234.338z M22.737,236.319
						c0-0.626-0.066-1.064-0.198-1.315c-0.132-0.251-0.353-0.376-0.665-0.376c-0.311,0-0.535,0.127-0.669,0.38
						c-0.135,0.253-0.201,0.692-0.201,1.315s0.068,1.059,0.205,1.308c0.137,0.248,0.36,0.372,0.669,0.372s0.53-0.124,0.661-0.372
						C22.671,237.382,22.737,236.945,22.737,236.319z" />
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF" d="M25.683,238.915h-0.951v-3.545h0.943v0.199c0.326-0.189,0.605-0.284,0.837-0.284
						c0.383,0,0.678,0.111,0.886,0.333c0.435-0.222,0.832-0.333,1.191-0.333c0.468,0,0.793,0.136,0.975,0.408
						c0.182,0.272,0.273,0.705,0.273,1.301v1.922h-0.951v-1.893c0-0.303-0.033-0.526-0.099-0.67
						c-0.066-0.144-0.199-0.216-0.397-0.216c-0.151,0-0.336,0.033-0.553,0.099l-0.106,0.035c0.018,0.355,0.028,0.624,0.028,0.809
						v1.836h-0.95v-1.822c0-0.35-0.031-0.597-0.092-0.741c-0.061-0.144-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.546,0.099
						l-0.085,0.028V238.915z" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF" d="M35.616,178.49c0.33-0.241,0.771-0.361,1.323-0.361c0.552,0,0.998,0.122,1.338,0.365
						s0.51,0.58,0.51,1.011c0,0.294-0.036,0.514-0.106,0.661c-0.071,0.147-0.218,0.309-0.441,0.487
						c0.223,0.188,0.383,0.365,0.479,0.532c0.097,0.167,0.145,0.398,0.145,0.692c0,0.497-0.177,0.863-0.532,1.098
						c-0.355,0.236-0.819,0.354-1.391,0.354c-1.267,0-1.901-0.456-1.901-1.368c0-0.324,0.049-0.576,0.148-0.757
						c0.098-0.18,0.259-0.364,0.483-0.551c-0.213-0.172-0.357-0.341-0.434-0.506c-0.076-0.165-0.114-0.381-0.114-0.65
						C35.122,179.067,35.287,178.731,35.616,178.49z M36.164,181.717c0,0.446,0.262,0.669,0.787,0.669s0.787-0.223,0.787-0.669
						c0-0.264-0.149-0.454-0.448-0.57H36.62C36.316,181.264,36.164,181.454,36.164,181.717z M36.955,179.072
						c-0.228,0-0.403,0.051-0.525,0.152c-0.122,0.101-0.183,0.256-0.183,0.464s0.124,0.398,0.373,0.57h0.669
						c0.248-0.172,0.372-0.37,0.372-0.593C37.661,179.27,37.426,179.072,36.955,179.072z" />
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF" d="M40.729,183.329h-0.951v-3.545h0.943v0.199c0.326-0.189,0.605-0.284,0.837-0.284
						c0.383,0,0.678,0.111,0.886,0.333c0.435-0.222,0.832-0.333,1.191-0.333c0.468,0,0.793,0.136,0.975,0.408
						c0.182,0.272,0.273,0.705,0.273,1.301v1.922h-0.951v-1.893c0-0.303-0.033-0.526-0.099-0.67
						c-0.066-0.144-0.199-0.216-0.397-0.216c-0.151,0-0.336,0.033-0.553,0.099l-0.106,0.035c0.018,0.355,0.028,0.624,0.028,0.809
						v1.836h-0.95v-1.822c0-0.35-0.031-0.597-0.092-0.741c-0.061-0.144-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.546,0.099
						l-0.085,0.028V183.329z" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF"
                                                                                    d="M51.889,131.532c-0.517-0.076-0.966-0.114-1.346-0.114c-0.72,0-1.079,0.39-1.079,1.171l0.159-0.046
						c0.335-0.096,0.601-0.145,0.798-0.145c0.573,0,1.004,0.132,1.292,0.395c0.289,0.264,0.434,0.673,0.434,1.228
						c0,0.555-0.162,0.975-0.487,1.258c-0.325,0.284-0.794,0.426-1.411,0.426c-0.616,0-1.08-0.224-1.395-0.673
						c-0.314-0.449-0.471-1.109-0.471-1.98c0-0.872,0.18-1.514,0.539-1.927s0.877-0.62,1.551-0.62c0.395,0,0.818,0.056,1.269,0.167
						l0.236,0.053L51.889,131.532z M50.33,133.311c-0.239,0-0.487,0.043-0.745,0.129l-0.129,0.038c0,0.877,0.284,1.315,0.852,1.315
						c0.243,0,0.431-0.067,0.562-0.201c0.132-0.134,0.198-0.323,0.198-0.566C51.068,133.55,50.822,133.311,50.33,133.311z" />
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF" d="M54.023,135.706h-0.951v-3.545h0.943v0.199c0.326-0.189,0.605-0.284,0.837-0.284
						c0.383,0,0.678,0.111,0.886,0.333c0.435-0.222,0.832-0.333,1.191-0.333c0.468,0,0.793,0.136,0.975,0.408
						c0.182,0.272,0.273,0.705,0.273,1.301v1.922h-0.951v-1.893c0-0.303-0.033-0.526-0.099-0.67
						c-0.066-0.144-0.199-0.216-0.397-0.216c-0.151,0-0.336,0.033-0.553,0.099l-0.106,0.035c0.018,0.355,0.028,0.624,0.028,0.809
						v1.836h-0.95v-1.822c0-0.35-0.031-0.597-0.092-0.741c-0.061-0.144-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.546,0.099
						l-0.085,0.028V135.706z" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF" d="M59.617,107.628h-3.312v-0.905l1.091-1.107c0.387-0.397,0.656-0.702,0.809-0.913
						c0.152-0.212,0.228-0.437,0.228-0.677s-0.063-0.409-0.189-0.507c-0.126-0.098-0.306-0.147-0.538-0.147
						c-0.346,0-0.722,0.031-1.13,0.093l-0.186,0.023l-0.054-0.828c0.505-0.155,1.029-0.232,1.571-0.232
						c1.083,0,1.625,0.488,1.625,1.462c0,0.382-0.083,0.712-0.248,0.99c-0.165,0.279-0.464,0.617-0.898,1.014l-0.89,0.805h2.12
						V107.628z" />
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <path fill="#FFFFFF" d="M61.852,107.628H60.9v-3.548h0.944v0.199c0.327-0.189,0.606-0.284,0.838-0.284
						c0.383,0,0.679,0.111,0.887,0.334c0.435-0.222,0.833-0.334,1.192-0.334c0.468,0,0.794,0.136,0.976,0.408
						c0.182,0.272,0.274,0.706,0.274,1.302v1.923h-0.951v-1.895c0-0.303-0.033-0.526-0.099-0.671
						c-0.066-0.144-0.199-0.216-0.397-0.216c-0.152,0-0.336,0.033-0.554,0.099l-0.106,0.035c0.018,0.355,0.028,0.625,0.028,0.809
						v1.838H62.98v-1.824c0-0.35-0.031-0.597-0.092-0.742c-0.061-0.144-0.196-0.216-0.404-0.216c-0.185,0-0.367,0.033-0.547,0.099
						l-0.085,0.028V107.628z" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g opacity="0">
                                                                    <polygon (click)="PMClicked($event,'0','1')"
                                                                        points="206.774,86.501 206.889,86.92 206.889,86.92 192.663,34.328 86.5,34.328 72.487,86.501 		" />
                                                                    <polygon (click)="PMClicked($event,'1','2')"
                                                                        points="168.937,105.07 211.891,105.07 206.889,86.92 164.403,86.92 		" />
                                                                    <polygon (click)="PMClicked($event,'7','2')" points="67.427,105.328 67.427,105.07 110.381,105.07 114.904,86.92 72.374,86.92 72.487,86.501 72.472,86.501 
			70.412,94.078 67.353,105.328 		" />
                                                                    <polygon (click)="PMClicked($event,'6','2')"
                                                                        points="132.595,86.92 115.52,86.92 110.997,105.07 130.867,105.07 		" />
                                                                    <polygon (click)="PMClicked($event,'2','2')"
                                                                        points="168.321,105.07 163.787,86.92 149.91,86.92 151.458,105.07 		" />
                                                                    <polygon (click)="PMClicked($event,'2','3')"
                                                                        points="153.794,132.456 175.162,132.456 168.47,105.667 151.509,105.667 		" />
                                                                    <polygon (click)="PMClicked($event,'7','3')"
                                                                        points="110.232,105.667 67.427,105.667 67.427,105.328 67.353,105.328 59.978,132.456 103.556,132.456 		" />
                                                                    <polygon (click)="PMClicked($event,'6','3')"
                                                                        points="110.848,105.667 104.172,132.456 128.26,132.456 130.81,105.667 		" />
                                                                    <polygon (click)="PMClicked($event,'1','3')" points="211.945,105.328 211.945,105.667 169.087,105.667 175.778,132.456 219.439,132.456 212.312,106.595 
			211.963,105.328 		" />
                                                                    <polygon (click)="PMClicked($event,'1','4')"
                                                                        points="219.48,133.054 175.928,133.054 187.818,180.651 232.722,180.651 219.515,132.731 219.48,132.731 		" />
                                                                    <polygon (click)="PMClicked($event,'2','4')"
                                                                        points="187.201,180.651 175.311,133.054 153.845,133.054 157.905,180.651 		" />
                                                                    <polygon (click)="PMClicked($event,'7','4')"
                                                                        points="91.545,180.651 103.407,133.054 59.892,133.054 59.892,132.769 48.386,175.091 46.879,180.651 		" />
                                                                    <polygon (click)="PMClicked($event,'6','4')"
                                                                        points="128.203,133.054 104.023,133.054 92.161,180.651 123.671,180.651 		" />
                                                                    <polygon (click)="PMClicked($event,'1','5')"
                                                                        points="187.967,181.249 201.65,236.022 247.983,236.022 232.874,181.202 232.874,181.249 		" />
                                                                    <polygon (click)="PMClicked($event,'2','5')"
                                                                        points="159.864,203.623 162.706,236.022 201.033,236.022 187.351,181.249 157.956,181.249 		" />
                                                                    <polygon (click)="PMClicked($event,'6','5')"
                                                                        points="123.614,181.249 92.012,181.249 78.362,236.022 118.399,236.022 		" />
                                                                    <polygon (click)="PMClicked($event,'7','5')"
                                                                        points="77.746,236.022 91.396,181.249 46.857,181.249 46.857,181.052 46.77,181.052 31.855,235.895 31.82,236.022 		" />
                                                                    <polygon (click)="PMClicked($event,'2','6')"
                                                                        points="165.978,273.334 210.354,273.334 201.183,236.62 162.758,236.62 		" />
                                                                    <polygon (click)="PMClicked($event,'1','6')"
                                                                        points="201.799,236.62 210.97,273.334 258.266,273.334 248.062,236.31 248.062,236.62 		" />
                                                                    <polygon (click)="PMClicked($event,'6','6')"
                                                                        points="118.342,236.62 78.213,236.62 69.063,273.334 114.847,273.334 		" />
                                                                    <polygon (click)="PMClicked($event,'7','6')"
                                                                        points="77.597,236.62 31.788,236.62 31.788,236.276 31.751,236.276 21.675,273.334 68.447,273.334 		" />
                                                                    <polygon
                                                                        points="262.566,288.99 262.581,288.99 260.717,282.227 		" />
                                                                    <polygon
                                                                        points="17.419,288.99 17.435,288.99 18.543,284.854 		" />
                                                                    <polygon points="258.347,273.627 258.347,273.932 211.12,273.932 214.848,288.855 214.307,288.99 262.566,288.99 260.717,282.227 
					" />
                                                                    <polygon points="68.298,273.932 21.623,273.932 21.623,273.576 21.61,273.576 18.543,284.854 17.435,288.99 65.12,288.99 
			64.579,288.855 		" />
                                                                    <polygon points="166.03,273.932 167.349,288.968 113.403,288.968 113.41,288.903 113.365,288.899 114.79,273.932 68.914,273.932 
			65.161,288.99 214.265,288.99 210.503,273.932 		" />
                                                                    <polygon (click)="PMClicked($event,'5','2')"
                                                                        points="136.882,105.07 137.852,86.92 133.196,86.92 131.468,105.07 		" />
                                                                    <polygon (click)="PMClicked($event,'5','2')"
                                                                        points="150.858,105.07 149.309,86.92 144.9,86.92 145.772,105.07 		" />
                                                                    <polygon (click)="PMClicked($event,'4','2')"
                                                                        points="144.772,105.07 143.899,86.92 138.853,86.92 137.883,105.07 		" />
                                                                    <polygon (click)="PMClicked($event,'5','3')"
                                                                        points="136.85,105.667 131.411,105.667 128.86,132.456 135.419,132.456 		" />
                                                                    <polygon (click)="PMClicked($event,'4','3')"
                                                                        points="146.089,132.456 144.801,105.667 137.851,105.667 136.42,132.456 		" />
                                                                    <polygon (click)="PMClicked($event,'3','3')"
                                                                        points="153.194,132.456 150.909,105.667 145.801,105.667 147.089,132.456 		" />
                                                                    <polygon (click)="PMClicked($event,'3','4')"
                                                                        points="149.407,180.651 157.305,180.651 153.245,133.054 147.118,133.054 		" />
                                                                    <polygon (click)="PMClicked($event,'5','4')"
                                                                        points="135.387,133.054 128.803,133.054 124.272,180.651 132.844,180.651 		" />
                                                                    <polygon (click)="PMClicked($event,'4','4')"
                                                                        points="146.118,133.054 136.388,133.054 133.845,180.651 148.406,180.651 		" />
                                                                    <polygon (click)="PMClicked($event,'5','5')"
                                                                        points="132.812,181.249 124.215,181.249 119,236.022 129.886,236.022 		" />
                                                                    <polygon (click)="PMClicked($event,'3','5')"
                                                                        points="152.069,236.022 162.028,236.022 157.356,181.249 149.435,181.249 		" />
                                                                    <polygon (click)="PMClicked($event,'4','5')"
                                                                        points="133.813,181.249 130.886,236.022 151.069,236.022 148.435,181.249 		" />
                                                                    <polygon (click)="PMClicked($event,'3','6')"
                                                                        points="129.854,236.62 118.943,236.62 115.447,273.334 127.892,273.334 		" />
                                                                    <polygon (click)="PMClicked($event,'3','6')"
                                                                        points="152.098,236.62 153.863,273.334 165.21,273.334 162.079,236.62 		" />
                                                                    <polygon (click)="PMClicked($event,'4','6')"
                                                                        points="130.854,236.62 128.893,273.334 152.863,273.334 151.097,236.62 		" />
                                                                    <polygon points="167.138,288.902 166.543,288.953 165.261,273.932 115.39,273.932 113.96,288.956 113.41,288.903 113.403,288.968 
			167.349,288.968 166.03,273.932 165.861,273.932 		" />
                                                                    <polygon
                                                                        points="65.159,289 65.161,288.99 65.12,288.99 		" />
                                                                    <polygon
                                                                        points="214.267,289 214.307,288.99 214.265,288.99 		" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        <!-- <img *ngFor="let pm of lstPMValues;" src="assets/images/wkts.png"
                                                                class="mCS_img_loaded"
                                                                [ngStyle]="{ 'left.px' : pm.PitchX, 'top.px' : pm.PitchY }"
                                                                style="position: absolute;z-index: 100;display: block;"> -->
                                                    </div>
                                                </div>
                                                <div class="main-tab-container impact" style="display:none;">
                                                    <div class="txt-center">
                                                        <svg *ngIf="isRHB" id="impactdata" style="position: relative;"
                                                            version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                            width="252px" height="290px" viewBox="0 0 252 290"
                                                            enable-background="new 0 0 252 290" xml:space="preserve">
                                                            <g id="Layer_2">
                                                            </g>
                                                            <g id="Layer_1">
                                                                <g>
                                                                    <rect x="0" y="230.497" fill="#D6CDA8" width="252"
                                                                        height="59.503" />
                                                                    <rect x="0" y="0" fill="#517F2A" width="252"
                                                                        height="231.463" />
                                                                    <g>
                                                                        <linearGradient id="SVGID_2_"
                                                                            gradientUnits="userSpaceOnUse" x1="80.7562"
                                                                            y1="272.7426" x2="80.7562" y2="4.2298">
                                                                            <stop offset="0"
                                                                                style="stop-color:#5FC447" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#5FC447;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="58.256" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_2_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="45"
                                                                            height="271.928" />
                                                                        <linearGradient id="SVGID_4_"
                                                                            gradientUnits="userSpaceOnUse" x1="173.7562"
                                                                            y1="271.8448" x2="173.7562" y2="2.7843">
                                                                            <stop offset="0"
                                                                                style="stop-color:#808080" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#517F2A;stop-opacity:0" />
                                                                        </linearGradient>
                                                                        <rect x="151.256" y="0.814"
                                                                            fill="url(#SVGID_4_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="45"
                                                                            height="271.928" />
                                                                        <linearGradient id="SVGID_6_"
                                                                            gradientUnits="userSpaceOnUse" x1="224.1281"
                                                                            y1="272.7426" x2="224.1281" y2="3.0767">
                                                                            <stop offset="0"
                                                                                style="stop-color:#FFD54F" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#FFD54F;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="196.256" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_6_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="55.744"
                                                                            height="271.928" />
                                                                        <rect x="0" y="141.25" fill="none"
                                                                            stroke="#FFFFFF" stroke-miterlimit="10"
                                                                            width="252" height="62.75" />
                                                                        <rect x="0" y="76.125" fill="none"
                                                                            stroke="#FFFFFF" stroke-width="0.9048"
                                                                            stroke-miterlimit="10" width="252"
                                                                            height="65" />
                                                                        <rect x="0" y="31.125" fill="none"
                                                                            stroke="#FFFFFF" stroke-width="1.0815"
                                                                            stroke-miterlimit="10" width="252"
                                                                            height="45" />
                                                                        <linearGradient id="SVGID_8_"
                                                                            gradientUnits="userSpaceOnUse" x1="127.2562"
                                                                            y1="272.7426" x2="127.2562" y2="3.4591">
                                                                            <stop offset="0"
                                                                                style="stop-color:#EF657D" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#EF657D;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="103.256" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_8_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="48"
                                                                            height="271.928" />

                                                                        <rect x="135.256" y="0.814" opacity="0.8"
                                                                            fill="none" stroke="#FFFFFF"
                                                                            stroke-width="0.4862" stroke-miterlimit="10"
                                                                            width="16" height="271.928" />

                                                                        <rect x="119.256" y="0.814" opacity="0.8"
                                                                            fill="none" stroke="#FFFFFF"
                                                                            stroke-width="0.4862" stroke-miterlimit="10"
                                                                            width="16" height="271.928" />

                                                                        <rect x="103.256" y="0.814" opacity="0.8"
                                                                            fill="none" stroke="#FFFFFF"
                                                                            stroke-width="0.4862" stroke-miterlimit="10"
                                                                            width="16" height="271.928" />
                                                                        <linearGradient id="SVGID_10_"
                                                                            gradientUnits="userSpaceOnUse" x1="29.1893"
                                                                            y1="272.7426" x2="29.1893" y2="3.4489">
                                                                            <stop offset="0"
                                                                                style="stop-color:#9A77C9" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#9A77C9;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="0" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_10_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="58.379"
                                                                            height="271.928" />
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon fill="#FCD462"
                                                                                    points="112.319,255.744 104.807,255.744 104.807,158.05 112.319,158.05 					" />
                                                                            </g>
                                                                            <g>
                                                                                <polygon fill="#FCD462"
                                                                                    points="131.012,255.744 123.5,255.744 123.5,158.05 131.012,158.05 					" />
                                                                            </g>
                                                                            <g>
                                                                                <polygon fill="#FCD462"
                                                                                    points="149.8,255.744 142.288,255.744 142.288,158.05 149.8,158.05 					" />
                                                                            </g>
                                                                            <polygon fill="#F6C358"
                                                                                points="126.9,155.563 123.207,155.563 123.207,154.46 112.614,154.46 112.614,155.563 109.114,155.563 
                                                                       109.114,158.029 112.614,158.029 112.614,159.132 123.207,159.132 123.207,158.029 126.9,158.029 				" />
                                                                            <polygon fill="#F6C358"
                                                                                points="145.626,155.563 141.932,155.563 141.932,154.46 131.339,154.46 131.339,155.563 
                                                                       127.839,155.563 127.839,158.029 131.339,158.029 131.339,159.132 141.932,159.132 141.932,158.029 145.626,158.029 				" />
                                                                        </g>
                                                                    </g>
                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="0" y1="254.149" x2="252" y2="254.149" />
                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="0" y1="272.74" x2="252" y2="272.74" />
                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="0" y1="254.149" x2="0" y2="272.74" />
                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="241.186" y1="254.149" x2="241.186"
                                                                        y2="272.74" />
                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="12.669" y1="254.149" x2="12.669"
                                                                        y2="272.74" />
                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="217.258" y1="254.149" x2="217.258"
                                                                        y2="272.74" />
                                                                    <image overflow="visible" width="280" height="600"
                                                                        xlink:href="assets/images/Cric.png"
                                                                        transform="matrix(0.4443 0 0 0.4443 91.2562 24.2584)">
                                                                    </image>

                                                                </g>
                                                                <g opacity="0">
                                                                    <rect (click)="ImpactClick($event,'2','1')"
                                                                        x="58.878" y="204.5" width="43.878"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'2','5')"
                                                                        x="58.878" y="1.314" width="43.878"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'6','5')"
                                                                        x="151.756" y="1.314" width="44"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'6','1')"
                                                                        x="151.756" y="204.5" width="44"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'7','1')"
                                                                        x="196.756" y="204.5" width="54.744"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'7','5')"
                                                                        x="196.756" y="1.314" width="54.744"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'2','2')"
                                                                        x="58.878" y="141.75" width="43.878"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'6','2')"
                                                                        x="151.756" y="141.75" width="44"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'7','2')"
                                                                        x="196.756" y="141.75" width="54.744"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'2','3')"
                                                                        x="58.878" y="76.666" width="43.878"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'6','3')"
                                                                        x="151.756" y="76.666" width="44"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'7','3')"
                                                                        x="196.756" y="76.666" width="54.744"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'2','4')"
                                                                        x="58.878" y="31.666" width="43.878"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'6','4')"
                                                                        x="151.756" y="31.666" width="44"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'7','4')"
                                                                        x="196.756" y="31.666" width="54.703"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'5','1')"
                                                                        x="135.499" y="204.5" width="15.257"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'5','5')"
                                                                        x="135.499" y="1.314" width="15.257"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'5','2')"
                                                                        x="135.499" y="141.75" width="15.257"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'5','3')"
                                                                        x="135.499" y="76.666" width="15.257"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'5','4')"
                                                                        x="135.499" y="31.666" width="15.257"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'4','5')"
                                                                        x="119.499" y="1.314" width="15.514"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'4','1')"
                                                                        x="119.499" y="204.5" width="15.514"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'4','2')"
                                                                        x="119.499" y="141.75" width="15.514"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'4','3')"
                                                                        x="119.499" y="76.666" width="15.514"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'4','4')"
                                                                        x="119.499" y="31.666" width="15.514"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'3','1')"
                                                                        x="103.756" y="204.5" width="15.257"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'3','5')"
                                                                        x="103.756" y="1.314" width="15.257"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'3','2')"
                                                                        x="103.756" y="141.75" width="15.257"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'3','3')"
                                                                        x="103.756" y="76.666" width="15.257"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'3','4')"
                                                                        x="103.756" y="31.666" width="15.257"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'1','1')" x="0.5"
                                                                        y="204.5" width="57.256" height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'1','5')" x="0.5"
                                                                        y="1.314" width="57.256" height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'1','2')" x="0.5"
                                                                        y="141.75" width="57.256" height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'1','3')" x="0.5"
                                                                        y="76.666" width="57.256" height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'1','4')"
                                                                        x="0.541" y="31.666" width="57.215"
                                                                        height="43.919" />
                                                                </g>
                                                            </g>
                                                        </svg>

                                                        <svg *ngIf="!isRHB" id="impactdata" style="position: relative;"
                                                            version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                            width="252px" height="290px" viewBox="0 0 252 290"
                                                            enable-background="new 0 0 252 290" xml:space="preserve">
                                                            <g id="Layer_2">
                                                            </g>
                                                            <g id="Layer_1">
                                                                <g>
                                                                    <rect x="-2.857" y="230.497" fill="#D6CDA8"
                                                                        width="261.714" height="66.041" />
                                                                    <rect x="-2.857" y="-5" fill="#517F2A"
                                                                        width="261.714" height="236.463" />
                                                                    <g>

                                                                        <linearGradient id="SVGID_2_"
                                                                            gradientUnits="userSpaceOnUse" x1="78.7562"
                                                                            y1="272.7426" x2="78.7562" y2="4.2298"
                                                                            gradientTransform="matrix(-1 0 0 1 252 0)">
                                                                            <stop offset="0"
                                                                                style="stop-color:#5FC447" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#5FC447;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="150.744" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_2_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="45"
                                                                            height="271.928" />

                                                                        <linearGradient id="SVGID_4_"
                                                                            gradientUnits="userSpaceOnUse" x1="171.7562"
                                                                            y1="271.8448" x2="171.7562" y2="2.7843"
                                                                            gradientTransform="matrix(-1 0 0 1 252 0)">
                                                                            <stop offset="0"
                                                                                style="stop-color:#808080" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#517F2A;stop-opacity:0" />
                                                                        </linearGradient>
                                                                        <rect x="57.744" y="0.814" fill="url(#SVGID_4_)"
                                                                            stroke="#FFFFFF" stroke-miterlimit="10"
                                                                            width="45" height="271.928" />

                                                                        <linearGradient id="SVGID_6_"
                                                                            gradientUnits="userSpaceOnUse" x1="224.5565"
                                                                            y1="272.7426" x2="224.5565" y2="3.0767"
                                                                            gradientTransform="matrix(-1 0 0 1 252 0)">
                                                                            <stop offset="0"
                                                                                style="stop-color:#FFD54F" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#FFD54F;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="-2.857" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_6_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="60.601"
                                                                            height="271.928" />
                                                                        <rect x="-2.129" y="141.25" fill="none"
                                                                            stroke="#FFFFFF" stroke-miterlimit="10"
                                                                            width="260.985" height="62.75" />

                                                                        <rect x="-2.129" y="76.125" fill="none"
                                                                            stroke="#FFFFFF" stroke-width="0.9048"
                                                                            stroke-miterlimit="10" width="260.985"
                                                                            height="65" />

                                                                        <rect x="-2.129" y="31.125" fill="none"
                                                                            stroke="#FFFFFF" stroke-width="1.0815"
                                                                            stroke-miterlimit="10" width="260.985"
                                                                            height="45" />

                                                                        <linearGradient id="SVGID_8_"
                                                                            gradientUnits="userSpaceOnUse" x1="125.2562"
                                                                            y1="272.7426" x2="125.2562" y2="3.4591"
                                                                            gradientTransform="matrix(-1 0 0 1 252 0)">
                                                                            <stop offset="0"
                                                                                style="stop-color:#EF657D" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#EF657D;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="102.744" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_8_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="48"
                                                                            height="271.928" />

                                                                        <rect x="102.744" y="0.814" opacity="0.8"
                                                                            fill="none" stroke="#FFFFFF"
                                                                            stroke-width="0.4862" stroke-miterlimit="10"
                                                                            width="16" height="271.928" />

                                                                        <rect x="118.744" y="0.814" opacity="0.8"
                                                                            fill="none" stroke="#FFFFFF"
                                                                            stroke-width="0.4862" stroke-miterlimit="10"
                                                                            width="16" height="271.928" />

                                                                        <rect x="134.744" y="0.814" opacity="0.8"
                                                                            fill="none" stroke="#FFFFFF"
                                                                            stroke-width="0.4862" stroke-miterlimit="10"
                                                                            width="16" height="271.928" />

                                                                        <linearGradient id="SVGID_10_"
                                                                            gradientUnits="userSpaceOnUse" x1="24.7609"
                                                                            y1="272.7426" x2="24.7609" y2="3.4489"
                                                                            gradientTransform="matrix(-1 0 0 1 252 0)">
                                                                            <stop offset="0"
                                                                                style="stop-color:#9A77C9" />
                                                                            <stop offset="1"
                                                                                style="stop-color:#9A77C9;stop-opacity:0" />
                                                                        </linearGradient>

                                                                        <rect x="195.621" y="0.814" opacity="0.8"
                                                                            fill="url(#SVGID_10_)" stroke="#FFFFFF"
                                                                            stroke-miterlimit="10" width="63.235"
                                                                            height="271.928" />
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon fill="#FCD462"
                                                                                    points="141.681,255.744 149.193,255.744 149.193,158.05 141.681,158.05 					" />
                                                                            </g>
                                                                            <g>
                                                                                <polygon fill="#FCD462"
                                                                                    points="122.988,255.744 130.5,255.744 130.5,158.05 122.988,158.05 					" />
                                                                            </g>
                                                                            <g>
                                                                                <polygon fill="#FCD462"
                                                                                    points="104.2,255.744 111.712,255.744 111.712,158.05 104.2,158.05 					" />
                                                                            </g>
                                                                            <polygon fill="#F6C358"
                                                                                points="127.1,155.563 130.793,155.563 130.793,154.46 141.386,154.46 141.386,155.563 144.886,155.563 
                                        144.886,158.029 141.386,158.029 141.386,159.132 130.793,159.132 130.793,158.029 127.1,158.029 				" />
                                                                            <polygon fill="#F6C358"
                                                                                points="108.374,155.563 112.068,155.563 112.068,154.46 122.661,154.46 122.661,155.563 
                                        126.161,155.563 126.161,158.029 122.661,158.029 122.661,159.132 112.068,159.132 112.068,158.029 108.374,158.029 				" />
                                                                        </g>
                                                                    </g>

                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="258.857" y1="254.149" x2="-2.857"
                                                                        y2="254.149" />

                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="258.857" y1="272.74" x2="-2.857"
                                                                        y2="272.74" />

                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="12.814" y1="254.149" x2="12.814"
                                                                        y2="272.74" />

                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="241.331" y1="254.149" x2="241.331"
                                                                        y2="272.74" />

                                                                    <line fill="none" stroke="#FFFFFF"
                                                                        stroke-width="1.1973" stroke-miterlimit="10"
                                                                        x1="36.742" y1="254.149" x2="36.742"
                                                                        y2="272.74" />

                                                                    <image overflow="visible" width="280" height="600"
                                                                        xlink:href="assets/images/Cric.png"
                                                                        transform="matrix(-0.4443 0 0 0.4443 162.7438 24.2584)">
                                                                    </image>
                                                                </g>
                                                                <g opacity="0">
                                                                    <rect (click)="ImpactClick($event,'6','1')"
                                                                        x="58.378" y="204.5" width="43.878"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'6','5')"
                                                                        x="58.378" y="1.314" width="43.878"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'2','5')"
                                                                        x="151.256" y="1.314" width="44"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'2','1')"
                                                                        x="151.256" y="204.5" width="44"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'1','1')"
                                                                        x="196.256" y="204.5" width="54.744"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'1','5')"
                                                                        x="196.256" y="1.314" width="54.744"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'6','2')"
                                                                        x="58.378" y="141.75" width="43.878"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'2','2')"
                                                                        x="151.256" y="141.75" width="44"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'1','2')"
                                                                        x="196.256" y="141.75" width="54.744"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'6','3')"
                                                                        x="58.378" y="76.666" width="43.878"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'2','3')"
                                                                        x="151.256" y="76.666" width="44"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'1','3')"
                                                                        x="196.256" y="76.666" width="54.744"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'6','4')"
                                                                        x="58.378" y="31.666" width="43.878"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'2','4')"
                                                                        x="151.256" y="31.666" width="44"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'1','4')"
                                                                        x="196.256" y="31.666" width="54.703"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'3','1')"
                                                                        x="134.999" y="204.5" width="15.257"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'3','5')"
                                                                        x="134.999" y="1.314" width="15.257"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'3','2')"
                                                                        x="134.999" y="141.75" width="15.257"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'3','3')"
                                                                        x="134.999" y="76.666" width="15.257"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'3','4')"
                                                                        x="134.999" y="31.666" width="15.257"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'4','5')"
                                                                        x="118.999" y="1.314" width="15.514"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'4','1')"
                                                                        x="118.999" y="204.5" width="15.514"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'4','2')"
                                                                        x="118.999" y="141.75" width="15.514"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'4','3')"
                                                                        x="118.999" y="76.666" width="15.514"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'4','4')"
                                                                        x="118.999" y="31.666" width="15.514"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'5','1')"
                                                                        x="103.256" y="204.5" width="15.257"
                                                                        height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'5','5')"
                                                                        x="103.256" y="1.314" width="15.257"
                                                                        height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'5','2')"
                                                                        x="103.256" y="141.75" width="15.257"
                                                                        height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'5','3')"
                                                                        x="103.256" y="76.666" width="15.257"
                                                                        height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'5','4')"
                                                                        x="103.256" y="31.666" width="15.257"
                                                                        height="43.919" />
                                                                    <rect (click)="ImpactClick($event,'7','1')" x="0"
                                                                        y="204.5" width="57.256" height="67.743" />
                                                                    <rect (click)="ImpactClick($event,'7','5')" x="0"
                                                                        y="1.314" width="57.256" height="29.27" />
                                                                    <rect (click)="ImpactClick($event,'7','2')" x="0"
                                                                        y="141.75" width="57.256" height="61.75" />
                                                                    <rect (click)="ImpactClick($event,'7','3')" x="0"
                                                                        y="76.666" width="57.256" height="64.007" />
                                                                    <rect (click)="ImpactClick($event,'7','4')"
                                                                        x="0.041" y="31.666" width="57.215"
                                                                        height="43.919" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<!-- Video Preview Modal -->
<div class="modal fade" id="videofilter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Advance Filter</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="" style="border-bottom: 1px solid #ccc;">
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                floatLabel="always">
                                <mat-select placeholder="Select Skill">
                                    <mat-option [value]="01">Batting</mat-option>
                                    <mat-option [value]="02">Bowling</mat-option>
                                    <mat-option [value]="03">Fielding</mat-option>
                                    <mat-option [value]="04">Fitness</mat-option>
                                    <mat-option [value]="05">Others</mat-option>
                                    <mat-option [value]="06">Wicket Keeper</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                floatLabel="always">
                                <mat-select placeholder="Select View">
                                    <mat-option [value]="01">Front</mat-option>
                                    <mat-option [value]="02">Side</mat-option>
                                    <mat-option [value]="03">Back</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                                floatLabel="always">
                                <mat-select placeholder="Select Session Name">
                                    <mat-option [value]="01">Batting</mat-option>
                                    <mat-option [value]="02">Bowling</mat-option>
                                    <mat-option [value]="03">Fielding</mat-option>
                                    <mat-option [value]="04">Fitness</mat-option>
                                    <mat-option [value]="05">Others</mat-option>
                                    <mat-option [value]="06">Wicket Keeper</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <div class="txt-center">
                                <a href="javascript:void(0);" class="theme-button mt25">Submit</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="drill-list mb20 br-5">
                            <div class="drill-thumbnail">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview">
                                    <img src="assets/images/thumbnail/thumb01.png" class="img-fluid">
                                </a>
                                <div class="ico-video"></div>
                                <div class="drill-type">Batting</div>
                                <div class="video-time">12.5 Mins</div>
                            </div>
                            <figcaption>
                                <div class="drill-user-date">
                                    <div class="drill-user">
                                        <a href="javascript:void(0);" class="video-user">Sanjay Bangar</a>
                                    </div>
                                    <div class="time">
                                        <p>15<sup>th</sup> Feb 2021 || 5:30 PM</p>
                                    </div>
                                </div>
                                <div class="drill-name-share">
                                    <div class="drill-name">Back Foot</div>
                                    <a href="javascript:void(0);" class="ico-menu" data-toggle="modal"
                                        data-target="#drillshare"></a>
                                </div>
                            </figcaption>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="drill-list mb20 br-5">
                            <div class="drill-thumbnail">
                                <a href="javascript:void(0);">
                                    <img src="assets/images/thumbnail/drills3.jpg" class="img-fluid">
                                </a>
                                <div class="ico-video"></div>
                                <div class="drill-type">Bowling</div>
                                <div class="video-time">12.5 Mins</div>
                            </div>
                            <figcaption>
                                <div class="drill-user-date">
                                    <div class="drill-user">
                                        <a href="javascript:void(0);" class="video-user">Sanjay Bangar</a>
                                    </div>
                                    <div class="time">
                                        <p>15<sup>th</sup> Feb 2021 || 5:30 PM</p>
                                    </div>
                                </div>
                                <div class="drill-name-share">
                                    <div class="drill-name">Back Foot</div>
                                    <a href="javascript:void(0);" class="ico-menu" data-toggle="modal"
                                        data-target="#drillshare"></a>
                                </div>
                            </figcaption>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="drill-list mb20 br-5">
                            <div class="drill-thumbnail">
                                <a href="javascript:void(0);">
                                    <img src="assets/images/thumbnail/thumb02.png" class="img-fluid">
                                </a>
                                <div class="ico-video"></div>
                                <div class="drill-type">Bowling</div>
                                <div class="video-time">12.5 Mins</div>
                            </div>
                            <figcaption>
                                <div class="drill-user-date">
                                    <div class="drill-user">
                                        <a href="javascript:void(0);" class="video-user">Sanjay Bangar</a>
                                    </div>
                                    <div class="time">
                                        <p>15<sup>th</sup> Feb 2021 || 5:30 PM</p>
                                    </div>
                                </div>
                                <div class="drill-name-share">
                                    <div class="drill-name">Back Foot</div>
                                    <a href="javascript:void(0);" class="ico-menu" data-toggle="modal"
                                        data-target="#drillshare"></a>
                                </div>
                            </figcaption>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="drill-list mb20 br-5">
                            <div class="drill-thumbnail">
                                <a href="javascript:void(0);">
                                    <img src="assets/images/thumbnail/thumb03.png" class="img-fluid">
                                </a>
                                <div class="ico-video"></div>
                                <div class="drill-type">Batting</div>
                                <div class="video-time">12.5 Mins</div>
                            </div>
                            <figcaption>
                                <div class="drill-user-date">
                                    <div class="drill-user">
                                        <a href="javascript:void(0);" class="video-user">Sanjay Bangar</a>
                                    </div>
                                    <div class="time">
                                        <p>15<sup>th</sup> Feb 2021 || 5:30 PM</p>
                                    </div>
                                </div>
                                <div class="drill-name-share">
                                    <div class="drill-name">Back Foot</div>
                                    <a href="javascript:void(0);" class="ico-menu" data-toggle="modal"
                                        data-target="#drillshare"></a>
                                </div>
                            </figcaption>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="drill-list mb20 br-5">
                            <div class="drill-thumbnail">
                                <a href="javascript:void(0);">
                                    <img src="assets/images/thumbnail/thumb01.png" class="img-fluid">
                                </a>
                                <div class="ico-video"></div>
                                <div class="drill-type">Batting</div>
                                <div class="video-time">12.5 Mins</div>
                            </div>
                            <figcaption>
                                <div class="drill-user-date">
                                    <div class="drill-user">
                                        <a href="javascript:void(0);" class="video-user">Sanjay Bangar</a>
                                    </div>
                                    <div class="time">
                                        <p>15<sup>th</sup> Feb 2021 || 5:30 PM</p>
                                    </div>
                                </div>
                                <div class="drill-name-share">
                                    <div class="drill-name">Back Foot</div>
                                    <a href="javascript:void(0);" class="ico-menu" data-toggle="modal"
                                        data-target="#drillshare"></a>
                                </div>
                            </figcaption>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="drill-list mb20 br-5">
                            <div class="drill-thumbnail">
                                <a href="javascript:void(0);">
                                    <img src="assets/images/thumbnail/drills3.jpg" class="img-fluid">
                                </a>
                                <div class="ico-video"></div>
                                <div class="drill-type">Bowling</div>
                                <div class="video-time">12.5 Mins</div>
                            </div>
                            <figcaption>
                                <div class="drill-user-date">
                                    <div class="drill-user">
                                        <a href="javascript:void(0);" class="video-user">Sanjay Bangar</a>
                                    </div>
                                    <div class="time">
                                        <p>15<sup>th</sup> Feb 2021 || 5:30 PM</p>
                                    </div>
                                </div>
                                <div class="drill-name-share">
                                    <div class="drill-name">Back Foot</div>
                                    <a href="javascript:void(0);" class="ico-menu" data-toggle="modal"
                                        data-target="#drillshare"></a>
                                </div>
                            </figcaption>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="txt-center">
                            <a href="javascript:void(0);" class="theme-button mt5">Apply</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Video Analyse</h4>
                <p>{{successAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false;">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Video Analyse</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Video Analyse</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isWarningAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isDeleteConfirm">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <p>Do want to delete the record permanently?</p>
                <p>If Confirm Press OK Button</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)=CofirmDelete()>Ok</button>
            <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
                (click)="isDeleteConfirm=false">Cancel</button>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="ratingModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="feedback-title">Feedback Rating</div>
                <div class="rating">
                    <div class="rate">
                        <input type="radio" id="star5" name="rate" value="5" (click)="changeRating(5)" />
                        <label for="star5" title="text">5 stars</label>
                        <input type="radio" id="star4" name="rate" value="4" (click)="changeRating(4)" />
                        <label for="star4" title="text">4 stars</label>
                        <input type="radio" id="star3" name="rate" value="3" (click)="changeRating(3)" />
                        <label for="star3" title="text">3 stars</label>
                        <input type="radio" id="star2" name="rate" value="2" (click)="changeRating(2)" />
                        <label for="star2" title="text">2 stars</label>
                        <input type="radio" id="star1" name="rate" value="1" (click)="changeRating(1)" />
                        <label for="star1" title="text">1 star</label>
                    </div>
                </div>
                <div class="ratingdesc">
                    <textarea class="reviewdesc" [(ngModel)]="SessionRatingComment"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <a href="javascript:void(0);" class="theme-button" data-dismiss="modal"
                    (click)="SaveSessionRating()">Submit</a>
                <a href="javascript:void(0);" class="theme-button" data-dismiss="modal">Cancel</a>
            </div>
        </div>
    </div>
</div>