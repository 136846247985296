import { Component, OnInit, Input, ViewChild, ElementRef, Directive, HostListener, ChangeDetectorRef } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { MastersServiceService } from '../masters-service.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import * as $ from 'jquery';

import { Hosted } from 'protractor/built/driverProviders';
import { FLOAT, float } from 'html2canvas/dist/types/css/property-descriptors/float';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
declare var jQuery: any;
declare var jwplayer: any;
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Location } from '@angular/common';

var commonPlayV1JV = false;
var commonPlayV2JV = false;

export interface User {
  name: string;
}

@Component({
  selector: 'app-video-compare',
  templateUrl: './video-compare.component.html',
  styleUrls: ['./video-compare.component.css']
})
export class VideoCompareComponent implements OnInit {

  constructor(private utility: UtilitiesService, private service: MastersServiceService, public datepipe: DatePipe,
    private router: Router, private _location: Location, private changeref: ChangeDetectorRef) { }


  videoType: string = "linkvideotimeline"

  @ViewChild('myCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('canvasDraw', { static: false }) canvas: ElementRef;
  @ViewChild('canvasDraw2', { static: false }) canvas2: ElementRef;
  @ViewChild('canvasDraw3', { static: false }) canvas3: ElementRef;

  @ViewChild('canvasDraw4', { static: false }) canvas4: ElementRef;
  @ViewChild('canvasDraw5', { static: false }) canvas5: ElementRef;
  @ViewChild('canvasDraw6', { static: false }) canvas6: ElementRef;

  public context: CanvasRenderingContext2D;
  myControl = new FormControl();
  options: any = [
  ];
  filteredOptions!: Observable<User[]>;

  public commentsFeed: boolean = false;

  public showMonthRange: boolean = true;
  public showDateRange: boolean = false;
  public advFilters: boolean = false;
  public annotationType: string = '';
  public clickCount: number = 0;
  public canvasData: canvasDetails[] = [];
  public canvasTempData: canvasDetails[] = [];
  public drawingId: number;
  public SessionID: string;
  public SessionVideo: string;
  public showcanvas: boolean = true;
  public videocurrentTime: string;

  public IsEmptyVideo: boolean = false;
  public CompareVideoURL: string;
  public CompareVideoThumbnail: string;

  public AnnotationTime: number;
  public lstAnnotations: any;
  public lstAnnotationDetails: any;
  public lstAnnotationReviews: any;

  public annotationNumber: number;
  public NextAnnotationTime: number;
  public lstCanvasAnnotationDetails: any;

  public lstCanvasAnn: any;

  canvasElement: any;
  canvasElement2: any;
  canvasElement3: any;

  canvasElement4: any;
  canvasElement5: any;
  canvasElement6: any;
  lines: any[];
  isDown: boolean = false;
  startX: number;
  startY: number;
  nearest: any;
  offsetX: any;
  offsetY: any;
  startDraw: boolean = false;
  angstartX: number;
  angstartY: number;
  isTextEditor: boolean = false;
  isScaleRular: boolean = false;
  seekbarmove: boolean = false;
  textArray: any = [];
  selectedText: number = -1;
  scaleArray: any = [];
  scaleassigned: boolean = false;
  unitValue: number;
  unitType: string;
  unitPixels!: number;
  drawcolor: string = "yellow";

  public UserCode: string; public AthleteID: string;
  public PlayerName: string; public PlayerRole: string;
  public VideoDate: string; public ProfileImage: string;
  public sessionsThumbnail: string; public SessionTypeName: string;
  public VideoViewName: string; public SessionName: string;
  public sFlag: string;

  public isShowLoader: boolean = false;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;

  videoanalyse: boolean = true;
  videocompare: boolean = false;
  videoStatus: string = 'play';
  compareduration!: number;
  videocompare1Starttime!: number;
  videocompare2Starttime!: number;
  refVideotoCompare!: number;

  @HostListener('videoplayer') myVideo: ElementRef;

  public isOverlay: boolean = false;
  public OverlayURL: string;
  public isSuperImpose: boolean = false;

  ngOnInit(): void {
    this.utility.pageLoadScript;
    this.SessionID = sessionStorage.getItem('sessionId');
    this.SessionVideo = sessionStorage.getItem('sessionVideoUrl');
    this.PlayerName = sessionStorage.getItem('sessionPlayerName');
    this.PlayerRole = sessionStorage.getItem('sessionPlayerRole');
    this.VideoDate = sessionStorage.getItem('sessionVideoDate');
    this.ProfileImage = sessionStorage.getItem('sessionProfileImage');
    this.sessionsThumbnail = sessionStorage.getItem('sessionsThumbnail');
    this.SessionTypeName = sessionStorage.getItem('SessionTypeName');
    this.VideoViewName = sessionStorage.getItem('VideoViewName');
    this.SessionName = sessionStorage.getItem('SessionName');
    this.UserCode = String(JSON.parse(sessionStorage.getItem('loginUserDetails').toString()).UserID);
    this.AthleteID = sessionStorage.getItem('athleteID');
    this.sFlag = sessionStorage.getItem('sFlag');

    // if (this.CompareVideoThumbnail == "" && this.CompareVideoURL == ""){
    //    this.IsEmptyVideo = true;
    // }

    this.IsEmptyVideo = true;
    //$("#video-progress-bar").css("width", "0%");
  }


  ngAfterViewInit() {
    this.canvasElement = this.canvas.nativeElement;
    this.canvasElement2 = this.canvas2.nativeElement;
    this.canvasElement3 = this.canvas3.nativeElement;

    this.canvasElement4 = this.canvas4.nativeElement;
    this.canvasElement5 = this.canvas5.nativeElement;
    this.canvasElement6 = this.canvas6.nativeElement;

    this.lines = [];
    requestAnimationFrame(() => {
      this.reOffset()
    })
    //this.FetchAnnotations();
  }

  reOffset() {
    let BB = this.canvasElement.getBoundingClientRect();
    this.offsetX = BB.left;
    this.offsetY = BB.top;
  }

  changeMonthDate() {
    this.showMonthRange = !this.showMonthRange;
    this.showDateRange = !this.showDateRange;
  }
  showCommentbox() {
    this.commentsFeed = true;
  }
  hideCommentbox() {
    this.commentsFeed = false;
  }
  classFlag: boolean = false;
  menuToggle(event: any) {
    event.target.classList.toggle('active')
  }

  draw(data, color, ctx) {
    //let ctx = this.canvasElement2.getContext('2d');
    let cw = this.canvasElement.width;
    let ch = this.canvasElement.height;
    // ctx.clearRect(0, 0, cw, ch);
    // draw all lines at their current positions
    // let ctx = this.canvasElement.getContext('2d');
    for (let i = 0; i < data.length; i++) {

      ctx.beginPath();
      ctx.moveTo(data[i].x0, data[i].y0);
      ctx.lineTo(data[i].x1, data[i].y1);
      ctx.strokeStyle = color;
      ctx.width = 5;
      ctx.stroke();
    }
    // draw markers if a line is being dragged
    if (this.nearest) {
      // point on line this.nearest to mouse
      ctx.beginPath();
      ctx.arc(this.nearest.pt.x, this.nearest.pt.y, 5, 0, Math.PI * 2);
      ctx.strokeStyle = color;
      ctx.stroke();
      // marker for original line before dragging
      this.drawLine(this.nearest.originalLine, color, ctx);
      // hightlight the line as its dragged
      this.drawLine(this.nearest.line, color, ctx);
    }
  }


  drawLine(line, color, ctx) {
    // let ctx = this.canvasElement.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(line.x0, line.y0);
    ctx.lineTo(line.x1, line.y1);
    ctx.strokeStyle = color;
    ctx.Width = 5;
    ctx.stroke();
  }

  drawCircle(ctx, x0, y0, x1, y1, color) {
    var xDiff = x0 - x1;
    var yDiff = y0 - y1;

    var r = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    ctx.lineWidth = 1;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.arc(x0, y0, r, 0, 2 * Math.PI);
    ctx.stroke();

  }

  drawRectangle(ctx, x, y, w, h, color) {
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = color;
    ctx.strokeRect(x, y, w, h);
  }

  drawArrow(ctx, startX, startY, endX, endY, color) {
    if (startX != endX && startY != endY) {
      var headlen = 15;
      var dx = endX - startX;
      var dy = endY - startY;
      var angle = Math.atan2(dy, dx);
      ctx.lineWidth = 1;
      headlen = headlen * ctx.lineWidth * 0.5;
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      //ctx.lineWidth = lineWidth;
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = color;
      ctx.stroke();
      ctx.closePath();
      //
      ctx.beginPath();

      ctx.moveTo(endX - headlen * Math.cos(angle - Math.PI / 6), endY - headlen * Math.sin(angle - Math.PI / 6));

      ctx.lineTo(endX, endY);

      ctx.lineTo(endX - headlen * Math.cos(angle + Math.PI / 6), endY - headlen * Math.sin(angle + Math.PI / 6));

      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    }
  }

  // test if x,y is inside the bounding box of texts[textIndex]
  textHittest(x, y, textIndex) {
    var text = this.textArray[textIndex];
    return (x >= text.x && x <= text.x + text.width && y >= text.y - text.height && y <= text.y);
  }

  drawAngleSymbol(x1, x2, x3, y1, y2, y3, color, ctx) {
    //  let ctx = this.canvasElement2.getContext('2d');
    var dx1 = x1 - x2;
    var dy1 = y1 - y2;
    var dx2 = x3 - x2;
    var dy2 = y3 - y2;
    var a1 = Math.atan2(dy1, dx1);
    var a2 = Math.atan2(dy2, dx2);
    var a = Math.round(((a2 - a1) * 180 / Math.PI + 360) % 360);
    // draw angleSymbol
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x2, y2);
    ctx.arc(x2, y2, 20, a1, a2);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.globalAlpha = 0.5;
    ctx.fill();
    ctx.restore();
    ctx.fillStyle = color;
    ctx.fillText(String(a) + '°', x2 + 25, y2);
  }

  handleclick(event) {
    if (this.annotationType == 'angle') {
      this.clickCount++;
      if (this.clickCount == 1) {
        this.angstartX = event.layerX;
        this.angstartY = event.layerY;
        this.lines = [];
      }
      else if (this.clickCount == 2) {
        this.lines.push({ x0: this.angstartX, y0: this.angstartY, x1: event.layerX, y1: event.layerY });
        this.angstartX = event.layerX;
        this.angstartY = event.layerY;
      }
      else if (this.clickCount == 3) {
        this.lines.push({ x0: this.angstartX, y0: this.angstartY, x1: event.layerX, y1: event.layerY });
        let ctx = this.canvasElement.getContext('2d');
        this.draw(this.lines, this.drawcolor, ctx);
        this.addtoCanvasData('angle', this.lines[0].x0, this.lines[0].y0, this.lines[1].x0, this.lines[1].y0, this.lines[1].x1, this.lines[1].y1, "", this.drawcolor, "", "", "", "", "", "");
        this.drawAngleSymbol(this.lines[0].x0, this.lines[1].x0, this.lines[1].x1, this.lines[0].y0, this.lines[1].y0, this.lines[1].y1, this.drawcolor, ctx)
        //this.lines=[];
        this.clickCount = 0;
      }
    }
    // else if(this.annotationType=='text'){
    //   this.isTextEditor=true;

    // }

  }

  handleMouseDown(event) {
    this.startX = event.layerX;
    this.startY = event.layerY;

    if (this.annotationType != '' && this.annotationType != 'text') //Common for all
    {
      this.startDraw = true;
      if (this.annotationType == 'scale') {
        if (this.unitPixels > 0) {
          this.isScaleRular = false;
        } else { this.isScaleRular = true; }
      }
    }
    else {  // for selecting the text
      event.preventDefault();

      this.startX = event.layerX;
      this.startY = event.layerY;
      // Put your mousedown stuff here
      for (var i = 0; i < this.textArray.length; i++) {
        if (this.textHittest(this.startX, this.startY, i)) {
          this.selectedText = i;
        }
      }
    }
    //this.lines = [];
  }

  handleMouseMove(event) {
    if (this.startDraw) {

      if (this.annotationType == 'line') {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement.getContext('2d');
        this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, this.drawcolor, ctx);
      }
      else if (this.annotationType == 'scale') {
        this.clearDrawingCanvas();
        //  this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, 'yellow');
        if (this.isScaleRular == true) {
          this.clearDrawingCanvas();
          let ctx = this.canvasElement.getContext('2d');
          this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, this.drawcolor, ctx);
        } else {
          let ctx = this.canvasElement.getContext('2d');
          var x1 = this.startX;
          var x2 = event.layerX;
          var y1 = this.startY;
          var y2 = event.layerY;
          this.drawLineText(ctx, x1, x2, y1, y2, this.drawcolor);
        }
      }
      else if (this.annotationType == 'freehand') {
        this.lines.push({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY });
        let ctx = this.canvasElement.getContext('2d');
        this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, this.drawcolor, ctx);
        this.addtoCanvasData('freehand', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
        this.startX = event.layerX;
        this.startY = event.layerY;

      }
      else if (this.annotationType == 'circle') {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement.getContext('2d');
        //this.drawCircle1(ctx,Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY));
        this.drawCircle(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      }
      else if (this.annotationType == 'rectangle') {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement.getContext('2d');
        this.drawRectangle(ctx, Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY), this.drawcolor);
      }
      else if (this.annotationType == 'arrow') {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement.getContext('2d');

        ctx.strokeStyle = this.drawcolor;
        this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      }
      // else if (this.annotationType == 'angle') {
      //   this.clearDrawingCanvas();
      //   let ctx = this.canvasElement.getContext('2d');
      //   ctx.strokeStyle = 'yellow';
      //   this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY);
      //   this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY);
      // }
    }
    else {
      if (this.selectedText < 0) {
        return;
      }
      event.preventDefault();
      this.clearTextCanvas();

      var mouseX = event.layerX;
      var mouseY = event.layerY;

      // Put your mousemove stuff here
      var dx = mouseX - this.startX;
      var dy = mouseY - this.startY;
      this.startX = mouseX;
      this.startY = mouseY;

      var text = this.textArray[this.selectedText];
      var selIndex = this.canvasData.findIndex(option => option.TextContent == text.text && option.X0 == String(text.x) && option.Y0 == String(text.y));
      text.x += dx;
      text.y += dy;
      if (selIndex >= 0) {
        this.canvasData[selIndex].X0 = String(text.x);
        this.canvasData[selIndex].Y0 = String(text.y);
      }
      let ctx = this.canvasElement3.getContext('2d');
      this.drawText(ctx, this.textArray);
    }
  }

  handleMouseMove2(event) {
    if (this.startDraw) {

      if (this.annotationType == 'line') {
        this.clearDrawingCanvas2();
        let ctx = this.canvasElement4.getContext('2d');
        this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, this.drawcolor, ctx);
      }
      else if (this.annotationType == 'scale') {
        this.clearDrawingCanvas2();
        //  this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, 'yellow');
        if (this.isScaleRular == true) {
          this.clearDrawingCanvas2();
          let ctx = this.canvasElement4.getContext('2d');
          this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, this.drawcolor, ctx);
        } else {
          let ctx = this.canvasElement.getContext('2d');
          var x1 = this.startX;
          var x2 = event.layerX;
          var y1 = this.startY;
          var y2 = event.layerY;
          this.drawLineText(ctx, x1, x2, y1, y2, this.drawcolor);
        }
      }
      else if (this.annotationType == 'freehand') {
        this.lines.push({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY });
        let ctx = this.canvasElement4.getContext('2d');
        this.drawLine({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY }, this.drawcolor, ctx);
        this.addtoCanvasData('freehand', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
        this.startX = event.layerX;
        this.startY = event.layerY;

      }
      else if (this.annotationType == 'circle') {
        this.clearDrawingCanvas2();
        let ctx = this.canvasElement4.getContext('2d');
        //this.drawCircle1(ctx,Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY));
        this.drawCircle(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      }
      else if (this.annotationType == 'rectangle') {
        this.clearDrawingCanvas2();
        let ctx = this.canvasElement4.getContext('2d');
        this.drawRectangle(ctx, Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY), this.drawcolor);
      }
      else if (this.annotationType == 'arrow') {
        this.clearDrawingCanvas2();
        let ctx = this.canvasElement4.getContext('2d');

        ctx.strokeStyle = this.drawcolor;
        this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      }
      // else if (this.annotationType == 'angle') {
      //   this.clearDrawingCanvas();
      //   let ctx = this.canvasElement.getContext('2d');
      //   ctx.strokeStyle = 'yellow';
      //   this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY);
      //   this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY);
      // }
    }
    else {
      if (this.selectedText < 0) {
        return;
      }
      event.preventDefault();
      this.clearTextCanvas();

      var mouseX = event.layerX;
      var mouseY = event.layerY;

      // Put your mousemove stuff here
      var dx = mouseX - this.startX;
      var dy = mouseY - this.startY;
      this.startX = mouseX;
      this.startY = mouseY;

      var text = this.textArray[this.selectedText];
      var selIndex = this.canvasData.findIndex(option => option.TextContent == text.text && option.X0 == String(text.x) && option.Y0 == String(text.y));
      text.x += dx;
      text.y += dy;
      if (selIndex >= 0) {
        this.canvasData[selIndex].X0 = String(text.x);
        this.canvasData[selIndex].Y0 = String(text.y);
      }
      let ctx = this.canvasElement3.getContext('2d');
      this.drawText(ctx, this.textArray);
    }
  }

  handleMouseUpOut(event) {
    this.startDraw = false;
    this.selectedText = -1;
    if (this.annotationType == 'line') {
      this.lines = [];
      this.lines.push({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY });
      this.clearDrawingCanvas();
      let ctx = this.canvasElement2.getContext('2d');
      this.draw(this.lines, this.drawcolor, ctx);
      this.addtoCanvasData('line', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
    }
    else if (this.annotationType == 'scale') {
      if (this.isScaleRular == true) {
        var xCorData = event.layerX - this.startX;
        var yCorData = event.layerY - this.startY;
        var dis = Math.sqrt(xCorData * xCorData + yCorData * yCorData);
        this.unitPixels = Math.round(dis);
        this.scaleassigned = true;
      } else {
        this.clearDrawingCanvas();
        let ctx = this.canvasElement2.getContext('2d');
        var x1 = this.startX;
        var x2 = event.layerX;
        var y1 = this.startY;
        var y2 = event.layerY;
        this.addtoCanvasData('scale', x1, y1, x2, y2, "", "", "", this.drawcolor, "", "", "", this.unitPixels, this.unitValue, this.unitType);
        this.drawLineText(ctx, x1, x2, y1, y2, this.drawcolor);
      }
    }
    else if (this.annotationType == 'freehand') {
      this.clearDrawingCanvas();
      let ctx = this.canvasElement2.getContext('2d');
      this.draw(this.lines, this.drawcolor, ctx);
    }
    else if (this.annotationType == 'circle') {
      this.clearDrawingCanvas();
      let ctx = this.canvasElement2.getContext('2d');
      //this.drawCircle1(ctx,Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY));
      this.drawCircle(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      this.addtoCanvasData('circle', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
    }
    else if (this.annotationType == 'rectangle') {
      this.clearDrawingCanvas();
      let ctx = this.canvasElement2.getContext('2d');
      this.drawRectangle(ctx, Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY), this.drawcolor);
      this.addtoCanvasData('rectangle', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
    }
    else if (this.annotationType == 'arrow') {
      this.clearDrawingCanvas();
      let ctx = this.canvasElement2.getContext('2d');
      ctx.strokeStyle = this.drawcolor;
      this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      this.addtoCanvasData('arrow', this.startX, this.startY, event.layerX, event.layerY, "", "", "2", this.drawcolor, "", "", "", "", "", "");
    }
  }

  handleMouseUpOut2(event) {
    this.startDraw = false;
    this.selectedText = -1;
    if (this.annotationType == 'line') {
      this.lines = [];
      this.lines.push({ x0: this.startX, y0: this.startY, x1: event.layerX, y1: event.layerY });
      this.clearDrawingCanvas2();
      let ctx = this.canvasElement5.getContext('2d');
      this.draw(this.lines, this.drawcolor, ctx);
      this.addtoCanvasData('line', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
    }
    else if (this.annotationType == 'scale') {
      if (this.isScaleRular == true) {
        var xCorData = event.layerX - this.startX;
        var yCorData = event.layerY - this.startY;
        var dis = Math.sqrt(xCorData * xCorData + yCorData * yCorData);
        this.unitPixels = Math.round(dis);
        this.scaleassigned = true;
      } else {
        this.clearDrawingCanvas2();
        let ctx = this.canvasElement5.getContext('2d');
        var x1 = this.startX;
        var x2 = event.layerX;
        var y1 = this.startY;
        var y2 = event.layerY;
        this.addtoCanvasData('scale', x1, y1, x2, y2, "", "", "", this.drawcolor, "", "", "", this.unitPixels, this.unitValue, this.unitType);
        this.drawLineText(ctx, x1, x2, y1, y2, this.drawcolor);
      }
    }
    else if (this.annotationType == 'freehand') {
      this.clearDrawingCanvas2();
      let ctx = this.canvasElement5.getContext('2d');
      this.draw(this.lines, this.drawcolor, ctx);
    }
    else if (this.annotationType == 'circle') {
      this.clearDrawingCanvas2();
      let ctx = this.canvasElement5.getContext('2d');
      //this.drawCircle1(ctx,Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY));
      this.drawCircle(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      this.addtoCanvasData('circle', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
    }
    else if (this.annotationType == 'rectangle') {
      this.clearDrawingCanvas2();
      let ctx = this.canvasElement5.getContext('2d');
      this.drawRectangle(ctx, Math.min(this.startX, event.layerX), Math.min(this.startY, event.layerY), Math.abs(this.startX - event.layerX), Math.abs(this.startY - event.layerY), this.drawcolor);
      this.addtoCanvasData('rectangle', this.startX, this.startY, event.layerX, event.layerY, "", "", "", this.drawcolor, "", "", "", "", "", "");
    }
    else if (this.annotationType == 'arrow') {
      this.clearDrawingCanvas2();
      let ctx = this.canvasElement5.getContext('2d');
      ctx.strokeStyle = this.drawcolor;
      this.drawArrow(ctx, this.startX, this.startY, event.layerX, event.layerY, this.drawcolor);
      this.addtoCanvasData('arrow', this.startX, this.startY, event.layerX, event.layerY, "", "", "2", this.drawcolor, "", "", "", "", "", "");
    }
  }

  addtoCanvasData(type, x0, y0, x1, y1, x2, y2, lwidth, color, canwidth, canheight, TextContent, unitPixels, unitValue, unitType) {
    var x = this.canvasData.length;

    if (x > 0) {
      var id = Number(this.canvasData[x - 1].AnnotationID) + 1;
      this.canvasData.push({
        AnnotationID: String(id), AnnotationType: type, X0: String(x0), Y0: String(y0),
        X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: lwidth,
        Color: color, Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
        , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: ""
      });

      this.canvasTempData.push({
        AnnotationID: String(id), AnnotationType: type, X0: String(x0), Y0: String(y0),
        X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: lwidth,
        Color: color, Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
        , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: ""
      });
    }
    else {
      this.canvasData.push({
        AnnotationID: "1", AnnotationType: type, X0: String(x0), Y0: String(y0),
        X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: lwidth, Color: color,
        Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
        , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: ""
      });

      this.canvasTempData.push({
        AnnotationID: "1", AnnotationType: type, X0: String(x0), Y0: String(y0),
        X1: String(x1), Y1: String(y1), X2: String(x2), Y2: String(y2), Linewidth: lwidth, Color: color,
        Canvaswidth: canwidth, Canvasheight: canheight, TextContent: TextContent
        , UnitPixels: String(unitPixels), UnitValue: String(unitValue), UnitType: String(unitType), Thumbnail: ""
      });
    }
  }

  drawText(ctx, textArray) {
    ctx.clearRect(0, 0, ctx.width, ctx.height);
    //  ctx.drawImage(imageObj, 0, 0, 1920, 1080);
    for (var i = 0; i < textArray.length; i++) {
      var text = textArray[i];
      ctx.fillText(text.text, text.x, text.y);
    }
  }

  drawLineText(ctx, x1, x2, y1, y2, color) {
    var dx = (x2 - x1);
    var dy = (y2 - y1);
    var pad;
    var alignment = "center";
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.strokeStyle = color;
    ctx.stroke();
    pad = 1 / 2;
    ctx.save();
    ctx.textAlign = alignment;
    ctx.textBaseline = "bottom";

    ctx.translate(x1 + dx * pad, y1 + dy * pad);
    if (dx < 0) {
      ctx.rotate(Math.atan2(dy, dx) - Math.PI);  //to avoid label upside down
    }
    else {
      ctx.rotate(Math.atan2(dy, dx));
    }
    var xCorData = x2 - x1;
    var yCorData = y2 - y1;
    var dis = Math.sqrt(xCorData * xCorData + yCorData * yCorData);
    if (dis > 0) {
      var pos = (dis / this.unitPixels) * this.unitValue;
      var txt = parseFloat(String(pos)).toFixed(2) + ' ' + this.unitType;
      ctx.fillStyle = color;
      ctx.fillText(txt, 0, 0);
    }
    ctx.restore();

  }

  addText() {
    // calc the y coordinate for this text on the canvas
    var y = this.textArray.length * 20 + 14;
    let ctx = this.canvasElement3.getContext('2d');
    // get the text from the input element
    var text = {
      text: $("#text_tool").val(),
      x: 20,
      y: y,
      width: 0,
      height: 0
    };

    // calc the size of this text for hit-testing purposes
    ctx.font = "14px consolas";
    ctx.fillStyle = this.drawcolor;
    text.width = ctx.measureText(text.text).width;
    text.height = 14;

    // put this new text in the texts array
    this.textArray.push(text);
    this.addtoCanvasData('text', text.x, text.y, "", "", "", "", "2", this.drawcolor, "", "", text.text, "", "", "");
    this.drawText(ctx, this.textArray);
    this.isTextEditor = false;
  }

  setScaleRular() {
    if (this.unitValue > 0 && this.unitType != "") {
      if (this.unitPixels > 0) {
        this.scaleArray.push({ unitValue: this.unitValue, unitType: this.unitType, unitPixels: this.unitPixels });
        this.isScaleRular = false;
      } else {
        this.isWarningAlert = true;
        this.warningAlert = "Draw a line to measure";
        // alert("Draw a line to measure");
      }
    }
    else {
      this.isWarningAlert = true;
      this.warningAlert = "Please select unit values";
      // alert("Please select unit values");
    }
  }

  clearDrawingCanvas() {
    let ctx = this.canvasElement.getContext('2d');
    let cw = this.canvasElement.width;
    let ch = this.canvasElement.height;
    ctx.clearRect(0, 0, cw, ch);

  }
  clearDrawingCanvas2() {
    let ctx = this.canvasElement4.getContext('2d');
    let cw = this.canvasElement4.width;
    let ch = this.canvasElement4.height;
    ctx.clearRect(0, 0, cw, ch);

  }
  clearCanvas() {
    let ctx = this.canvasElement2.getContext('2d');
    let cw = this.canvasElement2.width;
    let ch = this.canvasElement2.height;
    ctx.clearRect(0, 0, cw, ch);

  }
  clearTextCanvas() {
    let ctx = this.canvasElement3.getContext('2d');
    let cw = this.canvasElement3.width;
    let ch = this.canvasElement3.height;
    ctx.clearRect(0, 0, cw, ch);

  }
  changeAnnotation(type, videoPlayer) {
    this.annotationType = type;
    if (type == "angle") {
      this.clickCount = 0;
    }
    if (type == "text") {
      this.isTextEditor = true;
    }
    if (type == "scale") {
      this.isScaleRular = true;
      this.scaleArray = [];
    }
    this.startAnnotation(videoPlayer);
  }

  public ThumbURL: string;
  // saveAnnotation(videoplayer) {
  //   var canvasSnap = <HTMLCanvasElement>document.getElementById('canvasDraw2');
  //   var videoSnap = <HTMLCanvasElement>document.getElementById('videoplayer');
  //   var context = canvasSnap.getContext('2d');
  //   context.drawImage(videoSnap, 0, 0, canvasSnap.width, canvasSnap.height);

  //   this.service.UploadFile({ data: canvasSnap.toDataURL(), name: "image/png" }).subscribe((ret: any = []) => {
  //     this.ThumbURL = ret.name;
  //     this.service.VideoAnalyseCRUD({
  //       ActionFlag: 'CreateVideoAnnotations', RefUserID: this.UserCode, SessionID: this.SessionID,
  //       VideoAnalyseID: '', FrameTime: String(this.AnnotationTime),
  //       lstAnnotations: this.canvasData, Thumbnail: this.ThumbURL
  //     }).subscribe((ret: any = []) => {
  //       let BTStatus = String(ret.Status);
  //       this.clearCanvas();
  //       this.canvasData = [];
  //       this.clearTextCanvas();
  //       this.showcanvas = false;
  //       this.service.VideoAnalyseCRUD({ ActionFlag: 'FetchAnnotationsBySession', RefUserID: this.UserCode, SessionID: this.SessionID }).subscribe((ret: any = []) => {
  //         let BTStatus = String(ret.Status);
  //         if (ret.Status) {
  //           this.lstAnnotations = ret.lstAnnotations;
  //           this.lstAnnotationDetails = ret.lstAnnotationDetails;
  //           this.lstAnnotationReviews = ret.lstAnnotationReviews;

  //           videoplayer.currentTime = this.AnnotationTime;
  //           var nxttime = this.lstAnnotations.filter(option => Number(option.FrameTime) >= this.AnnotationTime);
  //           if (nxttime.length > 0)
  //             this.NextAnnotationTime = nxttime[0].FrameTime;
  //           else
  //             this.NextAnnotationTime = videoplayer.duration;
  //         }
  //       });
  //     });
  //   });
  // }

  // FetchAnnotations() {
  //   this.service.VideoAnalyseCRUD({ ActionFlag: 'FetchAnnotationsBySession', RefUserID: '24', SessionID: this.SessionID }).subscribe((ret: any = []) => {
  //     let BTStatus = String(ret.Status);
  //     if (ret.Status) {
  //       this.lstAnnotations = ret.lstAnnotations;
  //       this.lstAnnotationDetails = ret.lstAnnotationDetails;
  //       this.clearCanvas();
  //       this.canvasData = [];
  //       this.showcanvas = false;
  //       if (this.lstAnnotations.length > 0)
  //         this.NextAnnotationTime = this.lstAnnotations[0].FrameTime;
  //       this.annotationNumber = 0;
  //     }
  //   });
  // }
  LoadMetadata(e, video) {
    if (video == 'videoCompare1') {
      $("#custom-seekbar").css("width", "0%");
    }
    else if (video == 'videoCompare2') {
      $("#custom-seekbar2").css("width", "0%");
    }

  }

  startAnnotation(videoplayer) {
    if (!this.showcanvas) {
      this.canvasData = [];
      videoplayer.pause();
      this.AnnotationTime = videoplayer.currentTime;
      this.showcanvas = true;
      this.lines = [];
      this.clearCanvas();
      this.clearDrawingCanvas();
      this.clearTextCanvas();
    }
  }
  play1: boolean = true;
  play2: boolean = true;
  resume(videoplayer, player) {
    if (!this.isTimelineLinked) {
      if (videoplayer.paused == true)
        videoplayer.play();
      else
        videoplayer.pause();
      this.canvasData = [];
      this.clearCanvas();
      this.clearDrawingCanvas();
      this.clearDrawingCanvas2();
      this.clearTextCanvas();
      this.showcanvas = false;
      if (player == 'play1') {
        this.play1 = !this.play1;
      } else {
        this.play2 = !this.play2;
      }
    }
  }

  videoEnded(videoplayer) {
    //  this.NextAnnotationTime = this.lstAnnotations[0].FrameTime;
    videoplayer.pause();
    this.play1 = true;
  }
  Video1SeekTime: string = '0:00 Mins';
  Video1Duration: string = '0:00 Mins';
  Video2SeekTime: string = '0:00 Mins';
  Video2Duration: string = '0:00 Mins';
  setCurrentTime(videoplayer1, videoplayer2) {

    var percentage = (videoplayer1.currentTime / videoplayer1.duration) * 100;
    $("#custom-seekbar").css("width", percentage + "%");
    this.Video1SeekTime = Math.floor((videoplayer1.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((videoplayer1.currentTime) % 60)), 2) + ' Mins';
    this.Video1Duration = Math.floor((videoplayer1.duration) / 60) % 60 + ':' + this.pad((Math.floor((videoplayer1.duration) % 60)), 2) + ' Mins';

    var percentage2 = (videoplayer2.currentTime / videoplayer2.duration) * 100;
    $("#custom-seekbar2").css("width", percentage2 + "%");
    this.Video2SeekTime = Math.floor((videoplayer2.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((videoplayer2.currentTime) % 60)), 2) + ' Mins';
    this.Video2Duration = Math.floor((videoplayer2.duration) / 60) % 60 + ':' + this.pad((Math.floor((videoplayer2.duration) % 60)), 2) + ' Mins';

    if (this.isTimelineLinked) {
      this.setCompareTime(videoplayer1, videoplayer2)
    }

    // if (parseFloat(videoplayer.currentTime).toFixed(1) == (parseFloat(String(this.NextAnnotationTime)).toFixed(1))) {
    // if (videoplayer.currentTime >= this.NextAnnotationTime) {
    //   // videoplayer.pause();
    //   videoplayer.currentTime = this.NextAnnotationTime;
    //   this.showcanvas = false;
    //   this.AnnotationTime = videoplayer.currentTime;
    //   //var x= this.lstAnnotations[this.annotationNumber].FrameTime;
    //   this.lstCanvasAnnotationDetails = this.lstAnnotationDetails.filter(option => Number(option.FrameTime) === this.NextAnnotationTime);
    //   this.clearCanvas();
    //   // this.drawAnnotations(this.lstCanvasAnnotationDetails);
    //   var x = this.lstAnnotations.filter(option => option.FrameTime > this.NextAnnotationTime)
    //   if (x.length > 0)
    //     this.NextAnnotationTime = x[0].FrameTime;
    //   else
    //     this.NextAnnotationTime = videoplayer.duration;
    // }
  }

  skip(val, videoplayer) {
    if (!this.isTimelineLinked) {
      videoplayer.currentTime += val;
    }
  }

  seekbarclick(event, videoplayer) {
    if (!this.isTimelineLinked) {
      if (event.type == 'mousedown') {
        this.seekbarmove = true;
      } else if (event.type == 'mouseup') {
        this.seekbarmove = false;
      }
      else if ((event.type == 'mousemove' && this.seekbarmove === true) || event.type == 'click') {
        var offset = event.offsetX;
        var percentage = (offset / event.currentTarget.clientWidth);
        var vidTime = videoplayer.duration * percentage;
        videoplayer.currentTime = String(vidTime);

        this.clearCanvas();
        this.canvasData = [];
      }
    }
  }

  playVideo(vPlayer) {
    vPlayer.play();
    this.clearCanvas();
    this.canvasData = [];
  }

  unitTypeChange() {
    this.unitPixels = 0;
  }

  drawAnnotations(list) {
    //this.clearCanvas();
    this.textArray = [];
    for (var i = 0; i < list.length; i++) {
      // setTimeout(function () {
      // }, 500);
      if (list[i].AnnotationType == 'line') {
        this.lines = [];
        this.lines.push({ x0: list[i].X0, y0: list[i].Y0, x1: list[i].X1, y1: list[i].Y1 });
        let ctx = this.canvasElement.getContext('2d');
        this.draw(this.lines, list[i].Color, ctx);
      }
      else if (list[i].AnnotationType == 'freehand') {
        this.lines = [];
        this.lines.push({ x0: list[i].X0, y0: list[i].Y0, x1: list[i].X1, y1: list[i].Y1 });
        let ctx = this.canvasElement.getContext('2d');
        this.draw(this.lines, list[i].Color, ctx);
      }
      else if (list[i].AnnotationType == 'circle') {
        let ctx = this.canvasElement2.getContext('2d');
        this.drawCircle(ctx, list[i].X0, list[i].Y0, list[i].X1, list[i].Y1, list[i].Color);
      }
      else if (list[i].AnnotationType == 'rectangle') {
        let ctx = this.canvasElement2.getContext('2d');
        //this.drawCircle(ctx, list[i].X0, list[i].Y0, list[i].X1, list[i].Y1);
        this.drawRectangle(ctx, Math.min(list[i].X0, list[i].X1), Math.min(list[i].Y0, list[i].Y1), Math.abs(list[i].X0 - list[i].X1), Math.abs(list[i].Y0 - list[i].Y1), this.drawcolor);
      }
      else if (list[i].AnnotationType == 'arrow') {
        let ctx = this.canvasElement2.getContext('2d');
        ctx.strokeStyle = list[i].Color;
        this.drawArrow(ctx, list[i].X0, list[i].Y0, list[i].X1, list[i].Y1, list[i].Color);
      }
      else if (list[i].AnnotationType == 'angle') {
        let ctx = this.canvasElement.getContext('2d');
        ctx.strokeStyle = list[i].Color;
        this.lines = [];
        this.lines.push({ x0: list[i].X0, y0: list[i].Y0, x1: list[i].X1, y1: list[i].Y1 });
        this.lines.push({ x0: list[i].X1, y0: list[i].Y1, x1: list[i].X2, y1: list[i].Y2 });

        this.draw(this.lines, list[i].Color, ctx);
        this.drawAngleSymbol(list[i].X0, list[i].X1, list[i].X2, list[i].Y0, list[i].Y1, list[i].Y2, list[i].Color, ctx);
      }
      else if (list[i].AnnotationType == 'scale') {
        let ctx = this.canvasElement2.getContext('2d');
        ctx.strokeStyle = list[i].Color;
        this.unitPixels = list[i].UnitPixels;
        this.unitValue = list[i].UnitValue;
        this.unitType = list[i].UnitType;
        this.drawLineText(ctx, list[i].X0, list[i].X1, list[i].Y0, list[i].Y1, list[i].Color);
      }
      else if (list[i].AnnotationType == 'text') {
        let ctx = this.canvasElement2.getContext('2d');
        var text = {
          text: list[i].TextContent,
          x: list[i].X0,
          y: list[i].Y0,
          width: list[i].X1,
          height: list[i].Y1
        };

        // calc the size of this text for hit-testing purposes
        ctx.font = "14px consolas";
        ctx.fillStyle = list[i].Color;
        text.width = ctx.measureText(text.text).width;
        text.height = 14;

        // put this new text in the texts array
        this.textArray.push(text);
        this.drawText(ctx, this.textArray);

      }
    }
    this.textArray = [];
  }

  undoAnnotation(videoplayer) {
    this.clearCanvas();
    this.clearDrawingCanvas();
    if (this.canvasData.length > 0) {
      this.canvasData.splice(this.canvasData.length - 1, 1);
      // this.drawAnnotations(this.canvasData);
    }
    if (this.lstAnnotationDetails > 0) {
      // this.drawAnnotations(this.lstAnnotationDetails);
    }
  }

  changeVideoStatus(videoCompare1, videoCompare2, myvideo) {
    if (this.isTimelineLinked) {
      var ctime = (this.commonPercentage * this.compareduration) / 100;
      var v1play = false; var v2play = false;
      //alert(ctime + " " + this.V1StartTime + " " + this.V2StartTime + " " + this.videocompare1Starttime + " " + this.videocompare2Starttime + " " + videoCompare1.currentTime + " " + videoCompare2.currentTime + " " + videoCompare2.duration)
      if ((ctime >= this.V1StartTime) && (videoCompare1.currentTime < videoCompare1.duration)) {
        v1play = true;
      }
      if ((ctime >= this.V2StartTime) && (videoCompare2.currentTime < videoCompare2.duration)) {
        v2play = true;
      }
      if (this.videoStatus == 'play') {
        this.videoStatus = 'pause';
        if (v1play && v2play) {
          videoCompare1.play();
          videoCompare2.play();
          this.play1 = false;
          this.play2 = false;
        }
        else if (v1play) {
          videoCompare1.play();
          this.play1 = false;
          this.play2 = true;
        }
        else if (v2play) {
          videoCompare2.play();
          this.play1 = true;
          this.play2 = false;
        }
      }
      else {
        this.videoStatus = 'play';
        // this.isTimelineLinked = false;
        videoCompare1.pause();
        videoCompare2.pause();
        this.play1 = true;
        this.play2 = true;
        this.changeref.detectChanges();
      }
    }
    else {
      this.videoStatus = 'play';
      videoCompare1.pause();
      videoCompare2.pause();
      this.play1 = true;
      this.play2 = true;
    }


    // if (this.isTimelineLinked) {
    //   if (this.videoStatus == 'play') {
    //     this.videoStatus = 'pause';
    //     videoCompare1.pause();
    //     videoCompare2.pause();
    //     myvideo.pause();
    //   }
    //   else {
    //     this.videoStatus = 'play';
    //     videoCompare1.play();
    //     videoCompare2.play();
    //     this.clearDrawingCanvas();
    //     this.clearDrawingCanvas2();
    //     this.clearTextCanvas();
    //     this.showcanvas = false;
    //     myvideo.play();
    //   }
    // }
  }
  isTimelineLinked: boolean = false;

  /* Devendran Code -- Start*/
  showCommonPlay: any = true;
  videoLinkEnabled: any = false;
  commonCtrlVideoTimeDiff: any = 0;
  commonCtrlVideoTimeDiffID: any = "no_difference";
  commonCtrlVideoId: any = "videoCompare1";
  video1LinkTime: any;
  video2LinkTime: any;
  resumePlay: any = false;
  commonPlayFromFirstV1: any = false;
  commonPlayFromFirstV2: any = false;
  videoLinkIndicatorPosLeft: any = 0;


  /* Devendran Code -- END*/
  CompstartTime: any;
  CompremTime: any;
  V1StartTime: any;
  V2StartTime: any;
  remComparevideo: any;

  Linktimeline(videoCompare1, videoCompare2) {
    if (String(videoCompare2.duration) != 'NaN') {
      var v2link = videoCompare2.src;
      if (v2link.substr(v2link.length - 3) == "mp4") {
        this.isTimelineLinked = true;
        videoCompare1.pause();
        videoCompare2.pause();
        this.videoStatus = 'play';
        var vid1duration = videoCompare1.duration - videoCompare1.currentTime;
        var vid2duration = videoCompare2.duration - videoCompare2.currentTime;
        this.CompstartTime = Math.max(videoCompare1.currentTime, videoCompare2.currentTime);
        this.CompremTime = Math.max(vid1duration, vid2duration);

        var totalCompareDuration = this.CompstartTime + this.CompremTime;
        this.compareduration = totalCompareDuration;

        if (this.CompstartTime == videoCompare1.currentTime) {
          this.V1StartTime = 0;
          this.V2StartTime = videoCompare1.currentTime - videoCompare2.currentTime;
          this.secondvideoStartPercentage = ((this.V2StartTime) / this.compareduration) * 100;
        }
        else if (this.CompstartTime == videoCompare2.currentTime) {
          this.V1StartTime = videoCompare2.currentTime - videoCompare1.currentTime;
          this.V2StartTime = 0;
          this.secondvideoStartPercentage = ((this.V1StartTime) / this.compareduration) * 100;
        }

        if (this.CompremTime == vid1duration) {
          this.remComparevideo = 1;
        }
        else if (this.CompremTime == vid2duration) {
          this.remComparevideo = 2;
        }



        this.videocompare1Starttime = videoCompare1.currentTime;
        this.videocompare2Starttime = videoCompare2.currentTime;

        // if (this.compareduration == vid1duration)
        //   this.refVideotoCompare = 1;
        // else if (this.compareduration == vid2duration)
        //   this.refVideotoCompare = 2;

        this.currentDurationTime = Math.floor(totalCompareDuration / 60) % 60 + ':' + this.pad((Math.floor(totalCompareDuration % 60)), 2) + ' Mins';
        this.currentCompareTime = Math.floor((this.CompstartTime) / 60) % 60 + ':' + this.pad((Math.floor((this.CompstartTime) % 60)), 2) + ' Mins';

        // this.isSuperImpose = true;
        var percentage = ((this.CompstartTime) / this.compareduration) * 100;
        this.commonPercentage = percentage;
        this.comStartPercentage = percentage;
        $("#video-progress-bar").css("width", percentage + "%");
      }
      else {
        alert("Please select a video to compare");
      }
    }
  }

  currentCompareTime: string = '0:00 Mins';
  currentDurationTime: string = '0:00 Mins';
  pad(n, width) {
    var z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  currentCompTime!: any;
  setCompareTime(videoCompare1, videoCompare2) {
    var percentage = 0;

    if (this.V1StartTime == 0) {
      if (videoCompare1.currentTime < this.videocompare1Starttime) {
        percentage = ((videoCompare1.currentTime) / this.compareduration) * 100;
        this.currentCompareTime = Math.floor((videoCompare1.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((videoCompare1.currentTime) % 60)), 2) + ' Mins';
        this.currentCompTime = videoCompare1.currentTime;
        if (this.videoStatus == 'pause') {
          this.playvideos(percentage, videoCompare1, videoCompare2);
        }
      }
      else {
        if (this.remComparevideo == 1) {
          percentage = ((this.V1StartTime + videoCompare1.currentTime) / this.compareduration) * 100;
          this.currentCompareTime = Math.floor((this.V1StartTime + videoCompare1.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((this.V1StartTime + videoCompare1.currentTime) % 60)), 2) + ' Mins';
          this.currentCompTime = this.V1StartTime + videoCompare1.currentTime;
          if (this.videoStatus == 'pause') {
            this.playvideos(percentage, videoCompare1, videoCompare2);
          }
        }
        else {
          percentage = ((this.V2StartTime + videoCompare2.currentTime) / this.compareduration) * 100;
          this.currentCompareTime = Math.floor((this.V2StartTime + videoCompare2.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((this.V2StartTime + videoCompare2.currentTime) % 60)), 2) + ' Mins';
          this.currentCompTime = this.V2StartTime + videoCompare2.currentTime;
          if (this.videoStatus == 'pause') {
            this.playvideos(percentage, videoCompare1, videoCompare2);
          }
        }
      }


    }
    else if (this.V2StartTime == 0) {

      if (videoCompare2.currentTime < this.videocompare2Starttime) {
        percentage = ((videoCompare2.currentTime) / this.compareduration) * 100;
        this.currentCompareTime = Math.floor((videoCompare2.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((videoCompare2.currentTime) % 60)), 2) + ' Mins';
        this.currentCompTime = videoCompare2.currentTime;
        if (this.videoStatus == 'pause') {
          this.playvideos(percentage, videoCompare1, videoCompare2);
        }
      }
      else {

        if (this.remComparevideo == 1) {
          percentage = ((this.V1StartTime + videoCompare1.currentTime) / this.compareduration) * 100;
          this.currentCompareTime = Math.floor((this.V1StartTime + videoCompare1.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((this.V1StartTime + videoCompare1.currentTime) % 60)), 2) + ' Mins';
          this.currentCompTime = this.V1StartTime + videoCompare1.currentTime;
          if (this.videoStatus == 'pause') {
            this.playvideos(percentage, videoCompare1, videoCompare2);
          }
        }
        else {
          percentage = ((this.V2StartTime + videoCompare2.currentTime) / this.compareduration) * 100;
          this.currentCompareTime = Math.floor((this.V2StartTime + videoCompare2.currentTime) / 60) % 60 + ':' + this.pad((Math.floor((this.V2StartTime + videoCompare2.currentTime) % 60)), 2) + ' Mins';
          this.currentCompTime = this.V2StartTime + videoCompare2.currentTime;
          if (this.videoStatus == 'pause') {
            this.playvideos(percentage, videoCompare1, videoCompare2);
          }
        }
      }
    }


    // if (this.remComparevideo == 1) {
    //   percentage = ((videoCompare1.currentTime - this.videocompare1Starttime) / this.compareduration) * 100;
    //   this.currentCompareTime = Math.floor((videoCompare1.currentTime - this.videocompare1Starttime) / 60) % 60 + ':' + this.pad((Math.floor((videoCompare1.currentTime - this.videocompare1Starttime) % 60)), 2) + ' Mins';
    // }
    // else {
    //   percentage = ((videoCompare2.currentTime - this.videocompare2Starttime) / this.compareduration) * 100;
    //   this.currentCompareTime = Math.floor((videoCompare2.currentTime - this.videocompare2Starttime) / 60) % 60 + ':' + this.pad((Math.floor((videoCompare2.currentTime - this.videocompare2Starttime) % 60)), 2) + ' Mins';
    // }

    $("#video-progress-bar").css("width", percentage + "%");

    if (percentage >= 100) {
      videoCompare1.pause();
      videoCompare2.pause();
      this.videoStatus = 'play';
    }

    // if (parseFloat(videoplayer.currentTime).toFixed(1) == (parseFloat(String(this.NextAnnotationTime)).toFixed(1))) {
    // if (videoplayer.currentTime >= this.NextAnnotationTime) {
    //   videoplayer.pause();
    //   videoplayer.currentTime = this.NextAnnotationTime;
    //   this.showcanvas = true;
    //   this.AnnotationTime = videoplayer.currentTime;
    //   //var x= this.lstAnnotations[this.annotationNumber].FrameTime;
    //   this.lstCanvasAnnotationDetails = this.lstAnnotationDetails.filter(option => Number(option.FrameTime) === this.NextAnnotationTime);
    //   this.clearCanvas();
    //   this.drawAnnotations(this.lstCanvasAnnotationDetails);
    //   var x = this.lstAnnotations.filter(option => option.FrameTime > this.NextAnnotationTime)
    //   if (x.length > 0)
    //     this.NextAnnotationTime = x[0].FrameTime;
    //   else
    //     this.NextAnnotationTime = videoplayer.duration;
    // }  
  }

  playvideos(percentage, videoCompare1, videoCompare2) {
    var ctime; var v1play = false; var v2play = false;
    ctime = (percentage * this.compareduration) / 100;
    if ((ctime >= this.V1StartTime) && (videoCompare1.currentTime < videoCompare1.duration)) {
      v1play = true;
    }
    if ((ctime >= this.V2StartTime) && (videoCompare2.currentTime < videoCompare2.duration)) {
      v2play = true;
    }

    if (v1play) {
      videoCompare1.play();
      this.play1 = false;
    }
    else {
      videoCompare1.pause();
      this.play1 = true;
    }
    if (v2play) {
      videoCompare2.play();
      this.play2 = false;
    }
    else {
      videoCompare2.pause();
      this.play2 = true;
    }
  };

  skipCommon(val, videoCompare1, videoCompare2) {
    if (this.isTimelineLinked) {

      this.currentCompTime += val;
      var percentage = (this.currentCompTime / this.compareduration);

      if (this.V1StartTime == 0) {
        videoCompare1.currentTime = (this.compareduration * percentage);
        if ((this.compareduration * percentage) > this.V2StartTime)
          videoCompare2.currentTime = (this.compareduration * percentage) - this.V2StartTime;
        else
          videoCompare2.currentTime = 0;
      }
      else if (this.V2StartTime == 0) {
        videoCompare2.currentTime = (this.compareduration * percentage);
        if ((this.compareduration * percentage) > this.V1StartTime)
          videoCompare1.currentTime = (this.compareduration * percentage) - this.V1StartTime;
        else
          videoCompare1.currentTime = 0;
      }

      // videoCompare1.pause();
      // videoCompare2.pause();
      // this.videoStatus = 'pause';
      // this.play1 = true;
      // this.play2 = true;
    }
  }
  compareseekbarmove: boolean = false;
  seekbarpercentage: string = "0%";
  compSeekTime: any;
  commonPercentage: any;
  comStartPercentage: any;
  secondvideoStartPercentage: any;
  compareSeekbarclick(event, videoCompare1, videoCompare2, myVideo) {
    if (event.srcElement.className != 'vpoint1' && event.srcElement.className != 'vpoint2') {
      if (event.type == 'mousedown') {
        this.compareseekbarmove = true;
      } else if (event.type == 'mouseup') {
        this.compareseekbarmove = false;
      }
      else if ((event.type == 'mousemove' && this.compareseekbarmove === true) || event.type == 'click') {
        var offset = event.offsetX;
        var percentage = (offset / event.currentTarget.clientWidth);
        this.compSeekTime = this.compareduration ;
        this.currentCompTime = this.compSeekTime* percentage;
        if (this.V1StartTime == 0) {
          videoCompare1.currentTime = (this.compareduration * percentage);
          if ((this.compareduration * percentage) > this.V2StartTime)
            videoCompare2.currentTime = (this.compareduration * percentage) - this.V2StartTime;
          else
            videoCompare2.currentTime = 0;
        }
        else if (this.V2StartTime == 0) {
          videoCompare2.currentTime = (this.compareduration * percentage);
          if ((this.compareduration * percentage) > this.V1StartTime)
            videoCompare1.currentTime = (this.compareduration * percentage) - this.V1StartTime;
          else
            videoCompare1.currentTime = 0;
        }
        // videoCompare1.currentTime = (this.compareduration * percentage) + this.videocompare1Starttime;
        // videoCompare2.currentTime = (this.compareduration * percentage) + this.videocompare2Starttime;
        if (this.isOverlay == true) {
          myVideo.currentTime = videoCompare1.currentTime;
        }
        this.commonPercentage = percentage * 100;
        // alert(percentage * 100);
        $("#video-progress-bar").css("width", (percentage * 100) + "%");
      }
    }
  }
  isswapped: boolean = false;

  Back() {
    this.router.navigate([this.utility.previousPage]);
  }

  public _filterSkills: string;
  public _filterView: string;
  public _filterSessions: string;

  public listSessionType: any;
  public listVideoView: any;
  public lsitFilterVideos: any;

  filterClick() {
    var VideoCompareFilter = {
      ActionFlag: 'GetVideoFilters', AtheleteRefUserID: this.AthleteID,
      CoachRefUserID: this.UserCode
    }
    this.service.VideoCompareFilters(VideoCompareFilter).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (ret.Status) {
        this.listSessionType = ret.lstSessionType;
        this.listVideoView = ret.lstVideoView;
        this.lsitFilterVideos = ret.lstFilterVideos;
      }
    });
  }
  CompareVideoSession: string;
  CompareVideoSesType: string;
  CompareVideoSesView: string;
  CompareSessionID: string;
  chooseVideoToCompare(vid) {
    this.IsEmptyVideo = false;
    this.CompareVideoURL = vid.CloudURL;
    this.CompareVideoThumbnail = vid.ThumbnailURL;
    this.CompareVideoSession = vid.SessionName;
    this.CompareVideoSesType = vid.SessionTypeName;
    this.CompareVideoSesView = vid.VideoViewName;
    this.CompareSessionID = vid.SessionID;
  }

  public tempURL: string; public tempURL1: string;
  public tempThum: string; public tempThum1: string;

  SwapVideos() {
    // this.tempURL = this.SessionVideo;
    // this.tempURL1 = this.CompareVideoURL;
    // this.tempThum = this.sessionsThumbnail;
    // this.tempThum1 = this.CompareVideoThumbnail;

    // this.SessionVideo = ""; this.CompareVideoURL = "";
    // this.sessionsThumbnail = ""; this.CompareVideoThumbnail = "";

    // this.SessionVideo = this.tempURL1;
    // this.CompareVideoURL = this.tempURL;
    // this.sessionsThumbnail = this.tempThum1;
    // this.CompareVideoThumbnail = this.tempThum;
    if (this.isswapped) {
      this.isswapped = false;
    } else {
      this.isswapped = true;
    }

  }

  public superImposeURL: string; public superImposeThum: string;
  superImpose(imposevideo, videoCompare1, videoCompare2) {
    if (this.isOverlay == false) {
      this.isOverlay = true;
      this.superImposeThum = this.CompareVideoThumbnail;
      this.superImposeURL = this.CompareVideoURL;
      imposevideo.currentTime = videoCompare2.currentTime;

      imposevideo.play();
      videoCompare1.pause();
      imposevideo.pause();
    }
    else {
      this.isOverlay = false;
      this.CompareVideoThumbnail = this.superImposeThum;
      this.CompareVideoURL = this.superImposeURL;
      videoCompare1.pause();
      videoCompare2.pause();
      videoCompare2.currentTime = imposevideo.currentTime;
    }
  }

}


export interface canvasDetails {
  AnnotationID: string, AnnotationType: string, X0: string, Y0: string, X1: string,
  Y1: string, X2: string, Y2: string, Linewidth: string, Color: string, Canvaswidth: string,
  Canvasheight: string, TextContent: string, UnitPixels: string, UnitValue: string,
  UnitType: string, Thumbnail: string
}