<div class="page-container my-videos-page video-analyse-page  playlistview-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="video-container">
                            <div class="video-item br-5">
                                <div class="vprofile-header" style="margin-bottom: 5px;">
                                    <div class="row">
                                        <div class="col-md-6">
                                        <a href="javascript:void(0);" class="vprofile-left">
                                            <div class="vprofile-name">{{currentPlaylistName}}</div>
                                        </a>  
                                        </div>
                                        <div class="col-md-5 txt-right">
                                            <a href="javascript:void(0)" class="theme-button" routerLink="/myplaylist">Back</a>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="vprofile-more txt-right">
                                                <div class="dropdown" style="float: right;">
                                                  <a href="javascript:void(0);" class="ico-menu dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                      <a class="dropdown-item" href="javascript:void(0);" data-toggle="modal" data-target="#plpopupshare">Share to Coach</a>
                                                      <a class="dropdown-item" href="javascript:void(0);">Share External</a>
                                                      <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                                                    </div>
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                     
                                </div>
                                <div class="playlist-view-video">
                                    <!-- <div id="myElement1"></div> -->
                                    <video width="100%" class="video-js" width="640" height="264"
                                        (timeupdate)="setCurrentTime(videoPlayer)" #videoPlayer controls="false"
                                        poster="{{PosterURL}}" preload="auto"
                                        disablePictureInPicture controlsList="nodownload" 
                                        (loadeddata)="onVideoLoaded($event, videoPlayer)" 
                                        src="{{videoURL}}" (click)="VideoClick($event,videoPlayer)">
                                    </video>
                                    <div class="playlist-overlay">
                                        <div class="list-toggle collapsed"><img src="assets/images/right-arrow.png"></div>
                                        <div class="toggle-container">
                                            <a href="javascript:void(0);" (click)="playVideo(videoPlayer,play,ind)" [ngClass]="(ind==currentVideo)?'selected':''" *ngFor="let play of PlayListDetails;let ind=index;">
                                                <figcaption>
                                                    <ul class="playlist-container">
                                                        <!-- <li>21-May-2021</li> -->
                                                        <li>{{play.SessionTypeName}}</li>
                                                        <li>{{play.PMLineLength}}</li>
                                                    </ul>
                                                </figcaption>
                                            </a> 
                                           
                                        </div>
                                    </div>
                                    <div class="comparison-control box-shadow">
                                        <div class="video-action-progress">
                                            <div class="video-playpause">
                                                <a href="javascript:void(0);" (click)="changeVideoStatus(videoPlayer)">
                                                    <img *ngIf="videoStatus=='pause'" src="assets/images/annotation/ico_play_128.png">
                                                    <img *ngIf="videoStatus=='play'" src="assets/images/annotation/ico_pause_128.png">
                                                </a>
                                            </div>
                                            <div class="video-progress-container">
                                                <div class="video-progress-track"  (click)="seekbarclick($event,videoPlayer)"
                                                (mousedown)="seekbarclick($event,videoPlayer)"
                                                (mousemove)="seekbarclick($event,videoPlayer)"
                                                (mouseup)="seekbarclick($event,videoPlayer)">
                                                    <span class="start-time">{{VideoTimeText}}</span>
                                                    <span class="end-time">{{VideodurationText}}</span>
                                                    <div class="video-progress-bar" id="custom-seekbar"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="custom-seekbar" (click)="seekbarclick($event,videoPlayer)"
                                            (mousedown)="seekbarclick($event,videoPlayer)"
                                            (mousemove)="seekbarclick($event,videoPlayer)"
                                            (mouseup)="seekbarclick($event,videoPlayer)" id="custom-seekbar">
                                            <span></span>
                                        </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="playlist-right box-shadow br-5">
                            <div class="title">My Playlist</div>
                            <div class="common-body">
                                <div class="search-container">
                                    <mat-form-field class="md-block search-txt" appearance="outline"
                                        floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon">
                                            <a href="javascript:void(0);">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.12 373.12"
                                                    width="24" height="24">
                                                    <path
                                                        d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                </svg>
                                            </a>
                                        </mat-icon>
                                        <input matInput placeholder="Search Playlist"  [(ngModel)]="SearchFilter" >
                                    </mat-form-field>
                                </div>
                                <div class="analyse-video-container">
                                    <div class="analyse-list mb15"  (click)="changePlaylist(videoPlayer,play,ind)" 
                                    [ngClass]="(play.PlayListID==PlaylistID)?'selected':''" *ngFor="let play of PlayLists|filter:SearchFilter;let ind=index;">
                                        <div class="analyse-ttile">{{play.PlayListName}}</div>
                                        <a href="javascript:void(0)" routerLink="/playlist-view">
                                            <div class="playlistview-thumbnail">
                                                <img src="{{play.ThumbnailURL}}">
                                            </div>
                                            <div class="analyse-description">
                                                <div class="batting-style">{{play.Tags}}</div>
                                            </div>
                                            
                                            <div class="analyse-play-icon">
                                                <img src="assets/images/play.png">
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Preview Modal -->
<div class="modal fade" id="plpopupshare" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"> Playlist Share to - Coach</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="playlist-share-info">
                    <div class="">
                        <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                            floatLabel="always">
                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit=""
                                    height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path opacity=".3"
                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                    </path>
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                    </path>
                                </svg></mat-icon>
                            <!-- <mat-select placeholder="Coach">
                                <mat-option [value]="01">Virat Kohli</mat-option>
                                <mat-option [value]="02">Rohit Share</mat-option>
                                <mat-option [value]="03">Ravindra Jadeja</mat-option>
                                <mat-option [value]="04">Dinesh Karthik</mat-option>
                            </mat-select> -->
                            <mat-select placeholder="Coach" multiple [(ngModel)]="Coaches">
                                <mat-option [value]="coach.CoachRefUserID"
                                    *ngFor="let coach of CoachList"
                                    style="text-transform:capitalize;">
                                    {{coach.CoachName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="txt-center"><a href="javascript:void(0);"  data-dismiss="modal" class="theme-button mt5" (click)="SharetoCoaches()">Share</a></div>
                </div>
            </div>
        </div>
    </div>
</div>