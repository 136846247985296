import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UtilitiesService } from './utilities.service';
import { AuthGuard } from './auth/auth.guard';


import { AllRoutes, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { LandingLayoutComponent } from './layout/landing-layout.component';
import { HomeLayoutComponent } from './layout/home-layout.component';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle'; 
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { LandingComponent } from './landing/landing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlayerComponent } from './registration/player/player.component';
import { CoachComponent } from './registration/coach/coach.component';
import { TrainerComponent } from './registration/trainer/trainer.component';
import { PhysioComponent } from './registration/physio/physio.component';
import { NutritionistComponent } from './registration/nutritionist/nutritionist.component';
import { AssociationRegistrationComponent } from './association-registration/association-registration.component';
import { AssociationListComponent } from './association-list/association-list.component';
import { PlayerListComponent } from './registration/player-list/player-list.component';
import { CoachListComponent } from './registration/coach-list/coach-list.component';
import { TrainerListComponent } from './registration/trainer-list/trainer-list.component';
import { PhysioListComponent } from './registration/physio-list/physio-list.component';
import { DoctorComponent } from './registration/doctor/doctor.component';
import { DoctorListComponent } from './registration/doctor-list/doctor-list.component';
import { NutritionistListComponent } from './registration/nutritionist-list/nutritionist-list.component';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';
import { NgImageSliderModule } from 'ng-image-slider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { MastersComponent } from './masters/masters.component';
import { BatchMasterComponent } from './masters/batch-master/batch-master.component';
import { PayerGroupComponent } from './masters/payer-group/payer-group.component';
import { AthleteMappingComponent } from './masters/athlete-mapping/athlete-mapping.component';
import { SupportStaffMappingComponent } from './masters/support-staff-mapping/support-staff-mapping.component';
import { DashboardAthleteComponent } from './dashboard-athlete/dashboard-athlete.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { MatChipsModule } from '@angular/material/chips';

import '@swimlane/ngx-datatable/assets/icons.css';
import { AgeCategoryComponent } from './masters/age-category/age-category.component';
import { MyVideosComponent } from './my-videos/my-videos.component';
import { BlobModule } from 'angular-azure-blob-service';
import { CoachPlayersessionsComponent } from './coach/coach-playersessions/coach-playersessions.component';

import { ProfileComponent } from './profiles/profile/profile.component';
import { ProfileChangePasswordComponent } from './profiles/profile-change-password/profile-change-password.component';
import { ProfileCoachComponent } from './profiles/profile-coach/profile-coach.component';
import { DashboardCoachComponent } from './dashboard-coach/dashboard-coach.component';

import { PlannerComponent } from './planner/planner/planner.component';
import { PlayGroupsComponent } from './coach/play-groups/play-groups.component';
import { DrillsComponent } from './drills/drills/drills.component';
import { DrillsAthletesComponent } from './drills/drills-athletes/drills-athletes.component';
import { VideoAnalyseComponent } from './video-analyse/video-analyse.component';

import { VideoTaggingListComponent } from './video-tagging/video-tagging-list/video-tagging-list.component';
import { VideoTaggingComponent } from './video-tagging/video-tagging/video-tagging.component';
import { VideoCompareComponent } from './video-compare/video-compare.component';
import { DashboardAcademyComponent } from './dashboard-academy/dashboard-academy.component';

import { MyPlaylistComponent } from './my-playlist/my-playlist.component';
import { MyPlaylistViewComponent } from './my-playlist-view/my-playlist-view.component';
import { HelpComponent } from './help/help/help.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { CoachPadComponent } from './coach/coach-pad/coach-pad.component';
import { CoachpadPlayerComponent } from './coach/coachpad-player/coachpad-player.component';
import { CoachProfileComponent } from './coach/coach-profile/coach-profile.component';

import { CoachingComponent } from './coach/coaching/coaching.component';
import { CoachpadCoachwallComponent } from './coach/coachpad-coachwall/coachpad-coachwall.component';
import { CoachpadPlayerwallComponent } from './coach/coachpad-playerwall/coachpad-playerwall.component';

import { CoachSessionsComponent } from './coach/coach-sessions/coach-sessions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { OrderByPipe } from '../app/order-by.pipe';
import { AcademicPlayerSesssionsComponent } from './player/academic-player-sesssions/academic-player-sesssions.component';
import { FinanceDashboardComponent } from './Finance/finance-dashboard/finance-dashboard.component';
import { FinanceMasterComponent } from './Finance/finance-master/finance-master.component';
import { PackageSelectorComponent } from './Finance/package-selector/package-selector.component';
import { SortPipe } from './pipes/sort.pipe';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

@NgModule({
  declarations: [
    AppComponent,
    AllRoutes,
    DashboardComponent,
    HomeLayoutComponent,
    PlayerComponent,
    CoachComponent,
    TrainerComponent,
    PhysioComponent,
    NutritionistComponent,
    AssociationRegistrationComponent,
    AssociationListComponent, PlayerListComponent,
    CoachListComponent,
    TrainerListComponent,
    PhysioListComponent,
    DoctorComponent,
    DoctorListComponent,
    NutritionistListComponent,
    MastersComponent,
    BatchMasterComponent,
    PayerGroupComponent,
    AthleteMappingComponent,
    SupportStaffMappingComponent,
    AgeCategoryComponent,
    DashboardAthleteComponent,
    MyVideosComponent,
    CoachPlayersessionsComponent,
    ProfileComponent,
    ProfileChangePasswordComponent,
    ProfileCoachComponent,
    DashboardCoachComponent,
    PlannerComponent,
    PlayGroupsComponent,
    DrillsComponent,
    DrillsAthletesComponent,
    VideoAnalyseComponent,
    VideoTaggingListComponent,
    VideoTaggingComponent,
    VideoCompareComponent,
    HelpComponent,
    MyPlaylistComponent,
    MyPlaylistViewComponent,
    DashboardAcademyComponent,
    NumbersOnlyDirective,
    CoachPadComponent,
    CoachpadPlayerComponent,
    CoachProfileComponent,
    CoachingComponent,
    CoachpadCoachwallComponent,
    CoachpadPlayerwallComponent,
    OrderByPipe,
    CoachSessionsComponent,
    AcademicPlayerSesssionsComponent,
    FinanceDashboardComponent,
    FinanceMasterComponent,
    PackageSelectorComponent,
    SortPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonModule,
    MatNativeDateModule,
    MatRippleModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ImageCropperModule,
    // MatSlideToggleModule,
    MatAutocompleteModule,
    // DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    Ng2SearchPipeModule,
    CommonModule,
    NgxDatatableModule,
    ChartsModule,
    NgImageSliderModule,
    FullCalendarModule,
    NgxMaterialTimepickerModule,
    BlobModule.forRoot(),
    ColorPickerModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [UtilitiesService, AuthGuard, LandingComponent, LandingLayoutComponent, DatePipe , { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
