import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class DoctorComponent implements OnInit {
  public durations: string;
  public size: string;
  constructor() { }

  ngOnInit(): void {
  }

  onVideoChange(event) {
    var attachedFile = <File>event.target.files[0];

    let duration: any;

    //here you can check the file type for attachedFile either video or audio

    var video = document.createElement('video');
    video.preload = 'metadata';
    var x;
    var fd = new FormData();
    fd.append('file', attachedFile);

    var sizeInMB = (attachedFile.size / (1024 * 1024)).toFixed(2);
    this.size = sizeInMB + 'MB';
    video.src = URL.createObjectURL(attachedFile); 
    document.getElementById("myVideo").setAttribute("src", URL.createObjectURL(attachedFile));
    let vid = <HTMLVideoElement>document.getElementById("myVideo");
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      duration = video.duration; // here you could get the duration
      x = duration;
    }    
  }
  onMetadata(e, video) {
    console.log('metadata: ', e);
    console.log('duration: ', this.durations = video.duration);
    alert('File Size = '+this.size+' File Duration='+this.durations);
     
  }
}
