import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { AuthService } from '../auth/auth.service';
import { MastersServiceService } from '../masters-service.service';
declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './home-layout.component.html',
  styles: []
})

export class HomeLayoutComponent implements OnInit {

  @Output() routerData = new EventEmitter<any>();

  constructor(private utility: UtilitiesService, private aservice: AuthService, public router: Router,
    private service: MastersServiceService,) { }

  public isNotification: boolean = false;
  public isSetting: boolean = false;
  public isProfile: boolean = false;
  public UserName: string;
  public MemberTypeName: string;
  public MemberTypeID: string;
  public PageHeaderName: string = 'Dashboard';
  public LoginProfileImage:string;
  public DisplayName: string;

  public currentUrl: string;
  public ListPages: PageList[] = [
    { title: "Dashboard", url: "dashboard" },
    { title: "Dashboard", url: "athlete-dashboard" },
    { title: "Dashboard", url: "coach-dashboard" },
    { title: "Dashboard",url:"academy-dashboard"},
    { title: "Dashboard",url:"finance-dashboard"},
    { title: "Association Registration", url: "association-list" },
    { title: "Member Registration", url: "player-list" },
    { title: "Masters", url: "master" },
    { title: "My Videos", url: "my-videos" },
    { title: "Planner", url: "planner" },
    { title: "Play Groups", url: "play-groups" }, 
    { title: "Drills", url: "drills" },
    { title: "Drill Athletes", url: "drills-athletes" },
    { title: "Video Analyse",url:"video-analyse"},  
    { title: "Play Groups",url:"coach-sessions"},  
    { title: "Video Tagging",url:"video-list"},
    { title: "Help",url:"help"},
    { title: "Profile",url:"profile"},
    { title: "Profile",url:"change-password"},
    { title: "Profile",url:"profile-coach"},
    { title: "Video Compare",url:"video-compare"},
    { title: "Coach Pad",url:"coach-pad"},
    { title: "Coaching",url:"coaching"},
    { title: "Package Selector",url:"coaching"}
  ];

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $(document).on('click', ".mainmenu li a", function (this: any) {
          $('.mainmenu li a').removeClass('select');
          $('.mainmenu li').removeClass('active open');
          $('.mainmenu li .submenu').removeClass('nav-show').addClass('nav-hide').hide();
          $(this).parents('li').addClass('active open');
          $('.mainmenu li.active.open > .submenu').addClass('nav-show').removeClass('nav-hide').show();
          $(this).parent().removeClass('open').addClass('active');
          $(this).toggleClass('select');
            // var pageURL = $(this).attr('routerLink');
            // if (pageURL !== "javascript:void(0);")
            // {
            //   $('.apps-left').animate({marginLeft:-240});
            //   $('.toggle-menu.expanded').removeClass('expanded').addClass('collapsed').animate({marginLeft:5});
            // }
        });
        $(document).on('click', ".toggle-menu.collapsed", function (this: any) {
          $('.apps-left').animate({ marginLeft: 0 });
          $(this).removeClass('collapsed').addClass('expanded').animate({ marginLeft: 240 });
        });
        $(document).on('click', ".toggle-menu.expanded, .toggle-dashboard, .btn-reports a", function () {
          $('.apps-left').animate({ marginLeft: -240 });
          $('.toggle-menu.expanded').removeClass('expanded').addClass('collapsed').animate({ marginLeft: 5 });
        });
      })
    })(jQuery)
    this.utility.pageLoadScript();
    this.UserName = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserName);
    this.DisplayName = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).DisplayName);
    this.MemberTypeName = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeName);
    this.MemberTypeID = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID);
    this.LoginProfileImage = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).ProfileImage);

    // (this.LoginProfileImage) = this.service._userProfile;

    // if (this.MemberTypeID == '6') {
    //   this.PageHeaderName = 'Association Registration';
    // } else {
      this.PageHeaderName = 'Dashboard';
    // }

    
    this.currentUrl = this.router.url.replace('/', '');
    var x = this.ListPages.filter(option => option.url === this.currentUrl);
    if (x.length > 0)
      this.PageHeaderName = x[0].title;

  }
  showNotification() {
    this.isNotification = true;
    this.isSetting = false;
    this.isProfile = false;
  }
  showSetting() {
    this.isSetting = true;
    this.isNotification = false;
    this.isProfile = false;
  }

  LogOut() {
    this.aservice.logout();
  }

  ChangePage(route, page) {
    this.PageHeaderName = page;
    this.router.navigate([route]);
  }

  ProfileRouteLink(){
    if(this.MemberTypeID == '1'){
      this.GetRouterData = 1;
      sessionStorage.setItem("Profile", '1'); 
      SessionData = 1;
      this.routerData.emit(this.GetRouterData);
      this.router.navigate(['/profile' ]);
    }
    else if (this.MemberTypeID == '2'){
      this.GetRouterData = 1;
      sessionStorage.setItem("Profile", '1'); 
      SessionData = 1;
      this.routerData.emit(this.GetRouterData);
      this.router.navigate(['/profile-coach' ]);
    }
  }  

  public GetRouterData:any = [];

  PassWordRouteLink(){
    if(this.MemberTypeID == '1'){
      this.GetRouterData = 2;
      sessionStorage.setItem("Profile", '2');   
      SessionData = 2;
      this.routerData.emit(this.GetRouterData);
      this.router.navigate(['/profile' ]);
    }
    else if (this.MemberTypeID == '2'){
      this.GetRouterData = 2;
      sessionStorage.setItem("Profile", '2'); 
      SessionData = 2;
      this.routerData.emit(this.GetRouterData);
      this.router.navigate(['/profile-coach' ]);
    }
    else if  (this.MemberTypeID == '5'){
      this.router.navigate(['/change-password' ]);
    }
  }



  
  
}

 export var SessionData: number;
 export interface PageList { title: string, url: string }
//export var SessionData: any = [];
