import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { SessionData } from '../../layout/home-layout.component';
declare var $: any

@Component({
  selector: 'app-profile-coach',
  templateUrl: './profile-coach.component.html',
  styleUrls: ['./profile-coach.component.css']
})
export class ProfileCoachComponent implements OnInit {

  ChangePasswordForm:FormGroup;

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe) { }

  public UserCode: string = "";
  submitted = false;

  public isShowLoader:boolean;
  public isSuccessAlert:boolean = false; public successAlert:string;
  public isErrorAlert:boolean = false; public errorAlert:string;
  public isWarningAlert:boolean = false; public warningAlert:string;
  public isConfirmAlert:boolean = false; public confirmAlert:string;

  public ImageDetails: ImageDetails[] = [{ data: "", name: "" }];
  public IsUpload:boolean = false;
  public minDate: Date; public maxDate: Date;

  public lstMemberLevel: any[]; public lstMemberSpec: any[]; public lstCoachEdit: any[];
  public ProfileViewList: any[];
  
  public filOfcRoleUpdate: any = []; public croppedImage: string;
  public imageChangedEvent: any = '';
  public imageChangedType: string = '';
  public displayImageCrop: string = 'none';

  @Input()
  public OldPassword: string; public NewPassword: string; public ConfirmPassword: string;
  public UserName: string; public UserAge: string; public UserAssociation: string; public ProfileImage: string;
  public UserFirstName: string; public UserLastName: string; public Level: string; public Specialization: string;
  public Gender: string; public Height: string; public Weight: string; public Goal: string;
  public MobileNumber: string; public EmailID: string; public DOB: string;
  public CoachDescription: string; public CertifyAgency: string;
  public LevelID: string; public SpecID: string;

  public profileForm: boolean = false;
  public profileTbl: boolean = true;
  public IsChangePassword: boolean = false;
  
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.ClearPasswords();
    this.InitProfileLoad();
    // let element: HTMLElement = document.getElementById('Profile') as HTMLElement; element.click();
    // this.showProfileform(1);
  }

  public id:number; // id: any
  showProfileform(id: any) {
    this.profileTbl = true;
    this.profileForm = false;
    this.IsChangePassword = false;
    // this.ngOnInit();
    this.ClearPasswords();
    this.IsUpload = false;
    this.id = id;  
  }

  showProfile(id: any) {
    this.profileTbl = false;
    this.profileForm = true;
    this.IsChangePassword = false;
    this.MasterDataLoad();
    this.IsUpload = true;
    this.id = id;
  }

  showChangePassword(id: any) {
    this.profileTbl = false;
    this.profileForm = false;
    this.IsChangePassword = true;
    this.IsUpload = false;
    this.id = id;
  }


  OldPasswordstatus: boolean = false;
  clickOLD(){
      this.OldPasswordstatus = !this.OldPasswordstatus;       
  }

  NewPasswordstatus: boolean = false;
  clickNew(){
      this.NewPasswordstatus = !this.NewPasswordstatus;       
  }

  ConfirmPasswordstatus: boolean = false;
  clickConfirm(){
      this.ConfirmPasswordstatus = !this.ConfirmPasswordstatus;           
  }

  // -- CoachLoad
  InitProfileLoad() { 
    this.isShowLoader = true;
    //this.id = 1;
    this.id = SessionData;
    if ( this.id  == 1){
      this.profileTbl = true;
      this.profileForm = false;
      this.IsChangePassword = false;
      this.IsUpload = false;
    }
    else {
      this.profileTbl = false;
      this.profileForm = false;
      this.IsChangePassword = true;
      this.IsUpload = false;
    }
    this.ChangePasswordForm = this.formBuilder.group({
      n_OldPassword:['', Validators.required ],
      n_NewPassword:['', Validators.required ],
      n_ConfirmPassword:['', Validators.required],
  });
    var minCurrentDate = new Date();
    var maxNewDate = new Date();
    this.minDate = minCurrentDate;
    this.maxDate = new Date(maxNewDate.getFullYear(), maxNewDate.getMonth(), maxNewDate.getDate());
    var ProfileLoad = { ActionFlag: "View", UserID: this.UserCode };
    this.service.CoachRegistration(ProfileLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.ProfileViewList = ret.RowDetails;
          this.ProfileImage = this.ProfileViewList[0].ProfileImage;
          this.UserName = this.ProfileViewList[0].DisplayName;
          this.UserAge = this.ProfileViewList[0].Age;
          this.UserAssociation = this.ProfileViewList[0].AssociationName;
          this.UserFirstName = this.ProfileViewList[0].FirstName;
          this.UserLastName = this.ProfileViewList[0].LastName;
          this.Level = this.ProfileViewList[0].MemberLevelName;
          this.Specialization = this.ProfileViewList[0].SpecName;
          this.EmailID = this.ProfileViewList[0].Email;
          this.MobileNumber = this.ProfileViewList[0].Mobile;
          this.DOB = this.ProfileViewList[0].DateOfBirth;
          this.Gender = this.ProfileViewList[0].Gender;
          this.CoachDescription = this.ProfileViewList[0].CoachDescription;
          this.CertifyAgency = this.ProfileViewList[0].CertifyAgency;
          // this.ProfileImage = String(sessionStorage.setItem('LoginProfileImage','ProfileImage'));

        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Data Load Failed..!! \n';
        return;
      }
    })

  }

  MasterDataLoad() { 
    this.isShowLoader = true;
    var ProfileLoad = { ActionFlag: "Load", UserID: this.UserCode };
    this.service.CoachLoad(ProfileLoad).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstMemberLevel = ret.MemberLevelList;
        this.lstMemberSpec = ret.MemberSpecList;

        var ProfileLoad = { ActionFlag: "Edit", UserID: this.UserCode };
        this.service.CoachRegistration(ProfileLoad).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
            this.lstCoachEdit = ret.RowDetails;

            if (this.Gender == "Men") { this.Gender = "1" }
            else { this.Gender = "2" }
            this.UserName = this.lstCoachEdit[0].DisplayName; 
            this.LevelID = this.lstCoachEdit[0].LevelID;
            this.SpecID = this.lstCoachEdit[0].SpecID;
            this.CoachDescription = this.lstCoachEdit[0].Description;
            this.CertifyAgency = this.lstCoachEdit[0].CertifyAgency;

            this.isShowLoader = false;
          }
          else {
            this.isShowLoader = false;
            this.isErrorAlert = true;
            this.errorAlert = '\n Data Load Failed..!! \n';
            return;
          }
        })
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Data Load Failed..!! \n';
        return;
      }
    })
  }

  UpdateProfileDetails() {
    this.isShowLoader = true;
    var profileImage = "";
    if (this.ImageDetails[0].data != "") {
      this.service.UploadFile(this.ImageDetails[0]).subscribe((ret: any = []) => {
        if (ret.Status == true) {
          profileImage = ret.name;
          var ProfileLoad = {
                ActionFlag: "Register", UserID: this.UserCode, CoachImage:profileImage,
                DisplayName: this.UserName, LevelID: String(this.LevelID), SpecID: String(this.SpecID),
                Description: String(this.CoachDescription), CertifyAgency: String(this.CertifyAgency),
            }
            this.service.CoachRegistration(ProfileLoad).subscribe((ret: any = []) => {
              let BTStatus = String(ret.Status);
              if (BTStatus == "true") {
                this.isSuccessAlert = true;
                this.successAlert = 'Saved successfully..!!';
                this.isShowLoader = false;
                this.ngOnInit();
              }
              else {
                this.isShowLoader = false;
                this.isErrorAlert = true;
                this.errorAlert = ret.Message;
                return;
              }
            })
        }
      })
    }
    else {
      var ProfileLoad = {
            ActionFlag: "Register", UserID: this.UserCode, CoachImage:"",
            DisplayName: this.UserName, LevelID: String(this.LevelID), SpecID: String(this.SpecID),
            Description: String(this.CoachDescription), CertifyAgency: String(this.CertifyAgency),
        }
        this.service.CoachRegistration(ProfileLoad).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
            this.isSuccessAlert = true;
            this.successAlert = 'Updated successfully..!!';
            this.isShowLoader = false;
            this.ngOnInit();
          }
          else {
            this.isShowLoader = false;
            this.isErrorAlert = true;
            this.errorAlert = ret.Message;
            return;
          }
        })
    }
   

  }

onSubmit() {
  this.submitted = true;
  if (this.ChangePasswordForm.invalid) {
    return;
  }
  else {
    this.ChangePassWord();
  }
}

ChangePassWord(){
  if ( this.OldPassword != this.NewPassword && this.OldPassword != this.ConfirmPassword ){
      if ( this.NewPassword == this.ConfirmPassword  ){
        this.isShowLoader = true;
        var Changepassword = {
          OldPassword: this.OldPassword, Password: this.NewPassword , ConfirmPassword:this.ConfirmPassword, 
          ActionFlag: "Validate", UserID: this.UserCode  };
        this.service.ChangePassword(Changepassword).subscribe((ret: any = []) => {
          let BTStatus = String(ret.Status);
          if (BTStatus == "true") {
            this.isSuccessAlert = true;
            this.successAlert = "Password Changed Successfully ..!!"  
            this.isShowLoader = false;
            this.ngOnInit();
           //  this.showProfileform(1);
          }
          else {
            this.isShowLoader = false;
            this.isErrorAlert = true;
            this.errorAlert = ret.Message;
            return;
          }
        })
      }
      else{
        this.isErrorAlert = true;
        this.errorAlert = "New-password & Confirm-Password Must be same ..!!";
      }
   }
   else{
      this.isErrorAlert = true;
      this.errorAlert = "Old Password not match..!!";
   }
}


ClearPasswords(){
  this.OldPassword = "";  this.NewPassword = "";  this.ConfirmPassword = "";
}


onPlayerProfileChange(event,type) {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.ProfileImage = reader.result as string;
    //     this.ImageDetails = [];
    //     this.ImageDetails.push({ data: this.ProfileImage, name: file.type });  
    //   };
    // }
  const reader = new FileReader();
  this.imageChangedEvent = event;
  this.imageChangedType = type;
  $('#modalcrop').modal('show');
}

imageCropped(event: ImageCroppedEvent) {
  this.croppedImage = event.base64;
}

imageLoaded() {
  // show cropper
}

cropperReady() {
  // cropper ready
}

loadImageFailed() {
  // show message
}

Applycropper() {
  this.ProfileImage = this.croppedImage;
  this.ImageDetails = [];
  this.ImageDetails.push({ data: this.ProfileImage, name: 'image/png' });
  $('#modalcrop').modal('hide');
  // this.ProfileImage = "";
}




}


export interface ImageDetails { data: string, name: string }


// let element: HTMLElement = document.getElementsByClassName('btn')[0] as HTMLElement;
// element.click();
