import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../masters-service.service';

@Component({
  selector: 'app-my-playlist-view',
  templateUrl: './my-playlist-view.component.html',
  styleUrls: ['./my-playlist-view.component.css']
})
export class MyPlaylistViewComponent implements OnInit {
  public UserCode: string = "1";
  public PlayListDetails: any = [];
  public PlaylistID: string;
  constructor(private utility: UtilitiesService, private http: HttpClient, private blob: BlobService,
    private service: MastersServiceService, private formBuilder: FormBuilder, private _host: ElementRef,
    private router: Router) { }

  ngOnInit(): void {
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.utility.pageLoadScript();
    this.PlaylistID = String(this.service.playerlistID);
    this.initpageload();
    this.getCoaches();
  }
  videoURL: string = "";
  endTime: any;
  startTime: any;
  PosterURL: string = "";
  currentVideo: any;
  PlayLists: any = [];
  initpageload() {
    this.service.VideoPlayListCRUD({ ActionFlag: "GetPlaylistDetails", RefUserID: this.UserCode, PlayListID: this.PlaylistID }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.PlayListDetails = ret.PlayListDetails;
        this.PlayLists = ret.PlayLists;
        this.videoURL = this.PlayListDetails[0].CloudURL;
        this.PosterURL = this.PlayListDetails[0].ThumbnailURL;
        this.startTime = this.PlayListDetails[0].StartTime;
        this.endTime = this.PlayListDetails[0].EndTime;
        this.currentPlaylistName = this.PlayListDetails[0].PlayListName;
        this.currentVideo = 0;
      }
    })
  }
  CoachList: any[];
  SelectedCoachList: any = [];
  Coaches: any;
  getCoaches() {
    this.service.VideoPlayListView({ ActionFlag: "LoadCoaches", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      this.CoachList = ret.CoachDetails;
      // this.SelectedCoachList = this.CoachList.filter(s => s.IsChecked === 1);
    })

  }
  SharetoCoaches() {
    this.service.VideoPlayListView({ ActionFlag: "SharetoCoach", RefUserID: this.UserCode, PlayListID: this.PlaylistID, CoachID: this.Coaches.toString() }).subscribe((ret: any = []) => {
      this.CoachList = ret.CoachDetails;
      // this.SelectedCoachList = this.CoachList.filter(s => s.IsChecked === 1);
    })

  }
  VideoClick(event, videoPlayer) {
    if (this.currentVideo < this.PlayListDetails.length) {
      videoPlayer.currentTime = this.startTime;
      this.videoduration = this.endTime - this.startTime;
      if (this.videoduration > 0)
        this.VideodurationText = Math.floor(this.videoduration / 60) % 60 + ':' + this.pad((Math.floor(this.videoduration % 60)), 2) + ' Mins';

    }
  }
  VideoTimeText: string = "";
  setCurrentTime(videoPlayer) {

    if (videoPlayer.currentTime >= Number(this.endTime)) {

      if (this.currentVideo < this.PlayListDetails.length) {
        this.currentVideo++;
        this.videoURL = this.PlayListDetails[this.currentVideo].CloudURL;
        this.PosterURL = this.PlayListDetails[this.currentVideo].ThumbnailURL;
        this.startTime = this.PlayListDetails[this.currentVideo].StartTime;
        this.endTime = this.PlayListDetails[this.currentVideo].EndTime;
        videoPlayer.currentTime = this.startTime;
        this.videoduration = this.endTime - this.startTime;
        if (this.videoduration > 0)
          this.VideodurationText = Math.floor(this.videoduration / 60) % 60 + ':' + this.pad((Math.floor(this.videoduration % 60)), 2) + ' Mins';

        videoPlayer.pause();
        this.videoStatus = "pause";
      } else {
        videoPlayer.pause();
        this.videoStatus = "pause";
      }
    }
    else {
      var percentage = ((videoPlayer.currentTime - this.startTime) / this.videoduration) * 100;
      $("#custom-seekbar").css("width", percentage + "%");
      if ((videoPlayer.currentTime - this.startTime) > 0)
        this.VideoTimeText = Math.floor((videoPlayer.currentTime - this.startTime) / 60) % 60 + ':' + this.pad((Math.floor((videoPlayer.currentTime - this.startTime) % 60)), 2) + ' Mins';

    }
  }

  SearchFilter: string = "";
  VideodurationText: string = "";
  playVideo(videoPlayer, play, ind) {
    this.currentVideo = ind;
    videoPlayer.pause();
    this.videoURL = this.PlayListDetails[this.currentVideo].CloudURL;
    this.PosterURL = this.PlayListDetails[this.currentVideo].ThumbnailURL;
    this.startTime = this.PlayListDetails[this.currentVideo].StartTime;
    this.endTime = this.PlayListDetails[this.currentVideo].EndTime;
    //var s = Number(this.startTime) - videoPlayer.currentTime;
    videoPlayer.currentFrameTime  =Number(this.startTime);
    videoPlayer.currentTime=0;
    videoPlayer.currentTime+=videoPlayer.currentFrameTime;
    this.currentTime = this.startTime;
    this.videoduration = this.endTime - this.startTime;
    if (this.videoduration > 0)
      this.VideodurationText = Math.floor(this.videoduration / 60) % 60 + ':' + this.pad((Math.floor(this.videoduration % 60)), 2) + ' Mins';
    videoPlayer.play();
    this.videoStatus = "play";
  }
  pad(n, width) {
    var z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  currentPlaylistName: string = "";
  currentTime: number = 0;
  changePlaylist(videoPlayer, play, ind) {
    this.PlaylistID = String(play.PlayListID);
    this.currentPlaylistName = play.PlayListName;
    this.videoStatus = "pause";
    videoPlayer.pause();
    this.service.VideoPlayListCRUD({ ActionFlag: "GetPlaylistDetails", RefUserID: this.UserCode, PlayListID: this.PlaylistID }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.PlayListDetails = ret.PlayListDetails;
        this.videoURL = this.PlayListDetails[0].CloudURL;
        this.PosterURL = this.PlayListDetails[0].ThumbnailURL;
        this.startTime = this.PlayListDetails[0].StartTime;
        this.endTime = this.PlayListDetails[0].EndTime;
        this.currentVideo = 0;
        this.videoduration = this.endTime - this.startTime;
        this.VideodurationText = Math.floor(this.videoduration / 60) % 60 + ':' + this.pad((Math.floor(this.videoduration % 60)), 2) + ' Mins';

        this.currentTime = Number(this.startTime);
        videoPlayer.currentFrameTime=this.currentTime;
      }
    })
  }
  onVideoLoaded(event, videoPlayer) {
    videoPlayer.currentTime=0;
    videoPlayer.currentTime+=videoPlayer.currentFrameTime; 
  }
  seekbarmove: boolean = false;
  videoduration: any;
  seekbarclick(event, videoplayer) {
    if (event.type == 'mousedown') {
      this.seekbarmove = true;
    } else if (event.type == 'mouseup') {
      this.seekbarmove = false;
    }
    else if ((event.type == 'mousemove' && this.seekbarmove === true) || event.type == 'click') {
      var offset = event.offsetX;
      var percentage = (offset / event.currentTarget.clientWidth) * 100;
      $("#custom-seekbar").css("width", percentage + "%");
      var vidTime = ((this.videoduration * percentage) / 100) + Number(this.startTime);
      videoplayer.currentTime = vidTime;
    }
  }
  videoStatus: string = "play";
  changeVideoStatus(videoPlayer) {
    if (this.videoStatus == 'play') {
      this.videoStatus = 'pause';
      videoPlayer.pause();
    }
    else {
      this.videoStatus = 'play';
      videoPlayer.play();
    }
  }
}
