<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container admin-dashboard-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">    

                            <div class="col-md-4">
                                <div class="card-profile-container box-shadow br-5">
                                    <div class="card-profile-header tr-5">
                                        <div class="profile-picture">
                                            <input #fileUpload type="file" (click)="fileUpload.value = null" style="display:none" accept="image/*"
                                                (change)="onPlayerProfileChange($event,'profile')" >
                                            <img src="{{ProfileImage}}" alt="Profile Picture" onerror="this.src='assets/images/no_user.png';">
                                            <a *ngIf="IsUpload" href="javascript:void(0);" class="ico-profile-upload" (click)="fileUpload.click()"></a>
                                        </div>
                                    </div>
                                    <div class="card-profile-body">
                                        <div class="txt-center">
                                            <div class="profile-name" style="text-transform:capitalize;">{{UserName}}</div>
                                            <div class="profile-age"  style="text-transform:capitalize;">{{UserAge}}</div>
                                            <div class="profile-association" style="text-transform:capitalize;">
                                                {{UserAssociation}}</div>
                                        </div>
                                        <div class="proficiency-tbl">
                                            <table>
                                                <tr>
                                                    <td colspan="2" style="width:100%;" class="txt-center">
                                                        <div class="ico-proficiency"><img
                                                                src="assets/images/{{ImgPlayerRole}}.png" alt="All Rounder"
                                                                onerror="this.src='assets/images/no-image40.png';" >
                                                        </div>
                                                        <div class="profile-proficiency">{{PlayerRole}}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="txt-center" style="width: 50%;">
                                                        <div class="ico-proficiency"><img
                                                                src="assets/images/{{ImgBattingStyle}}.png" alt="Batsman"
                                                                onerror="this.src='assets/images/no-image40.png';" >
                                                        </div>
                                                        <div class="profile-proficiency">{{BattingStyle}}</div>
                                                    </td>
                                                    <td class="txt-center" style="width: 50%;">
                                                        <div class="ico-proficiency"><img
                                                                src="assets/images/{{ImgBowlingSpc}}.png" alt="Bowler"
                                                                onerror="this.src='assets/images/no-image40.png';" >
                                                        </div>
                                                        <div class="profile-proficiency">{{BowlingSpec}}</div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="profile-data">
                                            <ul>
                                                <li>
                                                    <div class="data-count">{{TotalVideos}}</div>
                                                    <div class="data-name">Videos</div>
                                                </li>
                                                <li>
                                                    <div class="data-count">{{TotalUploads}}</div>
                                                    <div class="data-name">Uploads</div>
                                                </li>
                                                <li>
                                                    <div class="data-count">{{TotalReviewed}}</div> 
                                                    <div class="data-name">Reviews</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <div class="card-profile-progress">
                                            <div class="monthly-updates">Monthly Uploads</div>
                                            <div class="prg-container">
                                                <div class="prg-rating">0/8</div>
                                                <div class="prg-ratio">
                                                    <mat-progress-bar mode="determinate" value="0"></mat-progress-bar>
                                                </div>
                                                <div class="prg-percentage">0%</div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-8">
                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="main-tab">
                                        <!-- <a href="javascript:void(0);" class="select" (click)="showProfileform()">Profile</a>
                                        <a href="javascript:void(0);" (click)="showChangePassword()">Change Password</a> -->
                                        <a href="javascript:void(0);"  class="select"  [ngClass]="{'select': id === 1 }" id="1"
                                           (click)="showProfileform(1)">Profile</a>
                                        <a href="javascript:void(0);" [ngClass]="{'select': id === 2 }" id="2"
                                           (click)="showChangePassword(2)">Change Password</a>
                                    </div>
                                    <div class="athlete-db">  
                                        <div class="main-tab-container">

                                            <div class="profile-form" *ngIf="profileForm">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <mat-label>First Name</mat-label>
                                                            <input matInput required [(ngModel)]="UserFirstName"
                                                                readonly style="text-transform:capitalize;">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <mat-label>Last Name</mat-label>
                                                            <input matInput required [(ngModel)]="UserLastName" readonly
                                                                style="text-transform:capitalize;">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <mat-label>Display Name</mat-label>
                                                            <input matInput required [(ngModel)]="UserName"
                                                                style="text-transform:capitalize;">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <mat-label>Mobile</mat-label>
                                                            <input matInput required [(ngModel)]="MobileNumber"
                                                                readonly>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <mat-label>Email</mat-label>
                                                            <input matInput required [(ngModel)]="EmailID" readonly>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field appearance="fill"
                                                            class="custom-dtpkr-txt md-block" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-label>Date of Birth(DOB)</mat-label>
                                                            <input matInput [matDatepicker]="dob" [(ngModel)]="DOB"
                                                                readonly [max]="maxDate">
                                                            <mat-datepicker-toggle matSuffix [for]="dob" readonly
                                                                disabled></mat-datepicker-toggle>
                                                            <mat-datepicker #dob readonly disabled></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="md-block">
                                                            <div class="radio-grp">Gender</div>
                                                        </div>
                                                        <mat-radio-group aria-label="Select an option" [(ngModel)]="Gender" readonly>
                                                            <mat-radio-button value="1" disabled>Male</mat-radio-button>
                                                            <mat-radio-button value="2" disabled>Female</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="528" y="1920" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z">
                                                                    </path>
                                                                    <path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z">
                                                                    </path>
                                                                </svg></mat-icon>
                                                            <mat-label>Level</mat-label>
                                                            <mat-select placeholder="Level" required [(ngModel)]="Level">
                                                                <mat-option [value]="level.MemberLevelID"
                                                                    *ngFor="let level of lstMemberLevel"
                                                                    style="text-transform:capitalize;">
                                                                    {{level.MemberLevelName}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="md-block">
                                                            <div class="radio-grp">Player Role*</div>
                                                        </div>
                                                        <mat-radio-group aria-label="Select an option"
                                                            class="radio-container" [(ngModel)]="PlayerRoleID">
                                                            <mat-radio-button *ngFor="let playerrole of lstPlayerRole"
                                                                [value]="playerrole.PlayerRoleID" class="rbt-click">
                                                                {{playerrole.DisplayName}}</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="md-block">
                                                            <div class="radio-grp" style="margin-bottom: 3px;">Batting Style*</div>
                                                        </div>
                                                        <mat-radio-group aria-label="Select an option"
                                                            class="radio-container" [(ngModel)]="BattingStyleID">
                                                            <mat-radio-button *ngFor="let battype of lstBattingType"
                                                                [value]="battype.BattingTypeID" class="rbt-click">
                                                                {{battype.DisplayName}}</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="md-block">
                                                            <div class="radio-grp" style="margin-bottom: 3px;">Batting Order*</div>
                                                        </div>
                                                        <mat-radio-group aria-label="Select an option"
                                                            class="radio-container" [(ngModel)]="BattingOrder">
                                                            <mat-radio-button *ngFor="let batorder of lstBattingOrder"
                                                                [value]="batorder.BatOrderID" class="rbt-click">
                                                                {{batorder.DisplayName}}</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="md-block">
                                                            <div class="radio-grp">Bowling Arm</div>
                                                        </div>
                                                        <mat-radio-group aria-label="Select an option"
                                                            class="radio-container" [(ngModel)]="BowlingArm"
                                                            (change)="BowlingStyleChange(BowlingTypeID)"  >
                                                            <mat-radio-button *ngFor="let bowlStyle of lstBowlingStyle"
                                                                [value]="bowlStyle.BowlingStyleID" class="rbt-click">
                                                                {{bowlStyle.DisplayName}}</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="col-md-6">  
                                                        <div class="md-block">
                                                            <div class="radio-grp">Bowling Type</div>
                                                        </div>
                                                        <mat-radio-group aria-label="Select an option"
                                                            class="radio-container" [(ngModel)]="BowlingTypeID"
                                                            (change)="BowlingStyleChange(BowlingTypeID)" >
                                                            <mat-radio-button *ngFor="let bowlType of lstBowlingType"
                                                                [value]="bowlType.BowlingTypeID" class="rbt-click">
                                                                {{bowlType.DisplayName}}</mat-radio-button>
                                                        </mat-radio-group>   
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="md-block">
                                                            <div class="radio-grp">Bowling Specification</div>
                                                        </div>
                                                        <mat-radio-group aria-label="Select an option"
                                                            class="radio-container" [(ngModel)]="BowlingSpecID">
                                                            <mat-radio-button *ngFor="let bowlSpec of BowlingSpList"
                                                                [value]="bowlSpec.BowlingSPTypeID" class="rbt-click">
                                                                {{bowlSpec.DisplayName}}</mat-radio-button>
                                                        </mat-radio-group> 
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="md-block">
                                                            <div class="radio-grp">Is Wicket Keeper</div>
                                                        </div>
                                                        <div class="">
                                                            No <mat-slide-toggle [(ngModel)]="IsWicketKeeper">Yes
                                                            </mat-slide-toggle>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg></mat-icon>
                                                            <mat-label>Height</mat-label>
                                                            <input matInput required [(ngModel)]="Height" maxlength="3"
                                                                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg></mat-icon>
                                                            <mat-label>Weight</mat-label>
                                                            <input matInput required [(ngModel)]="Weight" maxlength="3"
                                                                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <mat-form-field class="md-block mb20" appearance="outline"
                                                            floatLabel="always">
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg></mat-icon>
                                                            <mat-label>Goal</mat-label>
                                                            <input matInput required [(ngModel)]="Goal"
                                                                style="text-transform:capitalize;">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="txt-center mb20">
                                                    <button mat-raised-button color="primary"
                                                        (click)="ProfileUpdate()">Save</button>
                                                    <button mat-raised-button color="primary" class="ml10"
                                                    (click)="showProfileform(id=1)" >Cancel</button>
                                                </div>
                                            </div>

                                            <div class="tbl-profile-list" *ngIf="profileTbl">
                                                <a href="javascript:void(0);" class="tbl-edit"
                                                    (click)="showProfile(id=1)"></a>
                                                <table>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Name</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{UserName}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Age</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{UserAge}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Association</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{UserAssociation}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>First Name</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{UserFirstName}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Last Name</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{UserLastName}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Level</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{Level}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Role</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{PlayerRole}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Batting Style</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{BattingStyle}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Batting Order</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{BattingOrder}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Bowling Arm</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{BowlingArm}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Bowling Specification</strong>
                                                        </td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{BowlingSpec}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Wicket Keeper</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{IsWicketKeeper}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Gender</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{Gender}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Height</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{Height}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Weight</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{Weight}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 25%;"><strong>Goal</strong></td>
                                                        <td style="width: 5%;">:</td>
                                                        <td style="width: 70%;" style="text-transform:capitalize;">
                                                            {{Goal}}</td>
                                                    </tr>
                                                </table>
                                            </div>

                                            <div class="profile-form" *ngIf="IsChangePassword">
                                                <form [formGroup]="ChangePasswordForm" #cp="ngForm"
                                                    (ngSubmit)="onSubmit()">
                                                    <div class="athlete-db">
                                                        <div class="main-tab-container schedules">
                                                            <h5 class="txt-center">Update your password here..</h5>
                                                            <div class="row">
                                                                <div class="col-md-3"></div>
                                                                <div class="col-md-6">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <mat-form-field class="md-block "
                                                                                appearance="outline"
                                                                                floatLabel="always">
                                                                                <mat-label>Old Password</mat-label>
                                                                                <input
                                                                                    [type]="OldPasswordstatus ? 'text' : 'password'"
                                                                                    matInput required maxlength="10" 
                                                                                    [(ngModel)]="OldPassword"
                                                                                    formControlName="n_OldPassword"
                                                                                    aria-autocomplete="list">
                                                                                <div class="pwd-toggle"
                                                                                    (click)="clickOLD()"
                                                                                    [ngClass]="OldPasswordstatus ? 'show' : 'hide'">
                                                                                </div>
                                                                            </mat-form-field>
                                                                            <div *ngIf="cp.submitted && ChangePasswordForm.controls.n_OldPassword.invalid  "
                                                                                class="alert alert-danger "> 
                                                                                <div *ngIf="ChangePasswordForm.controls.n_OldPassword.errors.required">
                                                                                    Old Password is required.</div>
                                                                                    <!-- <div *ngIf="this.ChangePasswordForm.controls.n_OldPassword.errors.minlength">
                                                                                        Must be at least 8 characters long. </div> -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <mat-form-field class="md-block "
                                                                                appearance="outline"
                                                                                floatLabel="always">
                                                                                <mat-label>New Password</mat-label>
                                                                                <input
                                                                                    [type]="NewPasswordstatus ? 'text' : 'password'"
                                                                                    matInput required maxlength="10" 
                                                                                    [(ngModel)]="NewPassword"
                                                                                    formControlName="n_NewPassword"
                                                                                    aria-autocomplete="list">
                                                                                <div class="pwd-toggle"
                                                                                    (click)="clickNew()"
                                                                                    [ngClass]="NewPasswordstatus ? 'show' : 'hide'">
                                                                                </div>
                                                                            </mat-form-field>
                                                                            <div *ngIf="cp.submitted && ChangePasswordForm.controls.n_NewPassword.invalid "
                                                                                class="alert alert-danger">
                                                                                <div *ngIf="ChangePasswordForm.controls.n_NewPassword.errors.required">
                                                                                    New Password is required.</div>
                                                                                <!-- <div *ngIf="this.ChangePasswordForm.controls.n_NewPassword.errors.minlength">
                                                                                        Must be at least 8 characters long. </div>     -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <mat-form-field class="md-block "
                                                                                appearance="outline"
                                                                                floatLabel="always">
                                                                                <mat-label>Confirm Password</mat-label>
                                                                                <input
                                                                                    [type]="ConfirmPasswordstatus ? 'text' : 'password'"
                                                                                    matInput required maxlength="10" 
                                                                                    [(ngModel)]="ConfirmPassword"
                                                                                    formControlName="n_ConfirmPassword"
                                                                                    aria-autocomplete="list">
                                                                                <div class="pwd-toggle"
                                                                                    (click)="clickConfirm()"
                                                                                    [ngClass]="ConfirmPasswordstatus ? 'show' : 'hide'">
                                                                                </div>
                                                                            </mat-form-field>
                                                                            <div *ngIf="cp.submitted && ChangePasswordForm.controls.n_ConfirmPassword.invalid "
                                                                                    class="alert alert-danger">
                                                                                    <div *ngIf="ChangePasswordForm.controls.n_ConfirmPassword.errors.required">
                                                                                        Confirm Password is required.</div>
                                                                                <!-- <div *ngIf="this.ChangePasswordForm.controls.n_ConfirmPassword.errors.minlength">
                                                                                            Must be at least 8 characters long.  </div>         -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3"></div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="txt-center mb20">
                                                                        <button mat-raised-button
                                                                            color="primary">Save</button>
                                                                        <button mat-raised-button color="primary"
                                                                            class="ml10"
                                                                            (click)="showProfileform(1)">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="overlay success" *ngIf="isSuccessAlert">
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div class="alcenter">
                <h4>Profile</h4>
                <p>{{successAlert}}</p>
            </div> 
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isSuccessAlert=false;showProfileform(id=1);">Ok</button>
        </div>
    </div>
</div>
<div class="overlay error" *ngIf="isErrorAlert">
    <div class="error-widget">
        <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="error-container">
            <div class="alcenter">
                <h4>Profile</h4>
                <p>{{errorAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isErrorAlert=false">Ok</button>
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isWarningAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Profile</h4>
                <p>{{warningAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isWarningAlert=false">Ok</button>
            <!-- <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
                (click)="isWarningAlert=false">Cancel</button> -->
        </div>
    </div>
</div>
<div class="overlay warning" *ngIf="isConfirmAlert">
    <div class="warning-widget">
        <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
        <div class="warning-container">
            <div class="alcenter">
                <h4>Profile</h4>
                <p>{{confirmAlert}}</p>
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"
                (click)="isConfirmAlert=false">Ok</button>
            <!-- <button mat-raised-button color="warn" class="btncancel hvr-bounce-to-top"
                (click)="isConfirmAlert=false">Cancel</button> -->
        </div>
    </div>
</div>



<div class="modal fade" id="modalcrop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" >
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Resize Image</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <!-- <span  data-toggle="modal" data-target="#modalcrop">click</span>   -->
                    <div class="col-md-8" style="float:left">
                        <image-cropper [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                            [cropperMinWidth]="200" [hideResizeSquares]="false" format="png"
                            (imageCropped)="imageCropped($event)"
                            (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()" style="float:left;">
                        </image-cropper>
                    </div>
                    <!-- <button type="button" (click)=zoonIn()>+</button> <button type="button" (click)=zoonOut()>-</button> -->
                    <div class="col-md-4" style="float:left">
                        <img draggable="false" src="{{croppedImage}}"
                            class="upload-preview" style="width: 100%"/> 
                    </div>

                </div>
            </div>
            <div class="footer txt-center">
                <button type="button" class="btn btn-primary" (click)="Applycropper()">Apply</button>
            </div>
        </div>
    </div>
</div>