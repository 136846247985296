<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container admin-dashboard-page drill-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="dashboard-container">
                    <div class="athlete-dashboard-container">
                        <div class="row">

                            <div class="col-md-3">
                                <div class="upload-videos-container box-shadow br-5 search-container">
                                    <div class="title" [hidden]="videoLoaded"  >Upload Drill Videos</div>
                                    <div class="upload-container"  [hidden]="videoLoaded" >
                                        <div class="video-upload mb10">
                                            <div class="dropzone">    
                                                <div class="text-wrapper">
                                                    <div class="centered">
                                                        <input type="file" name="file" id="file" multiple
                                                        (change)="onVideoChange($event)" >
                                                        <label for="file"><span class="textLink">Select your file</span>
                                                            </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>

                                    <div class="upload-common" [hidden]="!videoLoaded" >  <!--[hidden]="!videoLoaded" -->
                                    <div class="title">Upload Drill Videos <span class="remove-list" (click) = "IsUploadShow()" ></span></div>
                                    <div class="upload-container upload-type mb10">

                                        <div class="left-video-container">
                                            <video id="myVideo" class="video-js" preload="none" width="640" height="100%"
                                                controls disablePictureInPicture controlsList="nodownload" 
                                                #video (loadedmetadata)="onMetadata($event, video)">
                                            </video>
                                            <canvas id="canvas"  width="1280" height="720" style="display: none;"></canvas> <br /><br />
                                            <div class="video-size txt-right">{{Videoduration}}
                                                <span>{{selectedfilesize}}</span>
                                            </div>
                                            <div class="card-profile-progress">
                                                <div class="prg-container">
                                                    <div class="prg-ratio session">
                                                        <mat-progress-bar mode="determinate" [value]="percent"></mat-progress-bar>
                                                    </div>
                                                    <div class="prg-percentage"> {{ percent }}%</div>
                                                </div>
                                            </div> 
                                        </div>

                                        <div class="upload-form">
                                            <form [formGroup]="DrillForm" #dril="ngForm" (ngSubmit)="uploadbutton()" >
                                                <div class="row">

                                                    <div class="col">
                                                        <span class="steps">Drill Name </span>
                                                        <mat-form-field class="md-block search-txt" appearance="outline"
                                                            floatLabel="always">
                                                            <!-- <mat-label>Drill Name</mat-label> -->
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <input matInput placeholder="Drill Name"  name="DrillName" 
                                                             formControlName="n_DrillName" [(ngModel)]="DrillName" maxlength="200" >
                                                        </mat-form-field>
                                                        <div *ngIf="dril.submitted && DrillForm.controls.n_DrillName.invalid"
                                                            class="alert alert-danger">
                                                            <div *ngIf="DrillForm.controls.n_DrillName.errors.required">
                                                                Drill Name is required.</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <span class="steps">Skills </span>
                                                        <mat-form-field class="md-block mat-dd-field search-txt"
                                                            appearance="outline" floatLabel="always">
                                                            <!-- <mat-label>Skills</mat-label> -->
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <mat-select placeholder="Skill"  name="Skills"
                                                                formControlName="n_Skills" [(ngModel)]="Skills" >
                                                                <mat-option *ngFor="let types of lstDrillSessionTypes" [value]="types.SessionTypeID">
                                                                    {{types.SessionTypeName}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="dril.submitted && DrillForm.controls.n_Skills.invalid"
                                                            class="alert alert-danger">
                                                            <div *ngIf="DrillForm.controls.n_Skills.errors.required">
                                                                Skills is required.</div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <span class="steps">Level </span>
                                                        <mat-form-field class="md-block mat-dd-field search-txt"
                                                            appearance="outline" floatLabel="always">
                                                            <!-- <mat-label>Skills</mat-label> -->
                                                            <mat-icon matSuffix class="mat-icon"><svg
                                                                    viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                    height="100%" width="100%"
                                                                    preserveAspectRatio="xMidYMid meet"
                                                                    focusable="false">
                                                                    <path opacity=".3"
                                                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                    </path>
                                                                    <path
                                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <mat-select placeholder="Levels"  name="Level"
                                                                formControlName="n_Level" [(ngModel)]="Levels" >
                                                                <mat-option *ngFor="let level of lstDrillLevel" [value]="level.DrillLevelID">
                                                                    {{level.DrillLevelName}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="dril.submitted && DrillForm.controls.n_Level.invalid"
                                                            class="alert alert-danger">
                                                            <div *ngIf="DrillForm.controls.n_Level.errors.required">
                                                                Level is required.</div>
                                                        </div>

                                                        <!-- <div class="select-view mb15">  
                                                            <div class="single-select">   
                                                                <ul>
                                                                    <li><a href="javascript:void(0);" id="1" 
                                                                            [ngClass]="classFlag === true ? 'active' : '' "
                                                                            (click)="menuToggle($event,'1','type')" >Beginer</a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" id="2"
                                                                            [ngClass]="classFlag === true ? 'active' : '' "
                                                                            (click)="menuToggle($event,'2','type')" >Intermediate</a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" id="3"
                                                                            [ngClass]="classFlag === true ? 'active' : '' "
                                                                            (click)="menuToggle($event,'3','type')" >Advanced</a>
                                                                    </li>   

                                                                </ul>
                                                            </div> 
                                                        </div> -->
                                                    </div>   

                                                    <div class="col">
                                                        <!--  <span class="steps">Materials </span>  -->  
                                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always" >
                                                            <mat-label>Keywords</mat-label>
                                                            <mat-chip-list #keyList aria-label="Material selection">
                                                              <mat-chip *ngFor="let key of keywords" [selectable]="keyselectable"
                                                                       [removable]="keyremovable" (removed)="removeKeywords(key)">
                                                                       {{key.name}}
                                                                  <mat-icon matChipRemove *ngIf="keyremovable"></mat-icon>
                                                              </mat-chip>
                                                              <input placeholder="Keywords..."
                                                                     [matChipInputFor]="keyList"
                                                                     [matChipInputSeparatorKeyCodes]="separatorKeys"
                                                                     [matChipInputAddOnBlur]="keyaddOnBlur"
                                                                     (matChipInputTokenEnd)="addKeywords($event)" 
                                                                     formControlName="n_Keywords" >
                                                            </mat-chip-list>
                                                          </mat-form-field>
                                                          <div *ngIf="dril.submitted && DrillForm.controls.n_Keywords.invalid"
                                                            class="alert alert-danger">
                                                            <div *ngIf="DrillForm.controls.n_Keywords.errors.required">
                                                                Keywords is required.</div>
                                                          </div> 
                                                    </div>

                                                    <!-- <div class="col">
                                                        <span class="steps">Steps :</span>
                                                        <div class="remove-step" *ngFor="let stepper of SteperArr; let i = index;" >
                                                            <a *ngIf="i != 0" href="javascript:void(0);" class="clear-list" 
                                                                (click)="removeSteps(i)" > <img src="assets/images/remove-list.png"> </a>
                                                            <mat-form-field class="md-block search-txt" appearance="outline"    floatLabel="always">
                                                                <input  #stepVar   matInput name="stepValue_{{i}}" id="StepValues{{i}}" formControlName="n_Steps"  
                                                                (blur)="onBlurEvent('StepValues'+i,i)"/>
                                                                <span *ngIf="i==stepcount" matSuffix class="addmore" (click)="addSteps('StepValues'+i)" >+</span>
                                                            </mat-form-field>      
                                                        </div>   
                                                    </div>    -->
                                                    
                                                    <div class="col-md-12">
                                                        <span class="steps">Steps :</span>
                                                        <div  class="step-container" >
                                                            <div *ngFor="let stp of listStepperTempList; let id=index " class="step-item">
                                                                {{stp.stepData}}
                                                                <a href="javascript:void(0);" class="clear-list" (click) = "RemoveStepperData(id)"> 
                                                                    <img src="assets/images/remove-list.png"> </a>
                                                            </div>
                                                        </div>
                                                        <div class="">
                                                            <mat-form-field class="md-block search-txt" appearance="outline"floatLabel="always">
                                                                <input matInput  [(ngModel)]="StepsValue"  formControlName="n_StepsValue" >
                                                                <span matSuffix class="addmore" (click) = "AddingStepperData()" >+</span>
                                                            </mat-form-field>
                                                        </div>
                                                    </div> 

                                                    <!-- formControlName="n_Steps{{i}}" -->
                                                    <div class="col">
                                                        <!--  <span class="steps">Materials </span>  -->  
                                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always" >
                                                            <mat-label>Materials</mat-label>
                                                            <mat-chip-list #chipList aria-label="Material selection">
                                                              <mat-chip *ngFor="let mat of materials" [selectable]="selectable"
                                                                       [removable]="removable" (removed)="remove(mat)">
                                                                       {{mat.name}}
                                                                  <mat-icon matChipRemove *ngIf="removable"></mat-icon>
                                                              </mat-chip>
                                                              <input placeholder="Material Required..."
                                                                     [matChipInputFor]="chipList"
                                                                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                     [matChipInputAddOnBlur]="addOnBlur"
                                                                     (matChipInputTokenEnd)="add($event)" 
                                                                     formControlName="n_Materials" >
                                                            </mat-chip-list>
                                                          </mat-form-field>
                                                          <div *ngIf="dril.submitted && DrillForm.controls.n_Materials.invalid"
                                                            class="alert alert-danger">
                                                            <div *ngIf="DrillForm.controls.n_Materials.errors.required">
                                                                Materials is required.</div>
                                                            </div>
                                                    </div>
                                                    
                                                    <div class="col-md-12">
                                                        <div class="switcher">
                                                            <span class="switcher-label">Is Public Access</span>
                                                            <div class=""  >
                                                                No <mat-slide-toggle [(ngModel)]="IsPublic" 
                                                                 formControlName="n_IsPublic" name="n_IsPublic" >Yes</mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="dril.submitted && DrillForm.controls.n_IsPublic.invalid"
                                                                            class="alert alert-danger">
                                                            <div *ngIf="DrillForm.controls.n_IsPublic.errors.required">
                                                                Is Public is required.</div>
                                                        </div>
                                                    </div>

                                                    <div class="col"></div>     
                                                    <!-- <form [formGroup]="myForm">
                                                        <ng-container *ngFor="let group of myForm.controls |keyvalue">
                                                        <div [formGroup]="group.value">        
                                                            <button type="button"  class="addmore" (click)="onAddProduct(group.value)" >+</button>
                                                            <div formArrayName="productList">
                                                                <div *ngFor="let item of productArray(group.value).controls; let i = index">
                                                                       <span class="steps">Steps :</span>
                                                                      <input [formControlName]="i">
                                                                      <button (click)="removeProduct(group.value,i)">remove</button>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                            <pre>
                                                                {{myForm?.value|json}}
                                                            </pre>
                                                        </ng-container>
                                                    </form> -->
                                                </div>
 
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="txt-center">
                                                            <button mat-raised-button color="primary" class="mb5"  type="submit" value="Upload">{{ButtonType}}</button>
                                                            <button mat-raised-button color="primary" class="mb5 ml10" (click)="ngOnInit()" >Clear</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div> 
                                </div>
                            </div>

                            <div class="col-md-9">
                                <div class="profile-with-tab br-5 box-shadow">
                                    <div class="main-tab" rel="drills-db">
                                            <a href="javascript:void(0);" rel="batting" class="select" (click)="DrillBatClick()" >Batting</a>
                                            <a href="javascript:void(0);" rel="bowling" (click)="DrillBawlClick()" >Bowling</a>
                                            <a href="javascript:void(0);" rel="fielding" (click)="DrillFieldClick()" >Fielding</a>
                                            <a href="javascript:void(0);" rel="wicket-keeping" (click)="DrillWicketClick()" >Wicket Keeping</a>
                                            <a href="javascript:void(0);" rel="fitness" (click)="DrillFitnessClick()">Fitness</a>
                                            <a href="javascript:void(0);" rel="others"  (click)="DrillOthersClick()" >Others</a>
                                            <a href="javascript:void(0);" rel="mydrills" (click)="ShowMyDrills()" >My Drills</a>
                                    </div>
                                    <div class="drills-db">

                                        <div class="search-container">
                                            <div class="row"> 
                                                <div class="col-md-4">
                                                    <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline" floatLabel="always">
                                                        <mat-icon matSuffix class="mat-icon"><svg
                                                                viewBox="0 0 24 24" x="768" y="0" fit=""
                                                                height="100%" width="100%"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                focusable="false">
                                                                <path opacity=".3"
                                                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                                                </path>
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                                                </path>
                                                            </svg>
                                                        </mat-icon>
                                                        <mat-select placeholder="Level" [(ngModel)]="SearchLevel" name="n_SearchLevel"  >
                                                            <mat-option >
                                                               All
                                                            </mat-option>
                                                            <mat-option *ngFor="let level of lstDrillLevel" [value]="level.DrillLevelName">
                                                                {{level.DrillLevelName}}
                                                            </mat-option>
                                                        </mat-select>   
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-6">
                                                    <mat-form-field class="md-block search-txt" appearance="outline" floatLabel="always">
                                                        <mat-icon matSuffix class="mat-icon">
                                                            <a href="javascript:void(0);">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.12 373.12" width="24"
                                                                    height="24">
                                                                    <path
                                                                        d="M266.667,234.667h-16.96l-5.867-5.867c20.907-24.213,33.493-55.68,33.493-90.133C277.333,62.08,215.253,0,138.667,0 S0,62.08,0,138.667s62.08,138.667,138.667,138.667c34.453,0,65.92-12.587,90.133-33.387l5.867,5.867v16.853L341.333,373.12 l31.787-31.787L266.667,234.667z M138.667,234.667c-53.013,0-96-42.987-96-96c0-53.013,42.987-96,96-96c53.013,0,96,42.987,96,96 C234.667,191.68,191.68,234.667,138.667,234.667z" />
                                                                </svg>
                                                            </a>
                                                        </mat-icon> 
                                                        <input matInput placeholder="Drill Name or Keywords.."   [(ngModel)]="SearchFilter" 
                                                        name="n_Search"  >
                                                    </mat-form-field> 
                                                </div>
                                                <!-- <div class="col-md-2">
                                                    <a href="javascript:void(0);" class="theme-button mt5">Search</a>
                                                </div> -->
                                                <div class="col-md-1"></div>
                                            </div>
                                        </div>
 
                                        <div class="main-tab-container batting" style="display: block;">
                                            <div class="row" *ngIf = "IsEmptyDrill"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640-Coach.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4"  *ngFor="let batting of lstBattingView|filter:SearchFilter" >
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <!-- <div class="vprofile-video" >
                                                            <video id="my-video" class="video-js" controls poster="{{batting.ThumbnailURL}}" preload="none" 
                                                                 width="540" data-setup="{}"  disablePictureInPicture controlsList="nodownload" >
                                                                <source src="{{batting.VideoURL}}" type="video/mp4">
                                                            </video>
                                                            </div> -->
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                            (click) = "ShowMyVideo(batting)" >
                                                                <img src="{{batting.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <!-- <div class="ico-video"></div> -->  
                                                            <div class="drill-type" style="text-transform:capitalize;" >{{batting.DrillTypeName}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;" >{{batting.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);"
                                                                        class="video-user" style="text-transform:capitalize;" >{{batting.CreatedBy}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{batting.CreatedDate}} || {{batting.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name" style="text-transform:capitalize;">{{batting.DrillName}}</div>
                                                                <a *ngIf="IsShareIconShow" href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click) ="DrillPlayGroupLoad(batting);" ></a>
                                                            </div>
                                                        </figcaption> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="main-tab-container bowling" style="display: none;">
                                            <div class="row" *ngIf = "IsEmptyDrill"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640-Coach.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4"  *ngFor="let bowling of lstBowlingView|filter:SearchFilter" >
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                            (click) = "ShowMyVideo(bowling)" >
                                                                <img src="{{bowling.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <!-- <div class="ico-video"></div> -->
                                                            <div class="drill-type" style="text-transform:capitalize;" >{{bowling.DrillTypeName}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;" >{{bowling.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);"
                                                                        class="video-user" style="text-transform:capitalize;" >{{bowling.CreatedBy}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{bowling.CreatedDate}} || {{bowling.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name" style="text-transform:capitalize;" >{{bowling.DrillName}}</div>
                                                                <a *ngIf="IsShareIconShow" href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click) ="DrillPlayGroupLoad(bowling);"  ></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="main-tab-container fielding" style="display: none;">
                                            <div class="row" *ngIf = "IsEmptyDrill"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640-Coach.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4"  *ngFor="let field of lstFieldingView|filter:SearchFilter" >
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                            (click) = "ShowMyVideo(field)" >
                                                                <img src="{{field.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <!-- <div class="ico-video"></div> -->
                                                            <div class="drill-type" style="text-transform:capitalize;" >{{field.DrillTypeName}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;">{{field.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);"
                                                                        class="video-user" style="text-transform:capitalize;">{{field.CreatedBy}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{field.CreatedDate}} || {{field.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name" style="text-transform:capitalize;">{{field.DrillName}}</div>
                                                                <a *ngIf="IsShareIconShow" href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click) ="DrillPlayGroupLoad(field);" ></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>

                                        <div class="main-tab-container wicket-keeping" style="display: none;">
                                            <div class="row" *ngIf = "IsEmptyDrill"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640-Coach.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4"  *ngFor="let wKeeper of lstWicketKeepingView|filter:SearchFilter" >
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                            (click) = "ShowMyVideo(wKeeper)"  >
                                                                <img src="{{wKeeper.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <!-- <div class="ico-video"></div> -->
                                                            <div class="drill-type" style="text-transform:capitalize;">{{wKeeper.DrillTypeName}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;">{{wKeeper.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);"
                                                                        class="video-user" style="text-transform:capitalize;">{{wKeeper.CreatedBy}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{wKeeper.CreatedDate}} || {{wKeeper.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name" style="text-transform:capitalize;">{{wKeeper.DrillName}}</div>
                                                                <a *ngIf="IsShareIconShow" href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click) ="DrillPlayGroupLoad(wKeeper);" ></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container fitness" style="display: none;">
                                            <div class="row" *ngIf = "IsEmptyDrill"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640-Coach.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4"  *ngFor="let fitness of lstFitnessView|filter:SearchFilter" >
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                            (click) = "ShowMyVideo(fitness)"  >
                                                                <img src="{{fitness.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <!-- <div class="ico-video"></div> -->
                                                            <div class="drill-type" style="text-transform:capitalize;">{{fitness.DrillTypeName}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;">{{fitness.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);"
                                                                        class="video-user" style="text-transform:capitalize;">{{fitness.CreatedBy}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{fitness.CreatedDate}} || {{fitness.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name" style="text-transform:capitalize;">{{fitness.DrillName}}</div>
                                                                <a *ngIf="IsShareIconShow" href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click) ="DrillPlayGroupLoad(fitness);" ></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container others" style="display: none;">
                                            <div class="row" *ngIf = "IsEmptyDrill"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640-Coach.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                 <div class="col-md-4"  *ngFor="let others of lstOthersView|filter:SearchFilter" >
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                            (click) = "ShowMyVideo(others)" >
                                                                <img src="{{others.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <!-- <div class="ico-video"></div> -->
                                                            <div class="drill-type" style="text-transform:capitalize;" >{{others.DrillTypeName}}</div>
                                                            <div class="video-time" style="text-transform:capitalize;">{{others.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);"
                                                                        class="video-user" style="text-transform:capitalize;" >{{others.CreatedBy}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{others.CreatedDate}} || {{others.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name" style="text-transform:capitalize;" >{{others.DrillName}}</div>
                                                                <a *ngIf="IsShareIconShow" href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click) ="DrillPlayGroupLoad(others);" ></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="main-tab-container mydrills" style="display: none;">
                                            <div class="row" *ngIf = "IsEmptyDrill"  >
                                                <div class="col-md-12">
                                                    <div class="no-videos txt-center">
                                                        <img src="assets/images/No-Drills-640-Coach.png" class="img-fluid" draggable="false">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4"  *ngFor="let mydrill of lstMyDrills|filter:SearchFilter|filter:SearchLevel" >
                                                    <div class="drill-list mb20 br-5">
                                                        <div class="drill-thumbnail">
                                                        
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#videopreview"
                                                             (click) = "ShowMyVideo(mydrill)" >
                                                                <img src="{{mydrill.ThumbnailURL}}" class="img-fluid">
                                                            </a>
                                                            <!-- <div class="ico-video" ></div> -->
                                                            <div class="drill-type"  style="text-transform:capitalize;" >{{mydrill.DrillTypeName}}</div>
                                                            <div class="video-time"  style="text-transform:capitalize;" >{{mydrill.VideoLength}}</div>
                                                        </div>
                                                        <figcaption>
                                                            <div class="drill-user-date">
                                                                <div class="drill-user">
                                                                    <a href="javascript:void(0);"
                                                                        class="video-user" style="text-transform:capitalize;" >{{mydrill.CreatedBy}}</a>
                                                                </div>
                                                            </div>
                                                            <div class="time">
                                                                <p>{{mydrill.CreatedDate}} || {{mydrill.CreatedTime}}</p>
                                                            </div>
                                                            <div class="drill-name-share">
                                                                <div class="drill-name" style="text-transform:capitalize;" >{{mydrill.DrillName}}</div>
                                                                <a *ngIf="IsShareIconShow" href="javascript:void(0);" class="ico-menu"
                                                                    data-toggle="modal" data-target="#drillshare"
                                                                    (click) ="DrillPlayGroupLoad(mydrill);" ></a>
                                                            </div>
                                                        </figcaption>
                                                    </div>
                                                </div>   
                                               
                                            </div>
                                        </div>

                                    </div> 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Share Modal -->
<div class="modal fade"  *ngIf="IsShareShow"  id="drillshare" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Share Drills</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12" *ngIf="!isfromcoachpad">
                        <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                            floatLabel="always">
                            <mat-label>Play Groups</mat-label>
                            <mat-icon matSuffix class="mat-icon">
                                <svg viewBox="0 0 24 24" x="768" y="0" fit=""
                                    height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path opacity=".3"
                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                    </path>
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                    </path>
                                </svg>
                            </mat-icon>
                            <mat-select placeholder="Play Groups"  [(ngModel)]="PlayGroups" (selectionChange)="DrillAthleteLoad(PlayGroups)"  >
                                <mat-option *ngFor="let group of lstGetCoachPlayGroup" [value]="group">
                                    {{group.PlayGroupName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> 
                    </div>
                    <div class="col-md-12" *ngIf="!isfromcoachpad">
                        <mat-form-field class="md-block mat-dd-field search-txt" appearance="outline"
                            floatLabel="always">
                            <mat-label>Athletes </mat-label>
                            <mat-icon matSuffix class="mat-icon">
                                <svg viewBox="0 0 24 24" x="768" y="0" fit=""
                                    height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path opacity=".3"
                                        d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z">
                                    </path> 
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z">
                                    </path>
                                </svg>
                            </mat-icon>
                            <mat-select placeholder="Athletes" [(ngModel)]="Athletes" multiple  >
                                <mat-option *ngFor="let athlete of lstGetAtheleteByPlayGroup" [value]="athlete.RefUserID" 
                                   (click)="onAthelteAdd(athlete.RefUserID,$event)"  >
                                    {{athlete.DisplayName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12" *ngIf="isfromcoachpad">
                        <mat-label>Share drill to {{coachpaddetails.PlayerName}} </mat-label>
                    </div>
                    <div class="col-md-12 txt-center">
                        <a href="javascript:void(0);" class="theme-button mt25" (click)="DrillShare()" >Share</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Preview Modal -->
<div class="modal fade" *ngIf="IsMyVideoShow" id="videopreview"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle" style="text-transform:capitalize;"  >{{PreviewDrillName}} 
                    <span class="skill-type" style="text-transform:capitalize;"  >{{DrillType}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                    <div class="drills-popup-video">
                        <div class="vprofile-video">
                            <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                src="{{VideoURL}}" data-setup="{}"  disablePictureInPicture controlsList="nodownload" type="video/mp4" >
                            </video>
                            <!-- <video id="my-video" class="video-js" controls poster="{{ThumbNail}}" preload="none" width="100%"
                                    data-setup="{}"  disablePictureInPicture controlsList="nodownload" >
                                <source src="{{VideoURL}}" type="video/mp4" /> </video> -->
                        </div>
                        <div class="drill-user-date">
                            <div class="drill-user">
                                <a href="javascript:void(0);" class="video-user"
                                style="text-transform:capitalize;"  >{{CreatedBy}}</a>
                            </div>
                            <div class="time">
                                <p>{{CreatedDate}} || {{CreatedTime}}</p>
                            </div>   
                        </div>                            
                    </div>
                    <div class="drills-description">    
                        <div class="title">Instruction</div>
                        <ul class="instruction mb15"  >
                            <li style="text-transform:capitalize;" 
                              *ngFor="let stp of lstDrillEditStep;let ind=index;">Step {{ind + 1}} : {{stp.StepText}}
                            </li>
                        </ul>
                        <div class="title">Required Items</div>
                        <ul class="required"   >
                            <li style="text-transform:capitalize;" *ngFor="let val of MarialsBind"  >{{val}}</li>
                        </ul>     
                    </div>
            </div>
        </div>
    </div>
</div>

<!-- Alerts -->
<div class="overlay success"  *ngIf = "isSuccessAlert" >
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div  class="alcenter" > 
                <h4>My Drills</h4>
                <p>{{successAlert}}</p> 
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" (click)="isSuccessAlert=false;WindowLoad();" >Ok</button>
        </div>
    </div>
</div> 
 <div class="overlay error" *ngIf = "isErrorAlert" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
                <div  class="alcenter" > 
                 <h4>My Drills</h4>
                <p>{{errorAlert}}</p> 
               </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"   (click)="isErrorAlert=false" >Ok</button>
        </div>
   </div>
</div> 
<div class="overlay warning" *ngIf = "isWarningAlert" >
    <div class="warning-widget">
       <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="warning-container">
        <div class="alcenter"> 
            <h4>My Drills</h4>
            <p >{{warningAlert}}</p> 
        </div>   
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="isWarningAlert=false" >Ok</button>
        </div>
   </div>
</div>
