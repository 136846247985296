import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { MastersServiceService} from '../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label,MultiDataSet } from 'ng2-charts';
import { ApplicationService } from '../../assets/js/app.config';

@Component({
  selector: 'app-dashboard',   
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private utility:UtilitiesService,private service:MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, private ApplicationService:ApplicationService,
     public datepipe: DatePipe) { this.today.setDate(this.today.getDate()); }    

  @Input()  today = new Date();

  public imageObject:any = [];

  public UserCode:string; public UserName:string;

  public isShowLoader:boolean;
  public isSuccessAlert: boolean = false; public successAlert:string ;
  public isErrorAlert: boolean = false; public errorAlert:string ;
  public isWarningAlert: boolean = false;  public warningAlert:string ;
  public  MemberTypeID : string=String(JSON.parse(sessionStorage.getItem("loginUserDetails")).MemberTypeID);
  listImages: Array<CoachList> = []; //public listTeamGrounds:any[];

  public imagepopup:boolean = false;
  sliderImagePopup: Boolean = false;

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode=String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.sessionItems = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    this.DashboardInitLoad();
  }

  public AssociationsList:any = []; public MemberList:any = [];
  public MemberTypeList:any = []; public PlayerTypeList:any = [];
  public AssociatioCoachList:any = [];   public AthleteAgeCategoryCount:any = [];
  public AthleteCategoryMonthWise:any = []; public AthleteMonthwiseReg:any = [];
  
  public AthleteCategoryList:any[];
  public lstMemberType:any = [];
  
  public AssociationName:string; public AssociationCity:string; public AssociationLogo:string;
  public AthleteCount:string;   public CoachCount:string;  public sessionItems:string; 

  public AssociationRole:string;
  public AgeCatTotal:number;  public listCategoryPercentage:any=[];
  public lineChartBindData:any = [];

  myList : MyClass[] = [];
  public DonutLabel:any = []; public DonutData:any = [[]]; 
  public TempDonutLabel:any = []; public TempDonutData:any[];  

  public demodoughnutChartLabels:string[] = ['No data', 'No data', 'No data','No data'];
  public demodoughnutChartData:number[] = [0,0,0,0];
  public demodoughnutChartType:string = 'doughnut';
  public doughnutChartColors: Color[] = [{ backgroundColor: ['#008ffb', '#00e396', '#feb019', '#9C311A' ]} ];

  public lineChartBind:any = []; public lineChartLable:any = []; public lineChartTooltip:any = [];

  public IsAthleteFocusShow:boolean = false;

  DashboardInitLoad(){
    this.isShowLoader = true;  
    // this.lineChartBind=[];  this.lineChartLable=[]; this.lineChartTooltip=[];
    this.AgeCatTotal = 0;
    pageStatus = "GridLoad";    
    var AssoLoad = { ActionFlag: "Dashboard" , ModuleName: "AssociationDashboard" , UserID:String(this.UserCode) };
    this.service.AssociationDashboardLoad(AssoLoad).subscribe((ret:any=[])=>{
      let BTStatus = String(ret.Status);
        if (BTStatus == "true"){
          this.AssociationsList = ret.AssociationsList; 
          this.MemberList       = ret.MemberList; 
          this.MemberTypeList   = ret.MemberTypeList; 
          this.PlayerTypeList   = ret.PlayerTypeList; 
          this.AssociatioCoachList = ret.AssociatioCoachList; 
          this.AthleteAgeCategoryCount  = ret.AthleteCategoryCountList; 
          this.AthleteCategoryMonthWise = ret.AthleteCategoryMonthWiseList; 
          this.AthleteMonthwiseReg      = ret.AthleteMonthwiseRegList; 
          this.isShowLoader = false;  

          // -- Basic Details -- //
          this.AssociationName   = this.AssociationsList[0].AssociationName; 
          this.AssociationCity   = this.AssociationsList[0].CityName;
          this.AssociationLogo   = this.AssociationsList[0].AssociationLogo;

          if (this.MemberList[0].MemberType == 'Coach')
          { this.CoachCount = this.MemberList[0].Counts; }
          else{ this.CoachCount = this.MemberList[0].Counts; }

          if (this.MemberList[0].MemberType == 'Cricketer')
          { this.AthleteCount = this.MemberList[0].Counts; }
          else{ this.AthleteCount = this.MemberList[1].Counts;  }

          if (this.MemberList[0].MemberType == 'Association'){ 
            this.AthleteCount = this.MemberList[2].Counts; 
          }
          else if (this.MemberList[0].MemberType == 'Coach'){
            this.AthleteCount = this.MemberList[1].Counts; 
          }
          else{ 
            this.AthleteCount = this.MemberList[0].Counts; 
          }

          // -- Image Slider -- //
          for (var i = 0; i < this.AssociatioCoachList.length; i++) {
              let customObj = new CoachList();
                  customObj.image = this.AssociatioCoachList[i].ProfileImage;
                  customObj.thumbImage = this.AssociatioCoachList[i].ProfileImage;
                  customObj.alt = this.AssociatioCoachList[i].DisplayName;
                  customObj.title = this.AssociatioCoachList[i].DisplayName;
              this.imageObject.push(customObj);
          }

          // -- Dough-Nut Chart -- //
          this.lstMemberType = this.MemberTypeList.filter(s => s.KPI === "PlayerRole");  
          this.DonutLabel    = ['Batsman', 'Bowler', 'All Rounder','WK'];
          this.DonutData = [0,0,0,0];
          for (var i = 0; i < this.lstMemberType.length; i++) {
            if(this.lstMemberType[i].KeyType=='Batsman')
             this.DonutData[0]=this.lstMemberType[i].TotalPlayers;
            else if(this.lstMemberType[i].KeyType=='Bowler')
             this.DonutData[1]=this.lstMemberType[i].TotalPlayers;

            else if(this.lstMemberType[i].KeyType=='All Rounder')
             this.DonutData[2]=this.lstMemberType[i].TotalPlayers;

            else if(this.lstMemberType[i].KeyType=='Wicket Keeper')
             this.DonutData[3]=this.lstMemberType[i].TotalPlayers;
          }

          if ( this.DonutData.length > 0) {
            this.demodoughnutChartLabels = this.DonutLabel;
            this.demodoughnutChartData   = this.DonutData; 
            this.IsAthleteFocusShow = false;
          } 
          else{
            this.IsAthleteFocusShow = true;
          }

          // -- Seek Bar -- //
          for (var i = 0; i < this.AthleteAgeCategoryCount.length; i++) {
              this.AgeCatTotal = this.AthleteAgeCategoryCount[i].Counts + this.AgeCatTotal;
          } 
          for( var j = 0; j < this.AthleteAgeCategoryCount.length; j++){
            this.listCategoryPercentage.push({ "Percentage" : ((this.AthleteAgeCategoryCount[j].Counts/this.AgeCatTotal)*100).toFixed(2) + '%',
                                               "Colors" : '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6) ,
                                               "ToolTip": this.AthleteAgeCategoryCount[j].CategoryName +'('+ this.AthleteAgeCategoryCount[j].Counts +')' });
          }
          
          // -- Line Chart -- //  
          //  public lineChartBind:any = []; public lineChartLable:any = [];
          for( var k = 0; k < this.AthleteMonthwiseReg.length; k++){
               this.lineChartBind.push(this.AthleteMonthwiseReg[k].Counts);
               this.lineChartLable.push(this.AthleteMonthwiseReg[k].Months);
               this.lineChartTooltip.push( this.AthleteMonthwiseReg[k].Months + '(' + this.AthleteMonthwiseReg[k].Counts + ')' )
           }
        }  
        else{
              this.isShowLoader = false;  
              this.isErrorAlert = true;
              this.errorAlert = '\n Dashboard Details Load Failed..!! \n';
              return;
        }
      }) 
  }

  CategorySelectionChange(catID){
    this.isShowLoader = true;
    // this.AthleteMonthwiseReg=[];
    var AssoLoad = { ActionFlag: "GetAthleteByCategory" , ModuleName: "AssociationDashboard" , 
                     CategoryID:String(catID) , UserID:String(this.UserCode) };
    this.service.AssociationDashboardLoad(AssoLoad).subscribe((ret:any=[])=>{
      this.lineChartBind=[];  this.lineChartLable=[]; this.lineChartTooltip=[];
      let BTStatus = String(ret.Status);
      if (BTStatus == "true"){
        this.AthleteCategoryList = ret.AthleteCategoryList;
          for( var k = 0; k < this.AthleteCategoryList.length; k++){
            this.lineChartBind.push(this.AthleteCategoryList[k].Counts);
            this.lineChartLable.push(this.AthleteCategoryList[k].Months);
            this.lineChartTooltip.push( this.AthleteCategoryList[k].CategoryName + '(' + this.AthleteCategoryList[k].Counts + ')' )
          }
          this.isShowLoader = false;  
      }  
      else{
            this.isShowLoader = false;  
            this.isErrorAlert = true;
            this.errorAlert = '\n Dashboard Details Load Failed..!! \n';
            return;
      }
    }) 
  }



  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['No data', 'No data', 'No data', 'No data', 'No data', 'No data', 'No data'];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartPlugins = [];

    public barChartData: ChartDataSets[] = [
      { data: [0, 0, 0, 0, 0, 0, 0], label: 'No data', stack: 'a' },
      { data: [0, 0, 0, 0, 0, 0, 0], label: 'No data', stack: 'a' },
      { data: [0, 0, 0, 0, 0, 0, 0], label: 'No data', stack: 'a' },
      { data: [0, 0, 0, 0, 0, 0, 0], label: 'No data', stack: 'a' }
    ];
    public barChartColors: Color[] = [
      { backgroundColor: '#008ffb' },
      { backgroundColor: '#00e396' },
      { backgroundColor: '#feb019' },
      { backgroundColor: '#ff4560' }
    ]
    
    // linechart
    // public lineChartData: ChartDataSets[] = [{ data: [65, 59, 80, 81, 56, 55, 40], label: "Athlete", lineTension: 0 }];
    // public lineChartLabels: Label[] = ["January","February","March","April","May","June","July" ];
   
    public lineChartData: ChartDataSets[] = [
      { data: this.lineChartBind, label: "Athlete", lineTension: 0 }
    ];
    public lineChartLabels: Label[] = this.lineChartLable;
    public lineChartColors: Color[] = [
      { borderColor: "#ff4560", backgroundColor: "rgba(255,69,96,0.3)" } ];
    public lineChartLegend = this.lineChartTooltip ;
    public lineChartType: ChartType = 'line';
    public lineChartPlugins = [];
 
}


export var pageStatus: string = "";
export class CoachList { image:string; thumbImage:String; title:string; alt:string; }
export class MyClass{  value1: string;  value2: string; }