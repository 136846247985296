import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  headers: any;
  _baseURL: string;
  _userProfile: string; 
  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders().set('content-type', 'application/json');
         this._baseURL = "http://localhost:55642/";
        // this._baseURL = "http://52.172.96.40/CricpassAPIDemo/";           
        // this._baseURL = "http://52.172.96.40/ThirdEyeAPI_UAT/";   
        // this._baseURL = "https://api.cricpass.com/"; 
  }  

  //-- Load Association Load and Basic Location Loads --//    
  public loadAssociationMaster(data) {
    return this.httpClient.post(this._baseURL + "AssociationRegistration/AssociationRegistrationLoad", data, { headers: this.headers });
  }
  // --- Association Master CRUD --- //
  public AssociationMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "AssociationRegistration/AssociationRegistrationCRUD", data, { headers: this.headers });
  }
  // --- Athlete Master CRUD --- //
  public AthleteMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "AtheleteRegistration/AtheleteRegistrationCRUD", data, { headers: this.headers });
  }
  public DeviceSignup(data) {
    return this.httpClient.post(this._baseURL + "Registration/DeviceSignup", data, { headers: this.headers });
  }
  public AtheleticLoad(data) {
    return this.httpClient.post(this._baseURL + "Registration/AtheleticLoad", data, { headers: this.headers });
  }
  public AtheleteRegistration(data) {
    return this.httpClient.post(this._baseURL + "Registration/AtheleteRegistration", data, { headers: this.headers });
  }
  public LoginValidate(data) {
    return this.httpClient.post(this._baseURL + "Registration/LoginValidate", data, { headers: this.headers });
  }
  public CoachLoad(data) {
    return this.httpClient.post(this._baseURL + "Registration/CoachLoad", data, { headers: this.headers });
  }
  public CoachRegistration(data) {
    return this.httpClient.post(this._baseURL + "Registration/CoachRegistration", data, { headers: this.headers });
  }
  // --- Coach Master CRUD --- //
  public AssoCoachRegLoad(data) {
    return this.httpClient.post(this._baseURL + "CoachRegistration/AssoCoachRegLoad", data, { headers: this.headers });
  }
  // --- Coach Master CRUD --- //
  public CoachMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "CoachRegistration/CoachRegistrationCRUD", data, { headers: this.headers });
  }
  public UploadFile(data) {
    return this.httpClient.post(this._baseURL + "Registration/UploadFile", data, { headers: this.headers });
  }
  // --- Association Dashboard CRUD --- //
  public AssociationDashboardLoad(data) {
    return this.httpClient.post(this._baseURL + "AssociationDashboard/AssociationDashboardLoad", data, { headers: this.headers });
  }
  // --- AgeCategory Master CRUD --- //
  public AgeCategoryMaster(data) {
    return this.httpClient.post(this._baseURL + "Masters/AgeCategoryMaster", data, { headers: this.headers });
  }
  // --- Batch Master CRUD --- //
  public BatchMasterMaster(data) {
    return this.httpClient.post(this._baseURL + "Masters/BatchMaster", data, { headers: this.headers });
  }
  // --- Player Group Master CRUD --- //
  public PlayGroupMasterCRUD(data) {
    return this.httpClient.post(this._baseURL + "Masters/PlayGroupMaster", data, { headers: this.headers });
  }
  // --- Support-Staff Mapping --- //
  public SupportStaffMapping(data) {
    return this.httpClient.post(this._baseURL + "Masters/SupportStaffMappingMaster", data, { headers: this.headers });
  }
  // --- Athlete Mapping --- //
  public AthleteMapping(data) {
    return this.httpClient.post(this._baseURL + "Masters/AthleteMappingMaster", data, { headers: this.headers });
  }
  // --- Athelete Dashboard  --- //
  public AthleteDashboard(data) {
    return this.httpClient.post(this._baseURL + "AssociationDashboard/AthleteDashboard", data, { headers: this.headers });
  }
  // --- Coach Dashboard  --- //
  public CoachDashboard(data) {
    return this.httpClient.post(this._baseURL + "AssociationDashboard/CoachDashboard", data, { headers: this.headers });
  }
  // --- Change Password  --- //
  public ChangePassword(data) {
    return this.httpClient.post(this._baseURL + "Registration/ChangePassword", data, { headers: this.headers });
  }
  // --- Athlete Session PageLoad  --- //
  public AthleteSessionPageLoad(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionLoad", data, { headers: this.headers });
  }
  public AthleteSessionCreation(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionCreation", data, { headers: this.headers });
  }
  public AthleteSessionsList(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionsList", data, { headers: this.headers });
  }
  public AthleteSessionSharing(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionSharing", data, { headers: this.headers });
  }
  public AthleteSessionChatting(data) {
    return this.httpClient.post(this._baseURL + "Sessions/SessionChatting", data, { headers: this.headers });
  }
  public SessionChattingCoachLoad(data) {
    return this.httpClient.post(this._baseURL + "CoachSession/SessionChattingCoachLoad", data, { headers: this.headers });
  }
  public SessionChattingCoachCRUD(data) {
    return this.httpClient.post(this._baseURL + "CoachSession/SessionChattingCoachCRUD", data, { headers: this.headers });
  }

  // --- My Drills  --- //
  public DrillsLoad(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillsLoad", data, { headers: this.headers });
  }
  public DrillsCRUD(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillsCRUD", data, { headers: this.headers });
  }
  public DrillSharing(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillSharing", data, { headers: this.headers });
  }
  public DrillsAthletes(data) {
    return this.httpClient.post(this._baseURL + "Drills/DrillsAthletes", data, { headers: this.headers });
  }

  //Planner 
  public PlannerCalendarLoad(data) {
    return this.httpClient.post(this._baseURL + "Planner/PlannerCalendarLoad", data, { headers: this.headers });
  }
  public PlannerPageLoad(data) {
    return this.httpClient.post(this._baseURL + "Planner/PlannerPageLoad", data, { headers: this.headers });
  }
  public PlannerEventsCRUD(data) {
    return this.httpClient.post(this._baseURL + "Planner/PlannerEventsCRUD", data, { headers: this.headers });
  }

  //Video Annotations
  public VideoAnalyseCRUD(data) {
    return this.httpClient.post(this._baseURL + "VideoAnalyse/VideoAnalyseCRUD", data, { headers: this.headers });
  }

  //Video Tagging
  public VideoTaggingLoad(data) {
    return this.httpClient.post(this._baseURL + "Videos/VideoTaggingLoad", data, { headers: this.headers });
  }
  public videoTaggingEngine(data) {
    return this.httpClient.post(this._baseURL + "Videos/VideoTaggingEngine", data, { headers: this.headers });
  }
  public VideoTaggingEngineCRUD(data) {
    return this.httpClient.post(this._baseURL + "Videos/VideoTaggingEngineCRUD", data, { headers: this.headers });
  }


}
