<div   *ngIf="Pagetitle=='Coaching'" class="page-container my-videos-page">
    <div class="container">
        <div class="input-container">
            <div class="row">
                <div class="col-md-12 txt-center">
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="container">
            <div class="output-container">
                <div class="row">
                    <div class="col-md-9">
                        <div class="Playerlist-container">
                            <div class="title">Active Player</div>
                            <ul>
                                <li *ngFor="let act of ActiveSessions; let ind=index;">
                                    <div class="playerlist-item" (click)="act.RequestStatusID==4?loadPlayerwall(act):false">
                                        <div class="playerimg">
                                            <a href="javascript:void(0);"  >
                                                <img src="{{act.PlayerImage}}">
                                            </a>
                                        </div>
                                        <div class="playerinfo">
                                            <h4>{{act.PlayerName}}</h4>
                                            <p class="coachoprofile-proficiency">Batsman</p>
                                        </div>
                                        <div class="playerstatus txt-center"
                                            *ngIf="act.RequestStatus!='Payment Pending'">
                                            <div class="remainingdays">
                                                <span>{{act.DaysLeft}}</span> Day to Left
                                            </div>
                                        </div>
                                        <div class="playerstatus txt-center"
                                            *ngIf="act.RequestStatus=='Payment Pending'">
                                            <div class="remainingdays">
                                                Payment Pending 
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="fixed-container min">
                            <div class="video-filters box-shadow br-5">
                                <div class="coachingtab">
                                    <div class="secondary-tab" rel="requestplayer">
                                        <a href="javascript:void(0);" class="select" rel="newrequest">New Request</a>
                                        <a href="javascript:void(0);" rel="sessioncompleted">Completed</a>
                                    </div>
                                </div>
                                <div class="requestplayer">
                                    <div class="secondary-tab-container newrequest" style="display:block;">
                                        <div class="new-request-container">
                                            <ul>
                                                <li *ngFor="let req of ActiveRequests; let ind=index;">
                                                    <div class="requester-profile">
                                                        <div class="requester-picture"><img src="{{req.PlayerImage}}">
                                                        </div>
                                                        <div class="requester-data">
                                                            <div class="requester-name">{{req.PlayerName}}</div>
                                                            <p class="proficiency">All-Rounder</p>
                                                            <div class="txt-center request-action">
                                                                <a href="javascript:void(0);" (click)="AcceptRequest(req)"
                                                                    class="txt-accept">Accept</a>
                                                                <a href="javascript:void(0);" (click)="IgnoreRequest(req)"
                                                                    class="txt-ignore">Ignore</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div class="secondary-tab-container sessioncompleted" style="display:none;">
                                        <div class="new-request-container">
                                            <ul>
                                                <li *ngFor="let comp of CompletedSessions; let ind=index;">
                                                    <div class="requester-profile">
                                                        <div class="requester-picture"><img
                                                                src="{{comp.PlayerImage}}"></div>
                                                        <div class="requester-data">
                                                            <div class="requester-name">{{comp.PlayerName}}</div>
                                                            <p class="proficiency">All-Rounder</p>
                                                            <div class="txt-center request-action">
                                                                <a href="javascript:void(0);" class="txt-ignore">Session
                                                                    Completed</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="Pagetitle=='PlayerWall'" ><app-coachpad-playerwall (buttonClick)="Back($event)"></app-coachpad-playerwall></div>