<div class="loadercontainer" *ngIf="isShowLoader">
    <div class="loader-container">
        <img src="assets/images/cricket-ball.gif" />
    </div>
</div>

<div class="page-container">
    <div class="container">
        <div class="input-container">
            <!-- <div class="white">
                <div class="row">
                    <div class="col-md-12">
                        Association Registration
                    </div>
                </div>
            </div> -->
        </div>
    </div>
<div class="page-wrapper">  
    <div class="form-container">   
        <div class="container">   
            <div class="output-container">
                <div class="white">
                    <div class="txt-right add-new back-previous">
                        <a routerLink="/association-list">
                            Back
                            <!-- <div class="ico-action-svg more" title="Previous Page">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.151 219.151" width="32" height="32">	<path d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575		C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"/><path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008 c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628 		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/></svg>
                                
                            </div> -->
                        </a> 
                    </div>
                    <mat-vertical-stepper #stepper [linear]="isLinear" >

                        <mat-step [completed]="false"  [stepControl]="AssociationForm" >
                          <form [formGroup]="AssociationForm" #f="ngForm"  >
                            <ng-template matStepLabel>Academic Info</ng-template>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <mat-label>Academic Name</mat-label>
                                        <input matInput required [(ngModel)]="AssociationName" formControlName="n_AssociationName" style="text-transform:capitalize;" 
                                        maxlength="150"  >
                                    </mat-form-field>
                                    <!-- <div class="alert alert-danger">  
                                            <div>Association Name is required</div>
                                    </div> -->
                                    <div *ngIf="f.submitted && AssociationForm.controls.n_AssociationName.invalid"
                                                            class="alert alert-danger">
                                          <div *ngIf="AssociationForm.controls.n_AssociationName.errors.required">
                                            Academic Name is required.</div>
                                     </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <mat-label>Display Name</mat-label>
                                        <input matInput required  [(ngModel)]="AssoDisplayName" formControlName="n_AssoDisplayName"  style="text-transform:capitalize;"
                                        maxlength="150"  >  
                                    </mat-form-field>
                                    <div *ngIf="f.submitted && AssociationForm.controls.n_AssoDisplayName.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationForm.controls.n_AssoDisplayName.errors.required" >
                                            Display Name is required </div>
                                    </div> 
                                </div> 
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Parent Academic Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                         <mat-select placeholder="Parent Academic Name" [(ngModel)]="ParentAssociation"  formControlName="n_ParentAssociation"  style="text-transform:capitalize;"  >
                                            <mat-option [value]="association.AssociationID"  *ngFor="let association of lstAssociationParentAss"  
                                            style="text-transform:capitalize;"  >{{association.AssociationName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="f.submitted && AssociationForm.controls.n_ParentAssociation.invalid"
                                                            class="alert alert-danger">
                                        <div *ngIf="AssociationForm.controls.n_ParentAssociation.errors.required">
                                            Parent Association is required.</div>
                                    </div>                            
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Sports</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <mat-select placeholder="Sports" required [(ngModel)]="Sports" formControlName="n_Sports"  
                                                  style="text-transform:capitalize;"  >
                                            <mat-option [value]="sport.SportID"  *ngFor="let sport of lstAssociationSports"  
                                                style="text-transform:capitalize;"  >{{sport.DisplayName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="f.submitted && AssociationForm.controls.n_Sports.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationForm.controls.n_Sports.errors.required" >
                                            Sports is required </div>
                                    </div>  
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Registration Date</mat-label>
                                        <input required matInput [matDatepicker]="picker" [(ngModel)]="RegistrationDate"  [max]="maxDate"
                                         formControlName="n_RegistrationDate" readonly >
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="f.submitted && AssociationForm.controls.n_RegistrationDate.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationForm.controls.n_RegistrationDate.errors.required" >
                                            Registration Date is required </div>
                                    </div> 
                                </div>
                                <div class="col-md-4" style="display: none;" >
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <mat-label>Registration Number</mat-label>
                                        <input matInput [(ngModel)]="RegistrationNumber" formControlName="n_RegistrationNumber" >
                                    </mat-form-field>
                                    <div *ngIf="f.submitted && AssociationForm.controls.n_RegistrationNumber.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationForm.controls.n_RegistrationNumber.errors.required" >
                                            Registration Number is required </div>
                                    </div>  
                                </div>
                            </div> 
                            <div class="txt-right">  
                              <button mat-raised-button color="primary" (click)="AssToSubscribe(stepper)"  >Next</button>
                            </div>
                          </form>
                        </mat-step>

                        <mat-step [completed]="false" [stepControl]="AssociationInfo"  >
                          <form [formGroup]="AssociationInfo" #g="ngForm" >
                            <ng-template matStepLabel>Subscription Info</ng-template>
                           <div class="row">
                               <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-label>Packages Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon mat-icon-custom"><span>$</span></mat-icon>
                                        <!-- <mat-select placeholder="Subscription Package"  required [(ngModel)]="Packages" formControlName="n_Packages"  style="text-transform:capitalize;"  >
                                            <mat-option [value]="1">High</mat-option>
                                            <mat-option [value]="2">Medium</mat-option>
                                            <mat-option [value]="3">Low</mat-option>
                                        </mat-select> -->
                                        <mat-select placeholder="Subscription Package" [(ngModel)]="Packages"  formControlName="n_Packages" style="text-transform:capitalize;"  required >
                                            <mat-option [value]="pack.AcademyTypeID"  *ngFor="let pack of lsyAssociationPackagesList"  style="text-transform:capitalize;"  >{{pack.AcademyTypeName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="g.submitted && AssociationInfo.controls.n_Packages.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationInfo.controls.n_Packages.errors.required" >
                                             Packages required </div>
                                    </div>  
                               </div>
                               <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <mat-label>No.of Users</mat-label>
                                        <input matInput required  [(ngModel)]="NoofUsers"  formControlName="n_NoofUsers" 
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"  >
                                    </mat-form-field>
                                    <div *ngIf="g.submitted && AssociationInfo.controls.n_NoofUsers.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationInfo.controls.n_NoofUsers.errors.required" >
                                            No.of.Users required </div>
                                    </div>  
                               </div>
                               <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Valid Till</mat-label>
                                        <input matInput required [matDatepicker]="validtill" [(ngModel)]="ValidDate"  formControlName="n_ValidDate" readonly [min]="today" >
                                        <mat-datepicker-toggle matSuffix [for]="validtill"></mat-datepicker-toggle>
                                        <mat-datepicker #validtill></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="g.submitted && AssociationInfo.controls.n_ValidDate.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationInfo.controls.n_ValidDate.errors.required" >
                                            Valid date is required </div>
                                    </div>  
                               </div>
                               <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <mat-label>Referral Code</mat-label>
                                        <input matInput  [(ngModel)]="ReferralCode"  formControlName="n_ReferralCode" maxlength="7"  >
                                    </mat-form-field>
                                    <!-- <div *ngIf="g.submitted && AssociationInfo.controls.n_ReferralCode.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="AssociationInfo.controls.n_ReferralCode.errors.required" >
                                                   Referral Code required </div>
                                   </div>   -->
                               </div>
                           </div>
                            <div class="txt-right">
                              <button mat-raised-button color="primary" matStepperPrevious style="margin-right: 15px;">Back</button>
                              <button mat-raised-button color="primary" (click)="AssSubToContact(stepper)" >Next</button>
                            </div> 
                          </form>
                        </mat-step>

                        <mat-step [completed]="false" [stepControl]="AssociationContact" >
                            <form [formGroup]="AssociationContact" #b="ngForm">
                                <ng-template matStepLabel>Contact Info</ng-template>
                                <div class="row">
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                            <mat-label>Email ID</mat-label>
                                            <input matInput required [(ngModel)]="EmailID"  formControlName="n_EmailID" name="n_EmailID" >
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_EmailID.invalid" class="alert alert-danger">
                                                <div *ngIf="AssociationContact.controls.n_EmailID.errors.required">
                                                    Email ID required</div>
                                                <div *ngIf="this.AssociationContact.controls.n_EmailID.errors.email">
                                                    Email Id is Incorrect</div>
                                        </div>  
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                            <mat-label>Contact Number 1</mat-label>
                                            <input matInput [(ngModel)]="Contact01"  formControlName="n_Contact01"  maxlength="10"  required
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" >
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_Contact01.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationContact.controls.n_Contact01.errors.required" >
                                             Contact 1 required </div>
                                        </div> 
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                            <mat-label>Contact Number 2</mat-label>
                                            <input matInput [(ngModel)]="Contact02"  formControlName="n_Contact02"  maxlength="10" 
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" >
                                        </mat-form-field>
                                                   <!-- <div *ngIf="f.submitted && AssociationContact.controls.n_Contact02.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="AssociationContact.controls.n_Contact02.errors.required" >
                                                   Contact 2 required </div>
                                               </div>    -->
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                            <mat-label>Address 1</mat-label>
                                            <input matInput [(ngModel)]="Address01" required formControlName="n_Address01"  style="text-transform:capitalize;"  >
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_Address01.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationContact.controls.n_Address01.errors.required" >
                                             Address 1 required </div>
                                        </div>  
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                            <mat-label>Address 2</mat-label>
                                            <input matInput [(ngModel)]="Address02"  formControlName="n_Address02"  style="text-transform:capitalize;"  >
                                        </mat-form-field>
                                                    <!-- <div *ngIf="f.submitted && AssociationContact.controls.n_Address02.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="AssociationContact.controls.n_Address02.errors.required" >
                                                   Address 2 required </div>
                                             </div>   -->
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-label>Region</mat-label>
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                            <mat-select placeholder="Region" [(ngModel)]="Continent"  formControlName="n_Continent" (selectionChange)="onChangeContinent(Continent)"  style="text-transform:capitalize;"  required >
                                                <mat-option [value]="Continent.ContinentID"  *ngFor="let Continent of ContinentsList"  style="text-transform:capitalize;"  >{{Continent.ContinentName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_Continent.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationContact.controls.n_Continent.errors.required" >
                                            Region required </div>
                                        </div>  
                                    </div>   
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-label>Country</mat-label>
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                            <mat-select placeholder="Country"  [(ngModel)]="Country"  formControlName="n_Country"  (selectionChange)="onChangeCountry(Country)"  style="text-transform:capitalize;" required  >
                                                <mat-option [value]="country.CountryID"  *ngFor="let country of CountryByRegion"  style="text-transform:capitalize;"  >{{country.CountryName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_Country.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationContact.controls.n_Country.errors.required" >
                                            Country required </div>
                                        </div>  
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-label>State</mat-label>
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                            <mat-select placeholder="State"  [(ngModel)]="States"  formControlName="n_States"   (selectionChange)="onChangeState(States)"  style="text-transform:capitalize;" required >
                                                <mat-option [value]="state.StateID" *ngFor="let state of StateByCountry"  style="text-transform:capitalize;"  >{{state.StateName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_States.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationContact.controls.n_States.errors.required" >
                                            State required </div>
                                        </div>  
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-label>City</mat-label>
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                            <mat-select placeholder="City"  [(ngModel)]="City"  formControlName="n_City" (selectionChange)="onChangeCity(City)"  style="text-transform:capitalize;"  required >
                                                <mat-option [value]="city.CityID" *ngFor="let city of CityByState"  style="text-transform:capitalize;"  >{{city.CityName}}</mat-option>
                                            </mat-select>  
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_City.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationContact.controls.n_City.errors.required" >
                                            City required </div>
                                        </div>  
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                            <mat-label>Pin Code</mat-label>
                                            <input matInput [(ngModel)]="Pincode"  formControlName="n_Pincode"   maxlength="6" required
                                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" >
                                        </mat-form-field>
                                        <div *ngIf="b.submitted && AssociationContact.controls.n_Pincode.invalid"
                                            class="alert alert-danger">
                                            <div *ngIf="AssociationContact.controls.n_Pincode.errors.required" >
                                            Pincode required </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="txt-right">
                                    <button mat-raised-button color="primary" matStepperPrevious style="margin-right: 15px;">Back</button>
                                    <button mat-raised-button color="primary"  (click)="AssContactToSettings(stepper)" >Next</button>
                                </div>
                            </form>
                        </mat-step>

                        <mat-step [completed]="false" [stepControl]="AssociationSettings" >
                            <form [formGroup]="AssociationSettings" #c="ngForm" (ngSubmit)="onSubmit()" >
                                <ng-template matStepLabel>Settings</ng-template>
                                <div class="row">
                                    <!-- <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon mat-icon-custom"><svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg></mat-icon>
                                            <mat-select placeholder="Season"  [(ngModel)]="Seasons"  formControlName="n_Seasons"  style="text-transform:capitalize;"  >
                                                <mat-option [value]="1">2020</mat-option>
                                                <mat-option [value]="2">2021</mat-option>
                                                <mat-option [value]="3">2022</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                             <div *ngIf="f.submitted && AssociationSettings.controls.n_Seasons.invalid"
                                                class="alert alert-danger">
                                                <div *ngIf="AssociationSettings.controls.n_Seasons.errors.required" >
                                                   Season required </div>
                                             </div>   
                                    </div> -->
                                    <div class="col-md-4">
                                        <div class="custom-color-picker2">
                                            <div class="color-left">
                                                <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Background Color</mat-label>
                                                    <input type="text" matInput value="#f1f5f9"  [(ngModel)]="BGColor_text"
                                                     (ngModelChange)="changeBGC(BGColor_text)"  formControlName="n_BGColor_text" />
                                                </mat-form-field>    
                                            </div>
                                            <div class="color-right">
                                                <input type="color" matInput id="BGColor"   [(ngModel)]="BGColor" class="form-control"
                                                 (ngModelChange)="changeBGC(BGColor)"  formControlName="n_BGColor" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="custom-color-picker2">
                                            <div class="color-left">
                                                <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                                <mat-label>Font Color</mat-label>
                                                    <input type="text" matInput value="#212529"  [(ngModel)]="FontColor_text"
                                                    (ngModelChange)="changeFC(FontColor_text)"  formControlName="n_FontColor_text" />
                                                </mat-form-field>    
                                            </div>
                                            <div class="color-right">
                                                <input type="color" matInput id="BGColor"  [(ngModel)]="FontColor" class="form-control" 
                                                (ngModelChange)="changeFC(FontColor)"  formControlName="n_FontColor" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="fileupload">
                                            <div class="placeholder">Academic Logo</div>                                            
                                            <input type="file" name="" class="fileupload-container" size="60" [(ngModel)]="AssociationLogo"
                                            formControlName="n_AssociationLogo" (change)="onFileChange($event,'association')" >
                                            <div class="browse-upload">
                                                <input name="" type="text" class="upload-txt" value="" placeholder="Select file" >
                                                <input type="submit" value="Browse" class="upload-btn">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="480" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5.08 8h2.95c.32-1.25.78-2.45 1.38-3.56-1.84.63-3.37 1.9-4.33 3.56zm2.42 4c0-.68.06-1.34.14-2H4.26c-.16.64-.26 1.31-.26 2s.1 1.36.26 2h3.38c-.08-.66-.14-1.32-.14-2zm-2.42 4a7.987 7.987 0 004.33 3.56A15.65 15.65 0 018.03 16H5.08zM12 4.04c-.83 1.2-1.48 2.53-1.91 3.96h3.82c-.43-1.43-1.08-2.76-1.91-3.96zM18.92 8a8.03 8.03 0 00-4.33-3.56c.6 1.11 1.06 2.31 1.38 3.56h2.95zM12 19.96c.83-1.2 1.48-2.53 1.91-3.96h-3.82c.43 1.43 1.08 2.76 1.91 3.96zm2.59-.4A8.03 8.03 0 0018.92 16h-2.95a15.65 15.65 0 01-1.38 3.56zM19.74 10h-3.38c.08.66.14 1.32.14 2s-.06 1.34-.14 2h3.38c.16-.64.26-1.31.26-2s-.1-1.36-.26-2zM9.66 10c-.09.65-.16 1.32-.16 2s.07 1.34.16 2h4.68c.09-.66.16-1.32.16-2s-.07-1.35-.16-2H9.66z"></path><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path></svg></mat-icon>
                                            <mat-label>Academic Website</mat-label>
                                            <input matInput  [(ngModel)]="AssociationWebsite"  formControlName="n_AssociationWebsite" >
                                        </mat-form-field>
                                        <div *ngIf="c.touched && AssociationSettings.controls.n_AssociationWebsite.invalid"
                                                        class="alert alert-danger">
                                            <div *ngIf="AssociationSettings.controls.n_AssociationWebsite.errors.pattern">
                                                            Please enter valid URL</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="480" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5.08 8h2.95c.32-1.25.78-2.45 1.38-3.56-1.84.63-3.37 1.9-4.33 3.56zm2.42 4c0-.68.06-1.34.14-2H4.26c-.16.64-.26 1.31-.26 2s.1 1.36.26 2h3.38c-.08-.66-.14-1.32-.14-2zm-2.42 4a7.987 7.987 0 004.33 3.56A15.65 15.65 0 018.03 16H5.08zM12 4.04c-.83 1.2-1.48 2.53-1.91 3.96h3.82c-.43-1.43-1.08-2.76-1.91-3.96zM18.92 8a8.03 8.03 0 00-4.33-3.56c.6 1.11 1.06 2.31 1.38 3.56h2.95zM12 19.96c.83-1.2 1.48-2.53 1.91-3.96h-3.82c.43 1.43 1.08 2.76 1.91 3.96zm2.59-.4A8.03 8.03 0 0018.92 16h-2.95a15.65 15.65 0 01-1.38 3.56zM19.74 10h-3.38c.08.66.14 1.32.14 2s-.06 1.34-.14 2h3.38c.16-.64.26-1.31.26-2s-.1-1.36-.26-2zM9.66 10c-.09.65-.16 1.32-.16 2s.07 1.34.16 2h4.68c.09-.66.16-1.32.16-2s-.07-1.35-.16-2H9.66z"></path><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path></svg></mat-icon>
                                            <mat-label>Twitter Hash Tag</mat-label>
                                            <input matInput  [(ngModel)]="Twitter"  formControlName="n_Twitter" >
                                        </mat-form-field>
                                        <div *ngIf="c.touched && AssociationSettings.controls.n_Twitter.invalid"
                                                        class="alert alert-danger">
                                            <div *ngIf="AssociationSettings.controls.n_Twitter.errors.pattern">
                                                            Please enter valid URL</div>
                                        </div>  
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="480" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5.08 8h2.95c.32-1.25.78-2.45 1.38-3.56-1.84.63-3.37 1.9-4.33 3.56zm2.42 4c0-.68.06-1.34.14-2H4.26c-.16.64-.26 1.31-.26 2s.1 1.36.26 2h3.38c-.08-.66-.14-1.32-.14-2zm-2.42 4a7.987 7.987 0 004.33 3.56A15.65 15.65 0 018.03 16H5.08zM12 4.04c-.83 1.2-1.48 2.53-1.91 3.96h3.82c-.43-1.43-1.08-2.76-1.91-3.96zM18.92 8a8.03 8.03 0 00-4.33-3.56c.6 1.11 1.06 2.31 1.38 3.56h2.95zM12 19.96c.83-1.2 1.48-2.53 1.91-3.96h-3.82c.43 1.43 1.08 2.76 1.91 3.96zm2.59-.4A8.03 8.03 0 0018.92 16h-2.95a15.65 15.65 0 01-1.38 3.56zM19.74 10h-3.38c.08.66.14 1.32.14 2s-.06 1.34-.14 2h3.38c.16-.64.26-1.31.26-2s-.1-1.36-.26-2zM9.66 10c-.09.65-.16 1.32-.16 2s.07 1.34.16 2h4.68c.09-.66.16-1.32.16-2s-.07-1.35-.16-2H9.66z"></path><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path></svg></mat-icon>
                                            <mat-label>Facebook</mat-label>
                                            <input matInput  [(ngModel)]="Facebook"  formControlName="n_Facebook" >
                                        </mat-form-field>
                                        <div *ngIf="c.touched && AssociationSettings.controls.n_Facebook.invalid"
                                                        class="alert alert-danger">
                                            <div *ngIf="AssociationSettings.controls.n_Facebook.errors.pattern">
                                                            Please enter valid URL</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                            <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="480" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5.08 8h2.95c.32-1.25.78-2.45 1.38-3.56-1.84.63-3.37 1.9-4.33 3.56zm2.42 4c0-.68.06-1.34.14-2H4.26c-.16.64-.26 1.31-.26 2s.1 1.36.26 2h3.38c-.08-.66-.14-1.32-.14-2zm-2.42 4a7.987 7.987 0 004.33 3.56A15.65 15.65 0 018.03 16H5.08zM12 4.04c-.83 1.2-1.48 2.53-1.91 3.96h3.82c-.43-1.43-1.08-2.76-1.91-3.96zM18.92 8a8.03 8.03 0 00-4.33-3.56c.6 1.11 1.06 2.31 1.38 3.56h2.95zM12 19.96c.83-1.2 1.48-2.53 1.91-3.96h-3.82c.43 1.43 1.08 2.76 1.91 3.96zm2.59-.4A8.03 8.03 0 0018.92 16h-2.95a15.65 15.65 0 01-1.38 3.56zM19.74 10h-3.38c.08.66.14 1.32.14 2s-.06 1.34-.14 2h3.38c.16-.64.26-1.31.26-2s-.1-1.36-.26-2zM9.66 10c-.09.65-.16 1.32-.16 2s.07 1.34.16 2h4.68c.09-.66.16-1.32.16-2s-.07-1.35-.16-2H9.66z"></path><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path></svg></mat-icon>
                                            <mat-label>Instagram</mat-label>
                                            <input matInput  [(ngModel)]="Instagram"  formControlName="n_Instagram" >
                                        </mat-form-field>
                                        <div *ngIf="c.touched && AssociationSettings.controls.n_Instagram.invalid"
                                                        class="alert alert-danger">
                                            <div *ngIf="AssociationSettings.controls.n_Instagram.errors.pattern">
                                                            Please enter valid URL</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                            <mat-label>Member Type</mat-label>
                                            <mat-icon matSuffix class="mat-icon mat-icon-custom"><svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg></mat-icon>
                                            <mat-select placeholder="Association Role" [(ngModel)]="AssociationRole"  formControlName="n_AssociationRole"   style="text-transform:capitalize;"  >
                                                <mat-option [value]="member.MemberTypeID"  *ngFor="let member of lstAssociationMember"  required
                                                    style="text-transform:capitalize;"  >{{member.MemberName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                               <div *ngIf="f.submitted && AssociationSettings.controls.n_AssociationRole.invalid"
                                                            class="alert alert-danger">
                                                <div *ngIf="AssociationSettings.controls.n_AssociationRole.errors.required">
                                                    Academic role is required</div> </div>
                                    </div>

                                    <div class="col-md-12 txt-right">
                                        <div class="img-preview">
                                            <img draggable="false"   src="{{AssociationLogo}}"   class="img-fluid" 
                                            onerror="this.src='assets/images/no_user.png';" />
                                            <!-- <img src="assets/images/no_user.png" class="img-fluid" > -->
                                        </div>
                                   </div>
                                </div>
                                <div class="txt-right">
                                    <button mat-raised-button color="primary" matStepperPrevious style="margin-right: 15px;">Back</button>
                                    <button mat-raised-button color="primary" style="margin-right: 15px;">{{ButtonType}}</button>
                                    <button mat-raised-button color="primary" (click)="stepper.reset()">Reset</button>
                                </div>
                            </form>
                        </mat-step>

                      </mat-vertical-stepper>
                </div>
            </div>
        </div>
    </div>
</div>  
</div>


<div class="overlay success"  *ngIf = "isSuccessAlert" >
    <div class="success-widget">
        <div class="success-header"><i class="fa fa-check"></i>Success</div>
        <div class="success-container">
            <div  class="alcenter" > 
                <h4>Academic Registration</h4>
                <p >{{successAlert}}</p> 
            </div>
        </div>
        <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top" routerLink="/association-list" (click)="isSuccessAlert=false" >Ok</button>
        </div>
    </div>
</div> 
 <div class="overlay error" *ngIf = "isErrorAlert" >
    <div class="error-widget">
       <div class="error-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="error-container">
            <div  class="alcenter" > 
                <h4>Academic Registration</h4>
            <p >{{errorAlert}}</p> 
        </div>
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="isErrorAlert=false" >Ok</button>
        </div>
   </div>
</div> 
 <div class="overlay warning" *ngIf = "isWarningAlert" >
    <div class="warning-widget">
       <div class="warning-header"> <i class="fa fa-check"></i>Warning </div>
       <div class="warning-container">
        <div class="alcenter"> 
            <h4>Academic Registration</h4>
            <p >{{warningAlert}}</p> 
        </div>   
       </div>
       <div class="button-container">
            <button mat-raised-button class="theme-bg btnsave hvr-bounce-to-top"  (click)="isWarningAlert=false" >Ok</button>
        </div>
   </div>
</div>



<div class="modal fade" id="modalcrop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" >
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Resize Image</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <!-- <span  data-toggle="modal" data-target="#modalcrop">click</span>   -->
                    <div class="col-md-8" style="float:left">
                        <image-cropper [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                            [cropperMinWidth]="200" [hideResizeSquares]="false" format="png"
                            (imageCropped)="imageCropped($event)"
                            (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()" style="float:left;">
                        </image-cropper>
                    </div>
                    <!-- <button type="button" (click)=zoonIn()>+</button> <button type="button" (click)=zoonOut()>-</button> -->
                    <div class="col-md-4" style="float:left">
                        <img draggable="false" src="{{croppedImage}}"
                            class="upload-preview" style="width: 100%"/> 
                    </div>
                </div>
            </div>
            <div class="footer txt-center">
                <button type="button" class="btn btn-primary" (click)="Applycropper()">Apply</button>
            </div>
        </div>
    </div>     
</div>