import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any=[], propName: string) {

    return value.sort((a, b) => {
       if (a[propName] < b[propName]) {
         return -1;
       } else if (a[propName] === b[propName]) {
         return 0;
       } else if (a[propName] > b[propName]) {
         return 1;
       }
       // if(a[propName] > b[propName]) return 1
       // else return 1;
     });
 
   }

}
