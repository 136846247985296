<div class="page-container">
    <div class="container">
        <div class="input-container">
            <div class="white">
                <div class="row">
                    <div class="col-md-10">
                        <div class="page-tab">
                            <a routerLink="/player">Player</a>
                            <a routerLink="/coach">Coach</a>
                            <a routerLink="/trainer" class="select">Trainer</a>
                            <a routerLink="/physio">Physio</a>
                            <a routerLink="/doctor">Doctor</a>
                            <a routerLink="/nutrition">Nutritionist</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="txt-right add-new back-previous">
                            <a routerLink="/trainer-list">
                                Back
                                <div class="ico-action-svg more" title="Previous Page">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.151 219.151" width="32" height="32">	<path d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575		C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"/><path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008 c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628 		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/></svg>
                                    <!-- <img src="assets/images/Back.png"> -->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="page-wrapper">
    <div class="form-container">
        <div class="container">
            <div class="output-container">
                <div class="white">
                    <mat-vertical-stepper #stepper>
                        <mat-step [completed]="false">
                            <form>
                            <ng-template matStepLabel>Basic Information</ng-template>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Association Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput required>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>First Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput required>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Last Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Father/Guardian Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Display Name</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <div class="md-block">
                                        <div class="radio-grp">Gender</div>
                                    </div>
                                    <mat-radio-group aria-label="Select an option">
                                        <mat-radio-button value="1">Male</mat-radio-button>
                                        <mat-radio-button value="2">Female</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Date of Birth(DOB)</mat-label>
                                        <input matInput [matDatepicker]="dob">
                                        <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                                        <mat-datepicker #dob></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button matStepperNext color="primary">Next</button>
                            </div>
                            </form>
                        </mat-step>
                        <mat-step [completed]="false">
                            <form>
                            <ng-template matStepLabel>Contact Infomation</ng-template>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Registration Number</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput required>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Registration Date</mat-label>
                                        <input matInput [matDatepicker]="picker">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <div class="fileupload">
                                        <div class="placeholder">Profile Image</div>                                            
                                        <input type="file" name="" class="fileupload-container" size="60">
                                        <div class="browse-upload">
                                            <input name="" type="text" class="upload-txt" value="" placeholder="Select file">
                                            <input type="submit" value="Browse" class="upload-btn">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Mobile Number</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Email Id</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Emergency Contact Person</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Emergency Contact No</mat-label>
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="768" y="0" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33A7.95 7.95 0 0020 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 014 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path></svg></mat-icon>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon mat-icon-custom"><svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg></mat-icon>
                                        <mat-label>Blood Group</mat-label>
                                        <mat-select placeholder="Blood Group">
                                            <mat-option [value]="01">O+ve</mat-option>
                                            <mat-option [value]="02">O-ve</mat-option>
                                            <mat-option [value]="03">AB-ve</mat-option>
                                            <mat-option [value]="04">AB+ve</mat-option>
                                            <mat-option [value]="05">B+ve</mat-option>
                                            <mat-option [value]="06">B-ve</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Nationality</mat-label>
                                        <mat-select placeholder="Nationality">
                                            <mat-option [value]="01">India</mat-option>
                                            <mat-option [value]="02">Australia</mat-option>
                                            <mat-option [value]="03">Sri Lanka</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12 txt-right">
                                    <div class="img-preview">
                                        <img src="assets/images/no_user.png" class="img-fluid">
                                    </div>
                               </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" matStepperNext>Next</button>
                            </div>
                            </form>
                        </mat-step>
                        <mat-step [completed]="false">
                            <ng-template matStepLabel>Trainer Information</ng-template>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Trainer Level</mat-label>
                                        <mat-select placeholder="Level">
                                            <mat-option [value]="01">Trainee</mat-option>
                                            <mat-option [value]="02">Subordinate</mat-option>
                                            <mat-option [value]="03">Senior</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <div class="fileupload">
                                        <div class="placeholder">Certification Upload (Image/PDF)</div>                                            
                                        <input type="file" name="" class="fileupload-container" size="60">
                                        <div class="browse-upload">
                                            <input name="" type="text" class="upload-txt" value="" placeholder="Select file">
                                            <input type="submit" value="Browse" class="upload-btn">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Trainer Description</mat-label>
                                        <textarea matInput placeholder="Coaching Description"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-label>Certification Agency</mat-label>
                                        <textarea matInput placeholder="Certification Agency"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 txt-right">
                                    <div class="img-preview" style="margin-top:20px;margin-bottom:0;">
                                        <img src="assets/images/no_user.png" class="img-fluid">
                                    </div>
                               </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" matStepperNext>Next</button>
                            </div>
                        </mat-step>
                        <mat-step [completed]="false">
                            <ng-template matStepLabel>Address</ng-template>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Address 1</mat-label>
                                        <input matInput>
                                    </mat-form-field>
                                </div><div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Address 2</mat-label>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Region</mat-label>
                                        <mat-select placeholder="Region">
                                            <mat-option [value]="01">Asia</mat-option>
                                            <mat-option [value]="02">Europ</mat-option>
                                            <mat-option [value]="03">South America</mat-option>
                                            <mat-option [value]="04">North America</mat-option>
                                            <mat-option [value]="05">Africa</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="528" y="1920" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><path d="M14.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path></svg></mat-icon>
                                        <mat-label>Country</mat-label>
                                        <mat-select placeholder="Country">
                                            <mat-option [value]="01">India</mat-option>
                                            <mat-option [value]="02">Australia</mat-option>
                                            <mat-option [value]="03">South Africa</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="432" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5 16h3.04l-1.52-2.03z"></path><path d="M9.78 11.63l1.25 1.67L14 9.33 19 16h-8.46l-4.01-5.37L1 18h22L14 6l-4.22 5.63zM5 16l1.52-2.03L8.04 16H5z"></path></svg></mat-icon>
                                        <mat-label>State</mat-label>
                                        <mat-select placeholder="State">
                                            <mat-option [value]="01">Tamil Nadu</mat-option>
                                            <mat-option [value]="02">Andhra Pradesh</mat-option>
                                            <mat-option [value]="03">Karnataka</mat-option>
                                            <mat-option [value]="04">Kerala</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="432" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5 16h3.04l-1.52-2.03z"></path><path d="M9.78 11.63l1.25 1.67L14 9.33 19 16h-8.46l-4.01-5.37L1 18h22L14 6l-4.22 5.63zM5 16l1.52-2.03L8.04 16H5z"></path></svg></mat-icon>
                                        <mat-label>City</mat-label>
                                        <mat-select placeholder="City">
                                            <mat-option [value]="01">Chennai</mat-option>
                                            <mat-option [value]="02">Kanchipuram</mat-option>
                                            <mat-option [value]="03">Chengalpattu</mat-option>
                                            <mat-option [value]="04">Trichy</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                        <mat-label>Pin Code</mat-label>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block mat-dd-field" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="432" y="1200" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M5 16h3.04l-1.52-2.03z"></path><path d="M9.78 11.63l1.25 1.67L14 9.33 19 16h-8.46l-4.01-5.37L1 18h22L14 6l-4.22 5.63zM5 16l1.52-2.03L8.04 16H5z"></path></svg></mat-icon>
                                        <mat-label>ID Proof</mat-label>
                                        <mat-select placeholder="ID Proof">
                                            <mat-option [value]="01">Aadhar Card</mat-option>
                                            <mat-option [value]="02">School Certificate</mat-option>
                                            <mat-option [value]="03">Pan Card</mat-option>
                                            <mat-option [value]="04">Driving Licence</mat-option>
                                            <mat-option [value]="05">passport</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <div class="fileupload">
                                        <div class="placeholder">ID Proof Upload</div>                                            
                                        <input type="file" name="" class="fileupload-container" size="60">
                                        <div class="browse-upload">
                                            <input name="" type="text" class="upload-txt" value="" placeholder="Select file">
                                            <input type="submit" value="Browse" class="upload-btn">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="md-block" appearance="outline" floatLabel="always">
                                        <mat-icon matSuffix class="mat-icon"><svg viewBox="0 0 24 24" x="912" y="1344" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M20 6H4l8 4.99zM4 8v10h16V8l-8 5z"></path><path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"></path></svg></mat-icon>
                                        <mat-label>Referal Code</mat-label>
                                        <input matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                     <mat-form-field appearance="fill" class="custom-dtpkr-txt md-block" appearance="outline" floatLabel="always">
                                         <mat-label>Package Valid Till</mat-label>
                                         <input matInput [matDatepicker]="validtill">
                                         <mat-datepicker-toggle matSuffix [for]="validtill"></mat-datepicker-toggle>
                                         <mat-datepicker #validtill></mat-datepicker>
                                     </mat-form-field>
                                </div>
                                <div class="col-md-4 txt-right">
                                     <div class="img-preview">
                                         <img src="assets/images/no_user.png" class="img-fluid">
                                     </div>
                                </div>
                            </div>
                            <div class="txt-right">
                                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" (click)="stepper.reset()">Reset</button>
                            </div>
                        </mat-step>
                        </mat-vertical-stepper>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
