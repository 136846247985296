import { Component, Input, OnInit } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService } from '../../masters-service.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ColumnMode } from '@swimlane/ngx-datatable/public-api';

@Component({
  selector: 'app-support-staff-mapping',
  templateUrl: './support-staff-mapping.component.html',
  styleUrls: ['./support-staff-mapping.component.css']
})
export class SupportStaffMappingComponent implements OnInit {

  constructor(private utility: UtilitiesService, private service: MastersServiceService, private formBuilder: FormBuilder) { }

  public staffList: boolean = false;
  public staffGroup: boolean = true;

  public ButtonType: string;
  public UserCode: string = "";

  @Input()
  public SuppportStaffGroup: string; public SuppportStaffGroups: string;

  public lstPlayGroup: any[]; public lstAssociationCoach: any[];
  public lstGetCoachByGroup: any[]; public lstGetCoachesBySelectedStatus: any[];
  public SupportStaffCounts: number;

  public SaveSSID: string; public SaveRefID: string;
  public listSupportStaffGroup: any = []; public listSSRefUserID: any = [];

  public isShowLoader: boolean;
  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;
  public isWarningAlert: boolean = false; public warningAlert: string;
  public isConfirmAlert: boolean = false; public confirmAlert: string;

  public ColumnMode: ColumnMode;
  public rowLimit: number = 10;
  public lstAssociationsFiltered: any = [];
  public SearchFilter: string;
  public LIMITS = [
    { value: 10 },
    { value: 20 },
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  rows = [];
  selected = [];
  // SelectionType = SelectionType;

  public _search!:string;

  showStaffgroup() {
    this.SuppportStaffGroups="";
    this._search = "";
    this.InitSupportStaffLoad();
    this.staffGroup = false;
    this.staffList = true;
  }
  hideStaffgroup() {
    this.staffList = false;
    this.staffGroup = true;
    this.InitSupportStaffLoad();
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.InitSupportStaffLoad();
  }

  InitSupportStaffLoad() {
    this.SupportStaffCounts = 0;
    this.isShowLoader = true;
    this.ButtonType = "Save";
    var SupportStaff = { PlayGroupID: "", CoachID: "", RefUserID: "", ActionFlag: "Load", UserID: this.UserCode };
    this.service.SupportStaffMapping(SupportStaff).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstPlayGroup = ret.PlayGroupList;
        if (this.lstPlayGroup.length > 0) {
          this.lstAssociationCoach = ret.AssociationCoachList;
          this.SuppportStaffGroup = (this.lstPlayGroup[0].PlayGroupID);
          this.SuppportStaffGroups = (this.lstPlayGroup[0].PlayGroupID);
          this.changePlayerGroup(this.lstPlayGroup[0].PlayGroupID);
          this.getSelectedPlayerGroup(this.lstPlayGroup[0].PlayGroupID);
        }
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Support-Staff Grid Load Failed..!! \n';
        return;
      }
    })
  }


  changePlayerGroup(groupId) {
    this.isShowLoader = true;
    this.ButtonType = "Save";
    var SupportStaff = {
      PlayGroupID: String(groupId), CoachID: "", RefUserID: "",
      ActionFlag: "GetCoachesByPlayGroup", UserID: this.UserCode
    };
    this.service.SupportStaffMapping(SupportStaff).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstGetCoachByGroup = ret.GetCoachByGroupList;
        this.isShowLoader = false;
        //this.ngOnInit();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Support-Staff Grid Load Failed..!! \n';
        return;
      }
    })
  }


  // this.SuppportStaffGroups
  getSelectedPlayerGroup(grpId) {
    this.isShowLoader = true;
    this.ButtonType = "Update";
    var SupportStaff = {
      PlayGroupID: String(grpId), CoachID: "", RefUserID: "",
      ActionFlag: "GetCoachesBySelectedStatus", UserID: this.UserCode
    };
    this.service.SupportStaffMapping(SupportStaff).subscribe((ret: any = []) => {
      let BTStatus = String(ret.Status);
      if (BTStatus == "true") {
        this.lstAssociationCoach = ret.GetCoachesBySelectedStatusList;
        this.isShowLoader = false;
        this.listSupportStaffGroup=[];
        this.listSSRefUserID=[];
        var lstSSFiltered = this.lstAssociationCoach.filter((con) => con.SelectedStatus === 1);
        for (var i = 0; i < lstSSFiltered.length; i++) {
          this.listSupportStaffGroup.push(lstSSFiltered[i].CoachID);
          this.listSSRefUserID.push(lstSSFiltered[i].RefUserID);
        }
        this.SupportStaffCounts=lstSSFiltered.length;
        // this.ngOnInit();
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = '\n Support-Staff Grid Load Failed..!! \n';
        return;
      }
    })
  }

  onAddclick(value, event) {
    if (event.currentTarget.checked == true) {
      this.listSupportStaffGroup.push(value.CoachID);
      this.listSSRefUserID.push(value.RefUserID);
    }
    else {
      var x = this.listSupportStaffGroup.indexOf(value.CoachID);
      this.listSupportStaffGroup.splice(x, 1);
      this.listSSRefUserID.splice(x, 1);
    }
    for (var i = 0; i < this.listSupportStaffGroup.length; i++) {
      this.SupportStaffCounts = i + 1;
    }
  }

  SavePlayerGroup() {
    if (this.SuppportStaffGroups != "" && this.SuppportStaffGroups != undefined) {
      this.isShowLoader = true;
      this.ButtonType = "Save";
      this.SaveSSID = (this.listSupportStaffGroup.join(','));
      this.SaveRefID = (this.listSSRefUserID.join(','));
      var SupportStaff = {
        PlayGroupID: String(this.SuppportStaffGroups), CoachID: String(this.SaveSSID),
        RefUserID: String(this.SaveRefID), ActionFlag: "Register", UserID: this.UserCode
      };

      this.service.SupportStaffMapping(SupportStaff).subscribe((ret: any = []) => {
        let BTStatus = String(ret.Status);
        if (BTStatus == "true") {
          this.isSuccessAlert = true;
          this.successAlert = 'Saved successfully..!!';
          this.isShowLoader = false;
          this.ngOnInit();
          this.hideStaffgroup();
        }
        else {
          this.isShowLoader = false;
          this.isErrorAlert = true;
          this.errorAlert = ret.Message;
          // this.errorAlert = '\n Save Failed..!! \n';
          return;
        }
      })
    }
    else {
      this.isShowLoader = false;
      this.isWarningAlert = true;
      this.warningAlert = '\n Group should not be empty..!! \n';
      return;
    }
  }







}
