import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { MastersServiceService} from '../../masters-service.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { MatStepper } from '@angular/material/stepper';
import { FormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-physio-list',
  templateUrl: './physio-list.component.html',
  styleUrls: ['./physio-list.component.css']
})
export class PhysioListComponent implements OnInit {

  constructor(private utility:UtilitiesService,private service:MastersServiceService, private formBuilder: FormBuilder,
    private router: Router, public datepipe: DatePipe ) { this.today.setDate(this.today.getDate()); }

  @Input()  today = new Date();
  @Output() EditAthleteID = new EventEmitter<any>();

  public UserCode:string = "1";
  public ButtonType:string;
  public isShowLoader:boolean;
  public lstAthleteGridLoad:any = [];
  public editAthleteData: any = [];

    
  ngOnInit(): void {
    this.utility.pageLoadScript();
  }

  InitGridLoad(){
    pageStatus = "GridLoad";
    var AssoLoad = { AccosiationID: "", ActionFlag: "GridLoad" , ModuleName: "AssoRegistration" , UserID:this.UserCode };
    this.service.AssociationMasterCRUD(AssoLoad).subscribe((ret:any=[])=>{
      let BTStatus = String(ret.Status);
        if (BTStatus == "true"){
          this.lstAthleteGridLoad = ret.AssociationsList;
        }  
        else{
            this.utility.openSnackBar('Load Failed..!!' );
        }
      })
  }

  DeleteAthlete(athlete){
    let deleteReferenceID = String(athlete.AssociationID);
    var AssoLoad = { RefUserID: deleteReferenceID, ActionFlag: "DeActivate" , ModuleName: "AssoRegistration" , UserID:this.UserCode };
    this.service.AssociationMasterCRUD(AssoLoad).subscribe((ret:any=[])=>{
      let BTStatus = String(ret.Status);
        if (BTStatus == "true"){
          this.utility.openSnackBar('Deleted Successfully..!!' );
        }
        else{
            this.utility.openSnackBar('Load Failed..!!' );
        }
      })
      pageStatus = "GridLoad";
  }

  
  EditAthlete(athlete){
    this.isShowLoader = true;  
       this.editAthleteData = athlete;
       pageStatus = "Edit";
       selectedAthleteList = athlete;
       this.EditAthleteID.emit(this.editAthleteData);
      this.router.navigate(['/player']);
    this.isShowLoader = false;  
  }


}

export var selectedAthleteList: any = [];
export var pageStatus: string = "";
