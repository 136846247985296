import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
declare var jQuery: any;
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  public previousPage:string;
  public currentPage:string;
  coachDetails:any;
  constructor(private snb: MatSnackBar, private router: Router) { }
  pageLoadScript() {
    if (this.currentPage != this.router.url) {
      this.previousPage = this.currentPage;
      this.currentPage = this.router.url;
    }
    // const script = document.createElement('script');
    // script.type ='text/javascript';
    // script.src ='assets/js/main.js';
    // if (this.router.url === '/video-analyse' || this.router.url === '/test-video' || this.router.url === '/drills') {
    //   document.body.appendChild(script);
    // }   

    (function ($) {
      "use strict";
      $(document).ready(function () {
        //fixed navbar
        $(window).scroll(function (this: any) {
          if ($(this).scrollTop() > 50) {
            $('#mainNav').addClass('navbar-shrink');
          } else if ($(this).scrollTop() < 50) {
            $("#mainNav").removeClass('navbar-shrink');
          }

          //fixed widget
          if ($(this).scrollTop() > 50) {
            $('.fixed-container.min').addClass('fixed');
          } else if ($(this).scrollTop() < 50) {
            $(".fixed-container.min").removeClass('fixed');
          }
        });

        $('.navbar-nav>li>a').on('click', function () {
          $('.navbar-collapse').collapse('hide');
        });
        //addclass and removeclass
        $(document).on('click', ".add-body", function (this: any) {
          $('#page-top').addClass('scroll-hidden');
        });
        $(document).on('click', ".hide-form,.btn-hide-form", function (this: any) {
          $('#page-top').removeClass('scroll-hidden');
        });

        // $(document).on('change', ".fileupload", function () {
        //   $('.upload-txt').attr('value', $('.fileupload-container').val());
        // });
        $(window).bind('resize', function () {
          sideMenu();
          innerpageHeight();
          advancesearch();
          customScroll();
        });
        sideMenu();
        function sideMenu() {
          setTimeout(function () {
            var bdyHeight = $(window).height();
              var logoHeight = $('.app-logo').outerHeight();
              var menuHeight = bdyHeight - logoHeight;
              //var hdrHeight = $('header').outerHeight();
              var menuscrollcontainer = menuHeight - 50;
              $('.sidebar').attr('style', 'height:' + menuscrollcontainer + 'px;');
          });
        }
        $(document).on('click', ".more-toggle.collapsed", function (this: any) {
          $(this).closest('.closest-popup').find('.toggle-popup').fadeIn();
          $(this).closest('.closest-popup').find('.more-toggle').removeClass("collapsed").addClass("expand");
          //$(this).addClass('expand');
        });
        $(document).on('click', ".more-toggle.expand,.cls-popup", function (this: any) {
          $(this).closest('.closest-popup').find('.toggle-popup').fadeOut();
          $(this).closest('.closest-popup').find('.more-toggle').removeClass("expand").addClass("collapsed");
          //$(this).addClass('collapsed');
        });
        $(document).on('click', ".alert-notification,.view-profile", function (this: any) {
          $('.alert-popup').hide();
          $(this).closest('.alert-notification').find('.alert-popup').toggle();
          return false;
        });
        // -- Have to add 17-03-2021 -- //
        $(document).on('click', ".alert-popup a", function (this: any) {
          $('.alert-popup').fadeOut();
        });
        $(document).click(function (event) {
          var msglist = $('.alert-notification,.view-profile');
          if (!$(event.target).is('.alert-popup')) {
            if (msglist.is(":visible")) {
              $('.alert-popup').fadeOut();
            }
          }
        });

        $(document).on('click', ".single-select a, .wagon-wheel-score a,.pitchmap-score a", function (this: any) {
          $(this).closest('.selector-ctrl, .scorecard-wagon,.scorecard-pitchmap').find('.single-select a,.wagon-wheel-score a,.pitchmap-score a').removeClass('select');
          $(this).addClass('select');
        });
        $(document).on('click', ".rbt-click", function (this: any) {
          //alert('hi');
          $(this).closest('.rbt').find('.rbt-click').removeClass('mat-radio-checked');
          $(this).addClass('mat-radio-checked');
        })
        $(document).on('click', ".select-captain", function (this: any) {
          $(this).closest('.selected-container').find('.select-captain').removeClass("select");
          $(this).addClass('select');
        });
        $(document).on('click', ".select-wk", function (this: any) {
          $(this).closest('#wicket-keeper').find('.select-wk').removeClass("select");
          $(this).addClass('select');
        });
        $(document).on('click', ".list-toggle.collapsed", function () {
          //alert('hi');
          $('.playlist-overlay').animate({right:0});
          $('.list-toggle').removeClass('collapsed').addClass('expanded');
        });
        $(document).on('click', ".list-toggle.expanded", function () {
          $('.playlist-overlay').animate({right:-220});
          $('.list-toggle').removeClass('expanded').addClass('collapsed');
        });

        $(document).on('click', ".main-tab a, .secondary-tab a, .third-tab a,.fourth-tab a", function (this: any) {
          var parentRelID = $(this).parent().attr('rel');
          var parentClassName = $(this).parent().attr('class');
          var relID = $(this).attr('rel');
          $('.' + parentRelID + ' .' + parentClassName + '-container').hide();
          $(this).parent().find('a').removeClass('select');
          $(this).addClass('select');
          $('.' + parentRelID + ' .' + parentClassName + '-container.' + relID).show();
        });

        innerpageHeight();
        function innerpageHeight() {
          setTimeout(function () {
            var bdyHeight = $(window).height();
            var hdrHeight = $('.header-right').outerHeight(true);
            var logoHeight = $('.logo').outerHeight(true);
            var titleHeight = $('.page-title').outerHeight(true);
            var inputHeight = $('.input-container').outerHeight(true);
            var leftinputHeight = $('.left-container .input-container').outerHeight(true);
            var rightinputHeight = $('.right-container .input-container').outerHeight(true);
            var outputHeight = $('.output-container').outerHeight(true);
            var leftoutputHeight = $('.left-container .output-container').outerHeight(true);
            var rightoutputHeight = $('.right-container .output-container').outerHeight(true);
            var searchHeight = $('.search-container').outerHeight(true);
            var tblHdrHeight = $('.scrollable-table .table thead').outerHeight(true);
            var tbHeight = $('.right-container .main-tab').outerHeight(true);
            var clndrHeight = $('.calendar').outerHeight(true);
            //alert(rightinputHeight);

            var scrollcontainer1 = bdyHeight - hdrHeight - inputHeight - 30;
            var scrollcontainer2 = bdyHeight - hdrHeight - inputHeight - 30;
            var scrollcontainer3 = scrollcontainer1 - searchHeight - tblHdrHeight - 20;
            var scrollcontainer4 = scrollcontainer2 - searchHeight - tblHdrHeight - 20;
            var scrollcontainer5 = scrollcontainer2 - leftinputHeight - 10;
            var scrollcontainer6 = scrollcontainer5 - inputHeight - 10;
            var scrollcontainer7 = scrollcontainer1 - leftinputHeight - 10;
            var scrollcontainer8 = scrollcontainer2 - searchHeight - tblHdrHeight - tbHeight - 20;
            var scrollcontainer9 = scrollcontainer1 - searchHeight - tblHdrHeight - 20;
            var scrollcontainer10 = clndrHeight - 50;
            var scrollcontainer11 = scrollcontainer2 - tblHdrHeight - 20;
            var scrollcontainer12 = scrollcontainer2 - inputHeight - 10;
            var scrollcontainer13 = bdyHeight - hdrHeight - titleHeight - 60;
            var scrollcontainer14 = bdyHeight - hdrHeight - titleHeight - inputHeight - tblHdrHeight - 80;
            var scrollcontainer15 = bdyHeight - hdrHeight - titleHeight - inputHeight - searchHeight - tblHdrHeight - 80;
            var scrollcontainer16 = scrollcontainer13 - rightinputHeight - tblHdrHeight - 40;
            //alert(tblHdrHeight);

            $('.output-container > .white, .left-container.yes-input > .white, .right-container.yes-input > .white,.stats-page .output-container.scroll-y').attr('style', 'height:' + scrollcontainer1 + 'px; position:relative;overflow:visible;');
            $('.left-container.no-input > .white,.right-container.no-input > .white,.page-container.scroll-y').attr('style', 'height:' + scrollcontainer2 + 'px; position:relative;');
            $('.scrollable-table .table tbody').attr('style', 'height:' + scrollcontainer3 + 'px; position:relative;overflow:visible;');
            $('.right-container.no-input > .white .scrollable-table .table tbody').attr('style', 'height:' + scrollcontainer4 + 'px; position:relative;overflow:visible;');
            $('.left-container.no-input .output-container > .white').attr('style', 'height:' + scrollcontainer5 + 'px; position:relative;overflow:visible;');
            $('.left-container.yes-input .output-container > .white').attr('style', 'height:' + scrollcontainer6 + 'px; position:relative;overflow:visible;');
            $('.stats-page .left-container.yes-input .output-container').attr('style', 'height:' + scrollcontainer7 + 'px; position:relative;overflow:visible;');
            $('.trail-page .right-container.no-input > .white .scrollable-table .table tbody').attr('style', 'height:' + scrollcontainer8 + 'px; position:relative;overflow:visible;');
            $('.right-container.no-input > .white .scrollable-table .table tbody').attr('style', 'height:' + scrollcontainer11 + 'px; position:relative;overflow:visible;');
            $('.dist-table .scrollable-table tbody').attr('style', 'height:' + scrollcontainer10 + 'px;');
            $('.stats-page .output-container .white').attr('style', 'height:' + scrollcontainer2 + 'px; position:relative;overflow:visible;');
            $('.stats-page .output-container.yes-input .white').attr('style', 'height:' + scrollcontainer12 + 'px; position:relative;overflow:visible;');
            $('.common-page .output-container.no-input .white').attr('style', 'height:' + scrollcontainer2 + 'px; position:relative;overflow:visible;');
            $('.page-container.no-input .output-container .white.scroll-y').attr('style', 'height:' + scrollcontainer13 + 'px; position:relative;overflow:visible;');
            $('.page-container .output-container .white2 .scrollable-table .table tbody').attr('style', 'height:' + scrollcontainer14 + 'px; position:relative;');
            $('.page-container .output-container.two-table .white2 .scrollable-table .table tbody').attr('style', 'height:' + scrollcontainer15 + 'px; position:relative;');
            $('.page-container .left-container.no-input .white').attr('style', 'height:' + scrollcontainer13 + 'px; position:relative;');
            $('.page-container .right-container.no-input .scrollable-table .table tbody').attr('style', 'height:' + scrollcontainer16 + 'px; position:relative;');

            $('.video-container').mouseover(function () {
              $('.button-controls').animate({ bottom: 0 }, 200);
            });
            $('.video-container').mouseleave(function () {
              $('.button-controls').animate({ bottom: -42 }, 200);
            });
          }, 500);
          $(window).bind('resize', function () {
            innerpageHeight();
            advancesearch();
            customScroll();
          });
        }


        customScroll();
        function customScroll() {
          setTimeout(function () {
            //alert('hi');
            $("scroll-xy, .scrollable-table, .customscroll-table").mCustomScrollbar({
              axis: "yx",
              scrollButtons: { enable: true },
              advanced: { autoScrollOnFocus: false },
              theme: "minimal",
              scrollbarPosition: "outside"
            });
            $(".scroll-y, .scrollable-table tbody, .scrollable-table tbody.scroll-vertical, .sidebar, .customscroll-table tbody").mCustomScrollbar({
              axis: "y",
              scrollButtons: { enable: true },
              advanced: { autoScrollOnFocus: false },
              theme: "minimal",
              scrollbarPosition: "outside"
            });
            $(".scroll-x, .ball-count,.pivot-container").mCustomScrollbar({
              axis: "x",
              scrollButtons: { enable: true },
              advanced: { autoExpandHorizontalScroll: true },
              theme: "minimal",
              scrollbarPosition: "outside"
            });
          });
        }

        advancesearch();
        function advancesearch() {
          $(document).on('click', '.search-toggle.collapsed,.showmore.collapsed', function (this: any) {
            //alert('hi');
            $('.advance-search-container,.showmore-container').slideDown('slow');
            $(this).removeClass('collapsed').addClass('expanded');
          });
          $(document).on('click', '.search-toggle.expanded,.showmore.expanded', function (this: any) {
            $('.advance-search-container,.showmore-container').slideUp('slow');
            $(this).removeClass('expanded').addClass('collapsed');
          });
          innerpageHeight();
        }

      });
    })(jQuery);
  }

  fullscreenlayout() {
    (function ($) {

      fullHeight();
      function fullHeight() {
        var bodyHeight = $(window).height();
        var statusHeight = $('.se-status-bar').outerHeight(true);
        var heightcontainer1 = bodyHeight - statusHeight - 10;
        var heightcontainer2 = bodyHeight - statusHeight - 10;
        $('.panel-container').attr('style', 'height:' + heightcontainer1 + 'px;');
        // $('.left-panel').attr('style', 'height:' + heightcontainer2 + 'px;');
      }
      $(window).bind('resize', function () {
        fullHeight();
      });

      // $(document).click(function (event) {
      //   var msglist = $('.more-toggle');
      //   if (!$(event.target).is('.toggle-popup')) {
      //     if (msglist.is(":visible")) {
      //       $('.toggle-popup').fadeOut();
      //     }
      //   }
      // }); 

      $(document).on('click', ".icon-toggle.collapsed", function () {
        $('.video-overlay').animate({ right: 0 });
        $('.icon-toggle').removeClass('collapsed').addClass('expanded');
      });
      $(document).on('click', ".icon-toggle.expanded", function () {
        $('.video-overlay').animate({ right: -130 });
        $('.icon-toggle').removeClass('expanded').addClass('collapsed');
      });

      $(document).on('click', '.toggleclass a', function (this: any) {
        $(this).toggleClass('select');
      })
      $(".se-speedtype").click(function (this: any) {
        //alert('fdf');
        $(this).text($(this).text() == 'Km/h' ? 'Mph' : 'Km/h');
      });
      $(".end-txt").click(function (this: any) {
       // alert('fdf');
        $(this).text($(this).text() == 'Near' ? 'Far' : 'Near');
      });
      // $(".end-txt").click(function(){
      //   $(this).text($(this).text() == 'Near' ? 'Far' : 'Near');
      // });
      $(document).on('click', ".view-by-icon", function (this: any) {
        var parentClass = $(this).parent().parent().attr('class');
        //alert(parentClass);
        var trimTxt = parentClass.split(' ')[0];
        $('.dd-panel').attr('style', 'visibility:hidden;').removeClass('open');
        $('.' + trimTxt + ' .dd-panel').attr('style', 'visibility:visible').toggleClass('open');
        return false;
      });
      $(document).on('click', ".dd-panel a", function (this: any) {
        var parentClass = $(this).parent().parent().parent().attr('class');
        var trimTxt = parentClass.split(' ')[0];
        //alert(trimTxt);
        $('.' + trimTxt + ' .dd-panel a').removeClass('select');
        $(this).addClass('select');
        var iconText = $(this).find('span').text();
        $('.' + trimTxt + ' .view-by-icon span.search-value').empty().text(iconText);
      });
      $(document).click(function (event: { target: any; }) {
        var msglist = $('.dd-panel');
        if (!$(event.target).is('.dd-panel')) {
          if (msglist.is(":visible")) {
            $('.dd-panel').attr('style', 'visibility:hidden;').removeClass('open');
          }
        }
      });

      $(document).on('click', ".view-by-icon", function(this:any) {
          var parentClass = $(this).parent().parent().attr('class');
          //alert(parentClass);
          var trimTxt = parentClass.split(' ')[0];
          $('.dd-panel').attr('style', 'visibility:hidden;').removeClass('open');
          $('.' + trimTxt + ' .dd-panel').attr('style', 'visibility:visible').toggleClass('open');
          return false;
        });
        $(document).on('click', ".dd-panel a", function (this:any) {
          var parentClass = $(this).parent().parent().parent().attr('class');
          var trimTxt = parentClass.split(' ')[0];
          //alert(trimTxt);
          $('.' + trimTxt + ' .dd-panel a').removeClass('select');
          $(this).addClass('select');
          var iconText = $(this).find('span').text();
          $('.' + trimTxt + ' .view-by-icon span.search-value').empty().text(iconText);
        });
        $(document).click(function(event: { target: any; }) {
          var msglist = $('.dd-panel');
          if(!$(event.target).is('.dd-panel')) {
            if(msglist.is(":visible")) {
              $('.dd-panel').attr('style', 'visibility:hidden;').removeClass('open');
            }
          }
        });

    })(jQuery);
  }

  colorpicker() {
    (function ($) {
      $('.demo').each(function () {
        $(this).minicolors({
          control: $(this).attr('data-control') || 'hue',
          defaultValue: $(this).attr('data-defaultValue') || '',
          format: $(this).attr('data-format') || 'hex',
          keywords: $(this).attr('data-keywords') || '',
          inline: $(this).attr('data-inline') === 'true',
          letterCase: $(this).attr('data-letterCase') || 'lowercase',
          opacity: $(this).attr('data-opacity'),
          position: $(this).attr('data-position') || 'bottom',
          swatches: $(this).attr('data-swatches') ? $(this).attr('data-swatches').split('|') : [],
          change: function (value, opacity) {
            if (!value) return;
            if (opacity) value += ', ' + opacity;
            if (typeof console === 'object') {
              console.log(value);
            }
          },
          theme: 'bootstrap'
        });

      });
    })(jQuery);
  }

  openSnackBar(Message) {
    horizontalPosition = 'right';
    verticalPosition = 'top';
    this.snb.open(Message, '', {
      duration: 2000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }


}

export var horizontalPosition: MatSnackBarHorizontalPosition;
export var verticalPosition: MatSnackBarVerticalPosition; 