import { Component, OnInit, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { UtilitiesService } from '../../utilities.service';
import { FormsModule, FormGroup, FormBuilder, Validators, NgForm, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { MastersServiceService } from '../../masters-service.service';
import { DatePipe } from '@angular/common';
import {  PlayGroupsComponent, SessionData } from '../play-groups/play-groups.component';


@Component({
  selector: 'app-coach-playersessions',
  templateUrl: './coach-playersessions.component.html',
  styleUrls: ['./coach-playersessions.component.css']
})
export class CoachPlayersessionsComponent implements OnInit { 
  SessionForm: FormGroup;
  config: UploadConfig
  /** The current percent to be displayed */
  percent: number;
  uploadedsize: string;
  isUploading: boolean = false;
  public UserCode: string = "";
  public SessionTypes: any[];
  public VideoViewTypes: any[];
  public SessionsList: any[];
  public CoachList: any[];
  public SelectedCoachList: any[];
  public MessageList: any[];
  // public PlayerDetails: PlayerDetailsArr =
  //   { ProfileImage: "", FirstName: "", LastName: "", PlayerRoleName: "", BattingTypeName: "", BowlingStyleName: "", Age: "", DrillSharedCount: "" };
  public PlayerDetails: any = [];
  public skill: string; public viewType: string;
  public sessionName: string; public sessionDate: Date;
  public videoURL: string; public selectedCoach: string = "";
  public openedShareid: string; public filterStartDate: string;
  public filterEndDate: string; public FilterSessionTypeID: string; public FilterViewID: string;

  myControl = new FormControl();

  public commentsFeed: boolean = false;

  public showMonthRange: boolean = false; public showDateRange: boolean = true;
  public advFilters: boolean = false; public selectedfilesize: string;
  public durations: number; public Videoduration: string;
  public currentFile: File; public selectedFile: string;
  public videoLoaded: boolean = false; public videoSubscription: boolean = true;

  public isSuccessAlert: boolean = false; public successAlert: string;
  public isErrorAlert: boolean = false; public errorAlert: string;

  public IsShowComments: boolean = false;

  constructor(private utility: UtilitiesService, private http: HttpClient, private blob: BlobService, private service: MastersServiceService
    , public datepipe: DatePipe, private formBuilder: FormBuilder, private _host: ElementRef, private router: Router) {
    this.currentFile = null;
    this.config = null;
    this.percent = 0;
  }

  public isShowLoader: boolean = false;
  public SearchFilter: string; public SearchLevel: string;

  public IsEmptyVideos: boolean = false; public IsEmptyDrill: boolean = false; public IsEmptyPlaylist: boolean = false;
  public IsSessionsListShow: boolean = true; public IsDrillsListShow: boolean = false; public IsPlayListShow: boolean = false;

  public AthleteID: string; public AthleteSessionID: string = "";
  public lstGetAthleteDrill: any[]; public lstDrillLevel: any[];
  public IsMyVideoShow: boolean = true;

  public lstReviewed: any = []; public ReviewCount: number = 0; public id: number;

  public PreviewDrillName: string; public DrillType: string;
  public ThumbNail: string; public VideoURL: string;
  public CreatedTime: string; public CreatedDate: string;
  public CreatedBy: string; public VideoLength: string;
  public lstDrillEdit: any[]; public lstDrillEditStep: any[];
  public MarialsBind: any = [];

  ngOnInit(): void {
    this.utility.previousPage=this.utility.currentPage;
    this.utility.currentPage=this.router.url;
    this.UserCode = String(JSON.parse(sessionStorage.getItem("loginUserDetails")).UserID);
    this.AthleteID = sessionStorage.getItem('sessionAthleteRefID');
    this.utility.pageLoadScript();
    this.SessionListLoad(Number(1));
    this.SessionFiltersLoad()
    this.videoSubscription = true;
  }

  SessionListLoad(id: any) {
    this.id = id;
    this.IsMyVideoShow = false;
    // let AthleteID = String(SessionData.RefUserID);
    this.service.SessionChattingCoachLoad({
      ActionFlag: "GetSharedSessions", CoachRefUserID: this.UserCode,
      AtheleteRefUserID: this.AthleteID
    }).subscribe((ret: any = []) => {
      this.SessionsList = ret.PlayerSharedSessionsList;
      if (ret.PlayerDetails != null) {
        this.PlayerDetails = ret.PlayerDetails[0];
      }
      if (this.SessionsList.length > 0) {
        this.SessionsList = this.SessionsList;

        this.lstReviewed = this.SessionsList.filter((con) => String(con.IsReviewed) === '1');
        for (let i = 0; i < this.lstReviewed.length; i++) {
          this.ReviewCount = i + 1;
        }

        this.IsEmptyVideos = false; this.IsEmptyDrill = false;
        this.IsSessionsListShow = true; this.IsDrillsListShow = false;
        this.IsPlayListShow=false;
      }
      else {
        this.IsEmptyVideos = true; this.IsEmptyDrill = false;
        this.IsSessionsListShow = false; this.IsDrillsListShow = false;
        this.IsPlayListShow=false;
      }
    })
  }


  DrillListLoad(id: any) {
    this.id = id;
    this.isShowLoader = true;
    this.IsMyVideoShow = false;
    var DrillsLoad = {
      ActionFlag: "GetCoachSharedDrillDetails",
      CoachRefUserID: this.UserCode, AtheleteRefUserID: this.AthleteID
    };
    this.service.SessionChattingCoachLoad(DrillsLoad).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstGetAthleteDrill = ret.CoachSharedDrillDetails;
        this.lstDrillLevel = ret.DrillLevelList;

        if (ret.PlayerDetails != null) {
          this.PlayerDetails = ret.PlayerDetails[0];
        }
        if (this.lstGetAthleteDrill.length > 0) {
          this.lstGetAthleteDrill = this.lstGetAthleteDrill;
          this.IsEmptyVideos = false; this.IsEmptyDrill = false;
          this.IsSessionsListShow = false; this.IsDrillsListShow = true;
          this.IsPlayListShow = false;
          this.isShowLoader = false;
        }
        else {
          this.IsEmptyVideos = false; this.IsEmptyDrill = true;
          this.IsSessionsListShow = false; this.IsDrillsListShow = false;
          this.IsPlayListShow = false;
          this.isShowLoader = false;
        }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  SessionFiltersLoad() {
    this.service.AthleteSessionPageLoad({ ActionFlag: "Load", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      this.SessionTypes = ret.SessionTypes;
      this.VideoViewTypes = ret.VideoViewTypes;
    })
  }

  ApplySessionFilters() {
    let AthleteID = String(SessionData.RefUserID);
    this.service.SessionChattingCoachLoad({ ActionFlag: "GetSharedSessions", CoachRefUserID: this.UserCode, AtheleteRefUserID: AthleteID, FilterSessionTypeID: this.selsesTypes.join(','), FilterViewID: this.selsesViews.join(','), FromDate: this.filterStartDate, ToDate: this.filterEndDate }).subscribe((ret: any = []) => {
      this.SessionsList = ret.PlayerSharedSessionsList;
    })
  }

  classFlag: boolean = false;
  public selsesTypes: any = []; public selsesViews: any = [];
  menuToggle(event: any, val, type) {
    event.target.classList.toggle('active');
    if (type == 'type') {
      if (event.target.classList.value == 'active') {
        this.selsesTypes.push(val);
      }
      else {
        var x = this.selsesTypes.indexOf(val);
        this.selsesTypes.splice(x, 1);
      }
    }
    else if (type == 'view') {
      if (event.target.classList.value == 'active' || event.target.classList.value == 'select active') {
        this.selsesViews.push(val);
      }
      else {
        var x = this.selsesViews.indexOf(val);
        this.selsesViews.splice(x, 1);
      }
    }
    // this.listAddAthleteGroup.splice(x,1);    
  }

  showCommentbox(id, SessionID) {
    this.IsShowComments = !this.IsShowComments;
    this.openedShareid = id;
    this.service.SessionChattingCoachCRUD({ ActionFlag: "Receive", CoachRefUserID: this.UserCode, SessionID: String(SessionID) }).subscribe((ret: any = []) => {
      this.MessageList = ret.CoachSessionChatInfo;
    })
  }

  hideCommentbox() {
    this.IsShowComments = false;
  }

  sendMessage(msgid) {
    var x = document.getElementById('message' + msgid).innerText;
    if (x != "" && x != null) {
      this.service.SessionChattingCoachCRUD({ ActionFlag: "SendCoach", CoachRefUserID: this.UserCode, SessionID: String(msgid), MsgContent: x }).subscribe((ret: any = []) => {
        this.MessageList = ret.CoachSessionChatInfo;
        document.getElementById('message' + msgid).innerText = "";
      })
    }
    else {
      this.isErrorAlert = true;
      this.errorAlert = "Commants should not be empty..!!";
    }
  }

  // -- Get Selected Drill Details -- //
  ShowMyVideo(video) {
    this.isShowLoader = true;
    this.IsEmptyDrill = false;
    this.IsMyVideoShow = true;
    this.MarialsBind = [];
    var Drills = { DrillID: String(video.DrillID), ActionFlag: "GetDrillDetails", RefUserID: this.UserCode };
    this.service.DrillsAthletes(Drills).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.lstDrillEdit = ret.DrillEditList;
        this.lstDrillEditStep = ret.DrillEditStepList;
        this.IsMyVideoShow = true;
        this.PreviewDrillName = this.lstDrillEdit[0].DrillName;
        this.DrillType = this.lstDrillEdit[0].DrillType;
        this.ThumbNail = this.lstDrillEdit[0].ThumbnailURL;
        this.VideoURL = this.lstDrillEdit[0].VideoURL;
        this.CreatedTime = this.lstDrillEdit[0].CreatedTime;
        this.CreatedDate = this.lstDrillEdit[0].CreatedDate;
        this.CreatedBy = video.SharedByUserName;
        this.VideoLength = video.VideoLength;
        var arrayMat = String(this.lstDrillEdit[0].Materials).split(",").map(String);
        for (var i = 0; i < arrayMat.length; i++) {
          this.MarialsBind.push(arrayMat[i]);
        }
        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }

  // -- Get Selected Playlist Details -- //
  PlayLists: any = [];
  PlayListDetails: any = [];
  PlayListLoad(video) {
    this.isShowLoader = true;
    this.IsEmptyVideos = false; this.IsEmptyDrill = false;
    this.IsSessionsListShow = false; this.IsDrillsListShow = false;
    this.service.VideoPlayListView({ CoachID: String(this.UserCode), ActionFlag: "GetPlaylistbyCoach", RefUserID: this.UserCode }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.PlayLists = ret.PlayLists;

        this.isShowLoader = false;
        if (this.PlayLists.length == 0) {
          this.IsEmptyPlaylist = true;
          this.IsPlayListShow = false;
        } else {
          this.IsPlayListShow = true;
          this.IsEmptyPlaylist = false;
        }
      }
      else {
        this.isShowLoader = false;
        this.isErrorAlert = true;
        this.errorAlert = ret.Message;
        return;
      }
    })
  }
  public filteredPlayListDetails: any = [];
  public playlistvideo: string = "";
  public PlayListName!: string;
  public videoEndTime: Number;
  public currentVideo: number;
  public currentVideoIndex: number;
  playlistClick(playlist, videoplayer) {
    this.service.VideoPlayListView({ CoachID: String(this.UserCode), ActionFlag: "GetPlaylistDetails", RefUserID: this.UserCode, PlayListID: String(playlist.PlayListID) }).subscribe((ret: any = []) => {
      if (ret.Status == true) {
        this.PlayListDetails = ret.PlayListDetails;
        this.playlistvideo = this.PlayListDetails[0].CloudURL;
        videoplayer.pause();
        videoplayer.currentTime = Number(this.PlayListDetails[0].StartTime);
        this.videoEndTime = Number(this.PlayListDetails[0].EndTime);
        this.currentVideo = 1;
        this.currentVideoIndex = 0;
        this.PlayListName = playlist.PlayListName;
        videoplayer.play();
      }
    });

  }
  setCurrentTime(videoplayer) {
    var percentage = (videoplayer.currentTime / videoplayer.duration) * 100;
    $("#custom-seekbar span").css("width", percentage + "%");
    // if (parseFloat(videoplayer.currentTime).toFixed(1) == (parseFloat(String(this.NextAnnotationTime)).toFixed(1))) {
    if (videoplayer.currentTime >= this.videoEndTime) {
      videoplayer.pause();
      this.playlistvideo = this.PlayListDetails[this.currentVideo].CloudURL;
      videoplayer.currentTime = Number(this.PlayListDetails[this.currentVideo].StartTime);
      this.videoEndTime = Number(this.PlayListDetails[this.currentVideo].EndTime);
      this.currentVideo = this.currentVideo + 1;
      this.currentVideoIndex = this.currentVideo - 1;
      videoplayer.play();
    }
  }
  playlistDetailClick(videoplayer, value, index) {
    videoplayer.pause();
    this.playlistvideo = this.PlayListDetails[index].CloudURL;
    videoplayer.currentTime = Number(this.PlayListDetails[index].StartTime);
    this.videoEndTime = Number(this.PlayListDetails[index].EndTime);
    this.currentVideo = index + 1;
    this.currentVideoIndex = index;
    videoplayer.play();
  }
  closePlayListVideo(videoplayer) {
    videoplayer.pause();
  }
  GetAnalysisDetails(data) {
    sessionStorage.setItem("sessionId", String(data.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(data.CloudURL));
    sessionStorage.setItem("sessionPlayerName", String(data.PlayerName));
    sessionStorage.setItem("sessionPlayerRole", String(data.PlayerRoleName));
    sessionStorage.setItem("sessionVideoDate", String(data.SessionDate));
    sessionStorage.setItem("sessionProfileImage", String(data.ProfileImage));
    sessionStorage.setItem("sessionsThumbnail", String(data.ThumbnailURL));
    sessionStorage.setItem("SessionTypeName", String(data.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(data.VideoViewName));
    sessionStorage.setItem("SessionName", String(data.SessionName));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));
    sessionStorage.setItem("sessionFlag", String("1"));
    sessionStorage.setItem("athleteID", String(this.AthleteID));
    this.service.VideoSessionDetails=data;
    this.router.navigate(['/video-analyse']);
  }


  GetCompareVideos(data) {
    sessionStorage.setItem("sessionId", String(data.SessionID));
    sessionStorage.setItem("sessionVideoUrl", String(data.CloudURL));
    sessionStorage.setItem("sessionPlayerName", String(data.PlayerName));
    sessionStorage.setItem("sessionPlayerRole", String(data.PlayerRoleName));
    sessionStorage.setItem("sessionVideoDate", String(data.SessionDate));
    sessionStorage.setItem("sessionProfileImage", String(data.ProfileImage));
    sessionStorage.setItem("sessionsThumbnail", String(data.ThumbnailURL));
    sessionStorage.setItem("SessionTypeName", String(data.SessionTypeName));
    sessionStorage.setItem("VideoViewName", String(data.VideoViewName));
    sessionStorage.setItem("SessionName", String(data.SessionName));
    sessionStorage.setItem("sessionUserCode", String(this.UserCode));
    sessionStorage.setItem("athleteID", String(this.AthleteID));
    sessionStorage.setItem("sFlag", String("1"));
    
    this.router.navigate(['/video-compare']);
  }


}
export interface PlayerDetailsArr {
  ProfileImage: string, FirstName: string, LastName: string, PlayerRoleName: string, BattingTypeName: string, BowlingStyleName: string, Age: string,
  DrillSharedCount: string
}
